    //@ts-ignore
    //@ts-nocheck
    import {
  Legend
} from "@devexpress/dx-react-chart-material-ui";
import {
  Avatar, Box, Button, Chip, Grid, IconButton, Modal, Tab, Tabs, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { withTranslation } from 'react-i18next';
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import * as Assets from './assets';
import {
  backIcon, facebookImg, googleImg, instagramImg, linkedinImg, mailIcon, phoneIcon, twitterImg, verify
} from "./assets";
import ProfileHeader from "./ProfileHeader";
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";

    const data = [
      { region: "Asia", val: 4119626293 },
      { region: "Africa", val: 1012956064 },
      { region: "Northern America", val: 344124520 },
      { region: "Latin America and the Caribbean", val: 590946440 },
      { region: "Europe", val: 727082222 },
      { region: "Oceania", val: 35104756 }
    ];

    class UserProfile extends UserProfileBasicController {
      constructor(props: Props) {
        super(props);
      }

      getEmbededUrl(url){
        let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        let matches = url.match(p);
        let embededUrl = 'https://www.youtube.com/embed/'
        if(matches){
            return  embededUrl + matches[1];
        }
        return embededUrl;
      }

      Listings = (userDetails) => {
        
        const showDetails =  userDetails.status?.data?.follow_status.toLowerCase() === "following" || userDetails.status?.data?.connect_status.toLowerCase() === "connected" 
        
        const {classes, t, i18n} = this.props
          
          const listings = this.state.showAllListings && showDetails
          ? userDetails?.listings?.data
          : userDetails?.listings?.data.slice(0, 2);

          return (
            <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
            <Box id='listings'>
            <Box style={{ padding: "10px 20px 10px" }}>
              <Grid container>
                <Grid md={12} xs={12}>
                  <Typography
                    style={{
                      color: "#353535",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      marginTop: 5,
                      fontWeight: 500,
                      marginBottom: 10
                    }}
                  >
                    {this.props.t("Listings")}
                  </Typography>
                </Grid>
                {listings.length > 0 && listings.map(listing=>(
                  <Grid
                  onClick={() => {
                    showDetails ? window.location =`/user-profile/${userDetails.id}/listing/${listing.id}` as any : null;
                  }}
                  key={listing.id}
                  md={6}
                  xs={12}
                  style={{ paddingRight: "15px", marginTop: "15px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <div>
                        <Avatar src={userDetails.profile_picture} style={{ height: "40px" }} />
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        <Typography
                          style={{
                            color: "#424242",
                            fontSize: "1.3rem",
                            fontWeight: "600"
                          }}
                        >
                          {userDetails.first_name}
                        </Typography>
                      
                      </div>
                    </div>
                  </div>
                  <div>
                    <Typography
                      style={{
                        color: "#424242",
                        fontFamily: "Poppins",
                        fontSize: 12,
                        marginBottom: 10,
                        marginTop: 10,
                        position: "relative"
                      }}
                    >
                      {t(listing.attributes.title)}
                    </Typography>
                  </div>
                  <Grid sm={12} xs={12} className={classes.socialMediaCol} style={{paddingLeft: 0}}>
                  <Box
                    style={{
                      backgroundColor: "#f2f2f2",
                      padding: "0px 0px"
                    }}
                  >
                    <Avatar src={this.getListingCategoryImage(listing.attributes.category)} className={classes.socialMediaImg} style={{height:263}} />
                  </Box>
                </Grid>
                </Grid>
                ))}
              </Grid>
            </Box>

            {listings.length > 0 && showDetails && (
              <Box
                onClick={()=>this.setState({showAllListings: !this.state.showAllListings})}
                style={{
                  borderBottom: "1px solid #ccc",
                  borderTop: "1px solid #ccc",
                  padding: "10px",
                  textAlign: "center",
                  marginTop: "30px",
                  cursor: 'pointer'
                }}
              >
                <Typography
                  style={{
                    color: "#6b6b6b",
                    fontSize: "1.3rem",
                    fontWeight: "bold"
                  }}
                >
                  {`${this.props.t("See")} ${this.state.showAllListings ? `${this.props.t("less")}`: `${this.props.t("all")}`} ${this.props.t("listings")}`}
                </Typography>
              </Box>
            )}
          
          </Box>
          </div>
          
          );
      };

      Portfolios = (userDetails) => {
        const showDetails =  userDetails.status?.data?.follow_status.toLowerCase() === "following" || userDetails.status?.data?.connect_status.toLowerCase() === "connected" 
        
        const {showAllPortfolios} = this.state
        
        const portfolios = showAllPortfolios && showDetails
          ? userDetails?.portfolios?.data
          : userDetails?.portfolios?.data.slice(0, 2);
          
          
          const {classes} = this.props;
          
          return (
            <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
            <Box id='portfolios'>
            <Box style={{ padding: "10px 20px 10px" }}>
              <Grid container>
                <Grid md={12} xs={12}>
                  <Typography
                    style={{
                      color: "#353535",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      marginTop: 5,
                      fontWeight: 500,
                      marginBottom: 10
                    }}
                  >
                    {this.props.t("Portfolio")}
                  </Typography>
                </Grid>

                {portfolios.length > 0 && portfolios.map(portfolio => (
                    <Grid 
                      onClick={() => {
                        showDetails ? window.location =`/user-profile/${userDetails.id}/portfolio/${portfolio.id}` as any : null
                    }}
                    key={portfolio.id} 
                    md={6} sm={4} xs={12} 
                    className={classes.socialMediaCol} 
                    style={{paddingLeft:0,paddingRight:15}}>
                    <Box style={{ backgroundColor: "#f2f2f2", padding: "0px 0px 15px 0px" }}>
                      <Avatar
                        src={portfolio.attributes.project_banner} className={classes.socialMediaImg} />
                      <Typography
                        style={{
                          color: "#6b6b6b",
                          fontSize: "1.3rem",
                          fontWeight: "bold",
                          padding: "10px",
                          textAlign: "center"
                        }}
                      >
                        {portfolio.attributes.title}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              
              
              </Grid>
            </Box>
            
            {portfolios.length > 0 && showDetails && (
              <Box
              onClick={()=>this.setState({showAllPortfolios: !showAllPortfolios})}
              style={{
                borderBottom: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                padding: "10px",
                textAlign: "center",
                marginTop: "30px",
                cursor: 'pointer'
              }}
            >
              <Typography
                style={{
                  color: "#6b6b6b",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                {`${this.props.t("See")}${showAllPortfolios ? `${this.props.t("less")}`: `${this.props.t("all")}`} ${this.props.t("portfolios")}`}
              </Typography>
            </Box>
            )}
          
          </Box>
          </div>
          );
        
      };

      HighlightedContent = (userDetails) => {
        
        const showDetails =  userDetails.status?.data?.follow_status.toLowerCase() === "following" || userDetails.status?.data?.connect_status.toLowerCase() === "connected" 
        
        const highlightedContent = this.state.showAllContent && showDetails
        ? userDetails?.highlighted_contents?.data
        : userDetails?.highlighted_contents?.data.slice(0, 2);
        
          return (
            <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
            <Box id='content'>
              <Box pl={3} pr={3} style={{ padding: "10px 20px 10px" }}>
                <Grid md={12} xs={12}>
                  <Typography
                    style={{
                      color: "#353535",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: 500,
                      marginBottom: 10
                    }}
                  >
                    {this.props.t("Highlighted Content")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  style={{ justifyContent: "space-between" }}
                >
                  {highlightedContent &&
                  highlightedContent.length > 0 && (
                    highlightedContent.map(
                      (highlightedContent) => (
                        <Grid
                          key={highlightedContent.id}
                          item
                          md={6}
                          xs={12}
                          style={{ paddingRight: "10px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              wordBreak: "break-all",
                              paddingBottom: "6px",
                              fontWeight: "500"
                            }}
                          >
                            {highlightedContent.attributes.title || ""}
                          </Typography>
                          <iframe
                           allowfullscreen="allowfullscreen"
                           mozallowfullscreen="mozallowfullscreen" 
                           msallowfullscreen="msallowfullscreen" 
                           oallowfullscreen="oallowfullscreen" 
                           webkitallowfullscreen="webkitallowfullscreen"
                            width="100%"
                            height="200px"
                            src={this.getEmbededUrl(
                              highlightedContent.attributes.video_url
                            )}
                          />
                        </Grid>
                      )
                    )
                  )}
                </Grid>
              </Box>
              {/* ends: portfolio */}
              {highlightedContent.length > 0 && showDetails && (
                  <Box
                    onClick={()=>this.setState({showAllContent: !this.state.showAllContent})}
                    style={{
                      borderBottom: "1px solid #ccc",
                      borderTop: "1px solid #ccc",
                      padding: "10px",
                      textAlign: "center",
                      cursor: 'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color: "#6b6b6b",
                        fontSize: "1.3rem",
                        fontWeight: "bold"
                      }}
                    >
                      {`${this.props.t("See")} ${this.state.showAllContent ? `${this.props.t("less")}`:`${this.props.t("all")}`} ${this.props.t("content")}`}
                    </Typography>
                  </Box>
                )}
            </Box>
            </div>
          );
        
      };

      CaseStudies = (userDetails) => {
        const {showAllCases} = this.state
        
        const showDetails =  userDetails.status?.data?.follow_status.toLowerCase() === "following" || userDetails.status?.data?.connect_status.toLowerCase() === "connected" 
        
        const cases = showAllCases && showDetails
        ? userDetails?.case_studies?.data
        : userDetails?.case_studies?.data.slice(0, 2);
            
        const {classes} = this.props;
        
        return (
          <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
          <Box id='case-study'>
            <Box style={{ padding: "10px 20px 10px" }}>
              <Grid container>
                <Grid md={12} xs={12}>
                  <Typography
                    style={{
                      color: "#353535",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      marginTop: 5,
                      fontWeight: 500,
                      marginBottom: 10
                    }}
                  >
                    {this.props.t("Case Study")}
                  </Typography>
                </Grid>

                {cases.length > 0 && cases.map(caseStudy => (
                    <Grid key={caseStudy.id} onClick={() => {
                      showDetails ? window.location =`/user-profile/${userDetails.id}/casestudy/${caseStudy.id}` as any : null
                  }} md={6} sm={4} xs={12} className={classes.socialMediaCol} style={{paddingLeft:0,paddingRight:15}}>
                    <Box style={{ backgroundColor: "#f2f2f2", padding: "0px 0px 15px 0px" }}>
                      <Avatar
                        src={caseStudy.attributes.case_banner} className={classes.socialMediaImg} />
                      <Typography
                        style={{
                          color: "#6b6b6b",
                          fontSize: "1.3rem",
                          fontWeight: "bold",
                          padding: "10px",
                          textAlign: "center"
                        }}
                      >
                        {caseStudy.attributes.title}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              
              
              </Grid>
            </Box>
            
            {cases.length > 0 && showDetails && (
              <Box
              onClick={()=>this.setState({showAllCases: !showAllCases})}
              style={{
                borderBottom: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                padding: "10px",
                textAlign: "center",
                marginTop: "30px",
                cursor: 'pointer'
              }}
            >
              <Typography
                style={{
                  color: "#6b6b6b",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                {`${this.props.t("See")} ${showAllCases ? `${
this.props.t("less")}`:`${this.props.t("all")}`} ${this.props.t("cases")}`}
              </Typography>
            </Box>
            )}
          </Box>
          </div>
        );
      
      };
      JobHistory = (elements, userDetails) => {
        if (elements.hasOwnProperty("Job History") && elements["Job History"]) {
          const { classes } = this.props;
          return (
            <>
            <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
              <Box pl={3} pr={3} style={{}}>
                <Grid container>
                  <Grid md={12} xs={12}>
                    <Typography
                      style={{
                        color: "#353535",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 30
                      }}
                    >
                      {this.props.t("Job History")}
                    </Typography>
                  </Grid>
                  <Grid md={12} xs={12}>
                    {userDetails.job_histories.data.length > 0 ? (
                      userDetails.job_histories.data.map((jobHistory) => (
                        <Box
                          key={jobHistory.id}
                          className={classes.jobHistoryCard}
                          style={{
                            fontFamily: "Poppins",
                            background: "#f7f7fc",
                            borderRadius: "16px",
                            padding: "20px",
                            display: "flex",
                            width: "100%",
                            marginBottom: "10px"
                          }}
                        >
                          <div>
                            <Avatar
                              src={jobHistory.attributes.company_logo}
                              style={{
                                height: "70px",
                                width: "70px",
                                objectFit: "conatin"
                              }}
                            />
                          </div>
                          <Box style={{ paddingLeft: "25px", width: "80%" }}>
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                color: "#081f32",
                                fontSize: "16px",
                                fontWeight: "600",
                                padding: "5px 0"
                              }}
                            >
                              {jobHistory.attributes.your_role},{" "}
                              {jobHistory.attributes.country}
                            </Typography>
                            <Typography
                              style={{
                                color: "#f14e24",
                                fontWeight: "600",
                                fontFamily: "Poppins",
                                fontSize: "16px"
                              }}
                            >
                              {jobHistory.attributes.company_name} ·
                              <span
                                style={{
                                  color: "#6e798c",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  padding: "5px 0",
                                  fontFamily: "Poppins"
                                }}
                              >
                                {" "}
                                {jobHistory.attributes.city},{" "}
                                {jobHistory.attributes.country}
                                <br />
                                {format(
                                  new Date(jobHistory.attributes.start_date),
                                  "MMM. yyyy"
                                )}{" "}
                                —{" "}
                                {jobHistory.attributes
                                  .i_currently_work_in_this_company
                                  ? `${this.props.t("Present")}`
                                  : format(
                                      new Date(jobHistory.attributes.end_date),
                                      "MMM. yyyy"
                                    )}{" "}
                                ·{" "}
                                {differenceInMonths(
                                  jobHistory.attributes
                                    .i_currently_work_in_this_company
                                    ? new Date()
                                    : new Date(jobHistory.attributes.end_date),
                                  new Date(jobHistory.attributes.start_date)
                                )}{" "}
                                {this.props.t("months")}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography
                        style={{
                          color: "#353535",
                          fontFamily: "Poppins",
                          fontSize: 16,
                          fontWeight: 400
                        }}
                      >
                        {this.props.t("No job histories available.")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
              </div>
              {/* ends: job history */}
              <hr
                style={{
                  borderWidth: "5px",
                  margin: "30px 0"
                }}
              />
            </>
          );
        }
      };

      OpenPositions = (userDetails) => {
        const {showAllPositions} = this.state
        
        const showDetails =  userDetails.status?.data?.follow_status.toLowerCase() === "following" || userDetails.status?.data?.connect_status.toLowerCase() === "connected"
        
        const positions = showAllPositions && showDetails
        ? userDetails?.open_positions
        : userDetails?.open_positions?.slice(0, 2);
            
          return (
            <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
          <Box id='open-positions'>
              <Box pr={3} style={{padding: '10px 20px 10px'}}>
                <Grid container>
                  <Grid md={12} xs={12}>
                    <Typography
                      style={{
                        color: "#353535",
                        fontEeight: "500",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 10
                      }}
                    >
                      {this.props.t("Open Positions")}
                    </Typography>
                  </Grid>
                  <Grid md={6} xs={12} style={{display:"flex",justifyContent:"space-between"}}>
                    {positions.length > 0 && (
                      positions.map((openPosition) => (
                        <Box
                          key={openPosition.id}
                          style={{
                            background: "white",
                            borderRadius: "16px",
                            padding: "20px 20px 20px 0px",
                            display: "flex",
                            width: "100%",
                            marginBottom: "10px",
                            marginLeft:"50px"
                          }}
                        >
                          <div>
                            <Avatar
                              src={userDetails.profile_picture}
                              style={{
                                height: "70px",
                                width: "70px",
                                objectFit: "conatin",
                                borderRadius: 0
                              }}
                            />
                          </div>
                          <Box style={{ paddingLeft: "25px", width: "80%" }}>
                            <Typography
                              style={{
                                color: "#081f32",
                                fontSize: "16px",
                                fontWeight: "600",
                                padding: "5px 0"
                              }}
                            >
                              {openPosition.job_title}, {userDetails.first_name}
                            </Typography>
                            <Typography
                              style={{
                                color: "#f14e24",
                                fontWeight: "600",
                                fontSize: "16px"
                              }}
                            >
                              <span
                                style={{
                                  color: "#6e798c",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  padding: "5px 0"
                                }}
                              >
                                {" "}
                                {openPosition.city}, {openPosition.country}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Box>
              {/* ends: job history */}
              {positions.length > 0 && showDetails && (
              <Box
              onClick={()=>this.setState({showAllPositions: !showAllPositions})}
              style={{
                borderBottom: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                padding: "10px",
                textAlign: "center",
                marginTop: "30px",
                cursor: 'pointer'
              }}
            >
              <Typography
                style={{
                  color: "#6b6b6b",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                {`${this.props.t("See")} ${showAllPositions ? `${this.props.t("less")}`:`${this.props.t("all")}`} ${this.props.t("positions")}`}
              </Typography>
            </Box>
            )}
            </Box>
            </div>
          );
        
      };

      Clients = (userDetails) => {
        const {showAllClients} = this.state
        
        const showDetails =  userDetails.status?.data?.follow_status.toLowerCase() === "following" || userDetails.status?.data?.connect_status.toLowerCase() === "connected"
        
        const clients = showAllClients && showDetails
          ? userDetails?.clients?.data
          : userDetails?.clients?.data.slice(0, 4);
          
          return (
            <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
            <Box id='clients'>
              <Box pl={3} pr={3} style={{padding: "10px 20px 10px" }}>
                <Grid container>
                  <Grid md={12} xs={12}>
                    <Typography
                      style={{
                        color: "#353535",
                        fontEeight: "500",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 30
                      }}
                    >
                      {this.props.t("Clients")}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    {clients.length > 0 && (
                      clients.map((client) => (
                        <Grid
                          key={client.id}
                          item
                          xs={6}
                          md={3}
                          style={{ paddingRight: "10px" }}
                        >
                          <Box
                            mb={2}
                            style={{
                              backgroundColor: "rgb(242, 242, 242)",
                              borderRadius: "12px",
                              textAlign: "center",
                              padding: "30px 30px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center"
                            }}
                          >
                            <Avatar
                              src={client.attributes.client_photo}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%"
                              }}
                            />
                            <Typography
                              style={{
                                color: "#4e4b66",
                                fontSize: "16px",
                                fontWeight: "900",
                                paddingTop: "20px"
                              }}
                            >
                              {client.attributes.client_name}
                            </Typography>
                          </Box>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Box>
              {clients.length > 0 && (
              <Box
              onClick={()=>this.setState({showAllClients: !showAllClients})}
              style={{
                borderBottom: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                padding: "10px",
                textAlign: "center",
                marginTop: "30px",
                cursor: 'pointer'
              }}
            >
              <Typography
                style={{
                  color: "#6b6b6b",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                {`${this.props.t("See")} ${showAllClients ? `${this.props.t("less")}`:`${this.props.t("all")}`} ${this.props.t("clients")}`}
              </Typography>
            </Box>
            )}
            </Box>
            </div>
          );
      };

      BrandAmbassadors = (userDetails) => {
        
        const showDetails =  userDetails.status?.data?.follow_status.toLowerCase() === "following" || userDetails.status?.data?.connect_status.toLowerCase() === "connected"   
        
        const {showAllAmbassadors} = this.state
        
        const Ambassadors = showAllAmbassadors && showDetails
          ? userDetails?.brand_ambassadors?.data
          : userDetails?.brand_ambassadors?.data.slice(0, 4);
          
          return (
            <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
            <Box id='brand-ambassadors'>
              <Box pl={3} pr={3} style={{padding: "10px 20px 10px"}}>
                <Grid container>
                  <Grid md={12} xs={12}>
                    <Typography
                      style={{
                        color: "#353535",
                        fontEeight: "500",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 30
                      }}
                    >
                      {this.props.t("Brand Ambassadors")}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                    // style={{ justifyContent: "space-between" }}
                  >
                    {Ambassadors.length > 0 && (
                      Ambassadors.map((brandAmassador) => brandAmassador.attributes.accounts?.data[0]?.attributes?.status === 'Accepted' && (
                        <Grid
                          key={brandAmassador.id}
                          item
                          xs={6}
                          md={3}
                          style={{ paddingRight: "10px" }}
                        >
                          <Box
                            mb={2}
                            style={{
                              backgroundColor: "rgb(242, 242, 242)",
                              borderRadius: "12px",
                              textAlign: "center",
                              padding: "30px 0 50px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center"
                            }}
                          >
                            <Avatar
                              src={brandAmassador.attributes.brand_ambassadors_image}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%"
                              }}
                            />

                            <Typography
                              style={{
                                color: "#4e4b66",
                                fontSize: "16px",
                                fontWeight: "900",
                                paddingTop: "20px"
                              }}
                            >
                              {brandAmassador.attributes.social_handle}
                            </Typography>
                            
                            <Typography
                                  style={{
                                    color: "#6E798C",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: 10,
                                    marginTop: 10,
                                    marginBottom: 2
                                  }}
                                >
                                  <Typography
                                    style={{
                                      color: "#F14E24",
                                      fontFamily: "Poppins",
                                      fontWeight: 600,
                                      fontSize: 10
                                    }}
                                    component="span"
                                  >
                                    {brandAmassador.attributes.accounts?.data[0]?.attributes?.account?.data?.attributes?.brand?.data?.attributes?.name}
                                  </Typography>{" "}
                                  · {userDetails.first_name}
                                </Typography>
                          </Box>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Box>
              {Ambassadors.length > 0 && showDetails && (
              <Box
              onClick={()=>this.setState({showAllAmbassadors: !showAllAmbassadors})}
              style={{
                borderBottom: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                padding: "10px",
                textAlign: "center",
                marginTop: "30px",
                cursor: 'pointer'
              }}
            >
              <Typography
                style={{
                  color: "#6b6b6b",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                {`${this.props.t("See")} ${showAllAmbassadors ? `${this.props.t("less")}`:`${this.props.t("all")}`} ${this.props.t("ambassadors")}`}
              </Typography>
            </Box>
            )}
            </Box>
            </div>
          );
        
      };
      
      Talents = (userDetails) => {
        
        const showDetails =  userDetails.status?.data?.follow_status.toLowerCase() === "following" || userDetails.status?.data?.connect_status.toLowerCase() === "connected"   
        
        const {showAllTalents} = this.state
        const {t} = this.props
        
        const Talents = showAllTalents && showDetails
          ? userDetails?.talents?.data
          : userDetails?.talents?.data.slice(0, 5);
          
          return (
            <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
            <Box id='talents'>
              <Box pl={3} pr={3} style={{padding: "10px 20px 10px"}}>
                <Grid container>
                  <Grid md={12} xs={12}>
                    <Typography
                      style={{
                        color: "#353535",
                        fontEeight: "500",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 30
                      }}
                    >
                      {t("Talents")}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  >
                    {Talents.length > 0 && (
                      Talents.map((talent) => talent.attributes.accounts?.data[0]?.attributes?.status === 'Accepted' && (
                        <Grid
                          key={talent.id}
                          item
                          xs={6}
                          md={3}
                          style={{ paddingRight: "10px" }}
                        >
                          <Box
                            mb={2}
                            style={{
                              backgroundColor: "rgb(242, 242, 242)",
                              borderRadius: "12px",
                              textAlign: "center",
                              padding: "30px 0 50px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center"
                            }}
                          >
                            <Avatar
                              src={talent.attributes.talent_photo}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%"
                              }}
                            />

                            <Typography
                              style={{
                                color: "#4e4b66",
                                fontSize: "16px",
                                fontWeight: "900",
                                paddingTop: "20px"
                              }}
                            >
                              {talent.attributes.social_handle}
                            </Typography>
                            
                            <Typography
                                  style={{
                                    color: "#6E798C",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: 10,
                                    marginTop: 10,
                                    marginBottom: 2
                                  }}
                                >
                                  <Typography
                                    style={{
                                      color: "#F14E24",
                                      fontFamily: "Poppins",
                                      fontWeight: 600,
                                      fontSize: 10
                                    }}
                                    component="span"
                                  >
                                    {talent.attributes.accounts?.data[0]?.attributes?.account?.data?.attributes?.brand?.data?.attributes?.name}
                                  </Typography>{" "}
                                  · {userDetails.first_name}
                                </Typography>
                          </Box>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Box>
              {Talents.length > 0 && showDetails && (
              <Box
              onClick={()=>this.setState({showAllTalents: !showAllTalents})}
              style={{
                borderBottom: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                padding: "10px",
                textAlign: "center",
                marginTop: "30px",
                cursor: 'pointer'
              }}
            >
              <Typography
                style={{
                  color: "#6b6b6b",
                  fontSize: "1.3rem",
                  fontWeight: "bold"
                }}
              >
                {`${this.props.t("See")} ${showAllTalents ? `${this.props.t("less")}`:`${this.props.t("all")}`} ${this.props.t("talents")}`}
              </Typography>
            </Box>
            )}
            </Box>
            </div>
          );
        
      };
      
      profileAdjustableElements = {
        "highlighted content": {
          title: `${this.props.t("Content")}`,
          redirectTo: "content",
          component:  this.HighlightedContent
        },
        "portfolio":{
          title: `${this.props.t("Portfolio")}`,
          redirectTo: "portfolios",
          component:  this.Portfolios
        },
        "listings":{
          title: `${this.props.t("Listings")}`,
          redirectTo: "listings",
          component:  this.Listings
        },
        "case studies":{
          title: `${this.props.t("Cases")}`,
          redirectTo: "case-study",
          component:  this.CaseStudies
        },
        "clients": {
          title: `${this.props.t("Clients")}`,
          redirectTo: "clients",
          component:  this.Clients
        },
        "open positions": {
          title: `${this.props.t("Positions")}`,
          redirectTo: "open-positions",
          component:  this.OpenPositions
        },
        "brand ambassadors": {
            title: `${this.props.t("Ambassadors")}`,
            redirectTo: "brand-ambassadors",
            component:  this.BrandAmbassadors
        },
        "talent": {
          title: `${this.props.t("Talents")}`,
          redirectTo: "talents",
          component:  this.Talents
      }
      };
      
      render() {
        
        const { userDetails, showAllAbout, adjustableElements } = this.state;
        const { classes, t, i18n } = this.props;
        let socialProfiles = [];
        const hash = window.location.hash
        
        if(!userDetails.brand?.data?.attributes?.user_type){
          return null;
        }
          
        let about = ''
        
        const showDetails =  userDetails.status?.data?.follow_status.toLowerCase() === "following" || userDetails.status?.data?.connect_status.toLowerCase() === "connected"
            
        if (userDetails?.about){
          about = showAllAbout && showDetails
          ? userDetails?.about
          : userDetails?.about?.length > 300
          ? userDetails?.about?.substr(0, 300).trim() + "..." 
          : userDetails?.about
        }

        if (
          userDetails.bx_block_social_profiles &&
          userDetails.bx_block_social_profiles.data.length > 0
        ) {
          socialProfiles = userDetails.bx_block_social_profiles.data.map(
            (profile) => {
              return {
                icon: Assets[profile.attributes.provider + "Icon"],
                count: profile.attributes?.audiences[0]?.follower_count || 0
              };
            }
          );
        }

        const userType = userDetails.brand?.data?.attributes?.user_type;
        
        const userId = localStorage.getItem("userId");
        const userToken = localStorage.getItem('userToken');
        
        
        if (adjustableElements && adjustableElements.length > 0) {
          for (let i = 0; i < adjustableElements.length; i++) {
            const element = this.profileAdjustableElements[adjustableElements[i].name.toLowerCase()];
            adjustableElements[i] = {
              ...adjustableElements[i],
              visible: element ? adjustableElements[i].visible : false,
              title: element ? element.title : adjustableElements[i].name,
              redirect_to: element ? element.redirectTo : adjustableElements[i].redirect_to, 
              component: element ? element.component : () => null 
            }
          }
        }

        return (
          <>
            <ProfileHeader heading={t("User Profile")} subModule={this.state.subModule} showProfile />
            <Grid className={classes.mainWrapper}>
              <Grid container>
                  <Grid item xs={12} md={2} style={{ position: "relative" }}>
                  { userToken &&
                    <SideMenu showProfile />
                  }
                  </Grid>

                <Grid xs={12} md={6}
                  style={{
                    //border: "1px solid #DEDDDA",
                    borderTop: "none",
                    height: "100%"
                  }}
                >
                  <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
            
                  <Box
                    style={{
                      height: 64,
                      //borderBottom: "1px solid #DEDDDA",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    
                    <Typography
                      style={{
                        color: "#081F32",
                        fontFamily: "Poppins",
                        fontWeight: 700,
                        fontSize: 18
                      }}
                    >
                      <IconButton
                      disableRipple={true}
                      style={{ marginLeft: 10, padding: 0, backgroundColor:'transparent' }}
                      onClick={() => this.props.navigation.goBack()}
                    >
                      <img
                        src={backIcon}
                        style={{ height: "16px", marginRight: "10px" }}
                      />
                    </IconButton>
                    
                      {userDetails.first_name}
                    </Typography>
                  </Box>

                  <Box style={{ position: "relative" }}>
                    <Avatar
                      src={userDetails.profile_cover}
                      style={{ width: "100%", borderRadius: 0, height: 120 }}
                    />
                    <Avatar
                      src={userDetails.profile_picture}
                      style={{
                        // width: "100px",
                        width: 80,
                        height: 80,
                        position: "absolute",
                        left: "17px",
                        bottom: "-45px"
                      }}
                    />
                  </Box>

                  <Box style={{ padding: "60px 20px 15px", position: "relative" }}>
                    {userId === userDetails.id && (
                        <Button
                        onClick={() => (window.location = "/settings/edit-account")}
                        variant="contained"
                        style={{
                          backgroundColor: "#fff",
                          border: "1px solid #F14E24",
                          borderRadius: 16,
                          height: 35,
                          marginBottom: 14,
                          boxShadow: "none",
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontSize: 13,
                          marginTop: 20,
                          position: "absolute",
                          top: "0px",
                          right: "10px"
                        }}
                      >
                        {this.props.t("Edit Profile")}
                      </Button>
                    )}
                  
                    <Typography
                      style={{
                        color: "#081F32",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        fontWeight: "bold"
                      }}
                    >
                      {userDetails.first_name}{" "}
                      {userDetails?.verified && (
                        <img src={verify} style={{ height: 13, marginLeft: "5px" }} />
                      )}
                    </Typography>
                    <Typography
                      style={{
                        color: "#9e9e9e",
                        fontFamily: "Poppins",
                        fontSize: 13
                      }}
                    >
                      @{userDetails.user_name}
                    </Typography>

                    <Typography
                      style={{
                        color: "#353535",
                        fontFamily: "Poppins",
                        fontSize: 12,
                        marginTop: "6px"
                      }}
                    >
                      {userDetails.about}
                    </Typography>
                    <Typography
                      style={{
                        color: "#9e9e9e",
                        fontFamily: "Poppins",
                        fontSize: 12,
                        marginTop: 5
                      }}
                    >
                      {userDetails?.brand?.data?.attributes.name} -{" "}
                      {userDetails?.profile_country_name || userDetails?.profile_country_code}
                    </Typography>
                    {userId !== userDetails.id && userToken && (
                        <Box style={{ display: "flex" }}>
                          
                          
                          {userDetails.status?.data?.follow_status.toLowerCase() === "following" ? (
                            <Button
                            onClick={() => this.unfollowAccount(userDetails.id)}
                              variant="contained"
                              type="submit"
                              style={{
                                backgroundColor: "#F14E24",
                                border: "1px solid #D9DBE9",
                                borderRadius: 16,
                                height: 35,
                                boxShadow: "none",
                                color: "#F7F7FC",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                marginTop: "10px",
                                marginRight: "10px"
                              }}
                            >
                              {this.props.t("Unfollow")}
                            </Button>
                          ) : (
                            <Button
                            onClick={() => this.followAccount(userDetails.id)}
                            variant="contained"
                            type="submit"
                            style={{
                              backgroundColor: "#F14E24",
                              border: "1px solid #D9DBE9",
                              borderRadius: 16,
                              height: 35,
                              boxShadow: "none",
                              color: "#F7F7FC",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop: "10px",
                              marginRight: "10px"
                            }}
                          >
                            {t(userDetails.status?.data?.follow_status || '') }
                          </Button>
                          )}
        
                        <Button
                        onClick={() => {
                          if(userDetails.status?.data?.connect_status.toLowerCase() === 'connected'){
                            this.disconnectAccount(userDetails.id)  
                          }else{
                            this.connectAccount(userDetails.id)
                          }
                        }}
                        disabled={
                          userDetails.status?.data?.connect_status && !userDetails.status?.data?.connect_status.toLowerCase().includes('connect')
                        }
                          variant="contained"
                          type="submit"
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #F14E24",
                            borderRadius: 16,
                            height: 35,
                            boxShadow: "none",
                            color: "#F14E24",
                            fontFamily: "Poppins",
                            fontSize: 13,
                            marginTop: "10px"
                          }}
                        >
                          {t(userDetails.status?.data?.connect_status.toLowerCase() === 'connected' ? 'Disconnect' : userDetails.status?.data?.connect_status || '')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.userProfileUl}>
                    <Tabs indicatorColor="primary" textColor="primary">
                      <Tab
                      onClick={()=>window.location.href = '#'}
                        label={this.props.t("Home")}
                        style={hash ? {
                          minWidth: "20%",
                          fontSize: "1.1rem",
                          fontWeight: "bold"
                        } : {
                          minWidth: "20%",
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          color: '#F14E24',
                          borderBottom: '1px solid #F14E24'
                        }}
                      />
                      <Tab
                        onClick={()=>window.location.href = '#about'}
                        label={this.props.t("About")}
                        style={ hash === '#about' ? {
                          minWidth: "20%",
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          color: '#F14E24',
                          borderBottom: '1px solid #F14E24'
                        } : {
                          minWidth: "20%",
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                        }}
                      />
                      
                      {userType && adjustableElements.map((element,index)=>{
                        if (element.visible) {
                          return (
                            <Tab
                              key={element.id}
                              onClick={()=>window.location.href = '#' + element.redirect_to}
                                label={t(element.title)}
                                style={ hash === '#' + element.redirect_to ? {
                                  minWidth: "20%",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                  color: '#F14E24', 
                                  borderBottom: '1px solid #F14E24'
                                }:{
                                  minWidth: "20%",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                  
                                }}
                            />
                          )
                        }
                      })}
                    </Tabs>
                  </Box>
                  </div>
                  <div style={{border:"1px solid #DEDDDA",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",marginBottom:"20px"}}>
                  <Box id='about' >
                    <Box style={{ padding: "10px 20px 10px" }}>
                      <Typography
                        style={{
                          color: "#353535",
                          fontFamily: "Poppins",
                          fontSize: 16,
                          marginTop: 5,
                          fontWeight: 500,
                          marginBottom: 10
                        }}
                      >
                        {this.props.t("About")}
                      </Typography>

                      <Typography
                        style={{
                          color: "#6b6b6b",
                          fontFamily: "Poppins",
                          fontSize: 12,
                          marginBottom: 10,
                        }}
                      >
                        {about}
                      </Typography>
                    </Box>
                {about.length > 0 && showDetails && (
                    <Box
                    onClick={()=>this.setState({showAllAbout: !showAllAbout})}
                    style={{
                      borderBottom: "1px solid #ccc",
                      borderTop: "1px solid #ccc",
                      padding: "10px",
                      textAlign: "center",
                      cursor: 'pointer'
                    }}
                  >
                    <Typography
                      style={{
                        color: "#6b6b6b",
                        fontSize: "1.3rem",
                        fontWeight: "bold"
                      }}
                    >
                      {`${this.props.t("See")} ${showAllAbout ? `${this.props.t("less")}`:`${this.props.t("all")}`} ${this.props.t("details")}`}
                    </Typography>
                  </Box>
                )}
                  
                  </Box>
                  </div>
                  {userType && adjustableElements.map((element)=>(
                    <Fragment key={element.id}>{element.visible && element.component(userDetails)}</Fragment>
                  ))}
                
                </Grid>
                <Grid sm={12} md={4}>
                  <Box style={{ padding: "25px" }}>
                    {!userToken && (
                          <Box style={{ border: "1px solid #ccc", borderRadius: "20px" , marginBottom: "15px"}}>
                          <Typography
                            style={{
                              fontSize: "1.5rem",
                              padding: "22px",
                              color: '#000000',
                              fontWeight: 600,
                              fontFamily: 'Poppins'
                            }}
                          >
                            {this.props.t("New to yup?")}
                          </Typography>
                          
                          <Typography
                            style={{
                              fontSize: "1.5rem",
                              paddingLeft: "22px",
                              color: '#8E8E8E',
                              fontWeight: 400,
                              fontFamily: 'Manrope',
                              marginBottom: 30
                            }}
                          >
                            {this.props.t("Sign up now to get your own personalized timeline!")}
                          </Typography>
                          
                          <Box style={{display:'flex',flexDirection:'column', paddingLeft:22, paddingRight:22}} >
                          <Button
                    onClick={(e) => (window.location = '/claim-username')}
                    variant="contained"
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D1D1D1",
                      borderRadius: 16,
                      width: '100%',
                      height: 48,
                      marginBottom: 6,
                      boxShadow: "none"
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Box display="flex" width="25%">
                        <img src={googleImg} style={{ width: 26, height: "auto" }} />
                      </Box>
                      <Box display="flex" width="75%" alignItems="center">
                        <Typography
                          style={{
                            color: "#000000",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 16
                          }}
                        >
                          {this.props.t("Sign up with Google")}
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
                  <Button
                    onClick={(e) => (window.location = '/claim-username')}
                    variant="contained"
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D9DBE9",
                      borderRadius: 16,
                      width: '100%',
                      height: 48,
                      marginBottom: 6,
                      boxShadow: "none"
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Box display="flex" width="25%">
                        <img src={mailIcon} style={{ width: 26, height: "auto" }} />
                      </Box>
                      <Box display="flex" width="75%">
                        <Typography
                          style={{
                            color: "#000000",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 16
                          }}
                        >
                          {this.props.t("Sign up with Email")}
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
        
                  <Button
                    onClick={(e) => (window.location = '/claim-username')}
                    variant="contained"
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #D9DBE9",
                      borderRadius: 16,
                      width: '100%',
                      height: 48,
                      marginBottom: 6,
                      boxShadow: "none"
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Box display="flex" width="25%">
                        <img src={phoneIcon} style={{ width: 26, height: "auto" }} />
                      </Box>
                      <Box display="flex" width="75%">
                        <Typography
                          style={{
                            color: "#000000",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 16
                          }}
                        >
                          {this.props.t("Sign up with Phone")}
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
                          </Box>
                          
                          <Typography
                            style={{
                              fontSize: "1.5rem",
                              paddingLeft: "22px",
                              color: '#8E8E8E',
                              fontWeight: 400,
                              fontFamily: 'Manrope',
                              marginTop: 10,
                              marginBottom: 20
                            }}
                          >
                            {this.props.t("By signing up, you agree to the Terms of Service and Privacy Policy, including Cookie Use.")}
                          </Typography>
                        </Box>
                    )}
                    
                    {userDetails.brand.data?.attributes?.user_type !== 'Team' && socialProfiles.length > 0 && (
                      <Box
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "20px",
                        marginBottom: "10px"
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "1.5rem",
                          padding: "12px",
                          borderBottom: "1px solid #ccc"
                        }}
                      >
                        {this.props.t("Social Media")}
                      </Typography>
                      <Box
                        style={{
                          padding: "20px",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        {socialProfiles.map((profile, index) => (
                          <Chip
                            key={index}
                            avatar={<Avatar alt={profile.icon} src={profile.icon} />}
                            label={profile.count}
                            style={{
                              backgroundColor: "#f3f3f3",
                              padding: "25px 10px",
                              fontSize: "1.1rem",
                              marginBottom: "10px",
                              marginRight: "10px"
                            }}
                          />
                        ))}
    
    
                      </Box>
                    </Box>
                    )}      
                    {/* <Grid xs={12} md={4}> */}
                    {
                      userDetails.brand.data?.attributes?.user_type === 'Team'  && userDetails?.company_informations?.data[0]?.attributes? 
                      <Box >
                      <Box
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "20px",
                          marginBottom: "10px"
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "1.5rem",
                            padding: "12px",
                            borderBottom: "1px solid #ccc"
                          }}
                        >
                          {this.props.t("Information")}
                        </Typography>
                        <Box
                          style={{
                            padding: "20px"
                          }}
                        >
                          <Typography variant="h5">{this.props.t("Founded")}</Typography>
                          <Typography variant="button" style={{ fontSize: "12px" }}>
                            { userDetails?.company_informations?.data[0]?.attributes?.foundation_date || '-'}
                          </Typography>
                          <Typography variant="h5" style={{ paddingTop: "10px" }}>
                            {this.props.t("Company size")}
                          </Typography>
                          <Typography
                            variant="button"
                            style={{ fontSize: "12px", textTransform: "lowercase" }}
                          >
                            { userDetails?.company_informations?.data[0]?.attributes?.company_size || '-'} {this.props.t("employees")}
                          </Typography>
                          <Typography variant="h5" style={{ paddingTop: "10px" }}>
                            {this.props.t("Website")}
                          </Typography>
                          <Typography
                            variant="button"
                            style={{ fontSize: "12px", textTransform: "lowercase" }}
                          >
                          <a href={userDetails?.company_informations?.data[0]?.attributes?.website || '#'} target="_blank">{ userDetails?.company_informations?.data[0]?.attributes?.website || '-'}</a> 
                          </Typography>
                          <Typography variant="h5" style={{ paddingTop: "10px" }}>
                            {this.props.t("Social Media")}
                          </Typography>
                          {
                            userDetails?.company_informations?.data[0]?.attributes?.facebook_field && 
                              <a href={ userDetails?.company_informations?.data[0]?.attributes?.facebook_field} target="_blank">
                                <img
                              src={facebookImg}
                              alt=""
                              style={{
                                width: "18px",
                                paddingTop: "10px",
                                marginRight: "32px"
                              }}
                            />
                              </a>
                          }
                            {
                            userDetails?.company_informations?.data[0]?.attributes?.linkedin_field && 
                            <a href={ userDetails?.company_informations?.data[0]?.attributes?.linkedin_field} target="_blank">
                                <img
                              src={linkedinImg}
                              alt=""
                              style={{
                                width: "18px",
                                paddingTop: "10px",
                                marginRight: "32px"
                              }}
                            />
                            </a>
                            }
                            {
                              userDetails?.company_informations?.data[0]?.attributes?.instagram_field && 
                              <a href={ userDetails?.company_informations?.data[0]?.attributes?.instagram_field} target="_blank">
                                  <img
                                src={instagramImg}
                                alt=""
                                style={{
                                  width: "18px",
                                  paddingTop: "10px",
                                  marginRight: "32px"
                                }}
                              />
                              </a>                        
                            }
                            {
                              userDetails?.company_informations?.data[0]?.attributes?.twitter_field && 
                              <a href={ userDetails?.company_informations?.data[0]?.attributes?.twitter_field} target="_blank">
                                <img
                              src={twitterImg}
                              alt=""
                              style={{
                                width: "18px",
                                paddingTop: "10px",
                                marginRight: "32px"
                              }}
                            />
                              </a>
                            }
                        </Box>
                      </Box>
                    </Box>
                      :null
                    }   
                    {userDetails.brand.data?.attributes?.user_type !== 'Team' && userDetails?.skills?.data && userDetails?.skills?.data.length > 0 && (
                        <Box style={{ border: "1px solid #ccc", borderRadius: "20px" }}>
                        <Typography
                          style={{
                            fontSize: "1.5rem",
                            padding: "12px",
                            borderBottom: "1px solid #ccc"
                          }}
                        >
                          {this.props.t("Skills")}
                        </Typography>
                        <Box
                          style={{
                            padding: "20px",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          {userDetails?.skills?.data && userDetails.skills.data.map(skill => (
                            <Chip
                              key={skill.id}
                              label={skill.attributes.name}
                              style={{
                                backgroundColor: "#f3f3f3",
                                padding: "25px 10px",
                                fontSize: "1.1rem",
                                marginBottom: "10px",
                                marginRight: "10px"
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}   
                  </Box>
                </Grid>
              
              </Grid>
            </Grid>
            <Snackbar
              open={Boolean(this.state.error)}
              onClose={() => this.setState({ error: null })}
              message={this.state.error || "N/A"}
              severity="error"
            />
            <Loader loading={this.state.loading} />
            <Modal
      open={this.state.open}
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
      onClose={this.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
    <div>Hello</div>
            
          
    </Modal>
          </>
        );
      }

      componentDidMount() {
        const langauge = localStorage.getItem("langauge")
        this.props.i18n.changeLanguage(langauge);
        const userToken = localStorage.getItem('userToken');
        if(userToken && window.location.pathname.includes('user')) {
          this.getUserInformation();
        }else {
          const path = window.location.pathname;
          const directories = path.split("/");
          const lastDirecotry = directories[(directories.length - 1)];
          this.getUserdataWithoutToken(lastDirecotry);
        }
      }
    } 
    const legendStyles = () => ({
      root: {
        display: "flex",
        margin: "auto",
        flexDirection: "row",
        flexWrap: "wrap"
      }
    });
    const legendLabelStyles = () => ({
      label: {
        whiteSpace: "wrap"
      }
    });

    const LegendRootBase = ({ classes, ...restProps }) => (
      <Legend.Root {...restProps} className={classes.root} />
    );
    const LegendRoot = withStyles(legendStyles, { name: "LegendRoot" })(
      LegendRootBase
    );

    const LegendLabelBase = ({ classes, ...restProps }) => (
      <Legend.Label className={classes.label} {...restProps} />
    );
    const LegendLabel = withStyles(legendLabelStyles, { name: "LegendLabel" })(
      LegendLabelBase
    );

    const userProfileStyle = {

      mainWrapper:
      {
        height: "100%",
        paddingBottom: 50,
        backgroundColor: "#FFFFFF",
        "@media (max-width: 768px)": {
        },
      },
      mainBodyContent:
      {
        height: "100vh",
        marginTop: 14,
        border: "#80808042 1px solid",
        padding: 25,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        "@media (max-width: 768px)": {
          padding: 10,
        },
      },
      userProfileUl:
      {
        borderBottom: "1px solid #ccc",
        borderTop: "1px solid #ccc",
        display: "flex",
        width: "100%",
        "@media (max-width: 767px)": {
          overflow: "scroll",
        },
        "& .MuiTabs-root":
        {
          width: "100%",
          justifyContent: "space-between",
        },
        "& .MuiTabs-flexContainer":
        {
          display: "flex",
          width: "100%",
          "@media (max-width: 768px)": {
            overflow: "scroll",
          }
        }
      },
      socialMediaImg:
      {
        width: "100%",
        height: "200px",
        borderRadius: "0px",
      },
      socialMediaCol:
      {

        paddingLeft: "15px",
        marginTop: "15px",
        "@media (max-width: 767px)": {
          paddingLeft: "0",
        }
      },
      HighlightedTitle:
      {
        color: "#424242",
        fontFamily: "Poppins",
        fontSize: 12,
        marginBottom: 10,
        paddingLeft: "15px",
        "@media (max-width: 767px)": {
          paddingLeft: "0",
        }
      }
    }
    export default withTranslation()(withStyles(userProfileStyle)(UserProfile))
