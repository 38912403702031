//@ts-ignore
//@ts-nocheck
import React from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import { Box, Link, Typography, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { logo, profileIcon, starImage } from "./assets";
import { withTranslation } from 'react-i18next';
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { withStyles } from "@material-ui/core/styles";
import Copyright from "../../../components/src/Copyright";

class UserNameRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, t, i18n } = this.props;
    
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
        height="110%"
        style={
          {
            // overflowX: "hidden"
          }
        }
      >
        <Box className={classes.claim}>
          <Typography
            style={{
              fontSize: 26,
              color: "#14142B",
              fontFamily: "Poppins",
              fontWeight: 800,
              paddingTop:"10px" 
            }}
          >
            {t("Claim your username")}
          </Typography>
          <img
            src={starImage}
            className={classes.backgroundImg}
            style={{ position: "absolute", height: 100, width: "100%"}}
          />
        </Box>

        <Formik
          initialValues={{
            userName: ""
          }}
          validationSchema={this.UserNameSchema(t)}
          validateOnMount={true}
          onSubmit={(values) => {
            this.createUserName(values);
          }}
        >
          {({ errors, touched }) => (
            <Form translate="yes" className={classes.form}>
              <Box display="flex" flexDirection="column">
                <Box
                  className={classes.InputUsername}
                  display="flex"
                  overflow="hidden"
                  alignItems="center"
                  height="56px"
                  border="1px solid #D1D1D1"
                  borderRadius="16px"
                  bgcolor="white"
                >
                  <Field
                    name="userName"
                    placeholder={t("Username")}
                    style={{
                      border: "none",
                      height: "100%",
                      width: "80%",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 16,
                      marginRight: 10,
                      marginLeft: 21,
                      outline: "none"
                    }}
                  />
                  <img
                    src={profileIcon}
                    style={{ width: 24, height: 24, marginRight: 16 }}
                  />
                </Box>
                {errors.userName && touched.userName ? (
                  <Typography
                    style={{
                      color: "#F14E24",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: 14,
                      marginTop: 5,
                      marginLeft: 10
                    }}
                  >
                    {errors.userName}
                  </Typography>
                ) : null}
                {this.state.error ? (
                  <Typography
                    style={{
                      color: "#F14E24",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: 14,
                      marginTop: 5,
                      marginLeft: 10
                    }}
                  >
                    {this.state.error}
                  </Typography>
                ) : null}

                <Button
                  className={classes.Continue}
                  variant="contained"
                  type="submit"
                  style={{
                    backgroundColor: "#F14E24",
                    border: "1px solid #D9DBE9",
                    borderRadius: 16,
                    height: 54,
                    marginBottom: 14,
                    boxShadow: "none",
                    color: "#F7F7FC",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: 16,
                    marginTop: 30
                  }}
                >
                  {t("Continue")}
                </Button>

                <Box
                  display="flex"
                  mt="25px"
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <Typography
                    style={{
                      color: "#A0A3BD",
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: 12,
                      textAlign: "center"
                    }}
                  >
                    {t("Already have an account")}
                  </Typography>
                  <Link
                    href="/login"
                    underline="none"
                    style={{
                      fontSize: 14,
                      color: "#F14E24",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      marginLeft: 5,
                      textTransform: "uppercase"
                    }}
                  >
                    {t("Sign in")}
                  </Link>
                </Box>

                <Box
                  display="flex"
                  mt="25px"
                  alignItems="flex-start"
                  className={classes.BySigningUp}
                >
                  <Typography
                    style={{
                      color: "#A0A3BD",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      fontWeight: "normal",
                      fontSize: 12
                    }}
                  >
                    {t("By signing up, I accept and I agree to the")}{" "}
                 
                    <Link
                        target="_blank"
                        href="/user-agreement"
                        underline="none"
                        style={{ color: "#F14E24" }}
                      >
                        {t("Terms of Service")}
                      </Link>{" "}
                   
                    {/* , {t("including the")} */}
                     {" "}
                    <Link
                      target="_blank"
                      href="/privacy-policy"
                      underline="none"
                      style={{ color: "#F14E24" }}
                    >
                      {t("Privacy Policy")}
                    </Link>
                    .
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" pt="22px">
                  <img style={{ width: 96, height: "auto" }} src={logo} />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>

        <Box mt="100px" mb="100px">
        <Copyright />
        </Box>
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    const data = localStorage.getItem("_oldAdjustableElements");
    // window.localStorage.clear();
    localStorage.removeItem("userToken")
    localStorage.removeItem("userId")
    localStorage.removeItem("userNameToken")
    localStorage.removeItem("adjustableElements")
    localStorage.removeItem("googleToken")

    localStorage.setItem("_oldAdjustableElements", data);
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});
const UserNameRegistrationStyles = {
  claim: {
    display: "flex",
    marginTop: "12rem",
    marginBottom: "27px",
    width: "363px",
    justifyContent: "center",
    "@media (max-width: 767px)": {
      marginTop: "3rem",
      width: "100%"
    }
  },
  form: {
    width: "363px",
    "@media (max-width: 767px)": {
      width: "100%"
    }
  },
  InputUsername: {
    "@media (max-width: 767px)": {
      marginLeft: "10px",
      marginRight: "10px"
    }
  },
  Continue: {
    "@media (max-width: 767px)": {
      marginLeft: "10px",
      marginRight: "10px"
    }
  },
  BySigningUp: {
    "@media (width: 540px)": {
      justifyContent: "center"
    }
  },
  backgroundImg:{
    "@media (max-width: 767px)":{
      height:"45px !important",
    }
  }
};

export default withTranslation()(withStyles(UserNameRegistrationStyles)(
  withRouter(UserNameRegistration as React.ComponentType<any>)
))
