// @ts-nocheck
// @ts-ignore
import React from "react";
import { StyleSheet, Platform } from "react-native";
import { Grid, Box, Typography, IconButton, Button,LinearProgress,Avatar } from "@material-ui/core";
import { withRouter } from "react-router";

import { backIcon, logo, noNotification, avatar } from "./assets"; 
import SideMenu from "../../../blocks/user-profile-basic/src/SideMenu.web";
import NotificationsController, { Props } from "./NotificationsController";
import ProfileHeader from "../../../blocks/user-profile-basic/src/ProfileHeader";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroller';


import { Link } from "react-router-dom";


import Loader from "../../../components/src/Loader.web";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 7,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: "white"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#E45F3A"
  }
}))(LinearProgress);
import { withTranslation } from 'react-i18next';


class NotificationList extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  displaytime(time){
    let date=new Date(time)

    let d=date.getHours();
    let m=date.getMinutes();
    return `${d}:${m<9 ? `0`+m : m}  (${moment(time).format("DD MMM YYYY")})`

  } 

  render() {
    const { navigation, history,t,i18n } = this.props;
    const { notifications, totalTasks, completedTasks } = this.state;

    return (
      <>
        <ProfileHeader heading={t("Notification")} subModule="3" showProfile />
        <Grid classes={styles.mainWrapper}>
          <Grid container>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid
            item
              xs={12}
              md={6}
              style={{
                border: "1px solid #DEDDDA",
                borderTop: "none",
                height: "100%"
              }}
            >
            
              {notifications.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingTop: "10rem"
                  }}
                >
                  <img src={noNotification} style={{ width: "22rem" }} />
                  <p
                    style={{
                      margin: "1rem",
                      color: "rgba(78, 75, 102, 1)",
                      fontFamily: "Poppins",
                      fontWeight: "normal"
                    }}
                  >
                    {t("Uh- oh, looks like you don’t have notifications yet.")}
                  </p>
                  <button
                    style={{
                      backgroundColor: "#F14E24",
                      border: "1px solid #D9DBE9",
                      borderRadius: 36,
                      height: 45,
                      marginBottom: 14,
                      boxShadow: "none",
                      color: "#F7F7FC",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: 16,
                      marginTop: 15,
                      width: "20rem"
                    }}
                    onClick={() => history.push("/discovery")}
                  > 
                    {t("Explore")}
                  </button>
                </div>
              ) : null
              }
              <Typography
                style={{
                  color: "#081F32",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: 15,
                  marginTop:'10px'
                }}
              />
                <div style={{height:700,overflow:"auto"}}>

<InfiniteScroll
pageStart={1}
loadMore={this.loadFunc}
hasMore={this.state.hasMore}
useWindow={false}
>




              {notifications.map((notification,i) => (

                  notification.attributes.action_needed ? (

                  <Box
                  key={i}
                  display="flex"
                  flexDirection="flex-start"
                  style={{
                    width: "97%",
                    height: "auto",
                    padding: 10,
                    border: "1px solid #EBEEF0",
                    borderRadius: 10,
                    marginLeft: 6,
                    justifyContent: "space-between",
                    marginBottom: 10
                  }}
                >
                  <Box style={{ display: "flex" }}  key={i}>
                    <Box style={{ marginRight: "1rem" }}  key={i}>
                      {
                        notification.attributes.guest.data.attributes.profile_picture ?
                        <img src={notification.attributes.guest.data.attributes.profile_picture} width="40" height="40" style={{borderRadius:10}}/>
                        :
          <Avatar style={{borderRadius:10}} />
                      }
                     
                    </Box>
                    <Box  key={i}>
                      <Typography
                        style={{
                          color: "#081F32",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14
                        }}
                      >
                        {notification.attributes.headings}
                      </Typography>
                      <Typography
                        style={{
                          color: "#B0B3B3",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 12
                        }}
                      >
                        {notification.attributes.contents}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>

                        {

                        }

                  <button onClick={()=>this.decline(notification)} style={{marginRight:'2rem',  backgroundColor: 'rgb(241, 78, 36)',
    border: '1px solid rgb(217, 219, 233)',
    borderRadius: '16px',
    height: 35,
    boxShadow: 'none',
    color:' rgb(247, 247, 252)',
    fontFamily: 'Poppins',
    fontSize: 13,
    marginTop: 10,
    marginRight: 10}}>
                     {t("Decline")}
                    </button>
                    <button onClick={()=>this.accept(notification)} style={{  backgroundColor: '#ffff',
    border: '1px solid red',
    borderRadius: '16px',
    height: 35,
    boxShadow: 'none',
    color:' red',
    fontFamily: 'Poppins',
    fontSize: 13,
    marginTop: 10,
    marginRight: 10}}>
                      {t("Accept")}
                    </button>
                 
                  </Box>
                </Box>

                )
                :
                (

                <Box
                key={i}
                  display="flex"
                  flexDirection="flex-start"
                  style={{
                    width: "97%",
                    height: "auto",
                    padding: 10,
                    border: "1px solid #EBEEF0",
                    borderRadius: 10,
                    marginLeft: 6,
                    justifyContent: "space-between",
                    marginBottom: 10
                  }}
                >
                  <Box style={{ display: "flex" }}  key={i}>
                    <Box style={{ marginRight: "1rem" }}  key={i}>
                    {
                        notification?.attributes?.guest?.data?.attributes?.profile_picture ?
                        <img src={notification?.attributes?.guest?.data?.attributes?.profile_picture} width="40" height="40" style={{borderRadius:10}}/>
                        :
          <Avatar style={{borderRadius:10}} />
                      }
                    </Box>
                    <Box>
                      <Typography
                        style={{
                          color: "#081F32",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14
                        }}
                      >
                        {notification.attributes.headings}
                      </Typography>
                      <Typography
                        style={{
                          color: "#B0B3B3",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 12
                        }}
                      >
                        {notification.attributes.contents}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        color: "#367474",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: 12
                      }}
                    >
                      {this.displaytime(notification.attributes.created_at)}
                    </Typography>
                    <Typography
                      style={{
                        color: "#B0B3B3",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: 20,
                        lineHeight: 1,
                        textAlign:'right'
                      }}
                    >
                      
                    </Typography>
                  </Box>
                </Box>
                )
              ))}
</InfiniteScroll>
</div>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Link
                  to="/profile-dashboard"
                  style={{
                    textDecoration: "none"
                  }}
                >
                  <Box style={{ padding: "30px" }}>
                    <Box
                      style={{
                        background:
                          "linear-gradient(to right, #03A1A4 0%, #99E7D1 100%)",
                        padding: "15px",
                        borderRadius: "15px"
                      }} 
                    >
                      <Typography
                        style={{
                          color: "#ffffff",
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          fontSize: 14,
                          paddingBottom: "10px"
                        }}
                      >
                        {t("Complete your profile today")}
                      </Typography>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          totalTasks > 0
                            ? Math.ceil((completedTasks / totalTasks) * 100)
                            : 0
                        }
                      />

                      <Typography
                        style={{
                          color: "#ffffff",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 12,
                          paddingTop: "10px"
                        }}
                      >
                        {completedTasks}/{totalTasks} {t("done")}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            
          </Grid>
        </Grid>
        <Loader loading={this.state.loading} />


       
      </>
    );
  }

  async componentDidMount() {
    this.fetchCompletedProfileDetails();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
const styles = StyleSheet.create({
  mainWrapper: {
    height: "100%",
    paddingBottom: `50px`,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
    }
  },
  mainBodyContent: {
    height: "100vh",
    marginTop: 14,
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10
    }
  },
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  title: {
    marginBottom: 2,
    fontSize: 14,
    textAlign: "left",
    marginTop: 16
  },

  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgDummyPassword: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    opacity: 0.4,
    fontWeight: "bold",
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 0,
    padding: 12,
    marginTop: 0
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    marginTop: 0,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  helperText: { marginTop: 10 }
});
// Customizable Area End

export default withTranslation()(withRouter(NotificationList as React.ComponentType<any>));
