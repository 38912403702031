import _defineProperty from "/builds/clientprojects/assembler_projects/prd/yuppgg-68443-react-native/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _yupImg, _yupImg2, _landingPageStyle;

import { workSpaceImage } from "../src/assets";
export var landingPageStyle = (_landingPageStyle = {
  workSpaceBody: {
    background: 'url(' + workSpaceImage + ')',

    /* text-align: center */

    /* background-size: cover */

    /* background: no-repeat */
    backgroundRepeatX: "no-repeat",
    height: "659px !important",
    backgroundRepeat: "round",

    /* width: revert */
    backgroundSize: "100%",
    "@media (max-width: 576px)": {
      marginTop: "20%",
      backgroundRepeat: "no-repeat"
    },
    "@media (max-width: 768px)": {
      backgroundRepeat: "no-repeat"
    }
  },
  mainContanier: {
    width: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    position: 'relative',
    top: "20rem"
  },
  workSpaceText: {
    fontSize: "85px",
    paddingLeft: '35px',
    fontFamily: "Rubik-bold",
    margin: 0
  },
  workSpaceText1: {
    fontSize: "85px",
    paddingLeft: '35px',
    fontFamily: "Rubik-bold",
    color: "#ff6300b3",
    margin: "0px",
    marginTop: "-30px"
  },
  connectText: {
    fontFamily: 'Manrope-bold',
    fontSize: "16px",
    paddingLeft: '35px'
  },
  connectSecText: {
    fontFamily: 'Manrope-bold',
    fontSize: "15px",
    color: "#4e4d4d",
    paddingTop: '7%'
  }
}, _defineProperty(_landingPageStyle, "connectSecText", {
  fontFamily: 'Manrope-bold',
  fontSize: "20px",
  color: "#4e4d4d",
  paddingTop: '7%'
}), _defineProperty(_landingPageStyle, "landingBtn", {
  fontSize: 20,
  padding: "8px 35px",
  backgroundColor: '#F14E24',
  '&:hover': {
    //   backgroundImage: 'none',
    backgroundColor: '#F14E24'
  }
}), _defineProperty(_landingPageStyle, "landingLastBtn", {
  fontSize: 15,
  padding: "8px 50px",
  backgroundColor: 'black',
  marginTop: "5%",
  '&:hover': {
    //   backgroundImage: 'none',
    backgroundColor: 'black'
  }
}), _defineProperty(_landingPageStyle, "landingSecBtn", {
  fontSize: 15,
  padding: "10px 55px",
  border: "1px solid #0000002e",
  color: "#F14E24",
  marginTop: "7%",
  '&:hover': {
    backgroundColor: 'transparent',
    border: "1px solid #0000002e"
  }
}), _defineProperty(_landingPageStyle, "yupImg", (_yupImg = {
  // position: "relative",
  // top: "-10rem",
  // // width: "inherit",
  width: "100%",
  top: "67rem"
}, _defineProperty(_yupImg, "width", "100%"), _defineProperty(_yupImg, "background", "#e1e2e270"), _yupImg)), _defineProperty(_landingPageStyle, "startNowBody", {
  width: "auto",
  height: "434px",
  background: "#FF6300"
}), _defineProperty(_landingPageStyle, "lastText", {
  fontSize: "40px",
  color: "black",
  fontFamily: 'Rubik-bold'
}), _defineProperty(_landingPageStyle, "secondBlock", {
  height: "auto !important"
}), _defineProperty(_landingPageStyle, "fbBtn", {
  borderRadius: 0,
  background: "transparent",
  border: "none",
  padding: "0 24px",
  fontSize: 20,
  marginTop: "5%",
  '&:hover': {
    backgroundColor: 'transparent',
    border: "1px solid black"
  }
}), _defineProperty(_landingPageStyle, "yupProfile", {
  background: "#e1e2e270",
  height: "823px !important",
  "@media (max-width: 576px)": {
    height: "12rem !important"
  },
  "@media (max-width: 768px)": {
    height: "12rem !important"
  }
}), _defineProperty(_landingPageStyle, "yupImg", (_yupImg2 = {
  // position: "relative",
  // top: "-10rem",
  // // width: "inherit",
  width: "100%",
  top: "67rem"
}, _defineProperty(_yupImg2, "width", "100%"), _defineProperty(_yupImg2, "background", "#e1e2e270"), _yupImg2)), _defineProperty(_landingPageStyle, "startNowBody", {
  width: "auto",
  height: "434px",
  background: "#FF6300"
}), _defineProperty(_landingPageStyle, "lastText", {
  fontSize: "54px",
  color: "black",
  fontFamily: 'Rubik-bold'
}), _defineProperty(_landingPageStyle, "secondBlock", {
  height: "auto !important"
}), _defineProperty(_landingPageStyle, "fbBtn", {
  borderRadius: 0,
  background: "transparent",
  border: "none",
  padding: "0 24px",
  fontSize: 20
}), _landingPageStyle);