// @ts-nocheck
// @ts-ignore
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import Storage from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { firebaseAPI } from "../../../web/src/App";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import firebase from 'firebase';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  history:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  success: boolean;
  notifications:any[];
  error: string | null;
  totalTasks: number;
  completedTasks: number;
  loading: boolean;
  hasMore:boolean;
  page:number;
  isAction:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  notificationApiCallId: any;
  getNotificationListApiCallId: any = "";
  getNotificationListApiCallId2: any = "";

  editProfileApiCallId: any;
  notificationRejectActionApiCallId:any;
  notificationAcceptActionApiCallId:any
  getProfileTasksApiCallId:any;
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      error: null,
      enableField: false,
      notifications:[],
      totalTasks: 0,
      completedTasks: 0,
      // Customizable Area Start
      success: false,
      loading: true,
      page:1,
      hasMore:true,
      isAction:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
  
    // Customizable Area Start
    else if (this.notificationApiCallId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.notificationApiCallId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        // this.setState({ countries: responseJson.data });
        this.setState({ success: true })
        window.location.replace("/profile-dashboard");
      }
    }
    else if (apiRequestCallId===this.getNotificationListApiCallId && this.getNotificationListApiCallId!=undefined) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    

      if (responseJson.data) {

       if(responseJson.data.length === 0){
this.setState({hasMore:false})


       } else{
         let temp= this.state.notifications.concat(responseJson.data)
         let filterArr=temp.filter((v,i,a)=>a.findLastIndex(v2=>(v2.id === v.id))===i)
        this.setState({ notifications: filterArr,hasMore:true });

       }


      } else if (responseJson.errors) {
        //Check Error Response
        // this.parseApiErrorResponse(responseJson);
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }

    }else if (apiRequestCallId===this.getNotificationListApiCallId2 && this.getNotificationListApiCallId2!=undefined) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    

      if (responseJson.data) {

       if(responseJson.data.length === 0){
this.setState({hasMore:false})


       } else{
         
        this.setState({ notifications: responseJson.data,hasMore:true });

       }


      } else if (responseJson.errors) {
        //Check Error Response
        // this.parseApiErrorResponse(responseJson);
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }

    } else if (apiRequestCallId===this.notificationAcceptActionApiCallId && this.notificationAcceptActionApiCallId!=undefined) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    

   
      if (responseJson.data) {
        this.getAllNotifications2();
        this.setState({loading: false})
      } else if (responseJson.errors) {
        //Check Error Response
        // this.parseApiErrorResponse(responseJson);
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }

    }
    else if (apiRequestCallId===this.notificationRejectActionApiCallId && this.notificationRejectActionApiCallId!=undefined) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    


      
      if (responseJson.success) {
        this.getAllNotifications2();
        this.setState({loading: false})
      } else if (responseJson.errors) {
        //Check Error Response
        // this.parseApiErrorResponse(responseJson);
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error });
      } else {
        this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }

    }else  if (apiRequestCallId === this.getProfileTasksApiCallId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (responseJson.data) {
       
        this.setState({
          loading: false, 
          totalTasks: responseJson.data.profile_status.total_tasks, 
          completedTasks: responseJson.data.profile_status.completed_tasks
        })
      } else if (responseJson.errors) {
        //Check Error Response
        // this.parseApiErrorResponse(responseJson);
        let error = Object.values(responseJson.errors[0])[0] as string;
        this.setState({ error , loading: false });
      } else {
        this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
      }

      // this.parseApiCatchErrorResponse(responseJson);
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  decline=(notification)=>{
    this.setState({hasMore:false,page:this.state.page-1})


    if(notification.attributes.notification_type=='Connection'){

      this.rejectConnection(notification.attributes.guest.data.id,notification.id)
    }else if (notification.attributes.notification_type=='Talent'){
      this.rejectTalent(notification.attributes.notification_type_id)

      
    }if(notification.attributes.notification_type=='Brand Ambassador'){
      this.brandReject(notification.attributes.notification_type_id)

    }

  }

  accept=(notification)=>{


    this.setState({hasMore:false,page:this.state.page-1})


    
    if(notification.attributes.notification_type=='Connection'){

     this.acceptConnection(notification.attributes.guest.data.id,notification.id)
    }else if (notification.attributes.notification_type=='Talent'){
    this.acceptTalent(notification.attributes.notification_type_id)


      
    }if(notification.attributes.notification_type=='Brand Ambassador'){
    this.brandAccept(notification.attributes.notification_type_id)


    }

  }

  // Customizable Area Start
  onAllowNotification = async() => {
   const fcmToken=await this.askForPermissioToReceiveNotifications()
   await this.updateToken(fcmToken);
    const authToken = await Storage.get("userToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const attrs = {
      allow_notification: true,
      step: 8
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  askForPermissioToReceiveNotifications = async () => {
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      return token;
    } catch (error) {
      console.error(error);
    }
  }

  rejectConnection=async(id,notiId)=>{
    this.setState({loading: true})

    try {
      const header = {
        token: localStorage.getItem("userToken"),
      };  
      const httpBody = {
        id: id,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.notificationRejectActionApiCallId = requestMessage.messageId;

      const formData = new FormData();
      formData.append('data[connection_id]',id)
      formData.append('data[notification_id]',notiId)
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointRejectConnection}/${id}`
      ); 
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodDeleteChatBox
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      
    }

}

acceptConnection=async(id,notiId)=>{
  this.setState({loading: true})

  try {
    const header = {
      token: localStorage.getItem("userToken"),
    };  
    const httpBody = {
      id: id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAcceptActionApiCallId = requestMessage.messageId;

    const formData = new FormData();
    formData.append('data[connection_id]',id)
    formData.append('data[notification_id]',notiId)


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointAcceptConnection}`
    ); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodAcceptConnection
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
 
  }

}

acceptTalent=async(id)=>{
  this.setState({loading: true})

  try {
    const header = {
      token: localStorage.getItem("userToken"),
    };  
    const httpBody = {
      id: id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAcceptActionApiCallId = requestMessage.messageId;

  


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointAcceptTalent}/${id}`
    ); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodAcceptConnection
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
 
  }

}

rejectTalent=async(id)=>{
  this.setState({loading: true})

  try {
    const header = {
      token: localStorage.getItem("userToken"),
    };  
    const httpBody = {
      id: id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAcceptActionApiCallId = requestMessage.messageId;



    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointRejectTalent}/${id}`
    ); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodAcceptConnection
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
 
  }

}

loadFunc=()=>{
  


  if(this.state.notifications.length === 0){

   
    this.getAllNotifications()
  }  else{
    
this.setState({hasMore:false})

    this.setState({page:this.state.page+1})
    this.getAllNotifications()
  }
 
}

brandAccept=async(id)=>{
  this.setState({loading: true})

  try {
    const header = {
      token: localStorage.getItem("userToken"),
    };  
    const httpBody = {
      id: id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAcceptActionApiCallId = requestMessage.messageId;




    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointAcceptBrand}/${id}`
    ); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodAcceptConnection
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
 
  }

}

brandReject=async(id)=>{
  this.setState({loading: true})

  try {
    const header = {
      token: localStorage.getItem("userToken"),
    };  
    const httpBody = {
      id: id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAcceptActionApiCallId = requestMessage.messageId;



    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointRejectBrand}/${id}`
    ); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodAcceptConnection
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
 
  }

}


  updateToken = async(value) => {
    try {

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editProfileApiCallId = requestMessage.messageId;

    const id = localStorage.getItem("userId")

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointeditProfile+`${id}`
    );
    const header = {

      token: localStorage.getItem("userToken")
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();
    formData.append("account[device_id]", value);
   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodeditProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  fetchCompletedProfileDetails = () => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getProfileTasksApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiProfileTasks
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiProfileTasks
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }


  getAllNotifications=async()=>{

 
    

    try {
      const header = {
        token: localStorage.getItem("userToken"),
      };  

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getNotificationListApiCallId = requestMessage.messageId;


      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiGetNotificationList}?page=${this.state.page}&per=10`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetNotificationList
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  getAllNotifications2=async()=>{
    this.setState({loading: true})

 
    

    try {
      const header = {
        token: localStorage.getItem("userToken"),
      };  

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getNotificationListApiCallId2 = requestMessage.messageId;


      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiGetNotificationList}?per=${this.state.page*10}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetNotificationList
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }
  
  // Customizable Area End
  
}
