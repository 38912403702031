import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  classes?: any;
  width?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  error: string | null;
  userType: string;
  kinds: any[];
  accountKind: string;
  interests: any[];
  selectedInterests: any;
  loading: boolean;
  userName:string;
  helps: any[];
  selectedHelps: any;
  currentCountry:any;
  countryCode:string;
  step: string;
  countryNames: any[]
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  createUserNameApiCallId: any;
  createPhoneAccountApiCallId: any;
  verifyPhoneAccountApiCallId: any;
  validationApiCallId: string = "";
  updateAccountTypeApiCallId: string = "";
  updateAccountKindApiCallId: string = "";
  updateInterestsApiCallId: string = "";
  updateHelpsApiCallId: string = "";
  updateConfirmDetailsApiCallId: string = "";
  fetchAccountKindApiCallId: string = "";
  fetchInterestsApiCallId: string = "";
  fetchHelpsApiCallId: string = "";
  setLanguageApiCallId: string = "";
  getCountrylistGetApiCallId: string = "";
  
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;

  socialLoginMessageRequestId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      userId: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "65",
      phone: "",
      error: null,
      userType: "",
      kinds: [],
      accountKind: "",
      interests: [],
      selectedInterests: {},
      loading: false,
      userName:'',
      helps: [],
      selectedHelps: {},
      currentCountry:'',
      countryCode:'',
      step:'',
      countryNames: []
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (responseJson.data) {
            window.location = `/signup/email/verify?token=${
              responseJson.meta.token
            }` as any;
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }

          // this.parseApiCatchErrorResponse(responseJson);
        }else if (apiRequestCallId === this.createUserNameApiCallId) {
          if (responseJson.data) {
            localStorage.setItem("userNameToken", responseJson?.meta?.username_token)
            window.location = "/signup" as any;
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }

          // this.parseApiCatchErrorResponse(responseJson);
        }  else if (apiRequestCallId === this.setLanguageApiCallId) {
          if (responseJson.data) {
           localStorage.setItem("userId", responseJson?.data?.id)
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }

          // this.parseApiCatchErrorResponse(responseJson);
        }
         else if (apiRequestCallId === this.createPhoneAccountApiCallId) {
          if (responseJson.data) {
            window.location = `/signup/phone/verify?token=${
              responseJson.meta.token
            }` as any;
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }

          // this.parseApiCatchErrorResponse(responseJson);
        } else if (apiRequestCallId === this.verifyPhoneAccountApiCallId) {
          if (responseJson.data) {
            localStorage.setItem("userToken", responseJson?.meta?.token)
            localStorage.setItem("userId", responseJson?.data?.id)
            window.location.replace("/select-type");
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }

          // this.parseApiCatchErrorResponse(responseJson);
        }
      }
    }
    if (
      this.socialLoginMessageRequestId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.socialLoginMessageRequestId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        localStorage.setItem("userToken", responseJson?.meta?.token)
        localStorage.setItem("userId", responseJson?.data?.id)
        window.location.replace("/select-type");
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error, loading:false });
          setTimeout(() => {
            window.location.replace("/signup");
          }, 2000);
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', loading:false });
          setTimeout(() => {
            window.location.replace("/signup");
          }, 2000);
      }
    }
    
    if (
      this.updateAccountTypeApiCallId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.updateAccountTypeApiCallId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson?.data) {
        window.location = ("/select-kind") as any;
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }
    }
    
    if (
      this.updateAccountKindApiCallId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.updateAccountKindApiCallId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        window.location = ("/setup-profile") as any;
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }
    }
    
    if (
      this.updateHelpsApiCallId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.updateHelpsApiCallId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        window.location = ("/confirm-details") as any;
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }
    }
    
    if (
      this.updateConfirmDetailsApiCallId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.updateConfirmDetailsApiCallId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        window.location = ("/select-interests") as any;
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }
    }
    
    if (
      this.updateInterestsApiCallId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.updateInterestsApiCallId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        window.location = ("/notification") as any;
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }
    }
    
    if (
      this.fetchAccountKindApiCallId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.fetchAccountKindApiCallId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        this.setState({
          kinds: responseJson?.data
        })
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }
    }

        if (
      this.fetchAccountKindApiCallId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.fetchAccountKindApiCallId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        this.setState({
          kinds: responseJson?.data
        })
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }
    }
    

  if (
    this.getCountrylistGetApiCallId !== null &&
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
      this.getCountrylistGetApiCallId
  ) {
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (responseJson?.countries) {
      this.setState({ countryNames: responseJson?.countries})
    } else if (responseJson?.errors) {
        let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
        this.setState({ error });
    } else {
        this.setState({ error: responseJson?.error || 'Something went wrong!' });
    }
  }
    
    if (
      this.fetchHelpsApiCallId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.fetchHelpsApiCallId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        this.setState({
          helps: responseJson?.data
        })
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }
    }
    
    if (
      this.fetchInterestsApiCallId !== null &&
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ===
        this.fetchInterestsApiCallId
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson?.data) {
        this.setState({
          interests: responseJson?.data
        })
      } else if (responseJson?.errors) {
          let error = `${Object.keys(responseJson.errors[0])[0]} ${Object.values(responseJson.errors[0])[0]}` as string;
          this.setState({ error });
      } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
      }
    }


    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  onSocialLogin = (data: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const httpBody = {
      data: data,
      username_token: localStorage.getItem('userNameToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.socialLoginMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createAccount(attributes: any): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    
    localStorage.setItem('email', attributes.email)
    const langauge = localStorage.getItem("langauge")
    let language = ""
    if(langauge === "br") {
      language = "pt"
    }else if (langauge === "kr"){
      language = "ko"
    }else if (langauge === "id"){
      language = "in"
    }else if (langauge === "th"){
      language = "th"
    }else{
      language = "en"
    }

    const attrs = {
      first_name: attributes.first_name,
      email: attributes.email,
      password: attributes.password,
      language: language
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      username_token: localStorage.getItem('userNameToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createUserName(attributes: any): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const langauge = localStorage.getItem("langauge")
    let language = ""
    if(langauge === "br") {
      language = "pt"
    }else if (langauge === "kr"){
      language = "ko"
    }else if (langauge === "id"){
      language = "in"
    }else if (langauge === "th"){
      language = "th"
    }else{
      language = "en"
    }

    const attrs = {
      username: attributes.userName,
      lang: language
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createUserNameApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'accounts/username'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  createPhoneAccount(attributes: any): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    
    localStorage.setItem('phone', `${this.state.countryCodeSelected}-${attributes.phone}`)

    const attrs = {
      first_name: attributes.first_name,
      phone_number: attributes.phone,
      password: attributes.password,
      country_code: this.state.countryCodeSelected
    };

    const data = {
      type: "sms_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      username_token: localStorage.getItem('userNameToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createPhoneAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  verifyAccount(attributes: any): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const langauge = localStorage.getItem("langauge")
    let language = ""
    if(langauge === "br") {
      language = "pt"
    }else if (langauge === "kr"){
      language = "ko"
    }else if (langauge === "id"){
      language = "in"
    }else if (langauge === "th"){
      language = "th"
    }else{
      language = "en"
    }

    const attrs = {
      pin: attributes.pin,
      token: attributes.token,
      step: 1,
      lang: language
    };

    const httpBody = attrs;
    
    const isEmailVerify = window.location.pathname.includes('/email/')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyPhoneAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `accounts/${isEmailVerify ? 'email_confirmation':'sms_confirmation'}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  updateAccountType = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };

    const attributes = {
      type_of_account: this.state.userType,
      step: 2
    };

    const data = { attributes };

    const httpBody = { data };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAccountTypeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "accounts/type_of_account"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  updateAccountKind = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };

    const attributes = {
      brand_id: this.state.accountKind,
      step: 3
    };

    const data = { attributes };

    const httpBody = { data };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAccountKindApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "accounts/select_brand"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  updateInterests = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };

    const data = Object.values(this.state.selectedInterests);

    const httpBody = { data, step: 7 };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateInterestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "interests"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  updateHelps = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };

    const data = Object.values(this.state.selectedHelps);

    const httpBody = { data, step: 5 };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateHelpsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "helps"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  updateConfirmDetails = (values): boolean => {
    const header = {
      "token": localStorage.getItem('userToken')
    };
    const formData = new FormData();
    const id = localStorage.getItem("userId")
    let step = 6
    formData.append("account[about]", values.description);
    formData.append("account[profile_country_code]", values.country);
    //@ts-nocheck
    //@ts-ignore
    formData.append("account[step]", step)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateConfirmDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account/accounts/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  fetchKindOfAccounts = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.fetchAccountKindApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "brands"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  setLanguage = async (langauge) => {
    try {
      // this.setState({loading: true})
      
      const header = {
        "token": localStorage.getItem('userToken')
      };

      const formData = new FormData();
      if(langauge === "br") {
        formData.append('language', "pt")
      }else if (langauge === "kr"){
        formData.append('language', "ko")
      }else if (langauge === "id"){
        formData.append('language', "in")
      }else if (langauge === "th"){
        formData.append('language', "th")
      }else{
        formData.append('language', "en")
      }
      
  
      // const data = {
      //   block_profile[blocked_id]: userId
      // };
  
      // const httpBody = { data };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setLanguageApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'account/accounts/language_setup'
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }
  
  fetchHelps = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.fetchHelpsApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "helps"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  fetchInterests = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.fetchInterestsApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "interests"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  resendEmailOtp = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };

    const attributes = {
      email: localStorage.getItem('email'),
    };

    const data = { attributes };

    const httpBody = { data };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "accounts/email_resend_otp"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  resendPhoneOtp = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": localStorage.getItem('userToken')
    };
    
    const phoneNumber = localStorage.getItem('phone')

    const attributes = {
      country_code: phoneNumber.split('-')[0],
      phone_number: phoneNumber.split('-')[1]
    };

    const data = { attributes };

    const httpBody = { data };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createPhoneAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "accounts/sms_resend_otp"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  resendOtpHandler = () => {
    const isEmailVerify = window.location.pathname.includes('/email/');
    
    if (isEmailVerify){
      this.resendEmailOtp();
    }else{
      this.resendPhoneOtp();
    }
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getCountryList() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountrylistGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/accounts/get_countries"
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: localStorage.getItem("userToken")
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTwitchSignup = async () => {
    try {
      if (window.location.hash) {
        if(window.location.hash.split("&")[1]?.split("=")[0] === 'id_token'){
          this.setState({loading: true})
          let idToken = window.location.hash.split("&")[1]?.split("=")[1];
          let decoded:any = jwt_decode(idToken);
          let response = await  axios.get('https://id.twitch.tv/oauth2/userinfo',{
            headers:{
              "Authorization":`Bearer ${window.location.hash.split("&")[0]?.split("=")[1]}`
            }
          }) 
          this.onSocialLogin({
              "type": "social_account",
              "attributes": {
                  "first_name": response.data.preferred_username,
                  "email": decoded.email || `${decoded.sub}@twitch.com`,
                  "unique_auth_id": decoded.sub
              }
          
          })
        } 
      }
    } catch (error) {
      console.log(error);
      this.setState({loading: false})
    }
   
  };

  getCountries = () => {
    const countries = this.state.countryNames
    const countryList = countries.map((item) => ({
      value: item.code,
      label: item.country
    }));
    return countryList;
  }

  getCountry = async () => {
    try {
          this.setState({loading: true})
          let response = await  axios.get('https://ipapi.co/json/')
          this.setState({loading: false ,currentCountry :response.data?.country_name})
    } catch (error) {
      console.log(error);
      this.setState({loading: false})
    }
   
  };

  
  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }
  
  selectUserTypeHandler = (userType) => {
    if(userType === this.state.userType){
        this.setState({ userType: "" })
    } else {
      this.setState({ userType })
    }
  }
  
  selectKindHandler = (kind) => {
    if(kind === this.state.accountKind){
        this.setState({ accountKind: "" })
    } else {
      this.setState({ accountKind: kind })
    }
  }
  
  selectInterestHandler = (interest) => {
    let selectedInterests = {...this.state.selectedInterests};
    
    if(selectedInterests.hasOwnProperty(interest.id)){
        delete selectedInterests[interest.id]
    } else {
      selectedInterests[interest.id] = interest;
    }
    
    this.setState({ selectedInterests })
  }
  
  selectHelpsHandler = (help) => {
    let selectedHelps = {...this.state.selectedHelps};
    
    if(selectedHelps.hasOwnProperty(help.id)){
        delete selectedHelps[help.id]
    } else {
      selectedHelps[help.id] = help;
    }
    
    this.setState({ selectedHelps })
  }
  
  checkUsernameToken = () => {
    const usernameToken = localStorage.getItem('userNameToken');
    if(!usernameToken){
      this.props.history.push('/claim-username')
    }
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount({})
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

    txtPhoneNumberWebProps = {
      onChangeText: (text: string) => {
        this.setState({ phone: text });
  
        //@ts-ignore
        this.txtPhoneNumberProps.value = text;
      }
    };


  txtUserNameWebProps = {
    onChangeText: (text: string) => {
      this.setState({ userName: text });

      //@ts-ignore
      this.txtUserNameProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  }; 

  EmailSignupSchema(t) {
  const validations = Yup.object().shape({
    first_name: Yup.string().required(`${t("This field is required.")}`),
    email: Yup.string()
      .trim()
      .email(`${t("Invalid email address")}`)
      .required(`${t("This field is required.")}`),
    password: Yup.string().required(`${t("This field is required.")}`),
    isSubscribe: Yup.boolean().required(`${t("Required")}`),
    termsAndConditions: Yup.boolean().required(`${t("Required")}`)
  }); 
  return validations
}
  
  UserNameSchema(t) {
    const validations = Yup.object().shape({
    userName: Yup.string().strict(true).lowercase(`${t('Please enter all values in lowercase')}`).required(`${t("This field is required.")}`),
  });
  return validations
}
  
  ConfirmDetailsSchema(t) {
    const validations = Yup.object().shape({
      country: Yup.string().required(`${t("This field is required.")}`),
      description: Yup.string().required(`${t("This field is required.")}`),
    });
    return validations
  }
    // TODO update phone number regex not working in safari
      // .matches(
      //   /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/,
      //   "Invalid phone number"
      // ),
  PhoneSignupSchema(t) {
    const validations  = Yup.object().shape({
    first_name: Yup.string().required(`${t("This field is required.")}`),
    phone: Yup.string()
      .trim()
      .required(`${t("This field is required.")}`),
    password: Yup.string().required(`${t("This field is required.")}`),
    isSubscribe: Yup.boolean().required(`${t("Required")}`),
    termsAndConditions: Yup.boolean().required(`${t("Required")}`)
  });
  return validations
}

  PhoneOtpSchema(t) {
    const validations  = Yup.object().shape({
    pin: Yup.number()
      .min(1000, `${t("Invalid otp")}`)
      .max(9999, `${t("Invalid otp")}`)
      .required(`${t("This field is required.")}`),
    token: Yup.string()
  });
  return validations
}

  // Customizable Area End
}
