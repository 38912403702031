//@ts-nocheck
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import { Box, Link, Typography, Button, Checkbox } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { CheckBox } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";
import { withTranslation } from 'react-i18next';
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Snackbar from "../../../components/src/Snackbar";
import { logo, starImage } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { withStyles } from "@material-ui/core/styles";
import Copyright from "../../../components/src/Copyright";

class SelectUserKind extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { kinds, accountKind } = this.state;
    const { classes, t , i18n } = this.props;
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
      >
        {/* <Box
          display="flex"
          justifyContent="space-between"
          width="85%"
          pt="22px"
        >
          <img
            style={{ width: 96, height: "auto" }}
            src={logo}
            onClick={() => {
              this.props.history.push("/");
            }}
          />
        </Box> */}

        <Box
          display="flex"
          mt="107px"
          mb="27px"
          width={isWidthUp("sm", this.props.width) ? 494 : 363}
          justifyContent="center"
        >
          <Typography className={classes.bestCategory}
            style={{
              fontSize: 26,
              color: "#14142B",
              fontFamily: "Manrope",
              fontWeight: 800
            }}
          >
            {t("What would describe you the best?")}
          </Typography>
          <img
            src={starImage}
            style={{ position: "absolute", height: 45, width: isWidthUp("sm", this.props.width) ? 494 : 363 }}
          />
        </Box>
        <Box
          display="flex"
          mt="6px"
          mb="19px"
          width="271px"
          justifyContent="center"
        >
          <Typography
            style={{
              fontSize: 14,
              color: "#4E4B66",
              fontFamily: "Poppins",
              fontWeight: 400,
              textAlign: "center"
            }}
          >
            {t("Don’t worry, you can update your profile category later.")}
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            flexWrap:'wrap',
            width: isWidthUp("sm", this.props.width) ? 618 : 350,
          }}
        >
          {kinds.map((kind: any, index: number) => (
            <Button
              key={kind.id}
              onClick={() => this.selectKindHandler(kind?.id)}
              variant="contained"
              style={{
                marginRight: 11,
                marginTop: 10,
                display: "flex",
                borderRadius: 16,
                width: isWidthUp("sm", this.props.width) ? 195 : 105,
                minHeight: isWidthUp("sm", this.props.width) ? 180 : 100,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FAFAFA",
                border: `1px solid ${
                    accountKind === kind?.id ? "#F14E24" : "transparent"
                }`,
                boxShadow:
                  "0px 1px 1px rgba(0, 0, 0, 0.14), 0px -1px 10px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  color: "#BFBFBF",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  textAlign: "center"
                }}
              >
                {kind?.attributes?.name || 'N/A'}
              </Typography>
            </Button>
          ))}
        </Box>

        <Button
          onClick={() => this.updateAccountKind()}
          disabled={!accountKind}
          variant="contained"
          style={{
            backgroundColor: "#F14E24",
            border: "1px solid #D9DBE9",
            borderRadius: 16,
            height: 34,
            width: isWidthUp("sm", this.props.width) ? 607 : 350,
            marginBottom: 14,
            boxShadow: "none",
            color: "#F7F7FC",
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 16,
            marginTop: 28,
            opacity: accountKind ? 1 : 0.6
          }}
        >
          {t("Continue")}
        </Button>

        <Box mt="315px" mb="100px">
        <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        
        <CatchInvalidToken
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
        />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchKindOfAccounts();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});
const selectKindStyles={
  bestCategory:{
    "@media (max-width: 768px)":{
     textAlign:"center",
     fontSize:"29px !important",
    }
  }
}

// export default withWidth()(withRouter(SelectUserKind as React.ComponentType<any>));
export default withTranslation()(withStyles(selectKindStyles)(withWidth()(
  withRouter(SelectUserKind as React.ComponentType<any>)
)))
