export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const businessImg = require("../assets/business.png");
export const creatorImg = require("../assets/creator.png");
export const facebookImg = require("../assets/facebook.png") 
export const twitchImg = require("../assets/twitch.png") 
export const twitterImg = require("../assets/twitter.png") 
export const youtubeImg = require("../assets/youtube.png")
export const tiktokImg = require("../assets/tiktok.png") 
export const instagramImg = require("../assets/instagram.png")
export const DummyUserImg = require("../assets/dummy-user-image.png")
export const defaultImg = require("../assets/avatar.png")