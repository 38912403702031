//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Button, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import {
  calendarIcon, instagramIcon, locationIcon, stepthree, twitchIcon,
  twitterIcon, verify, youtubeIcon
} from "./assets";
import ListingsController, { Props } from "./ListingsController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class ListingPreview extends ListingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, navigation, t, i18n } = this.props;
    const { showMore, userDetails } = this.state;

    const pricing = JSON.parse(localStorage.getItem("listingPricing"));

    const formDetails = JSON.parse(localStorage.getItem("listingDetails"));

    const listingDetails = { ...pricing, ...formDetails };

    if (!pricing || !formDetails) {
      this.props.history.replace("/portfolio");
      return null;
    }

    return (
      <>
        <ProfileHeader
          heading={t("Review Listing")}
          subModule={true}
          showProfile={true}
          multiPage
          backLocation="/listing/pricing"
        />
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>

          <Grid
            sm={12}
            md={6}
            style={{
              border: "1px solid #DEDDDA",
              borderTop: "none",
              height: "100%"
            }}
          >
            <Box className={classes.progressBarRow}>
              <Typography
                style={{
                  color: "#808080",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: 13,
                  marginRight: 14
                }}
              >
                {t("Step")} 3 of 3
              </Typography>
              <img src={stepthree} style={{ height: 12, width: 185 }} />
            </Box>

            <Box
              style={{
                height: 244,
                width: "100%",
                position: "relative",
                top: "24px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundImage: `url(${listingDetails.bannerUrl})`
              }}
            />
            <Avatar
              src={userDetails.profile_picture}
              style={{
                height: "112px",
                width: "112px",
                marginTop: -60,
                marginLeft: 35
              }}
            />

            <Box
              display="flex"
              flexDirection="column"
              mt="11px"
              mb="40px"
              pl="52px"
              pr="41px"
            >
              <Box display="flex" alignItems="center">
                <Typography
                  style={{
                    color: "#14142A",
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: 18
                  }}
                >
                  {userDetails.first_name}
                </Typography>

                {userDetails?.verified && (
                   <img
                   src={verify}
                   style={{
                     height: "16px",
                     width: "16px",
                     marginLeft: 8
                   }}
                 />
                )}
               
              </Box>

              <Box display="flex" alignItems="center" mt="7px">
                <Box display="flex" alignItems="center">
                  <img
                    src={locationIcon}
                    style={{
                      height: "15px",
                      width: "14px",
                      marginRight: 3
                    }}
                  />
                  <Typography
                    style={{
                      color: "#A0A3BD",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 14
                    }}
                  >
                    {userDetails?.profile_country_name || userDetails?.profile_country_code}
                  </Typography>
                </Box>
                <Box
                  style={{
                    backgroundColor: "#D9DBE9",
                    // border: "1px solid #D9DBE9",
                    transform: "rotate(90deg)",
                    width: 14,
                    height: 0.1
                  }}
                />

                <Box display="flex" alignItems="center">
                  <img
                    src={calendarIcon}
                    style={{
                      height: "15px",
                      width: "14px",
                      marginRight: 3
                    }}
                  />
                                </Box>
              </Box>

              <Box display="flex" alignItems="center" mt="8px">
                <Typography
                  style={{
                    color: "#16CF7C",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 14
                  }}
                >
                  {t(this.getCategoryName(listingDetails.category))}
                </Typography>

                <Box
                  style={{
                    backgroundColor: "#D9DBE9",
                    // border: "1px solid #D9DBE9",
                    transform: "rotate(90deg)",
                    width: 14,
                    height: 0.1
                  }}
                />

                <Typography
                  style={{
                    color: "#16CF7C",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 14
                  }}
                >
                  {`${t(JSON.parse(listingDetails.currency).label)} ${t(
                    listingDetails.price
                  )} ${t(JSON.parse(listingDetails.subscription).label)}`}
                </Typography>

                <Box
                  style={{
                    backgroundColor: "#D9DBE9",
                    // border: "1px solid #D9DBE9",
                    transform: "rotate(90deg)",
                    width: 14,
                    height: 0.1
                  }}
                />

                <Typography
                  style={{
                    color: "#A0A3BD",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 14,
                    marginRight: 6
                  }}
                >
                  {t("Platform")}:
                </Typography>

                {listingDetails.mediaChannels.includes("youtube") && (
                  <img
                    src={youtubeIcon}
                    style={{
                      height: "13px",
                      width: "13px",
                      marginRight: 5
                    }}
                  />
                )}

                {listingDetails.mediaChannels.includes("twitter") && (
                  <img
                    src={twitterIcon}
                    style={{
                      height: "13px",
                      width: "13px",
                      marginRight: 5
                    }}
                  />
                )}

                {listingDetails.mediaChannels.includes("twitch") && (
                  <img
                    src={twitchIcon}
                    style={{
                      height: "13px",
                      width: "13px",
                      marginRight: 5
                    }}
                  />
                )}

                {listingDetails.mediaChannels.includes("instagram") && (
                  <img
                    src={instagramIcon}
                    style={{
                      height: "13px",
                      width: "13px",
                      marginRight: 5
                    }}
                  />
                )}
              </Box>

              <Box display="flex" flexDirection="column" mt="24px">
                <Typography
                  style={{
                    color: "#081F32",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: 18,
                    wordBreak: "break-all"
                  }}
                >
                  {listingDetails.title}
                </Typography>
                {!showMore ? (
                  <Typography
                    style={{
                      color: "#4E4B66",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 13,
                      marginTop: 4,
                      wordBreak: "break-all"
                    }}
                  >
                    <>
                      {listingDetails.description.length > 300
                        ? listingDetails.description.substr(0, 300).trim() +
                        "..."
                        : listingDetails.description}
                      {listingDetails.description.length > 300 && (
                        <Typography
                          onClick={() => this.setState({ showMore: true })}
                          style={{
                            color: "#57D1D4",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14,
                            marginLeft: 5,
                            display: "inline",
                            cursor: "pointer",
                            textDecorationLine: "underline"
                          }}
                        >
                          {t("see more")}
                        </Typography>
                      )}
                    </>
                  </Typography>
                ) : (
                  <>
                    <Typography
                      style={{
                        color: "#4E4B66",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 13,
                        marginTop: 4,
                        wordBreak: "break-all"
                      }}
                    >
                      {listingDetails.description}
                    </Typography>
                    <Typography
                      onClick={() => this.setState({ showMore: false })}
                      style={{
                        color: "#57D1D4",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        marginTop: 5,
                        display: "inline",
                        cursor: "pointer",
                        textDecorationLine: "underline"
                      }}
                    >
                      {t("see less")}
                    </Typography>
                  </>
                )}

                <Typography
                  style={{
                    color: "#081F32",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: 18,
                    marginTop: 21
                  }}
                >
                  {t("Duration")}
                </Typography>
                <Typography
                  style={{
                    color: "#4E4B66",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 18,
                    marginBottom: 13,
                    marginTop: 4
                  }}
                >
                  {t(JSON.parse(listingDetails.duration).label)}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => this.handleCreateUpdateListing(listingDetails)}
                  variant="contained"
                  type="submit"
                  disabled={this.state.loading}
                  style={{
                    backgroundColor: "#F14E24",
                    border: "1px solid #D9DBE9",
                    borderRadius: 16,
                    height: 34,
                    marginBottom: 14,
                    boxShadow: "none",
                    color: "#F7F7FC",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 14,
                    marginTop: 30
                  }}
                >
                  {t("Submit")}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Snackbar
            open={Boolean(this.state.error)}
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
            severity="error"
          />
          <Loader loading={this.state.loading} />
          <CatchInvalidToken
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
          />
        </Grid>
      </>
    );
  }

  async componentDidMount() {
    // this.getValidations();
    // this.requestSessionData();
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
    this.getUserInformation();
  }
}
// Customizable Area Start
export const listingPreviewStyle = {
  progressBarRow: {
    marginRight: "30px", 
    marginTop: "-39px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
   
    "@media (max-width: 768px)": {
      marginRight: "0", 
      marginTop: "-32px",
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      marginTop: "15px",
    },
   
  },
};
// Customizable Area End
export default withTranslation()(
  withStyles(listingPreviewStyle)(
    withRouter(ListingPreview as React.ComponentType<any>)
  )
);