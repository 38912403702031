//@ts-ignore
//@ts-nocheck
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import { CloseOutlined } from "@material-ui/icons";
import React from "react";
import { withTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { withRouter } from "react-router";

class CropImage extends React.Component {
  constructor(props) {
    super(props);
    // Customizable Area Start
    this.state = {
      src: this.props.url,
      crop: {
        unit: "px",
        width: this.props.width || 600,
        // height: this.props.height || 250,
        aspect: this.props.circle ? 1 / 1 : 16 / 9
      },
      isOpen: this.props.open
    };

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };
  handleClose = () => {
    this.props.handleClose();
    URL.revokeObjectURL(this.state.src);
    this.setState({ isOpen: false, src: null });
  };

  handleSave = (url) => {
    this.props.getCropImageUrl(url);
    this.setState({ isOpen: false });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  }

  handleZoomIn = () => {
    this.setState((prevState) => {
      if (prevState.scale < 10) {
        return {
          scale: prevState.scale + 1
        };
      } else {
        return {
          scale: prevState.scale
        };
      }
    });
  };

  handleZoomOut = () => {
    this.setState((prevState) => {
      if (prevState.scale > 1) {
        return {
          scale: prevState.scale - 1
        };
      } else {
        return {
          scale: prevState.scale
        };
      }
    });
  };

  // Customizable Area End

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    const { t, i18n } = this.props;
    return (
      <Dialog fullScreen open={this.state.isOpen} onClose={this.handleClose}>
        <AppBar
          sx={{ position: "relative" }}
          style={{ backgroundColor: "#F14E24", color: "white" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <CloseOutlined />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
              {t("Crop Image")}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Box
            style={{
              width: "100%",
              padding: "80px",
              display: "flex",
              justifyContent: "space-between"
            }}
          >

            <Box style={{ display: "flex" }} />
            <Button
              onClick={() => {
                this.handleSave(croppedImageUrl);
              }}
              style={{
                fontSize: "18px",
                float: "right",
                border: "1px solid #F14E24",
                backgroundColor: "#F14E24",
                color: "white",
                display: "inlineBlock!important",
                marginTop: "10px"
              }}
            >
              {t("Save")}
            </Button>
          </Box>
          <Box
            style={{
              width: "90%",
              marginRight: "auto",
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirdss
                minWidth={this.props.circle ? 150 : 600}
                minHeight={this.props.circle ? 150 : 250}
                circularCrop={this.props.circle}
                keepSelection={true}
                locked={false}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </Box>
        </Box>
      </Dialog>
    );
  }
}
// Customizable Area Start

// Customizable Area End

export default withTranslation()(
  withRouter(CropImage as React.ComponentType<any>)
);
