//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Chip, Dialog, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withTranslation } from 'react-i18next';
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import {
  jpgIcon, locationIcon, pdfIcon, pngIcon, verify
} from "./assets";
import PortfolioController from "./PortfolioController";
import ProfileHeader from "./ProfileHeader";

class PortfolioDetails extends PortfolioController {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;
    const {userDetails, PortfolioIdData, showMore} = this.state
    
    let socialProfiles = [];
    
    if (
      userDetails.bx_block_social_profiles &&
      userDetails.bx_block_social_profiles.data.length > 0
    ) {
      socialProfiles = userDetails.bx_block_social_profiles.data.map(
        (profile) => {
          return {
            icon: Assets[profile.attributes.provider + "Icon"],
            count: profile.attributes?.audiences[0]?.follower_count || 0
          };
        }
      );
    }
    
    return (
      <>
        <ProfileHeader heading={userDetails.first_name} onClick={() => window.history.back()} subModule={this.state.subModule} showProfile />
        <Grid className={classes.mainWrapper}>
          <Grid container>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Box style={{ padding: "15px 0px" }}>
                  <Grid container>
                    <Grid Item md={3}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          src={userDetails.profile_picture}
                          style={{
                            width: 100,
                            height: 100
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid Item md={4}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#081F32",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: "10px",
                          }}
                        >
                          {userDetails.first_name}{" "}
                          {userDetails.activated && (
                             <img
                             src={verify}
                             style={{ height: 13, marginLeft: "5px" }}
                           />
                          )}
                         
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#969696",
                              fontSize: "1.2rem",
                              fontWeight: "400",
                              paddingRight: "10px",
                              borderRight: "1px solid #969696",
                            }}
                          >
                            <img
                              src={locationIcon}
                              style={{ height: "18px", paddingRight: "5px" }}
                            />
                            {userDetails?.profile_country_name || userDetails?.profile_country_code}
                          </Typography>
                          <Typography
                            style={{
                              color: "#969696",
                              fontSize: "1.2rem",
                              fontWeight: "400",
                              paddingLeft: "10px",
                            }}
                          >
                            {'@' + userDetails.user_name}
                          </Typography>
                        </Box>

                        <Typography
                          style={{
                            color: "#969696",
                            fontSize: "1.2rem",
                            fontWeight: "400",
                            paddingRight: "10px",
                          }}
                        >
                           {userDetails.brand?.data?.attributes?.name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Box style={{ padding: "10px 20px 0px" }}>
                    <Typography
                      style={{
                        color: "#353535",
                        fontFamily: "Poppins",
                        fontSize: 18,
                        marginTop: 5,
                        fontWeight: 600,
                        marginBottom: 7,
                      }}
                    >
                      {PortfolioIdData.attributes?.title}
                    </Typography>

                    {!showMore ? (
                  <Typography
                    style={{
                      color: "#4E4B66",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 13,
                      marginTop: 4,
                      wordBreak: "break-all"
                    }}
                  >
                    <>
                      {PortfolioIdData.attributes?.description.length > 300
                        ? PortfolioIdData.attributes?.description.substr(0, 300).trim() +
                          "..."
                        : PortfolioIdData.attributes?.description}
                      {PortfolioIdData.attributes?.description.length > 300 && (
                        <Typography
                          onClick={() => this.setState({ showMore: true })}
                          style={{
                            color: "#57D1D4",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14,
                            marginLeft: 5,
                            display: "inline",
                            cursor: "pointer",
                            textDecorationLine: "underline"
                          }}
                        >
                          {t("see more")}
                        </Typography>
                      )}
                    </>
                  </Typography>
                ) : (
                  <>
                    <Typography
                      style={{
                        color: "#4E4B66",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 13,
                        marginTop: 4,
                        wordBreak: "break-all"
                      }}
                    >
                      {PortfolioIdData.attributes?.description}
                    </Typography>
                    <Typography
                      onClick={() => this.setState({ showMore: false })}
                      style={{
                        color: "#57D1D4",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        marginTop: 5,
                        display: "inline",
                        cursor: "pointer",
                        textDecorationLine: "underline"
                      }}
                    >
                      {t("see less")}
                    </Typography>
                  </>
                )}
                  </Box>
                </Box>
                <Box style={{ padding: "10px 20px 15px" }}>
                  <Avatar src={PortfolioIdData.attributes?.project_banner} style={{width:'100%', height:193,borderRadius:0}} />
                </Box>
                <Box style={{ padding: "10px 20px 10px" }}>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <Typography
                        style={{
                          color: "#353535",
                          fontFamily: "Poppins",
                          fontSize: 18,
                          marginTop: 5,
                          fontWeight: 600,
                          marginBottom: 7,
                        }}
                      >
                        {t("Project Files")}
                      </Typography>
                    </Grid>

                    <Box display="flex" flexWrap="wrap">
                  {PortfolioIdData.attributes?.project_files.map((media) => (
                    <Box
                      onClick={() => this.setState({selectedMedia : media})}
                      display="flex"
                      flexDirection="column"
                      style={{
                        backgroundColor: "#eff0f6",
                        minHeight: 140,
                        width: 168,
                        borderRadius: 16,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        marginRight: 10,
                        marginBottom: 10,
                        cursor: 'pointer'
                      }}
                    >
                      {media?.mimetype?.split("/")[1] === "pdf" && (
                        <img src={pdfIcon} style={{ height: 50, width: 50 }} />
                      )}

                      {media?.mimetype?.split("/")[1] === "png" && (
                        <img src={pngIcon} style={{ height: 50, width: 50 }} />
                      )}

                      {(media?.mimetype?.split("/")[1] === "jpg" ||
                        media?.mimetype?.split("/")[1] === "jpeg") && (
                        <img src={jpgIcon} style={{ height: 50, width: 50 }} />
                      )}

                      <Typography
                        style={{
                          color: "#191919",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 15,
                          textAlign: "center",
                          wordBreak: "break-all"
                        }}
                      >
                        {media?.filename || media}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid sm={12} md={4}>
              <Box style={{ padding: "25px" }}>
                <Box
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      padding: "12px",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    {t("Social Media")}
                  </Typography>
                  <Box
                    style={{
                      padding: "20px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {socialProfiles.map((profile, index) => (
                      <Chip
                        key={index}
                        avatar={<Avatar alt={profile.icon} src={profile.icon} />}
                        label={profile.count}
                        style={{
                          backgroundColor: "#f3f3f3",
                          padding: "25px 10px",
                          fontSize: "1.1rem",
                          marginBottom: "10px",
                          marginRight: "10px"
                        }}
                      />
                    ))}
                  </Box>
                </Box>

                <Box style={{ border: "1px solid #ccc", borderRadius: "20px" }}>
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      padding: "12px",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    {t("Skills")}
                  </Typography>
                  <Box
                    style={{
                      padding: "20px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {userDetails?.skills?.data && userDetails.skills.data.map(skill => (
                      <Chip
                        key={skill.id}
                        label={skill.attributes.name}
                        style={{
                          backgroundColor: "#f3f3f3",
                          padding: "25px 10px",
                          fontSize: "1.1rem",
                          marginBottom: "10px",
                          marginRight: "10px"
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        
        <Dialog
        open={this.state.selectedMedia}
        onClose={() => this.setState({selectedMedia: null})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        {this.state.selectedMedia?.file?.type?.split("/")[1] !== "pdf" ? (
          <Avatar src={this.state.selectedMedia?.url} style={{width:'100%',height:'auto',borderRadius:0}} />
        ) :(
          <iframe src={this.state.selectedMedia?.url} style={{width:'600px',height:'600px'}} />
        ) }
        
     
      </Dialog>
      
      </>
    );
  }
  componentDidMount() {
    this.getPortfolioInformation();
    this.getUserInformation()
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
const PortfolioDetailsStyle = {
  mainWrapper:
  {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    },
  },
  mainBodyContent:
  {
    height: "100%",
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10,
    },
  },
}
// export default PortfolioDetails;
export default withTranslation()(withStyles(PortfolioDetailsStyle)(PortfolioDetails))
