export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backgroundImage = require("../assets/backgroundLanding.png");
//export const landingLogoImage = require("../assets/logo.png");
export const joinLogoImg = require("../assets/logo 96x96.svg");
export const leftArrowImage = require("../assets/left_arrow.png");
export const phoneImg1 = require("../assets/phone_img1.png");
export const phoneImg2 = require("../assets/phone_img2.png");
export const bannerImg = require("../assets/banner.png");
export const profileImg = require("../assets/profileImg.png");
export const landingDiamondImg = require("../assets/diamond.png");
export const landingShapedimaImg = require("../assets/shapedima.png");
export const landingPublishersImg = require("../assets/publishers.png");
export const followBgCircleImg = require("../assets/follow_bg_circle.png");
export const followBgImg = require("../assets/follow_bg.png");
export const secondBackgroudImage = require("../assets/follow_bg.png");
export const contactBg = require("../assets/contact_bg.png");
export const ablertKing = require("../assets/Albert Kim - South Korea.jpg")
export const bruno = require("../assets/Bruno Lopes - Brazil.jpg")
export const chirstian = require("../assets/Christian Borneman - Latam.JPG")
export const dc = require("../assets/DC Dominguez - Philippines.jpg")
export const genie = require("../assets/Genie Kim - South Korea.jpeg")
export const jie = require("../assets/Jie Ming Tay - Global.jpg")
export const joao = require("../assets/Joao Pott - Brazil.jpg")
export const julius = require("../assets/Julius Mariano - Philippines.jpg")
export const nattapon = require("../assets/Nattapon Ruetthapai - Thailand.jpg")
export const syaza = require("../assets/Syaza Qistina Tan.jpg")
export const syaza1 = require("../assets/Syaza Qistina Tan.jpg")
export const letterPdf = require("../assets/Founder Letter.pdf")
export const backIcon = require("../assets/ic_back.png");
export const logo = require("../assets/logo copy.png");