//@ts-ignore
//@ts-nocheck
import { Box, Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withTranslation } from 'react-i18next';
import Snackbar from "../../../components/src/Snackbar";
import AboutController, { Props } from "./AboutController.web";
import ProfileHeader from './ProfileHeader';
import SideMenu from "./SideMenu";

class EditSkills extends AboutController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getSkills()
    this.getUserData()
    this.getSelectedSkills()
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  checkSelectedSkills = () => {
    const { selectedSkills } = this.state
    if (selectedSkills.length > 3) {
      this.setState({ error: "Skills not more than three" })
    } else {
      this.setState({ error: null })
      const skill_ids = selectedSkills
      this.updateSkills(skill_ids)
    }
  }

  selectSkills = (id) => {
    const haveId = this.state.selectedSkills.includes(id)
    if (haveId) {
      const selectedId = this.state.selectedSkills.filter((item) => (item != id))
      this.setState({ selectedSkills: selectedId })
    }
    else {
      let skill_ids = [...this.state.selectedSkills]
      skill_ids = [...skill_ids, id]
      this.setState({ selectedSkills: [...this.state.selectedSkills, id] })
    }
  }

  removeSkills = (id) => {
    let skill_ids = [...this.state.selectedSkills]
    remove(id)
  }

  render() {
    const { props: { classes, t , i18n }, state: { listSkills, selectedSkills, error } } = this

    return (
      <>
        <ProfileHeader heading={t("Skills")} subModule="3" showProfile />
        <Grid className={classes.mainWrapper}>
          <Grid container>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.mainBodyContent}>
                <Typography className={classes.heading}>
                  {t("Please select all skills that apply to your profile. You can choose a maximum number of")} <b>{t("3 skills")}</b> {t("for your account")}.
                </Typography>

                <Box className={classes.btnGrid}
                  style={{
                    display: "flex",
                    flexWrap: 'wrap',
                    width: "100%",
                  }}
                >
                  {listSkills && listSkills.length > 0 &&
                    listSkills.map((text, index) => (
                      <Button
                        key={text.id}
                        onClick={() => this.selectSkills(text.id)}
                        variant="contained"
                        className={classes.btnSelect}
                        style={{
                          border: `1px solid ${selectedSkills.includes(text.id) ? "#F14E24" : "#E0E0E0"
                            }`,
                        }}
                      >
                        <Typography className={classes.skillTitle}>
                          {text.attributes.name}
                        </Typography>
                      </Button>
                    ))}
                </Box>
                <Box display="flex" justifyContent="flex-end" style={{ paddingBottom: 185, marginRight: 16 }} className={classes.saveBtn} >
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={this.checkSelectedSkills}
                    style={{
                      backgroundColor: "#F14E24",
                      border: "1px solid #D9DBE9",
                      borderRadius: 16,
                      height: 34,
                      marginBottom: 14,
                      boxShadow: "none",
                      color: "#F7F7FC",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: 30,
                      padding: "18px 30px",
                    }}
                  >
                    {t("Submit")}
                  </Button>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
      </>

    )
  }
}

const aboutStyle = {
  mainWrapper:
  {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
  },
  mainBodyContent:
  {
    height: "100%",
    marginTop: 30,
    border: "#80808042 1px solid",
    padding: 42,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10,
    },
  },
  heading: {
    color: "#191919",
    fontFamily: "Poppins",
    fontSize: 17,
    marginBottom: 17,
    fontWeight: 400,
  },
  main: {
    "@media (max-width: 768px)": {
      display: "block",
    }
  },
  selectSkills: {
    "@media (max-width: 768px)": {
      maxWidth: "100% !important",
    }
  },
  btnSelect: {
    margin: "10px",
    display: "flex",
    borderRadius: 20,
    padding: "15px 40px",
    minHeight: 36,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    minWidth: "160px",
    boxShadow: "none",
    "@media (max-width: 767px)": {
      minWidth: "90%",
      minHeight: "55px",
      maxHeight: "55px",
      padding: "5px 40px",
    },

  },
  skillTitle: {
    fontSize: 15,
    color: "rgb(196 185 185)",
    fontFamily: "Poppins",
    fontWeight: 600,
    textAlign: "center",
    "@media (max-width: 767px)": {
      fontSize: 13,
    },
  },
  saveBtn: {
    "@media (max-width: 768px)": {
      display: "flex",
      justifyContent: "flex-start !important",
    }
  },
  btnGrid: {
    "@media (max-width: 767px)": {
      display: "grid !important",
      gridTemplateColumns: "1fr 1fr !important",
    }
  },
}

export default withTranslation()(withStyles(aboutStyle)(EditSkills))