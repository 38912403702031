//@ts-ignore
//@ts-nocheck
import React from "react";
import { StyleSheet, Platform } from "react-native";
import { Grid, Box, Typography, IconButton, Button, Dialog, DialogTitle, ListItem, ListItemAvatar, Avatar, ListItemText, List } from "@material-ui/core";
import { withRouter } from "react-router";

import { backIcon, logo, newMessage,searchIcon,closeIcon } from "./assets";
import SideMenu from "../../../blocks/user-profile-basic/src/SideMenu.web";
import InboxController, { Props } from "./InboxController";
import { Formik, Form, Field } from "formik";
import { withTranslation } from 'react-i18next';

class ListOfUser extends React.Component {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation,onCloseData,users,open,updateSearch ,selectedUsersArray,removeSelectedUserHandler,noClick,t, i18n} = this.props;


    return (
      <div style={{     width: 600,
        background: 'white',
        maxHeight: '50rem',
        borderRadius: 10
     }}>

        <Grid container>

          <Grid xs={12} style={{display:'flex',justifyContent:'space-between',alignItems:'baseline'}}>
            <Box
              style={{
                height: 64,
                display: "flex",
                alignItems: "center"
              }}
            >
              <IconButton
                onClick={noClick}
             
                style={{ marginLeft: 10 }}
              >
                <img src={backIcon} style={{ height: 14, width: 17 }} />
              </IconButton>
              <Typography
                style={{
                  color: "#081F32",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: 18
                }}
              >
                {t("New message")}
              </Typography>


            </Box>
            <Box>
              {
                selectedUsersArray.length > 0 &&(
                  <>
                   <Button
                      onClick={() => onCloseData('')}
                      variant="contained"
                      type="button"
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: 16,
                        height: 36,
                        width: 96,
                        boxShadow: "none",
                        color: "#F14E24",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: 14,
                      }}
                    >
                      {t("Next")}
                    </Button>
                  </>
                )
              }
            </Box>

          </Grid>

          <Grid xs={12}>

            <Box style={{ borderBottom: "1px solid #DEDDDA" }}>
            <div class="input-container" style={{display:'flex',width:'100%',marginBottom:15,marginLeft:'2rem',alignItems:'center'}}   onChange={(e) => updateSearch(e)}>
              <img src={searchIcon} width="15" height="15" />
              <input class="input-field" type="text" placeholder={t("Search people")} name="usrnm"  style={{
                  border: "none",
                  height: "100%",
                  width: "80%",
                  color: "rgba(0, 0, 0, 0.6)",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: 16,
                  marginRight: 10,
                  marginLeft: 5,
                  outline: "none"
                }}/>

           </div>
              
         
{selectedUsersArray.length > 0 && (
                    <Box
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        marginBottom: 7,
                        paddingLeft: 22,
                        paddingRight: 22
                      }}
                    >
                      {selectedUsersArray.map((user) => (
                        <Box
                          key={user.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: 33,
                            width: 152,
                            borderRadius: 16.5,
                            marginRight: 5,
                            marginBottom: 5,
                            border: "1px solid #CFD9DE"
                          }}
                        >
                          <Box display="flex">
                            <Avatar
                              style={{
                                borderRadius: 11.5,
                                height: 23,
                                width: 23,
                                marginLeft: 5,
                                marginRight: 7
                              }}
                            />

                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 15,
                                color: "#081F32"
                              }}
                            >
                              {user.account.data.attributes.first_name.length > 7
                                ? user.account.data.attributes.first_name
                                    .substr(0, 7)
                                    .trim() + "..."
                                : user.account.data.attributes.first_name}
                            </Typography>
                          </Box>

                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => removeSelectedUserHandler(user)}
                          >
                            <img
                              src={closeIcon}
                              style={{
                                height: 14,
                                width: 14,
                                marginRight: 14
                              }}
                            />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  )}
            </Box>
          </Grid>

          <Grid xs={12}>



            <List style={{ backgroundColor: '#ffff',height:'46rem', overflow: "auto" }} >
{
  users.length>0 ? <>
  {
                users.map(user => (

                  <ListItem button onClick={() => onCloseData(user)} style={{ borderBottom: "1px solid #DEDDDA" }}>
                    <ListItemAvatar>
                      {
          
            user.account.data.attributes.profile_picture ?    <img src={user.account.data.attributes.profile_picture} width="40" height="40" style={{borderRadius:10}}/>
            :
            <Avatar style={{borderRadius:10}} />

                      }
                      

                     
                    </ListItemAvatar>
                    <ListItemText primary={user.account.data.attributes.first_name} secondary={'@'+user.account.data.attributes.user_name}/>
                   
                  </ListItem>


                ))
              }


  </>
  :
  <>
  <div style={{padding:'1rem'}}>
    No User found
  </div>
  </>
}
            

            </List>
          </Grid>



        </Grid>
      </div>
    );
  }

  async componentDidMount() {
   
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  title: {
    marginBottom: 2,
    fontSize: 14,
    textAlign: "left",
    marginTop: 16
  },

  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgDummyPassword: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    opacity: 0.4,
    fontWeight: "bold",
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 0,
    padding: 12,
    marginTop: 0
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    marginTop: 0,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  helperText: { marginTop: 10 }
});
// Customizable Area End

export default withTranslation()(withRouter(ListOfUser as React.ComponentType<any>))
