//@ts-ignore
//@ts-nocheck
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import classNames from "classnames";
import { withRouter } from "react-router";
import FacebookLogin from "react-facebook-login";

import TopNav from "../../../components/src/TopNav";
import Footer from "../../../components/src/Footer.web";
import { landingPageStyle } from "../assets/LandingPage";
import { yupProfileImage } from "./assets";
import { upperStartNowImage } from "./assets";
import { yupsecImage, yupIllu, illustratiom, illustration } from "./assets";
import LandingPageController, { Props } from "./LandingPageController.web";

class MainLandingPage extends LandingPageController {
  constructor(props: any) {
    super(props);
    this.state = {
      data: "",
      isLogin: false,
      userToken: ""
    };
  }

  componentDidMount() {
    this.getKickStartContent();
    this.getMakeMoneyContent();
    this.getRewardContent();
    const auth = localStorage.getItem("fblst_1800966006635263");
    if (auth) {
      this.setState({ isLogin: true, userToken: auth });
    }
  }

  responseFacebook = (response) => {
    this.setState({ data: response, isLogin: true });
    const auth = localStorage.getItem("fblst_1800966006635263");
    this.setState({ userToken: auth });
  };

  onLogOut = () => {
    localStorage.removeItem("fblst_1800966006635263");
    this.setState({ isLogin: false, userToken: "", data: "" });
  };

  render() {
    const { classes } = this.props;
    const {
      data,
      isLogin,
      userToken,
      kickStartContent,
      makeMoneyContent,
      rewardContent
    } = this.state;
    const url = "/claim-username";

    return (
      <Box display="flex" flexDirection="column">
        <div className="row">
          <TopNav />
        </div>
        <div className={classes.workSpaceBody}>
          <div className={classes.mainContanier}>
            <div
              className="row"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                paddingLeft: "4%"
              }}
            >
              <p className={classes.workSpaceText}>Your gamer</p>
              <p className={classes.workSpaceText1}>workplace</p>
              <p className={classes.connectText}>
                yup.gg connects you to gaming projects
                <br /> and other gamers, wherever you are.
              </p>
            </div>
            <div
              className="row"
              style={{
                paddingLeft: "6%",
                paddingTop: "45px",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              {/* {isLogin ?  */}
              <Button
                color="primary"
                className={classes.landingBtn}
                onClick={() => {
                  this.props.history.push(url);
                }}
              >
                Get Started
              </Button>
              {/* // : */}
              {/* <Button color="primary" className={classes.landingBtn}> */}
              {/* <FacebookLogin
              //     appId="1800966006635263"
              //     autoLoad={false}
              //     textButton="FbLogin"
              //     fields="name,email,picture"
              //     callback={this.responseFacebook}
              //     cssClass={`${classes.fbBtn}`}
              //   /> */}
              {/* // </Button>
          // }    */}
            </div>
          </div>
        </div>
        {/* <div className={classes.yupProfile}>
        <img src={yupProfileImage} className={classes.yupImg} />
      </div> */}
        <div className={classes.secondBlock}>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} style={{ paddingLeft: "9%" }}>
              <img
                style={{ width: "100%", height: "auto", marginTop: "10%" }}
                src={illustration}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ padding: "4%" }}>
              <Grid container style={{ display: "block" }}>
                <h1 className={classes.lastText}>
                  Your Profile, <br />
                  Your Network
                </h1>
                {/* <p className={classes.connectSecText}>{makeMoneyContent}</p> */}
                <p className={classes.connectSecText}>
                  Create your professional profile and connect your
                  gamingnetwork, all in one-place, Easy to build ans easierto
                  share...
                </p>
              </Grid>
              {/* <Grid container>
              <Button color="secondary" className={classes.landingSecBtn} onClick={() => {this.props.history.push(url)}}>
                Find Brands
              </Button>
            </Grid> */}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} style={{ padding: "4% 9%" }}>
              <Grid container>
                <h1 className={classes.lastText}>
                  Kickstart
                  <br />
                  your carrer
                </h1>
                {/* <p className={classes.connectSecText}>{kickStartContent}</p> */}
                <p className={classes.connectSecText}>
                  Build your professional network and find more opportunites to
                  connect to people that can help your career grow!
                </p>
              </Grid>
              <Grid container>
                <Button
                  color="secondary"
                  className={classes.landingSecBtn}
                  onClick={() => {
                    this.props.history.push(url);
                  }}
                >
                  Reserve Your Name
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ padding: "4%" }}>
              <img src={yupIllu} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              style={{ paddingLeft: "9%", paddingTop: "4%" }}
            >
              <img src={illustratiom} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ padding: "4%" }}>
              <Grid container style={{ display: "block" }}>
                <h1 className={classes.lastText}>
                  Make more <br />
                  money
                </h1>
                {/* <p className={classes.connectSecText}>{makeMoneyContent}</p> */}
                <p className={classes.connectSecText}>
                  yup is optimized for revenue generation. Use it to explore
                  opportunities, connect with your favorite brands and find
                  jobs!
                </p>
              </Grid>
              <Grid container>
                <Button
                  color="secondary"
                  className={classes.landingSecBtn}
                  onClick={() => {
                    this.props.history.push(url);
                  }}
                >
                  Find Brands
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container>
          <Grid item xs={12} sm={6} md={6} style={{ padding: "4% 9%" }}>
            <Grid container >
              <h1 className={classes.lastText}>Earn rewards</h1>
              <p className={classes.connectSecText}>Coming Soon: Yup Rewards gives you discounts on the 
                <br />things you love and helps you get the gear you need!</p>  
            </Grid>
            <Grid container>
              <Button color="secondary" className={classes.landingSecBtn} onClick={() => {this.props.history.push(url)}}>
                Call-to-action
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} style={{ padding: "4%"}}>
            <img src={rewards} />
          </Grid>
        </Grid> */}
        </div>
        <div className={classes.startNowBlock}>
          <img
            src={upperStartNowImage}
            style={{ width: "100%", marginBottom: "-10px" }}
          />
          <div className={classes.startNowBody}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{ paddingTop: "4%", paddingLeft: "15%" }}
              >
                <Grid container>
                  <h1 className={classes.lastText}>
                    See it for yourself.
                    <br /> Sign up for yup
                    <br /> (it’s free){" "}
                  </h1>
                </Grid>
                <Grid container>
                  <Button
                    color="primary"
                    className={classes.landingLastBtn}
                    onClick={() => {
                      this.props.history.push(url);
                    }}
                  >
                    StartNow
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <img src={yupsecImage} />
              </Grid>
            </Grid>
          </div>
        </div>
        <Footer />
      </Box>
    );
  }
}

export default withStyles(landingPageStyle)(withRouter(MainLandingPage));
