// @ts-nocheck
// @ts-ignore
import React from "react";
import { StyleSheet, Platform } from "react-native";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, IconButton, Button, Modal,Avatar, Snackbar } from "@material-ui/core";
import { withRouter } from "react-router";

import { backIcon, logo, searchIcon, newMessage,noMessage } from "./assets";
import SideMenu from "../../../blocks/user-profile-basic/src/SideMenu.web";
import ProfileHeader from "../../../blocks/user-profile-basic/src/ProfileHeader";
import InboxController, { Props } from "./InboxController";
import ListOfUserWeb from "./ListOfUser.web";
import ChatBox from "./ChatBox.web";
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from "../../../components/src/Loader.web";
import { withTranslation } from 'react-i18next';
import ConfirmationWeb from "./Confirmation.web";
import moment from "moment";
import ActionCable from 'actioncable';



class InboxList extends InboxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  displaytime(time){
   
    let date=new Date(time.attributes.created_at)

    let d=date.getHours();
    let m=date.getMinutes();
    return `${d}:${m<9 ? `0`+m : m} (${moment(time.attributes.created_at).format("DD MMM YYYY")})`

  }
  handleModal = (id) => {

    this.setState({ isModal: !this.state.isModal,id:id})
  };

  displayText(data){
    const { t } = this.props;

    if(data.attributes.body){
      return data.attributes.body 
    }else{
      return  t("file")
    }
  }

  displayOtherAccountName(data){
    

    if(data.accounts.data.length>1){

      return `${data.admin.data.attributes.first_name} `

    }else{

      const id = localStorage.getItem("userId")  
      const filteredPeople = data.accounts.data.filter((item) => item.id !== id);
  
  
      if(filteredPeople.length){
  
        return filteredPeople[0].attributes?.first_name
      }else{
      return ` ${data.admin.data.attributes.first_name}`

      }
    }

      return null;  
  


  }
  displayOtherAccountUserName(data){

    if(data.accounts.data.length>1){

      return ` ${data.admin.data.attributes.user_name} + ${data.accounts.data.length}`

    }else{

      const id = localStorage.getItem("userId")  
      const filteredPeople = data.accounts.data.filter((item) => item.id !== id);
 
  
      if(filteredPeople.length){
  
        return filteredPeople[0].attributes?.user_name
      }else {
      return ` ${data.admin.data.attributes.user_name}`


      }
    }
 


  }
  displayOtherAccountImage(data){

    

    if(data.accounts.data.length>1){

      return data.admin.data.attributes.profile_picture

    }else{

      const id = localStorage.getItem("userId")  
      const filteredPeople = data.accounts.data.filter((item) => item.id !== id);

  
      if(filteredPeople.length){
  
        return filteredPeople[0].attributes?.profile_picture
      }else{
      return ` ${data.admin.data.attributes.profile_picture}`

      }
    }

  

  }

  render() {
    const { navigation,t,i18n } = this.props;
    const { messages, selectUser, open, users,isModal } = this.state;
    const { classes } = this.props;
    const userId= localStorage.getItem('userId')
    const CableApp = {}
    const token=localStorage.getItem('authToken')
    CableApp.cable = ActionCable.createConsumer(`https://yuppgg-68443-ruby.b68443.dev.eastus.az.svc.builder.cafe/cable?token=${token}`)

  

    return (
      <>
        <ProfileHeader
          heading={t("Messages")} 
          subModule={true}
          showProfile={true}
        />
        <Grid className={classes.mainWrapper}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Box className={classes.hederInboxRow}>
                <div className="input-container" style={{alignItems:'center',border:'1px solid rgb(222, 221, 218)',borderRadius:30,padding:'1rem',display:'flex',width:'100%'}}   onChange={(e) => this.updateSearchList(e)}>
              <img src={searchIcon} width="15" height="15" />
              <input className="input-field" type="text" placeholder={t("Search")} name="usrnm"  style={{
                  border: "none",
                  height: "100%",
                  width: "80%",
                  color: "rgba(0, 0, 0, 0.6)",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: 16,
                  marginRight: 10,
                  marginLeft: 5,
                  outline: "none"
                }}/>
           </div>
                 
                   <IconButton
                    onClick={this.handleClickOpen}
                    
                    style={{ marginLeft: 10 }}
                  >
                    <img src={newMessage} style={{ height: 48, width: 48 }} />
                  </IconButton>
                 
                </Box>
                <Box>
            
              
              
  
            </Box>
            
                {


                  this.state.messagesFilter.length ? <>
  
                  {
                    this.state.messagesFilter.map((message,i) => (

                      <Box
                      key={i}
                        onClick={() => this.openSingleChat(message)}
                        display="flex"
                        flexDirection="flex-start"
                        style={{
                          width: "97%",
                          height: "auto",
  
                          border: "1px solid #EBEEF0",
                          borderRadius: 10,
                          
                          padding: 5,
                          
                          justifyContent: 'space-between',
                          marginBottom: 10,
                        }}
                      >
                       
  
         
            <Box display='flex' style={{cursor:'pointer'}}>
  
            <Box >
            {
            
            this.displayOtherAccountImage(message.attributes) ?    <Avatar src={this.displayOtherAccountImage(message.attributes)} width="40" height="40" style={{borderRadius:10}}/>
            :
            <Avatar style={{borderRadius:25}} />
  
                      }
            
            </Box>
  
  
                          <Box marginLeft='10px'>
                            <Box display="flex">
  
                            <Typography
                              style={{
                                color: "#081F32",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: 14
                              }}
                            >
                              {this.displayOtherAccountName(message?.attributes)} 
                            </Typography>
                            {" "}
                            <Typography
                              style={{
                                color: "#AFBBC4",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: 12
                              }}
                            >
                            {" "}  @{this.displayOtherAccountUserName(message?.attributes)}
                            </Typography>
                            </Box>
                            <Typography
                              style={{
                                color: "#8a9ca9",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 13,
                                overflow:'hidden' 
                              }}
                            >
                              {this.displayText(message?.attributes?.messages.data)}
                            </Typography>
                          </Box>
                          </Box>
                       
                        <Box style={{ textAlign: "end",minWidth:115 }} >
                          <Typography
                            style={{
                              color: "#AFBBC4",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 10
                            }}
                          >
                            {this.displaytime(message?.attributes?.messages.data)}
                          </Typography>
                        
                          {
                           userId == message.attributes?.admin_id  &&  <DeleteIcon style={{cursor:'pointer'}} onClick={(e)=>{e.stopPropagation();this.handleModal(message.id);}}/>
                          }
                          
                        </Box>
  
  
  
                  
  
                </Box>
                    ))
                  }


                  </>
                    :
                    <>
                    
                    <div  style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                      <img src={noMessage}/>
                    <p
                    style={{
                      margin: "1rem",
                      color: "rgba(78, 75, 102, 1)",
                      fontFamily: "Poppins",
                      fontWeight: "normal"
                    }}
                  >
                    Your inbox is pretty empty.
                  </p>
                  <p
                    style={{
                      margin: "1rem",
                      color: "rgba(78, 75, 102, 1)",
                      fontFamily: "Poppins",
                      fontWeight: "normal"
                    }}
                  >
                                     Find other people to send messages.

                  </p>
                  <button
                    style={{
                      backgroundColor: "#F14E24",
                      border: "1px solid #D9DBE9",
                      borderRadius: 36,
                      height: 45,
                      marginBottom: 14,
                      boxShadow: "none",
                      color: "#F7F7FC",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: 16,
                      marginTop: 15,
                      width: "20rem"
                    }}
                    onClick={() => this.props.history.push("/discovery")}
                  > 
                    {t("Explore")}
                  </button>
                    </div>
                    </>
                  
                }
                </Box>
            </Grid>
            <Grid item sm={12} md={4} style={{width:'100%'}}>

             

          {
            selectUser!==null ? 
            <>
            <ChatBox handleClose2nd={this.handleClose2nd} selectedRoomId={this.state.selectedChatRoomId} CableApp={CableApp} handleClickOpen={this.handleClickOpen2nd} open2nd={this.state.open2nd} handleFile={this.handleSelectFile} allAccount={this.state.allAccount} selectUser={this.state.selectUser} NewMessage={this.CreateNewMessage} createMessage={this.createMessages} newValue={this.state.newMessage} messageList={this.state.singleChatRoom}/>
           </>
            : 
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'20rem'}}>
            <p style={{fontFamily: "Poppins",fontWeight:'normal'}}>
                      {t("You don't have a message selected")}
                    </p>
                    <p style={{ color: '#B6BFC8', fontWeight: 'normal', fontFamily: "Poppins" }}>
                      {t("Choose one from your existing messages,or start a new one.")}
                    </p>

                    <button style={{ background: '#EF4F37', padding: '10px', width: '20rem', borderRadius: 36, color: '#ffff', border: 0 }} onClick={this.handleClickOpen}>
                      {t("New Message")}
                    </button>

                  </div>
            }

            </Grid>
          </Grid>
        </Grid>
       
      <Modal
  open={this.state.open}
  style={{display:'flex',alignItems:'center',justifyContent:'center'}}
  onClose={this.handleClose}
  
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
>
<ListOfUserWeb
removeSelectedUserHandler={this.removeSelectedUserHandler}
 open={this.state.open}
users={users}
updateSearch={this.updateSearch}
onClose={this.noClick}
onCloseData={this.handleClose}
 noClick={this.noClick}
 selectedUsersArray={this.state.selectedUsersArray}

 
        
      />
</Modal>
<Loader loading={this.state.loading} />
<ConfirmationWeb isModal={isModal} handleModal={this.handleModal} deleteAccount={()=>this.deleteChat(this.state.id)}/>



      
      </>
    );
  }

  async componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    this.getAllChatroom();
    this.getAllUsers();
  }
}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  title: {
    marginBottom: 2,
    fontSize: 14,
    textAlign: "left",
    marginTop: 16
  },

  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgDummyPassword: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    opacity: 0.4,
    fontWeight: "bold",
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 0,
    padding: 12,
    marginTop: 0
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    marginTop: 0,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  helperText: { marginTop: 10 }
});
// Customizable Area End

const InboxListStyle = {
  mainWrapper:
  {
    height: "100%",
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    },
  },
  mainBodyContent:
  {
    height: "89vh",
    overflow:'auto',
    // marginTop: 30,
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      padding: 10,
      height: "auto",
    },
  },
  hederInboxRow:
  {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "10px",
    // paddingRight: "15px",
    marginBottom: "20px",
    "& button":
    {
      padding: 0,
    },
    "& p":
    {
      margin: 0,
    }
  },
}
// Customizable Area End
export default withTranslation()(withStyles(InboxListStyle)(
  withRouter(InboxList as React.ComponentType<any>))
);


// export default withRouter(InboxList as React.ComponentType<any>);