//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Button, Dialog,
  DialogActions,
  DialogTitle, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import React from "react";
import { withTranslation } from 'react-i18next';
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";
import TalentsController, { Props } from "./TalentsController";

class TalentsList extends TalentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation } = this.props;
    const { talents } = this.state;
    const { classes, t , i18n } = this.props;
    return (
      <>
        <ProfileHeader heading={t("Talents")} subModule={true} showProfile={true} />
        <Grid
          sm={12}
          container={true}
          style={{ backgroundColor: "#FFFFFF" }}
          className={classes.talentBlk}
        >
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Grid
            sm={12}
            md={6}
            style={{
              border: "1px solid #DEDDDA",
              borderTop: "none"
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                onClick={() => {
                  localStorage.removeItem("talentSearchedUsers");
                  localStorage.removeItem("talentDetails");
                  this.props.history.push("/talents/search-user");
                }}
                style={{
                  padding: 10,
                  cursor: "pointer",
                  minHeight: 127,
                  width: "85%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: 43,
                  borderRadius: 16,
                  border: "1px solid #DBDBDB",
                  backgroundImage: "linear-gradient(to right, rgb(241, 78, 36), rgb(195, 0, 82))",
                }}
              >
                <Typography
                  style={{
                    color:"#fff",
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: 22
                  }}
                >
                  {t("Add New Talent")}
                </Typography>
                <Add
                  style={{
                    marginTop: 5,
                    color:"#fff",
                    height: 24,
                    width: 24
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                flexWrap="wrap"
                width="85%"
                mt="32px"
              >
                {talents.map((talent) => (
                  <Box
                    key={talent.id}
                    display="flex"
                    style={{
                      height: "auto",
                      minHeight: 105,
                      borderRadius: 16,
                      border: "1px solid #EBEEF0",
                      marginBottom: 7
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "22px 4px 22px 20px"
                      }}
                    >
                      <Box display="flex" width="70%" alignItems="center">
                        <Avatar
                          src={talent.attributes.talent_photo}
                          style={{
                            width: 48,
                            height: 48,
                            marginRight: 35,
                            borderRadius: 0
                          }}
                        />
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            style={{
                              color: "#081F32",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 18
                            }}
                          >
                            {talent.attributes.talent_name}
                          </Typography>
                          <Typography
                            style={{
                              color: "#6E798C",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 10,
                              marginTop: 4,
                              marginBottom: 2
                            }}
                          >
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 10
                              }}
                              component="span"
                            >
                              {talent.attributes.accounts?.data[0]?.attributes?.account?.data?.attributes?.brand?.data?.attributes?.name}
                            </Typography>{" "}
                            · {talent.attributes.social_handle}
                          </Typography>
                          
                          <Typography
                            style={{
                              color: "#6E798C",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 10,
                              marginTop: 4,
                              marginBottom: 2
                            }}
                          >
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 10
                              }}
                              component="span"
                            >
                              {t("Status")}
                            </Typography>{" "}
                            · {talent.attributes.accounts?.data[0]?.attributes?.status === 'Resend' ? 'Declined':talent.attributes.accounts?.data[0]?.attributes?.status}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                          display="flex"
                          width="30%"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Box display="flex">
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "talentDetails",
                                  JSON.stringify({
                                    id: talent.id,
                                    talentName:
                                    talent.attributes.talent_name,
                                    socialHandle:
                                    talent.attributes.social_handle,
                                    location: talent.attributes.location,
                                    description:
                                    talent.attributes.discription,
                                    logo: "",
                                    logoUrl:
                                    talent.attributes
                                        .talent_photo ?? ''
                                  })
                                );
                                this.props.history.push("/talents/create");
                              }}
                              variant="text"
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: 14,
                                justifyContent: "flex-start"
                              }}
                            >
                              {t("Edit")}
                            </Button>
                          </Box>
                          <Box display="flex">
                            <Button
                              onClick={() => {
                                this.setState({
                                  showDialog: true,
                                  talentId: talent.id
                                });
                              }}
                              variant="text"
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: 14,
                                justifyContent: "flex-start"
                              }}
                            >
                              {t("Delete")}
                            </Button>
                          </Box>
                          
                          {talent.attributes.accounts?.data[0]?.attributes?.status === 'Resend' && (
                             <Box display="flex">
                             <Button
                               onClick={() => {
                                this.resendNotification(talent.attributes.accounts?.data[0]?.attributes?.id)
                               }}
                               variant="text"
                               style={{
                                 color: "#F14E24",
                                 fontFamily: "Poppins",
                                 fontWeight: 500,
                                 fontSize: 14,
                                 justifyContent: "flex-start"
                               }}
                             >
                               {t("Resend")}
                             </Button>
                           </Box>
                          )}
                        </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Snackbar
            open={Boolean(this.state.error)}
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
            severity="error"
          />
          <Loader loading={this.state.loading} />
          
          <CatchInvalidToken
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
          />
        </Grid>
        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            { `${t("Are you sure you want to delete this talent?")}`}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ showDialog: false, talentId: null })
              }
              variant="text"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => this.deleteTalent(this.state.talentId)}
              variant="text"
              autoFocus
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getAllTalents();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
// Customizable Area End
const talentsListStyles = {
  profileBlk: {
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  imgLogo: {
    "@media (max-width: 768px)": {
      display: "none !important"
    }
  },
  talentBlk: {
    "@media (max-width: 768px)": {
      display: "block !important"
    }
  }
};
export default withTranslation()(withStyles(talentsListStyles)(
  withRouter(TalentsList as React.ComponentType<any>)
))
