//@ts-ignore
//@ts-nocheck
import { Box, Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { withTranslation } from 'react-i18next';
import TwitterLogin from "react-twitter-auth";
import Snackbar from "../../../components/src/Snackbar";
import AboutController, { Props } from "./AboutController.web";
import { facebookImg, twitchImg, twitterImg, youtubeImg } from "./assets";
import ProfileHeader from './ProfileHeader';
import SideMenu from "./SideMenu";
;

let formFields = {
  twitter: "",
  facbook: "",
  instagram: "",
  twitch: "",
  youtube: "",
  tiktok: "",
}
class ConnectSocialMedia extends AboutController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getSocialAccounts()
    this.handleTwitchSignup()
    this.googleResponse()
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  intializeFields = () => {
    this.state.socialAccounts.forEach(({ provider, user_name }) => {
      formFields = { ...formFields, [provider]: user_name }
    })
    return formFields
  }

  onFailed = () => {
    console.log("Failed")
  }
  onSuccess = (resp) => {
    resp.json().then(user => {
      if (user) {
        this.setState({ twitterUsername: user.data?.attributes?.user_name })
      }
    })
  }

  render() {
    const { classes, t , i18n } = this.props
    const { facebookUsername, googleUsername, twitterUsername, twitchUsername } = this.state

    const twitchStageUrl =
      "https://id.twitch.tv/oauth2/authorize?client_id=ob87fgrb8uq1cc3pah4zr2wcgzlhn5&redirect_uri=https://www.yup.gg/social-media&response_type=token+id_token&scope=user:read:email+openid&claims={%22id_token%22:{%22email%22:null,%22email_verified%22:null},%22userinfo%22:{%22picture%22:null,%22preferred_username%22:null}}";
    const token = localStorage.getItem("userToken")
    const twitterUrl = `https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/get_twitter_token?token=${token}&callback_url=${window.location.href}`

    return (
      <>
        <ProfileHeader heading={t("Social Media")} subModule="3" showProfile />
        <Grid className={classes.main + " " + classes.mainWrapper}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu subModule="3" showProfile />
            </Grid>
            <Grid item xs={12} md={6} className={classes.socialIcons}>
              <div className={classes.mainBodyContent}>
                <Typography className={classes.heading}>
                  {t("If you have several accounts to connect, don't worry--we'll bring you back to this screen after each profile is set up")}.
                </Typography>
                <div style={{ paddingBottom: 15, display: "flex" }}>
                  <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                    <img src={twitterImg} style={{ width: 26, height: "auto" }} />
                  </Box>
                  <Box display="flex" width="100%">
                    <TwitterLogin
                      loginUrl={twitterUsername ? "" : "https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/twitter/callback"}
                      onSuccess={this.onSuccess}
                      onFailed={this.onFailed}
                      requestTokenUrl={twitterUsername ? "" : twitterUrl}
                      className={classes.twitterBtn}
                      children={
                        <Button variant='contained' className={classes.btn}
                          style={{
                            backgroundColor: twitterUsername ? "black" : "#FFFFFF",
                            color: twitterUsername ? "white" : "rgb(184 184 189)",
                            width: "100%"
                          }}
                          onClick={() => { }}
                        >
                          <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: 14 }}> {twitterUsername ? twitterUsername : `${t("Connect Twitter")}`} </Typography>
                        </Button>
                      }
                    />
                  </Box>
                </div>
                  <div style={{ paddingBottom: 15, display: "flex" }}>

                    <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                      <img src={facebookImg} style={{ width: 26, height: "auto" }} />
                    </Box>

                    <Box display="flex" width="100%">
                      <FacebookLogin
                        fields="name,email,picture"
                        scope="email,public_profile"
                        appId="1800966006635263"
                        callback={this.responseFacebook}
                        returnScopes={true}
                        render={renderProps => (
                          <Button variant='contained'
                            className={classes.btn}
                            style={{
                              backgroundColor: facebookUsername ? "black" : "#FFFFFF",
                              color: facebookUsername ? "white" : "rgb(184 184 189)",
                            }}
                            onClick={facebookUsername ? () => { } : () => { renderProps.onClick() }}
                          >

                            <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: 14 }}> {facebookUsername ? facebookUsername : `${t("Connect Facebook")}`}</Typography>
                          </Button>
                        )}
                      />
                    </Box>

                  </div>

                <div style={{ paddingBottom: 15, display: "flex" }}>
                  <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                    <img src={youtubeImg} style={{ width: 26, height: "auto" }} />
                  </Box>
                  <Box display="flex" width="100%">

                    <Button variant='contained'
                      className={classes.btn}
                      style={{
                        backgroundColor: googleUsername ? "black" : "#FFFFFF",
                        color: googleUsername ? "white" : "rgb(184 184 189)",
                      }}
                      onClick={
                        googleUsername ? () => { } : () => { this.connectGoogle() }}
                    >
                      <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: 14 }}> {googleUsername ? googleUsername : `${t("Connect Youtube")}`}</Typography>
                    </Button>

                  </Box>
                </div>
                <div style={{ paddingBottom: 15, display: "flex" }}>
                  <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                    <img src={twitchImg} style={{ width: 26, height: "auto" }} />
                  </Box>
                  <Box display="flex" width="100%">
                    <Button
                      onClick={twitchUsername ? () => { } : (e) => (window.location = twitchStageUrl)}
                      variant="contained" className={classes.btn}
                      style={{
                        backgroundColor: twitchUsername ? "black" : "#FFFFFF",
                        color: twitchUsername ? "white" : "rgb(184 184 189)",
                      }}
                    >
                      <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: 14 }}>{twitchUsername ? twitchUsername : `${t("Connect Twitch")}`}</Typography>
                    </Button>
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity={this.state.isSucsess ? "success" : "error"}
        />
      </>
    )
  }
}


const SocialMediaStyle = {
  mainWrapper:
  {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    },
  },
  mainBodyContent:
  {
    height: "100vh",
    border: "#80808042 1px solid",
    padding: 42,
    marginTop: 50,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10,
      marginTop: 10,
    },
  },
  field: {
    border: "1px solid #DBDBDB",
    height: 50,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    paddingRight: 10,
    outline: "none",
    borderRadius: 16,
    '&:hover': {
      // borderColor: "black",
      // color: "white"
    },
    "&:focus": {
      background: "black",
      color: "white"
    }

  },
  heading: {
    color: "#191919",
    fontFamily: "Poppins",
    fontSize: 17,
    marginBottom: 50,
    fontWeight: 500,
  },
  btn: {
    border: "1px solid #D9DBE9",
    borderRadius: 16,
    width: "80%",
    mixWidth: "auto",
    height: 50,
    boxShadow: "none",
    justifyContent: "left",
    color: "rgb(184 184 189)",
    fontFamily: "Poppins",
    "&:hover": {
      backgroundColor: 'black !important',
      color: "white !important",
      boxShadow: "none",
    },
  },
  twitterBtn: {
    padding: 0,
    background: "none",
    border: "none",
    width: "80%",
  },
  main: {
    "@media (max-width: 768px)": {
      display: "block",
    }
  },
  socialIcons: {
    "@media (max-width: 768px)": {
      maxWidth: "100% !important",
    }
  },
}

export default withTranslation()(withStyles(SocialMediaStyle)(ConnectSocialMedia))