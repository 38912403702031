//@ts-ignore
//@ts-nocheck
import {
  Box, Button,
  Checkbox, Grid, IconButton, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CameraAltOutlined, CheckBox, Clear } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from 'react-i18next';
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import {
  checkboxIcon, stepOne
} from "./assets";
import CaseStudyController, { Props } from "./CaseStudyController";
import CropImage from "./CropImage.web";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";


class CaseStudyCreate extends CaseStudyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation } = this.props;
    const { classes, t, i18n } = this.props;
    const formDetails = JSON.parse(localStorage.getItem("caseStudyDetails"));

    return (

      <>
        <ProfileHeader
          heading={t("Case Study")}
          subModule={true}
          showProfile={true}
        />
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Formik
            initialValues={
              formDetails || {
                title: "",
                description: "",
                categories: [],
                banner: "",
                bannerUrl: ""
              }
            }
            validationSchema={this.CreateCaseStudySchema(t)}
            validateOnMount={true}
            onSubmit={(values) => {
              localStorage.setItem("caseStudyDetails", JSON.stringify(values));
              this.props.history.push("/caseStudy/media");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldError
            }) => (
              <Grid
                sm={12}
                md={6}
                style={{
                  border: "1px solid #DEDDDA",
                  borderTop: "none",
                  width: '100%',
                }}
              >
                <Box className={classes.progressBarRow}>
                  <Typography
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 13,
                      marginRight: 14
                    }}
                  >
                    {t("Step")} 1 of 3
                  </Typography>
                  <img src={stepOne} style={{ height: 12, width: 185 }} />
                </Box>
             
                <Form className={classes.form}
                  translate="yes"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 52,
                    paddingRight: 41,
                    paddingTop: 40
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Case Study title")}
                    </Typography>
                    <Field
                      name="title"
                      placeholder={t("Insert a name for your CaseStudy")}
                      style={{
                        border: "1px solid #DBDBDB",
                        height: 45,
                        width: "100%",
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 16,
                        paddingRight: 10,
                        outline: "none",
                        borderRadius: 16
                      }}
                    />
                    {errors.title && touched.title ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.title}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box display="flex" flexDirection="column" mt="38px">
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Add description")}
                    </Typography>

                    <textarea
                      value={values.description}
                      name="description"
                      onChange={handleChange("description")}
                      placeholder={t("Tell a bit more about the case study")}
                      style={{
                        border: "1px solid #DBDBDB",
                        height: 119,
                        width: "100%",
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 16,
                        paddingRight: 10,
                        paddingTop: 18,
                        outline: "none",
                        borderRadius: 16,
                        resize: "none"
                      }}
                    />
                    {errors.description && touched.description ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.description}
                      </Typography>
                    ) : null}
                  </Box>



                  <Box display="flex" flexDirection="column" mt="33px">
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("How do you categorize this case study")}?
                    </Typography>
                    <Grid className={classes.study}
                      sm={12}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Grid
                        sm={12}
                        md={4}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Checkbox
                          checked={values.categories.includes("graphic_design")}
                          onChange={() =>
                            this.handleSelectCategories(
                              "graphic_design",
                              values.categories,
                              setFieldValue
                            )
                          }
                          style={{ padding: 0, marginRight: 10 }}
                          checkedIcon={
                            <CheckBox
                              style={{
                                color: "#F14E24",
                                width: 16,
                                height: 16,
                                padding: 0,
                                margin: 0
                              }}
                            />
                          }
                          icon={
                            <img
                              src={checkboxIcon}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                        />
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 13
                          }}
                        >
                          {t("Graphic design")}
                        </Typography>
                      </Grid>
                      <Grid
                        sm={12}
                        md={4}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Checkbox
                          checked={values.categories.includes("ui_ux")}
                          onChange={() =>
                            this.handleSelectCategories(
                              "ui_ux",
                              values.categories,
                              setFieldValue
                            )
                          }
                          style={{ padding: 0, marginRight: 10 }}
                          checkedIcon={
                            <CheckBox
                              style={{
                                color: "#F14E24",
                                width: 16,
                                height: 16,
                                padding: 0,
                                margin: 0
                              }}
                            />
                          }
                          icon={
                            <img
                              src={checkboxIcon}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                        />
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 13
                          }}
                        >
                          {t("UI/UX")}
                        </Typography>
                      </Grid>
                      <Grid
                        sm={12}
                        md={4}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Checkbox
                          checked={values.categories.includes("architecture")}
                          onChange={() =>
                            this.handleSelectCategories(
                              "architecture",
                              values.categories,
                              setFieldValue
                            )
                          }
                          style={{ padding: 0, marginRight: 10 }}
                          checkedIcon={
                            <CheckBox
                              style={{
                                color: "#F14E24",
                                width: 16,
                                height: 16,
                                padding: 0,
                                margin: 0
                              }}
                            />
                          }
                          icon={
                            <img
                              src={checkboxIcon}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                        />
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 13
                          }}
                        >
                          {t("Architecture")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className={classes.studyBlk1}
                      sm={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 12
                      }}
                    >
                      <Grid
                        sm={12}
                        md={4}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Checkbox
                          checked={values.categories.includes("illustration")}
                          onChange={() =>
                            this.handleSelectCategories(
                              "illustration",
                              values.categories,
                              setFieldValue
                            )
                          }
                          style={{ padding: 0, marginRight: 10 }}
                          checkedIcon={
                            <CheckBox
                              style={{
                                color: "#F14E24",
                                width: 16,
                                height: 16,
                                padding: 0,
                                margin: 0
                              }}
                            />
                          }
                          icon={
                            <img
                              src={checkboxIcon}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                        />
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 13
                          }}
                        >
                          {t("Illustration")}
                        </Typography>
                      </Grid>
                      <Grid
                        sm={12}
                        md={4}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Checkbox
                          checked={values.categories.includes("fashion")}
                          onChange={() =>
                            this.handleSelectCategories(
                              "fashion",
                              values.categories,
                              setFieldValue
                            )
                          }
                          style={{ padding: 0, marginRight: 10 }}
                          checkedIcon={
                            <CheckBox
                              style={{
                                color: "#F14E24",
                                width: 16,
                                height: 16,
                                padding: 0,
                                margin: 0
                              }}
                            />
                          }
                          icon={
                            <img
                              src={checkboxIcon}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                        />
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 13
                          }}
                        >
                          {t("Fashion")}
                        </Typography>
                      </Grid>
                      <Grid
                        sm={12}
                        md={4}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Checkbox
                          checked={values.categories.includes("advertising")}
                          onChange={() =>
                            this.handleSelectCategories(
                              "advertising",
                              values.categories,
                              setFieldValue
                            )
                          }
                          style={{ padding: 0, marginRight: 10 }}
                          checkedIcon={
                            <CheckBox
                              style={{
                                color: "#F14E24",
                                width: 16,
                                height: 16,
                                padding: 0,
                                margin: 0
                              }}
                            />
                          }
                          icon={
                            <img
                              src={checkboxIcon}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                        />
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 13
                          }}
                        >
                          {t("Advertising")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className={classes.studyBlk2}
                      sm={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 12
                      }}
                    >
                      <Grid
                        sm={12}
                        md={4}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Checkbox
                          checked={values.categories.includes("photography")}
                          onChange={() =>
                            this.handleSelectCategories(
                              "photography",
                              values.categories,
                              setFieldValue
                            )
                          }
                          style={{ padding: 0, marginRight: 10 }}
                          checkedIcon={
                            <CheckBox
                              style={{
                                color: "#F14E24",
                                width: 16,
                                height: 16,
                                padding: 0,
                                margin: 0
                              }}
                            />
                          }
                          icon={
                            <img
                              src={checkboxIcon}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                        />
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 13
                          }}
                        >
                          {t("Photography")}
                        </Typography>
                      </Grid>
                      <Grid
                        sm={12}
                        md={4}
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Checkbox
                          checked={values.categories.includes("product_design")}
                          onChange={() =>
                            this.handleSelectCategories(
                              "product_design",
                              values.categories,
                              setFieldValue
                            )
                          }
                          style={{ padding: 0, marginRight: 10 }}
                          checkedIcon={
                            <CheckBox
                              style={{
                                color: "#F14E24",
                                width: 16,
                                height: 16,
                                padding: 0,
                                margin: 0
                              }}
                            />
                          }
                          icon={
                            <img
                              src={checkboxIcon}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                        />
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 13
                          }}
                        >
                          {t("Product Design")}
                        </Typography>
                      </Grid>
                    </Grid>
                    {errors.categories && touched.categories ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 10
                        }}
                      >
                        {errors.categories}
                      </Typography>
                    ) : null}
                  </Box>


                  <Box display="flex" flexDirection="column" mt="33px">
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Clients")}
                    </Typography>
                    <Field
                      name="clients"
                      placeholder={t("Garena, Riot Games, Blizzard...")}
                      style={{
                        border: "1px solid #DBDBDB",
                        height: 45,
                        width: "100%",
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 16,
                        paddingRight: 10,
                        outline: "none",
                        borderRadius: 16
                      }}
                    />
                    {errors.clients && touched.clients ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.clients}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box display="flex" flexDirection="column" mt="33px" mb="40px">
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Case banner")}
                    </Typography>

                    <Box
                      style={{
                        border: "1px solid #DBDBDB",
                        height: 244,
                        width: "100%",
                        outline: "none",
                        borderRadius: 16,
                        resize: "none",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundSize: values.bannerUrl? "cover" : "",
                        backgroundRepeat: values.bannerUrl? "no-repeat": "",
                        backgroundImage: values.bannerUrl
                          ? `url(${values.bannerUrl})`
                          : ""
                      }}
                    >
                      {this.state.isBannerImage && (
                        <CropImage
                          handleClose={() => {
                            setFieldValue("banner", null);
                            setFieldValue("bannerUrl", null);
                            this.setState({ isBannerImage: false });
                          }}
                          url={values.bannerUrl}
                          open={this.state.isBannerImage}
                          getCropImageUrl={(url) => {
                            setFieldValue("banner", url);
                            setFieldValue("bannerUrl", url);
                            this.setState({ isBannerImage: false });
                          }}
                        />
                      )}
                      <input
                        type="file"
                        onChange={(e: any) => {
                          this.handleSelectBanner(
                            e,
                            setFieldValue,
                            setFieldError
                          )
                        }}
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          zIndex: 2,
                          cursor: "pointer",
                          opacity: 0
                        }}
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      {values.bannerUrl && (
                        <Box style={{ zIndex: 10 }}>
                          <IconButton
                            style={{ marginRight: 30, position: "relative" }}
                          >
                            <input
                              type="file"
                              onChange={(e: any) => {
                                this.handleSelectBanner(
                                  e,
                                  setFieldValue,
                                  setFieldError
                                )
                              }}
                              style={{
                                position: "absolute",
                                zIndex: 2,
                                height: "100%",
                                width: "100%",
                                cursor: "pointer",
                                opacity: 0
                              }}
                              accept="image/png, image/jpeg, image/jpg"
                            />
                            <CameraAltOutlined
                              style={{
                                color: "white",
                                width: 24,
                                height: "auto"
                              }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={(e: any) =>
                              this.handleSelectBanner("", setFieldValue, setFieldError)
                            }
                          >
                            <Clear
                              style={{
                                color: "white",
                                width: 26,
                                height: "auto"
                              }}
                            />
                          </IconButton>
                        </Box>
                      )}
                      {values.bannerUrl && (
                        <Box
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            zIndex: 5,
                            backgroundColor: "black",
                            opacity: 0.5
                          }}
                        />
                      )}

                      {!values.bannerUrl && (
                        <Typography
                          style={{
                            color: "rgba(0, 0, 0, 0.38)",
                            fontFamily: "Poppins",
                            fontSize: 22
                          }}
                        >
                          {t("Choose banner")}
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex">
                      <Typography
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 12,
                          marginTop: "10px",
                        }}
                      >
                        {t("Ideal size")}:{" "}
                        <Typography
                          style={{
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 12,
                            display: "inline"
                          }}
                        >
                          {t("1920x1080px")}
                        </Typography>
                      </Typography>
                    </Box>
                    {errors.bannerUrl && touched.bannerUrl ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.bannerUrl}
                      </Typography>
                    ) : null}

                    <Box display="flex" justifyContent="flex-end" className={classes.btnNext}>
                      <Button
                        variant="contained"
                        type="submit"
                        style={{
                          backgroundColor: "#F14E24",
                          border: "1px solid #D9DBE9",
                          borderRadius: 16,
                          height: 34,
                          marginBottom: 14,
                          boxShadow: "none",
                          color: "#F7F7FC",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14,
                          marginTop: 30,
                          padding: "20px 32px",
                        }}
                      >
                        {t("Next")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Grid>
            )}
          </Formik>
        </Grid>
      </>

    );
  }

  async componentDidMount() {
    // this.getValidations();
    // this.requestSessionData();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
const CaseStudyCreateStyles = {
  form: {
    "@media (max-width: 767px)": {
      padding: "10px !important",
    }
  },
  stepOne: {
    "@media (max-width: 767px)": {
      display: "block !important",
      marginLeft: "10px",
    }
  },
  btnShare: {
    "@media (max-width: 767px)": {
      marginLeft: "10px",
    }
  },
  btnNext: {
    "@media (max-width: 767px)": {
      justifyContent: "flex-start",
    }
  },
  study: {
    "@media (max-width: 768px)": {
      flexDirection: "column !important",
    }
  },
  studyBlk2: {
    "@media (max-width: 768px)": {
      flexDirection: "column !important",
      marginTop: "0px !important",
    }
  },
  studyBlk1: {
    "@media (max-width: 768px)": {
      flexDirection: "column !important",
      marginTop: "0px !important",
    }
  },
  progressBarRow: {
    marginRight: "30px",
    marginTop: "-39px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media (max-width: 768px)": {
      marginRight: "0",
      marginTop: "-32px",
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      marginTop: "15px",
    },
  },
}
// Customizable Area End
export default withTranslation()(withStyles(CaseStudyCreateStyles)(withRouter(CaseStudyCreate as React.ComponentType<any>)))
