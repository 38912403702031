//@ts-ignore
//@ts-nocheck
import {
  Box, Button, Dialog,
  DialogActions,
  DialogTitle, Grid, IconButton, Popover, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { MoreHoriz } from "@material-ui/icons";
import React from "react";
import { withTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import { instagramIcon, twitchIcon, twitterIcon, youtubeIcon } from "./assets";
import ListingsController, { Props } from "./ListingsController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";


class ListingsList extends ListingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, t, i18n } = this.props;
    const { navigation } = this.props;
    const { listings } = this.state;

    const subscriptions = {};
    const currencies = {};
    const durations = {};

    for (let i = 0; i < this.Subscriptions.length; i++) {
      subscriptions[this.Subscriptions[i].value] = this.Subscriptions[i].label;
    }

    for (let i = 0; i < this.Currencies.length; i++) {
      currencies[this.Currencies[i].value] = this.Currencies[i].label;
    }

    for (let i = 0; i < this.Durations.length; i++) {
      durations[this.Durations[i].value] = this.Durations[i].label;
    }

    return (
      <>
        <ProfileHeader
          heading={t("Listings")}
          subModule={true}
          showProfile={true}
        />
        <Grid className={classes.mainWrapper}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid sm={12} md={6} className={classes.bodyContent}>
              <Box className={classes.mainBodyContent}>
                <Box
                  onClick={() => {
                    localStorage.removeItem("listingDetails");
                    localStorage.removeItem("listingPricing");
                    this.props.history.push("/listing/create");
                  }}
                  style={{
                    padding: 10,
                    cursor: "pointer",
                    backgroundImage:
                      "linear-gradient(to right, rgb(241, 78, 36), rgb(195, 0, 82))",
                    minHeight: 127,
                    width: "86%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 43,
                    marginBottom: 20,
                    backgroundColor: "#FAFAFA",
                    boxShadow:
                      "0px 1px 1px rgba(0, 0, 0, 0.141176), 0px 2px 1px -1px rgba(0, 0, 0, 0.121569), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                    borderRadius: 16
                  }}
                >
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      fontSize: 22
                    }}
                  >
                    {t("Create a new listing")}
                  </Typography>
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: 12,
                      marginTop: 13
                    }}
                  >
                    {t("Create a new listing or use a template")}
                  </Typography>
                </Box>
                {listings.map((listing) => (
                  <Box
                    key={listing.id}
                    style={{
                      background: "#f7f7fc",
                      borderRadius: "16px",
                      padding: "20px",
                      display: "flex",
                      width: "86%",
                      marginBottom: "10px",
                      position: "relative"
                    }}
                  >
                    <Box
                      style={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        zIndex: 10
                      }}
                    >
                      <IconButton
                        style={{ backgroundColor: "transparent" }}
                        onClick={(e) => this.handlePopoverOpen(e, listing.id)}
                      >
                        <MoreHoriz
                          style={{ width: 24, height: 24, color: "#000" }}
                        />
                      </IconButton>

                      <Popover
                        open={this.state.openedPopoverId === listing.id}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                      >
                        <Box display="flex" flexDirection="column">
                          <Button
                            onClick={() => {
                              localStorage.setItem(
                                "listingDetails",
                                JSON.stringify({
                                  id: listing.id,
                                  title: listing.attributes.title,
                                  description: listing.attributes.description,
                                  category:
                                    listing.attributes.category ||
                                    "social_media_campaign",
                                  banner: "",
                                  bannerUrl: listing.attributes.banner
                                })
                              );
                              localStorage.setItem(
                                "listingPricing",
                                JSON.stringify({
                                  id: listing.id,
                                  currency: JSON.stringify({
                                    label:
                                      currencies[
                                        listing.attributes.pricing_currency
                                      ],
                                    value: listing.attributes.pricing_currency
                                  }),
                                  price: listing.attributes.pricing,
                                  subscription: JSON.stringify({
                                    label:
                                      subscriptions[
                                        listing.attributes.pricing_type
                                      ],
                                    value: listing.attributes.pricing_type
                                  }),
                                  duration: JSON.stringify({
                                    label:
                                      durations[listing.attributes.duration],
                                    value: listing.attributes.duration
                                  }),
                                  mediaChannels:
                                    listing.attributes.media_channel
                                })
                              );
                              this.props.history.push("/listing/create");
                            }}
                            variant="text"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 12,
                              textAlign: "left",
                              justifyContent: "flex-start"
                            }}
                          >
                            {t("Edit")}
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                showDialog: true,
                                listingId: listing.id
                              });
                            }}
                            variant="text"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 12,
                              textAlign: "left",
                              justifyContent: "flex-start"
                            }}
                          >
                            {t("Delete")}
                          </Button>
                        </Box>
                      </Popover>
                    </Box>
                    <div>
                      <img
                        src={this.getCategoryImage(listing.attributes.category)}
                        style={{
                          height: "70px",
                          width: "70px",
                          objectFit: "conatin"
                        }}
                      />
                    </div>
                    <Box style={{ paddingLeft: "25px", width: "80%" }}>
                      <Typography
                        style={{
                          color: "#6e798c",
                          fontSize: "10px",
                          fontWeight: "600",
                          textTransform: "uppercase"
                        }}
                      >
                        {t(this.getCategoryName(listing.attributes.category))}
                      </Typography>
                      <Typography
                        style={{
                          color: "#081f32",
                          fontWeight: "600",
                          fontSize: "16px",
                          padding: "5px 0"
                        }}
                      >
                        {listing.attributes.title}
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <Typography
                          style={{
                            color: "#16cf7c",
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            fontWeight: "400"
                          }}
                        >
                          {t("Price")}:{" "}
                          <span>{`${
                            currencies[listing.attributes.pricing_currency]
                          }${listing.attributes.pricing} ${t(
                            subscriptions[listing.attributes.pricing_type]
                          )}`}</span>
                        </Typography>
                        <div style={{ display: "flex" }}>
                          {listing.attributes.media_channel.includes(
                            "instagram"
                          ) && (
                            <Box>
                              <img
                                src={instagramIcon}
                                style={{
                                  height: 10,
                                  width: 10
                                }}
                              />
                            </Box>
                          )}
                          {listing.attributes.media_channel.includes(
                            "twitter"
                          ) && (
                            <Box ml={1}>
                              <img
                                src={twitterIcon}
                                style={{
                                  height: 10,
                                  width: 10
                                }}
                              />
                            </Box>
                          )}
                          {listing.attributes.media_channel.includes(
                            "twitch"
                          ) && (
                            <Box ml={1}>
                              <img
                                src={twitchIcon}
                                style={{
                                  height: 10,
                                  width: 10
                                }}
                              />
                            </Box>
                          )}
                          {listing.attributes.media_channel.includes(
                            "youtube"
                          ) && (
                            <Box ml={1}>
                              <img
                                src={youtubeIcon}
                                style={{
                                  height: 10,
                                  width: 10
                                }}
                              />
                            </Box>
                          )}
                        </div>
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid sm={12} md={3} />
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        <Loader loading={this.state.loading} />

        <CatchInvalidToken
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
        />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("Are you sure you want to delete this listing?")}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ showDialog: false, listingId: null })
              }
              variant="text"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => this.deleteListing(this.state.listingId)}
              variant="text"
              autoFocus
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  async componentDidMount() {
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
    this.getAllListings();
  }
}
// Customizable Area Start

// Customizable Area End

const listingStyle = {
  productPlacementCard: {
    display: "flex",

    justifyContent: "space-between",
    margin: "34px 6px 0 6px",
    flexWrap: "wrap",
    "@media (max-width: 768px)": {
      justifyContent: "space-between"
    },
    "@media (min-width: 767px)": {
      width: "60%"
    }
  },
  productPlacementSubCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "116px",
    margin: "0 6px",
    marginBottom: 10,
    cursor: "pointer",
    "@media (max-width: 767px)": {
      width: "100%"
    }
  },
  productImg: {
    "@media (width: 768px)": {
      marginRight: "45px !important"
    }
  },
  productImg1: {
    "@media (width: 768px)": {
      marginRight: "27px !important"
    }
  },
  productImg2: {
    "@media (width: 768px)": {
      marginRight: "10px !important"
    }
  },
  mainWrapper: {
    height: "100%",
    width: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    }
  },
  bodyContent: {
    width: "100%"
  },
  mainBodyContent: {
    height: "100%",
    // height: "100%",
    width: "100%",
    // marginTop: 30,
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      padding: 10
    }
  }
};

export default withTranslation()(
  withStyles(listingStyle)(withRouter(ListingsList as React.ComponentType<any>))
);
