//@ts-ignore
//@ts-nocheck
import { Typography, Checkbox, Box } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import Grid from "@material-ui/core/Grid";
import ProfileHeader from "../../user-profile-basic/src/ProfileHeader";
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import SubSideMenu from "./SubSideMenu.web";
import EmailAndSmsController from "./EmailAndSmsController";
import { withTranslation } from 'react-i18next';

class EmailAndSms extends EmailAndSmsController {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes, t, i18n } = this.props;

    return (
      <>
        <ProfileHeader heading={t("Subscribe to")} subModule="3" />
        <Grid container className={classes.mainContainer}>
          <Grid item sm={3} xs={12} style={{ position: "relative" }}>
            <SideMenu subModule="3" />
          </Grid>
          <Grid item xs={2} className={classes.settingIcon}>
            <SubSideMenu handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className={classes.smsBlock}>
              <div style={{ marginBottom: "10px" }}>
                <Box display="flex" mt="10px" alignItems="flex-start">
                  <Checkbox
                    style={{ padding: 0, marginRight: 4, marginTop: 4 }}
                    name="FeedbackEmails"
                    color="primary"
                    value="feedback_emails"
                    checked={this.state.FeedbackEmailsCheckedOrNot}
                    onClick={this.handleChange}
                    checkedIcon={<CheckBox className={classes.checkBox} />}
                  />
                  <Typography className={classes.text}>{t("Feedback Emails")}</Typography>
                </Box>
                <Typography className={classes.subText}>{t("Give Feedback on yup.gg")}</Typography>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Box display="flex" mt="10px" alignItems="flex-start">
                  <Checkbox
                    style={{ padding: 0, marginRight: 4, marginTop: 4 }}
                    name="ReminderEmails"
                    value="reminder_emails"
                    color="primary"
                    checked={this.state.ReminderEmailsCheckedOrNot}
                    onClick={this.handleChange}
                    checkedIcon={<CheckBox className={classes.checkBox} />}
                  />
                  <Typography className={classes.text}>{t("Reminder Emails")}</Typography>
                </Box>

                <Typography className={classes.subText}>{t("Get a notification you may have missed.")}</Typography>
              </div>

              <div style={{ marginBottom: "10px" }}>
                <Box display="flex" mt="10px" alignItems="flex-start">
                  <Checkbox
                    style={{ padding: 0, marginRight: 4, marginTop: 4 }}
                    name="ProductEmails"
                    value="product_emails"
                    color="primary"
                    checked={this.state.ProductEmailsCheckedOrNot}
                    onClick={this.handleChange}
                    checkedIcon={<CheckBox className={classes.checkBox} />}
                  />
                  <Typography className={classes.text}>{t("Product Emails")}</Typography>
                </Box>
                <Typography className={classes.subText}>{t("Get tip's about yup tool.")}</Typography>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Box display="flex" mt="10px" alignItems="flex-start">
                  <Checkbox
                    style={{ padding: 0, marginRight: 4, marginTop: 4 }}
                    name="NewsEmails"
                    color="primary"
                    value="news_emails"
                    checked={this.state.NewsEmailsCheckedOrNot}
                    onClick={this.handleChange}
                    checkedIcon={<CheckBox className={classes.checkBox} />}
                  />
                  <Typography className={classes.text}>{t("News Emails")}</Typography>
                </Box>
                <Typography className={classes.subText}>{t("Learn about new yup.gg Features")}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

const style = {
  smsBlock: {
    padding: "15px 50px 0px 50px",
    position: "relative",
    "@media (max-width: 768px)": {
      marginTop: 0,
    }
  },
  mainContainer: {
    paddingBottom: 0,
    height: "100%",
    "@media (max-width: 768px)": {
      height: 0
    }
  },
  settingIcon: {
    borderLeft: "1px solid rgba(128, 128, 128, 0.26)",
    borderRight: "1px solid rgba(128, 128, 128, 0.26)",
    "@media (max-width: 768px)": {
      borderLeft: "none",
      borderRight: "none",
      position: "absolute",
      right: "40px",
      top: "16px",
    }
  },
  text: {
    fontFamily: "Poppins",
    color: "#7B7B7B",
    fontSize: "1.3rem",
    fontWeight: "600",
    padding: 6,
  },
  checkBox: {
    color: "#F14E24",
    padding: 0,
    margin: 0,
  },
  subText: {
    color: "#a8a8a8",
    fontSize: "1.2rem",
    fontFamily: "Poppins"
  }
}

export default withTranslation()(withStyles(style)(EmailAndSms))
