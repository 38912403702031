//@ts-ignore
//@ts-nocheck
import {
  Box, Button, Grid, MenuItem,
  Select, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import { steptwo } from "./assets";
import ListingsController, { Props } from "./ListingsController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class ListingPricing extends ListingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  Subscriptions = [
    {
      label: "At Completion",
      value: "at_completion"
    },
    {
      label: "Per Day",
      value: "per_day"
    },
    {
      label: "Per Week",
      value: "per_week"
    },
    {
      label: `${this.props.t("Per Month")}`,
      value: "per_month"
    },
    {
      label: "Per Quarter",
      value: "per_quarter"
    },
    {
      label: "Semi-Annually",
      value: "semi_annually"
    },
    {
      label: "Per Year",
      value: "per_year"
    }
  ];

  render() {
    const { classes, t, i18n } = this.props;
    const { navigation } = this.props;

    const formDetails = JSON.parse(localStorage.getItem("listingDetails"));

    const existingPricing = JSON.parse(localStorage.getItem("listingPricing"));

    if (!formDetails) {
      this.props.history.replace("/listings");
      return null;
    }

    return (
      <>
        <ProfileHeader
          heading={t("New Listing")}
          subModule={true}
          showProfile={true}
          multiPage
          backLocation="/listing/create"
        />
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>

          <Formik
            className={classes.month}
            initialValues={
              existingPricing || {
                currency: JSON.stringify(this.Currencies[0]) || "",
                price: "",
                subscription: JSON.stringify({
                  label: `${t("Per Month")}`,
                  value: "per_month"
                }),
                duration: "",
                mediaChannels: []
              }
            }
            validationSchema={this.ListingPricingSchema(t)}
            validateOnMount={true}
            onSubmit={(values) => {
              localStorage.setItem("listingPricing", JSON.stringify(values));
              this.props.history.push("/listing/preview");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue
            }) => (
              <Grid
                sm={12}
                md={6}
                style={{
                  border: "1px solid #DEDDDA",
                  borderTop: "none",
                  height: "100%",
                  width: "100%"
                }}
              >
                <Box className={classes.progressBarRow}>
                  <Typography
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 13,
                      marginRight: 14
                    }}
                  >
                    {t("Step")} 2 of 3
                  </Typography>
                  <img src={steptwo} style={{ height: 12, width: 185 }} />
                </Box>
                <Form translate="yes" className={classes.listingFrm}>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      style={{
                        color: "#191919",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Pricing")}
                    </Typography>

                    <Box display="flex">
                      <Select
                        className={classes.dollar}
                        disableUnderline={true}
                        classes={{
                          select: this.props.classes.currencyDropdown
                        }}
                        style={{
                          width: "29%"
                        }}
                        IconComponent={() => (
                          <KeyboardArrowDown
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 11,
                              height: 24,
                              width: 24,
                              color: "#8E8E8E"
                            }}
                          />
                        )}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.currency}
                        onChange={handleChange("currency")}
                      >
                        {this.Currencies.map((currency, index) => (
                          <MenuItem
                            className={classes.formTitle}
                            key={index}
                            value={JSON.stringify(currency)}
                          >
                            {currency.label}
                          </MenuItem>
                        ))}
                      </Select>

                      <Field
                        style={{
                          width: "30%"
                        }}
                        name="price"
                        placeholder={t("Enter Price")}
                        type="number"
                        className={classes.formLabel}
                      />

                      <Select
                        disableUnderline={true}
                        className={classes.month}
                        classes={{
                          select: this.props.classes.subscriptionDropdown
                        }}
                        style={{
                          width: "42%"
                        }}
                        IconComponent={() => (
                          <KeyboardArrowDown
                            className={classes.formDropdown}
                            style={{}}
                          />
                        )}
                        placeholder={t("select duration")}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.subscription}
                        onChange={handleChange("subscription")}
                        className={classes.month}
                      >
                        {this.Subscriptions.map((subscription, index) => (
                          <MenuItem
                            className={classes.formTitle}
                            key={index}
                            value={JSON.stringify(subscription)}
                          >
                            {t(subscription.label)}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    {errors.price && touched.price ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.price}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box display="flex" flexDirection="column" mt="38px">
                    <Typography
                      style={{
                        color: "#191919",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Duration")}
                    </Typography>

                    <Select
                      disableUnderline={true}
                      classes={{
                        select: this.props.classes.durationDropdown
                      }}
                      style={{
                        width: "30"
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDown
                          style={{
                            position: "absolute",
                            left: 10,
                            top: 11,
                            height: 24,
                            width: 24,
                            color: "#8E8E8E"
                          }}
                        />
                      )}
                      renderValue={(value) =>
                        value ? value : `${t("Select duration")}`
                      }
                      displayEmpty={true}
                      value={
                        values.duration
                          ? `${t(JSON.parse(values.duration).label)}`
                          : ""
                      }
                      onChange={handleChange("duration")}
                    >
                      {this.Durations.map((duration, index) => (
                        <MenuItem
                          style={{
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14
                          }}
                          key={index}
                          value={JSON.stringify(duration)}
                        >
                          {t(duration.label)}
                        </MenuItem>
                      ))}
                    </Select>

                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 12,
                        marginTop: 5
                      }}
                    >
                      {t("Current category")}:{" "}
                      <Typography
                        component="span"
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          fontSize: 12,
                          display: "inline"
                        }}
                      >
                        {t(localStorage.getItem("userCategory"))}
                      </Typography>
                    </Typography>

                    {errors.duration && touched.duration ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.duration}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box display="flex" flexDirection="column" mt="38px">
                    <Typography
                      style={{
                        color: "#191919",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Select media channels")}
                    </Typography>
                    <Box display="flex" justifyContent="space-between">
                      <Button
                        onClick={() =>
                          this.selectMediaChannelsHander(
                            "twitter",
                            values.mediaChannels,
                            setFieldValue
                          )
                        }
                        style={{
                          color: "rgba(0, 0, 0, 0.38)",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14,
                          borderRadius: 16,
                          border: `1px solid ${
                            values.mediaChannels.includes("twitter")
                              ? "#F14E24"
                              : "#EBEEF0"
                          }`,
                          height: 50,
                          width: "49%"
                        }}
                      >
                        {t("Twitter")}
                      </Button>
                      <Button
                        onClick={() =>
                          this.selectMediaChannelsHander(
                            "instagram",
                            values.mediaChannels,
                            setFieldValue
                          )
                        }
                        style={{
                          color: "rgba(0, 0, 0, 0.38)",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14,
                          borderRadius: 16,
                          border: `1px solid ${
                            values.mediaChannels.includes("instagram")
                              ? "#F14E24"
                              : "#EBEEF0"
                          }`,
                          height: 50,
                          width: "49%"
                        }}
                      >
                        {t("Instagram")}
                      </Button>
                    </Box>

                    <Box display="flex" justifyContent="space-between" mt="5px">
                      <Button
                        onClick={() =>
                          this.selectMediaChannelsHander(
                            "twitch",
                            values.mediaChannels,
                            setFieldValue
                          )
                        }
                        style={{
                          color: "rgba(0, 0, 0, 0.38)",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14,
                          marginBottom: 17,
                          borderRadius: 16,
                          border: `1px solid ${
                            values.mediaChannels.includes("twitch")
                              ? "#F14E24"
                              : "#EBEEF0"
                          }`,
                          height: 50,
                          width: "49%"
                        }}
                      >
                        {t("Twitch")}
                      </Button>
                      <Button
                        onClick={() =>
                          this.selectMediaChannelsHander(
                            "youtube",
                            values.mediaChannels,
                            setFieldValue
                          )
                        }
                        style={{
                          color: "rgba(0, 0, 0, 0.38)",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14,
                          marginBottom: 17,
                          borderRadius: 16,
                          border: `1px solid ${
                            values.mediaChannels.includes("youtube")
                              ? "#F14E24"
                              : "#EBEEF0"
                          }`,
                          height: 50,
                          width: "49%"
                        }}
                      >
                        {t("YouTube")}
                      </Button>
                    </Box>

                    {errors.mediaChannels && touched.mediaChannels ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.mediaChannels}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    mt="16px"
                    mb="40px"
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{
                          backgroundColor: "#F14E24",
                          border: "1px solid #D9DBE9",
                          borderRadius: 16,
                          height: 34,
                          marginBottom: 14,
                          boxShadow: "none",
                          color: "#F7F7FC",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14,
                          marginTop: 30
                        }}
                      >
                        {t("Next")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Grid>
            )}
          </Formik>
        </Grid>
      </>
    );
  }

  async componentDidMount() {
    // this.getValidations();
    // this.requestSessionData();
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
export const listingPricingStyle = {
  currencyDropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 24,
    // paddingRight: 10,
    outline: "none",
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    borderRightWidth: 0,
    "&:focus": {
      borderTopLeftRadius: 16,
      borderBottomLeftRadius: 16,
      borderRightWidth: 0
      // backgroundColor: "white",
      // zIndex: 2
    }
  },
  subscriptionDropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 4,
    paddingRight: 10,
    outline: "none",
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    borderLeftWidth: 0,
    "&:focus": {
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
      borderLeftWidth: 0
      // backgroundColor: "white",
      // zIndex: 2
    }
  },
  durationDropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 40,
    paddingRight: 10,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
      // backgroundColor: "white",
      // zIndex: 2
    }
  },
  dollar: {
    "@media(max-width: 767px)": {
      // width:"42% !important",
      fontSize: 11
    }
  },
  month: {
    "@media(max-width: 767px)": {
      marginRight: "-14px !important",
      marginLeft: "-2px !important"
    }
  },
  listingFrm: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 52,
    paddingRight: 41,
    paddingTop: 40,
    "@media(max-width: 767px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  formLabel: {
    border: "1px solid #DBDBDB",
    height: 47,
    width: "20%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 5,
    paddingRight: 10,
    outline: "none",
    "@media(max-width: 767px)": {
      // fontSize: 11,
    }
  },
  formTitle: {
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    "@media(max-width: 767px)": {
      fontSize: 11
    }
  },
  formDropdown: {
    position: "absolute",
    top: 11,
    right: 10,
    height: 24,
    width: 24,
    color: "#8E8E8E",
    "@media(max-width: 767px)": {
      position: "absolute",
      top: 11,
      right: -1,
      height: 24,
      width: 24,
      color: "#8E8E8E"
    }
  },
  progressBarRow: {
    marginRight: "30px", 
    marginTop: "-39px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
   
    "@media (max-width: 768px)": {
      marginRight: "0", 
      marginTop: "-32px",
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      marginTop: "15px",
    },
   
  },
};
// Customizable Area End

export default withTranslation()(
  withStyles(listingPricingStyle)(
    withRouter(ListingPricing as React.ComponentType<any>)
  )
);
