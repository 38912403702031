//@ts-ignore
//@ts-nocheck
import React from "react";
import { StyleSheet, Platform } from "react-native";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  Popover,
  Dialog,
  DialogActions,
  DialogTitle,
  Avatar
} from "@material-ui/core";
import { withRouter } from "react-router";
import { MoreHoriz } from "@material-ui/icons";
import SideMenu from "./SideMenu.web";
import ProfileHeader from "./ProfileHeader";
import CaseStudyController, { Props } from "./CaseStudyController";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "../../../components/src/Snackbar";
import Loader from "../../../components/src/Loader.web";
import { withTranslation } from 'react-i18next';


class CaseStudyList extends CaseStudyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { t, i18n } = this.props;
    const { caseStudys } = this.state;
    const { classes } = this.props;
    return (
      <>
        <ProfileHeader heading={t("Case Study")} subModule="3" showProfile />
        <Grid className={classes.mainWrapper}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid xs={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Box
                  onClick={() => {
                    localStorage.removeItem("caseStudyDetails");
                    localStorage.removeItem("caseStudyMedia");
                    this.props.history.push("/casestudy/create");
                  }}
                  style={{
                    padding: 10,
                    cursor: "pointer",
                    // backgroundImage: "linear-gradient(#F14E24, #C30052)",
                    backgroundImage: "linear-gradient(to right, rgb(241, 78, 36), rgb(195, 0, 82))",
                    minHeight: 127,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 43,
                    backgroundColor: "#FAFAFA",
                    boxShadow:
                      "0px 1px 1px rgba(0, 0, 0, 0.141176), 0px 2px 1px -1px rgba(0, 0, 0, 0.121569), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                    borderRadius: 16
                  }}
                >
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      fontSize: 22
                    }}
                  >
                    {t("Create a new case study")}
                  </Typography>
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: 12,
                      marginTop: 13
                    }}
                  >
                    {t("Your case studies show how your team has been successful in the past")}
                  </Typography>
                </Box>
                <Grid container className={classes.caseStudyCard}>
                  {caseStudys.map((caseStudy) => (
                    <Grid item xs={12} sm={6}
                      key={caseStudy.id}
                      // display="flex"
                      // flexDirection="column"
                      style={{
                        backgroundColor: "#F7F7FC",
                        border: "1px dashed #EBEEF0",
                        borderRadius: 10,
                        marginBottom: 10,
                        position: "relative",
                        borderRadius: "15px",
                      }}
                    >
                      <Box style={{
                        marginLeft: 3,
                        marginRight: 3,
                      }}>
                        <Box style={{ position: "absolute", top: 1, right: 1, zIndex: 10 }}>
                          <IconButton
                            style={{ backgroundColor: 'transparent' }}
                            onClick={(e) =>
                              this.handlePopoverOpen(e, caseStudy.id)
                            }
                          >
                            <MoreHoriz style={{ width: 24, height: 24, color: "white" }} />
                          </IconButton>
                          <Popover
                            open={this.state.openedPopoverId === caseStudy.id}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handlePopoverClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                            }}
                          >
                            <Box display='flex' flexDirection='column' >
                              <Button
                                onClick={() => {
                                  localStorage.setItem("caseStudyDetails", JSON.stringify({
                                    id: caseStudy.id,
                                    title: caseStudy.attributes.title,
                                    description: caseStudy.attributes.description,
                                    categories: caseStudy.attributes.categories,
                                    clients: caseStudy.attributes.clients.data[0]?.attributes?.client_name,
                                    banner: "",
                                    bannerUrl: caseStudy.attributes.case_banner
                                  }));
                                  localStorage.setItem("caseStudyMedia", JSON.stringify({ caseStudyId: caseStudy.id, deletedMedia: [], media: caseStudy.attributes.case_files.map(media => ({ id: media.id, url: media.url, file: { name: media?.filename || 'File', type: media?.mimetype || '' } })) }));
                                  this.props.history.push("/casestudy/create");
                                }}
                                variant="text"
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: 12,
                                  textAlign: 'left',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                {t("Edit")}
                              </Button>
                              <Button
                                onClick={() => {
                                  this.setState({ showDialog: true, caseStudyId: caseStudy.id })
                                }}
                                variant="text"
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: 12,
                                  textAlign: 'left',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                {t("Delete")}
                              </Button>
                            </Box>
                          </Popover>
                        </Box>
                        <Avatar
                          src={caseStudy.attributes.case_banner}
                          style={{
                            width: "100%",
                            height: 125,
                            objectFit: "contain",
                            borderRadius: "15px 15px 0 0",
                          }}
                        />
                        <Typography
                          style={{
                            color: "#14142A",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 12,
                            marginTop: 20,
                            marginBottom: 30,
                            textAlign: "center",
                            wordBreak: "break-all"
                          }}
                        >
                          {caseStudy.attributes.title}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid xs={12} md={4} />
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        <Loader loading={this.state.loading} />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`${t("Are you sure you want to delete this case study?")}`}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => this.setState({ showDialog: false, caseStudyId: null })} variant='text'>
              {t("Cancel")}
            </Button>
            <Button onClick={() => this.deleteCaseStudy(this.state.caseStudyId)} variant='text' autoFocus>
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  async componentDidMount() {
    this.getAllCaseStudys();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
const caseStudyListStyles = {
  btnShare: {
    "@media (max-width: 767px)": {
      marginLeft: "10px"
    }
  },
  mainWrapper: {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    }
  },
  mainBodyContent: {
    height: "100%",
    marginTop: 30,
    border: "#80808042 1px solid",
    padding: 40,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    "@media (max-width: 768px)": {
      padding: 20
    }
  },
  caseStudyCard: {
    // display: "flex",
    // marginLeft:"50px",
    // width:"100%",
    // justifyContent: "center",
    marginTop: "34px",
    // flexWrap: "wrap",
    // paddingLeft:"52px",
    "@media (max-width: 768px)": {
      // justifyContent: "center",
      paddingLeft: "0"
    }
  }
};
// Customizable Area End
export default withTranslation()(withStyles(caseStudyListStyles)(
  withRouter(CaseStudyList as React.ComponentType<any>)
)
);
