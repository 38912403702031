// index.js - WEB
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import "./i18n";
import App from './App';
import '../public/index.css';
import theme from './theme';
import registerServiceWorker from '../../components/src/registerServiceWorker';


ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Router>
      <Suspense fallback={null}>
        <App />
        </Suspense>
      </Router>
    </ThemeProvider>,
  document.getElementById('root')
);
registerServiceWorker('./firebase-messaging-sw.js');
