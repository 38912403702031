import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  auth: string | null | undefined;
  loading: boolean;
  getHighlightedContent: any;
  error: any;
  mainVideo : any;
  getDataHighlightedContent:any;
  mainTitle:any;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HighlightedContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  auth: string | null | undefined = window.localStorage.getItem("userToken");
  getHighlightedContentApiCallId: any;
  deleteHighlightedContentApiCallId:any;
  createHighlightedContentApiCallId: any;
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      // Customizable Area End
      loading: false,
      auth: "",
      getHighlightedContent: [],
      getDataHighlightedContent:[],
      error: "",
      mainVideo:'',
      mainTitle:''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // async componentDidMount() {
  //   this.getHighlightedContent();
  // }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      //runEngine.debugLog("Message Recived", message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson.data || responseJson.message) {
        if (apiRequestCallId === this.getHighlightedContentApiCallId) {
          const data = responseJson.data
          const videoUrl=[]
          let mainVideo : any 
          let mainTitle:any
          data.map((value)=>{

            if(value.attributes?.main_video){
                 mainVideo = value.attributes?.video_url
                 mainTitle = value.attributes?.title
             }
             else if(value.attributes?.video_url ){
                videoUrl.push({video_url:value.attributes?.video_url,title:value.attributes?.title ,id:value.attributes?.id})
             }
          })

          this.setState({
            getDataHighlightedContent : responseJson.data,
            getHighlightedContent:videoUrl ,
            mainVideo :mainVideo,
            mainTitle,
            loading: false,
          });
        } else if (apiRequestCallId === this.createHighlightedContentApiCallId) {
          this.setState({ loading: false });
          window.location = "/profile-dashboard" as any;
        } else if (apiRequestCallId === this.deleteHighlightedContentApiCallId) {
          this.getHighlightedContent();
          this.setState({ loading: false });
        }
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  // Customizable Area End

  getHighlightedContent = () => {
    try {
      const header = {
        token: this.auth,
      };

      //const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getHighlightedContentApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getHighlightedContentAPIEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };
  isNonNullAndEmpty(value: any) {
    return (
      value.video_url !== undefined &&
      value.video_url !== null &&
      value.video_url !== "null" &&
      value.video_url.trim().length > 0
    );
  }
  deleteHighlightedContent = (index: any) => {
    
    try {
      const header = { 
        token: this.auth,
      };
     let videoId:any;
     if(this.isNonNullAndEmpty(this.state.getHighlightedContent[index])){
      this.setState({loading:true})
      this.state.getDataHighlightedContent.map((value)=>{
            if( value.attributes?.id== this.state.getHighlightedContent[index].id){
              videoId = value.attributes?.id;
              return null;
             }
          })
     }else{
         return null;
     }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteHighlightedContentApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getHighlightedContentAPIEndPoint}/${videoId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  
  createHighlightedContent = (values: any) => {
    try {
      const header = {
        token: this.auth,
        "Content-Type": configJSON.validationApiContentType,
      };
      const attributes :any=[]
      values.vidoes.map((value)=>{
       attributes.push(value)    
      })
       attributes.push({ video_url:values.mainVideo,title: values.mainVideoTitle, main_video:true})
      const httpBody = {
        data: {
            attributes
        },
      };
      // const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createHighlightedContentApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.postHighlightedContentAPIEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };
  
  HighlightedContentSchema(t) {
  const validation = Yup.object().shape({
    vidoes: Yup.array().of(
      Yup.object().shape({
        video_url: Yup.string().matches(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/, `${t("Invalid youtube url")}`).required(`${t("Youtube url is required.")}` ),
        title:Yup.string().required(`${t("Title is required.")}`)
      })
    ),
   // vidoes: Yup.array().of(Yup.string().matches(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/, 'Invalid youtube url')),
    mainVideo: Yup.string().matches(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/, `${t("Invalid youtube url")}`).required(`${t("Youtube url is required.")}`),
    mainVideoTitle:Yup.string().required(`${t("Title is required.")}` ),
  });
return validation
}
}