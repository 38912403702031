//@ts-ignore
//@ts-nocheck
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Radio,
  Avatar
} from "@material-ui/core";
import { Formik, Form } from "formik";

import { logo, checkedIcon, backArrow, uncheckedIcon, avatar } from "./assets";
import { withTranslation } from 'react-i18next';

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";
import Copyright from "../../../components/src/Copyright";

class ForgotPasswordMethods extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const forgotPasswordPhone = localStorage.getItem("forgotPasswordPhone");
    const forgotPasswordEmail = localStorage.getItem("forgotPasswordEmail");
    const forgotPasswordUsername = localStorage.getItem(
      "forgotPasswordUsername"
    );
    const {t , i18n} = this.props
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
        height="124%"
      >
        <IconButton
          onClick={() => {
            this.props.history.goBack();
          }}
          style={{ position: "absolute", top: 62, left: 69 }}
        >
          <img src={backArrow} style={{ width: 17, height: 14 }} />
        </IconButton>

        <Box
          display="flex"
          mt="160px"
          mb="42px"
          width="494px"
          justifyContent="center"
        >
          <img src={logo} style={{ height: 45, width: 350 }} />
        </Box>

        <Avatar src={"/"} style={{ width: 94, height: 94 }} />

        <Typography
          style={{
            color: "#14142B",
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: 18,
            marginTop: 3
          }}
        >
          {`@${forgotPasswordUsername}`}
        </Typography>

        <Typography
          style={{
            color: "#A0A3BD",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: 12,
            marginTop: 17,
            marginBottom: 24,
            width: 327,
            textAlign: "center"
          }}
        >
          {t("Enter your a login option and we'll send you a link to get back into your account.")}
        </Typography>

        <Formik
          initialValues={{
            selectedType: forgotPasswordEmail ? "email" : "phone"
          }}
          onSubmit={(values) => {
            this.sendOtp(values);
          }}
        >
          {({ values, handleChange }) => (
            <Form translate="yes" style={{ width: 363 }}>
              <Fragment>
                <Box display="flex" flexDirection="column">
                  
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" flexDirection="column">
                        <Typography
                          style={{
                            color: "#4E4B66",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14
                          }}
                        >
                          {t("Send an email")}
                        </Typography>
                        <Typography
                          style={{
                            color: "#A0A3BD",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 12,
                            marginTop: 8
                          }}
                        >
                          {forgotPasswordEmail || `${t("Update your Email")}`}
                        </Typography>
                      </Box>
                      <Radio
                        checked={values.selectedType === "email"}
                        onChange={handleChange}
                        value="email"
                        name="selectedType"
                        checkedIcon={
                          <img
                            src={checkedIcon}
                            style={{ width: 20, height: 21 }}
                          />
                        }
                        icon={
                          <img
                            src={uncheckedIcon}
                            style={{ width: 20, height: 21 }}
                          />
                        }
                      />
                    </Box>
                  

              
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt="19px"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography
                          style={{
                            color: "#4E4B66",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14
                          }}
                        >
                          {t("Send a SMS")}
                        </Typography>
                        <Typography
                          style={{
                            color: "#A0A3BD",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 12,
                            marginTop: 8
                          }}
                        >
                          {/* {forgotPasswordPhone.replaceAll(
                            /\d(?!\d{0,3}$)/g,
                            "*"
                          ) || "N/A"} */}
                          {forgotPasswordPhone || `${t("Update your Phone number")}`}
                        </Typography>
                      </Box>
                      <Radio
                        checked={values.selectedType === "phone"}
                        onChange={handleChange}
                        value="phone"
                        name="selectedType"
                        disabled={forgotPasswordPhone==null?true:false}
                        disableRipple={true}
                        checkedIcon={
                          <img
                            src={checkedIcon}
                            style={{ width: 20, height: 21 }}
                          />
                        }
                        icon={
                          <img
                            src={uncheckedIcon}
                            style={{ width: 20, height: 21 }}
                          />
                        }
                      />
                    </Box>
                  

                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "#F14E24",
                      border: "1px solid #D9DBE9",
                      borderRadius: 16,
                      height: 56,
                      marginBottom: 14,
                      boxShadow: "none",
                      color: "#F7F7FC",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: 16,
                      marginTop: 18
                    }}
                  >
                    {t("Send")}
                  </Button>
                </Box>
              </Fragment>
            </Form>
          )}
        </Formik>

        <Box mb="100px">
        <Copyright />
        </Box>
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    // Customizable Area End
  }

  async componentWillUnmount() {
    localStorage.removeItem("forgotPasswordPhone");
    localStorage.removeItem("forgotPasswordEmail");
    localStorage.removeItem("forgotPasswordUsername");
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});

export default withTranslation()(withRouter(ForgotPasswordMethods as React.ComponentType<any>))