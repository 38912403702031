// Customizable Area Start
import * as Yup from "yup";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  auth: string | null | undefined;
  loading: boolean;
  error: any;
  getDataCompanyInformation:any;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CompanyInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  auth: string | null | undefined = window.localStorage.getItem("userToken");
  getCompanyInformationApiCallId: any;
  deleteHighlightedContentApiCallId:any;
  createCompanyInformationApiCallId: any;
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loading: false,
      auth: "",
      getDataCompanyInformation:{},
      error: "",
      // Customizable Area End
      
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      //runEngine.debugLog("Message Recived", message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson.data) {
        if (apiRequestCallId === this.getCompanyInformationApiCallId) {
          const data = responseJson.data?.attributes
          this.setState({
            getDataCompanyInformation :data,
            loading: false,
          });
        } else if (apiRequestCallId === this.createCompanyInformationApiCallId) {
          this.setState({ loading: false });
          window.location = "/profile-dashboard" as any;
        } else if (apiRequestCallId === this.deleteHighlightedContentApiCallId) {
          this.setState({ loading: false });
        }
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  // Customizable Area End

  getCompanyInformation = () => {
    try {
      const header = {
        token: this.auth,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCompanyInformationApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiCreateCompanyInformation}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };
  isNonNullAndEmpty(value: any) {
    return (
      value.video_url !== undefined &&
      value.video_url !== null &&
      value.video_url !== "null" &&
      value.video_url.trim().length > 0
    );
  }

  
  createCompanyInformation = (values: any) => {
    try {
      const header = {
        token: this.auth,
      };
      const formData = new FormData();
      formData.append('data[website]', values.website);
     formData.append('data[company_size]', values.companySize);
     formData.append('data[foundation_date]', values.foundationDate);
     formData.append('data[facebook_field]', values.facebook);
     formData.append('data[instagram_field]', values.instagram);
     formData.append('data[twitter_field]', values.twitter);
     formData.append('data[linkedin_field]', values.linkedin);
     
     const httpBody = formData;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createCompanyInformationApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiCreateCompanyInformation}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };


  CompanyInformationSchema(t) {
    const validations = Yup.object().shape({
      website: Yup.string().matches(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, `${t("Invalid URL")}`).required(`${t("URL is required.")}` ),
      companySize:Yup.number().required(`${t("Company size is required.")}`)
      .typeError(`${t("Only numbers are allowed.")}`)
      .positive(`${t("Negative numbers are not allowed.")}`)
      .integer(`${t("year can't contain a decimal.")}`),
      foundationDate:Yup.date()
      .required(`${t("Foundation date is required.")}`)
      .typeError(`${t("Only year are allowed.")}`)
      .max(new Date().getFullYear(),`${t('Year must be current year or less than current year')}`),
      facebook: Yup.string().matches(/(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/, `${t('Invalid facebook URL')}` ),
      instagram: Yup.string().matches(/(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im, `${t('Invalid instagram URL')}`),
      twitter: Yup.string().matches(/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/, `${t('Invalid twitter URL')}`),
      linkedin: Yup.string().matches(/(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/, `${t('Invalid linkedin URL')}`),
    });
    return validations
  }
}