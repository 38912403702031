import React, { FC } from "react";
import { Snackbar as MuiSnackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface Props {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error";
  onClose: () => void;
}

const Snackbar: FC<Props> = ({
  open,
  message,
  onClose,
  severity = "error"
}) => {
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open && !message.toLowerCase().includes("token")}
      autoHideDuration={6000}
      onClose={() => onClose()}
    >
      <Alert
        style={{
          fontFamily: "Poppins",
          fontWeight: 300,
          fontSize: 16
        }}
        onClose={() => onClose()}
        severity={severity}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
