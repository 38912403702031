//@ts-ignore
//@ts-nocheck

import {
  Avatar, Box, Dialog, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withTranslation } from 'react-i18next';
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import {
  facebookImg,
  instagramImg, jpgIcon, linkedinImg, locationIcon, pdfIcon, pngIcon, twitterImg, verify
} from "./assets";
import CaseStudyController, { Props } from "./CaseStudyController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class CaseStudyDetails extends CaseStudyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation, classes, t, i18n } = this.props;
    const { loading, showMore, caseIdData, userDetails } = this.state;
    return (
      <>
        <ProfileHeader heading={userDetails.first_name} onClick={() => window.history.back()} subModule={true} showProfile={true} />
        <>
          <Grid className={classes.mainWrapper}>
            <Grid container>
              <Grid item xs={12} md={2} style={{ position: "relative" }}>
                <SideMenu showProfile />
              </Grid>

              <Grid
                sm={12}
                md={6}
                style={{
                  border: "1px solid #DEDDDA",
                  borderTop: "none",
                  height: "100%"
                }}
              >
                <Box style={{ padding: "15px 0px" }}>
                  <Grid container>
                    <Grid Item md={3}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <Avatar
                          src={userDetails.profile_picture}
                          style={{
                            width: 100,
                            height: 100
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid Item md={4}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%"
                        }}
                      >
                        <Typography
                          style={{
                            color: "#081F32",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: "10px"
                          }}
                        >
                          {userDetails.first_name}{" "}
                          {userDetails.activated && (
                            <img
                             src={verify}
                             style={{ height: 13, marginLeft: "5px" }}
                           />
                          )}
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px"
                          }}
                        >
                          <Typography
                            style={{
                              color: "#969696",
                              fontSize: "1.2rem",
                              fontWeight: "400",
                              paddingRight: "10px",
                              borderRight: "1px solid #969696"
                            }}
                          >
                            <img
                              src={locationIcon}
                              style={{ height: "18px", paddingRight: "5px" }}
                            />
                            {userDetails?.profile_country_name || userDetails?.profile_country_code}
                          </Typography>
                          <Typography
                            style={{
                              color: "#969696",
                              fontSize: "1.2rem",
                              fontWeight: "400",
                              paddingLeft: "10px"
                            }}
                          >
                             {'@' + userDetails.user_name}
                          </Typography>
                        </Box>

                        <Typography
                          style={{
                            color: "#969696",
                            fontSize: "1.2rem",
                            fontWeight: "400",
                            paddingRight: "10px"
                          }}
                        >
                          {userDetails.brand?.data?.attributes?.name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Box style={{ padding: "10px 20px 0px" }}>
                    <Typography
                      style={{
                        color: "#353535",
                        fontFamily: "Poppins",
                        fontSize: 18,
                        marginTop: 5,
                        fontWeight: 600,
                        marginBottom: 7
                      }}
                    >
                      {caseIdData.attributes?.title}
                    </Typography>

                    {!showMore ? (
                  <Typography
                    style={{
                      color: "#4E4B66",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 13,
                      marginTop: 4,
                      wordBreak: "break-all"
                    }}
                  >
                    <>
                      {caseIdData.attributes?.description.length > 300
                        ? caseIdData.attributes?.description.substr(0, 300).trim() +
                          "..."
                        : caseIdData.attributes?.description}
                      {caseIdData.attributes?.description.length > 300 && (
                        <Typography
                          onClick={() => this.setState({ showMore: true })}
                          style={{
                            color: "#57D1D4",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14,
                            marginLeft: 5,
                            display: "inline",
                            cursor: "pointer",
                            textDecorationLine: "underline"
                          }}
                        >
                          {t("see more")}
                        </Typography>
                      )}
                    </>
                  </Typography>
                ) : (
                  <>
                    <Typography
                      style={{
                        color: "#4E4B66",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 13,
                        marginTop: 4,
                        wordBreak: "break-all"
                      }}
                    >
                      {caseIdData.attributes?.description}
                    </Typography>
                    <Typography
                      onClick={() => this.setState({ showMore: false })}
                      style={{
                        color: "#57D1D4",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        marginTop: 5,
                        display: "inline",
                        cursor: "pointer",
                        textDecorationLine: "underline"
                      }}
                    >
                      {t("see less")}
                    </Typography>
                  </>
                )}
                  </Box>
                </Box>

                <Box style={{ padding: "10px 20px 15px" }}>
                <Avatar src={caseIdData.attributes?.case_banner} style={{width:'100%', height:193,borderRadius:0}} />
                </Box>

                <Box style={{ padding: "10px 20px 10px" }}>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <Typography
                        style={{
                          color: "#353535",
                          fontFamily: "Poppins",
                          fontSize: 18,
                          marginTop: 5,
                          fontWeight: 600,
                          marginBottom: 7
                        }}
                      >
                        {t("Case Files")}
                      </Typography>
                    </Grid>

                    {caseIdData.attributes?.case_files.map((media) => (
                    <Box
                      onClick={() => this.setState({selectedMedia : media})}
                      display="flex"
                      flexDirection="column"
                      style={{
                        backgroundColor: "#eff0f6",
                        minHeight: 140,
                        width: 168,
                        borderRadius: 16,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        marginRight: 10,
                        marginBottom: 10,
                        cursor: 'pointer'
                      }}
                    >
                      {media?.mimetype?.split("/")[1] === "pdf" && (
                        <img src={pdfIcon} style={{ height: 50, width: 50 }} />
                      )}

                      {media?.mimetype?.split("/")[1] === "png" && (
                        <img src={pngIcon} style={{ height: 50, width: 50 }} />
                      )}

                      {(media?.mimetype?.split("/")[1] === "jpg" ||
                        media?.mimetype?.split("/")[1] === "jpeg") && (
                        <img src={jpgIcon} style={{ height: 50, width: 50 }} />
                      )}

                      <Typography
                        style={{
                          color: "#191919",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 15,
                          textAlign: "center",
                          wordBreak: "break-all"
                        }}
                      >
                        {media?.filename || 'N/A'}
                      </Typography>
                    </Box>
                  ))}
                  </Grid>
                </Box>
              </Grid>

              <Grid xs={12} md={4}>
              { 
                  userDetails?.company_informations?.data[0] ? 
                  <Box style={{ padding: "25px" }}>
                  <Box
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "20px",
                      marginBottom: "10px"
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "1.5rem",
                        padding: "12px",
                        borderBottom: "1px solid #ccc"
                      }}
                    >
                      {t("Information")}
                    </Typography>
                    <Box
                      style={{
                        padding: "20px"
                      }}
                    >
                      <Typography variant="h5">{t("Founded")}</Typography>
                      <Typography variant="button" style={{ fontSize: "12px" }}>
                        { userDetails?.company_informations?.data[0].attributes.foundation_date}
                      </Typography>
                      <Typography variant="h5" style={{ paddingTop: "10px" }}>
                        {t("Company size")}
                      </Typography>
                      <Typography
                        variant="button"
                        style={{ fontSize: "12px", textTransform: "lowercase" }}
                      >
                        { userDetails?.company_informations?.data[0].attributes.company_size} employees
                      </Typography>
                      <Typography variant="h5" style={{ paddingTop: "10px" }}>
                        {t("Website")}
                      </Typography>
                      <Typography
                        variant="button"
                        style={{ fontSize: "12px", textTransform: "lowercase" }}
                      >
                       <a href={userDetails?.company_informations?.data[0].attributes.website} target="_blank">{ userDetails?.company_informations?.data[0].attributes.website}</a> 
                      </Typography>
                      <Typography variant="h5" style={{ paddingTop: "10px" }}>
                        {t("Social Media")}
                      </Typography>
                      {
                        userDetails?.company_informations?.data[0].attributes.facebook_field && 
                          <a href={ userDetails?.company_informations?.data[0].attributes?.facebook_field} target="_blank">
                             <img
                           src={facebookImg}
                           alt=""
                           style={{
                             width: "18px",
                             paddingTop: "10px",
                             marginRight: "15px"
                           }}
                         />
                          </a>
                      }
                        {
                         userDetails?.company_informations?.data[0].attributes.linkedin_field && 
                         <a href={ userDetails?.company_informations?.data[0].attributes?.linkedin_field} target="_blank">
                            <img
                          src={linkedinImg}
                          alt=""
                          style={{
                            width: "18px",
                            paddingTop: "10px",
                            marginRight: "15px"
                          }}
                        />
                         </a>
                        }
                         {
                           userDetails?.company_informations?.data[0].attributes.instagram_field && 
                           <a href={ userDetails?.company_informations?.data[0].attributes?.instagram_field} target="_blank">
                              <img
                            src={instagramImg}
                            alt=""
                            style={{
                              width: "18px",
                              paddingTop: "10px",
                              marginRight: "15px"
                            }}
                          />
                           </a>                        
                         }
                         {
                          userDetails?.company_informations?.data[0].attributes.twitter_field && 
                          <a href={ userDetails?.company_informations?.data[0].attributes?.twitter_field} target="_blank">
                             <img
                           src={twitterImg}
                           alt=""
                           style={{
                             width: "18px",
                             paddingTop: "10px",
                             marginRight: "15px"
                           }}
                         />
                          </a>
                         }
                    </Box>
                  </Box>
                </Box>
                  :null
                }
              </Grid>
            </Grid>
          </Grid>
          
          <Dialog
        open={this.state.selectedMedia}
        onClose={() => this.setState({selectedMedia: null})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        {this.state.selectedMedia?.file?.type?.split("/")[1] !== "pdf" ? (
          <Avatar src={this.state.selectedMedia?.url} style={{width:'100%',height:'auto',borderRadius:0}} />
        ) :(
          <iframe src={this.state.selectedMedia?.url} style={{width:'600px',height:'600px'}} />
        ) }
        
     
      </Dialog>
        </>
      </>
    );
  }

  async componentDidMount() {
    this.getCaseInformation();
    this.getUserInformation();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
const CaseStudyDetailsStyles = {
  stepThree: {
    "@media (max-width: 767px)": {
      display: "block !important",
      marginLeft: "10px"
    }
  },
  btnShare: {
    "@media (max-width: 767px)": {
      marginLeft: "10px"
    }
  },
  submitBtn: {
    "@media (max-width: 768px)": {
      justifyContent: "flex-start"
    }
  },
  mainWrapper: {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    }
  },
  mainBodyContent: {
    height: "100%",
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10
    }
  }
};
// Customizable Area End

// export default withRouter(CaseStudyPreview as React.ComponentType<any>);
export default withTranslation()(withStyles(CaseStudyDetailsStyles)(
  withRouter(CaseStudyDetails as React.ComponentType<any>)
))
