export var notificationStyle = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: "100%",
    background: "#E5E5E5",
    "@media (min-width:375px) and (max-width:1024px)": {
      height: "100vh"
    }
  },
  heading: {
    marginTop: 40,
    fontSize: 24,
    color: "#14142B",
    fontFamily: 'Poppins',
    fontWeight: 800
  },
  img: {
    width: 42,
    height: 'auto'
  },
  subHeading: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 18,
    color: "#14142B",
    fontFamily: 'Poppins',
    fontWeight: 800,
    textAlign: "center"
  },
  text: {
    color: '#A0A3BD',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 12,
    width: 366,
    textAlign: "center",
    padding: "0px 67px"
  },
  btnText: {
    background: "#F14E24",
    fontSize: 13,
    fontFamily: "Poppins",
    marginTop: "10%",
    padding: "5px 90px",
    '&:hover': {
      backgroundColor: '#F14E24'
    },
    "@media (max-width: 600px)": {
      padding: "5px 50px",
      width: "75%",
      margin: "auto",
      marginTop: "10%"
    }
  },
  skipText: {
    fontSize: 10,
    color: "#055AFF",
    fontFamily: 'Poppins',
    fontWeight: 300,
    textAlign: "center",
    marginTop: "12%",
    cursor: 'pointer'
  },
  endText: {
    color: '#626262',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 10
  }
};