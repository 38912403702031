// App.js - WEB
import React, { Component, Suspense } from 'react';
import { View } from 'react-native';
import { Box } from '@material-ui/core';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HomeScreen from '../../components/src/HomeScreen';
// import TopNav from "../../components/src/TopNav";
import Header from '../../components/src/Header';
import Grid from '@material-ui/core/Grid';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import KarmaPoints from '../../blocks/KarmaPoints/src/KarmaPoints';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import PromoteContent from '../../blocks/PromoteContent/src/PromoteContent';
import Share from '../../blocks/Share/src/Share';
import Reviews from '../../blocks/Reviews/src/Reviews';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import ShoppingCart from '../../blocks/ShoppingCart/src/ShoppingCart';
import ManualTransactions from '../../blocks/ManualTransactions/src/ManualTransactions';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import CfWalletCreation from '../../blocks/CfWalletCreation/src/CfWalletCreation';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import Invitefriends from '../../blocks/invitefriends/src/Invitefriends';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import OrderCreation from '../../blocks/OrderCreation/src/OrderCreation';
import InappPurchasing from '../../blocks/InappPurchasing/src/InappPurchasing';
import DiscountsOffers from '../../blocks/DiscountsOffers/src/DiscountsOffers';
import Notifications from '../../blocks/Notifications/src/Notifications';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import SubscriptionBilling from '../../blocks/SubscriptionBilling/src/SubscriptionBilling';
import ConnectedAccounts from '../../blocks/ConnectedAccounts/src/ConnectedAccounts';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import Surveys from '../../blocks/Surveys/src/Surveys';
import Analytics from '../../blocks/Analytics/src/Analytics';
import Scheduling from '../../blocks/Scheduling/src/Scheduling';
import GoogleNewsIntegration from '../../blocks/GoogleNewsIntegration/src/GoogleNewsIntegration';
import AddFriends from '../../blocks/AddFriends/src/AddFriends';
import Search from '../../blocks/Search/src/Search';
import Discovery from '../../blocks/Search/src/Discovery.web';
import RecommendationEngine from '../../blocks/RecommendationEngine/src/RecommendationEngine';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import UserProfile from '../../blocks/user-profile-basic/src/UserProfile.web';
import MobilePreview from '../../blocks/user-profile-basic/src/MobilePreview.web';
import CompanyInformation from '../../blocks/user-profile-basic/src/CompanyInformation.web';

import CollectTransactionFees from '../../blocks/CollectTransactionFees/src/CollectTransactionFees';
import RequestManagement from '../../blocks/RequestManagement/src/RequestManagement';
import Bookmark from '../../blocks/Bookmark/src/Bookmark';
import BlogPostsManagement from '../../blocks/BlogPostsManagement/src/BlogPostsManagement';
import DocusignIntegration from '../../blocks/DocusignIntegration/src/DocusignIntegration';
import ActivityLog from '../../blocks/ActivityLog/src/ActivityLog';
import CfOpportunityCampaignCreation from '../../blocks/CfOpportunityCampaignCreation/src/CfOpportunityCampaignCreation';
import PaymentAdmin from '../../blocks/PaymentAdmin/src/PaymentAdmin';
import ReviewPrompt from '../../blocks/ReviewPrompt/src/ReviewPrompt';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import TimeTracker from '../../blocks/TimeTracker/src/TimeTracker';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import RefundManagement from '../../blocks/RefundManagement/src/RefundManagement';
import Ordermanagement from '../../blocks/ordermanagement/src/Ordermanagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import InventoryManagementIntegration from '../../blocks/InventoryManagementIntegration/src/InventoryManagementIntegration';
import LanguageOptions from '../../blocks/LanguageOptions/src/LanguageOptions';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web.tsx';
import ForgotPasswordMethods from '../../blocks/forgot-password/src/ForgotPasswordMethods.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import DonationPayments from '../../blocks/DonationPayments/src/DonationPayments';
import Leaderboard from '../../blocks/Leaderboard/src/Leaderboard';
import Chat from '../../blocks/Chat/src/Chat';
import ProjecttaskTracking from '../../blocks/ProjecttaskTracking/src/ProjecttaskTracking';
import FriendList from '../../blocks/FriendList/src/FriendList';
import SalesReporting from '../../blocks/SalesReporting/src/SalesReporting';
import Videos from '../../blocks/Videos/src/Videos';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import PhoneAccountRegistration from '../../blocks/email-account-registration/src/PhoneAccountRegistration.web';
import PhoneOtp from '../../blocks/email-account-registration/src/PhoneOtp.web';
import RegistrationMethods from '../../blocks/email-account-registration/src/RegistrationMethods.web';
import OrganisationHierarchy from '../../blocks/OrganisationHierarchy/src/OrganisationHierarchy';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import DynamicContent from '../../blocks/DynamicContent/src/DynamicContent';
import Settings2 from '../../blocks/Settings2/src/Settings2';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Favourites from '../../blocks/Favourites/src/Favourites';
import StatisticsReports from '../../blocks/StatisticsReports/src/StatisticsReports';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLogin.web';
import JobListing from '../../blocks/JobListing/src/JobListing';
import Download from '../../blocks/Download/src/Download';
import PeopleManagement from '../../blocks/PeopleManagement/src/PeopleManagement';
import Trending from '../../blocks/Trending/src/Trending';
import Payments from '../../blocks/Payments/src/Payments';
import ActivityFeed from '../../blocks/ActivityFeed/src/ActivityFeed';
import DeepLinking from '../../blocks/DeepLinking/src/DeepLinking';
import Sms2 from '../../blocks/Sms2/src/Sms2';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import Customisableusersubscriptions from '../../blocks/CustomisableUserSubscriptions/src/Customisableusersubscriptions';
import ManualTransactions2 from '../../blocks/ManualTransactions2/src/ManualTransactions2';
import Bookmark2 from '../../blocks/Bookmark2/src/Bookmark2';
import RecommendationEngine4 from '../../blocks/RecommendationEngine4/src/RecommendationEngine4';
import PeopleManagement2 from '../../blocks/PeopleManagement2/src/PeopleManagement2';
import CustomForm2 from '../../blocks/CustomForm2/src/CustomForm2';
import Chat9 from '../../blocks/Chat9/src/Chat9';
import JobListing2 from '../../blocks/JobListing2/src/JobListing2';
import CfInventoryManagement from '../../blocks/CfInventoryManagement/src/CfInventoryManagement';
import LandingPage2 from '../../blocks/LandingPage2/src/LandingPage2';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import Analytics3 from '../../blocks/Analytics3/src/Analytics3';
import Videos4 from '../../blocks/Videos4/src/Videos4';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import ProjecttaskTracking2 from '../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2';
import OrderCreation3 from '../../blocks/OrderCreation3/src/OrderCreation3';
import ProjectTemplates from '../../blocks/ProjectTemplates/src/ProjectTemplates';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import CfMobileInterfaceInWeb3 from '../../blocks/CfMobileInterfaceInWeb3/src/CfMobileInterfaceInWeb3';

import MainLandingPage from '../../blocks/LandingPage/src/LandingPage.web';
import TeamPage from '../../blocks/LandingPage/src/TeamPage.web';
import SetupProfile from '../../blocks/email-account-login/src/SetupProfile.web';
import PushNotification from '../../blocks/Notifications/src/Notifications.web';
import SocialLogin from '../../blocks/social-media-account-login/src/SocialLogin.web';
import PrivacyPolicy from '../../blocks/email-account-registration/src/PrivacyPolicy.web';
import TermsAndConditions from '../../blocks/email-account-registration/src/TermsAndConditions.web';
import UserAgreement from '../../blocks/email-account-registration/src/UserAgreement.web';
import SelectUserType from '../../blocks/email-account-registration/src/SelectUserType.web';
import SelectUserKind from '../../blocks/email-account-registration/src/SelectUserKind.web';
import SelectHelps from '../../blocks/email-account-registration/src/SelectHelps.web';
import ConfirmDetails from '../../blocks/email-account-registration/src/ConfirmDetails.web';
import SelectInterests from '../../blocks/email-account-registration/src/SelectInterests.web';
import PortfolioList from '../../blocks/user-profile-basic/src/PortfolioList.web';
import PortfolioCreate from '../../blocks/user-profile-basic/src/PortfolioCreate.web';
import PortfolioMedia from '../../blocks/user-profile-basic/src/PortfolioMedia.web';
import PortfolioPreview from '../../blocks/user-profile-basic/src/PortfolioPreview.web';
import PortfolioDetails from '../../blocks/user-profile-basic/src/PortfolioDetails.web';
import ProfileDashboard from '../../blocks/user-profile-basic/src/ProfileDashboard.web';
import ListingsList from '../../blocks/user-profile-basic/src/ListingsList.web';
import ListingCreate from '../../blocks/user-profile-basic/src/ListingCreate.web';
import ListingPricing from '../../blocks/user-profile-basic/src/ListingPricing.web';
import ListingPreview from '../../blocks/user-profile-basic/src/ListingPreview.web';
import ListingDetails from '../../blocks/user-profile-basic/src/ListingDetails.web';
import About from '../../blocks/user-profile-basic/src/About.web';
import EditHeader from '../../blocks/user-profile-basic/src/EditHeader.web';
import EditSkills from '../../blocks/user-profile-basic/src/EditSkills.web';
import CaseStudyList from '../../blocks/user-profile-basic/src/CaseStudyList.web';
import CaseStudyCreate from '../../blocks/user-profile-basic/src/CaseStudyCreate.web';
import CaseStudyMedia from '../../blocks/user-profile-basic/src/CaseStudyMedia.web';
import CaseStudyPreview from '../../blocks/user-profile-basic/src/CaseStudyPreview.web';
import CaseStudyDetails from '../../blocks/user-profile-basic/src/CaseStudyDetails.web';
import PrivacySecurity from '../../blocks/Settings2/src/PrivacySecurity.web';
import ChangePassword from '../../blocks/Settings2/src/ChangePassword.web';
import EmailAndSms from '../../blocks/Settings2/src/EmailAndSms.web';
import ProfessionalAccount from '../../blocks/Settings2/src/ProfessionalAccount.web';
import ConnectSocialAccount from '../../blocks/Settings2/src/ConnectSocialAccount.web';
import UserNameRegistration from '../../blocks/email-account-registration/src/UserNameRegistration.web';
import JobHistoryList from '../../blocks/user-profile-basic/src/JobHistoryList.web';
import JobHistoryCreate from '../../blocks/user-profile-basic/src/JobHistoryCreate.web';
import HighlightedContent from '../../blocks/user-profile-basic/src/HighlightedContent.web';
import OpenPositionsList from '../../blocks/user-profile-basic/src/OpenPositionsList.web';
import OpenPositionsCreate from '../../blocks/user-profile-basic/src/OpenPositionsCreate.web';
import ClientsList from '../../blocks/user-profile-basic/src/ClientsList.web';
import ClientsCreate from '../../blocks/user-profile-basic/src/ClientsCreate.web';
import AmbassadorsList from '../../blocks/user-profile-basic/src/AmbassadorsList.web';
import AmbassadorsCreate from '../../blocks/user-profile-basic/src/AmbassadorsCreate.web';
import AmbassadorsSearchUser from '../../blocks/user-profile-basic/src/AmbassadorsSearchUser.web';
import AmbassadorsInviteSent from '../../blocks/user-profile-basic/src/AmbassadorsInviteSent.web';
import TalentsList from '../../blocks/user-profile-basic/src/TalentsList.web';
import TalentsCreate from '../../blocks/user-profile-basic/src/TalentsCreate.web';
import TalentsSearchUser from '../../blocks/user-profile-basic/src/TalentsSearchUser.web';
import TalentsInviteSent from '../../blocks/user-profile-basic/src/TalentsInviteSent.web';
import EditProfile from '../../blocks/Settings2/src/EditProfile.web';
import ConnectSocialMedia from '../../blocks/user-profile-basic/src/ConnectSocialMedia.web';
import NotificationsList from '../../blocks/Notifications/src/NotificationList.web';
import InboxList from '../../blocks/Notifications/src/InboxList.web';

import NewLandingPage from '../../blocks/LandingPage2/src/NewLandingPage.web';
import NewLandingPage2 from '../../blocks/LandingPage2/src/NewLandingPage2.web';
import CeoLetter from '../../blocks/LandingPage2/src/CeoLetter.web';
import Support from '../../blocks/contactus/src/Support.web';

const routeMap = {
  NewLandingPage: {
    component: NewLandingPage,
    path: '/',
    exact: true
  },
  // NewLandingPage: {
  //   component: NewLandingPage,
  //   path: '/new-landingPage',
  //   exact: true
  // },
  NewLandingPage2: {
    component: NewLandingPage2,
    path: '/aboutus',
    exact: true
  },
  CeoLetter: {
    component: CeoLetter,
    path: '/ceoLetter',
    exact: true
  },
  ProjectTemplates: {
    component: ProjectTemplates,
    path: '/ProjectTemplates'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  CfMobileInterfaceInWeb3: {
    component: CfMobileInterfaceInWeb3,
    path: '/CfMobileInterfaceInWeb3'
  },

  ManualTransactions2: {
    component: ManualTransactions2,
    path: '/ManualTransactions2'
  },
  Bookmark2: {
    component: Bookmark2,
    path: '/Bookmark2'
  },
  RecommendationEngine4: {
    component: RecommendationEngine4,
    path: '/RecommendationEngine4'
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: '/PeopleManagement2'
  },
  CustomForm2: {
    component: CustomForm2,
    path: '/CustomForm2'
  },
  Chat9: {
    component: Chat9,
    path: '/Chat9'
  },
  JobListing2: {
    component: JobListing2,
    path: '/JobListing2'
  },
  CfInventoryManagement: {
    component: CfInventoryManagement,
    path: '/CfInventoryManagement'
  },
  LandingPage2: {
    component: LandingPage2,
    path: '/LandingPage2'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  Analytics3: {
    component: Analytics3,
    path: '/Analytics3'
  },
  Videos4: {
    component: Videos4,
    path: '/Videos4'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  ProjecttaskTracking2: {
    component: ProjecttaskTracking2,
    path: '/ProjecttaskTracking2'
  },
  OrderCreation3: {
    component: OrderCreation3,
    path: '/OrderCreation3'
  },

  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  KarmaPoints: {
    component: KarmaPoints,
    path: '/KarmaPoints'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  PromoteContent: {
    component: PromoteContent,
    path: '/PromoteContent'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart'
  },
  ManualTransactions: {
    component: ManualTransactions,
    path: '/ManualTransactions'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  CfWalletCreation: {
    component: CfWalletCreation,
    path: '/CfWalletCreation'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  Invitefriends: {
    component: Invitefriends,
    path: '/Invitefriends'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  OrderCreation: {
    component: OrderCreation,
    path: '/OrderCreation'
  },
  InappPurchasing: {
    component: InappPurchasing,
    path: '/InappPurchasing'
  },
  DiscountsOffers: {
    component: DiscountsOffers,
    path: '/DiscountsOffers'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  SubscriptionBilling: {
    component: SubscriptionBilling,
    path: '/SubscriptionBilling'
  },
  ConnectedAccounts: {
    component: ConnectedAccounts,
    path: '/ConnectedAccounts'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  Surveys: {
    component: Surveys,
    path: '/Surveys'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  GoogleNewsIntegration: {
    component: GoogleNewsIntegration,
    path: '/GoogleNewsIntegration'
  },
  AddFriends: {
    component: AddFriends,
    path: '/AddFriends'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  RecommendationEngine: {
    component: RecommendationEngine,
    path: '/RecommendationEngine'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  UserProfile: {
    component: UserProfile,
    path: '/userProfile'
  },
  UserProfileById: {
    component: UserProfile,
    path: '/user-profile/:userId',
    exact: true
  },
  MobilePreview: {
    component: MobilePreview,
    path: '/mobilePreview'
  },

  CollectTransactionFees: {
    component: CollectTransactionFees,
    path: '/CollectTransactionFees'
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement'
  },
  Bookmark: {
    component: Bookmark,
    path: '/Bookmark'
  },
  BlogPostsManagement: {
    component: BlogPostsManagement,
    path: '/BlogPostsManagement'
  },
  DocusignIntegration: {
    component: DocusignIntegration,
    path: '/DocusignIntegration'
  },
  ActivityLog: {
    component: ActivityLog,
    path: '/ActivityLog'
  },
  CfOpportunityCampaignCreation: {
    component: CfOpportunityCampaignCreation,
    path: '/CfOpportunityCampaignCreation'
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: '/PaymentAdmin'
  },
  ReviewPrompt: {
    component: ReviewPrompt,
    path: '/ReviewPrompt'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  TimeTracker: {
    component: TimeTracker,
    path: '/TimeTracker'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  RefundManagement: {
    component: RefundManagement,
    path: '/RefundManagement'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  InventoryManagementIntegration: {
    component: InventoryManagementIntegration,
    path: '/InventoryManagementIntegration'
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/forgot-password',
    exact: true
  },
  ForgotPasswordMethods: {
    component: ForgotPasswordMethods,
    path: '/forgot-password/select-type',
    exact: true
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  DonationPayments: {
    component: DonationPayments,
    path: '/DonationPayments'
  },
  Leaderboard: {
    component: Leaderboard,
    path: '/Leaderboard'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  ProjecttaskTracking: {
    component: ProjecttaskTracking,
    path: '/ProjecttaskTracking'
  },
  FriendList: {
    component: FriendList,
    path: '/FriendList'
  },
  SalesReporting: {
    component: SalesReporting,
    path: '/SalesReporting'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/signup/email',
    exact: true
  },
  PhoneAccountRegistration: {
    component: PhoneAccountRegistration,
    path: '/signup/phone',
    exact: true
  },
  PhoneOtp: {
    component: PhoneOtp,
    path: '/signup/phone/verify',
    exact: true
  },
  EmailOtp: {
    component: PhoneOtp,
    path: '/signup/email/verify',
    exact: true
  },
  RegistrationMethods: {
    component: RegistrationMethods,
    path: '/signup',
    exact: true
  },
  OrganisationHierarchy: {
    component: OrganisationHierarchy,
    path: '/OrganisationHierarchy'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  DynamicContent: {
    component: DynamicContent,
    path: '/DynamicContent'
  },

  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  StatisticsReports: {
    component: StatisticsReports,
    path: '/StatisticsReports'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/login',
    exact: true
  },
  JobListing: {
    component: JobListing,
    path: '/JobListing'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  PeopleManagement: {
    component: PeopleManagement,
    path: '/PeopleManagement'
  },
  Trending: {
    component: Trending,
    path: '/Trending'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: '/ActivityFeed'
  },
  DeepLinking: {
    component: DeepLinking,
    path: '/DeepLinking'
  },
  Sms2: {
    component: Sms2,
    path: '/Sms2'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions'
  },

  Discovery: {
    component: Discovery,
    path: '/discovery',
    exact: true
  },
  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  PushNotification: {
    component: PushNotification,
    path: '/notification'
  },

  SocialLogin: {
    component: SocialLogin,
    path: '/social-login',
    exact: true
  },

  AlertWeb: {
    component: AlertBlock,
    path: '/AlertWeb',
    modal: true
  },

  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/privacy-policy',
    exact: true
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/terms-and-conditions',
    exact: true
  },
  UserAgreement: {
    component: UserAgreement,
    path: '/user-agreement',
    exact: true
  },
  SelectUserType: {
    component: SelectUserType,
    path: '/select-type',
    exact: true
  },
  SelectUserKind: {
    component: SelectUserKind,
    path: '/select-kind',
    exact: true
  },
  SelectInterests: {
    component: SelectInterests,
    path: '/select-interests',
    exact: true
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/forgot-password/verify',
    exact: true
  },
  NewPassword: {
    component: NewPassword,
    path: '/new-password',
    exact: true
  },
  PortfolioList: {
    component: PortfolioList,
    path: '/portfolio',
    exact: true
  },
  PortfolioCreate: {
    component: PortfolioCreate,
    path: '/portfolio/create',
    exact: true
  },
  PortfolioMedia: {
    component: PortfolioMedia,
    path: '/portfolio/media',
    exact: true
  },
  PortfolioPreview: {
    component: PortfolioPreview,
    path: '/portfolio/preview',
    exact: true
  },

  PortfolioDetails: {
    component: PortfolioDetails,
    path: '/user-profile/:userId/portfolio/:portfoloiId',
    exact: true
  },
  ProfileDashboard: {
    component: ProfileDashboard,
    path: '/profile-dashboard',
    exact: true
  },
  ListingsList: {
    component: ListingsList,
    path: '/listings',
    exact: true
  },
  ListingCreate: {
    component: ListingCreate,
    path: '/listing/create',
    exact: true
  },
  ListingPricing: {
    component: ListingPricing,
    path: '/listing/pricing',
    exact: true
  },
  ListingPreview: {
    component: ListingPreview,
    path: '/listing/preview',
    exact: true
  },

  ListingDetails: {
    component: ListingDetails,
    path: '/user-profile/:userId/listing/:listingId',
    exact: true
  },
  CompanyInformation: {
    component: CompanyInformation,
    path: '/company-information',
    exact: true
  },
  ConnectSocialMedia: {
    component: ConnectSocialMedia,
    path: '/social-media',
    exact: true
  },
  About: {
    component: About,
    path: '/about',
    exact: true
  },
  EditHeader: {
    component: EditHeader,
    path: '/edit-header',
    exact: true
  },
  EditSkills: {
    component: EditSkills,
    path: '/edit-skills',
    exact: true
  },
  CaseStudyList: {
    component: CaseStudyList,
    path: '/casestudy',
    exact: true
  },
  CaseStudyCreate: {
    component: CaseStudyCreate,
    path: '/casestudy/create',
    exact: true
  },
  CaseStudyMedia: {
    component: CaseStudyMedia,
    path: '/casestudy/media',
    exact: true
  },
  CaseStudyPreview: {
    component: CaseStudyPreview,
    path: '/casestudy/preview',
    exact: true
  },
  CaseStudyDetails: {
    component: CaseStudyDetails,
    path: '/user-profile/:userId/casestudy/:casestudyId',
    exact: true
  },
  PrivacySecurity: {
    component: PrivacySecurity,
    path: '/settings/privacy-security',
    exact: true
  },
  ChangePassword: {
    component: ChangePassword,
    path: '/settings/change-password',
    exact: true
  },
  EmailAndSms: {
    component: EmailAndSms,
    path: '/settings/email-sms',
    exact: true
  },
  ProfessionalAccount: {
    component: ProfessionalAccount,
    path: '/settings/create-professional-account',
    exact: true
  },
  ConnectSocialAccount: {
    component: ConnectSocialAccount,
    path: '/settings/connect-account',
    exact: true
  },
  JobHistoryList: {
    component: JobHistoryList,
    path: '/job-history',
    exact: true
  },
  JobHistoryCreate: {
    component: JobHistoryCreate,
    path: '/job-history/create',
    exact: true
  },
  HighlightedContent: {
    component: HighlightedContent,
    path: '/highlighted-content',
    exact: true
  },

  OpenPositionsList: {
    component: OpenPositionsList,
    path: '/open-positions',
    exact: true
  },
  OpenPositionsCreate: {
    component: OpenPositionsCreate,
    path: '/open-positions/create',
    exact: true
  },
  ClientsList: {
    component: ClientsList,
    path: '/clients',
    exact: true
  },
  ClientsCreate: {
    component: ClientsCreate,
    path: '/clients/create',
    exact: true
  },
  AmbassadorsList: {
    component: AmbassadorsList,
    path: '/ambassadors',
    exact: true
  },
  AmbassadorsCreate: {
    component: AmbassadorsCreate,
    path: '/ambassadors/create',
    exact: true
  },
  AmbassadorsSearchUser: {
    component: AmbassadorsSearchUser,
    path: '/ambassadors/search-user',
    exact: true
  },
  AmbassadorsInviteSent: {
    component: AmbassadorsInviteSent,
    path: '/ambassadors/invite-sent',
    exact: true
  },
  TalentsList: {
    component: TalentsList,
    path: '/talents',
    exact: true
  },
  TalentsSearchUser: {
    component: TalentsSearchUser,
    path: '/talents/search-user',
    exact: true
  },
  TalentsInviteSent: {
    component: TalentsInviteSent,
    path: '/talents/invite-sent',
    exact: true
  },
  TalentsCreate: {
    component: TalentsCreate,
    path: '/talents/create',
    exact: true
  },
  EditProfile: {
    component: EditProfile,
    path: '/settings/edit-account',
    exact: true
  },
  UserNameRegistration: {
    component: UserNameRegistration,
    path: '/claim-username',
    exact: true
  },
  SelectHelps: {
    component: SelectHelps,
    path: '/select-helps',
    exact: true
  },
  ConfirmDetails: {
    component: ConfirmDetails,
    path: '/confirm-details',
    exact: true
  },
  UserNameRegistration: {
    component: UserNameRegistration,
    path: '/claim-username',
    exact: true
  },
  SelectHelps: {
    component: SelectHelps,
    path: '/select-helps',
    exact: true
  },
  ConfirmDetails: {
    component: ConfirmDetails,
    path: '/confirm-details',
    exact: true
  },
  SetupProfile: {
    component: SetupProfile,
    path: '/setup-profile',
    exact: true
  },
  NotificationList: {
    component: NotificationsList,
    path: '/notification-list',
    exact: true
  },
  InboxList: {
    component: InboxList,
    path: '/inbox',
    exact: true
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  OtherUserProfile: {
    component: UserProfile,
    path: '/user/:user_name',
    exact: true
  },
  Support: {
    component: Support,
    path: '/support',
    exact: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyD_DIdTP1MjMhP_cHYjInS4rkYWixAiBO4',
  authDomain: 'yupgg-d1a48.firebaseapp.com',
  projectId: 'yupgg-d1a48',
  storageBucket: 'yupgg-d1a48.appspot.com',
  messagingSenderId: '613727127987',
  appId: '1:613727127987:web:57e93cbff376d591b463c6',
  measurementId: 'G-4KDMVRDBFT'
});

const db = firebaseAPI.firestore();

export { db };

const openRoutes = [
  '',
  'aboutus',
  'login',
  'social-login',
  'claim-username',
  'signup',
  'user-agreement',
  'privacy-policy',
  'forgot-password',
  'new-password',
  'user'
];

class App extends Component {
  componentDidMount() {
    const currentRoute = window.location.pathname.split('/')[1];
    const isUserloggedIn = localStorage.getItem('userToken');
    if (!openRoutes.includes(currentRoute)) {
      if (!isUserloggedIn) {
        window.location.replace('/login');
      }
    }
    else{
      if (isUserloggedIn) {
        window.location.replace('/profile-dashboard');
      }
    }
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </Box>
    );
  }
}

export default App;
