//@ts-ignore
//@ts-nocheck
import React from "react";
import { StyleSheet, Platform } from "react-native";
import {
  Box,
  Link,
  Typography,
  Button,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import CustomFacebookLogInButton from "../../social-media-account/src/CustomFacebookLogInButton.web";
import CustomGoogleLogInButton from "../../social-media-account/src/CustomGoogleLogInButton.web";
import CustomTwitterLogInButton from "../../social-media-account/src/CustomTwitterLoginButon";
import { logo, twitchIcon, appleIcon, mailIcon, phoneIcon } from "./assets";
import Snackbar from "../../../components/src/Snackbar";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import { withTranslation } from 'react-i18next';

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import Copyright from "../../../components/src/Copyright";

class RegistrationMethods extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  setUserInformation = () => {
    const { firstName, lastName, email, userId } = this.state;
    const data = {
      type: "social_account",
      attributes: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        unique_auth_id: userId
      }
    };
    this.onSocialLogin(data);
  };

  responseFacebook = (response: any) => {
    if (response.accessToken) {
      const name = response.name;
      const email = response.email;
      const userId = response.id;
      const firstName = name
        .split(" ")
        .slice(0, -1)
        .join(" ");
      const lastName = name
        .split(" ")
        .slice(-1)
        .join(" ");
      this.setState({ firstName, lastName, email, userId });
      this.setUserInformation();
    }
    };

  responseGoogle = (response: any) => {
    if (response.accessToken) {
      const firstName = response.profileObj.givenName;
      const lastName = response.profileObj.familyName;
      const userId = response.profileObj.googleId;
      const email = response.profileObj.email;
      this.setState({ firstName, lastName, email, userId });
      this.setUserInformation();
    }
  };

  onFailed = () => {
    console.log("Failed")
  }
  onSuccess = (resp) => {
    resp.json().then(user => {
      if (user) {
        const userId = user.data?.attributes?.account_id
        const email = user.data?.attributes?.email
        this.setState({ email, userId })
        // this.setUserInformation();
        localStorage.setItem("userToken", user.meta?.token)
        this.props.history.push("/select-type");
      }
    })
  }
  

  render() {
    const { t, i18n } = this.props

    const userToken = localStorage.getItem("userNameToken") 
    
    const devUrl = 'https://yuppgg-68443-react-native.b68443.dev.us-east-1.aws.svc.builder.cafe';
    const stageUrl = 'https://yuppgg-68443-ruby.b68443.stage.us-east-1.aws.svc.builder.ai';
    const prodUrl = 'https://www.yup.gg';
    
    const backendDevUrl = 'https://yuppgg-68443-ruby.b68443.dev.eastus.az.svc.builder.cafe';
    const backendStageUrl = 'https://yuppgg-68443-ruby.b68443.stage.us-east-1.aws.svc.builder.ai';
    const backendProdUrl = 'https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai';
    
    const twitchUrl =
      `https://id.twitch.tv/oauth2/authorize?client_id=ob87fgrb8uq1cc3pah4zr2wcgzlhn5&redirect_uri=${prodUrl}/signup&response_type=token+id_token&scope=user:read:email+openid&claims={%22id_token%22:{%22email%22:null,%22email_verified%22:null},%22userinfo%22:{%22picture%22:null,%22preferred_username%22:null}}`;

      const twitterUrl = `${backendProdUrl}/get_twitter_token?callback_url=${window.location.origin}/select-type`
      const twitterLoginUrl = `${backendProdUrl}/twitter/callback?username_token=${userToken}`
    
    
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#E5E5E5"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="85%"
          pt="22px"
        >
          <img
            style={{ width: isWidthUp('sm',this.props.width) ? 96: 45, height: "auto" }}
            src={logo}
            onClick={() => {
              this.props.history.push("/");
            }}
          />
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                fontSize: 16,
                color: "#4E4B66",
                fontFamily: "Poppins",
                fontWeight: 400
              }}
            >
              {t("Already have an account")}
            </Typography>
            <Link
              onClick={() => {
                this.props.history.push("/login");
              }}
              underline="none"
              style={{
                fontSize: 14,
                color: "#F14E24",
                fontFamily: "Roboto",
                fontWeight: 500,
                marginLeft: 5,
                textTransform: "uppercase"
              }}
            >
              {t("Sign in")}
            </Link>
          </Box>
        </Box>
        <Typography
          style={{
            marginTop: 58,
            marginBottom: 20,
            fontSize: 26,
            color: "#14142B",
            fontFamily: "Manrope",
            fontWeight: 800
          }}
        >
          {t("Create your account")}
        </Typography>
        <Box display="flex" flexDirection="column" >
          {/* <Button variant='contained' style={{
                        backgroundColor: '#1DA1F3',
                        borderRadius: 16,
                        width: 361,
                        height: 80,
                        marginBottom: 14,
                        boxShadow: 'none'
                    }} >
                        <Box display='flex' justifyContent='space-between' width='80%' >
                            <Box display='flex' width='25%'>
                                <img src={twitterIcon} style={{ width: 26, height: 'auto' }} />
                            </Box>
                            <Box display='flex' width='75%'> */}
          {/* <Typography style={{
                                    color: '#FFFFFF',
                                    fontFamily: 'Poppins',
                                    fontWeight: 500,
                                    fontSize: 16
                                }} >Sign up with Twitter</Typography> */}
          <CustomTwitterLogInButton
            loginUrl={twitterLoginUrl}
            requestTokenUrl={twitterUrl}
            onSuccess={this.onSuccess}
            onFailed={this.onFailed}
            loginTwitterButtonText={t("Sign up with Twitter")}
            onPress={() => {}}
            // className={classes.twitterBtn}
          />
          {/* </Box>
                        </Box>
                    </Button> */}
          <CustomFacebookLogInButton
            testID="btnFacebookLogIn"
            appId="1800966006635263"
            loginFacebookButtonText={t("Sign up with Facebook")}
            callback={this.responseFacebook}
            onPress={() => {}}
          />
          {/* <Typography style={{
                                    color: '#FFFFFF',
                                    fontFamily: 'Poppins',
                                    fontWeight: 500,
                                    fontSize: 16
                                }} >Sign up with Facebook</Typography> */}
          {/* </Box>
                        </Box>
                    </Button> */}

          {/* <Button variant='contained' style={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #D9DBE9',
                        borderRadius: 16,
                        width: 361,
                        height: 80,
                        marginBottom: 14,
                        boxShadow: 'none'
                    }} > */}
          {/* <Box display='flex' justifyContent='space-between' width='80%' > */}
          {/* <Box display='flex' width='25%'>
                                <img src={googleIcon} style={{ width: 26, height: 'auto' }} />
                            </Box> */}
          {/* <Box display='flex' width='75%'> */}
          <CustomGoogleLogInButton
            clientId="613727127987-1l2aeoj9g2ph2gdhu933518kvrh8rua7.apps.googleusercontent.com"
            loginGoogleButtonText={t("Sign up with Google")}
            onPress={() => {}}
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
          />
          {/* </Box>
                        </Box> */}
          {/* </Button> */}

          {/* <Button
            variant="contained"
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #D9DBE9",
              borderRadius: 16,
              width: 361,
              height: 80,
              marginBottom: 14,
              boxShadow: "none"
            }}
          >
            <Box display="flex" justifyContent="space-between" width="80%">
              <Box display="flex" width="25%">
                <img src={appleIcon} style={{ width: 26, height: "auto" }} />
              </Box>
              <Box display="flex" width="75%" alignItems="center">
                <Typography
                  style={{
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 16
                  }}
                >
                  Sign up with Apple
                </Typography>
              </Box>
            </Box>
          </Button> */}

          <Button
            onClick={(e) => (window.location = twitchUrl)}
            variant="contained"
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #D9DBE9",
              borderRadius: 16,
              width: 361,
              height: 80,
              marginBottom: 14,
              boxShadow: "none"
            }}
          >
            <Box display="flex" justifyContent="space-between" width="80%">
              <Box display="flex" width="25%">
                <img src={twitchIcon} style={{ width: 26, height: "auto" }} />
              </Box>
              <Box display="flex" width="75%" alignItems="center">
                <Typography
                  style={{
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 16
                  }}
                >
                  {t("Sign up with Twitch")}
                </Typography>
              </Box>
            </Box>
          </Button>

          <Button
            onClick={(e) => this.props.history.push("/signup/email")}
            variant="contained"
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #D9DBE9",
              borderRadius: 16,
              width: 361,
              height: 80,
              marginBottom: 14,
              boxShadow: "none"
            }}
          >
            <Box display="flex" justifyContent="space-between" width="80%">
              <Box display="flex" width="25%">
                <img src={mailIcon} style={{ width: 26, height: "auto" }} />
              </Box>
              <Box display="flex" width="75%">
                <Typography
                  style={{
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 16
                  }}
                >
                  {t("Sign up with Email")}
                </Typography>
              </Box>
            </Box>
          </Button>

          <Button
            onClick={(e) => this.props.history.push("/signup/phone")}
            variant="contained"
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #D9DBE9",
              borderRadius: 16,
              width: 361,
              height: 80,
              marginBottom: 14,
              boxShadow: "none"
            }}
          >
            <Box display="flex" justifyContent="space-between" width="80%">
              <Box display="flex" width="25%">
                <img src={phoneIcon} style={{ width: 26, height: "auto" }} />
              </Box>
              <Box display="flex" width="75%">
                <Typography
                  style={{
                    color: "#000000",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 16
                  }}
                >
                  {t("Sign up with Phone")}
                </Typography>
              </Box>
            </Box>
          </Button>
        </Box>
        <Box width={327} mt="20px">
          <Typography
            style={{
              color: "#A0A3BD",
              fontFamily: "Poppins",
              fontWeight: "normal",
              fontSize: 12,
              textAlign:'center'
            }}
          >
            {t("By signing up, I accept and I agree to the")}{" "}
        
            <Link
              target="_blank"
              href="/privacy-policy"
              underline="always"
              style={{ color: "#F14E24" }}
            >
              {t("Privacy Policy")}
            </Link>{" "}
           {" "}{t("and")}{" "}

           <Link
                        target="_blank"
                        href="/user-agreement"
                        underline="none"
                        style={{ color: "#F14E24" }}
                      >
                        {t("Terms of Service")}
                      </Link>
         
            .
          </Typography>
        </Box>

        <Box mt="119px">
        <Copyright />
        </Box>

        <Box mt="32px">
          <Typography
            style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 10
            }}
          >
            <Link
                        target="_blank"
                        href="/user-agreement"
                        underline="none"
                        style={{ color: "#F14E24" }}
                      >
                        {t("User Agreement")}
                      </Link>{" "}
          </Typography>
        </Box>
        <Box mt="2px" mb="34px">
          <Typography
            style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 10
            }}
          >
            <Link
              target="_blank"
              href="/privacy-policy"
              underline="always"
              style={{ color: "#F14E24" }}
            >
              {t("Privacy Policy")}
            </Link>
          </Typography>
        </Box>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        <CatchInvalidToken
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
        />
        <Loader loading={this.state.loading} />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleTwitchSignup();
    this.checkUsernameToken();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    // Customizable Area End
  }
}

const registerStyle = {
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  },
  twitterBtn: {
    padding: 0,
    background: "none",
    border: "none",
  }
  // Customizable Area End
};

export default withTranslation()(withWidth()(withStyles(registerStyle)(withRouter(RegistrationMethods))));
