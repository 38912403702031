//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Button, Dialog, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withTranslation } from 'react-i18next';
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import { jpgIcon, locationIcon, pdfIcon, pngIcon, stepthree, verify } from "./assets";
import PortfolioController, { Props } from "./PortfolioController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";


class PortfolioPreview extends PortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, navigation, t, i18n } = this.props;

    const { loading, showMore } = this.state;

    const media = JSON.parse(localStorage.getItem("portfolioMedia"));

    const formDetails = JSON.parse(localStorage.getItem("portfolioDetails"));

    const projectDetails = { ...media, ...formDetails };

    const { userDetails, PortfolioIdData } = this.state

    if (!media || !formDetails) {
      this.props.history.replace("/portfolio");
      return null;
    }
    return (
      <>
        <ProfileHeader
          heading={t("Review Project")}
          subModule={true}
          showProfile={true}
          multiPage
          backLocation="/portfolio/media"
        />
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Grid
            sm={12}
            md={6}
            style={{
              border: "1px solid #DEDDDA",
              borderTop: "none",
              height: "100%",
              width: "100%"
            }}
          >
            <Box className={classes.progressBarRow}>
              <Typography
                style={{
                  color: "#808080",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: 13,
                  marginRight: 14,
                }}
              >
                {t("Step")} 3 of 3
              </Typography>
              <img src={stepthree} style={{ height: 12, width: 185 }} />
            </Box>
               <Box
              display="flex"
              flexDirection="column"
              mt="40px"
              mb="40px"
              pl="52px"
              pr="41px"
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box
                  style={{
                    display: "flex",

                  }}
                >
                  <Avatar
                    src={userDetails.profile_picture}
                    style={{
                      width: 100,
                      height: 100
                    }}
                  />
                </Box>
                <Box>
                  <Typography style={{ fontSize: "20px", fontWeight: "bold", marginLeft: "30px", marginBottom: "6px" }}>
                    {userDetails.first_name}{" "}
                    {userDetails.activated && (
                      <img
                        src={verify}
                        style={{ height: 13, marginLeft: "5px" }}
                      />
                    )}
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginLeft: "30px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#969696",
                        fontSize: "1.2rem",
                        paddingRight: "10px",
                        borderRight: "1px solid #969696",
                      }}
                    >
                      <img
                        src={locationIcon}
                        style={{ height: "18px", paddingRight: "5px" }}
                      />
                      {userDetails?.profile_country_name || userDetails?.profile_country_code}
                    </Typography>
                    <Typography
                      style={{
                        color: "#969696",
                        fontSize: "14px",
                        paddingLeft: "10px",
                      }}
                    >
                      {'@' + userDetails?.user_name}
                    </Typography>
                  </Box>
                  <Typography style={{ marginLeft: "30px", color: "#969696", fontSize: "14px" }}>
                    {userDetails.brand?.data?.attributes?.name}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  style={{
                    color: "#081F32",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: 18,
                    marginTop: "20px",
                    wordBreak: "break-all"
                  }}
                >
                  {projectDetails.title}
                </Typography>

                {!showMore ? (
                  <Typography
                    style={{
                      color: "#4E4B66",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 13,
                      marginTop: 4,
                      wordBreak: "break-all"
                    }}
                  >
                    <>
                      {projectDetails.description.length > 300
                        ? projectDetails.description.substr(0, 300).trim() +
                        "..."
                        : projectDetails.description}
                      {projectDetails.description.length > 300 && (
                        <Typography
                          onClick={() => this.setState({ showMore: true })}
                          style={{
                            color: "#57D1D4",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14,
                            marginLeft: 5,
                            display: "inline",
                            cursor: "pointer",
                            textDecorationLine: "underline"
                          }}
                        >
                          {t("see more")}
                        </Typography>
                      )}
                    </>
                  </Typography>
                ) : (
                  <>
                    <Typography
                      style={{
                        color: "#4E4B66",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 13,
                        marginTop: 4,
                        wordBreak: "break-all"
                      }}
                    >
                      {projectDetails.description}
                    </Typography>
                    <Typography
                      onClick={() => this.setState({ showMore: false })}
                      style={{
                        color: "#57D1D4",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        marginTop: 5,
                        display: "inline",
                        cursor: "pointer",
                        textDecorationLine: "underline"
                      }}
                    >
                      {t("see less")}
                    </Typography>
                  </>
                )}

                <Box
                  style={{
                    height: 193,
                    width: "auto",
                    borderRadius: 12,
                    marginTop: 34,
                    position: "relative",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundImage: `url(${projectDetails.bannerUrl})`
                  }}
                />
                 <Typography
                  style={{
                    color: "#081F32",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: 18,
                    marginBottom: 13,
                    marginTop: 47
                  }}
                >
                  {t("Project Files")}
                </Typography>
                <Box display="flex" flexWrap="wrap">
                  {projectDetails.media.map((media) => (
                    <Box
                      onClick={() => this.setState({ selectedMedia: media })}
                      display="flex"
                      flexDirection="column"
                      style={{
                        backgroundColor: "#eff0f6",
                        minHeight: 140,
                        width: 168,
                        borderRadius: 16,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        marginRight: 10,
                        marginBottom: 10,
                        cursor: 'pointer'
                      }}
                    >
                      {media?.file?.type?.split("/")[1] === "pdf" && (
                        <img src={pdfIcon} style={{ height: 50, width: 50 }} />
                      )}

                      {media?.file?.type?.split("/")[1] === "png" && (
                        <img src={pngIcon} style={{ height: 50, width: 50 }} />
                      )}

                      {(media?.file?.type?.split("/")[1] === "jpg" ||
                        media?.file?.type?.split("/")[1] === "jpeg") && (
                          <img src={jpgIcon} style={{ height: 50, width: 50 }} />
                        )}

                      <Typography
                        style={{
                          color: "#191919",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 12,
                          marginTop: 15,
                          textAlign: "center",
                          wordBreak: "break-all"
                        }}
                      >
                        {media?.file?.name || media}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => this.handleCreateUpdatePortfolio(projectDetails)}
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  style={{
                    backgroundColor: "#F14E24",
                    border: "1px solid #D9DBE9",
                    borderRadius: 16,
                    height: 34,
                    marginBottom: 14,
                    boxShadow: "none",
                    color: "#F7F7FC",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 14,
                    marginTop: 30
                  }}
                >
                  {t("Submit")}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Snackbar
            open={Boolean(this.state.error)}
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
            severity="error"
          />
          <Loader loading={loading} />

          <CatchInvalidToken
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
          />

          <Dialog
            open={this.state.selectedMedia}
            onClose={() => this.setState({ selectedMedia: null })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

            {this.state.selectedMedia?.file?.type?.split("/")[1] !== "pdf" ? (
              <Avatar src={this.state.selectedMedia?.url} style={{ width: '100%', height: 'auto', borderRadius: 0 }} />
            ) : (
              <iframe src={this.state.selectedMedia?.url} style={{ width: '600px', height: '600px' }} />
            )}


          </Dialog>
        </Grid>
      </>
    );
  }
  async componentDidMount() {
    // this.getValidations();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    // this.requestSessionData();
    //  this.getPortfolioInformation();
    this.getUserInformation();
  }
}
// Customizable Area Start
export const portfolioPreviewStyle = {
  progressBarRow: {
    marginRight: "30px",
    marginTop: "-39px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    "@media (max-width: 768px)": {
      marginRight: "0",
      marginTop: "-32px",
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      marginTop: "15px",
    },
  },
};

// Customizable Area End
export default withTranslation()(
  withStyles(portfolioPreviewStyle)(
    withRouter(PortfolioPreview as React.ComponentType<any>)
  )
);
