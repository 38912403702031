//@ts-ignore
//@ts-nocheck
import PropTypes from "prop-types";
import React, { Component } from "react";
import { StyleSheet} from "react-native";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Box, Link, Typography, Button } from '@material-ui/core';

import { facebookImage, facebookIcon } from "./assets";
import { configJSON } from "./SocialMediaAccountWebController";

type Props = {
  testID: string;
  appId: string;
  loginFacebookButtonText: string;
  callback: (response: any) => void;
  onPress: () => void;
  // Customizable Area Start
  // Customizable Area End
};

export default class CustomFacebookLogInButton extends Component<Props> {
  static propTypes = {
    testID: PropTypes.string,
    appId: PropTypes.string,
    loginFacebookButtonText: PropTypes.string,
    callback: PropTypes.func.isRequired,
    onPress: PropTypes.func.isRequired
    // Customizable Area Start
    // Customizable Area End
  };

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <FacebookLogin
        // Customizable Area Start
        fields="name,email,picture"
        scope="email,public_profile"
        // Customizable Area End
        appId={this.props.appId}
        callback={this.props.callback}
        render={renderProps => (
          <Button variant='contained'
            style={{ 
              backgroundColor: '#1775F0',
              borderRadius: 16,
              width: 361,
              height: 80,
              marginBottom: 14,
              boxShadow: 'none',
              fontFamily: 'Poppins',
            }}
          onClick={() => {
                renderProps.onClick()
                this.props.onPress();
              }}
          >
            <Box display='flex' justifyContent='space-between' width='80%' >
              <Box display='flex'>
                <img src={facebookIcon} style={{ width: 26, height: 'auto' }} />
              </Box>
              <Box display='flex' style={{ fontSize: 16, color: "white", minWidth: "75%", maxWidth: "100%" }}>
                {this.props.loginFacebookButtonText}
              </Box>
            </Box>
          </Button>
        )}
      />
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  facebookStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    elevation: 6,
    shadowRadius: 8,
    borderWidth: 0,
    borderRadius: 0,
    paddingTop: "4px",
    boxShadow: 'none',
  },
  facebookImageStyle: {
    marginRight: 10,
    width: 20,
    height: 20
  },
  facebookTextStyle: {
    color: '#ffffff',
    fontFamily: 'Poppins',
    fontWeight: "500",
    fontSize: 16,
    boxShadow: 'none',
    paddingLeft: 7
  },
  faceBtn:{
    boxShadow: "none",
  }
});
// Customizable Area End
