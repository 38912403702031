//@ts-nocheck
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import { Box, Link, Typography, Button, Checkbox } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { CheckBox } from "@material-ui/icons";
import { withTranslation } from 'react-i18next';
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Snackbar from "../../../components/src/Snackbar";
import { checkboxIcon, starImage } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import { withStyles } from "@material-ui/core/styles";
import Copyright from "../../../components/src/Copyright";

class SelectHelps extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { helps, selectedHelps } = this.state;
    const { classes, t , i18n } = this.props;
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
      >
      

        <Box
          display="flex"
          mt="107px"
          mb="27px"
          width={isWidthUp("sm", this.props.width) ? 494 : 363}
          justifyContent="center"
        >
          <Typography className={classes.Help}
            style={{
              fontSize: 26,
              color: "#14142B",
              fontFamily: "Manrope",
              fontWeight: 800
            }}
          >
            {t("What can yup.gg help you with")}
          </Typography>
          <img
            src={starImage}
            style={{
              position: "absolute",
              height: 45,
              width: isWidthUp("sm", this.props.width) ? 494 : 363
            }}
          />
        </Box>
        <Box
          display="flex"
          mt="28px"
          mb="48px"
          width="365px"
          justifyContent="center"
        >
          <Typography className={classes.chooseBelow}
            style={{
              fontSize: 14,
              color: "#4E4B66",
              fontFamily: "Poppins",
              fontWeight: 400,
              textAlign: "center"
            }}
          >
            {t("Choose from the actions below to let us know how you’d like to use your yup.gg Team account to help your business.")}
          </Typography>
        </Box>

        <Box className={classes.optionSelect}
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: isWidthUp("sm", this.props.width) ? 437 : 363
          }}
        >
          {helps.map((help: any) => (
            <Box
              onClick={() => this.selectHelpsHandler(help)}
              key={help.id}
              display="flex"
              height="30px"
              mb="10px"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: "#463E3F",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  textAlign: "center"
                }}
              >
                {help.attributes.name || "N/A"}
              </Typography>

              <Checkbox
                checked={selectedHelps.hasOwnProperty(help.id)}
                onChange={() => this.selectHelpsHandler(help)}
                style={{ padding: 0, marginRight: 4, marginTop: 4 }}
                checkedIcon={
                  <CheckBox
                    style={{
                      color: "#F14E24",
                      width: 16,
                      height: 16,
                      padding: 0,
                      margin: 0
                    }}
                  />
                }
                icon={
                  <img src={checkboxIcon} style={{ width: 16, height: 16 }} />
                }
              />
            </Box>
          ))}
        </Box>

        <Button
          onClick={() => this.updateHelps()}
          disabled={Object.values(selectedHelps).length === 0}
          variant="contained"
          style={{
            backgroundColor: "#F14E24",
            border: "1px solid #D9DBE9",
            borderRadius: 16,
            height: 34,
            width: isWidthUp("sm", this.props.width) ? 437 : 363,
            marginBottom: 14,
            boxShadow: "none",
            color: "#F7F7FC",
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 16,
            marginTop: 20,
            opacity: Object.values(selectedHelps).length > 0 ? 1 : 0.6
          }}
        >
          {t("Continue")}
        </Button>
        <Link
        href="/confirm-details"
          underline="none"
          style={{
            
              // backgroundColor:"#F14E24" ,
              // border: "1px solid #D9DBE9",
              // borderRadius: 16,
              height: 34,
              width:  isWidthUp("sm", this.props.width) ? 437 : 350,
              marginBottom: 14,
              // boxShadow: "none",
              color:"#055AFF" ,
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 14,
              marginTop: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textDecoration:"none !important"
              // opacity: Object.values(selectedHelps).length > 0 ? 1 : 0.6
            
          }}
        >
          {t("Skip for Now")}
        </Link>

        <Box mt="315px" mb="100px">
        <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        
        <CatchInvalidToken
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
        />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchHelps();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});
const selectHelpStyles={
  Help:{
    "@media (max-width: 768px)":{
     textAlign:"center",
     fontSize:"29px !important",
    }
  },
  optionSelect:{
    "@media (max-width: 768px)":{
      paddingLeft:"10px",
      paddingRight:"10px",
    }
  },
  chooseBelow:{
    "@media (max-width: 768px)":{
      paddingLeft:"10px",
      paddingRight:"10px",
    }
  }
}


// export default withWidth()(withRouter(SelectHelps as React.ComponentType<any>));
export default withTranslation()(withStyles(selectHelpStyles)(withWidth()(
  withRouter(SelectHelps as React.ComponentType<any>)
)))
