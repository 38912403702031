//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Button, Dialog,
  DialogActions,
  DialogTitle, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import AmbassadorsController, { Props } from "./AmbassadorsController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class AmbassadorsList extends AmbassadorsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation } = this.props;
    const { ambassadors } = this.state;
    const { classes, t , i18n } = this.props;
    return (
      <>
        <ProfileHeader heading={t("Brand Ambassadors")} subModule="3" showProfile />
        <Grid className={classes.mainWrapper}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid xs={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Box
                  onClick={() => {
                    localStorage.removeItem("ambassadorDetails");
                    localStorage.removeItem("ambassadorSearchedUsers");
                    this.props.history.push("/ambassadors/search-user");
                  }}
                  style={{
                    padding: 10,
                    cursor: "pointer",
                    minHeight: 127,
                    width: "92%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 43,
                    borderRadius: 16,
                    border: "1px solid #DBDBDB",
                    backgroundImage: "linear-gradient(to right, rgb(241, 78, 36), rgb(195, 0, 82))",
                  }}
                >
                  <Typography
                    style={{
                      color:"#fff",
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      fontSize: 22
                    }}
                  >
                    {t("Add New Ambassador")}
                  </Typography>
                  <Add
                    style={{
                      marginTop: 5,
                      color:"#fff",
                      height: 24,
                      width: 24
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                  width="92%"
                  mt="32px"
                >
                  {ambassadors.map((ambassador) => (
                    <Box
                      key={ambassador.id}
                      display="flex"
                      style={{
                        height: "auto",
                        minHeight: 105,
                        borderRadius: 16,
                        border: "1px solid #EBEEF0",
                        marginBottom: 7
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          padding: "22px 4px 22px 20px"
                        }}
                      >
                        <Box display="flex" width="70%" alignItems="center">
                          <Avatar
                            src={ambassador.attributes.brand_ambassadors_image}
                            style={{
                              width: 48,
                              height: 48,
                              marginRight: 35,
                              borderRadius: 0
                            }}
                          />
                          <Box
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              style={{
                                color: "#081F32",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 18
                              }}
                            >
                              {ambassador.attributes.ambassadors_name}
                            </Typography>
                            <Typography
                              style={{
                                color: "#6E798C",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 10,
                                marginTop: 4,
                                marginBottom: 2
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: 10
                                }}
                                component="span"
                              >
                                {ambassador.attributes.accounts?.data[0]?.attributes?.account?.data?.attributes?.brand?.data?.attributes?.name}
                              </Typography>{" "}
                              · {ambassador.attributes.social_handle}
                            </Typography>
                            <Typography
                              style={{
                                color: "#6E798C",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 10,
                                marginTop: 4,
                                marginBottom: 2
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: 10
                                }}
                                component="span"
                              >
                                {t("Status")}
                              </Typography>{" "}
                              · {ambassador.attributes.accounts?.data[0]?.attributes?.status === 'Resend' ? 'Declined' : ambassador.attributes.accounts?.data[0]?.attributes?.status}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          width="30%"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Box display="flex">
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "ambassadorDetails",
                                  JSON.stringify({
                                    id: ambassador.id,
                                    ambassadorName:
                                      ambassador.attributes.ambassadors_name,
                                    socialHandle:
                                      ambassador.attributes.social_handle,
                                    location: ambassador.attributes.location,
                                    description:
                                      ambassador.attributes.discription,
                                    logo: "",
                                    logoUrl:
                                      ambassador.attributes
                                        .brand_ambassadors_image ?? ''
                                  })
                                );
                                this.props.history.push("/ambassadors/create");
                              }}
                              variant="text"
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: 14,
                                justifyContent: "flex-start"
                              }}
                            >
                              {t("Edit")}
                            </Button>
                          </Box>
                          <Box display="flex">
                            <Button
                              onClick={() => {
                                this.setState({
                                  showDialog: true,
                                  ambassadorId: ambassador.id
                                });
                              }}
                              variant="text"
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: 14,
                                justifyContent: "flex-start"
                              }}
                            >
                              {t("Delete")}
                            </Button>
                          </Box>
                          {ambassador.attributes.accounts?.data[0]?.attributes?.status === 'Resend' && (
                                 <Box display="flex">
                                 <Button
                                   onClick={() => {
                                     this.resendNotification(ambassador.attributes.accounts?.data[0]?.attributes?.id)
                                   }}
                                   variant="text"
                                   style={{
                                     color: "#F14E24",
                                     fontFamily: "Poppins",
                                     fontWeight: 500,
                                     fontSize: 14,
                                     justifyContent: "flex-start"
                                   }}
                                 >
                                   {t("Resend")}
                                 </Button>
                               </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Snackbar
            open={Boolean(this.state.error)}
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
            severity="error"
          />
          <Loader loading={this.state.loading} />
          <CatchInvalidToken
              onClose={() => this.setState({ error: null })}
              message={this.state.error || "N/A"}
            />
        </Grid>
        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`${t("Are you sure you want to delete this ambassador?")}`}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ showDialog: false, ambassadorId: null })
              }
              variant="text"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => this.deleteAmbassador(this.state.ambassadorId)}
              variant="text"
              autoFocus
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getAllAmbassadors();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
const AmbassadorsListStyle = {
  twitterBtn: {
    padding: 0,
    background: "none",
    border: "none",
    width: "80%"
  },
  videoLabel: {
    fontFamily: "Poppins",
    fontSize: 13,
    display: "block",
    color: "#081F32",
    fontWeight: 400,
    marginBottom: "10px"
  },
  mainWrapper: {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    }
  },
  mainBodyContent: {
    marginTop: 30,
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      padding: 10
    }
  }
};
// Customizable Area End
export default withTranslation()(withStyles(AmbassadorsListStyle)(
  withRouter(AmbassadorsList as React.ComponentType<any>)))
