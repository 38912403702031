//@ts-ignore
//@ts-nocheck
import Grid from "@material-ui/core/Grid";
import React from "react";
import { withTranslation } from "react-i18next";
import { Dimensions, PixelRatio, StyleSheet, Text, View } from "react-native";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Snackbar from "../../../components/src/Snackbar";
import Loader from "../../../components/src/Loader.web";
import ConnectSocialMedia from "./ConnectSocialMedia.web";
import EditHeader from "./EditHeader.web";
import MobilePreview from "./MobilePreview.web";
import ProfileHeader from "./ProfileHeader";
import SetProfile from "./SetProfile";
import SideMenu from "./SideMenu.web";
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";

let screenWidth = Dimensions.get("window").width;
let screenHeight = Dimensions.get("window").height;
//Artboard Dimension
let artBoardHeightOrg = 0;
let artBoardWidthOrg = 0;
//Re calculated Artboard Dimension
let artBoardWidth = isSameRatio() ? artBoardWidthOrg : screenWidth;
let artBoardHeight = isSameRatio() ? artBoardHeightOrg : screenHeight;
function isSameRatio(): boolean {
  return (
    artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1
  );
}
let extraSpace = 0;

function deviceBasedDynamicDimension(
  originalDimen: number,
  compareWithWidth: boolean,
  resizeFactor: number
): number | undefined {
  if (originalDimen != null) {
    if (resizeFactor != null) {
      originalDimen *= resizeFactor;
    }
    const compareArtBoardDimenValue = compareWithWidth
      ? artBoardWidth
      : artBoardHeight;
    const artBoardScreenDimenRatio =
      (originalDimen * 100) / compareArtBoardDimenValue;
    let compareCurrentScreenDimenValue = compareWithWidth
      ? screenWidth
      : screenHeight - extraSpace;
    return PixelRatio.roundToNearestPixel(
      (artBoardScreenDimenRatio * compareCurrentScreenDimenValue) / 100
    );
  }
  return null;
}

class ProfileDashboard extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }

  showEditHeader = () => {
    this.setState({ subModule: 2 });
    this.updateHeading("Header");
  };

  showSocialMedia = () => {
    this.setState({ subModule: 3 });
    this.updateHeading("Social Media");
  };

  updateHeading = (text) => {
    this.setState({ heading: text });
  };

  showModule = () => {
    const { subModule } = this.state;
    switch (subModule) {
      case 1:
        return (
          <SetProfile
            showEditHeader={this.showEditHeader}
            showSocialMedia={this.showSocialMedia}
          />
        );
      case 2:
        return <EditHeader />;
      case 3:
        return <ConnectSocialMedia />;
      default:
        <SetProfile showEditHeader={this.showEditHeader} />;
    }
  };

  componentDidMount() {
    this.getUserInformation();
  }

  componentDidUpdate = (prePros, preState) => {
    const { userDetails } = this.state;
    if (userDetails !== preState.userDetails) {
      const langauge = localStorage.getItem("langauge");
      this.props.i18n.changeLanguage(langauge);
    }
  };

  render() {
    localStorage.setItem("userName", this.state.userDetails.user_name);
    const { t, i18n } = this.props;

    return (
      <>
        <ProfileHeader
          subModule={this.state.subModule}
          heading={t("Build your profile")}
          showProfile
        />
        <Grid container style={{ paddingBottom: 50 }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.showModule()}
          </Grid>
          <Grid item xs={12} sm={4} style={{ margin: "0 auto" }}>
            <View style={styles.group_previewGroup}>
              <Text style={styles.textlabel_scrolldownText}>
                {t("Scroll down to preview your profile")}
              </Text>
              <MobilePreview
                adjustableElements={this.state.adjustableElements}
                userDetails={this.state.userDetails}
              />
            </View>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="warning"
        />
        <CatchInvalidToken
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
        />
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

export default withTranslation()(ProfileDashboard);

const styles = StyleSheet.create({
  group_previewGroup: {
    // width: deviceBasedDynamicDimension(408, true, 1),
    height: deviceBasedDynamicDimension(801, false, 1),
    // marginLeft: deviceBasedDynamicDimension(1006, true, 1),
    marginTop: deviceBasedDynamicDimension(50, false, 1),
    opacity: 1,
    // position: 'absolute',
    borderRadius: deviceBasedDynamicDimension(0, true, 1),
    borderWidth: deviceBasedDynamicDimension(0, true, 1),
    paddingLeft: "8px",
    paddingRight: "10px"
  },

  textlabel_scrolldownText: {
    // width: deviceBasedDynamicDimension(253, true, 1),
    height: deviceBasedDynamicDimension(17, false, 1),
    // marginLeft: deviceBasedDynamicDimension(70, true, 1),
    marginTop: deviceBasedDynamicDimension(0, false, 1),
    opacity: 1,
    // position: 'absolute',
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: deviceBasedDynamicDimension(0, true, 1),
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins",
    fontSize: deviceBasedDynamicDimension(14, true, 1)
  }
});
