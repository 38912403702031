export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const workSpaceImage = require("../assets/get-start.png");
export const yupProfileImage = require("../assets/yup-profile-image.png");
export const upperStartNowImage = require("../assets/upperStartNow.png");
export const yupsecImage = require("../assets/yupsec.png");
export const yupIllu = require("../assets/yupillu.png");
export const illustratiom = require("../assets/illustratiom.png");
export const illustration = require("../assets/illustration.png");
export const rewards = require("../assets/rewards.png");
