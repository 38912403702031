export var setupProfileStyle = {
  body: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "#F2F2F2",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    marginTop: 20
  },
  heading: {
    fontSize: 26,
    color: "#14142B",
    fontFamily: "Manrope",
    fontWeight: 800
  },
  subHeading: {
    fontSize: 14,
    color: "#4E4B66",
    fontFamily: "Poppins",
    fontWeight: 400,
    textAlign: "center"
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    width: 437,
    textAlign: "center",
    position: "relative"
  },
  logo: {
    margin: 15,
    overflow: "hidden",
    fontSize: "x-large"
  },
  btn: {
    border: "2px solid #D9DBE9",
    borderRadius: 16,
    width: "100%",
    mixWidth: "auto",
    height: 50,
    boxShadow: "none",
    justifyContent: "left",
    backgroundColor: 'none',
    color: "#00000087",
    "&:hover": {
      backgroundColor: 'none',
      boxShadow: "none"
    }
  },
  headingContent: {
    display: "flex",
    mt: "107px",
    mb: "27px",
    width: "494px",
    justifyContent: "center"
  },
  twitterBtn: {
    padding: 0,
    background: "none",
    border: "none",
    width: "100%"
  }
};