//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Button, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Field, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import AmbassadorsController, { Props } from "./AmbassadorsController";
import CropImage from "./CropImage.web";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class AmbassadorsCreate extends AmbassadorsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // const { navigation } = this.props;
    const { classes, t, i18n } = this.props;
    const formDetails = JSON.parse(localStorage.getItem("ambassadorDetails"));
    return (
      <>
        <ProfileHeader
          heading={t("Brand Ambassadors")}
          subModule="3"
          showProfile
        />
        <Grid className={classes.mainWrapper}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>

            <Grid xs={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Formik
                  initialValues={
                    formDetails || {
                      ambassadorName: "",
                      socialHandle: "",
                      location: "",
                      description: "",
                      logo: "",
                      logoUrl: ""
                    }
                  }
                  validationSchema={this.CreateAmbassadorSchema(t)}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    this.handleCreateUpdateAmbassador(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldError
                  }) => {
                    return (
                      <Form
                        translate="yes"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: 52,
                          paddingRight: 41,
                          paddingTop: 40
                        }}
                      >
                        {this.state.isLogoImage && (
                          <CropImage
                            handleClose={() => {
                              setFieldValue("logo", null);
                              setFieldValue("logoUrl", null);
                              this.setState({ isLogoImage: false });
                            }}
                            circle={true}
                            width={150}
                            height={150}
                            url={values.logoUrl}
                            open={this.state.isLogoImage}
                            getCropImageUrl={(url) => {
                              setFieldValue("logo", url);
                              setFieldValue("logoUrl", url);
                              this.setState({ isLogoImage: false });
                            }}
                          />
                        )}
                        <Box display="flex">
                          <Avatar
                            src={values.logoUrl || "/"}
                            style={{ width: 38, height: 38, marginRight: 22 }}
                          />
                          <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                            justifyContent="flex-start"
                          >
                            <Typography
                              style={{
                                color: "#262626",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 19,
                                marginLeft: 8
                              }}
                            >
                              {values.ambassadorName ||
                                `${t("Ambassador name")}`}
                            </Typography>
                            <Box display="flex">
                              <Button
                                variant="text"
                                style={{
                                  color: "#F14E24",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: 14
                                }}
                              >
                                <input
                                  type="file"
                                  onChange={(e: any) => {
                                    this.handleSelectImage(
                                      e,
                                      setFieldValue,
                                      setFieldError
                                    );
                                  }}
                                  style={{
                                    position: "absolute",
                                    height: "100%",
                                    width: "100%",
                                    zIndex: 100,
                                    cursor: "pointer !important",
                                    opacity: 0
                                  }}
                                  accept="image/png, image/jpeg, image/jpg"
                                />
                                {t("Upload photo")}
                              </Button>
                            </Box>
                          </Box>
                        </Box>

                        <Box display="flex" flexDirection="column" mt="37px">
                          <Typography
                            style={{
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontSize: 16,
                              marginBottom: 17
                            }}
                          >
                            {t("Ambassador name")}
                          </Typography>
                          <Field
                            maxLength={100}
                            name="ambassadorName"
                            placeholder={t("name")}
                            style={{
                              border: "1px solid #DBDBDB",
                              height: 45,
                              width: "100%",
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 16,
                              paddingRight: 10,
                              outline: "none",
                              borderRadius: 16
                            }}
                          />
                          {errors.ambassadorName && touched.ambassadorName ? (
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5
                              }}
                            >
                              {errors.ambassadorName}
                            </Typography>
                          ) : null}
                        </Box>

                        <Box display="flex" flexDirection="column" mt="30px">
                          <Typography
                            style={{
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontSize: 16,
                              marginBottom: 17
                            }}
                          >
                            {t("Social Handle")}
                          </Typography>
                          <Field
                            maxLength={100}
                            name="socialHandle"
                            placeholder={t("@user")}
                            style={{
                              border: "1px solid #DBDBDB",
                              height: 45,
                              width: "100%",
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 16,
                              paddingRight: 10,
                              outline: "none",
                              borderRadius: 16
                            }}
                          />
                          {errors.socialHandle && touched.socialHandle ? (
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5
                              }}
                            >
                              {errors.socialHandle}
                            </Typography>
                          ) : null}
                        </Box>

                        <Box display="flex" flexDirection="column" mt="30px">
                          <Typography
                            style={{
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontSize: 16,
                              marginBottom: 17
                            }}
                          >
                            {t("Location")}
                          </Typography>
                          <Field
                            maxLength={100}
                            name="location"
                            placeholder={t("Rio de Janeiro")}
                            style={{
                              border: "1px solid #DBDBDB",
                              height: 45,
                              width: "100%",
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 16,
                              paddingRight: 10,
                              outline: "none",
                              borderRadius: 16
                            }}
                          />
                          {errors.location && touched.location ? (
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5
                              }}
                            >
                              {errors.location}
                            </Typography>
                          ) : null}
                        </Box>

                        <Box display="flex" flexDirection="column" mt="38px">
                          <Typography
                            style={{
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontSize: 16,
                              marginBottom: 17
                            }}
                          >
                            {t("Add description")}
                          </Typography>

                          <textarea
                            maxLength={2000}
                            value={values.description}
                            name="description"
                            onChange={handleChange("description")}
                            placeholder={t(
                              "Tell a bit more about the ambassador"
                            )}
                            style={{
                              border: "1px solid #DBDBDB",
                              height: 119,
                              width: "100%",
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: 14,
                              paddingLeft: 16,
                              paddingRight: 10,
                              paddingTop: 18,
                              outline: "none",
                              borderRadius: 16,
                              resize: "none"
                            }}
                          />
                          {errors.description && touched.description ? (
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5
                              }}
                            >
                              {errors.description}
                            </Typography>
                          ) : null}
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="column"
                          mt="16px"
                          mb="40px"
                        >
                          <Box display="flex" justifyContent="flex-end">
                            <Button
                              variant="contained"
                              type="submit"
                              style={{
                                backgroundColor: "#F14E24",
                                border: "1px solid #D9DBE9",
                                borderRadius: 16,
                                height: 34,
                                marginBottom: 14,
                                boxShadow: "none",
                                color: "#F7F7FC",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: 14,
                                marginTop: 30
                              }}
                            >
                              {t("Submit")}
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Grid>
            <Snackbar
              open={Boolean(this.state.error)}
              onClose={() => this.setState({ error: null })}
              message={this.state.error || "N/A"}
              severity="error"
            />
            <Loader loading={this.state.loading} />
            <CatchInvalidToken
              onClose={() => this.setState({ error: null })}
              message={this.state.error || "N/A"}
            />
          </Grid>
        </Grid>
      </>
    );
  }
  async componentDidMount() {
    const searchedUsers = localStorage.getItem("ambassadorSearchedUsers");

    if (!searchedUsers) {
      window.location.replace("/ambassadors/search-user");
    }
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
export const AmbassadorsCreateStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
    }
  },
  mainWrapper: {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    }
  },
  mainBodyContent: {
    height: "100%",
    marginTop: 30,
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      padding: 10
    }
  }
};
// Customizable Area End

export default withTranslation()(
  withStyles(AmbassadorsCreateStyle)(
    withRouter(AmbassadorsCreate as React.ComponentType<any>)
  )
);
