Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";

exports.endPointApiGetPortfolios = "portfolios";
exports.contentTypeApiGetPortfolios = "application/json";
exports.methodTypeApiGetPortfolios = "GET";

exports.contenttypeApiCreatePortfolio = "multipart/form-data";
exports.endPointApiCreatePortfolio = "portfolios";
exports.callTypeApiCreatePortfolio = "POST";
exports.callTypeApiUpdatePortfolio = "PATCH";

exports.endPointApiDeletePortfolio = "portfolios/";
exports.methodTypeApiDeletePortfolio = "DELETE";

exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.postHighlightedContentAPIEndPoint = "/highlighted_contents";
exports.getHighlightedContentAPIEndPoint = "/highlighted_contents";
exports.httpDeleteType = "DELETE";

exports.endPointApiGetCaseStudys = "case_studies";
exports.contentTypeApiGetCaseStudy = "application/json";
exports.methodTypeApiGetCaseStudy = "GET";

exports.contenttypeApiCreateCaseStudy = "multipart/form-data";
exports.endPointApiCreateCaseStudy = "case_studies";
exports.callTypeApiCreateCaseStudy = "POST";

exports.endPointApiUpdateCaseStudy = "case_studies/";
exports.callTypeApiUpdateCaseStudy = "PUT";

exports.endPointApiDeleteCaseStudy = "case_studies/";
exports.callTypeApiDeleteCaseStudy = "DELETE";

exports.endPointApiGetDetailCaseStudy = "case_studies/";
exports.callTypeApiGetDetailCaseStudy = "GET";

exports.endPointApiGetCategories = "categories/categories";
exports.contentTypeApiGetCategories = "application/json";
exports.methodTypeApiGetCategories = "GET";

exports.contenttypeApiCreateListing = "multipart/form-data";
exports.endPointApiCreateListing = "listings";
exports.callTypeApiCreateListing = "POST";

exports.endPointApiGetListings = "listings";
exports.callTypeApiGetListings = "GET";

exports.endPointApiUpdateListing = "listings/";
exports.callTypeApiUpdateListing = "PUT";

exports.endPointApiDeleteListing = "listings/";
exports.callTypeApiDeleteListing = "DELETE";

exports.contentTypeAbout = "multipart/form-data";
exports.endPointAboutApi = "account/accounts/1/update_about";
exports.MethodAboutAPi = "PUT";

exports.contentTypeGetSkills = "application/json";
exports.endPointGetSkills = "skills";
exports.MethodGetSkills = "GET";

exports.contentTypeUpdateSkills = "application/json";
exports.endPointUpdateSkills = "accounts/update_skills";
exports.methodUpdateSkills = "PATCH";

exports.contentTypeUpdateHeader = "multipart/form-data";
exports.endPointUpdateHeader = "account/accounts/";
exports.MethodUpdateHeader = "PUT";

exports.endPointGetUserData = "profiles/";
exports.contentTypeGetUserData = "application/json";
exports.MethodGetUserData = "GET";

exports.endPointSocialAccounts = "social_profiles";
exports.MethodSocialAccounts = "POST";

exports.endPointSelectedSkills = "skills/my_skills";
exports.MethodSelectedSkills = "GET";

exports.endPointGetSocialAccounts = "social_profiles";
exports.MethodGetSocialAccounts = "GET";

exports.endPointApiCreateAmbassadors = "brand_ambassadors";
exports.callTypeApiCreateAmbassadors = "POST";

exports.endPointApiSearchUserAmbassadors = "brand_ambassador_search";
exports.callTypeApiSearchUserAmbassadors = "POST";

exports.endPointApiResendBrandAmbassadorNotification = "brand_ambassador_resend/";
exports.callTypeApiResendBrandAmbassadorNotification = "POST";

exports.endPointApiUpdateAmbassadors = "brand_ambassadors/";
exports.callTypeApiUpdateAmbassadors = "PUT";

exports.endPointApiGetAmbassadors = "brand_ambassadors";
exports.methodTypeApiGetAmbassadors = "GET";

exports.endPointApiDeleteAmbassadors = "brand_ambassadors";
exports.methodTypeApiDeleteAmbassadors = "DELETE";

exports.endPointApiCreateJob = "job_histories";
exports.callTypeApiCreateJob = "POST";

exports.endPointApiGetJob = "job_histories";
exports.methodTypeApiGetJob = "GET";

exports.endPointApiDeleteJob = "job_histories/";
exports.callTypeApiDeleteJob = "DELETE";

exports.endPointApiUpdateJob = "job_histories/";
exports.methodTypeApiUpdateJob = "PUT";

exports.endPointApiCreateOpenPosition = "open_positions";
exports.callTypeApiCreateOpenPosition = "POST";

exports.endPointApiUpdateOpenPosition = "open_positions/";
exports.callTypeApiUpdateOpenPosition = "PUT";

exports.endPointApiGetOpenPosition = "open_positions";
exports.methodTypeApiGetOpenPosition = "GET";

exports.endPointApiDeleteOpenPosition = "open_positions/";
exports.methodTypeApiDeleteOpenPosition = "DELETE";

exports.endPointApiCreateTalents = "talents";
exports.callTypeApiCreateTalents = "POST";

exports.endPointApiSearchUserTalents = "talent_search";
exports.callTypeApiSearchUserTalents = "POST";

exports.endPointApiResendTalentNotification = "talent_resend/";
exports.callTypeApiResendTalentNotification = "POST";

exports.endPointApiUpdateTalents = "talents/";
exports.callTypeApiUpdateTalents = "PUT";

exports.endPointApiGetTalents = "talents";
exports.methodTypeApiGetTalents = "GET";

exports.endPointApiDeleteTalents = "talents";
exports.methodTypeApiDeleteTalents = "DELETE";

exports.endPointApiCreateClients = "clients";
exports.callTypeApiCreateClients = "POST";

exports.endPointApiUpdateClients = "clients/";
exports.callTypeApiUpdateClients = "PUT";

exports.endPointApiDeleteClients = "clients/";
exports.callTypeApiDeleteClients = "DELETE";

exports.endPointApiGetClients = "clients";
exports.methodTypeApiGetClients = "GET";

exports.MethodGetBrands = "GET";
exports.endPointGetBrands = "brands";

exports.MethodConnectSocialAccount = "POST";
exports.endPointConnectSocialAccount = "social_profiles";

exports.MethodConnectTwitchAccount = "POST";
exports.endPointConnectTwitchAccount = "twitch";

exports.endPointConnectGoogle = `authorize`;
exports.MethodConnectGoogle = "GET";

exports.MethodConnectYoutube = "POST";
exports.endPointConnectYoutube = "youtube_connect";

exports.endPointApiConnectAccounts = "bx_block_friendlist/connections";
exports.methodTypeApiConnectAccounts = "POST";

exports.endPointApiDisconnectAccounts = "bx_block_friendlist/disconnect";
exports.methodTypeApiDisconnectAccounts = "DELETE";

exports.endPointApiFollowAccounts = "bx_block_follower/followers";
exports.methodTypeApiFollowAccounts = "POST";
exports.endPointApiBlock = "block_profiles";
exports.methodTypeApiBlock = "POST";

exports.endPointApisetLanguage = "account/accounts/language_setup";
exports.methodTypeApisetLanguage = "PUT";

exports.endPointApiUnfollowAccounts = "bx_block_follower/followers/";
exports.methodTypeApiUnfollowAccounts = "DELETE";

exports.getUserdataWithoutTokenEndPoint = "users/"
exports.getUserdataWithoutTokenMethod = "GET"

exports.endPointApiUpdateAdjustableElements = "adjustable_elements";
exports.methodTypeApiUpdateAdjustableElements = "POST";

exports.endPointApiCreateCompanyInformation="company_informations";
exports.endPointApiNotificationCount="notifications/unread_notifications";

exports.methodTypeApigetCountryList = "GET";
exports.endPointApigetCountryList = "account/accounts/get_countries"

// Customizable Area End

