//@ts-ignore
//@ts-nocheck
import { Typography, Button, Checkbox, Box } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import SettingController from './SettingController.web';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {
  transition,
  boxShadow,
  drawerWidth,
} from "../../../components/src/ComponentTheme";
import { withTranslation } from 'react-i18next';

class SubSideMenu extends SettingController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  render() {
    const { classes, location, handleDrawerToggle, mobileOpen, t , i18n } = this.props;
    const activeTab = location.pathname;

    const list = () => {
      return(
      <>
      <Box style={{ display: "grid" }}>
        <Link to="/settings/edit-account" className={classes.linkText} >
          <div className={ activeTab === "/settings/edit-account" ? classes.routebtnActive : classes.routebtn}>
            <Typography
              className={classes.routebtnText}
              style={{ fontWeight: activeTab === "/settings/edit-account" ? "bold" : "normal"}}
            >
              {t("Edit Account")}
            </Typography>
          </div>
        </Link>
        <Link to="/settings/connect-account" className={classes.linkText} >
          <div className={ activeTab === "/settings/connect-account" ? classes.routebtnActive : classes.routebtn}>
            <Typography
              className={classes.routebtnText}
              style={{ fontWeight: activeTab === "/settings/connect-account" ? "bold" : "normal" }}
            >
              {t("Connect Accounts")}
            </Typography>
          </div>
        </Link>
        <Link to="/settings/change-password" className={classes.linkText} >
          <div className={ activeTab === "/settings/change-password" ? classes.routebtnActive : classes.routebtn }>
            <Typography
              className={classes.routebtnText}
              style={{ fontWeight: activeTab === "/settings/change-password" ? "bold" : "normal" }}
            >
              {t("Change Password")}
            </Typography>
          </div>
        </Link>
        <Link to="/settings/email-sms" className={classes.linkText} >
          <div className={activeTab === "/settings/email-sms" ? classes.routebtnActive : classes.routebtn} >
            <Typography
              className={classes.routebtnText}
              style={{ fontWeight: activeTab === "/settings/email-sms" ? "bold" : "normal"}}
            >
              {t("Email and SMS")}
            </Typography>
          </div>
        </Link>

        <Link to="/settings/privacy-security" className={classes.linkText} >
          <div className={activeTab === "/settings/privacy-security" ? classes.routebtnActive : classes.routebtn}>
            <Typography
              className={classes.routebtnText}
              style={{ fontWeight: activeTab === "/settings/privacy-security" ? "bold" : "normal" }}
            >
              {t("Privacy and Security")}
            </Typography>
          </div>
        </Link>
        <Link to="/settings/create-professional-account" className={classes.linkText}>
          <div className={ activeTab === "/settings/create-professional-account" ? classes.routebtnActive : classes.routebtn }>
            <Typography
              className={classes.routebtnText}
              style={{ fontWeight: activeTab === "/settings/create-professional-account" ? "bold" : "normal"}}
            >
              {t("Create Professional Account")}
            </Typography>
          </div>
        </Link>
      </Box>
    </>
    )}

    return (
      <>
        <Hidden xsDown>
          <div className={classes.collapse}>
            {list()}
          </div>
        </Hidden>
      <Hidden mdUp>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
      >
        <FiSettings style={{ fontSize: "larger", marginTop: 2 }} />
      </IconButton>
      </Hidden>
      <Hidden mdUp>
      <Drawer
        ariant="temporary"
        anchor={"right"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
        </IconButton>
        {list()}
      </Drawer>
      </Hidden>
      </>
    );
  }
}

const SubSideMenuStyle = theme => ({
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition
  },
  hidden: {
    width: "100%"
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto"
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center"
  },
  closeButtonDrawer: {
    position: "absolute",
    right: "8px",
    top: "9px",
    zIndex: "1"
  },
  routebtn: {
    fontSize: 15,
    display: "flex",
    padding: "12px 10px",
    cursor: "pointer",
    fontFamily: "Poppins",
  },
  routebtnActive: {
    fontSize: 15,
    display: "flex",
    padding: "12px 10px",
    cursor: "pointer",
    borderLeft: "2px solid #F14E24",
  },
  routebtnText: {
    fontSize: 15,
    fontFamily: "Poppins",
    paddingLeft: 10,
    letterSpacing: 1,
    paddingBottom: 5
  },
  profileImg: {
    width: 40,
    height: 40,
    borderRadius: "50%",
  },
  menuImg: {
    width: 19,
    height: 19,
  },
  linkText: {
    textDecoration: "none",
    color: "#333"
  }
});

export default withTranslation()(withStyles(SubSideMenuStyle)(withRouter(SubSideMenu as React.ComponentType<any>)))

SubSideMenu.defaultProp = {
  color: "black"
};
