//@ts-ignore
//@ts-nocheck
import * as React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from '@material-ui/core/Dialog';
import Slide from "@material-ui/core/Slide";
import { Typography, Button, Avatar, Box, Hidden } from "@material-ui/core";
import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ConfirmationModal = ({ isModal, handleModal, deleteAccount, t, i18n }) => {

  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            width: '35%',
            height: "20%"
          }
        }}
        open={isModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Typography style={{ fontSize: 15, paddingTop: 10,color:'black'}}>
          {/* {t("Are you sure to disable your account.")} */}
          Are you sure to delete this conversation.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button style={{ fontSize: 12, color: "white", background: "#9c9897"}} onClick={handleModal}>{t("Cancel")}</Button>
          <Button style={{ fontSize: 12, color: "white", background: "#F14E24"}}onClick={deleteAccount}>{t("Confirm")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withTranslation()(ConfirmationModal)