//@ts-ignore
//@ts-nocheck
import {
  Box, Button,
  Checkbox, Grid, IconButton, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CameraAltOutlined, CheckBox, Clear } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from 'react-i18next';
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import {
  checkboxIcon, stepOne
} from "./assets";
import CropImage from "./CropImage.web";
import PortfolioController, { Props } from "./PortfolioController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class PortfolioCreate extends PortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    const { classes, navigation, t, i18n } = this.props;
    const formDetails = JSON.parse(localStorage.getItem("portfolioDetails"));

    let cropUrl;
    const getCropImageUrl = (url) => {
      cropUrl = url
    }
    return (
      <>
        <ProfileHeader heading={t("Portfolio")} subModule={true} showProfile={true} multiPage backLocation="/portfolio" />
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Formik
            initialValues={
              formDetails || {
                title: "",
                description: "",
                categories: [],
                toolsUsed: "",
                banner: "",
                bannerUrl: cropUrl || ""
              }
            }
            validationSchema={this.CreatePortfolioSchema(t)}
            validateOnMount={true}
            onSubmit={(values) => {
              localStorage.setItem("portfolioDetails", JSON.stringify(values));
              this.props.history.push("/portfolio/media");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldError
            }) => {
              console.log(values);
              return (
                (
                  <Grid
                    sm={12}
                    md={6}
                    style={{
                      border: "1px solid #DEDDDA",
                      borderTop: "none",
                      height: "100%"
                    }}
                  >
                    <Box className={classes.progressBarRow}>
                      <Typography
                        style={{
                          color: "#808080",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 13,
                          marginRight: 14
                        }}
                      >
                        {t("Step")} 1 of 3
                      </Typography>
                      <img src={stepOne} style={{ height: 12, width: 185 }} />
                    </Box>
                    <Form
                      translate="yes"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        padding: 45
                      }}
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            // fontWeight: 700,
                            fontSize: 16,
                            marginBottom: 17
                          }}
                        >
                          {t("Project title")}
                        </Typography>
                        <Field
                          name="title"
                          placeholder={t("Insert a name for your project")}
                          style={{
                            border: "1px solid #DBDBDB",
                            height: 45,
                            width: "100%",
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14,
                            paddingLeft: 16,
                            paddingRight: 10,
                            outline: "none",
                            borderRadius: 16
                          }}
                        />
                        {errors.title && touched.title ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 5
                            }}
                          >
                            {errors.title}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box display="flex" flexDirection="column" mt="16px">
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            // fontWeight: 700,
                            fontSize: 16,
                            marginBottom: 17
                          }}
                        >
                          {t("Tools used")}
                        </Typography>
                        <Field
                          name="toolsUsed"
                          placeholder={t("Which software, hardware or materials you used?")}
                          style={{
                            border: "1px solid #DBDBDB",
                            height: 45,
                            width: "100%",
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14,
                            paddingLeft: 16,
                            paddingRight: 10,
                            outline: "none",
                            borderRadius: 16
                          }}
                        />
                        {errors.toolsUsed && touched.toolsUsed ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 5
                            }}
                          >
                            {errors.toolsUsed}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box display="flex" flexDirection="column" mt="33px">
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            // fontWeight: 700,
                            fontSize: 16,
                            marginBottom: 17
                          }}
                        >
                          {t("How do you categorize this project")}?*
                        </Typography>
                        <Grid
                          sm={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Grid
                            sm={12}
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Checkbox
                              checked={values.categories.includes("graphic_design")}
                              onChange={() =>
                                this.handleSelectCategories(
                                  "graphic_design",
                                  values.categories,
                                  setFieldValue
                                )
                              }
                              style={{ padding: 0, marginRight: 10 }}
                              checkedIcon={
                                <CheckBox
                                  style={{
                                    color: "#F14E24",
                                    width: 16,
                                    height: 16,
                                    padding: 0,
                                    margin: 0
                                  }}
                                />
                              }
                              icon={
                                <img
                                  src={checkboxIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                            />
                            <Typography
                              style={{
                                color: "#191919",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 13
                              }}
                            >
                              {t("Graphic design")}
                            </Typography>
                          </Grid>
                          <Grid
                            sm={12}
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Checkbox
                              checked={values.categories.includes("ui_ux")}
                              onChange={() =>
                                this.handleSelectCategories(
                                  "ui_ux",
                                  values.categories,
                                  setFieldValue
                                )
                              }
                              style={{ padding: 0, marginRight: 10 }}
                              checkedIcon={
                                <CheckBox
                                  style={{
                                    color: "#F14E24",
                                    width: 16,
                                    height: 16,
                                    padding: 0,
                                    margin: 0
                                  }}
                                />
                              }
                              icon={
                                <img
                                  src={checkboxIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                            />
                            <Typography
                              style={{
                                color: "#191919",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 13
                              }}
                            >
                              {t("UI/UX")}
                            </Typography>
                          </Grid>
                          <Grid
                            sm={12}
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Checkbox
                              checked={values.categories.includes("architecture")}
                              onChange={() =>
                                this.handleSelectCategories(
                                  "architecture",
                                  values.categories,
                                  setFieldValue
                                )
                              }
                              style={{ padding: 0, marginRight: 10 }}
                              checkedIcon={
                                <CheckBox
                                  style={{
                                    color: "#F14E24",
                                    width: 16,
                                    height: 16,
                                    padding: 0,
                                    margin: 0
                                  }}
                                />
                              }
                              icon={
                                <img
                                  src={checkboxIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                            />
                            <Typography
                              style={{
                                color: "#191919",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 13
                              }}
                            >
                              {t("Architecture")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          sm={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 12
                          }}
                        >
                          <Grid
                            sm={12}
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Checkbox
                              checked={values.categories.includes("illustration")}
                              onChange={() =>
                                this.handleSelectCategories(
                                  "illustration",
                                  values.categories,
                                  setFieldValue
                                )
                              }
                              style={{ padding: 0, marginRight: 10 }}
                              checkedIcon={
                                <CheckBox
                                  style={{
                                    color: "#F14E24",
                                    width: 16,
                                    height: 16,
                                    padding: 0,
                                    margin: 0
                                  }}
                                />
                              }
                              icon={
                                <img
                                  src={checkboxIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                            />
                            <Typography
                              style={{
                                color: "#191919",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 13
                              }}
                            >
                              {t("Illustration")}
                            </Typography>
                          </Grid>
                          <Grid
                            sm={12}
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Checkbox
                              checked={values.categories.includes("fashion")}
                              onChange={() =>
                                this.handleSelectCategories(
                                  "fashion",
                                  values.categories,
                                  setFieldValue
                                )
                              }
                              style={{ padding: 0, marginRight: 10 }}
                              checkedIcon={
                                <CheckBox
                                  style={{
                                    color: "#F14E24",
                                    width: 16,
                                    height: 16,
                                    padding: 0,
                                    margin: 0
                                  }}
                                />
                              }
                              icon={
                                <img
                                  src={checkboxIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                            />
                            <Typography
                              style={{
                                color: "#191919",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 13
                              }}
                            >
                              {t("Fashion")}
                            </Typography>
                          </Grid>
                          <Grid
                            sm={12}
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Checkbox
                              checked={values.categories.includes("advertising")}
                              onChange={() =>
                                this.handleSelectCategories(
                                  "advertising",
                                  values.categories,
                                  setFieldValue
                                )
                              }
                              style={{ padding: 0, marginRight: 10 }}
                              checkedIcon={
                                <CheckBox
                                  style={{
                                    color: "#F14E24",
                                    width: 16,
                                    height: 16,
                                    padding: 0,
                                    margin: 0
                                  }}
                                />
                              }
                              icon={
                                <img
                                  src={checkboxIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                            />
                            <Typography
                              style={{
                                color: "#191919",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 13
                              }}
                            >
                              {t("Advertising")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          sm={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 12
                          }}
                        >
                          <Grid
                            sm={12}
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Checkbox
                              checked={values.categories.includes("photography")}
                              onChange={() =>
                                this.handleSelectCategories(
                                  "photography",
                                  values.categories,
                                  setFieldValue
                                )
                              }
                              style={{ padding: 0, marginRight: 10 }}
                              checkedIcon={
                                <CheckBox
                                  style={{
                                    color: "#F14E24",
                                    width: 16,
                                    height: 16,
                                    padding: 0,
                                    margin: 0
                                  }}
                                />
                              }
                              icon={
                                <img
                                  src={checkboxIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                            />
                            <Typography
                              style={{
                                color: "#191919",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 13
                              }}
                            >
                              {t("Photography")}
                            </Typography>
                          </Grid>
                          <Grid
                            sm={12}
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Checkbox
                              checked={values.categories.includes("product_design")}
                              onChange={() =>
                                this.handleSelectCategories(
                                  "product_design",
                                  values.categories,
                                  setFieldValue
                                )
                              }
                              style={{ padding: 0, marginRight: 10 }}
                              checkedIcon={
                                <CheckBox
                                  style={{
                                    color: "#F14E24",
                                    width: 16,
                                    height: 16,
                                    padding: 0,
                                    margin: 0
                                  }}
                                />
                              }
                              icon={
                                <img
                                  src={checkboxIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                            />
                            <Typography
                              style={{
                                color: "#191919",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 13
                              }}
                            >
                              {t("Product Design")}
                            </Typography>
                          </Grid>
                        </Grid>
                        {errors.categories && touched.categories ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 10
                            }}
                          >
                            {errors.categories}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box display="flex" flexDirection="column" mt="38px">
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontSize: 16,
                            marginBottom: 17
                          }}
                        >
                          {t("Add description")}
                        </Typography>

                        <textarea
                          value={values.description}
                          name="description"
                          onChange={handleChange("description")}
                          placeholder={t("Tell a bit more about the project")}
                          style={{
                            border: "1px solid #DBDBDB",
                            height: 119,
                            width: "100%",
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14,
                            paddingLeft: 16,
                            paddingRight: 10,
                            paddingTop: 18,
                            outline: "none",
                            borderRadius: 16,
                            resize: "none"
                          }}
                        />
                        {errors.description && touched.description ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 5
                            }}
                          >
                            {errors.description}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box display="flex" flexDirection="column" mt="16px" mb="40px">
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            // fontWeight: 700,
                            fontSize: 16,
                            marginBottom: 17
                          }}
                        >
                          {t("Project banner")}
                        </Typography>

                        <Box
                          style={{
                            border: "1px solid #DBDBDB",
                            height: 244,
                            width: "100%",
                            outline: "none",
                            borderRadius: 16,
                            resize: "none",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundImage: values.bannerUrl
                              ? `url(${values.bannerUrl})`
                              : ""
                          }}
                        >
                          <input
                            type="file"
                            onChange={(e: any) => {
                              this.handleSelectBanner(
                                e,
                                setFieldValue,
                                setFieldError
                              )
                            }}
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              zIndex: 2,
                              cursor: "pointer",
                              opacity: 0
                            }}
                            accept="image/png, image/jpeg, image/jpg"
                          />
                          {this.state.ImgDialogOpen && <CropImage url={values.bannerUrl}
                            open={this.state.ImgDialogOpen}
                            handleClose={() => {
                              setFieldValue("bannerUrl", null);
                              this.setState({ ImgDialogOpen: false })
                            }}
                            getCropImageUrl={(url) => {
                              setFieldValue("bannerUrl", url);
                              this.setState({ ImgDialogOpen: false })
                            }} />}

                          {values.bannerUrl && (
                            <Box style={{ zIndex: 10 }}>
                              <IconButton
                                style={{ marginRight: 30, position: "relative" }}
                              >
                                <input
                                  type="file"
                                  onChange={(e: any) => {
                                    this.handleSelectBanner(
                                      e,
                                      setFieldValue,
                                      setFieldError
                                    )
                                  }}
                                  style={{
                                    position: "absolute",
                                    zIndex: 2,
                                    height: "100%",
                                    width: "100%",
                                    cursor: "pointer",
                                    opacity: 0
                                  }}
                                  accept="image/png, image/jpeg, image/jpg"
                                />
                                <CameraAltOutlined
                                  style={{
                                    color: "white",
                                    width: 24,
                                    height: "auto"
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={(e: any) =>
                                  this.handleSelectBanner("", setFieldValue, setFieldError)
                                }
                              >
                                <Clear
                                  style={{
                                    color: "white",
                                    width: 26,
                                    height: "auto"
                                  }}
                                />
                              </IconButton>
                            </Box>
                          )}
                          {values.bannerUrl && (
                            <Box
                              style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                zIndex: 5,
                                backgroundColor: "black",
                                opacity: 0.5
                              }}
                            />
                          )}

                          {!values.bannerUrl && (
                            <Typography
                              style={{
                                color: "rgba(0, 0, 0, 0.38)",
                                fontFamily: "Poppins",
                                // fontWeight: 700,
                                fontSize: 22
                              }}
                            >
                              {t("Choose banner")}
                            </Typography>
                          )}
                        </Box>
                        <Box display="flex">
                          <Typography
                            style={{
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: 12,
                              marginTop: "8px"
                            }}
                          >
                            {t("Ideal size")}:{" "}
                            <Typography
                              style={{
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: 12,
                                display: "inline"
                              }}
                            >
                              {t("1920x1080px")}
                            </Typography>
                          </Typography>
                        </Box>
                        {errors.bannerUrl && touched.bannerUrl ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 5
                            }}
                          >
                            {errors.bannerUrl}
                          </Typography>
                        ) : null}

                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            variant="contained"
                            type="submit"
                            style={{
                              backgroundColor: "#F14E24",
                              border: "1px solid #D9DBE9",
                              borderRadius: 16,
                              height: 34,
                              marginBottom: 14,
                              boxShadow: "none",
                              color: "#F7F7FC",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: 14,
                              marginTop: 30
                            }}
                          >
                            {t("Next")}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  </Grid>
                )
              )
            }}
          </Formik>
        </Grid>
      </>


    );
  }

  async componentDidMount() {
    // this.getValidations();
    // this.requestSessionData();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
export const portfolioCreateStyle = {
  progressBarRow: {
    marginRight: "30px", 
    marginTop: "-39px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
   
    "@media (max-width: 768px)": {
      marginRight: "0", 
      marginTop: "-32px",
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      marginTop: "15px",
    },
   
  },
};


// Customizable Area End
export default withTranslation()(
  withStyles(portfolioCreateStyle)(
    withRouter(PortfolioCreate as React.ComponentType<any>)
  )
);