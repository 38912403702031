Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings2";
exports.labelBodyText = "Settings2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";

exports.updatePasswordAPIEndPoint = "profile/password/";
exports.getUserDataAPIEndPoint = "profiles/";
exports.getSubscribeAPIEndPoint = "/subscribe_for_notifications/";
exports.subscribeAPIEndPoint = "/subscribe_for_notifications";

exports.endPointsendProfessionalAccount = "account/accounts/";
exports.MethodsendProfessionalAccount = "PUT";

exports.endPointGetUserData = "profiles/";
exports.MethodGetUserData = "GET";

exports.endPointeditProfile = "account/accounts/";
exports.MethodeditProfile = "PUT";

exports.endPointeditSecurity =
  "account/accounts/enable_disable_two_factor_auth";
exports.MethodeditSecurity = "PUT";

exports.MethodConnectSocialAccount = "POST";
exports.endPointConnectSocialAccount = "social_profiles";

exports.endPointGetSocialAccounts = "social_profiles";
exports.MethodGetSocialAccounts = "GET";

exports.MethodConnectTwitchAccount = "POST";
exports.endPointConnectTwitchAccount = "twitch";

exports.endPointConnectGoogle = "authorize";
exports.MethodConnectGoogle = "GET";

exports.MethodConnectYoutube = "POST";
exports.endPointConnectYoutube = "youtube_connect";

exports.endPointApisetLanguage = "account/accounts/language_setup";
exports.methodTypeApisetLanguage = "PUT";

exports.endPointDeleteAccount = "account/accounts/";
exports.MethodDeleteAccount = "DELETE"
// Customizable Area End
