// @ts-ignore
// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import Storage from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { firebaseAPI } from "../../../web/src/App";
// import { getMessaging, getToken } from "firebase";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import firebase from 'firebase';
import {db} from "../../../../packages/web/src/App"
import {Actioncable} from'Actioncable'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;

  // Customizable Area Start
  onClose:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  success: boolean;
  open:boolean;
  open2nd:boolean;
  messages:any[];
  messagesFilter:any[];
  users:any[];
  error: string | null;
  searchValue:string;
  selectUser:any;
  selectedChatRoomId:any;
  newMessage:any;
  singleChatRoom:any;
  currentAccountId:any;
  selectedUsersArray:any[]
  allAccount:any[];
  landing:boolean;
  isModal:boolean;
  id:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InboxController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  messageApiCallId: any;
  getMessageListApiCallId: any = "";
  getUserListApiCallId: any = "";
  deleteChatRoomApiCallId: any = "";
  getCreateChatRoomApiCallId: any = "";
  getCreateMessagesApiCallId: any = "";
  getShowMessagesApiCallId: any = "";
  getShowChatsApiCallId: any = "";
  editProfileApiCallId: any;
  intervalId:any;
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      error: null,
      enableField: false,
      messages:[],
  messagesFilter:[],
      users:[],
      open2nd:false,
      searchValue:"",
      selectedChatRoomId:'',
      newMessage:'',
      currentAccountId:'',
      singleChatRoom:[],
      // Customizable Area Start
      success: false,
      selectUser:null,
      selectedUsersArray:[],
      allAccount:[],
      open:false,
      loading:false,
      isModal: false,
      id:'',
      intervalId:null

      // Customizable Area End
    };
    this.CreateNewMessage = this.CreateNewMessage.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
  
    


  
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
  const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  let errors = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    
    
       if (this.messageApiCallId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.messageApiCallId) {
        
  
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
  
        console.log("errorReponse ", responseJson);
  
        if (responseJson && !responseJson.errors) {

          this.setState({ success: true })
          window.location.replace("/profile-dashboard");
        }
      }
      else if (apiRequestCallId===this.getMessageListApiCallId && this.getMessageListApiCallId!=undefined) {
        
      
  
       

        if (responseJson.data) {
           this.setState({ messages: responseJson.data });
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }
  
      }
  
      else if (apiRequestCallId===this.getUserListApiCallId && this.getUserListApiCallId!=undefined) {
        

  
       
        if (responseJson) {
          if(responseJson.data)
          {


            const id = localStorage.getItem("userId")
  
            this.setState({ users: responseJson.data});
          }else{
            this.setState({ users: []});

          }
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }
  
      }
      else if (apiRequestCallId===this.getCreateChatRoomApiCallId && this.getCreateChatRoomApiCallId!=undefined) {
       
  
       
        if (responseJson) {
          if(responseJson.chatroom.data.attributes.accounts.data.length>1){
            this.setState({ selectedChatRoomId: responseJson.chatroom.data.id,selectUser:responseJson.chatroom.data.attributes.admin.data,allAccount:responseJson.chatroom.data.attributes.accounts.data,singleChatRoom:responseJson.chatroom.data.attributes.messages.data});
          }else
          {
            this.setState({ selectedChatRoomId: responseJson.chatroom.data.id,selectUser:responseJson.chatroom.data.attributes.accounts.data[0],allAccount:responseJson.chatroom.data.attributes.accounts.data,singleChatRoom:responseJson.chatroom.data.attributes.messages.data});


           }
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }
  
      } else if (apiRequestCallId===this.getShowChatsApiCallId && this.getShowChatsApiCallId!=undefined) {
      
  
        if (responseJson) {
         
           this.setState({ messages: responseJson.chatrooms.data,messagesFilter:responseJson.chatrooms.data ,loading:false});
        } else if (responseJson?.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }
  
      }
      else if (apiRequestCallId===this.getShowMessagesApiCallId && this.getShowMessagesApiCallId!=undefined) {
      
  
       
        if (responseJson) {
      

let reverse=[...responseJson.data].reverse()
         
            this.setState({ singleChatRoom: reverse,loading:false },()=>console.log('singlechat',this.state.singleChatRoom));
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }
  
      }
      if (apiRequestCallId===this.getCreateMessagesApiCallId && this.getCreateMessagesApiCallId!=undefined ) {
      
  
       
        if (!responseJson.error) {
          let tempArray=this.state.singleChatRoom
  
          tempArray.push(responseJson.data)
           this.setState({ singleChatRoom: tempArray,newMessage:'' });
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }
  
      }else if (apiRequestCallId===this.deleteChatRoomApiCallId&& this.deleteChatRoomApiCallId!=undefined ) {
        
      
  
       
        if (!responseJson.error) {
          console.log(responseJson)
    this.setState({loading:false,isModal:false})
          this.getAllChatroom();
         
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }
  
      }

    }else {
      console.log(responseJson)
      this.parseApiErrorResponse(responseJson);
    }
    

    // Customizable Area End

  }



  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  selectUser=(user)=>{

    this.setState({ selectUser:user });
    

  }

  handleSelectFile = (
    file: any
  ) => {

    
    if(file && !['image/png', 'image/jpeg', 'image/jpg','application/pdf'].includes(file.type)){
      return  alert('Only png and jpeg are supported.')
    }
    
    
   
    this.createFileMessages(file)
  };


  handleClickOpen = () => {
    this.setState({ open:true });
    
  };
  handleClickOpen2nd = () => {
    this.setState({ open2nd:true });
    
  };
  handleClose2nd = () => {
    this.setState({ open2nd:false });
    
  };

  handleClose = (user) => { 

    
    if(user.hasOwnProperty('account')){
      
    
      let tempArray=[];
      tempArray=this.state.selectedUsersArray;
     
       let result = tempArray.find(item=>item.account.data.id==user.account.data.id)
    
      if(!result){
      

        
        tempArray.push(user);
      }
     
      this.setState({selectedUsersArray:tempArray})
    }

    else{

     

      this.setState({ open:false },()=>this.createChatRoom());
    }
   
  };

  noClick=()=>{
    this.setState({ open:false });
  }

  

  removeSelectedUserHandler = (user) => {
    const selectedUsers = this.state.selectedUsersArray; 
    selectedUsers.splice(selectedUsers.findIndex(a => a.account.data.id === user.account.data.id) , 1)
    this.setState({selectedUsersArray:selectedUsers})
  }
  

  deleteChat=(id)=>{
    if(id==this.state.selectedChatRoomId){
      this.setState({selectUser:null,selectedChatRoomId:[]})
      this.setState({loading:true})
    
    this.deleteChatRoom(id);
      
    }else{

 
      this.setState({loading:true})
      this.deleteChatRoom(id);
    }

  }

  openSingleChat(chatData){
    console.log(chatData)
    if(chatData.attributes.accounts.data.length>1){
      this.setState({ selectedChatRoomId: chatData.id,selectUser:chatData.attributes.admin.data,allAccount:chatData.attributes.accounts.data},()=>{this.getAllMessages()});
    }else
    {
      this.setState({ selectedChatRoomId: chatData.id,selectUser:chatData.attributes.accounts.data[0],allAccount:chatData.attributes.accounts.data},()=>{this.getAllMessages()});


     }
  

  }
//   recall(){
//     let fn=this


//     var intervalId = window.setInterval(function(){
//       this
//  fn.getAllMessages();


//     }, 10000);
//   }

  CreateNewMessage(value){
    let data=value.target.value;
    this.setState({newMessage:data})
  }

 

  

  updateSearch=(e)=>{
    this.setState({ searchValue:e.target.value },()=> this.getAllUsers());
   
   
  }
  updateSearchList=(e)=>{

    if(e.target.value){
      let filterData=this.state.messages.filter(item=>item.attributes.accounts.data[0].attributes.first_name.includes(e.target.value))
    this.setState({messagesFilter:filterData})


    }else{

      this.setState({messagesFilter:this.state.messages})
    }



  }
  getAllUsers=async()=>{

    try {
    const header = {
      token: localStorage.getItem("userToken"),
      "Content-Type":configJSON.contentTypeApiGetUserList
    };  
    const httpBody = {
      search: this.state.searchValue,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetUserList}/${this.state.searchValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserList
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }


}

getAllChatroom=async()=>{
  this.setState({loading:true})


  try {
  const header = {
    token: localStorage.getItem("userToken"),
    "Content-Type":configJSON.contentTypeApiGetChatRooms
  };  
 

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getShowChatsApiCallId = requestMessage.messageId;


  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiGetGetChatRooms
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiGetGetChatRooms
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
} catch (error) {
  console.log(error);
}


}

  startNewMessage=(user)=>{
    const id = localStorage.getItem("userId")

         db.collection('users').doc(id).collection(user.id).add({
    
     name:user.attributes.first_name,
     text:'hello',
     createdAt:new Date().getTime()
        
    })
  .then(function() {
      console.log("Document successfully written!");
  })
  .catch(function(error) {
      console.error("Error writing document: ", error);
  });

 

  db.collection(id).doc(user.id).get()
.then(querySnapshot => {
  const data = querySnapshot.docs.map(doc => doc.data());
});

  }

  deleteChatRoom=async(id)=>{

    try {
      const header = {
        token: localStorage.getItem("userToken"),
      };  
      const httpBody = {
        id: id,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.deleteChatRoomApiCallId = requestMessage.messageId;

      const formData = new FormData();
      formData.append('id',id)
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointDeleteChatBox}/${id}`
      ); 
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodDeleteChatBox
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }

}

  createChatRoom=async()=>{   
    try {
      
     
      
      const header = {
        token: localStorage.getItem("userToken")
      };

      const formData = new FormData();
    formData.append("accounts[0]",localStorage.getItem("userId"));
    
    formData.append("chatroom[title]",`${localStorage.getItem("userId")}${this.state.selectedUsersArray[0].account.data.id}`);
    
    this.state.selectedUsersArray.map((item,i)=>{
      formData.append(
        `accounts[${i+1}]`,item.account.data.id
        
      );
    })

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCreateChatRoomApiCallId= requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiGetCreateChatBox
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetCreateChatBox
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }


}
createMessages=async(id)=>{
 

  const message=this.state.newMessage;
  const chatRoomId=this.state.selectedChatRoomId;

  if(!message.trim() || !message){
    return null;
  }

  try {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCreateMessagesApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetCreateMessages
    );
    
    const header = {
      token: localStorage.getItem("userToken"),
    };  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

  const formData = new FormData();
  formData.append("message[chatroom_id]",chatRoomId);
  formData.append("message[body]",message);

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiGetCreateMessages
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
} catch (error) {
  console.log(error);
}


}

createFileMessages=async(file)=>{
  const message=this.state.newMessage;
  const chatRoomId=this.state.selectedChatRoomId;

  try {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCreateMessagesApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetCreateMessages
    );
    
    const header = {
      token: localStorage.getItem("userToken"),
    };  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

  const formData = new FormData();

  formData.append("message[chatroom_id]",chatRoomId);
  formData.append("message[body]",'');

  const blob = await fetch(file).then(r => r.blob());

  formData.append("message[attached_files]",file);

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiGetCreateMessages
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
} catch (error) {
  console.log(error);
}


}

  getAllMessages=async()=>{
    this.setState({loading:true})


    try {
      const header = {
        token: localStorage.getItem("userToken"),
      };  
      const formData = new FormData();
      console.log(this.state.selectedChatRoomId)
      formData.append('chatroom_id',this.state.selectedChatRoomId)
      for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getShowMessagesApiCallId= requestMessage.messageId;
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiGetGetMessages}?chatroom_id=${this.state.selectedChatRoomId}`
      );
      
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetGetChatRooms
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
   
  }
  // Customizable Area End
  
}
