Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Notifications";
exports.labelBodyText = "Notifications Body";

exports.notificationEndPoint = "accounts/notifications";

exports.btnExampleTitle = "CLICK ME";

exports.endPointApiGetNotificationList = "notifications";
exports.contentTypeApiGetNotificationList = "application/json";
exports.methodTypeApiGetNotificationList="GET"
exports.endPointApiGetUserList = "bx_block_friendlist/my_connections";
exports.contentTypeApiGetUserList = "application/json";
exports.methodTypeApiGetUserList="GET"
exports.endPointApiGetCreateChatBox = "bx_block_chat/chatrooms";
exports.contentTypeApiGetCreateChatBox = "multipart/form-data";
exports.methodTypeApiGetCreateChatBox="POST"
exports.endPointApiGetCreateMessages = "bx_block_chat/messages";
exports.contentTypeApiGetCreateMessages = "application/json";
exports.methodTypeApiGetCreateMessages="POST"
exports.endPointApiGetGetMessages = "bx_block_chat/messages";
exports.contentTypeApiGetGetMessages = "application/json";
exports.methodTypeApiGetGetMessages="GET"
exports.endPointApiGetGetChatRooms = "bx_block_chat/chatrooms";
exports.contentTypeApiGetGetChatRooms = "application/json";
exports.methodTypeApiGetGetChatRooms="GET"
exports.endPointeditProfile = "account/accounts/";
exports.MethodeditProfile = "PUT";
exports.endPointDeleteChatBox = "bx_block_chat/chatrooms";
exports.endPointRejectConnection = "bx_block_friendlist/connections";
exports.endPointAcceptConnection = "bx_block_friendlist/request_accept";
exports.endPointRejectTalent = "talent_reject";
exports.endPointAcceptTalent = "talent_request_accept";
exports.endPointRejectBrand = "brand_ambassador_reject";
exports.endPointAcceptBrand = "brand_ambassador_request_accept";
exports.MethodAcceptConnection = "POST";
exports.MethodDeleteChatBox = "DELETE";
exports.endPointApiProfileTasks = "profile_tasks";
exports.methodTypeApiProfileTasks = "GET";
// Customizable Area End