import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible,listingProductPlacement,listingSocialCampaign, socialMediaCampaign, productPlacement, productUnboxing, } from "./assets";
import { EditAttributes } from "@material-ui/icons";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  value: number;
  adjustableElements: any[];
  userDetails: any;
  subModule: number;
  heading: string;
  loading: boolean;
  error: any;
  open:any;
  showAllPortfolios: boolean;
  showAllListings: boolean;
  showAllContent: boolean;
  showAllAbout: boolean;
  showAllClients: boolean;
  showAllAmbassadors: boolean;
  showAllCases: boolean;
  showAllPositions: boolean;
  showAllTalents: boolean;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  getUserInformationApiCallId: any;
  connectAccountApiCallId: any = '';
  disconnectAccountApiCallId: any = '';
  followAccountApiCallId: any = '';
  blockApiCallId:any='';
  getUserdataWithoutTokenApiCallId: any = "";
  updateAdjustableElementsApiCallId: any = "";
  setLanguageApiCallId: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      value: 0,
      adjustableElements: [],
      userDetails: {},
      subModule: 1,
      heading: "Build your profile",
      loading: false,
      error: null,
      open:false,
      showAllPortfolios: false,
      showAllListings: false,
      showAllContent: false,
      showAllAbout: false,
      showAllClients: false,
      showAllAmbassadors: false,
      showAllCases: false,
      showAllPositions: false,
      showAllTalents: false
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId && responseJson) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData) {
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }

              if (regexData.email_validation_regexp) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules
                });
              }
            }
          }
        } else if (apiRequestCallId === this.userProfileGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data.attributes;

            if (this.userAttr !== null && this.userAttr !== undefined) {
              let email = this.userAttr.email;
              let firstName = this.userAttr.first_name;
              let lastName = this.userAttr.last_name;
              let currentCountryCode = this.userAttr.country_code;
              let phoneNumber = this.userAttr.phone_number
                ? this.userAttr.phone_number
                : "";

              this.setState({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
              });

              //@ts-ignore
              this.txtInputFirstNameProps.value = firstName;

              //@ts-ignore
              this.txtInputLastNameProps.value = lastName;

              //@ts-ignore
              this.txtInputEmailProps.value = email;

              //@ts-ignore
              this.txtInputPhoneNumberProps.value = phoneNumber;

              this.registrationAndLoginType = this.userAttr.type;

              if (this.userAttr.country_code) {
                this.setState({ currentCountryCode: currentCountryCode });
              }

              if (
                configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: true
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: false,
                  llDoChangePwdContainerVisible: false
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
              ) {
                this.setState({
                  llChangePwdDummyShowContainerVisible: true,
                  edtMobileNoEnabled: false,
                  countryCodeEnabled: false
                });
                this.txtInputPhoneNumberProps.editable = false;
              }
            }
          } else {
            //Check Error Response
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiChangePhoneValidation) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success
            this.validateAndUpdateProfile();
          } else {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (
          apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
        ) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success

            this.showAlert("Success", "Profile updated successfully.");
            this.enableDisableEditPassword(false);
            this.getUserProfile();
          } else {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          }
        } else if (apiRequestCallId === this.getUserInformationApiCallId) {
          if (responseJson != null && responseJson.errors === undefined) {
              if(responseJson?.data?.attributes?.brand?.data?.attributes?.user_type){
                let userAdjustableElements = responseJson?.data?.attributes?.adjustable_elements?.data;
                
                let adjustableElements = [];
                
                for (let i = 0; i < userAdjustableElements.length; i++) {
                  const element = this.adjustableElements[userAdjustableElements[i].attributes.name.toLowerCase()];
                  if (element) {
                    adjustableElements.push({...userAdjustableElements[i].attributes, sub_title: element.subTitle, redirect_to: element.redirectTo})
                  }
                }
                
              this.setState({
                adjustableElements,
                loading:false,
                userDetails: {...responseJson.data.attributes,id:responseJson.data.id}
              });
              let userId = localStorage.getItem("userId")
              let serachUserId = localStorage.getItem("serachUserId")
              if (userId === serachUserId) {
              let language = responseJson?.data?.attributes?.language
              if (language === "ko"){
                localStorage.setItem("langauge", "kr")
              }
              if(language === "pt") {
                localStorage.setItem("langauge", "br")
              }
              if(language === "in") {
                localStorage.setItem("langauge", "id")
              }
              if (language === "en"){
                localStorage.setItem("langauge", "en")
              }
              if (language === "th"){
                localStorage.setItem("langauge", "th")
              }
            }else{
              this.setState({loading:false})
            }
          }else{
            this.setState({loading:false})
          }
          }   else {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error, loading:false });
          }
          let step = responseJson?.data?.attributes?.step
            switch (step){
              // case 1: { return this.props.history.push("/")}
              case 1: { return this.props.history.push("/select-type")}
              case 2: { return this.props.history.push("/select-kind")}
              case 3: { return this.props.history.push("/setup-profile")}
              case 4: { return this.props.history.push("/select-helps")}
              case 5: { return this.props.history.push("/confirm-details")}
              case 6: {return this.props.history.push("/select-interests")}
              // case 7: { return this.props.history.push("/profile-dashboard")}
              default: return step
            }
        } 
        else if (apiRequestCallId === this.getUserdataWithoutTokenApiCallId) {
          if (responseJson != null && responseJson.errors === undefined) {
            if(responseJson?.data?.attributes?.brand?.data?.attributes?.user_type){  
                let userAdjustableElements = responseJson?.data?.attributes?.adjustable_elements?.data;
                
                let adjustableElements = [];
                
                for (let i = 0; i < userAdjustableElements.length; i++) {
                  const element = this.adjustableElements[userAdjustableElements[i].attributes.name.toLowerCase()];
                  if (element) {
                    adjustableElements.push({...userAdjustableElements[i].attributes, sub_title: element.subTitle, redirect_to: element.redirectTo})
                  }
                }
                
              this.setState({
                adjustableElements,
                userDetails: {...responseJson.data.attributes,id:responseJson.data.id}
              });
              
             
            }
          } else {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          }
        }
        else  if (apiRequestCallId === this.connectAccountApiCallId) {
          if (responseJson.data) {
            this.getUserInformation();
            this.setState({loading: false})
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error , loading: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
          }
  
          // this.parseApiCatchErrorResponse(responseJson);
        } else  if (apiRequestCallId === this.disconnectAccountApiCallId) {
          if (responseJson.data) {
            this.getUserInformation();
            this.setState({loading: false})
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error , loading: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
          }
  
          // this.parseApiCatchErrorResponse(responseJson);
        } else if (apiRequestCallId === this.followAccountApiCallId) {
          if (responseJson.data) {
            this.getUserInformation();
            this.setState({loading: false})
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error , loading: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
          }
  
          // this.parseApiCatchErrorResponse(responseJson);
        }else if (apiRequestCallId === this.blockApiCallId) {
          if (responseJson.data) {

            this.getUserInformation();
            this.setState({loading: false})
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error , loading: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
          }
  
          // this.parseApiCatchErrorResponse(responseJson);
        } else if (apiRequestCallId === this.setLanguageApiCallId) {
          if (responseJson.data) {
            console.log("language", responseJson.data)
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error , loading: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
          }
        }
        else if (apiRequestCallId === this.updateAdjustableElementsApiCallId) {
          if (responseJson.data) {

            window.location.reload();
            this.setState({loading: false})
          } else if (responseJson.errors) {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error , loading: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
          }
  
          // this.parseApiCatchErrorResponse(responseJson);
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
     onChangeText: (text:string) => 
     {
       if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })
     
        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
       }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType:"tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps  = {
    value: "",
    editable: true,
    onChangeText: (text:string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps  = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
  ? this.txtInputEmailWebProps
  : this.txtInputEmailMobileProps;
  
  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => { 
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text:string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ 
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry });
        this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
        this.imgNewPasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText:(text:string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({ 
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry });
        this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
        this.imgReTypePasswordShowhideProps.source =  this.txtInputNewPasswordProps.secureTextEntry  ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  };
  
  adjustableElements = {
    "highlighted content": {
      subTitle: "Your featured content",
      redirectTo: "/highlighted-content"
    },
    "skills" : {
      subTitle: "What you can do",
      redirectTo: "/edit-skills"
    },
    "portfolio":{
      subTitle: "Highlight your work",
      redirectTo: "/portfolio"
    },
    "listings":{
      subTitle: "How you can be hired",
      redirectTo: "/listings"
    },
    "job history":{
      subTitle: "Your previous jobs/work",
      redirectTo: "/job-history"
    },
    "case studies":{
      subTitle: "Show how brands/Gamers work with you",
      redirectTo: "/casestudy"
    },
    "clients": {
      subTitle: "Companies that has worked with you",
      redirectTo: "/clients"
    },
    "open positions": {
      subTitle: "How people can work for you",
      showPreview: true,
      redirectTo: "/open-positions"
    },
    "brand ambassadors": {
      subTitle: "The people who speak for you",
      redirectTo: "/ambassadors"
    },
    "information" : {
      subTitle: "Background information on your organization",
      redirectTo: "/company-information"
    },
    "teams" : {
      subTitle: "List of games you compete in",
      redirectTo: null
    },
    "sponsors": {
      subTitle: "Brands that support you",
      redirectTo: null
    },
    "talent": {
      subTitle: "Your Roster of Talent",
      redirectTo: "/talents"
    }
  };
  
  handleClickOpen=()=>{
    this.setState({open:true})
  }

  handleClose = (id) => { 
    console.log(id)
    // console.log(user)
    
    // if(user.hasOwnProperty('id')){
      
    //   console.log('2nd'+user)
      
    //   this.setState({singleChatRoom:[],selectUser:user, open:false},()=>this.createChatRoom())
    // }

    // else{

    //   this.setState({ open:false },()=>console.log('4th'+this.state.selectUser));
    // }
   
  };

  getUserdataWithoutToken = async (lastDirecotry) => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };
      
      // const id = window.location.pathname.includes('user-profile') ? window.location.pathname.split('/')[2] : localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getUserdataWithoutTokenApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getUserdataWithoutTokenEndPoint}${lastDirecotry}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getUserdataWithoutTokenMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  getUserInformation = async () => {
    try {
      this.setState({loading:true})
      const header = {
        token: localStorage.getItem("userToken")
      };
      // const id = localStorage.getItem("userId")
      const id = window.location.pathname.includes('user-profile') ? window.location.pathname.split('/')[2] : localStorage.getItem("userId");
       localStorage.setItem("serachUserId",id);
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getUserInformationApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointGetUserData}${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.MethodGetUserData
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading:false})
      console.log(error);
    }
  };
  
  connectAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };
  
      const data = {
        connection_id: userId
      };
  
      const httpBody = { data };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.connectAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiConnectAccounts
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiConnectAccounts
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  disconnectAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.disconnectAccountApiCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiDisconnectAccounts}?person_id=${userId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiDisconnectAccounts
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
    blockAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "token": localStorage.getItem('userToken')
      };

      const formData = new FormData();
      formData.append('block_profile[blocked_id]',userId)
  
      // const data = {
      //   block_profile[blocked_id]: userId
      // };
  
      // const httpBody = { data };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.blockApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiBlock
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiBlock
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }

  setLanguage = async (langauge) => {
    try {
      // this.setState({loading: true})
      
      const header = {
        "token": localStorage.getItem('userToken')
      };

      const formData = new FormData();
      if(langauge === "br") {
        formData.append('language', "pt")
      }else if (langauge === "kr"){
        formData.append('language', "ko")
      }else if (langauge === "id"){
        formData.append('language', "in")
      }else if (langauge === "th"){
        formData.append('language', "th")
      }else{
        formData.append('language', "en")
      }
  
      // const data = {
      //   block_profile[blocked_id]: userId
      // };
  
      // const httpBody = { data };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setLanguageApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApisetLanguage
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApisetLanguage
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  unBlockAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "token": localStorage.getItem('userToken')
      };

      const formData = new FormData();
      formData.append('block_profile[blocked_id]',userId)
  
      // const data = {
      //   block_profile[blocked_id]: userId
      // };
  
      // const httpBody = { data };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.blockApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiBlock
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiBlock
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }

  followAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };
  
      const data = {
        following_id: userId
      };
  
      const httpBody = { data };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.followAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiFollowAccounts
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiFollowAccounts
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  unfollowAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.followAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiUnfollowAccounts}${userId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiUnfollowAccounts
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  updateAdjustableElementsHandler = async (adjustableElements) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };
      
      const httpBody = { data:  adjustableElements};

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateAdjustableElementsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiUpdateAdjustableElements}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiUpdateAdjustableElements
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }

  updatePreview = async (index) => {
    const adjustableElements = [...this.state.adjustableElements];

    adjustableElements[index].visible = !adjustableElements[index]
      .visible;
      
    this.updateAdjustableElementsHandler(adjustableElements)
    
  };

  updateAdjustableElements = async () => {
      this.getUserInformation();
  };
  
  getCategoryImage = (category) => {
    let image = socialMediaCampaign;
    switch (category) {
      case "social_media_campaign":
        image = socialMediaCampaign;
        break;
      case "product_placement":
        image = productPlacement;
        break;
      case "product_un_boxing":
        image = productUnboxing;
        break;

      default:
        break;
    }

    return image;
  };
  
  getListingCategoryImage = (category) => {
    let image = listingSocialCampaign;
    switch (category) {
      case "social_media_campaign":
        image = listingSocialCampaign;
        break;
      case "product_placement":
        image = listingProductPlacement;
        break;
      case "product_un_boxing":
        image = listingSocialCampaign;
        break;

      default:
        break;
    }

    return image;
  };

  // Customizable Area End

}
