//@ts-ignore
//@ts-nocheck
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import React from "react";
import { withTranslation } from "react-i18next";
import { BiArrowBack } from "react-icons/bi";
import { withRouter } from "react-router";
import {
  EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from "react-share";
import AboutController, { Props } from "./AboutController.web";
import { ShareIcon } from "./assets";

class ProfileHeader extends AboutController {
  constructor(props:Props) {
    super(props);
  }

  componentDidMount() {
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
  }

  render() {
    const {
      classes,
      subModule,
      heading,
      history,
      showProfile,
      connectBlock,
      backLocation,
      multiPage,
      onClick,
      t,
      i18n
    } = this.props;

    const userToken = localStorage.getItem("userToken");
    const username = window.location.pathname.includes("user")
      ? window.location.pathname.split("/")[2]
      : "";
    const user_name = userToken ? localStorage.getItem("userName") : username;
    const devUrl =
      "https://yuppgg-68443-react-native.b68443.dev.us-east-1.aws.svc.builder.cafe";
    const stageUrl =
      "https://yuppgg-68443-ruby.b68443.stage.us-east-1.aws.svc.builder.ai";
    const prodUrl = "https://www.yup.gg";
    const shareUrl = `${prodUrl}/user/${user_name}`;
    const title = "Yup.gg";

    return (
      <>
        <Box className={classes.headerProfile}>
          <Grid container style={{ height: 65 }}>
            {showProfile ? (
              <>
                <Grid item xs={2} className={classes.gridImgBlock}>
                  <img
                    style={{ height: "30px" }}
                    className={classes.imgLogo + " " + classes.headerImgLogo}
                    src={require("../assets/logo 96x96.svg")}
                  />
                </Grid>
                <Grid item xs={6} className={classes.headerRow}>
                  {subModule === 1 ? (
                    <Typography className={classes.buildText}>
                      {" "}
                      {heading}
                    </Typography>
                  ) : (
                    <div
                      className={classes.innerTitleBlock}
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={
                        onClick
                          ? () => onClick()
                          : multiPage
                          ? () => history.push(`${backLocation}`)
                          : () => history.push("/profile-dashboard")
                      }
                    >
                      <BiArrowBack
                        style={{ position: "absolute", fontSize: 22 }}
                      />
                      <Typography className={classes.buildText2}>
                        {heading}
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid item xs={4} className={classes.headerBlock}>
                  <Box
                    style={{
                      margin: 20,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Typography
                      className={classes.shareLink}
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        paddingRight: "6px"
                      }}
                    >
                      {t("Your profile:")}
                    </Typography>
                    <Typography
                      className={classes.shareLink}
                      style={{ fontFamily: "Poppins" }}
                    >
                      {shareUrl}
                    </Typography>
                    <img
                      src={ShareIcon}
                      style={{ fontFamily: "Poppins" }}
                      onClick={() => this.setState({ showShareMenu: true })}
                      className={classes.shareIcon}
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Hidden xsDown>
                  <Grid item xs={3} className={classes.gridImgBlock}>
                    <img
                      className={classes.imgLogo + " " + classes.headerImgLogo}
                      style={{ height: "30px" }}
                      src={require("../assets/logo 96x96.svg")}
                    />
                  </Grid>
                </Hidden>
                <Grid item xs={2} className={classes.headerBlockSetting}>
                  <Box
                    style={{ marginTop: 20, display: "flex", marginLeft: 20 }}
                  >
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        fontFamily: "Poppins"
                      }}
                    >
                      {t("Settings")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} className={classes.headerBlockHeading}>
                  <Typography
                    className={
                      connectBlock
                        ? classes.connectTextSetting
                        : classes.buildTextSetting
                    }
                  >
                    {heading}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Modal
          open={this.state.showShareMenu}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          onClose={() => this.setState({ showShareMenu: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            display="flex"
            bgcolor="white"
            width={400}
            height={"auto"}
            padding="30px"
          >
            <Box display="flex" flexDirection="column" width="100%">
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  color: "#000000",
                  marginBottom: 10
                }}
              >
                {t("Share via")}:
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>

                <TwitterShareButton url={shareUrl} title={title}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>

                <TelegramShareButton url={shareUrl} title={title}>
                  <TelegramIcon size={32} round />
                </TelegramShareButton>

                <WhatsappShareButton
                  url={shareUrl}
                  title={title}
                  separator=":: "
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>

                <EmailShareButton url={shareUrl} subject={title} body="body">
                  <EmailIcon size={32} round />
                </EmailShareButton>

                <IconButton
                  onClick={() => navigator.clipboard.writeText(shareUrl)}
                >
                  <FileCopyOutlinedIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
}

const profileHeaderStyle = {
  headerRow: {
    border: "#80808042 1px solid",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    height: "65px",
    "@media (max-width: 768px)": {
      paddingRight: 20,
      border: 0,
      width: "83%"
    }
  },
  gridImgBlock: {
    border: "#80808042 1px solid",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "65px",
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  headerBlockHeading: {
    border: "#80808042 1px solid",
    display: "flex",
    "@media (max-width: 768px)": {
      border: 0,
      padding: 10
    }
  },
  headerBlockSetting: {
    border: "#80808042 1px solid",
    display: "flex",
    "@media (max-width: 768px)": {
      border: 0,
      display: "none"
    }
  },
  imgLogo: {
    width: 100,
    height: 30,
    marginLeft: 76,
    marginTop: 18,
    opacity: 1,
    objectFit: "contain",
    marginRight: 20
  },
  headerBlock: {
    border: "#80808042 1px solid",
    display: "flex",
    height: "65px",
    "@media (max-width: 768px)": {
      border: 0
    }
  },
  shareIcon: {
    fontSize: "38px",
    paddingLeft: "10px",
    cursor: "pointer",
    width: "34px",
    "@media (max-width: 768px)": {
      position: "absolute",
      right: "64px",
      marginTop: "8px"
    }
  },
  shareLink: {
    fontSize: 12,
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  headerProfile: {
    width: "100%",
    height: 65,
    marginLeft: 0,
    marginTop: 0,
    opacity: 1
  },
  imgLogo: {
    width: 100,
    height: 20,
    opacity: 1,
    objectFit: "contain",
    marginRight: 20
  },

  buildText: {
    // marginTop: 17,
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Poppins",
    paddingLeft: 20,
    "@media (max-width: 768px)": {
      paddingLeft: 10,
      fontSize: 15,
      whiteSpace: "nowrap",
      marginTop: "20px"
    }
  },
  buildTextSetting: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Poppins",
    paddingLeft: 20,
    "@media (max-width: 768px)": {
      paddingLeft: 10,
      fontSize: 15,
      whiteSpace: "nowrap",
      marginTop: "20px"
    }
  },
  connectTextSetting: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Poppins",
    paddingLeft: 20,
    "@media (max-width: 768px)": {
      paddingLeft: 10,
      fontSize: 13,
      whiteSpace: "nowrap",
      marginTop: "20px"
    }
  },
  buildText2: {
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Poppins",
    paddingLeft: 30,
    "@media (max-width: 768px)": {
      paddingLeft: 30,
      fontSize: 15,
      whiteSpace: "nowrap"
    }
  },
  innerTitleBlock: {
    display: "flex",
    cursor: "pointer",
    width: "fit-content",
    "@media (max-width: 768px)": {
      paddingTop: 20
    }
  }
};

export default withTranslation()(
  withStyles(profileHeaderStyle)(withRouter(ProfileHeader))
);
