//@ts-ignore
//@ts-nocheck
import React from 'react';
import { withRouter } from 'react-router';
import { Grid, Box, Button, Typography } from "@material-ui/core";
import {
  TextInput
} from "react-native";
import { withStyles } from "@material-ui/core/styles";
import NewHeader from "./NewHeader";
// import Footer from "../../../components/src/Footer.web";
import { withTranslation } from 'react-i18next';
import LandingPage2Controller, { Props } from './LandingPage2Controller';
import Footer from "../../../components/src/Footer.web";
import {
  bannerImg,
  profileImg,
  joinLogoImg,
  leftArrowImage,
  contactBg,
  ablertKing,
  bruno,
  chirstian,
  dc,
  genie,
  jie,
  joao,
  julius,
  nattapon,
  syaza,
  letterPdf
} from "./assets";

// { img: profileImg, name: "Vinh Nguyen Hoang", place: "Vietnam" },
class NewLandingPage2 extends LandingPage2Controller {

  render() {
    const { classes, t, i18n } = this.props
    const userData = [
      { img: profileImg, name: "Rai Cockfield", place: `${this.props.t("CEO / Co-Founder")}` },
      { img: chirstian, name: "Christian Borneman", place: `${t("Latin America")}` },
      { img: jie, name: "Jie Ming Tay", place: `${t("HQ - SG")}` }, { img: syaza, name: "Syaza Qistina Tan", place: `${t("Singapore")}` },
      { img: ablertKing, name: "Albert Kim", place: `${t("Korea")}` }, { img: genie, name: "Genie Kim", place: `${t("Korea")}` }, { img: joao, name: "João Pott", place: `${t("Brazil")}` },
      { img: bruno, name: "Bruno Lopes", place: `${t("Brazil")}` }, { img: nattapon, name: "Nattapon Ruetthapai", place: `${t("Thailand")}` },
      { img: julius, name: "Julius Mariano", place: `${t("Philippines")}` },
      { img: dc, name: "DC Dominguez", place: `${t("Philippines")}` }
    ]
    return (
      <div className={classes.mainWrapper}>
        <div className={classes.mainContanier}>
          <NewHeader />
          {/* contentHeader */}
          <Box className={classes.contentHeader}>
            <h1 className={classes.title}>{t("Our mission")}</h1>
            <p className={classes.lead} style={{
              paddingBottom: "20px",
            }}>
              {t("yup.gg’s mission is to")}<span className={classes.textColor}>{t("aggregate and organise")}</span>{t("all the")}<span className={classes.textColor}>{t("opportunities")}</span>{t("in the")}<span className={classes.textColor}>{t("gaming &")}
              {t("esports")}</span> {t("industry in one place")}
              {t("making information universally accessible and business easily actionable")}
            </p>
          </Box>
          {/* ends: contentHeader */}
        </div>
        {/* main content */}
        <Box className={classes.contentWrapper}>
          <Box className={classes.sevreBlock}>
            <Typography style={{ fontFamily: "Poppins", fontSize: 20, fontWeight: "bold", color: "#333" }}>{t("Who we serve")}</Typography>
            <Typography style={{ fontFamily: "Poppins", color: "#4d4c4d", fontSize: 14, paddingTop: 5 }}>
              {t("Find the category that best describes you to kickstart your adventure into yup")}.
            </Typography>
            <div style={{ paddingTop: "3%", display: "flex" }}>
              <Typography style={{ fontFamily: "Poppins", fontSize: 17, fontWeight: "bold", color: "#333",whiteSpace:"nowrap"}}>{t("Individuals")}</Typography>
              <p style={{ borderTop: "2px solid rgb(77 76 77 / 66%)", width: "100%", margin: "10px 30px", color: "white" }}>a</p>
            </div>
          </Box>

          <Box style={{ padding: "2% 10%" }}>
            <Grid container>
              <Grid xs={12} md={6}>
                <p style={{ color: "#3d3d3c", fontSize: 17, }}>+</p>
                <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#333" }}>{t("Creators & Influencers")}</Typography>
                <p className={classes.gridText}>
                  {t("Display your work and skills")}<br />
                  {t("Find people to collaborate and work with")}<br />
                  {t("Expand your business network")}<br />
                  {t("Find more opportunities (work & money)")}<br />
                </p>
              </Grid>
              <Grid xs={12} md={6} className={classes.secondBlock}>
                <p style={{ color: "#3d3d3c", fontSize: 17, }}>+</p>
                <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#333" }}>{t("Artists Specialists")} <br />{t("and Skilled Professionals")}</Typography>
                <p className={classes.gridText}>
                  {t("Display your work and skills")}<br />
                  {t("Be more discoverable within the gaming industry")}<br />
                  {t("Find projects that fit your skillset")}<br />
                </p>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "4%" }}>
              <Grid xs={12} md={6}>
                <p style={{ color: "#3d3d3c", fontSize: 17, }}>+</p>
                <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#333" }}>{t("Business Professionals")}</Typography>
                <p className={classes.gridText}>
                  • {t("Grow your network")} <br />
                  • {t("Find jobs available in the gaming industry")}<br />
                  • {t("Help educate and support the gaming creative industry")}
                 
                  {/* • {t("Help educate and support the gaming creative industry")} <br />{t("industry")} */}
                </p>
              </Grid>
              <Grid xs={12} md={6} className={classes.secondBlock}>
                <p style={{ color: "#3d3d3c", fontSize: 17, }}>+</p>
                <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#333" }}>{t("Hard Core Gamers")}</Typography>
                <p className={classes.gridText}>
                  • {t("Grow your rewards and influence")}<br />
                  • {t("Display your skills")} <br />
                  • {t("Get more games news and access direct from")} <br />{t("the companies you love")}
                </p>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: "4%" }}>
              <Grid xs={12} md={6}>
                <p style={{ color: "#3d3d3c", fontSize: 17, }}>+</p>
                <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#333" }}>{t("Esports Professionals")}</Typography>
                <p className={classes.gridText}>
                  • {t("Grow your network")} <br />
                  • {t("Find jobs available in the gaming industry")}<br />
                  • {t("Help educate and support the gaming creative industry")}

                  {/* • {t("Help educate and support the gaming creative")} <br />{t("industry")} */}
                </p>
              </Grid>
              <Grid xs={12} md={6} className={classes.secondBlock}>
                <p style={{ color: "#3d3d3c", fontSize: 17, }}>+</p>
                <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#333" }}>{t("Casual Gamers")}</Typography>
                <p className={classes.gridText}>
                  • {t("Grow your network")} <br />
                  • {t("Learn more about the gaming industry")}<br />
                  • {t("Upgrade your gaming experience through our")}<br />{t("marketplace and rewards (coming soon)")}
                </p>
              </Grid>
            </Grid>
          </Box>
          {/* start Organizations area */}
          <div style={{ borderRadius: 18, background: "#171717", marginTop: 15 }}>
            <div style={{ paddingTop: "5%", display: "flex", marginLeft: "10%" }}>
              <Typography style={{ fontFamily: "Poppins", fontSize: 17, fontWeight: "bold", color: "#fff" ,whiteSpace:"nowrap" }}>{t("Organizations")}</Typography>
              <p style={{ borderTop: "2px solid rgb(111 111 111)", width: "100%", margin: "10px 30px", color: "black" }}>{t("a")}</p>
            </div>

            <Box style={{ padding: "2% 10%" }}>
              <Grid container>
                <Grid xs={12} md={6}>
                  <p style={{ color: "#fff", fontSize: 17, }}>+</p>
                  <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#fff" }}>{t("Enterprise Clients")}</Typography>
                  <p className={classes.gridText2}>
                    • {t("Manage your gaming profile/messaging")}<br />
                    • {t("Learn about and launch better gaming activations")}<br />
                    • {t("Manage and measure your activations better")}<br />
                    • {t("Bring your entire service network onto one platform")}
                  </p>
                </Grid>
                <Grid xs={12} md={6} className={classes.secondBlock} >
                  <p style={{ color: "#fff", fontSize: 17, }}>+</p>
                  <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#fff" }}>{t("SMB & Growing Businesses")}</Typography>
                  <p className={classes.gridText2}>
                    • {t("Learn how to reach the gaming community")}<br />
                    • {t("Launch tailored campaigns targeting gamers")} <br />{t("in your market more effectively")}<br />
                    • {t("Build a community of gamers around your brand")}<br />
                  </p>
                </Grid>
              </Grid> 

              <Grid container style={{ marginTop: "4%" }}>
                <Grid xs={12} md={6}>
                  <p style={{ color: "#fff", fontSize: 17, }}>+</p>
                  <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#fff" }}>{t("Agencies (Media & Creative)")}</Typography>
                  <p className={classes.gridText2}>
                    • {t("Manage your network of clients more easily")}<br />
                    • {t("Learn how to reach and monetise the gaming")} <br />{t("industry better")}<br />
                    • {t("Execute a more diverse and effective set of")} <br />{t("campaigns to reach the gaming audience")}<br />
                  </p>
                </Grid>
                <Grid xs={12} md={6} className={classes.secondBlock}>
                  <p style={{ color: "#fff", fontSize: 17, }}>+</p>
                  <Typography style={{ fontFamily: "Poppins", fontSize: 15, fontWeight: "bold", color: "#fff" }}>{t("Agencies (Talent)")}</Typography>
                  <p className={classes.gridText2}>
                    • {t("Monetise your business and talents")}<br />
                    • {t("Manage your talent in one place with better tools and information")}<br />
                    • {t("Recruit new talents to your organization with more information to guide you in the process")}
                  </p>
                </Grid>
              </Grid>
            </Box>
          </div>
          {/* end Organizations area */}

          {/* team section */}
          <Box style={{ marginTop: "6%", marginLeft: "10%" }}>
            <Typography style={{ fontFamily: "Poppins", fontSize: 20, fontWeight: "bold", color: "#333" }}>{t("Our team and commitments")}</Typography>
            <Typography className={classes.ourTeam} style={{ fontFamily: "Poppins", color: "#4d4c4d", fontSize: 15, paddingTop: 5, width: "60%" }}>
              {t("yup.gg is committed to growing a diverse workforce that enables us to understand and address the")}
              {t("opportunities and challenges inherent to operating in the gaming & esports economy")}
             {t("Our goal is bring in the best people to serve the global gaming community")}
            </Typography>
          </Box>
          <Box style={{ marginTop: "5%", borderLeft: "3px solid #f81700", marginLeft: "8%" }}>
            <Grid container style={{ marginLeft: "4%", width: "auto" }}>
              <Grid xs={12} md={6}>
                <p onClick={() => this.props.history.push("/ceoLetter")}
                  style={{ fontSize: 13, fontFamily: "Poppins", color: "#808080", fontWeight: 600, textDecoration: "none", cursor: "pointer" }}>
                  {t("2021 Letter from the CEO / Co-Founder")}</p>
                <p className={classes.weAim} style={{ fontSize: 40, fontFamily: "Poppins", color: "#333333", width: "65%" }}>{t("We aim to improve the lives of gamers globally")}</p>
                <p style={{ fontSize: 19, fontFamily: "Poppins", color: "#f81700", fontWeight: 400, paddingTop: 40 }}>— {t("Raiford C Cockfield III")}</p>
              </Grid>
              <Grid xs={12} md={6} className={classes.secondBlock}>
                <img src={profileImg} className={classes.porfileImg} alt="" />
              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginTop: "2%" }}>
            <Grid container style={{ textAlign: "center", padding: "0 85px" }}>
              {userData.map((item) => {
                return (
                  <Grid xs={12} md={3} style={{ marginTop: "4%" }}>
                    <img src={item.img} className={classes.subPorfileImg} alt="" />
                    <div style={{ paddingTop: 10 }}>
                      <p style={{ color: "#4d4c4d", fontFamily: "Poppins", fontSize: 13, fontWeight: "bold" }}>{item.name}</p>
                      <p style={{ color: "#808080", fontFamily: "Poppins", fontSize: 14 }}>{item.place}</p>
                    </div>
                  </Grid>
                )
              })}

            </Grid>
          </Box>
          {/* ends: team section */}

          {/* join team section */}
          <Box className={classes.joinTeamCard}>
            <Grid container>
              <Grid xs={12} md={6}>
                <img src={joinLogoImg} className={classes.joinLogoImg} alt="" />
                <p className={classes.joinTeam}style={{ fontSize: 40, fontFamily: "Poppins", color: "#fff", width: "65%", lineHeight: "1.19", paddingTop: "30px" }}>
                  {t("Join our")}<br></br>
                  {t("team today")}</p>
              </Grid>
              <Grid xs={12} md={6}>
                <Typography style={{ fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#fff" }}>{t("Be a part of the gaming revolution")}
                </Typography>
                <Typography className={classes.findJobs} style={{ fontFamily: "Poppins", fontWeight: "200", color: "#e2e2e2", fontSize: 14, padding: "20px 0", width: "45%" }}>
                  {t("To find jobs at yup.gg simply create your profile and follow ours to get updates on all the contract")}
                  {t("part-time and full-time work available at yup.gg")}
                </Typography>
                <Button style={{ color: "#2f2f2f", backgroundColor: "#fff", fontWeight: "200", fontSize: 16, padding: "10px 20px" }}
                  onClick={() => { this.props.history.push('/claim-username') }}
                >
                 {t("Apply now")} <img src={leftArrowImage} className={classes.btnIcon} alt="" />
                </Button>

              </Grid>
            </Grid>
          </Box>
          {/* ends: join team section */}

          {/* join network section */}
          <Box className={classes.networkCard}>
            <Typography className={classes.networkTitle}>{t("Join the network")}</Typography>
            <Grid container spacing={3}>
              <Grid xs={12} md={4} className={classes.px}>
                <Box className={classes.joinCard}>
                  <span className={classes.joinTitle}>{t("BRANDS")}</span>
                  <h5 className={classes.joinh5}>
                    {t("Opportunities")}
                  </h5>
                  <p className={classes.joinLead}>
                    {t("Have access to a feed of paid campaigns partnerships and discounts to help you maximize your potential impact or earnings")}
                  </p>
                </Box>
              </Grid>
              <Grid xs={12} md={4} className={classes.px}>
                <Box className={classes.joinCard}>
                  <span className={classes.joinTitle}>{t("LEARN")}</span>
                  <h5 className={classes.joinh5}>
                    {t("Insights")}
                  </h5>
                  <p className={classes.joinLead}>
                    {t("Insights is where a Gamer can get industry updates key insights or results from the top esports leagues and tournaments globally")}
                  </p>
                </Box>
              </Grid>
              <Grid xs={12} md={4} className={classes.px}>
                <Box className={classes.joinCard}>
                  <span className={classes.joinTitle}>{t("ECONOMY")}</span>
                  <h5 className={classes.joinh5}>
                    {t("Marketplace")}
                  </h5>
                  <p className={classes.joinLead}>
                    {t("Marketplace is where a Gamer can shop for all of their merch and product needs from top brands and teams from around the world")}
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* ends: join network section */}


          {/* join network section */}
          <Box className={classes.contactCard}>
            {/* <Typography className={classes.networkTitle}>Join the network.</Typography> */}
            <Grid container className={classes.contactCardBg}>
              <Grid xs={12} md={6} style={{ padding: "5%" }}>
                <h4 className={classes.joinh4}>
                 {t("Sign up for updates")}
                </h4>
                <p className={classes.joinLead}>
                 {t("Get the latest from the yup ecosystem and engineering updates straight to your inbox")}
                </p>
                <Grid xs={12} md={8} className={classes.contactInputRow}>
                  <TextInput
                    placeholder={t("Your Email")} className={classes.contactInput} />
                  <Button className={classes.signupBtn}>
                    {t("sign up")}
                  </Button>
                </Grid>
                <span className={classes.contactSpan}>{t("You can unsubscribe at any time")}</span>

              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* ends: main content */}
        <Footer />
      </div >
    )
  }
}

const style = {
  contentHeader: {
    paddingLeft: 100,
    "@media (min-device-width: 216px) and (max-width: 576px)": {
      paddingLeft: "10%",
      marginTop: "-14px",
    },
    "@media (min-device-width: 600px) and (max-width: 830px)": {
      paddingLeft: "10%",
      marginTop: "30px",
    },
  },
  secondBlock: {
    paddingLeft: 30,
    "@media (max-width: 576px)": {
      paddingLeft: 0,
    },
    "@media (max-width: 830px)": {
      paddingLeft: 0,
    },
  },
  sevreBlock: {
    // marginTop: "24%",
    marginLeft: "10%",
    "@media (min-device-width: 216px) and (max-width: 576px)": {
      marginTop: "5%",
    },
    // "@media (max-width: 767px)": {
    //   marginTop: "5%",
    // },
    "@media (min-device-width: 600px) and (max-width: 830px)": {
      marginTop: "30px",
    },
  },
  body: {
    overflowX: "hidden",
  },
  html: {
    overflowX: "hidden",
  },
  mainWrapper:
  {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowX: "hidden",
  },
  mainContanier: {
    // background: 'url(' + bannerImg + ')',
    // backgroundImage: bannerImg,
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "100%",
    // height: "inherit",

    background: 'url(' + bannerImg + ')',
    width: "100%",
    height: "100%",
    minHeight: "80vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    // "@media (max-width: 576px)": {
    //   minHeight: "80vh",
    //   backgroundPosition: "right top",
    // },
    "@media only (max-width: 1024px) ": {
      minHeight: "60vh",
    },
    "@media (max-width: 768px)": {
      minHeight: "45vh",
      backgroundPosition: "right top",
    },
    "@media (max-width: 768px) and (orientation: landscape) ": {
      minHeight: "80vh",
      backgroundPosition: "right top",
    },
   
    
  },
  pageFooter: {
    backgroundColor: "#151931 !important",
  },
  contentWrapper: {
    paddingTop: "50px",
    // maxWidth: "1000px",
    width: "100%",
    "@media (max-width: 576px)": {
      paddingTop: 0,
    },
    "@media (max-width: 830px)": {
      paddingTop: 0,
    },
  },
  title: {
    color: "#fff",
    fontWeight: "normal",
    fontFamily: "Poppins",
    fontSize: 80,
    marginTop: 40,
    "@media (min-device-width: 216px) and (max-width: 576px)": {
      fontSize: "23px",
      marginTop: 0,
    },
    "@media (min-device-width: 600px) and (max-width: 830px)": {
      fontSize: "30px",
      marginTop: 0,
    },
  },
  lead: {
    color: "#d6d6d6",
    fontWeight: "normal",
    fontFamily: "Poppins",
    margin: "30px 0",
    width: 760,
    fontSize: 21,
    
    "@media (max-width:830px)": {
      fontSize: 13,
      width: "350px",
      paddingBottom: "20px",
    },
    "@media (max-width:576px)": {
      width: "300px",
    },
    // "@media (min-device-width: 216px) and (max-width: 576px)": {
    //   width: "210px",
    //   margin: 0
    // },
    // "@media (min-device-width: 600px) and (max-width: 830px)": {
    //   width: "350px",
    //   margin: 0
    // },
  },
  gridText: {
    color: "#3d3d3c",
    fontFamily: "Poppins",
    fontSize: 13,
    paddingTop: 8,
  },
  gridText2: {
    color: "#e2e2e2",
    fontFamily: "Poppins",
    fontSize: 13,
    paddingTop: 8,
  },
  porfileImg: {
    width: "360px",
    // height: "450px",
    objectFit: "contain",
    "@media (max-width: 576px)": {
      width: "100%",
    },
    "@media (max-width: 830px)": {
      width: "300px",
    },
  },
  subPorfileImg: {
    width: "120px",
    height: "120px",
    objectFit: "cover",
    borderRadius: 15,
  },
  joinTeamCard: {
    margin: "6% 0",
    paddingLeft: "10%",
    backgroundColor: "#171717",
    padding: "30px",
  },
  joinLogoImg: {
    height: "76px",
    width: "76px",
    objectFit: "contain"
  },
  btnIcon: {
    paddingLeft: "15px",
  },
  networkTitle: {
    color: "#333",
    fontFamily: "Poppins",
    fontSize: "40px",
    padding: "0 0 20px 0",
    textAlign: "center",
    "@media (max-width: 767px)": {
      fontSize:"30px !important",
    }  
  },
  networkCard: {
    margin: "6% 0",
    padding: "0 10%",
    // "@media (max-width: 576px)": {
    //   marginTop: "-30px"
    // },
    // "@media (max-width: 830px)": {
    //   marginLeft: "10px",
    //   marginRight: "10px"
    // },
  },
  px: {
    padding: "0 10px",
  },
  joinCard: {
    padding: "20px",
    backgroundColor: "#171717",
    borderRadius: "18px",
    height: 250,
    marginTop: "5%",
  },
  joinTitle:
  {
    color: "#808080",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Manrope",
    lineHeight: "2.43",
  },
  joinLead:
  {
    fontSize: 13,
    fontWeight: "200",
    fontFamily: "Poppins",
    color: "#fff",
    lineHeight: "1.19",
    paddingTop: "10px"
  },
  joinh5:
  {
    color: "#e2e2e2",
    fontSize: "25px",
    fontWeight: "600",
    fontFamily: "Manrope",
    lineHeight: "1.9",
    margin: "25px 0 5px 0",
  },
  contactCard:
  {
    // padding: "20px"

  },
  contactCardBg:
  {
    background: 'url(' + contactBg + ')',
    // backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    // padding: "0 10%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    minHeight: "550px",
    width: "100%",
  },
  joinh4:
  {
    color: "#fff",
    fontSize: "32px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "2.38",
  },
  contactSpan:
  {
    color: "#7d8194",
    fontSize: "13px",
    fontWeight: "normal",
    fontFamily: "Manrope",
  },
  contactInput:
  {
    backgroundColor: "transparent",
    border: "1px solid #fff",
    borderRadius: "16px",
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "12px",
    fontWeight: "normal",
    fontFamily: "Poppins",
    lineHeight: "1.33",
    margin: "30px 0 20px 0",
    padding: "15px",
    width: "100%",

  },
  contactInputRow:
  {
    position: "relative",
  },
  signupBtn:
  {
    opacity: "0.6",
    backgroundColor: "#f14e24",
    border: "1px solid transparent",
    borderRadius: "16px",
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins",
    lineHeight: "1.33",
    padding: "5px 15px",
    position: "absolute",
    right: "10px",
    top: "40%",
    '&:hover': {
      //   backgroundImage: 'none',
      backgroundColor: "#f81700",
      border: "1px solid #fff",
      color: "#fff",
    },
  },
  textColor:{
   color : "#EFCC8A"
  },
  ourTeam:{
    "@media (max-width: 768px)": {
      width:"90% !important",
    }
  },
  weAim:{
    "@media (max-width: 768px)": {
      fontSize:"28px !important",
      width:"90% !important",
    }
  },
  joinTeam:{
    "@media (max-width: 768px)": {
      width:"100% !important",
    }
  },
  findJobs:{
    "@media (max-width: 768px)": {
      width:"100% !important",
    }
  },
}

export default withTranslation()(withStyles(style)(withRouter(NewLandingPage2)))
