//@ts-nocheck
//@ts-ignore
import {
  Avatar, Box, Button,
  Dialog, Grid, IconButton, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add, Close } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation } from 'react-i18next';
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import {
  jpgIcon, pdfIcon, pngIcon, steptwo
} from "./assets";
import PortfolioController, { Props } from "./PortfolioController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";


class PortfolioMedia extends PortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, navigation, t, i18n } = this.props;

    const formDetails = JSON.parse(localStorage.getItem("portfolioDetails"));

    const exisitngMedia = JSON.parse(localStorage.getItem("portfolioMedia"));

    if (!formDetails) {
      this.props.history.replace("/portfolio");
      return null;
    }

    return (
      <>
        <ProfileHeader
          heading={t("Project Files")}
          subModule={true}
          showProfile={true}
          multiPage
          backLocation="/portfolio/create"
        />

        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Formik
            initialValues={
              exisitngMedia || {
                media: [],
                deletedMedia: []
              }
            }
            validationSchema={this.MediaPortfolioSchema(t)}
            validateOnMount={true}
            onSubmit={(values) => {
              localStorage.setItem("portfolioMedia", JSON.stringify(values));
              this.props.history.push("/portfolio/preview");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              setFieldError,
              setFieldTouched
            }) => (
              <Grid
                sm={12}
                md={6}
                style={{
                  border: "1px solid #DEDDDA",
                  borderTop: "none",
                  width: "100%",
                  height: values.media.length > 3 ? "100%" : "100%"
                }}
              >
                <Box className={classes.progressBarRow}>
                  <Typography
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 13,
                      marginRight: 14
                    }}
                  >
                    {t("Step")} 2 of 3
                  </Typography>
                  <img src={steptwo} style={{ height: 12, width: 185 }} />
                </Box>
                <Form
                  translate="yes"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 52,
                    paddingRight: 41,
                    paddingTop: 40
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    mt="40px"
                    mb="40px"
                  >
                    <Box
                      style={{
                        border: "1px solid #DBDBDB",
                        height: 244,
                        width: "100%",
                        outline: "none",
                        borderRadius: 16,
                        resize: "none",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <input
                        multiple
                        type="file"
                        onChange={(e: any) =>
                          this.handleSelectMedia(
                            e,
                            values.media,
                            setFieldValue,
                            setFieldError,
                            setFieldTouched
                          )
                        }
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          zIndex: 2,
                          cursor: "pointer",
                          opacity: 0
                        }}
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                      />
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography
                          style={{
                            color: "rgba(0, 0, 0, 0.38)",
                            fontFamily: "Poppins",
                            fontWeight: 700,
                            fontSize: 22
                          }}
                        >
                          {t("Drop your files here")}
                        </Typography>
                        <Add
                          style={{
                            height: 24,
                            width: 24,
                            color: "rgba(0, 0, 0, 0.38)",
                            marginTop: 20
                          }}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Typography
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 12,
                          marginTop: "8px"
                        }}
                      >
                        {t("Compatible files")}:{" "}
                        <Typography
                          style={{
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 700,
                            fontSize: 12,
                            display: "inline"
                          }}
                        >
                          {t("PDF, PNG, JPEG")}
                        </Typography>
                      </Typography>
                    </Box>
                    {errors.media && touched.media ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.media}
                      </Typography>
                    ) : null}
                    {values.media.length > 0 && (
                      <Box display="flex" flexDirection="column" mt="40px">
                        <Typography
                          style={{
                            color: "#191919",
                            fontFamily: "Poppins",
                            fontWeight: 700,
                            fontSize: 16,
                            marginBottom: 25
                          }}
                        >
                          {t("You uploaded")}
                        </Typography>
                        <Box display="flex" flexWrap="wrap">
                          {values.media.map((media, index) => (
                            <Box
                              key={index}
                              display="flex"
                              flexDirection="column"
                              style={{
                                backgroundColor: "#eff0f6",
                                minHeight: 140,
                                width: 168,
                                borderRadius: 16,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 10,
                                marginRight: 10,
                                marginBottom: 10,
                                position: "relative"
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  this.removeMediaHandler(
                                    index,
                                    values.media,
                                    values.deletedMedia,
                                    setFieldValue
                                  )
                                }
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                  padding: 0
                                }}
                              >
                                <Close style={{ height: 18, width: 18 }} />
                              </IconButton>

                              <Box style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: '100%',
                                cursor: 'pointer'
                              }}
                                onClick={() => this.setState({ selectedMedia: media })} >
                                {media?.file?.type?.split("/")[1] === "pdf" && (
                                  <img
                                    src={pdfIcon}
                                    style={{ height: 50, width: 50 }}
                                  />
                                )}

                                {media?.file?.type?.split("/")[1] === "png" && (
                                  <img
                                    src={pngIcon}
                                    style={{ height: 50, width: 50 }}
                                  />
                                )}

                                {(media?.file?.type?.split("/")[1] === "jpg" ||
                                  media?.file?.type?.split("/")[1] === "jpeg") && (
                                    <img
                                      src={jpgIcon}
                                      style={{ height: 50, width: 50 }}
                                    />
                                  )}

                                <Typography
                                  style={{
                                    color: "#191919",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: 12,
                                    marginTop: 15,
                                    textAlign: "center",
                                    wordBreak: "break-all"
                                  }}
                                >
                                  {media?.file?.name || media}
                                </Typography>
                              </Box>


                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}

                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{
                          backgroundColor: "#F14E24",
                          border: "1px solid #D9DBE9",
                          borderRadius: 16,
                          height: 34,
                          marginBottom: 14,
                          boxShadow: "none",
                          color: "#F7F7FC",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14,
                          marginTop: 30
                        }}
                      >
                        {t("Next")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Grid>
            )}
          </Formik>
        </Grid>

        <Dialog
          open={this.state.selectedMedia}
          onClose={() => this.setState({ selectedMedia: null })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >

          {this.state.selectedMedia?.file?.type?.split("/")[1] !== "pdf" ? (
            <Avatar src={this.state.selectedMedia?.url} style={{ width: '100%', height: 'auto', borderRadius: 0 }} />
          ) : (
            <iframe src={this.state.selectedMedia?.url} style={{ width: '600px', height: '600px' }} />
          )}


        </Dialog>
      </>
    );
  }

  async componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    // this.getValidations();
    // this.requestSessionData();
  }
}
// Customizable Area Start
export const portfolioMediaStyle = {
  progressBarRow: {
    marginRight: "30px", 
    marginTop: "-39px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
   
    "@media (max-width: 768px)": {
      marginRight: "0", 
      marginTop: "-32px",
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      marginTop: "15px",
    },
   
  },
};

// Customizable Area End
export default withTranslation()(
  withStyles(portfolioMediaStyle)(
    withRouter(PortfolioMedia as React.ComponentType<any>)
  )
);