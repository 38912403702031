//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Button, Dialog,
  DialogActions,
  DialogTitle, Grid, IconButton, Popover, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { MoreHoriz } from "@material-ui/icons";
import { differenceInMonths, format } from "date-fns";
import React from "react";
import { withTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import {
  verticalDots
} from "./assets";
import JobHistoryController, { Props } from "./JobHistoryController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class JobHistoryList extends JobHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getFullMonthName = (date) => {
    const { t } = this.props;
    return `${t(format(new Date(date), "MMM"))}${t(
      format(new Date(date), ". yyyy")
    )}`;
  };
  // Customizable Area End

  render() {
    const { navigation } = this.props;
    const { jobs } = this.state;
    const { classes, t, i18n } = this.props;

    return (
      <>
        <ProfileHeader heading={t("Job History")} showProfile />
        <Grid className={classes.mainWrapper}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid xs={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Box
                  onClick={() => {
                    localStorage.removeItem("jobDetails");
                    this.props.history.push("/job-history/create");
                  }}
                  style={{
                    padding: 10,
                    cursor: "pointer",
                    backgroundImage:
                      "linear-gradient(to right, rgb(241, 78, 36), rgb(195, 0, 82))",
                    minHeight: 127,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 43,
                    marginBottom: 20,
                    backgroundColor: "#FAFAFA",
                    boxShadow:
                      "0px 1px 1px rgba(0, 0, 0, 0.141176), 0px 2px 1px -1px rgba(0, 0, 0, 0.121569), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                    borderRadius: 16
                  }}
                >
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      fontSize: 22
                    }}
                  >
                    {t("Add New Experience")}
                  </Typography>
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: 12,
                      marginTop: 13
                    }}
                  >
                    {t(
                      "Your job history should show off your experiences to attract more clients"
                    )}
                  </Typography>
                </Box>
                <Box className={classes.JobHistoryCard}>
                  {jobs.map((job) => (
                    <Box
                      key={job.id}
                      display="flex"
                      style={{
                        height: "auto",
                        marginBottom: 7,
                        position: "relative"
                      }}
                    >
                      <Box
                        style={{
                          position: "absolute",
                          top: 1,
                          right: 1,
                          zIndex: 10
                        }}
                      >
                        <IconButton
                          style={{ backgroundColor: "transparent" }}
                          onClick={(e) => this.handlePopoverOpen(e, job.id)}
                        >
                          <MoreHoriz
                            style={{ width: 24, height: 24, color: "black" }}
                          />
                        </IconButton>

                        <Popover
                          open={this.state.openedPopoverId === job.id}
                          anchorEl={this.state.anchorEl}
                          onClose={this.handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                          }}
                        >
                          <Box display="flex" flexDirection="column">
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "jobDetails",
                                  JSON.stringify({
                                    id: job.id,
                                    companyName: job.attributes.company_name,
                                    role: job.attributes.your_role,
                                    country: JSON.stringify({
                                      label: job.attributes.country
                                    }),
                                    city: job.attributes.city,
                                    description: job.attributes.discription,
                                    isCurrentlyWorking:
                                      job.attributes
                                        .i_currently_work_in_this_company,
                                    startMonth: JSON.stringify(
                                      this.Months[
                                        new Date(
                                          job.attributes.start_date
                                        ).getMonth()
                                      ]
                                    ),
                                    startYear: new Date(
                                      job.attributes.start_date
                                    )
                                      .getFullYear()
                                      .toString(),
                                    endMonth: JSON.stringify(
                                      this.Months[
                                        job.attributes
                                          .i_currently_work_in_this_company
                                          ? new Date().getMonth()
                                          : new Date(
                                              job.attributes.end_date
                                            ).getMonth()
                                      ]
                                    ),
                                    endYear: job.attributes
                                      .i_currently_work_in_this_company
                                      ? new Date().getFullYear().toString()
                                      : new Date(job.attributes.end_date)
                                          .getFullYear()
                                          .toString(),
                                    logo: "",
                                    logoUrl: job.attributes.company_logo
                                  })
                                );
                                this.props.history.push("/job-history/create");
                              }}
                              variant="text"
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 12,
                                textAlign: "left",
                                justifyContent: "flex-start"
                              }}
                            >
                              {t("Edit")}
                            </Button>
                            <Button
                              onClick={() => {
                                this.setState({
                                  showDialog: true,
                                  jobId: job.id
                                });
                              }}
                              variant="text"
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 12,
                                textAlign: "left",
                                justifyContent: "flex-start"
                              }}
                            >
                              {t("Delete")}
                            </Button>
                          </Box>
                        </Popover>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: 105,
                          width: 34,
                          backgroundColor: "#EFF0F6",
                          borderRadius: "16px 0px 0px 16px"
                        }}
                      >
                        <img
                          src={verticalDots}
                          style={{ width: 24, height: 24 }}
                        />
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          minHeight: 105,
                          width: "100%",
                          backgroundColor: "#F7F7FC",
                          borderRadius: "0px 16px 16px 0px",
                          padding: "22px 20px"
                        }}
                      >
                        <Avatar
                          src={job.attributes.company_logo}
                          style={{ width: 48, height: 48, marginRight: 35 }}
                        />
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            style={{
                              color: "#081F32",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 18
                            }}
                          >
                            {`${job.attributes.your_role}, ${
                              job.attributes.country
                            }`}
                          </Typography>
                          <Typography
                            style={{
                              color: "#6E798C",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 10,
                              marginTop: 4,
                              marginBottom: 2
                            }}
                          >
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 10
                              }}
                              component="span"
                            >
                              {job.attributes.company_name}
                            </Typography>{" "}
                            ·{" "}
                            {`${job.attributes.city}, ${
                              job.attributes.country
                            }`}
                          </Typography>
                          <Typography
                            style={{
                              color: "#6E798C",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: 18
                            }}
                          >
                            {/* {format(
                              new Date(job.attributes.start_date),
                              "MMM. yyyy"
                            )} */}
                            {this.getFullMonthName(job.attributes.start_date)} —{" "}
                            {job.attributes.i_currently_work_in_this_company
                              ? `${t("Present")}`
                              : this.getFullMonthName(
                                  job.attributes.end_date
                                )
                            // format(
                            //     new Date(job.attributes.end_date),
                            //     "MMM. yyyy"
                            // )
                            }{" "}
                            ·{" "}
                            {differenceInMonths(
                              job.attributes.i_currently_work_in_this_company
                                ? new Date()
                                : new Date(job.attributes.end_date),
                              new Date(job.attributes.start_date)
                            )}{" "}
                            {t("months")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid sm={12} md={4} />
          </Grid>
        </Grid>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        <Loader loading={this.state.loading} />

        <CatchInvalidToken
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
        />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`${t("Are you sure you want to delete this job?")}`}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => this.setState({ showDialog: false, jobId: null })}
              variant="text"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => this.deleteJob(this.state.jobId)}
              variant="text"
              autoFocus
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  async componentDidMount() {
    this.getAllJobs();
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
const JobHistoryListStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    // paddingRight: 10,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
      // backgroundColor: "white",
      // zIndex: 2
    }
  },
  mainWrapper: {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    }
  },
  mainBodyContent: {
    height: "100%",
    marginTop: 30,
    border: "#80808042 1px solid",
    padding: 42,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    "@media (max-width: 768px)": {
      padding: 10
    }
  },
  JobHistoryCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    // width: "80%",
    "@media (max-width: 767px)": {
      width: "100%"
    }
  }
};
// Customizable Area End

export default withTranslation()(
  withStyles(JobHistoryListStyle)(
    withRouter(JobHistoryList as React.ComponentType<any>)
  )
);
