//@ts-ignore
//@ts-nocheck
import React from "react";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { withRouter } from "react-router";
import classNames from "classnames";

import appLogo from '../assets/yup-logo.png';
import { TopNavBarStyle } from './TopNavBarStyle';
import Header from "./Header";
class TopNav extends React.Component {
  render() {
    const { classes } = this.props
    
  return (
    <Header
      absolute
      color="transparent"
      image={appLogo}
      links={
        <div className={classes.collapse}>
          {/* <List className={classes.list + " " + classes.mlAuto}>
            <ListItem className={classes.listItem}>
              <Button
                className={classes.navLink}
                onClick={e => e.preventDefault()}
                color="transparent"
              >
                Teams
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                href="#pablo"
                className={classes.navLink}
                onClick={e => e.preventDefault()}
                color="transparent"
              >
                Nations
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                href="#pablo"
                className={classes.navLink}
                onClick={e => e.preventDefault()}
                color="transparent"
              >
                Rewards
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                href="#pablo"
                className={classes.navLink}
                onClick={e => e.preventDefault()}
                color="transparent"
              >
                Company
              </Button>
            </ListItem>
          </List> */}
          <List className={classes.list + " " + classes.mlAuto}>
            <ListItem className={classes.listItem}>
              <Button
                color="white"
                backgroundColor="black"
                target="_blank"
                className={classes.navLink + " " + classes.button}
                onClick={() => {this.props.history.push("/login")}}
              >
                Sign in
              </Button>
            </ListItem>
          </List>
        </div>
      }
    />
  );
  }
};

export default withStyles(TopNavBarStyle)(withRouter(TopNav))
