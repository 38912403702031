import _defineProperty from "/builds/clientprojects/assembler_projects/prd/yuppgg-68443-react-native/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _yupImg, _yupImg2, _NewLandingPageStyle;

// import { workSpaceImage } from "../src/assets";
import { backgroundImage } from "../src/assets";
import { followBgImg } from "../src/assets";
export var NewLandingPageStyle = (_NewLandingPageStyle = {
  landingMainWrapper: {
    overflowX: "hidden"
  },
  mainContanier: {
    // background: 'url(' + backgroundImage + ')',
    // backgroundImage: backgroundImage,
    // backgroundRepeatX: "repeat",
    // backgroundRepeatY: "no-repeat",
    // height: "100%",
    // backgroundRepeat: "round",
    // backgroundSize: "100%",
    background: 'url(' + backgroundImage + ')',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    "@media (max-width: 1400px)": {
      // padding: "0 20px",
      backgroundPosition: "left top"
    }
  },
  contentP: {
    "@media (max-width: 1400px)": {
      padding: "0 20px"
    }
  },
  followBgImage: {
    background: 'url(' + followBgImg + ')',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "left top",
    paddingTop: "300px",
    "@media (max-width: 767px)": {
      paddingTop: "0"
    }
  },
  backgroundImage: {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100vh",
    zIndex: "-1",
    "@media (max-width: 767px)": {
      position: "unset"
    } // top: 0;
    // left: 0;
    // position: absolute;
    // z-index: -1;
    // width: 100%;
    // background-size: contain;
    // height: 100vh;

  },
  followBgCircleImg: {
    position: "absolute",
    left: "0",
    width: "100%",
    zIndex: "-1",
    objectPosition: "bottom",
    objectFit: "cover"
  },
  followBgImg: {
    // background: 'url(' + followBgImg + ')',
    // backgroundSize: "cover",
    // backgroundImage: followBgImg,
    // backgroundRepeatX: "no-repeat",
    // height: "100%",
    // backgroundRepeat: "round",
    // backgroundSize: "100%",
    position: "absolute",
    left: "0",
    // height: "100%",
    width: "100%",
    zIndex: "-1",
    objectPosition: "bottom",
    objectFit: "cover" // left: 40%;
    // top: 0;
    // /* transform: translate(-50%, 0); */
    // width: 500px;
    // height: 500px;
    // position: absolute;
    // background: url(/static/media/follow_bg_circle.9d2b60e0.png);
    // background-size: cover;
    // z-index: -1;

  },
  socialRightBlock: {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width: 767px)": {
      justifyContent: "center"
    }
  },
  socialCol: {
    zIndex: "1",
    position: "relative"
  },
  mainWrapper: {
    backgroundColor: "#000",
    width: "100%",
    margin: "0 auto",
    zIndex: "1",
    position: "relative",
    backgroundRepeat: "round",
    backgroundSize: "100%"
  },
  contentWrapper: {
    maxWidth: "1200px",
    width: "100%",
    margin: "0 auto"
  },
  subContentWrapper: {
    maxWidth: "1200px",
    width: "100%",
    margin: "0 auto",
    padding: "200px 0 200px 0",
    // "@media (max-width: 1400px)": {
    //   padding: "0 20px",
    // },
    "@media (max-width: 767px)": {
      padding: "0"
    }
  },
  upcomingWrapper: {
    maxWidth: "1200px",
    width: "100%",
    margin: "0 auto",
    padding: "50px 0 50px 0",
    "@media (max-width: 767px)": {
      padding: "0"
    }
  },
  headerCotenblock: {
    paddingBottom: "600px",
    "@media (max-width: 1400px)": {
      paddingBottom: "200px"
    },
    "@media (max-width: 767px)": {
      paddingBottom: "0"
    }
  },
  socialmain: {
    position: "relative"
  },
  socialRow: {
    position: "absolute",
    top: "400px",
    "@media (max-width: 1400px)": {
      top: "100px"
    },
    "@media (max-width: 767px)": {
      position: "unset"
    }
  },
  contentHeader: {
    paddingTop: "200px",
    "@media (max-width: 1400px)": {
      paddingTop: "100px"
    },
    "@media (max-width: 767px)": {
      paddingTop: "0"
    }
  },
  contentHeaderP: {
    paddingTop: "50px"
  },
  title: {
    color: "#fff",
    fontWeight: "normal",
    fontFamily: "Poppins",
    fontSize: "85px",
    "@media (max-width: 1400px)": {
      fontSize: "40px"
    },
    "@media (max-width: 767px)": {
      fontSize: "30px"
    }
  },
  titleH3: {
    fontSize: "64px",
    "@media (max-width: 1400px)": {
      fontSize: "32px"
    },
    "@media (max-width: 767px)": {
      fontSize: "28px"
    }
  },
  featureTitle: {
    textAlign: "center",
    width: "100%",
    marginBottom: "50px",
    marginTop: "0",
    "@media (max-width: 767px)": {
      margin: 0
    }
  },
  phoneImgrow: {
    display: "flex",
    "@media (max-width: 767px)": {
      justifyContent: "center"
    }
  },
  phoneImgDiv: {
    "@media (max-width: 767px)": {
      maxWidth: "150px"
    }
  },
  phoneImgSpacing: {
    paddingRight: "10px"
  },
  featureLeftCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "455px !important"
  },
  featureCard: {
    padding: "15px",
    backgroundColor: "#171717",
    borderRadius: "18px",
    minHeight: "200px",
    position: "relative"
  },
  featureRightBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "realative"
  },
  publisherCard: {
    minHeight: "195px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  topBlock: {
    position: "relative",
    margin: "30px 0",
    minHeight: "120px"
  },
  landingDiamondImg: {
    position: "absolute",
    top: "0",
    left: "-22px",
    width: "100px",
    height: "100px",
    objectFit: "contain"
  },
  landingShapedimaImg: {
    position: "absolute",
    top: "20px",
    right: "0",
    // transform: "translate(-50%, -50%)",
    width: "180px",
    height: "70px",
    objectFit: "contain",
    objectPosition: "left",
    "@media (max-width: 767px)": {
      width: "120px"
    }
  },
  landingPublishersImg: {
    position: "absolute",
    top: "-15px",
    right: "-44px",
    // transform: "translate(-50%, -50%)",
    width: "200px",
    height: "200px",
    objectFit: "contain",
    objectPosition: "left",
    "@media (max-width: 767px)": {
      right: "0",
      width: "100px",
      height: "100px"
    }
  },
  landingFeatureh5: {
    lineHeight: "1.39",
    color: "#fff",
    fontWeight: "300",
    fontSize: "18px",
    fontFamily: "Poppins"
  },
  // landingPublisherh5: {
  //   margin: "40px 0"
  // },
  lead: {
    color: "#d6d6d6",
    fontSize: "21px",
    fontWeight: "200",
    fontFamily: "Poppins",
    margin: "20px 0",
    "@media (max-width: 1400px)": {
      fontSize: "14px"
    },
    "@media (max-width: 767px)": {
      fontSize: "14px"
    }
  },
  featureLead: {
    color: "#e2e2e2",
    fontSize: "13px",
    fontWeight: "200",
    fontFamily: "Poppins",
    margin: "20px 0 60px 0"
  },
  colPt: {
    paddingTop: "15px"
  },
  colPr: {
    paddingRight: "15px"
  },
  featureP: {
    margin: "70px 0 40px 0"
  },
  landingJoinBtn: {
    fontSize: "21px",
    backgroundColor: "#f81700;",
    border: "1px solid transparent",
    borderRadius: "10px",
    padding: "15px 25px",
    color: "#fff",
    '&:hover': {
      //   backgroundImage: 'none',
      backgroundColor: "#f81700",
      color: "#fff",
      border: "1px solid transparent"
    },
    "@media (max-width: 1400px)": {
      fontSize: "16px"
    },
    "@media (max-width: 767px)": {
      padding: "10px",
      fontSize: "15px"
    }
  },
  socialSection: {
    padding: "20px 0 50px 0",
    "@media (max-width: 767px)": {
      padding: "20px 0 0 0"
    }
  },
  socialSectiontitle: {
    color: "#808080",
    fontSize: "21px",
    fontWeight: "600",
    fontFamily: "Manrope",
    "@media (max-width: 767px)": {
      fontSize: "17px"
    }
  },
  featureRow: {
    padding: "20px"
  },
  featureSectiontitle: {
    color: "#878787"
  },
  followLeftSection: {
    "@media (max-width: 767px)": {
      display: "flex",
      justifyContent: "center"
    }
  },
  followRightSection: {
    textAlign: "right",
    position: "relative",
    "@media (max-width: 767px)": {
      padding: "20px"
    }
  },
  phoneImg: {
    width: "200px",
    height: "457px",
    objectFit: "contain",
    "@media (max-width: 1400px)": {
      width: "100%",
      height: "320px"
    },
    "@media (max-width: 767px)": {
      width: "100%",
      height: "300px"
    }
  },
  headerlogo: {
    width: "68px",
    height: "20px",
    objectFit: "contain",
    backgroundColor: "red",
    padding: "20px"
  },
  workSpaceText: {
    fontSize: "85px",
    paddingLeft: '35px',
    fontFamily: "Rubik-bold",
    margin: 0
  },
  workSpaceText1: {
    fontSize: "85px",
    paddingLeft: '35px',
    fontFamily: "Rubik-bold",
    color: "#ff6300b3",
    margin: "0px",
    marginTop: "-30px"
  },
  connectText: {
    fontFamily: 'Manrope-bold',
    fontSize: "16px",
    paddingLeft: '35px'
  },
  connectSecText: {
    fontFamily: 'Manrope-bold',
    fontSize: "15px",
    color: "#4e4d4d",
    paddingTop: '7%'
  }
}, _defineProperty(_NewLandingPageStyle, "connectSecText", {
  fontFamily: 'Manrope-bold',
  fontSize: "20px",
  color: "#4e4d4d",
  paddingTop: '7%'
}), _defineProperty(_NewLandingPageStyle, "landingBtn", {
  fontSize: 20,
  padding: "8px 35px",
  backgroundColor: '#F14E24',
  '&:hover': {
    //   backgroundImage: 'none',
    backgroundColor: '#F14E24'
  }
}), _defineProperty(_NewLandingPageStyle, "landingLastBtn", {
  fontSize: 15,
  padding: "8px 50px",
  backgroundColor: 'black',
  marginTop: "5%",
  '&:hover': {
    //   backgroundImage: 'none',
    backgroundColor: 'black'
  }
}), _defineProperty(_NewLandingPageStyle, "landingSecBtn", {
  fontSize: 15,
  padding: "10px 55px",
  border: "1px solid #0000002e",
  color: "#F14E24",
  marginTop: "7%",
  '&:hover': {
    backgroundColor: 'transparent',
    border: "1px solid #0000002e"
  }
}), _defineProperty(_NewLandingPageStyle, "yupImg", (_yupImg = {
  // position: "relative",
  // top: "-10rem",
  // // width: "inherit",
  width: "100%",
  top: "67rem"
}, _defineProperty(_yupImg, "width", "100%"), _defineProperty(_yupImg, "background", "#e1e2e270"), _yupImg)), _defineProperty(_NewLandingPageStyle, "startNowBody", {
  width: "auto",
  height: "434px",
  background: "#FF6300"
}), _defineProperty(_NewLandingPageStyle, "lastText", {
  fontSize: "40px",
  color: "black",
  fontFamily: 'Rubik-bold'
}), _defineProperty(_NewLandingPageStyle, "secondBlock", {
  height: "auto !important"
}), _defineProperty(_NewLandingPageStyle, "fbBtn", {
  borderRadius: 0,
  background: "transparent",
  border: "none",
  padding: "0 24px",
  fontSize: 20,
  marginTop: "5%",
  '&:hover': {
    backgroundColor: 'transparent',
    border: "1px solid black"
  }
}), _defineProperty(_NewLandingPageStyle, "yupProfile", {
  background: "#e1e2e270",
  height: "823px !important",
  "@media (max-width: 767px)": {
    height: "12rem !important"
  },
  "@media (max-width: 768px)": {
    height: "12rem !important"
  }
}), _defineProperty(_NewLandingPageStyle, "yupImg", (_yupImg2 = {
  // position: "relative",
  // top: "-10rem",
  // // width: "inherit",
  width: "100%",
  top: "67rem"
}, _defineProperty(_yupImg2, "width", "100%"), _defineProperty(_yupImg2, "background", "#e1e2e270"), _yupImg2)), _defineProperty(_NewLandingPageStyle, "startNowBody", {
  width: "auto",
  height: "434px",
  background: "#FF6300"
}), _defineProperty(_NewLandingPageStyle, "lastText", {
  fontSize: "54px",
  color: "black",
  fontFamily: 'Rubik-bold'
}), _defineProperty(_NewLandingPageStyle, "secondBlock", {
  height: "auto !important"
}), _defineProperty(_NewLandingPageStyle, "fbBtn", {
  borderRadius: 0,
  background: "transparent",
  border: "none",
  padding: "0 24px",
  fontSize: 20
}), _NewLandingPageStyle);