//@ts-ignore
//@ts-nocheck
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import AmbassadorsController, { Props } from "./AmbassadorsController";
import { illustration } from "./assets";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class AmbassadorsInviteSent extends AmbassadorsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
  // Customizable Area End

  render() {
    const { classes,t, i18n } = this.props;

    return (
      <>
        <ProfileHeader
          heading={t("Brand Ambassadors")}
          subModule={1}
          showProfile={true}
        />
        <Grid
          sm={12}
          container={true}
          style={{ backgroundColor: "#FFFFFF" }}
          className={classes.talentBlk}
        >
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>

          <Grid
            sm={12}
            md={6}
            style={{
              border: "1px solid #DEDDDA",
              borderTop: "none"
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              style={{
                marginTop: 70,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
                width: "100%"
              }}
            >
              <img src={illustration} style={{ height: 280, width: 280 }} />
              <Box display="flex" width="45%" mt="10px">
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 14,
                    color: "#4E4B66",
                    textAlign: "center"
                  }}
                >
                  {t("Your invite was sent! Once the Creator has confirm they are a part of your talent team, you will see their profile added to Brand Ambassadors!")}
                </Typography>
              </Box>
              <Button
                onClick={() => (window.location = "/ambassadors" as any)}
                variant="contained"
                type="button"
                style={{
                  backgroundColor: "#F14E24",
                  border: "1px solid #D9DBE9",
                  borderRadius: 16,
                  height: 40,
                  width: 222,
                  marginBottom: 14,
                  boxShadow: "none",
                  color: "#F7F7FC",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: 14,
                  marginTop: 30
                }}
              >
                {t("Finish")}
              </Button>
            </Box>
          </Grid>

          <Grid sm={12} md={4} className={classes.profileBlk} />
          <Snackbar
            open={Boolean(this.state.error)}
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
            severity="error"
          />
          <Loader loading={this.state.loading} />
        </Grid>
      </>
    );
  }
}
// Customizable Area Start
const ambassadorsInviteSentStyles = {
  profileBlk: {
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  imgLogo: {
    "@media (max-width: 768px)": {
      display: "none !important"
    }
  },
  talentBlk: {
    "@media (max-width: 768px)": {
      display: "block !important"
    }
  }
};

export const AmbassadorsInviteSentStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
    }
  }
};
// Customizable Area End
export default withTranslation()(withStyles(ambassadorsInviteSentStyles)(
  withRouter(AmbassadorsInviteSent as React.ComponentType<any>)
))
