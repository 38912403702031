//@ts-ignore
//@ts-nocheck
import { Box, Button, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import { CameraAltOutlined, Clear } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation } from 'react-i18next';
import { TiArrowSortedDown } from "react-icons/ti";
import Select, { components } from 'react-select';
import Loader from "../../../components/src/Loader.web";
import AboutController, { Props } from "./AboutController.web";
import { backImage, defaultImg } from "./assets";
import CropImage from "./CropImage.web";
import ProfileHeader from './ProfileHeader';
import SideMenu from "./SideMenu";

class EditHeader extends AboutController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getUserData()
    this.getBrands()
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    this.getCountryList()
  }

  componentDidUpdate = (prevPorps, prevState) => {
    if (this.state.email !== prevState.email) {
      this.getUserData()
    }
  }


  render() {
    const { classes, t , i18n } = this.props
    const fileInput = React.createRef();
    const fileInputCover = React.createRef();

    const accountTypes = this.state.brands.map((item) => ({
      value: item.attributes.id, label: item.attributes.name
    }))

    const handleClick = () => {
      fileInput.current.click();
    }
    const onClick = () => {
      fileInputCover.current.click();
    }
    const handleImageChange = (e, setFieldValue) => {
      e.preventDefault();
      const file = e.target.files[0];
      setFieldValue("profileUrl", file ? URL.createObjectURL(file) : "");
      setFieldValue("profileImg", file ? URL.createObjectURL(file) : "");
      this.setState({isProfileImage: true})
      e.target.value = ""
    };

    const handleCoverChange = (e, setFieldValue) => {
      e.preventDefault();
      const file = e.target.files[0];
      setFieldValue("cover_Url", file ? URL.createObjectURL(file) : "");
      setFieldValue("coverUrl", file ? URL.createObjectURL(file) : "");
      this.setState({ImgDialogOpen: true})
      e.target.value = ""
    };

    const removeCover = (setFieldValue) => {
      setFieldValue("cover_Url", "");
      setFieldValue("coverUrl", "");
    }

    const CaretDownIcon = () => {
      return <TiArrowSortedDown style={{ color: "rgb(128 128 128 / 68%)" }} />;
    };

    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon />
        </components.DropdownIndicator>
      );
    };

    return (
      <>
        <ProfileHeader heading={t("Header")} subModule="3" showProfile/>
        
        <Grid container className={classes.mainWrapper}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu subModule="3" showProfile />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.mainBodyContent}>
              {this.state.loading ?
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <CircularProgress style={{ color: "#F14E24" }} /> */}
                </Box>
                :
                <Formik
                  initialValues={{
                    email: this.state.userData?.email || "",
                    accountType: this.state.userData?.brand?.data?.attributes?.id || "",
                    country: this.state.userData?.profile_country_code || "",
                    profileUrl: this.state.userData?.profile_picture || "",
                    coverUrl: this.state.userData?.profile_cover || backImage || "",
                    profileImg: this.state.userData?.profile_picture || defaultImg || "",
                    cover_Url: this.state.userData?.profile_cover || "",
                    team: "",
                    countryName: "",
                    name: this.state.userData?.first_name || ""
                  }}
                  validationSchema={this.validationHeader(t)}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    this.updateHeader(values)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue
                  }) => {
                    const selectedCountry = this.getCountries().find(({ value }) => value !== "" && value == values.country)

                    return (
                      <Form
                        translate="yes"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className={classes.profilebox}>
                        { this.state.isProfileImage && <CropImage url={values.profileImg} 
                        open={this.state.isProfileImage} 
                        circle={true}
                        handleClose={() => {
                          setFieldValue("profileImg", '');
                          this.setState({ isProfileImage: false });
                        }}
                        width={150}
                        height={150}
                        getCropImageUrl={(url) => {
                            setFieldValue("profileUrl", url);
                            setFieldValue("profileImg", url);
                          this.setState({isProfileImage: false})}} /> }
                          <div className="fileinput text-center">
                            <input 
                            type="file"
                            accept="image/*"
                            onChange={(e: any) =>
                                  handleImageChange(
                                    e,
                                    setFieldValue
                                  )
                                } 
                             ref={fileInput} style={{ display: "contents" }} />
                          </div>
                          <img
                            className={classes.profileImg}
                            src={values.profileImg}
                          />
                          <Box style={{ display: "grid", paddingLeft: 30 }}>
                            <Typography style={{ fontSize: 20, fontFamily: "Poppins" }}>{values.name}</Typography>
                            <Typography style={{ fontSize: 14, fontFamily: "Poppins", color: "#F14E24", cursor: "pointer" }} onClick={handleClick}>{t("Change profile photo")}</Typography>
                          </Box>
                        </div>

                        <div style={{ marginTop: 50 }}>
                          <div style={{ paddingTop: 25 }}>
                            <Typography style={{ fontSize: 17, padding: "7px 0", fontFamily: "Poppins", color: "hsl(0deg 0% 50% / 53%)" }}>{t("Account type")}</Typography>
                            <Select
                              styles={selectStyles}
                              isSearchable={true}
                              components={{ DropdownIndicator }}
                              placeholder={t("Select Account Type")}
                              defaultValue={accountTypes.find(({ value }) => value == values.accountType)}
                              name="accountType"
                              onChange={
                                (option: any) => {
                                  setFieldValue('accountType', option.value)
                                  setFieldValue("team", option.label)
                                }
                              }
                              options={accountTypes}
                              theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: "hsl(0, 0%, 80%)",
                                }
                              })}
                            />
                            <div style={{ display: "flex", padding: "7px 0" }}>
                              <Typography style={{ fontSize: 14, fontFamily: "Poppins", color: "hsl(0deg 0% 50% / 53%)" }}>{t("Current category")}:</Typography>
                              <Typography style={{ fontSize: 13, fontFamily: 'Rubik-bold', color: "rgb(128 128 128 / 68%)", paddingLeft: 5 }}>{values.team}</Typography>
                            </div>
                            {errors.accountType && touched.accountType ? (
                              <Typography className={classes.errorText}>{errors.accountType}</Typography>
                            ) : null}
                          </div>

                          <div style={{ paddingTop: 25 }}>
                            <Typography style={{ fontSize: 17, padding: "7px 0", fontFamily: "Poppins", color: "hsl(0deg 0% 50% / 53%)" }}>{t("Home market")}</Typography>
                            <Select
                              styles={selectStyles}
                              isSearchable={true}
                              components={{ DropdownIndicator }}
                              placeholder={t("Select Home Market")}
                              defaultValue={selectedCountry}
                              name="country"
                              onChange={(option: any) => {
                                setFieldValue('country', option.value)
                                setFieldValue('countryName', option.label)
                              }
                              }
                              options={this.getCountries()}
                              theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: "hsl(0, 0%, 80%)",
                                }
                              })}
                            />
                            <div style={{ display: "flex", padding: "7px 0" }}>
                              <Typography style={{ fontSize: 14, fontFamily: "Poppins", color: "hsl(0deg 0% 50% / 53%)" }}>{t("Current market")}:</Typography>
                              <Typography style={{ fontSize: 13, fontFamily: 'Rubik-bold', color: "rgb(128 128 128 / 68%)", paddingLeft: 5 }}>{values.countryName
                              }</Typography>
                            </div>
                            {errors.country && touched.country ? (
                              <Typography className={classes.errorText}>{errors.country}</Typography>
                            ) : null}
                          </div>

                          <div style={{ paddingTop: 25 }}>
                            <Box display="flex" flexDirection="column" mt="16px">
                              <Typography style={{ fontSize: 17, padding: "7px 0", fontFamily: "Poppins", color: "hsl(0deg 0% 50% / 53%)" }}>{t("Profile cover")}</Typography>

                              <div className={classes.profileUrl}
                                style={{
                                  width: "100%",
                                  outline: "none",
                                  borderRadius: 30,
                                  resize: "none",
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: 170,
                                  opacity: 0.8,
                                  backgroundImage: `url(${values.coverUrl})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                  backgroundSize: "cover",
                                }}
                              >
                                <input type="file" accept="image/*" onChange={e => handleCoverChange(e, setFieldValue)} ref={fileInputCover} style={{ display: "contents" }} />
                                
                                { this.state.ImgDialogOpen && <CropImage url={values.coverUrl} 
                        open={this.state.ImgDialogOpen} 
                        handleClose={() => {
                          setFieldValue("cover_Url", null);
                          setFieldValue("coverUrl", null);
                          this.setState({ ImgDialogOpen: false });
                        }}
                        getCropImageUrl={(url) => {
                          setFieldValue("cover_Url", url);
                          setFieldValue("coverUrl", url);
                          this.setState({ImgDialogOpen:false})}} /> }
                                
                                <CameraAltOutlined
                                  style={{
                                    color: values.coverUrl ? "white" : "black",
                                    width: 24,
                                    height: "auto",
                                    marginRight: 50,
                                  }}
                                  onClick={onClick}
                                />
                                <Clear
                                  style={{
                                    color: "white",
                                    width: 26,
                                    height: "auto"
                                  }}
                                  onClick={() => removeCover(setFieldValue)}
                                />
                              </div>

                            </Box>
                            <div style={{ display: "flex", padding: "7px 0",alignItems:'baseline' }} className={classes.ideal}>
                              <Typography style={{ fontSize: 14, fontFamily: "Poppins", color: "hsl(0deg 0% 50% / 53%)" }}>{t("Ideal size")}:</Typography>
                              <Typography style={{ fontSize: 13, fontFamily: 'Rubik-bold', color: "rgb(128 128 128 / 68%)", paddingLeft: 5 }}>1128x191px</Typography>
                            </div>
                          </div>
                          <Box display="flex" justifyContent="flex-end" >
                            <Button
                              variant="contained"
                              type="submit"
                              style={{
                                backgroundColor: "#F14E24",
                                border: "1px solid #D9DBE9",
                                borderRadius: 16,
                                height: 34,
                                marginBottom: 14,
                                boxShadow: "none",
                                color: "#F7F7FC",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: 15,
                                marginTop: 30,
                                padding: "18px 30px",
                              }}
                            >
                              {t("Save")}
                            </Button>
                          </Box>

                        </div>
                      </Form>

                    )
                  }}
                </Formik>
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
          <Loader loading={this.state.loading} />
        </Grid>
      </>
    )
  }
}

const EditHeaderStyle = {
  mainWrapper:
  {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
  },
  mainBodyContent:
  {
    height: "100vh",
    border: "#80808042 1px solid",
    padding: 40,
    marginTop: 50,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10,
      marginTop:30,
    },
  },
  profilebox: {
    fontSize: 18,
    display: "flex",
    position: "absolute",
  },
  profileImg: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
  backImage: {
    background: 'url(' + backImage + ')',
    backgroundRepeatX: "no-repeat",
    backgroundRepeat: "round",
    backgroundSize: "100%",
    height: 170,
    opacity: 0.8,
    textAlign: "center",
    borderRadius: 30,
  },
  ideal:{
    "@media (max-width: 768px)": {
      padding:"30px 0px !important",
    }
  },
  profileUrl:{
    "@media (width: 320px)": {
      height:"112px !important",
    }
  },
  profileUrl:{
    "@media (width: 768px)": {
      height:"272px !important",
    }
  },
  errorText: {
    color: "#F14E24",
    fontFamily: "Poppins",
    fontWeight: 300,
    fontSize: 14,
    marginTop: 5
  }
}

const selectStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 56,
      position: "aboslute",
      borderRadius: 20,
      '&:hover': {
        borderColor: "hsl(0, 0%, 80%)",
      }
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none",
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset"
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200
    }
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 17,
      fontFamily: "Poppins",
      color: "rgb(128 128 128 / 68%)",
    }
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      position: "absolute",
      marginTop: 9,
      fontSize: 22,
      color: "rgb(128 128 128 / 68%)",
    }
  },
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      paddingLeft: 40
    }
  },
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      color: "rgb(128 128 128 / 68%)",
    }
  }
}

export default withTranslation()(withStyles(EditHeaderStyle)(EditHeader))
