//@ts-nocheck
import {
  Avatar, Box, Button,
  Checkbox, Grid, MenuItem,
  Select, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { CheckBox, KeyboardArrowDown } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import { checkboxIcon } from "./assets";
import CropImage from "./CropImage.web";
import JobHistoryController, { Props } from "./JobHistoryController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class JobHistoryCreate extends JobHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation } = this.props;
    const Years = this.getYears();
    const { classes, t, i18n } = this.props;
    const formDetails = JSON.parse(localStorage.getItem("jobDetails"));
    return (
      <>
        <ProfileHeader
          heading={t("Job History")}
          subModule={true}
          showProfile={true}
          multiPage
          backLocation="/job-history"
        />
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Formik
            initialValues={
              formDetails || {
                companyName: "",
                role: "",
                country: "",
                city: "",
                description: "",
                isCurrentlyWorking: true,
                startMonth: "",
                startYear: "",
                endMonth: JSON.stringify({
                  label: "Jan",
                  value: "01"
                }),
                endYear: new Date().getFullYear().toString(),
                logo: "",
                logoUrl: ""
              }
            }
            validationSchema={this.CreateJobSchema(t)}
            validateOnMount={true}
            onSubmit={(values) => {
              this.handleCreateUpdateJob(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldError,
              setFieldTouched
            }) => {
              console.log(errors, touched);
              return (
                <Grid
                  sm={12}
                  md={6}
                  style={{
                    border: "1px solid #DEDDDA",
                    borderTop: "none",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <Form
                    translate="yes"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      padding: 42
                    }}
                  >
                    {this.state.isLogoImage && (
                      <CropImage
                        handleClose={() => {
                          setFieldValue("logo", null);
                          setFieldValue("logoUrl", null);
                          this.setState({ isLogoImage: false });
                        }}
                        circle={true}
                        width={150}
                        height={150}
                        url={values.logoUrl}
                        open={this.state.isLogoImage}
                        getCropImageUrl={(url) => {
                          setFieldValue("logo", url);
                          setFieldValue("logoUrl", url);
                          this.setState({ isLogoImage: false });
                        }}
                      />
                    )}
                    <Box display="flex">
                      <Avatar
                        src={values.logoUrl || "/"}
                        style={{ width: 38, height: 38, marginRight: 22 }}
                      />
                      <Box
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 19,
                            marginLeft: 8
                          }}
                        >
                          {values.companyName || `${t("Company name")}`}
                        </Typography>
                        <Box display="flex">
                          <Button
                            variant="text"
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: 14
                            }}
                          >
                            <input
                              type="file"
                              onChange={(e: any) => {
                                this.handleSelectImage(
                                  e,
                                  setFieldValue,
                                  setFieldError
                                );
                              }}
                              style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                zIndex: 100,
                                cursor: "pointer !important",
                                opacity: 0
                              }}
                              accept="image/png, image/jpeg, image/jpg"
                            />
                            {t("Upload photo")}
                          </Button>
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" mt="37px">
                      <Typography
                        style={{
                          color: "rgb(38 38 38 / 60%)",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 16,
                          marginBottom: 17
                        }}
                      >
                        {t("Company name")}
                      </Typography>
                      <Field
                        maxLength={100}
                        name="companyName"
                        placeholder="i.e. yup.gg"
                        style={{
                          border: "1px solid #DBDBDB",
                          height: 45,
                          width: "100%",
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 16,
                          paddingRight: 10,
                          outline: "none",
                          borderRadius: 16
                        }}
                      />
                      {errors.companyName && touched.companyName ? (
                        <Typography
                          style={{
                            color: "#F14E24",
                            fontFamily: "Poppins",
                            fontWeight: 300,
                            fontSize: 14,
                            marginTop: 5
                          }}
                        >
                          {errors.companyName}
                        </Typography>
                      ) : null}
                    </Box>

                    <Box display="flex" flexDirection="column" mt="30px">
                      <Typography
                        style={{
                          color: "rgb(38 38 38 / 60%)",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 16,
                          marginBottom: 17
                        }}
                      >
                        {t("Your role")}
                      </Typography>
                      <Field
                        maxLength={100}
                        name="role"
                        placeholder={t("Brand ambassador")}
                        style={{
                          border: "1px solid #DBDBDB",
                          height: 45,
                          width: "100%",
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 16,
                          paddingRight: 10,
                          outline: "none",
                          borderRadius: 16
                        }}
                      />
                      {errors.role && touched.role ? (
                        <Typography
                          style={{
                            color: "#F14E24",
                            fontFamily: "Poppins",
                            fontWeight: 300,
                            fontSize: 14,
                            marginTop: 5
                          }}
                        >
                          {errors.role}
                        </Typography>
                      ) : null}
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      mt="30px"
                    >
                      <Box display="flex" flexDirection="column" width="49%">
                        <Typography
                          style={{
                            color: "rgb(38 38 38 / 60%)",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 16,
                            marginBottom: 17
                          }}
                        >
                          {t("Country")}
                        </Typography>
                        <Select
                          disableUnderline={true}
                          classes={{
                            select: this.props.classes.dropdown
                          }}
                          IconComponent={() => (
                            <KeyboardArrowDown
                              style={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                height: 30,
                                width: 30,
                                color: "#8E8E8E",
                                opacity: isWidthUp("sm", this.props.width)
                                  ? 1
                                  : 0
                              }}
                            />
                          )}
                          renderValue={(value) =>
                            value ? value : `${t("select country")}`
                          }
                          displayEmpty={true}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            values.country
                              ? JSON.parse(values.country).label
                              : ""
                          }
                          onChange={handleChange("country")}
                          onBlur={() => {
                            setFieldValue("city", "");
                          }}
                        >
                          {this.getCountries().map((country, index) => (
                            <MenuItem
                              key={index}
                              style={{
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 14
                              }}
                              value={JSON.stringify(country)}
                            >
                              {country.label}
                            </MenuItem>
                          ))}
                        </Select>

                        {errors.country && touched.country ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 5
                            }}
                          >
                            {errors.country}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box display="flex" flexDirection="column" width="49%">
                        <Typography
                          style={{
                            color: "rgb(38 38 38 / 60%)",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 16,
                            marginBottom: 17
                          }}
                        >
                          {t("City")}
                        </Typography>

                        <Field
                          maxLength={100}
                          name="city"
                          placeholder={t("City name")}
                          style={{
                            border: "1px solid #DBDBDB",
                            height: 45,
                            width: "100%",
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14,
                            paddingLeft: 16,
                            paddingRight: 10,
                            outline: "none",
                            borderRadius: 16
                          }}
                        />
                        {errors.city && touched.city ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 5
                            }}
                          >
                            {errors.city}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" mt="38px">
                      <Typography
                        style={{
                          color: "#191919",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 16,
                          marginBottom: 17
                        }}
                      >
                        {t("Add description")}
                      </Typography>

                      <textarea
                        maxLength={2000}
                        value={values.description}
                        name="description"
                        onChange={handleChange("description")}
                        placeholder={t("Tell a bit more about your experience")}
                        style={{
                          border: "1px solid #DBDBDB",
                          height: 119,
                          width: "100%",
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 14,
                          paddingLeft: 16,
                          paddingRight: 10,
                          paddingTop: 18,
                          outline: "none",
                          borderRadius: 16,
                          resize: "none"
                        }}
                      />
                      {errors.description && touched.description ? (
                        <Typography
                          style={{
                            color: "#F14E24",
                            fontFamily: "Poppins",
                            fontWeight: 300,
                            fontSize: 14,
                            marginTop: 5
                          }}
                        >
                          {errors.description}
                        </Typography>
                      ) : null}
                    </Box>

                    <Box display="flex" mt="30px" alignItems="center">
                      <Checkbox
                        checked={values.isCurrentlyWorking}
                        onChange={handleChange("isCurrentlyWorking")}
                        style={{ padding: 0, marginRight: 8, marginTop: 0 }}
                        checkedIcon={
                          <CheckBox
                            style={{
                              color: "#F14E24",
                              width: 16,
                              height: 16,
                              padding: 0,
                              margin: 0
                            }}
                          />
                        }
                        icon={
                          <img
                            src={checkboxIcon}
                            style={{ width: 16, height: 16 }}
                          />
                        }
                      />
                      <Typography
                        style={{
                          color: "#191919",
                          fontFamily: "Poppins",
                          fontWeight: "normal",
                          fontSize: 14
                        }}
                      >
                        {t("I currently work in this company")}
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      mt="30px"
                    >
                      <Box display="flex" flexDirection="column" width="49%">
                        <Typography
                          style={{
                            color: "rgb(38 38 38 / 60%)",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 16,
                            marginBottom: 17
                          }}
                        >
                          {t("Start date")}
                        </Typography>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Select
                            style={{ width: "49%" }}
                            disableUnderline={true}
                            classes={{
                              select: this.props.classes.dropdown
                            }}
                            IconComponent={() => (
                              <KeyboardArrowDown
                                style={{
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                  height: 30,
                                  width: 30,
                                  color: "#8E8E8E",
                                  opacity: isWidthUp("sm", this.props.width)
                                    ? 1
                                    : 0
                                }}
                              />
                            )}
                            renderValue={(value) =>
                              value ? value : `${t("Month")}`
                            }
                            displayEmpty={true}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              values.startMonth
                                ? `${t(JSON.parse(values.startMonth).label)}`
                                : ""
                            }
                            onChange={handleChange("startMonth")}
                            onBlur={() => {
                              const {
                                startMonth,
                                startYear,
                                endMonth,
                                endYear
                              } = values;

                              if (
                                startMonth &&
                                startYear &&
                                endMonth &&
                                endYear
                              ) {
                                const startDate = new Date(
                                  `${startYear}-${
                                    JSON.parse(startMonth).value
                                  }-01`
                                );

                                const endDate = new Date(
                                  `${endYear}-${JSON.parse(endMonth).value}-01`
                                );

                                if (startDate.getTime() > endDate.getTime()) {
                                  setFieldError(
                                    "endYear",
                                    "End date should be greater than start date."
                                  );
                                  setFieldTouched("endYear", true, false);
                                }
                              }
                            }}
                          >
                            {this.Months.map((month, index) => (
                              <MenuItem
                                key={index}
                                style={{
                                  color: "#8E8E8E",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: 14
                                }}
                                value={JSON.stringify(month)}
                              >
                                {t(month.label)}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            style={{ width: "49%" }}
                            disableUnderline={true}
                            classes={{
                              select: this.props.classes.dropdown
                            }}
                            IconComponent={() => (
                              <KeyboardArrowDown
                                style={{
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                  height: 30,
                                  width: 30,
                                  color: "#8E8E8E",
                                  opacity: isWidthUp("sm", this.props.width)
                                    ? 1
                                    : 0
                                }}
                              />
                            )}
                            renderValue={(value) =>
                              value ? value : `${t("Year")}`
                            }
                            displayEmpty={true}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.startYear}
                            onChange={handleChange("startYear")}
                            onBlur={() => {
                              const {
                                startMonth,
                                startYear,
                                endMonth,
                                endYear
                              } = values;

                              if (
                                startMonth &&
                                startYear &&
                                endMonth &&
                                endYear
                              ) {
                                const startDate = new Date(
                                  `${startYear}-${
                                    JSON.parse(startMonth).value
                                  }-01`
                                );

                                const endDate = new Date(
                                  `${endYear}-${JSON.parse(endMonth).value}-01`
                                );

                                if (startDate.getTime() > endDate.getTime()) {
                                  setFieldError(
                                    "endYear",
                                    "End date should be greater than start date."
                                  );
                                  setFieldTouched("endYear", true, false);
                                }
                              }
                            }}
                          >
                            {Years.map((year, index) => (
                              <MenuItem
                                key={index}
                                style={{
                                  color: "#8E8E8E",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: 14
                                }}
                                value={year}
                              >
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>

                        {(errors.startMonth && touched.startMonth) ||
                        (errors.startYear && touched.startYear) ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 5
                            }}
                          >
                            {errors.startMonth || errors.startYear}
                          </Typography>
                        ) : null}
                      </Box>
                      {!values.isCurrentlyWorking && (
                        <Box display="flex" flexDirection="column" width="49%">
                          <Typography
                            style={{
                              color: "rgb(38 38 38 / 60%)",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: 16,
                              marginBottom: 17
                            }}
                          >
                            {t("End date")}
                          </Typography>

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Select
                              style={{ width: "49%" }}
                              disableUnderline={true}
                              classes={{
                                select: this.props.classes.dropdown
                              }}
                              IconComponent={() => (
                                <KeyboardArrowDown
                                  style={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    height: 30,
                                    width: 30,
                                    color: "#8E8E8E",
                                    opacity: isWidthUp("sm", this.props.width)
                                      ? 1
                                      : 0
                                  }}
                                />
                              )}
                              renderValue={(value) =>
                                value ? value : `${t("Month")}`
                              }
                              displayEmpty={true}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={
                                values.endMonth
                                  ? `${t(JSON.parse(values.endMonth).label)}`
                                  : ""
                              }
                              onChange={handleChange("endMonth")}
                              onBlur={() => {
                                const {
                                  startMonth,
                                  startYear,
                                  endMonth,
                                  endYear
                                } = values;

                                if (
                                  startMonth &&
                                  startYear &&
                                  endMonth &&
                                  endYear
                                ) {
                                  const startDate = new Date(
                                    `${startYear}-${
                                      JSON.parse(startMonth).value
                                    }-01`
                                  );

                                  const endDate = new Date(
                                    `${endYear}-${
                                      JSON.parse(endMonth).value
                                    }-01`
                                  );

                                  if (startDate.getTime() > endDate.getTime()) {
                                    setFieldError(
                                      "endYear",
                                      "End date should be greater than start date."
                                    );
                                    setFieldTouched("endYear", true, false);
                                  }
                                }
                              }}
                            >
                              {this.Months.map((month, index) => (
                                <MenuItem
                                  key={index}
                                  style={{
                                    color: "#8E8E8E",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: 14
                                  }}
                                  value={JSON.stringify(month)}
                                >
                                  {t(month.label)}
                                </MenuItem>
                              ))}
                            </Select>
                            <Select
                              style={{ width: "49%" }}
                              disableUnderline={true}
                              classes={{
                                select: this.props.classes.dropdown
                              }}
                              IconComponent={() => (
                                <KeyboardArrowDown
                                  style={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    height: 30,
                                    width: 30,
                                    color: "#8E8E8E",
                                    opacity: isWidthUp("sm", this.props.width)
                                      ? 1
                                      : 0
                                  }}
                                />
                              )}
                              renderValue={(value) =>
                                value ? value : `${t("Year")}`
                              }
                              displayEmpty={true}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={values.endYear}
                              onChange={handleChange("endYear")}
                              onBlur={() => {
                                const {
                                  startMonth,
                                  startYear,
                                  endMonth,
                                  endYear
                                } = values;

                                if (
                                  startMonth &&
                                  startYear &&
                                  endMonth &&
                                  endYear
                                ) {
                                  const startDate = new Date(
                                    `${startYear}-${
                                      JSON.parse(startMonth).value
                                    }-01`
                                  );

                                  const endDate = new Date(
                                    `${endYear}-${
                                      JSON.parse(endMonth).value
                                    }-01`
                                  );

                                  if (startDate.getTime() > endDate.getTime()) {
                                    setFieldError(
                                      "endYear",
                                      "End date should be greater than start date."
                                    );
                                    setFieldTouched("endYear", true, false);
                                  }
                                }
                              }}
                            >
                              {Years.map((year, index) => (
                                <MenuItem
                                  disabled={
                                    +year < +values.startYear ||
                                    (new Date().getMonth() + 1 <
                                      Number(
                                        JSON.parse(values.endMonth).value
                                      ) &&
                                      +year ===
                                        Number(new Date().getFullYear()))
                                  }
                                  key={index}
                                  style={{
                                    color: "#8E8E8E",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: 14
                                  }}
                                  value={year}
                                >
                                  {year}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          {(errors.endMonth && touched.endMonth) ||
                          (errors.endYear && touched.endYear) ? (
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5
                              }}
                            >
                              {errors.endMonth || errors.endYear}
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      mt="16px"
                      mb="40px"
                    >
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          type="submit"
                          style={{
                            backgroundColor: "#F14E24",
                            border: "1px solid #D9DBE9",
                            borderRadius: 16,
                            height: 34,
                            marginBottom: 14,
                            boxShadow: "none",
                            color: "#F7F7FC",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 14,
                            marginTop: 30
                          }}
                        >
                          {t("Submit")}
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                </Grid>
              );
            }}
          </Formik>

          <Grid sm={12} md={4}>
          </Grid>
          <Snackbar
            open={Boolean(this.state.error)}
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
            severity="error"
          />
          <Loader loading={this.state.loading} />
          <CatchInvalidToken
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
          />
        </Grid>
      </>
    );
  }

  async componentDidMount() {
    // this.getValidations();
    // this.requestSessionData();
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
    this.getCountryList();
  }
}
// Customizable Area Start
export const JobHistoryCreateStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    // paddingRight: 10,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
      // backgroundColor: "white",
      // zIndex: 2
    }
  }
};
// Customizable Area End

export default withTranslation()(
  withWidth()(
    withStyles(JobHistoryCreateStyle)(
      withRouter(JobHistoryCreate as React.ComponentType<any>)
    )
  )
);
