//@ts-ignore
//@ts-nocheck
import { Avatar, Box, Chip, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { differenceInMonths, format } from "date-fns";
import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import * as Assets from "./assets";
import {
  instagramIcon, MobileBackIcon, mobileBgUrl, productPlacement,
  productUnboxing, socialMediaCampaign, twitchIcon, twitterIcon, youtubeIcon
} from "./assets";


class MobilePreview extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  Currencies = [
    {
      label: "S$",
      value: "SGD"
    },
    {
      label: "$",
      value: "USD"
    },
    {
      label: "R$",
      value: "BRL"
    },
    {
      label: "¥",
      value: "CNY"
    },
    {
      label: "€",
      value: "EUR"
    },
    {
      label: "£",
      value: "GBP"
    },
    {
      label: "HK$",
      value: "HKD"
    },
    {
      label: "Rp",
      value: "IDR"
    },
    {
      label: "¥",
      value: "JPY"
    },
    {
      label: "₩",
      value: "KRW"
    },
    {
      label: "RM",
      value: "MYR"
    },
    {
      label: "₱",
      value: "PHP"
    },
    {
      label: "ر.س",
      value: "SAR"
    },
    {
      label: "฿",
      value: "THB"
    },
    {
      label: "NT$",
      value: "TWD"
    },
    {
      label: "₫",
      value: "VND"
    }
  ];

  Subscriptions = [
    {
      label: "At Completion",
      value: "at_completion"
    },
    {
      label: "Per Day",
      value: "per_day"
    },
    {
      label: "Per Week",
      value: "per_week"
    },
    {
      label: "Per Month",
      value: "per_month"
    },
    {
      label: "Per Quarter",
      value: "per_quarter"
    },
    {
      label: "Semi-Annually",
      value: "semi_annually"
    },
    {
      label: "Per Year",
      value: "per_year"
    }
  ];

  getEmbededUrl(url) {
    let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    let matches = url.match(p);
    let embededUrl = "https://www.youtube.com/embed/";
    if (matches) {
      return embededUrl + matches[1];
    }
    return embededUrl;
  }

  getCategoryName = (category) => {
    let title = "N/A";
    switch (category) {
      case "social_media_campaign":
        title = "Social Media";
        break;
      case "product_placement":
        title = "Product Placement";
        break;
      case "product_un_boxing":
        title = "Product Un-Boxing";
        break;

      default:
        break;
    }

    return title;
  };

  getCategoryImage = (category) => {
    let image = socialMediaCampaign;
    switch (category) {
      case "social_media_campaign":
        image = socialMediaCampaign;
        break;
      case "product_placement":
        image = productPlacement;
        break;
      case "product_un_boxing":
        image = productUnboxing;
        break;

      default:
        break;
    }

    return image;
  };
  
  getFullMonthName = (date) => {
    const { t } = this.props;
    return `${t(format(
      new Date(date),
      "MMM"
    ))}${t(format(
      new Date(date),
      ". yyyy"
    ))}`
  }

  Listings = (userDetails) => {
      const subscriptions = {};
      const currencies = {};
      const {t} = this.props;

      for (let i = 0; i < this.Subscriptions.length; i++) {
        subscriptions[this.Subscriptions[i].value] = this.Subscriptions[
          i
        ].label;
      }

      for (let i = 0; i < this.Currencies.length; i++) {
        currencies[this.Currencies[i].value] = this.Currencies[i].label;
      }

      return (
        <>
          <Box pl={3} pr={3} style={{}}>
            <Grid container>
              <Grid md={12} xs={12}>
                <Typography
                  style={{
                    color: "#353535",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 500,
                    marginBottom: 30
                  }}
                >
                  {this.props.t("Listings")}
                </Typography>
              </Grid>
              <Grid md={12} xs={12}>
                {/* list1 */}
                {userDetails.listings.data.length > 0 ? (
                  userDetails.listings.data.map((listing) => (
                    <Box
                      key={listing.id}
                      style={{
                        background: "#f7f7fc",
                        borderRadius: "16px",
                        padding: "20px",
                        display: "flex",
                        width: "100%",
                        marginBottom: "10px"
                      }}
                    >
                      <div>
                        <img
                          src={this.getCategoryImage(
                            listing.attributes.category
                          )}
                          style={{
                            height: "70px",
                            width: "70px",
                            objectFit: "conatin"
                          }}
                        />
                      </div>
                      <Box style={{ paddingLeft: "25px", width: "80%" }}>
                        <Typography
                          style={{
                            color: "#6e798c",
                            fontSize: "10px",
                            fontWeight: "600",
                            textTransform: "uppercase"
                          }}
                        >
                          {t(this.getCategoryName(listing.attributes.category))}
                        </Typography>
                        <Typography
                          style={{
                            color: "#081f32",
                            fontWeight: "600",
                            fontSize: "16px",
                            padding: "5px 0"
                          }}
                        >
                          {listing.attributes.title}
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <Typography
                            style={{
                              color: "#16cf7c",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              fontWeight: "400"
                            }}
                          >
                            {this.props.t("Price")}:{" "}
                            <span>{`${listing.attributes.pricing}${
                              currencies[listing.attributes.pricing_currency]
                            } ${
                              t(subscriptions[listing.attributes.pricing_type])
                            }`}</span>
                          </Typography>
                          <div style={{ display: "flex" }}>
                            {listing.attributes.media_channel.includes(
                              "instagram"
                            ) && (
                              <Box>
                                <img
                                  src={instagramIcon}
                                  style={{
                                    height: 10,
                                    width: 10
                                  }}
                                />
                              </Box>
                            )}
                            {listing.attributes.media_channel.includes(
                              "twitter"
                            ) && (
                              <Box ml={1}>
                                <img
                                  src={twitterIcon}
                                  style={{
                                    height: 10,
                                    width: 10
                                  }}
                                />
                              </Box>
                            )}
                            {listing.attributes.media_channel.includes(
                              "twitch"
                            ) && (
                              <Box ml={1}>
                                <img
                                  src={twitchIcon}
                                  style={{
                                    height: 10,
                                    width: 10
                                  }}
                                />
                              </Box>
                            )}
                            {listing.attributes.media_channel.includes(
                              "youtube"
                            ) && (
                              <Box ml={1}>
                                <img
                                  src={youtubeIcon}
                                  style={{
                                    height: 10,
                                    width: 10
                                  }}
                                />
                              </Box>
                            )}
                          </div>
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography
                    style={{
                      color: "#353535",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: 400
                    }}
                  >
                    {this.props.t("No listings available.")}
                  </Typography>
                )}

                {/* ends: list1 */}
              </Grid>
            </Grid>
          </Box>
          {/* ends: listings */}
        </>
      );
    
  };

  Portfolios = (userDetails) => {
    const {t} = this.props;
      return (
        <>
          <Box pl={3} pr={3} style={{}}>
            <Grid md={12} xs={12}>
              <Typography
                style={{
                  color: "#353535",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 30
                }}
              >
                {this.props.t("Portfolio")}
              </Typography>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 4 }}
              style={{ justifyContent: "space-between" }}
            >
              {userDetails.portfolios.data.length > 0 ? (
                userDetails.portfolios.data.map((portfolio) => (
                  <Grid
                    key={portfolio.id}
                    item
                    xs={6}
                    style={{ paddingRight: "10px", marginBottom: 10 }}
                  >
                    <Box
                      style={{
                        backgroundColor: "#f7f7fc",
                        borderBottomLeftRadius: "16px",
                        borderBottomRightRadius: "16px",
                        height: 200,
                        overflow: 'hidden'
                      }}
                    >
                      <Avatar
                        src={portfolio.attributes.project_banner}
                        style={{
                          borderRadius: 0,
                          width: "100%",
                          height: "150px",
                          objectFit: "cover"
                        }}
                      />

                      <Typography
                        style={{
                          color: "#081f32",
                          fontSize: "12px",
                          fontWeight: "bold",
                          padding: "5px",
                          textAlign: "center",
                          wordBreak: "break-word",
                          justifyContent: "center",
                          alignItems: 'center',
                          display: 'flex',
                          height: 50
                        }}
                      >
                        {portfolio.attributes.title.length > 20
                        ? portfolio.attributes.title.substr(0, 20).trim() +
                          "..."
                        : portfolio.attributes.title}
                      </Typography>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Typography
                  style={{
                    color: "#353535",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 400
                  }}
                >
                  {this.props.t("No portfolios available.")}
                </Typography>
              )}
            </Grid>
          </Box>
          {/* ends: portfolio */}
        </>
      );
    
  };

  HighlightedContent = (userDetails) => {
    const {t} = this.props;
      return (
        <>
          <Box pl={3} pr={3} style={{}}>
            <Grid md={12} xs={12}>
              <Typography
                style={{
                  color: "#353535",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: 30
                }}
              >
                {this.props.t("Highlighted Content")}
              </Typography>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 4 }}
              style={{ justifyContent: "space-between" }}
            >
              {userDetails.highlighted_contents.data &&
              userDetails.highlighted_contents.data.length > 0 ? (
                userDetails.highlighted_contents.data.map(
                  (highlightedContent) => (
                    <Grid
                      key={highlightedContent.id}
                      item
                      xs={12}
                      style={{ paddingRight: "10px" }}
                    >
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          wordBreak: "break-all",
                          paddingBottom: "6px",
                          fontWeight: "500"
                        }}
                      >
                        {highlightedContent.attributes.title || ""}
                      </Typography>
                      <iframe
                       allowfullscreen="allowfullscreen"
                       mozallowfullscreen="mozallowfullscreen" 
                       msallowfullscreen="msallowfullscreen" 
                       oallowfullscreen="oallowfullscreen" 
                       webkitallowfullscreen="webkitallowfullscreen"
                        width="100%"
                        height="150px"
                        src={this.getEmbededUrl(
                          highlightedContent.attributes.video_url
                        )}
                      />
                    </Grid>
                  )
                )
              ) : (
                <Typography
                  style={{
                    color: "#353535",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 400
                  }}
                >
                  {this.props.t("No highlighted content available.")}
                </Typography>
              )}
            </Grid>
          </Box>
          {/* ends: portfolio */}
        </>
      );
    
  };

  CaseStudies = (userDetails) => {
    const {t} = this.props;
    return (  
      <>
        <Box pl={3} pr={3} style={{}}>
          <Grid md={12} xs={12}>
            <Typography
              style={{
                color: "#353535",
                fontEeight: "500",
                fontFamily: "Poppins",
                fontSize: 16,
                fontWeight: 500,
                marginBottom: 30
              }}
            >
              {this.props.t("Case Study")}
            </Typography>
          </Grid>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 4 }}
            style={{ justifyContent: "space-between" }}
          >
            {userDetails.case_studies.data.length > 0 ? (
              userDetails.case_studies.data.map((caseStudy) => (
                <Grid
                  key={caseStudy.id}
                  item
                  xs={6}
                  style={{ paddingRight: "10px" }}
                >
                  <Box
                    style={{
                      backgroundColor: "#f7f7fc",
                      borderBottomLeftRadius: "16px",
                      borderBottomRightRadius: "16px"
                    }}
                  >
                    <Avatar
                      src={caseStudy.attributes.case_banner}
                      style={{
                        borderRadius: 0,
                        width: "100%",
                        height: "180px",
                        objectFit: "cover"
                      }}
                    />
                    <Typography
                      style={{
                        color: "#081f32",
                        fontSize: "12px",
                        fontWeight: "bold",
                        padding: "10px 10px 20px 10px",
                        textAlign: "center",
                        marginBottom: "10px"
                      }}
                    >
                      {caseStudy.attributes.title}
                    </Typography>
                  </Box>
                </Grid>
              ))
            ) : (
              <Typography
                style={{
                  color: "#353535",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: 400
                }}
              >
                {this.props.t("No case studies available.")}
              </Typography>
            )}
          </Grid>
        </Box>
        {/* ends: case study */}
      </>
    );
    
  };

  JobHistory = (userDetails) => {
      const { classes, t, i18n } = this.props;
      return (
        <>
          <Box pl={3} pr={3} style={{}}>
            <Grid container>
              <Grid md={12} xs={12}>
                <Typography
                  style={{
                    color: "#353535",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 500,
                    marginBottom: 30
                  }}
                >
                  {this.props.t("Job History")}
                </Typography>
              </Grid>
              <Grid md={12} xs={12}>
                {userDetails.job_histories.data.length > 0 ? (
                  userDetails.job_histories.data.map((jobHistory) => (
                    <Box
                      key={jobHistory.id}
                      className={classes.jobHistoryCard}
                      style={{
                        fontFamily: "Poppins",
                        background: "#f7f7fc",
                        borderRadius: "16px",
                        padding: "20px",
                        display: "flex",
                        width: "100%",
                        marginBottom: "10px"
                        // "@media (max-width: 768px)": {
                        //   padding: "5px",
                        // },
                      }}
                    >
                      <div>
                        <Avatar
                          src={jobHistory.attributes.company_logo}
                          style={{
                            height: "70px",
                            width: "70px",
                            objectFit: "conatin"
                          }}
                        />
                      </div>
                      <Box style={{ paddingLeft: "25px", width: "80%" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            color: "#081f32",
                            fontSize: "16px",
                            fontWeight: "600",
                            padding: "5px 0"
                          }}
                        >
                          {jobHistory.attributes.your_role},{" "}
                          {jobHistory.attributes.country}
                        </Typography>
                        <Typography
                          style={{
                            color: "#f14e24",
                            fontWeight: "600",
                            fontFamily: "Poppins",
                            fontSize: "16px"
                          }}
                        >
                          {jobHistory.attributes.company_name} ·
                          <span
                            style={{
                              color: "#6e798c",
                              fontSize: "16px",
                              fontWeight: "500",
                              padding: "5px 0",
                              fontFamily: "Poppins"
                            }}
                          >
                            {" "}
                            {jobHistory.attributes.city},{" "}
                            {jobHistory.attributes.country}
                            <br />
                            {this.getFullMonthName(jobHistory.attributes.start_date)}
                            {" "}
                            —{" "}
                            {jobHistory.attributes
                              .i_currently_work_in_this_company
                              ? `${this.props.t("Present")}`
                              :
                              this.getFullMonthName(jobHistory.attributes.end_date)
                                }{" "}
                            ·{" "}
                            {differenceInMonths(
                              jobHistory.attributes
                                .i_currently_work_in_this_company
                                ? new Date()
                                : new Date(jobHistory.attributes.end_date),
                              new Date(jobHistory.attributes.start_date)
                            )}{" "}
                            {this.props.t("months")}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography
                    style={{
                      color: "#353535",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: 400
                    }}
                  >
                    {this.props.t("No job histories available.")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          {/* ends: job history */}
        </>
      );
   
  };

  OpenPositions = (userDetails) => {
    const {t} = this.props;
      return (
        <>
          <Box pl={3} pr={3} style={{}}>
            <Grid container>
              <Grid md={12} xs={12}>
                <Typography
                  style={{
                    color: "#353535",
                    fontEeight: "500",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 500,
                    marginBottom: 30
                  }}
                >
                  {this.props.t("Open Positions")}
                </Typography>
              </Grid>
              <Grid md={12} xs={12}>
                {userDetails.open_positions.length > 0 ? (
                  userDetails.open_positions.map((openPosition) => (
                    <Box
                      key={openPosition.id}
                      style={{
                        background: "#f7f7fc",
                        borderRadius: "16px",
                        padding: "20px",
                        display: "flex",
                        width: "100%",
                        marginBottom: "10px"
                      }}
                    >
                      <div>
                        <Avatar
                          src={"/"}
                          style={{
                            height: "70px",
                            width: "70px",
                            objectFit: "conatin"
                          }}
                        />
                      </div>
                      <Box style={{ paddingLeft: "25px", width: "80%" }}>
                        <Typography
                          style={{
                            color: "#081f32",
                            fontSize: "16px",
                            fontWeight: "600",
                            padding: "5px 0"
                          }}
                        >
                          {openPosition.job_title}, {openPosition.country}
                        </Typography>
                        <Typography
                          style={{
                            color: "#f14e24",
                            fontWeight: "600",
                            fontSize: "16px"
                          }}
                        >
                          {openPosition.company_name} ·
                          <span
                            style={{
                              color: "#6e798c",
                              fontSize: "16px",
                              fontWeight: "500",
                              padding: "5px 0"
                            }}
                          >
                            {" "}
                            {openPosition.city}, {openPosition.country}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography
                    style={{
                      color: "#353535",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: 400
                    }}
                  >
                    {this.props.t("No open positions available.")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          {/* ends: job history */}
        </>
      );
   
  };

  Clients = (userDetails) => {
    const {t} = this.props;
      return (
        <>
          <Box pl={3} pr={3} style={{}}>
            <Grid container>
              <Grid md={12} xs={12}>
                <Typography
                  style={{
                    color: "#353535",
                    fontEeight: "500",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 500,
                    marginBottom: 30
                  }}
                >
                  {this.props.t("Clients")}
                </Typography>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                style={{ justifyContent: "space-between" }}
              >
                {userDetails.clients.data.length > 0 ? (
                  userDetails.clients.data.map((client) => (
                    <Grid
                      key={client.id}
                      item
                      xs={6}
                      style={{ paddingRight: "10px" }}
                    >
                      <Box
                        mb={2}
                        style={{
                          backgroundColor: "#f7f7fc",
                          borderRadius: "12px",
                          textAlign: "center",
                          padding: "30px 0 50px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center"
                        }}
                      >
                        <Avatar
                          src={client.attributes.client_photo}
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%"
                          }}
                        />
                        <Typography
                          style={{
                            color: "#4e4b66",
                            fontSize: "16px",
                            fontWeight: "900",
                            paddingTop: "20px"
                          }}
                        >
                          {client.attributes.client_name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    style={{
                      color: "#353535",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: 400
                    }}
                  >
                    {this.props.t("No clients available.")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          {/* ends: clients */}
        </>
      );
  };

  BrandAmbassadors = (userDetails) => {
    const {t} = this.props;
      return (
        <>
          <Box pl={3} pr={3} style={{}}>
            <Grid container>
              <Grid md={12} xs={12}>
                <Typography
                  style={{
                    color: "#353535",
                    fontEeight: "500",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 500,
                    marginBottom: 30
                  }}
                >
                  {this.props.t("Brand Ambassadors")}
                </Typography>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                style={{ justifyContent: "space-between" }}
              >
                {userDetails.brand_ambassadors.data.length > 0 ? (
                  userDetails.brand_ambassadors.data.map((brandAmassador) => (
                    <Grid
                      key={brandAmassador.id}
                      item
                      xs={6}
                      style={{ paddingRight: "10px" }}
                    >
                      <Box
                        mb={2}
                        style={{
                          backgroundColor: "#f7f7fc",
                          borderRadius: "12px",
                          textAlign: "center",
                          padding: "30px 0 50px",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center"
                        }}
                      >
                        <Avatar
                          src={brandAmassador.attributes.brand_ambassadors_image}
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%"
                          }}
                        />

                        <Typography
                          style={{
                            color: "#4e4b66",
                            fontSize: "16px",
                            fontWeight: "900",
                            paddingTop: "20px"
                          }}
                        >
                          {brandAmassador.attributes.social_handle}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    style={{
                      color: "#353535",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: 400
                    }}
                  >
                    {this.props.t("No brand ambassadors available.")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          {/* ends: clients */}
        </>
      );
  };

  Skills = (userDetails) => {
    const {t} = this.props;
      return (
        <>
          <Box pl={3} pr={3} style={{}}>
            <Grid container>
              <Grid md={12} xs={12}>
                <Typography
                  style={{
                    color: "#353535",
                    fontEeight: "500",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 500,
                    marginBottom: 30
                  }}
                >
                  {this.props.t("Skills")}
                </Typography>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                style={{ justifyContent: "space-between" }}
              >
                {userDetails.skills.data.length > 0 ? (
                  userDetails.skills.data.map((skill) => (
                    <Grid
                      key={skill.id}
                      item
                      xs={6}
                      style={{ paddingRight: "10px" }}
                    >
                      <Box
                        mb={2}
                        style={{
                          backgroundColor: "#f7f7fc",
                          border: "1px solid #ebeef0",
                          borderRadius: "25px",
                          textAlign: "center",
                          padding: "25px 15px",
                          wordBreak: "break-word"
                        }}
                      >
                        <Typography
                          style={{
                            color: "#4e4b66",
                            fontSize: "13px",
                            textAlign: "center",
                            fontFamily: "Poppins"
                          }}
                        >
                          {skill.attributes.name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography
                    style={{
                      color: "#353535",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: 400
                    }}
                  >
                    {this.props.t("No skills available.")}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {/* ends: Skills */}
          </Box>
        </>
      );
  };

  AdjustableElements = {
    "highlighted content": {
      component: this.HighlightedContent
    },
    "skills" : {
      component: this.Skills
    },
    "portfolio":{
      component: this.Portfolios
    },
    "listings":{
      component: this.Listings
    },
    "job history":{
      component: this.JobHistory
    },
    "case studies":{
      component: this.CaseStudies
    },
    "clients": {
      component: this.Clients
    },
    "open positions": {
      component: this.OpenPositions
    },
    "brand ambassadors": {
      component: this.BrandAmbassadors
    },
  };

  render() {
    const { classes, t, i18n } = this.props;
    const { userDetails, adjustableElements } = this.props;

    if (!userDetails.id) {
      return null;
    }

    let socialProfiles = [];

    if (
      userDetails.bx_block_social_profiles &&
      userDetails.bx_block_social_profiles.data.length > 0
    ) {
      socialProfiles = userDetails.bx_block_social_profiles.data.map(
        (profile) => {
          return {
            icon: Assets[profile.attributes.provider + "Icon"],
            count: profile?.attributes?.audiences[0]?.follower_count || 0
          };
        }
      );
    }
    
    if (adjustableElements && adjustableElements.length > 0) {
      for (let i = 0; i < adjustableElements.length; i++) {
        const element = this.AdjustableElements[adjustableElements[i].name.toLowerCase()];
        adjustableElements[i] = {...adjustableElements[i], component: element ? element.component : () => null }
      }
    }
    
    return (
      <Box className={classes.mobileViewBgImg}>
        {/* mobile  */}
        <Box className={classes.mobileViewScroll}>
          <Box
            style={{
              height: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative"
            }}
          >
            <Typography
              style={{
                color: "#081F32",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: 15
              }}
            >
              <img
                src={MobileBackIcon}
                style={{
                  height: "18px",
                  position: "absolute",
                  left: "10px",
                  top: "15px",
                  borderRadius: "50%",
                  WebkitBorderRadius: "50%",
                  MozBorderRadius: "50%"
                }}
              />
              {userDetails.first_name}
            </Typography>
          </Box>
          {/* banner section */}
          <Box style={{ position: "relative" }}>
            <Avatar
              src={userDetails.profile_cover}
              style={{ width: "100%", height: 98, borderRadius: 0 }}
            />
            <Avatar
              src={userDetails.profile_picture}
              style={{
                width: 91,
                height: 91,
                position: "absolute",
                bottom: "-32px",
                transform: "translate(-50%, 10px)",
                left: "50%",
                border: "6px solid white"
              }}
            />
          </Box>
          {/* ends: banner section */}
          <Box
            style={{
              padding: "50px 0 15px",
              position: "relative"
            }}
          >
            {/* container */}
            <Box
              style={{
                width: "100%",
                margin: "0 auto"
              }}
            >
              {/* ends: user profile section */}
              {
                  userDetails.profile_country_code && <Box
                  style={{
                    position: "absolute",
                    left: "0px",
                    top: "17px"
                  }}
                >
                 
                  <Typography
                    style={{
                      color: "#9e9e9e",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      paddingLeft: "8px",
                      paddingTop: "30px"
                    }}
                  >
                    <img
                      src={`https://countryflagsapi.com/png/${userDetails.profile_country_code || userDetails.profile_country_name}`}
                      style={{ height: "10px", paddingRight: "7px" }}
                    />
                    {userDetails.profile_country_name || userDetails.profile_country_code}
                  </Typography>
                </Box>
                }
              
              {/* ends: user profile section */}
              <Typography
                style={{
                  color: "#081f32",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingTop: "25px"
                }}
              >
                @{userDetails.user_name}{" "}
              </Typography>
              <Typography
                style={{
                  color: "#081f32",
                  fontFamily: "Poppins",
                  fontSize: 13,
                  // marginTop: 5,
                  textAlign: "center",
                  marginBottom: "15px"
                }}
              >
                {userDetails.brand.data.attributes.name}
              </Typography>
              {/* social row */}
              <Box
                style={{
                  padding: "10px 20px 0 20px",
                  display: "flex",
                  flexWrap: "wrap"
                  // justifyContent: "center",
                  // alignItems: "center"
                }}
              >
                {socialProfiles.map((profile, index) => (
                  <Chip
                    avatar={
                      <Avatar
                        alt={profile.icon}
                        src={profile.icon}
                        style={{ height: "12px", width: "12px" }}
                      />
                    }
                    label={profile.count}
                    style={{
                      backgroundColor: "#f3f3f3",
                      width: 84,
                      height: 33,
                      fontSize: "12px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      marginBottom: "10px",
                      marginRight: "10px"
                    }}
                  />
                ))}
              </Box>
              {/* ends: social row */}
              <Box
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              />
              <hr
                style={{
                  borderWidth: "5px",
                  margin: "30px 0"
                }}
              />
              {/* About */}
              <Box pl={3} pr={3} style={{}}>
                <Grid container>
                  <Grid md={12} xs={12}>
                    <Typography
                      style={{
                        color: "#353535",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 20
                      }}
                    >
                      {this.props.t("About")}
                    </Typography>
                    <p
                      style={{
                        color: "#636079",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 12
                      }}
                    >
                      {userDetails.about}
                    </p>
                  </Grid>
                </Grid>
              </Box>
              {/* ends: About */}
              {/* <hr
                style={{
                  borderWidth: "5px",
                  margin: "30px 0"
                }}
              /> */}

              {adjustableElements && adjustableElements.length > 0 && adjustableElements.map((item,index) => (
                <>{item.visible && item.component(userDetails)} 
                  {adjustableElements.length -1 !== index && (
                    <hr
                    style={{
                      borderWidth: "5px",
                      margin: "30px 0"
                    }}
                  /> 
                  )}
                  
              </>
              ))}
              
              
            </Box>
            {/* ends: container */}
          </Box>
        </Box>
      </Box>
    );
  }
}

const setProfileStyle = {
  mobileViewBgImg: {
    padding: "10px",
    position: "relative",
    width: "480px",
    width: "100%",
    height: "100%",
    minHeight: "800px",
    maxHeight: "800px",
    background: `url(${mobileBgUrl}) no-repeat`,
    backgroundSize: "contain",
    backgroundPosition: "top",
    margin: "10px auto",
    "@media (max-width: 768px)": {
      // width: "350px",
    },
    "@media (max-width: 576px)": {
      // width: "100%",
      // backgroundSize: "contain",
    }
  },
  mobileViewBgBottomImg: {
    height: "70px",
    width: "480px",
    position: "absolute",
    bottom: "-71px",
    left: "50.10%",
    transform: "translate(-50%, -50%)",
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  mobileViewScroll: {
    margin: "48px 20px 0 20px",
    // height: "650px",
    maxHeight: "650px",
    minHeight: "420px",
    width: "100%",
    maxWidth: "100%",
    minWidth: "300px",
    margin: "30px auto",
    overflowX: "hidden",
    overflowY: "scroll",
    "@media screen and (min-width: 1024px)": {
      minWidth: "300px",
      width: "min-content"
    },
    "@media only screen and (min-width: 1024px) and (max-width: 1024px)": {
      height: "600px",
      padding: "10px"
    },
    "@media only screen and (min-width: 812px) and (max-width: 823px)": {
      width: "inherit"
    },
    "@media only screen and (min-width: 768px) and (max-width: 768px)": {
      width: "inherit",
      maxWidth: "auto",
      minWidth: "auto",
      padding: 10,

      height: "430px"
    },
    "@media only screen and (min-width: 320px) and (max-width: 740px) and (orientation: landscape)": {
      width: "inherit",
      minHeight: "340px",
      maxHeight: "340px"
    },
    "@media only screen and (min-width: 568px) and (max-width: 568px)": {
      width: "min-content"
    },

    "@media (max-width: 576px)": {
      height: "560px",
      maxWidth: "300px",
      padding: "10px"
    },
    "@media only screen and (min-width: 330px) and (max-width: 600px)": {
      height: "645px"
    }
  },
  jobHistoryCard: {}
};
// export default MobilePreview;

export default withTranslation()(withStyles(setProfileStyle)(withRouter(MobilePreview as React.ComponentType<any>)))
