//@ts-nocheck
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import {
  Box,
  Typography,
  Link,
  Button,
  MenuItem,
  Select,
} from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Country } from "country-state-city";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { KeyboardArrowDown } from "@material-ui/icons";
import Snackbar from "../../../components/src/Snackbar";
import { mobilePreview, starImage } from "./assets";
import { withTranslation } from 'react-i18next';

// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { constants } from "crypto";
import Loader from "../../../components/src/Loader.web";
import Copyright from "../../../components/src/Copyright";
class ConfirmDetails extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const {t , i18n} = this.props
    // const allCountryList = Country.getAllCountries();
    // const countryList = allCountryList.map((item) => ({
    //   value: item.isoCode,
    //   label: item.name,
    // }));
if(!this.state.currentCountry){
  return  <Loader loading={this.state.loading} />;
}
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
      >
        {/* <Link
          href="/select-interests"
          underline="none"
          style={{
            color: "#1DA1F3",
            fontSize: 14,
            fontFamily: "Poppins",
            fontWeight: 400,
            position: "absolute",
            top: 43,
            right: 44,
          }}
        >
          Skip for Now
        </Link> */}

        <Box
          display="flex"
          mt="107px"
          mb="28px"
          width={isWidthUp("sm", this.props.width) ? 437 : 363}
          // justifyContent="center"
        >
          <Typography
            style={{
              fontSize: 26,
              color: "#14142B",
              fontFamily: "Poppins",
              fontWeight: 800,
              marginLeft: 50,
            }}
          >
            {t("Confirm your details")}
          </Typography>
          <img
            src={starImage}
            style={{ position: "absolute", height: 45, width: isWidthUp("sm", this.props.width) ? 437 : 363 }}
          />
        </Box>

        <Formik
          initialValues={{
            country: this.state?.currentCountry,
            description: "",
          }}
          validationSchema={this.ConfirmDetailsSchema(t)}
          validateOnMount={true}
          onSubmit={(values) => {
            this.updateConfirmDetails(values);
          }}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form translate="yes" style={{ width: isWidthUp("sm", this.props.width) ? 396 : 363 }}>
              <Box display="flex" flexDirection="column">
                <Typography
                  style={{
                    color: "#4E4B66",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 14,
                    marginBottom: 17,
                  }}
                >
                  {t("Please select your home market.*")}
                </Typography>

                <Select
                  name="country"
                  disableUnderline={true}
                  classes={{
                    select: this.props.classes.countryDropdown,
                  }}
                  style={{
                    width: "100%",

                  }}
                  IconComponent={() => (
                    <KeyboardArrowDown
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 11,
                        height: 24,
                        width: 24,
                        color: "#8E8E8E"
      
                      }}
                    />
                  )}
                  renderValue={(value) =>(value ? value :"Country" )}
                  displayEmpty={true}
                  value={`${t(values.country)}`}
                  onChange={handleChange("country")}
                >
                  {this.getCountries().map((country, index) => (
                    <MenuItem
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                      key={index}
                      value={country.label}
                    >
                      {`${t(country.label)}`}
                    </MenuItem>
                  ))}
                </Select>

                {errors.country && touched.country ? (
                  <Typography
                    style={{
                      color: "#F14E24",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: 14,
                      marginTop: 5,
                      marginLeft: 10,
                    }}
                  >
                    {errors.country}
                  </Typography>
                ) : null}

                <Typography
                  style={{
                    color: "#4E4B66",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 14,
                    marginBottom: 17,
                    marginTop: 48,
                  }}
                >
                  {t("yup.gg is your professional home, your profile should reflect how you want the gaming industry to see you.")}
                </Typography>

                <textarea
                  maxLength={2000}
                  value={values.description}
                  name="description"
                  onChange={handleChange("description")}
                  placeholder={t("Add a quick description")}
                  style={{
                    border: "1px solid #000",
                    height: 156,
                    width: "100%",
                    color: "#8E8E8E",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 16,
                    paddingLeft: 21,
                    paddingRight: 10,
                    paddingTop: 18,
                    outline: "none",
                    borderRadius: 16,
                    resize: "none",
                    backgroundColor: "transparent",
                  }}
                />
                {errors.description && touched.description ? (
                  <Typography
                    style={{
                      color: "#F14E24",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: 14,
                      marginTop: 5,
                      marginLeft: 10,
                    }}
                  >
                    {errors.description}
                  </Typography>
                ) : null}

                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: "#F14E24",
                    border: "1px solid #D9DBE9",
                    borderRadius: 16,
                    height: 34,
                    width: isWidthUp("sm", this.props.width) ? 400 : 363,
                    marginBottom: 14,
                    boxShadow: "none",
                    color: "#F7F7FC",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: 16,
                    marginTop: 28,
                  }}
                >
                  {t("Continue")}
                </Button>
                <Link
        href="/select-interests"
          underline="none"
          style={{
            
              // backgroundColor:"#F14E24" ,
              // border: "1px solid #D9DBE9",
              // borderRadius: 16,
              height: 34,
              width:  isWidthUp("sm", this.props.width) ? 400 : 350,
              marginBottom: 14,
              // boxShadow: "none",
              color:"#055AFF" ,
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 14,
              marginTop: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
              // opacity: Object.values(selectedHelps).length > 0 ? 1 : 0.6
            
          }}
        >
          {t("Skip for Now")}
        </Link>

              </Box>
            </Form>
          )}
        </Formik>

        <Box mt="315px" mb="100px" alignSelf="center">
        <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />

        {/* <img
          src={mobilePreview}
          style={{ height: "80%", position: "absolute", top: 105, right: 192 }}
        /> */}
      </Box>
    );
  }

  async componentDidMount() {
    this.getCountry();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    this.getCountryList();
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold",
  },
  // Customizable Area End
});

export const ConfirmDetailsStyle = {
  countryDropdown: {
    padding: 0,
    border: "1px solid #000",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 21,
    paddingRight: 10,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16,
      // backgroundColor: "white",
      // zIndex: 2
    },
  },
};

export default withTranslation()(withWidth()(withStyles(ConfirmDetailsStyle)(
  withRouter(ConfirmDetails as React.ComponentType<any>)
)))
