//@ts-ignore
//@ts-nocheck
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import { Box, Typography, Button, IconButton } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Snackbar from "../../../components/src/Snackbar";
import { logo, profileIcon, lock, backArrow } from "./assets";
import { withTranslation } from 'react-i18next';

// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { withStyles } from "@material-ui/core/styles";
import Copyright from "../../../components/src/Copyright";

class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, t , i18n } = this.props;
    return (
      <Box style={{overflowX:"hidden"}} className={classes.ForgotPassBlk}
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
       
        height="100%"
      >
        <IconButton className={classes.Arrow}
          onClick={() => {
            this.props.history.push("/");
          }}
          // style={{ position: "absolute", top: 62, left: 69 }}
        >
          <img src={backArrow} style={{ width: 17, height: 14 }} />
        </IconButton>

        <Box className={classes.logo}
          // display="flex"
          // mt="160px"
          // mb="50px"
          // width="494px"
          // justifyContent="center"
        >
          <img src={logo} style={{ height: 45 }} className={classes.ImgLogo} />
        </Box>

        <img src={lock} style={{ width: 79, height: 79 }} />

        <Typography
          style={{
            color: "#14142B",
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: 18,
            marginTop: 10
          }}
        >
          {t("Having trouble signing in")}
        </Typography>

        <Typography
          style={{
            color: "#A0A3BD",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: 12,
            marginTop: 17,
            marginBottom: 24,
            width: 327,
            textAlign: "center"
          }}
        >
          {t("Enter your a login option and we'll send you a link to get back into your account")}.
        </Typography>

        <Formik
          initialValues={{
            email: ""
          }}
          validationSchema={this.EmailSchema(t)}
          validateOnMount={true}
          onSubmit={(values) => {
            this.checkUser(values);
          }}
        >
          {({ values, errors, touched, isValid }) => (
            <Form translate="yes" style={{}} className={classes.form}>
              <Fragment>
                <Box display="flex" flexDirection="column">
                  <Box className={classes.InputPhone}
                    display="flex"
                    overflow="hidden"
                    alignItems="center"
                    height="56px"
                    border="1px solid #D1D1D1"
                    borderRadius="16px"
                    bgcolor="white"
                  >
                    <Field
                      name="email"
                      placeholder={t("Phone, Username or email")}
                      style={{
                        border: "none",
                        height: "100%",
                        width: "80%",
                        color: "rgba(0, 0, 0, 0.6)",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 16,
                        marginRight: 10,
                        marginLeft: 21,
                        outline: "none"
                      }}
                    />
                    <img
                      src={profileIcon}
                      style={{ width: 24, height: 24, marginRight: 16 }}
                    />
                  </Box>
                  {errors.email && touched.email ? (
                    <Typography
                      style={{
                        color: "#F14E24",
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        fontSize: 14,
                        marginTop: 5,
                        marginLeft: 10
                      }}
                    >
                      {errors.email}
                    </Typography>
                  ) : null}

                  <Button className={classes.BtnNext}
                    disabled={!isValid}
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "#F14E24",
                      border: "1px solid #D9DBE9",
                      borderRadius: 16,
                      height: 56,
                      marginBottom: 14,
                      boxShadow: "none",
                      color: "#F7F7FC",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: 16,
                      marginTop: 18,
                      opacity: !isValid ? 0.6 : 1
                    }}
                  >
                    {t("Next")}
                  </Button>
                </Box>
              </Fragment>
            </Form>
          )}
        </Formik>

        <Box mt="100px" mb="100px" className={classes.copyright}>
        <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    localStorage.removeItem("forgotPasswordPhone");
    localStorage.removeItem("forgotPasswordEmail");
    localStorage.removeItem("forgotPasswordUsername");
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});
const ForgotPasswordStyles={
  logo:{
    display:"flex",
    marginTop:"60px",
    marginBottom:"50px",
    width: "350px",
    justifyContent:"center",
    "@media (max-width: 768px)":{
     width:"100% !important",
     marginTop:"39px !important",
    }
  },
  Arrow:{
    position: "absolute",
     top: "62px", 
     left: "69px",
    "@media (max-width: 768px)":{
      top:"38px",
      left:"8px",
    }
  },
  copyright:{
    "@media (max-width: 768px)":{
      marginTop:"100px",
    }
  },
  form:{
    width:"363px",
    "@media (max-width: 767px)":{
      width:"100%",
    }
  },
  ImgLogo:{
    "@media (max-width: 768px)":{
    //  marginTop:"30px",
    }
  },
  InputPhone:{
    "@media (max-width: 768px)":{
      marginLeft:"10px",
      marginRight:"10px",
    }
  },
  BtnNext:{
    "@media (max-width: 768px)":{
      marginLeft:"10px",
      marginRight:"10px",
    }
  },
  ForgotPassBlk:{
    "@media (max-width: 768px)":{
      overflowY:"hidden",
    }
  }
}

export default withTranslation()(withStyles(ForgotPasswordStyles)(withRouter(ForgotPassword as React.ComponentType<any>)))
