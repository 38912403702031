export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// export const logo = require("../assets/logo.png");
export const logo = require("../assets/logo 96x96.svg");
export const twitterIcon = require("../assets/twitter.png");
export const facebookIcon = require("../assets/facebook.png");
export const googleIcon = require("../assets/google.png");
export const appleIcon = require("../assets/apple.png");
export const twitchIcon = require("../assets/twitch.png");
export const mailIcon = require("../assets/mail.png");
export const phoneIcon = require("../assets/phone.png");
export const callIcon = require("../assets/call.png");
export const profileIcon = require("../assets/profile.png");
export const lockIcon = require("../assets/lock.png");
export const passwordIcon = require("../assets/password.png");
export const checkboxIcon = require("../assets/checkbox.png");
export const starImage = require("../assets/stars.png");
export const mobilePreview = require("../assets/mobile_preview.png");
