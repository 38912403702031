//@ts-ignore
//@ts-nocheck
import { Avatar, Box, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withTranslation } from 'react-i18next';
import { BiEnvelope, BiHash, BiHomeCircle } from "react-icons/bi";
import { BsPerson, BsThreeDots } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { VscSignOut, VscTools } from "react-icons/vsc";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import imageLogo from "../assets/logo 96x96.svg";
import AboutController, { Props } from "./AboutController";


class SideMenu extends AboutController {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount() {
    this.getUserInformation();
    this.getNotificationCount();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  logout = () => {
    window.localStorage.clear();
    window.location = "/" as any;
  };

  toggle = () => {
    const temp = this.state.HamburgerMenuOpen;
    this.setState({ HamburgerMenuOpen: !temp });
  };
  render() {
    const {
      classes,
      subModule,
      location,
      history,
      showProfile,
      toggleMenu,
      t,
      i18n
    } = this.props;
    const { email, firstName, profileUrl } = this.state;

    const pathname = location.pathname;
   
    return (
      <>
        <div
          className={
            this.state.HamburgerMenuOpen
              ? classes.HamburgerMenuOpen + " " + classes.HamburgerMenuClose
              : classes.HamburgerMenuOpen
          }
          onClick={this.toggle}
        >
          <span />
          <span />
          <span />
        </div>
        <Box
          className={
            this.state.HamburgerMenuOpen
              ? classes.hamburgerMenu + " " + classes.hamburgerMenuActive
              : classes.hamburgerMenu
          }
        >
          <div
            className={
              this.state.HamburgerMenuOpen
                ? classes.HamburgerMenuOpen + " " + classes.HamburgerMenuClose
                : classes.HamburgerMenuOpen
            }
            onClick={this.toggle}
          >
            <span />
            <span />
            <span />
            {/* <VscMenu style={{ fontSize: "larger", marginTop: 2 }} /> */}
          </div>
          <Grid>
            <div className={classes.imgLogoBlock}>
                <img
                  className={classes.imgLogo + " " + classes.headerImgLogo}
                  src={imageLogo}
                />
            </div>
            <Grid>
              <Link
                to="#"
                style={{
                  textDecoration: "none",
                  color: "rgba(142, 142, 142, 1)"
                }}
              >
                <div
                  className={classes.routebtn}
                  style={{
                  color: "rgba(142, 142, 142, 1)"
                  }}
                >
                  <BiHomeCircle style={{ fontSize: "larger", marginTop: 2 }} />
                  <Typography className={classes.routebtnText}>{t("Home")}</Typography>
                </div>
              </Link>
              <div
                onClick={() => history.push("/discovery")}
                className={classes.routebtn}
                style={{
                  color: pathname.includes("discovery") ? "indianred" : ""
                }}
              >
                <BiHash style={{ fontSize: "larger", marginTop: 2 }} />
                <Typography className={classes.routebtnText}>
                  {t("Discovery")}
                </Typography>
              </div>
              <Link
                to="/notification-list"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  className={classes.routebtn}
                  style={{
                    color: pathname.includes("notification-list")
                      ? "indianred"
                      : ""
                  }}
                >
                  <BiHomeCircle style={{ fontSize: "larger", marginTop: 2 }} />
                  <Typography className={classes.routebtnText} style={{position:'relative'}}>
                    {t("Notifications")} {" "}
                  
                    {this.state.notificationCount ?
                      <span className={classes.button__badge}> {this.state.notificationCount}
                      </span> :null
                       }
                    
                  </Typography>
                  
                </div>
              </Link>
              <Link
                to="/inbox"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  style={{
                    color: pathname.includes("inbox") ? "indianred" : ""
                  }}
                  className={classes.routebtn}
                >
                  <BiEnvelope style={{ fontSize: "larger", marginTop: 2 }} />
                  <Typography className={classes.routebtnText}>
                    {t("Inbox")}
                  </Typography>
                </div>
              </Link>
              <Link
                to="/profile-dashboard"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  className={classes.routebtn}
                  style={{
                    color: pathname.includes("profile-dashboard")
                      ? "indianred"
                      : ""
                  }}
                >
                  <BsPerson style={{ fontSize: "larger", marginTop: 2 }} />
                  <Typography className={classes.routebtnText}>
                    {t("Profile")}
                  </Typography>
                </div>
              </Link>
              <Link
                to="/settings/edit-account"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  className={classes.routebtn}
                  style={{
                    color:
                      pathname.includes("settings/edit-account") ||
                      pathname.includes("settings/connect-account") ||
                      pathname.includes("settings/change-password") ||
                      pathname.includes("settings/email-sms") ||
                      pathname.includes("settings/privacy-security") ||
                      pathname.includes("settings/create-professional-account")
                        ? "indianred"
                        : ""
                  }}
                >
                  <FiSettings style={{ fontSize: "larger", marginTop: 2 }} />
                  <Typography className={classes.routebtnText}>
                    {t("Settings")}
                  </Typography>
                </div>
              </Link>
              <div
                className={classes.routebtn}
                style={{ color: "rgba(142, 142, 142, 1)" }}
              >
                <VscTools style={{ fontSize: "larger", marginTop: 2 }} />
                <Typography className={classes.routebtnText}>
                  {t("Workplace")}
                </Typography>
              </div>
              <div
                onClick={this.logout}
                className={classes.routebtn}
              >
                <VscSignOut style={{ fontSize: "larger", marginTop: 2 }} />
                <Typography className={classes.routebtnText}>{t("Logout")}</Typography>
              </div>
            </Grid>
            
          </Grid>
          {showProfile && (
            <Grid
              container
              className={
                subModule == 1 ? classes.profilebox : classes.profilebox1
              }
              style={this.props.profileBoxStyle}
            >
              <Grid className={classes.profileRow}>
                <Grid className={classes.dFlex}>
                  {
                    this.state?.firstName != undefined && this.state?.userName != undefined ?
                    (<>
                     <Grid>
                    <Avatar className={classes.profileImg} src={profileUrl} />
                  </Grid>
                      <Grid>
                      <Box style={{ display: "grid", paddingLeft: 10 }}>
                      <Typography style={{ fontSize: 12 }}>
                        {this.state?.firstName}
                      </Typography>
                      <Typography
                        style={{ fontSize: 12 }}
                      >{`@${this.state?.userName}`}</Typography>
                    </Box>   
                  </Grid>
                  </>
                    ):null
                  }
                </Grid>
                <Grid>
                  <BsThreeDots style={{}} />
                </Grid>
                {/* </div> */}
              </Grid>
            </Grid>
          )}
        </Box>
      </>
    );
  }
}

const SideMenuStyle = {
  imgLogoBlock: {
    position: "relative",
    "@media (max-width: 768px)": {
      paddingBottom: "20px"
    }
  },
  imgLogo: {
    position: "absolute",
    top: "-19px",
    width: "100px",
    height: "20px",
    objectFit: "contain",
    display: "none",
    "@media (max-width: 768px)": {
      display: "flex",
      height: "30px"
    }
  },
  HamburgerMenuOpen: {
    "@media (max-width: 768px)": {
      display: "block",
      position: "absolute",
      right: "11px",
      top: "-37px"
    },
    display: "none",
    height: "30px",
    width: "30px",
    // marginTop: "23px",
    // marginLeft: "25px",
    zIndex: "999",
    "& span": {
      display: "block",
      width: "33px",
      height: "4px",
      marginBottom: "5px",
      position: "relative",
      background: "#222",
      borderRadius: "3px",
      cursor: "pointer",
      zIndex: 1,
      transformOrigin: "4px 0px",
      transition:
        "transform 0.5s cubicBezier(0.77,0.2,0.05,1.0), background 0.5s cubicBezier(0.77,0.2,0.05,1.0), opacity 0.55s ease",
      "&:first-child": {
        transformOrigin: "0% 0%"
      },
      "&:nth-last-child(2)": {
        transformOrigin: "0% 100%"
      }
    }
  },
  HamburgerMenuClose: {
    top: "-44px",
    right: "25px",
    "& span": {
      opacity: "1",
      transform: "rotate(45deg) translate(-3px, -19px)",
      background: "#232323",
      "&:nth-last-child(3)": {
        opacity: 0,
        transform: "rotate(0deg) scale(0.2, 0.2)"
      },
      "&:nth-last-child(2)": {
        transform: "rotate(-45deg) translate(0, 18px)"
      }
    }
  },
  hamburgerMenu: {
    display: "grid",
    marginTop: 30,
    justifyContent: "center",
    // height: "100%",
    "@media (max-width: 768px)": {
      opacity: "0",
      marginTop: "0",
      display: "block",
      justifyContent: "center",
      position: "fixed",
      background: "#fff",
      zIndex: 0,
      width: "300px",
      // height: "100%",
      top: 0,
      padding: "50px 30px",
      left: 0,
      visibility: "hidden",
      transitionDuration: "0.3s",
      transitionProperty: "all",
      transitionTimingFunction: "ease",
      transform: "translatex(-100%)"
    }
  },
  dFlex: {
    display: "flex"
  },
  profileRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      width: "83%"
    }
  },
  hamburgerMenuActive: {
    "@media (max-width: 768px)": {
      opacity: "1",
      zIndex: 99,
      width: "100%",
      height: "100%",
      visibility: "visible",
      transform: "translatex(0)"
    }
  },
  button__badge: {
    backgroundColor: '#fa3e3e',
    borderRadius: '2px',
    color: 'white',   
    padding: '1px 3px',
    fontSize: '10px',
    
    position: 'absolute',/* Position the badge within the relatively positioned button */
    top: 5,
    right: -25,
  },
  routebtn: {
    fontSize: 18,
    display: "flex",
    padding: "15px 5px",
    cursor: "pointer",
    "@media (max-width: 1024px)": {
      fontSize: 14,
      padding: "10px 0"
    }
  },
  routebtnText: {
    fontSize: 18,
    fontFamily: "Poppins",
    paddingLeft: 10,
    "@media (max-width: 1024px)": {
      fontSize: 15
    }
  },
  profileImg: {
    width: 40,
    height: 40,
    borderRadius: "50%"
  },
  menuImg: {
    width: 19,
    height: 19
  },
  profilebox: {
    fontSize: 18,
    display: "flex",
    padding: "15px 15px 15px 35px",
    // position: "absolute",
    bottom: -120,
    width: "100%"
  },
  profilebox1: {
    fontSize: 18,
    display: "flex",
    // padding: "15px 15px 15px 35px",
    // position: "absolute",
    bottom: -40,
    marginTop: "50px",
    width: "100%",
    "@media (max-width: 768px)": {
      // bottom: 20,
      // position: "absolute"
    }
  }
};

export default withTranslation()(withStyles(SideMenuStyle)(
  withRouter(SideMenu as React.ComponentType<any>))
);
