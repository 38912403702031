import React from 'react';
import { withRouter } from 'react-router';

import TopNav from "../../../components/src/TopNav";
import Footer from "../../../components/src/Footer.web";
import LandingPageController, { Props }  from './LandingPageController';

class TeamPage extends LandingPageController {
 render() {
   return(
    <div>
    hello
  </div>
   )
 }
}

//@ts-ignore
export default withRouter(TeamPage)