//@ts-ignore
//@ts-nocheck
import React from "react";
import { withRouter } from "react-router";
import { Grid, item, Box, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// import i18n from 'i18next';

// import NewHeader from "./NewHeader";
// import Footer from "../../../components/src/Footer.web";

class SupportPage extends React.Component {
  render() {
    const { classes, t } = this.props;

    return (
      <section class="login-main-wrapper">
        <div class="main-container">
          <div class="login-process">
            <div class="login-main-container">
              <div
                class="thankyou-wrapper"
                style={{
                  width: "100%",
                  height: "auto",
                  margin: "auto",
                  background: "#ffffff",
                  padding: "10px 0px 50px"
                }}
              >
                <h1
                  style={{
                    font: "100px Arial, Helvetica, sans-serif",
                    textAlign: "center",
                    color: "#333333",
                    padding: "0px 10px 10px"
                  }}
                >
                  <img
                    src="http://montco.happeningmag.com/wp-content/uploads/2014/11/thankyou.png"
                    alt="thanks"
                  />
                </h1>
                <p
                  style={{
                    font: "26px Arial, Helvetica, sans-serif",
                    textAlign: "center",
                    color: "#333333",
                    padding: "5px 10px 10px"
                  }}
                >
                  for contacting us, we will get in touch with you soon...{" "}
                </p>
                <Link
                  to="/"
                  style={{
                    font: "26px Arial, Helvetica, sans-serif",
                    textAlign: "center",
                    color: "#ffffff",
                    display: "block",
                    textDecoration: "none",
                    width: "250px",
                    background: "#E47425",
                    margin: "10px auto 0px",
                    padding: "15px 20px 15px",
                    borderBottom: "5px solid #F96700"
                  }}
                >
                  Back to home
                </Link>
                <div class="clr" />
              </div>
              <div class="clr" />
            </div>
          </div>
          <div class="clr" />
        </div>
      </section>
    );
  }
}

const styles = {};

export default withTranslation()(withStyles(styles)(withRouter(SupportPage)));
