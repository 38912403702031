//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Button, Grid, IconButton, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import AmbassadorsController, { Props } from "./AmbassadorsController";
import { closeIcon, searchIcon } from "./assets";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class AmbassadorsSearchUser extends AmbassadorsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation, classes, t, i18n } = this.props;
    const { searchKeyword, searchResults, selectedUsers } = this.state;
    const formDetails = JSON.parse(localStorage.getItem("ambassadorDetails"));

    const selectedUsersArray = Object.values(selectedUsers);

    return (
      <>
        <ProfileHeader
          heading={t("Brand Ambassadors")}
          subModule={true}
          showProfile={true}
        />
        <Grid
          sm={12}
          container={true}
          style={{ backgroundColor: "#FFFFFF" }}
          className={classes.talentBlk}
        >
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>

          <Formik
            initialValues={
              formDetails || {
                talentName: "",
                socialHandle: "",
                location: "",
                description: "",
                logo: "",
                logoUrl: ""
              }
            }
            validationSchema={this.CreateTalentSchema}
            validateOnMount={true}
            onSubmit={(values) => {
              this.handleCreateUpdateTalent(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldError
            }) => (
              <Grid
                sm={12}
                md={6}
                style={{
                  border: "1px solid #DEDDDA",
                  borderTop: "none"
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{
                    marginTop: 40,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    borderRadius: 0,
                    borderBottom: "1px solid rgb(222, 221, 218)"
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%"
                    }}
                  >
                    <img
                      src={searchIcon}
                      style={{
                        height: 18,
                        width: 18,
                        marginLeft: 40
                      }}
                    />
                    <input
                      ref={this.searchInputRef}
                      placeholder={t("Search people")}
                      value={searchKeyword}
                      onChange={(e) => this.searchHandler(e.target.value)}
                      style={{
                        height: 48,
                        width: "80%",
                        paddingLeft: 10,
                        border: "none",
                        outline: "none",
                        zIndex: 8,
                        color: "#8E8E8E",
                        fontFamily: "Manrope",
                        fontSize: 14,
                        fontWeight: 400
                      }}
                    />
                  </Box>

                  {selectedUsersArray.length > 0 && (
                    <Box
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        marginBottom: 7,
                        paddingLeft: 22,
                        paddingRight: 22
                      }}
                    >
                      {selectedUsersArray.map((user) => (
                        <Box
                          key={user?.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: 33,
                            width: 152,
                            borderRadius: 16.5,
                            marginRight: 5,
                            marginBottom: 5,
                            border: "1px solid #CFD9DE"
                          }}
                        >
                          <Box display="flex">
                            <Avatar
                              style={{
                                borderRadius: 11.5,
                                height: 23,
                                width: 23,
                                marginLeft: 5,
                                marginRight: 7
                              }}
                            />

                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 15,
                                color: "#081F32"
                              }}
                            >
                              {user?.attributes?.first_name?.length > 7
                                ? user?.attributes?.first_name
                                    .substr(0, 7)
                                    .trim() + "..."
                                : user?.attributes?.first_name}
                            </Typography>
                          </Box>

                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => this.removeSelectedUserHandler(user)}
                          >
                            <img
                              src={closeIcon}
                              style={{
                                height: 14,
                                width: 14,
                                marginRight: 14
                              }}
                            />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                {searchResults.length > 0 &&
                  searchResults.map((user) => {
                    if (!selectedUsers.hasOwnProperty(user.id)) {
                      return (
                        <Box
                          onClick={() => this.selectUserHandle(user)}
                          key={user.id}
                          display="flex"
                          justifyContent="center"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            height: 76,
                            borderRadius: 0,
                            borderBottom: "1px solid rgb(222, 221, 218)",
                            cursor: "pointer"
                          }}
                        >
                          <Avatar
                            src={user.attributes.profile_picture}
                            style={{
                              borderRadius: 5,
                              height: 46,
                              width: 44,
                              marginLeft: 18
                            }}
                          />
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: 13
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 13,
                                color: "#081F32"
                              }}
                            >
                              {user.attributes.first_name}{" "}
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 10,
                                color: "#6E7191"
                              }}
                            >
                              @{user.attributes.user_name}{" "}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}

                <Box
                  display="flex"
                  flexDirection="column"
                  mt="16px"
                  mb="40px"
                  mr="26px"
                >
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      onClick={() => this.searchUserSubmitHandler()}
                      variant="contained"
                      type="button"
                      style={{
                        backgroundColor: "#F14E24",
                        border: "1px solid #D9DBE9",
                        borderRadius: 16,
                        height: 36,
                        width: 96,
                        marginBottom: 14,
                        boxShadow: "none",
                        color: "#F7F7FC",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: 14,
                        marginTop: 30
                      }}
                    >
                      {t("Next")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}
          </Formik>

          <Grid sm={12} md={4} className={classes.profileBlk} />
          <Snackbar
            open={Boolean(this.state.error)}
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
            severity="error"
          />
          <Loader loading={this.state.loading} />
          <CatchInvalidToken
              onClose={() => this.setState({ error: null })}
              message={this.state.error || "N/A"}
            />
        </Grid>
      </>
    );
  }

  async componentDidMount() {
    this.fetchSearchResults("");
    const searchedUsers = localStorage.getItem("ambassadorSearchedUsers");

    if (searchedUsers) {
      this.setState({ selectedUsers: JSON.parse(searchedUsers) });
    }
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
const ambassadorsSearchUserStyles = {
  profileBlk: {
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  imgLogo: {
    "@media (max-width: 768px)": {
      display: "none !important"
    }
  },
  talentBlk: {
    "@media (max-width: 768px)": {
      display: "block !important"
    }
  }
};

export const AmbassadorsSearchUserStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
    }
  }
};
// Customizable Area End
export default withTranslation()(withStyles(ambassadorsSearchUserStyles)(
  withRouter(AmbassadorsSearchUser as React.ComponentType<any>)
))
