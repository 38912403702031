import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { kickStartContentType, kickStartMethod, kickStartEndPoint } from "./config";


export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  kickStartContent: string;
  makeMoneyContent: string;
  rewardContent: string;
}
interface SS {
  id: any;
}
export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {

  kickStartContentMessageRequestId: any
  makeMoneyContentMessageRequestId: any
  rewardsContentMessageRequestId: any

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      kickStartContent: "",
      makeMoneyContent: "",
      rewardContent: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    else if (this.kickStartContentMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.kickStartContentMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse)

      if (responseJson) {
        // this.setState({ courses: responseJson.data })
        this.setState({ kickStartContent: responseJson.content })
      }
    }

    else if (this.makeMoneyContentMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.makeMoneyContentMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse)

      if (responseJson) {
        // this.setState({ courses: responseJson.data })
        this.setState({ makeMoneyContent: responseJson.content })
      }
    }

    else if (this.rewardsContentMessageRequestId !== null && message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) === this.rewardsContentMessageRequestId) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("errorReponse ", errorReponse)

      if (responseJson) {
        // this.setState({ courses: responseJson.data })
        this.setState({ rewardContent: responseJson.content })
      }
    }

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  getKickStartContent = () => {

    const header = {
      "Content-Type": kickStartContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.kickStartContentMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${kickStartEndPoint}kick-start`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.kickStartMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMakeMoneyContent = () => {

    const header = {
      "Content-Type": kickStartContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.makeMoneyContentMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${kickStartEndPoint}make-money`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.kickStartMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRewardContent = () => {

    const header = {
      "Content-Type": kickStartContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rewardsContentMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${kickStartEndPoint}rewards`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.kickStartMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
}
