//@ts-ignore
//@ts-nocheck
import React, { Fragment } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Avatar,
  LinearProgress,
  IconButton,
  Backdrop
} from "@material-ui/core";
import { Clear, SearchOutlined } from "@material-ui/icons";
import { Add } from "@material-ui/icons";
import Slider from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import { FlagImage, verify, logo } from "./assets";
import ProfileHeader from "../../../blocks/user-profile-basic/src/ProfileHeader";
import Snackbar from "../../../components/src/Snackbar";
import Loader from "../../../components/src/Loader.web";
import SearchController, { Props } from "./SearchController";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from 'react-i18next';

// const tabOptions = {
//   tabBarOptions: {
//     activeTintColor: 'white',
//     inactiveTintColor: 'red',
//     style: {
//       backgroundColor: 'green',
//       borderTopWidth: 1,
//       borderBottomWidth: 0,
//       borderTopColor: 'blue'
//     },
//     indicatorStyle: {
//       backgroundColor: 'red',
//     },
//   },
// }

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 7,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: "white"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#E45F3A"
  }
}))(LinearProgress);

class Discovery extends SearchController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;
    const {
      loading,
      error,
      completedTasks,
      totalTasks,
      showMoreCategories,
      showMoreContentCreators,
      showMoreEsportsPlayers,
      showMoreFeaturedGamers,
      showMorePopularProfiles,
      searchKeyword,
      showSearchResults,
    } = this.state;
    let {
      recentSearches,
      searchResults,
      popularProfiles,
      contentCreators,
      esportsPlayers,
      featuredGamers,
      categories
    } = this.state;

    recentSearches = recentSearches.filter(search => Boolean(search?.account?.data?.id))

    searchResults = searchResults.filter(search => Boolean(search?.attributes?.brand.data))

    popularProfiles = popularProfiles.filter(profile => Boolean(profile?.account?.brand_id))

    contentCreators = contentCreators.filter(profile => Boolean(profile?.account?.brand_id))

    esportsPlayers = esportsPlayers.filter(profile => Boolean(profile?.account?.brand_id))

    featuredGamers = featuredGamers.filter(profile => Boolean(profile?.account?.brand_id))

    popularProfiles = showMorePopularProfiles
      ? popularProfiles
      : popularProfiles.slice(0, 2);

    contentCreators = showMoreContentCreators
      ? contentCreators
      : contentCreators.slice(0, 2);

    esportsPlayers = showMoreEsportsPlayers
      ? esportsPlayers
      : esportsPlayers.slice(0, 2);

    featuredGamers = showMoreFeaturedGamers
      ? featuredGamers
      : featuredGamers.slice(0, 2);

    categories = showMoreCategories ? categories : categories.slice(0, 8);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      this.setState({ value: newValue });
    };

    console.log(this.state.recentSearches)

    return (
      <>
        <ProfileHeader
          heading={
            this.state.selectedCategory
              ? this.state.selectedCategory.attributes.name
              : `${t("Discovery")}`
          }
          onClick={
            this.state.selectedCategory
              ? () => {
                this.setState({
                  selectedCategory: null,
                  categoryProfiles: []
                });
              }
              : null
          }
          subModule={this.state.subModule}
          showProfile
        />

        {!this.state.selectedCategory && (
          <Grid className={classes.mainWrapper}>
            <Grid container>
              <Grid item sm={12} md={2} style={{ position: "relative" }}>
                <SideMenu showProfile />
              </Grid>
              <Grid sm={12} md={6} className={classes.centerWrapper}>
                <div style={{ border: "1px solid #DEDDDA", borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px", marginBottom: "20px" }}>
                  <Box
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      height: 64,
                      // borderBottom: "1px solid #DEDDDA",
                      display: "flex",
                      alignItems: "center",
                      // margin: "10px 0 20px 0",
                      color: "#FAFAFA",
                      fontFamily: "Poppins"
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        borderRadius: 22,
                        border: "1px solid rgb(222, 221, 218)"
                      }}
                    >
                      <SearchOutlined style={{ marginLeft: 10, color: "gray" }} />
                      <input
                        ref={this.searchInputRef}
                        placeholder={t("Search")}
                        value={searchKeyword}
                        onChange={(e) => this.searchHandler(e.target.value)}
                        onFocus={() => this.toggleShowSearchResults(true)}
                        style={{
                          height: 45,
                          width: "80%",
                          paddingLeft: 10,
                          border: "none",
                          outline: "none",
                          zIndex: 8,
                          color: "gray"
                        }}
                      />
                    </Box>
                  </Box>
                  <Box style={{ position: "relative" }}>
                    {showSearchResults && (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#FFFFFF",
                          boxShadow: "0px 0px 15px rgba(101, 119, 134, 0.2)",
                          borderRadius: 4,
                          width: "95%",
                          minHeight: 68,
                          maxHeight: 516,
                          marginLeft: "auto",
                          marginRight: "auto",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          left: 0,
                          zIndex: 6000,
                          paddingBottom: 10,
                          overflowY: "auto"
                        }}
                      >
                        {searchKeyword.trim().length === 0 &&
                          recentSearches.length > 0 ? (
                          <Fragment>
                            <Box
                              style={{
                                paddingLeft: 11,
                                paddingRight: 30,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: 57,
                                borderBottom: "1px solid #EBEEF0"
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#0F1419",
                                  fontSize: "20px",
                                  fontWeight: "normal"
                                }}
                              >
                                {t("Recent")}
                              </Typography>
                              <Button
                                onClick={() => this.clearAllRecentSearch()}
                                variant="text"
                                style={{
                                  color: "#F14E24",
                                  fontSize: "13px",
                                  fontWeight: "bold"
                                }}
                              >
                                {t("Clear all")}
                              </Button>
                            </Box>

                            {recentSearches.map((search) => (
                              <Box
                                key={search?.account?.data?.id}
                                style={{
                                  paddingLeft: 11,
                                  paddingRight: 30,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  height: 68,
                                  borderBottom: "1px solid #EBEEF0"
                                }}
                              >
                                <Box
                                  onClick={() => {
                                    window.location = ("/user-profile/" +
                                      search.recent_search.data.attributes
                                        .search_account_id) as any;
                                  }}
                                  display="flex"
                                  alignItems="center"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Avatar
                                    src={search?.account?.data?.attributes?.profile_picture}
                                    style={{ height: 40, width: 40 }}
                                  />
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    ml="12px"
                                  >
                                    <Typography
                                      style={{
                                        color: "#0F1419",
                                        fontSize: "15px",
                                        fontWeight: "normal"
                                      }}
                                    >
                                      {search?.account?.data?.attributes?.first_name}
                                      {search?.account?.data?.attributes?.verified && (
                                        <img
                                          src={verify}
                                          style={{ height: 13, marginLeft: "5px" }}
                                        />
                                      )}
                                    </Typography>
                                    <Typography
                                      style={{
                                        color: "#5B7083",
                                        fontSize: "15px",
                                        fontWeight: "normal"
                                      }}
                                    >
                                      @{search?.account?.data?.attributes?.user_name}
                                    </Typography>
                                  </Box>
                                </Box>
                                <IconButton
                                  style={{ zIndex: 6010 }}
                                  onClick={() =>
                                    this.removeRecentSearch(
                                      search?.recent_search?.data?.id
                                    )
                                  }
                                  variant="text"
                                >
                                  <Clear
                                    style={{
                                      height: 20,
                                      width: 20,
                                      color: "#F14E24"
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            ))}
                          </Fragment>
                        ) : (
                          searchKeyword.trim().length === 0 && (
                            <Box
                              style={{
                                display: "flex",
                                width: "100%",
                                height: 68,
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#0F1419",
                                  fontSize: "14px",
                                  fontWeight: "normal"
                                }}
                              >
                                {t("No results")}
                              </Typography>
                            </Box>
                          )
                        )}

                        {searchKeyword.trim().length > 0 &&
                          searchResults.length > 0 ? (
                          <Fragment>
                            {searchResults.map((search) => (
                              <Box
                                onClick={() =>
                                  this.handleCreateRecentSearch(search.id)
                                }
                                key={search.id}
                                style={{
                                  paddingLeft: 11,
                                  paddingRight: 30,
                                  display: "flex",
                                  alignItems: "center",
                                  height: 68,
                                  borderBottom: "1px solid #EBEEF0",
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                  marginTop: 10,
                                  cursor: "pointer"
                                }}
                              >
                                <Avatar
                                  src={search.attributes.profile_picture}
                                  style={{ height: 40, width: 40 }}
                                />
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  ml="12px"
                                >
                                  <Typography
                                    style={{
                                      color: "#0F1419",
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                      fontFamily: "Manrope"
                                    }}
                                  >
                                    {search.attributes.first_name}
                                  </Typography>
                                  <Typography
                                    style={{
                                      color: "#5B7083",
                                      fontSize: "15px",
                                      fontWeight: "normal",
                                      fontFamily: "Manrope"
                                    }}
                                  >
                                    @{search.attributes.user_name}
                                  </Typography>
                                </Box>
                              </Box>
                            ))}
                          </Fragment>
                        ) : (
                          searchKeyword.trim().length > 0 && (
                            <Box
                              style={{
                                display: "flex",
                                width: "100%",
                                height: 68,
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <Typography
                                style={{
                                  color: "#0F1419",
                                  fontSize: "14px",
                                  fontWeight: "normal"
                                }}
                              >
                                {t("No results")}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    )}

                    <div>
                      <Tabs
                        value={this.state.value}
                        classes={{ indicator: classes.indicator }}
                        onChange={handleChange}
                        centered
                      >
                        <Grid item xs={6} style={{ textAlign: "center" }}>
                          <Tab
                            label={t("Profile")}
                            className={classes.tab1}
                            onClick={() =>
                              this.props.history.push("/profile-dashboard")
                            }
                          />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "center" }}>
                          <Tab
                            label={t("People")}
                            className={classes.tabSecond}
                            onClick={() => this.props.history.push("/discovery")}
                          />
                        </Grid>
                      </Tabs>
                    </div>
                    <Grid
                      container
                      style={{
                        padding: "20px 15px",
                        borderBottom: "1px solid rgb(222, 221, 218)",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <Grid md={8}>
                        <Typography
                          style={{
                            fontSize: "1.6rem",
                            fontWeight: "600",
                            color: "#150050"
                          }}
                        >
                          {t("Most Popular Profiles")}
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Button
                          onClick={() =>
                            this.setState({
                              showMorePopularProfiles: !showMorePopularProfiles
                            })
                          }
                          variant="text"
                          style={{
                            color: "#F14E24",
                            fontSize: "1.4rem",
                            fontWeight: "bold",
                            float: "right"
                          }}
                        >
                          {showMorePopularProfiles ? `${t("See Less")}` : `${t("See More")}`}
                        </Button>
                      </Grid>
                    </Grid>
                    {popularProfiles.map((profile) => (
                      <Grid className={classes.showmoreprofiles}
                        key={profile.account.id}
                        container
                        style={{
                          // padding: "15px",
                          // borderBottom: "1px solid #000"
                        }}
                      >
                        <Grid
                          onClick={() => {
                            window.location = ("/user-profile/" +
                              profile.account.id) as any;
                          }}
                          xs={2} sm={2}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            cursor: "pointer"
                          }}
                        >
                          <Avatar
                            src={profile.account.profile_picture}
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "6px"
                            }}
                          />
                        </Grid>
                        <Grid className={classes.textBlock}
                          xs={5} sm={5}
                          onClick={() => {
                            window.location = ("/user-profile/" +
                              profile.account.id) as any;
                          }}
                          style={{
                            cursor: "pointer"
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "1.3rem",
                              fontWeight: "600",
                              color: "#150050",
                              marginBottom: "4px"
                            }}
                          >
                            {profile.account.first_name}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "1.1rem",
                              color: "#c3c3c3",
                              fontWeight: "400",
                              marginBottom: "5px"
                            }}
                          >
                            {`@${profile.account.user_name}`}
                          </Typography>
                          <Typography className={classes.leadText}>
                            {profile.account.about
                              ? `@${profile.account.about}`
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid className={classes.rightBlock}
                          sm={5}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          {profile.follow_status.toLowerCase() === "following" ? (
                            <Button
                              onClick={() =>
                                this.unfollowAccount(profile.account.id)
                              }
                              style={{
                                backgroundColor: "#F14E24",
                                border: "1px solid #D9DBE9",
                                borderRadius: 16,
                                height: 35,
                                boxShadow: "none",
                                color: "#F7F7FC",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                padding: "10px 20px",
                                marginRight: "10px"
                              }}
                            >
                              {t("Unfollow")}
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                this.followAccount(profile.account.id)
                              }
                              style={{
                                backgroundColor: "#F14E24",
                                border: "1px solid #D9DBE9",
                                borderRadius: 16,
                                height: 35,
                                boxShadow: "none",
                                color: "#F7F7FC",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                padding: "10px 20px",
                                marginRight: "10px"
                              }}
                            >
                              {t(profile.follow_status)}
                            </Button>
                          )}

                          <Button
                            onClick={() => {
                              if (profile.connection_status.toLowerCase() === 'connect') {
                                this.connectAccount(profile.account.id)
                              } else {
                                this.disconnectAccount(profile.account.id)
                              }
                            }}
                            disabled={
                              !profile.connection_status.toLowerCase().includes('connect')
                            }
                            style={{
                              backgroundColor: "#fff",
                              border: "1px solid #F14E24",
                              borderRadius: 16,
                              height: 35,
                              boxShadow: "none",
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              padding: "10px 20px"
                            }}
                          >
                            {t(profile.connection_status.toLowerCase() === 'connected' ? 'Disconnect' : profile.connection_status)}
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </div>
                <Box style={{ border: "1px solid #DEDDDA", borderRadius: "12px", marginBottom: "20px" }}>
                  <Grid
                    container
                    style={{
                      padding: "20px 15px",
                      borderBottom: "1px solid rgb(222, 221, 218)",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <Grid md={8}>
                      <Typography
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "600",
                          color: "#150050"
                        }}
                      >
                        {t("Search by Category")}
                      </Typography>
                    </Grid>
                    <Grid md={4}>
                      <Button
                        onClick={() =>
                          this.setState({
                            showMoreCategories: !showMoreCategories
                          })
                        }
                        variant="text"
                        style={{
                          color: "blue",
                          fontSize: "1.4rem",
                          // fontWeight: "bold",
                          float: "right"
                        }}
                      >
                        {t("Show")} {showMoreCategories ? `${t("Less")}` : `${t("More")}`}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container>
                    {categories.map((category) => (
                      <Grid
                        key={category.id}
                        xs={6}
                        sm={3}
                        style={{ padding: "10px" }}
                      >
                        <Box
                          onClick={() => this.handleSelectCategory(category)}
                          style={{
                            backgroundColor: "#d4d4d4",
                            padding: "15px",
                            textAlign: "center",
                            borderRadius: "5px",
                            height: 130,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          <Typography
                            style={{
                              color: "#150050",
                              fontSize: "1.3rem",
                              marginTop: "10px",
                              fontWeight: "bold",
                              wordBreak: "break-word"
                            }}
                          >
                            {category.attributes.name}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                {featuredGamers && featuredGamers.length > 0 && (
                  <Box style={{ 
                        border: "1px solid #DEDDDA", 
                        borderRadius: "12px", 
                        marginBottom: "20px" 
                    }}>
                    <Grid
                      container
                      style={{
                        padding: "20px 15px",
                        borderBottom: "1px solid rgb(222, 221, 218)",
                        // borderTop: "1px solid rgb(222, 221, 218)",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 15
                      }}
                    >
                      <Grid md={8}>
                        <Typography
                          style={{
                            fontSize: "1.6rem",
                            fontWeight: "600",
                            color: "#150050"
                          }}
                        >
                          {t("Featured Gamers")}
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Button
                          onClick={() =>
                            this.setState({
                              showMoreFeaturedGamers: !showMoreFeaturedGamers
                            })
                          }
                          variant="text"
                          style={{
                            color: "blue",
                            fontSize: "1.4rem",
                            // fontWeight: "bold",
                            float: "right"
                          }}
                        >
                          {t("Show")} {showMoreFeaturedGamers ? `${t("Less")}` : `${t("More")}`}
                        </Button>
                      </Grid>
                    </Grid>
                    {featuredGamers.length > 0 ? (
                      featuredGamers.map((profile) => (
                        <Grid className={classes.showmoreprofiles}
                          key={profile.account.id}
                          container
                          style={{
                            // padding: "15px",
                            // borderBottom: "1px solid rgb(222, 221, 218)"
                          }}
                        >
                          <Grid
                            xs={2} sm={2}
                            onClick={() => {
                              window.location = ("/user-profile/" +
                                profile.account.id) as any;
                            }}
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              cursor: "pointer"
                            }}
                          >
                            <Avatar
                              src={profile.account.profile_picture}
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "6px"
                              }}
                            />
                          </Grid>
                          <Grid className={classes.textBlock}
                            xs={5} sm={5}
                            onClick={() => {
                              window.location = ("/user-profile/" +
                                profile.account.id) as any;
                            }}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "1.3rem",
                                fontWeight: "600",
                                color: "#150050",
                                marginBottom: "4px"
                              }}
                            >
                              {profile.account.first_name}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "1.1rem",
                                color: "#c3c3c3",
                                fontWeight: "400",
                                marginBottom: "5px"
                              }}
                            >
                              {`@${profile.account.user_name}`}
                            </Typography>
                            <Typography className={classes.leadText}>
                              {profile.account.about
                                ? `@${profile.account.about}`
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid className={classes.rightBlock}
                            sm={5}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end"
                            }}
                          >
                            {profile.following_status.toLowerCase() ===
                              "following" ? (
                              <Button
                                onClick={() =>
                                  this.unfollowAccount(profile.account.id)
                                }
                                style={{
                                  backgroundColor: "#F14E24",
                                  border: "1px solid #D9DBE9",
                                  borderRadius: 16,
                                  height: 35,
                                  boxShadow: "none",
                                  color: "#F7F7FC",
                                  fontFamily: "Poppins",
                                  fontSize: 13,
                                  padding: "10px 20px",
                                  marginRight: "10px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {t("Unfollow")}
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  this.followAccount(profile.account.id)
                                }
                                style={{
                                  backgroundColor: "#F14E24",
                                  border: "1px solid #D9DBE9",
                                  borderRadius: 16,
                                  height: 35,
                                  boxShadow: "none",
                                  color: "#F7F7FC",
                                  fontFamily: "Poppins",
                                  fontSize: 13,
                                  padding: "10px 20px",
                                  marginRight: "10px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {t(profile.following_status)}
                              </Button>
                            )}

                            <Button
                              onClick={() => {
                                if (profile.connection_status.toLowerCase() === 'connect') {
                                  this.connectAccount(profile.account.id)
                                } else {
                                  this.disconnectAccount(profile.account.id)
                                }
                              }}
                              disabled={
                                !profile.connection_status.toLowerCase().includes('connect')
                              }
                              style={{
                                backgroundColor: "#fff",
                                border: "1px solid #F14E24",
                                borderRadius: 16,
                                height: 35,
                                boxShadow: "none",
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                padding: "10px 20px",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {t(profile.connection_status.toLowerCase() === 'connected' ? 'Disconnect' : profile.connection_status)}
                            </Button>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Grid className={classes.showmoreprofiles}
                        container
                        style={{
                          // padding: "15px",
                          // borderBottom: "1px solid rgb(222, 221, 218)"
                        }}
                      >
                        <Grid
                          md={12}
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          <Typography
                            style={{
                              fontSize: "1.6rem",
                              fontWeight: "600",
                              color: "#150050"
                            }}
                          >
                            {t("No Featured Gamers Found!")}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                )}

                {contentCreators && contentCreators.length > 0 && (
                  <Box style={{ border: "1px solid #DEDDDA", borderRadius: "12px", marginBottom: "20px" }}>
                    <Grid
                      container
                      style={{
                        padding: "20px 15px",
                        borderBottom: "1px solid rgb(222, 221, 218)",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <Grid md={8}>
                        <Typography
                          style={{
                            fontSize: "1.6rem",
                            fontWeight: "600",
                            color: "#150050"
                          }}
                        >
                          {t("Content Creators")}
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Button
                          onClick={() =>
                            this.setState({
                              showMoreContentCreators: !showMoreContentCreators
                            })
                          }
                          variant="text"
                          style={{
                            color: "blue",
                            fontSize: "1.4rem",
                            // fontWeight: "bold",
                            float: "right"
                          }}
                        >
                          {t("Show")} {showMoreContentCreators ? `${t("Less")}` : `${t("More")}`}
                        </Button>
                      </Grid>
                    </Grid>
                    {contentCreators.length > 0 ? (
                      contentCreators.map((profile) => (
                        <Grid className={classes.showmoreprofiles}
                          key={profile.account.id}
                          container
                          style={{
                            // padding: "15px",
                            // borderBottom: "1px solid rgb(222, 221, 218)"
                          }}
                        >
                          <Grid
                            xs={2} sm={2}
                            onClick={() => {
                              window.location = ("/user-profile/" +
                                profile.account.id) as any;
                            }}
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              cursor: "pointer"
                            }}
                          >
                            <Avatar
                              src={profile.account.profile_picture}
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "6px"
                              }}
                            />
                          </Grid>
                          <Grid className={classes.textBlock}
                            xs={5} sm={5}
                            onClick={() => {
                              window.location = ("/user-profile/" +
                                profile.account.id) as any;
                            }}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "1.3rem",
                                fontWeight: "600",
                                color: "#150050",
                                marginBottom: "4px"
                              }}
                            >
                              {profile.account.first_name}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "1.1rem",
                                color: "#c3c3c3",
                                fontWeight: "400",
                                marginBottom: "5px"
                              }}
                            >
                              {`@${profile.account.user_name}`}
                            </Typography>
                            <Typography className={classes.leadText}>
                              {profile.account.about
                                ? `@${profile.account.about}`
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid className={classes.rightBlock}
                            sm={5}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end"
                            }}
                          >
                            {profile.following_status.toLowerCase() ===
                              "following" ? (
                              <Button
                                onClick={() =>
                                  this.unfollowAccount(profile.account.id)
                                }
                                style={{
                                  backgroundColor: "#F14E24",
                                  border: "1px solid #D9DBE9",
                                  borderRadius: 16,
                                  height: 35,
                                  boxShadow: "none",
                                  color: "#F7F7FC",
                                  fontFamily: "Poppins",
                                  fontSize: 13,
                                  padding: "10px 20px",
                                  marginRight: "10px"
                                }}
                              >
                                {t("Unfollow")}
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  this.followAccount(profile.account.id)
                                }
                                style={{
                                  backgroundColor: "#F14E24",
                                  border: "1px solid #D9DBE9",
                                  borderRadius: 16,
                                  height: 35,
                                  boxShadow: "none",
                                  color: "#F7F7FC",
                                  fontFamily: "Poppins",
                                  fontSize: 13,
                                  padding: "10px 20px",
                                  marginRight: "10px"
                                }}
                              >
                                {t(profile.following_status)}
                              </Button>
                            )}
                            <Button
                              onClick={() => {
                                if (profile.connection_status.toLowerCase() === 'connect') {
                                  this.connectAccount(profile.account.id)
                                } else {
                                  this.disconnectAccount(profile.account.id)
                                }
                              }}
                              disabled={
                                !profile.connection_status.toLowerCase().includes('connect')
                              }
                              style={{
                                backgroundColor: "#fff",
                                border: "1px solid #F14E24",
                                borderRadius: 16,
                                height: 35,
                                boxShadow: "none",
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                padding: "10px 20px"
                              }}
                            >
                              {t(profile.connection_status.toLowerCase() === 'connected' ? 'Disconnect' : profile.connection_status)}
                            </Button>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Grid className={classes.showmoreprofiles}
                        container
                        style={{
                          // padding: "15px",
                          // borderBottom: "1px solid rgb(222, 221, 218)"
                        }}
                      >
                        <Grid
                          md={12}
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          <Typography
                            style={{
                              fontSize: "1.6rem",
                              fontWeight: "600",
                              color: "#150050"
                            }}
                          >
                            {t("No Content Creators Found!")}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                )}

                {esportsPlayers && esportsPlayers.length > 0 && (
                  <Box style={{ border: "1px solid #DEDDDA", borderRadius: "12px", marginBottom: "20px" }}>
                    <Grid
                      container
                      style={{
                        padding: "20px 15px",
                        borderBottom: "1px solid rgb(222, 221, 218)",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <Grid md={8}>
                        <Typography
                          style={{
                            fontSize: "1.6rem",
                            fontWeight: "600",
                            color: "#150050"
                          }}
                        >
                          {t("Esports Pro Player")}
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Button
                          onClick={() =>
                            this.setState({
                              showMoreEsportsPlayers: !showMoreEsportsPlayers
                            })
                          }
                          variant="text"
                          style={{
                            color: "blue",
                            fontSize: "1.4rem",
                            // fontWeight: "bold",
                            float: "right"
                          }}
                        >
                          {t("Show")} {showMoreEsportsPlayers ? `${t("Less")}` : `${t("More")}`}
                        </Button>
                      </Grid>
                    </Grid>
                    {esportsPlayers.length > 0 ? (
                      esportsPlayers.map((profile) => (
                        <Grid className={classes.showmoreprofiles}
                          key={profile.account.id}
                          container
                          style={{
                            // padding: "15px",
                            // borderBottom: "1px solid rgb(222, 221, 218)"
                          }}
                        >
                          <Grid
                            xs={2} sm={2}
                            onClick={() => {
                              window.location = ("/user-profile/" +
                                profile.account.id) as any;
                            }}
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              cursor: "pointer"
                            }}
                          >
                            <Avatar
                              src={profile.account.profile_picture}
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "6px"
                              }}
                            />
                          </Grid>
                          <Grid className={classes.textBlock}
                            xs={5} sm={5}
                            onClick={() => {
                              window.location = ("/user-profile/" +
                                profile.account.id) as any;
                            }}
                            style={{
                              cursor: "pointer"
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "1.3rem",
                                fontWeight: "600",
                                color: "#150050",
                                marginBottom: "4px"
                              }}
                            >
                              {profile.account.first_name}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "1.1rem",
                                color: "#c3c3c3",
                                fontWeight: "400",
                                marginBottom: "5px"
                              }}
                            >
                              {`@${profile.account.user_name}`}
                            </Typography>
                            <Typography className={classes.leadText}>
                              {profile.account.about
                                ? `@${profile.account.about}`
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid className={classes.rightBlock}
                            sm={5}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end"
                            }}
                          >
                            {profile.following_status.toLowerCase() ===
                              "following" ? (
                              <Button
                                onClick={() =>
                                  this.unfollowAccount(profile.account.id)
                                }
                                style={{
                                  backgroundColor: "#F14E24",
                                  border: "1px solid #D9DBE9",
                                  borderRadius: 16,
                                  height: 35,
                                  boxShadow: "none",
                                  color: "#F7F7FC",
                                  fontFamily: "Poppins",
                                  fontSize: 13,
                                  padding: "10px 20px",
                                  marginRight: "10px"
                                }}
                              >
                                {t("Unfollow")}
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  this.followAccount(profile.account.id)
                                }
                                style={{
                                  backgroundColor: "#F14E24",
                                  border: "1px solid #D9DBE9",
                                  borderRadius: 16,
                                  height: 35,
                                  boxShadow: "none",
                                  color: "#F7F7FC",
                                  fontFamily: "Poppins",
                                  fontSize: 13,
                                  padding: "10px 20px",
                                  marginRight: "10px"
                                }}
                              >
                                {t(profile.following_status)}
                              </Button>
                            )}
                            <Button
                              onClick={() => {
                                if (profile.connection_status.toLowerCase() === 'connect') {
                                  this.connectAccount(profile.account.id)
                                } else {
                                  this.disconnectAccount(profile.account.id)
                                }
                              }}
                              disabled={
                                !profile.connection_status.toLowerCase().includes('connect')
                              }
                              style={{
                                backgroundColor: "#fff",
                                border: "1px solid #F14E24",
                                borderRadius: 16,
                                height: 35,
                                boxShadow: "none",
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                padding: "10px 20px"
                              }}
                            >
                              {t(profile.connection_status.toLowerCase() === 'connected' ? 'Disconnect' : profile.connection_status)}
                            </Button>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Grid className={classes.showmoreprofiles}
                        container
                        style={{
                          // padding: "15px",
                          // borderBottom: "1px solid rgb(222, 221, 218)"
                        }}
                      >
                        <Grid
                          md={12}
                          style={{ justifyContent: "center", display: "flex" }}
                        >
                          <Typography
                            style={{
                              fontSize: "1.6rem",
                              fontWeight: "600",
                              color: "#150050"
                            }}
                          >
                            {t("No Esports Pro Player Found!")}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                )}
              </Grid>
              <Grid sm={12} md={4}>
                <Link
                  to="/profile-dashboard"
                  style={{
                    textDecoration: "none"
                  }}
                >
                  <Box style={{ padding: "30px" }}>
                    <Box
                      style={{
                        background:
                          "linear-gradient(to right, #03A1A4 0%, #99E7D1 100%)",
                        padding: "30px",
                        borderRadius: "15px"
                      }}
                    >
                      <Typography
                        style={{
                          color: "#ffffff",
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          fontSize: 14,
                          paddingBottom: "10px"
                        }}
                      >
                        {t("Complete your profile today")}
                      </Typography>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          totalTasks > 0
                            ? Math.ceil((completedTasks / totalTasks) * 100)
                            : 0
                        }
                      />

                      <Typography
                        style={{
                          color: "#ffffff",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 12,
                          paddingTop: "10px"
                        }}
                      >
                        {completedTasks}/{totalTasks} {t("done")}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}

        {this.state.selectedCategory && (
          <Grid className={classes.mainWrapper}>
            <Grid container>
              <Grid item xs={12} md={2} style={{ position: "relative" }}>
                <SideMenu showProfile />
              </Grid>
              <Grid
                xs={12}
                md={6}
                style={{
                  border: "1px solid #DEDDDA",
                  borderTop: "none",
                  height: "100%"
                }}
              >
                <Box
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    height: 64,
                    borderBottom: "1px solid #DEDDDA",
                    display: "flex",
                    alignItems: "center",
                    // margin: "10px 0 20px 0",
                    color: "#FAFAFA",
                    fontFamily: "Poppins"
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: 22,
                      border: "1px solid rgb(222, 221, 218)"
                    }}
                  >
                    <SearchOutlined style={{ marginLeft: 10, color: "gray" }} />
                    <input
                      ref={this.searchInputRef}
                      placeholder={t("Search")}
                      value={searchKeyword}
                      onChange={(e) => this.searchHandler(e.target.value)}
                      onFocus={() => this.toggleShowSearchResults(true)}
                      style={{
                        height: 45,
                        width: "80%",
                        paddingLeft: 10,
                        border: "none",
                        outline: "none",
                        zIndex: 8,
                        color: "gray"
                      }}
                    />
                  </Box>
                </Box>
                <Box style={{ position: "relative" }}>
                  {showSearchResults && (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 0px 15px rgba(101, 119, 134, 0.2)",
                        borderRadius: 4,
                        width: "95%",
                        minHeight: 68,
                        maxHeight: 516,
                        marginLeft: "auto",
                        marginRight: "auto",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        zIndex: 6000,
                        paddingBottom: 10,
                        overflowY: "auto"
                      }}
                    >
                      {searchKeyword.trim().length === 0 &&
                        recentSearches.length > 0 ? (
                        <Fragment>
                          <Box
                            style={{
                              paddingLeft: 11,
                              paddingRight: 30,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              height: 57,
                              borderBottom: "1px solid #EBEEF0"
                            }}
                          >
                            <Typography
                              style={{
                                color: "#0F1419",
                                fontSize: "20px",
                                fontWeight: "normal"
                              }}
                            >
                              {t("Recent")}
                            </Typography>
                            <Button
                              onClick={() => this.clearAllRecentSearch()}
                              variant="text"
                              style={{
                                color: "#F14E24",
                                fontSize: "13px",
                                fontWeight: "bold"
                              }}
                            >
                              {t("Clear all")}
                            </Button>
                          </Box>

                          {recentSearches.map((search) => (
                            <Box
                              key={search.account?.data?.id}
                              style={{
                                paddingLeft: 11,
                                paddingRight: 30,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: 68,
                                borderBottom: "1px solid #EBEEF0"
                              }}
                            >
                              <Box
                                onClick={() => {
                                  window.location = ("/user-profile/" +
                                    search.recent_search.data.attributes
                                      .search_account_id) as any;
                                }}
                                display="flex"
                                alignItems="center"
                                style={{ cursor: "pointer" }}
                              >
                                <Avatar
                                  src={search.account?.data?.attributes?.profile_picture}
                                  style={{ height: 40, width: 40 }}
                                />
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  ml="12px"
                                >
                                  <Typography
                                    style={{
                                      color: "#0F1419",
                                      fontSize: "15px",
                                      fontWeight: "normal"
                                    }}
                                  >
                                    {search.account?.data?.attributes?.first_name}
                                    {search?.account?.data?.attributes?.verified && (
                                        <img
                                          src={verify}
                                          style={{ height: 13, marginLeft: "5px" }}
                                        />
                                      )}
                                  </Typography>
                                  <Typography
                                    style={{
                                      color: "#5B7083",
                                      fontSize: "15px",
                                      fontWeight: "normal"
                                    }}
                                  >
                                    @{search.account?.data?.attributes?.user_name}
                                  </Typography>
                                </Box>
                              </Box>
                              <IconButton
                                style={{ zIndex: 6010 }}
                                onClick={() =>
                                  this.removeRecentSearch(
                                    search.recent_search?.data?.id
                                  )
                                }
                                variant="text"
                              >
                                <Clear
                                  style={{
                                    height: 20,
                                    width: 20,
                                    color: "#F14E24"
                                  }}
                                />
                              </IconButton>
                            </Box>
                          ))}
                        </Fragment>
                      ) : (
                        searchKeyword.trim().length === 0 && (
                          <Box
                            style={{
                              display: "flex",
                              width: "100%",
                              height: 68,
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <Typography
                              style={{
                                color: "#0F1419",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {t("No results")}
                            </Typography>
                          </Box>
                        )
                      )}

                      {searchKeyword.trim().length > 0 &&
                        searchResults.length > 0 ? (
                        <Fragment>
                          {searchResults.map((search) => (
                            <Box
                              onClick={() =>
                                this.handleCreateRecentSearch(search.id)
                              }
                              key={search.id}
                              style={{
                                paddingLeft: 11,
                                paddingRight: 30,
                                display: "flex",
                                alignItems: "center",
                                height: 68,
                                borderBottom: "1px solid #EBEEF0",
                                paddingTop: 10,
                                paddingBottom: 10,
                                marginTop: 10,
                                cursor: "pointer"
                              }}
                            >
                              <Avatar
                                src={search.attributes.profile_picture}
                                style={{ height: 40, width: 40 }}
                              />
                              <Box
                                display="flex"
                                flexDirection="column"
                                ml="12px"
                              >
                                <Typography
                                  style={{
                                    color: "#0F1419",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontFamily: "Manrope"
                                  }}
                                >
                                  {search.attributes.first_name}
                                </Typography>
                                <Typography
                                  style={{
                                    color: "#5B7083",
                                    fontSize: "15px",
                                    fontWeight: "normal",
                                    fontFamily: "Manrope"
                                  }}
                                >
                                  @{search.attributes.user_name}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Fragment>
                      ) : (
                        searchKeyword.trim().length > 0 && (
                          <Box
                            style={{
                              display: "flex",
                              width: "100%",
                              height: 68,
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <Typography
                              style={{
                                color: "#0F1419",
                                fontSize: "14px",
                                fontWeight: "normal"
                              }}
                            >
                              {t("No results")}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  )}

                  {this.state.categoryProfiles.length > 0 &&
                    this.state.categoryProfiles.map((profile) => (
                      <Grid className={classes.showmoreprofiles}
                        key={profile.id}
                        container
                        style={{
                          // padding: "15px",
                          // borderBottom: "1px solid rgb(222, 221, 218)"
                        }}
                      >
                        <Grid
                          onClick={() => {
                            window.location = ("/user-profile/" +
                              profile.id) as any;
                          }}
                          md={2}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            cursor: "pointer"
                          }}
                        >
                          <Avatar
                            src={profile.attributes.profile_picture}
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "6px"
                            }}
                          />
                        </Grid>
                        <Grid
                          md={5}
                          onClick={() => {
                            window.location = ("/user-profile/" +
                              profile.id) as any;
                          }}
                          style={{
                            cursor: "pointer"
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "1.3rem",
                              fontWeight: "600",
                              color: "#150050",
                              marginBottom: "4px"
                            }}
                          >
                            {/* <img
                  src={FlagImage}
                  style={{ height: "15px", marginRight: "10px" }}
                /> */}
                            {profile.attributes.first_name}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "1.1rem",
                              color: "#c3c3c3",
                              fontWeight: "400",
                              marginBottom: "5px"
                            }}
                          >
                            {`@${profile.attributes.user_name}`}
                          </Typography>
                          {/* <Typography
                style={{
                  fontSize: "1.2rem",
                  color: "#F14E24",
                  fontWeight: "bold",
                  marginBottom: "3px"
                }}
              >
                Player
                <span style={{ color: "#c3c3c3", paddingLeft: "5px" }}>
                  Free Fire
                </span>
              </Typography> */}
                          <Typography className={classes.leadText}>
                            {profile.attributes.about
                              ? `@${profile.attributes.about}`
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          md={5}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end"
                          }}
                        >
                          {profile.attributes.status?.data?.follow_status?.toLowerCase() ===
                            "following" ? (
                            <Button
                              onClick={() => this.unfollowAccount(profile.id)}
                              style={{
                                backgroundColor: "#F14E24",
                                border: "1px solid #D9DBE9",
                                borderRadius: 16,
                                height: 35,
                                boxShadow: "none",
                                color: "#F7F7FC",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                padding: "10px 20px",
                                marginRight: "10px"
                              }}
                            >
                              {t("Unfollow")}
                            </Button>
                          ) : profile.attributes.status?.data && (

                            <Button
                              onClick={() => this.followAccount(profile.id)}
                              style={{
                                backgroundColor: "#F14E24",
                                border: "1px solid #D9DBE9",
                                borderRadius: 16,
                                height: 35,
                                boxShadow: "none",
                                color: "#F7F7FC",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                padding: "10px 20px",
                                marginRight: "10px"
                              }}
                            >
                              {t(profile.attributes.status?.data?.follow_status)}
                            </Button>
                          )}

                          {profile.attributes.status?.data && (
                            <Button
                              onClick={() => {
                                if (profile.attributes.status?.data?.connect_status?.toLowerCase() === 'connect') {
                                  this.connectAccount(profile.id)
                                } else {
                                  this.disconnectAccount(profile.id)
                                }
                              }}
                              disabled={
                                !profile.attributes.status?.data?.connect_status?.toLowerCase().includes('connect')
                              }
                              style={{
                                backgroundColor: "#fff",
                                border: "1px solid #F14E24",
                                borderRadius: 16,
                                height: 35,
                                boxShadow: "none",
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                padding: "10px 20px"
                              }}
                            >
                              {t(profile.attributes.status?.data?.connect_status.toLowerCase() === 'connected' ? 'Disconnect' : profile.attributes.status?.data?.connect_status)}
                            </Button>
                          )}


                        </Grid>
                      </Grid>
                    ))}
                </Box>
              </Grid>
              <Grid xs={12} sm={4}>
                <Link
                  to="/profile-dashboard"
                  style={{
                    textDecoration: "none"
                  }}
                >
                  <Box style={{ padding: "30px" }}>
                    {/* <img src={ProgressImage} style={{width:"100%",}}/> */}
                    <Box
                      style={{
                        background:
                          "linear-gradient(to right, #03A1A4 0%, #99E7D1 100%)",
                        padding: "15px",
                        borderRadius: "15px"
                      }}
                    >
                      <Typography
                        style={{
                          color: "#ffffff",
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          fontSize: 14,
                          paddingBottom: "10px"
                        }}
                      >
                        {t("Complete your profile today")}
                      </Typography>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          totalTasks > 0
                            ? Math.ceil((completedTasks / totalTasks) * 100)
                            : 0
                        }
                      />

                      <Typography
                        style={{
                          color: "#ffffff",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 12,
                          paddingTop: "10px"
                        }}
                      >
                        {completedTasks}/{totalTasks} {t("done")}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Snackbar
          open={Boolean(error)}
          onClose={() => this.setState({ error: null })}
          message={error || "N/A"}
          severity="error"
        />
        <Loader loading={loading} />

        <Backdrop
          style={{
            zIndex: 5000,
            color: "transparent",
            backgroundColor: "transparent"
          }}
          open={showSearchResults}
          onClick={() => {
            this.toggleShowSearchResults(false);
          }}
        />
      </>
    );
  }
  componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    this.fetchDiscoveryData();
    this.fetchCategories();
    this.fetchCompletedProfileDetails();
    this.fetchRecentSearch();

  }
}
const DiscoveryStyle = {
  indicator: {
    background: "none"
  },

  mainWrapper: {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    }
  },
  mainBodyContent: {
    height: "100vh",
    marginTop: 14,
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10
    }
  },
  tabSecond: {
    fontSize: 17,
    fontFamily: "Poppins",
    padding: 15,
    minWidth: "100%",
    height: 100,
    background: "black",
    color: "white",
    textTransform: "none",
    letterSpacing: 1,
    opacity: 1
    // "&:hover": {
    //   background: "black",
    //   color: "white"
    // }
  },
  tab1: {
    fontSize: 17,
    fontFamily: "Poppins",
    padding: 15,
    minWidth: "100%",
    height: 100,
    textTransform: "none",
    background: "rgb(247, 247, 252)",
    "& .PrivateTabIndicator-colorSecondary-54": {
      background: "#fff"
    }
  },
  completeProfile: {
    "@media (max-width: 768px)": {
      width: "360% !important",
    },
    background: "rgb(247, 247, 252)",
  },
  textBlock: {
    "@media (max-width: 767px)": {
      paddingLeft: 15,
    },
  },
  centerWrapper: {
    borderTop: "none",
    height: "100%",
    "@media (max-width: 768px)": {
      order: "2",
    },
  },
  rightBlock: {
    "@media (max-width: 767px)": {
      width: "100%",
    },
  },
  leadText: {
    fontSize: "1.2rem",
    color: "#c3c3c3",
    maxHeight: "17px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "280px",
    textOverflow: "ellipsis",
    fontWeight: "400",
    marginBottom: 5,
    marginTop: 5,
    "@media (max-width: 767px)": {
      width: "150px",
    },
  },
  showmoreprofiles: {
    "@media (max-width: 767px)": {
      // flexDirection:"column",
      // alignItems:"flex-start",
    },
    padding: "15px",
    borderBottom: "1px solid #deddda",
    "&:last-child": {
      borderBottom: "0",
    },
  },

};

export default withTranslation()(withStyles(DiscoveryStyle)(withRouter(Discovery)));
// export default Discovery;
