import React from "react";
import { Typography } from "@material-ui/core";

export default function Copyright() {
  return (
    <Typography
      style={{
        color: "#626262",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: 10
      }}
    >
      {`© 2019 - ${new Date().getFullYear()} yup® PTE Ltd.`}
    </Typography>
  );
}
