//@ts-nocheck
import {
  Avatar, Box, Button, Dialog,
  DialogActions,
  DialogTitle, Grid, IconButton, Popover, Typography
} from "@material-ui/core";
import { Add, MoreHoriz } from "@material-ui/icons";
import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import { verticalDots } from "./assets";
import ClientsController, { Props } from "./ClientsController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";
class ClientsList extends ClientsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation, t , i18n } = this.props;
    const { clients } = this.state;
    return (
      <>
        <ProfileHeader
          heading={t("Clients")}
          subModule={true}
          showProfile={true}
        />
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>

          <Grid
            sm={12}
            md={6}
            style={{
              border: "1px solid #DEDDDA",
              borderTop: "none",
              height: "100%"
            }}
          >
          <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                onClick={() => {
                  localStorage.removeItem("clientDetails");
                  this.props.history.push("/clients/create");
                }}
                style={{
                  padding: 10,
                  cursor: "pointer",
                  minHeight: 127,
                  width: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: 43,
                  borderRadius: 16,
                  border: "1px solid #DBDBDB",
                  backgroundImage: "linear-gradient(to right, rgb(241, 78, 36), rgb(195, 0, 82))",
                }}
              >
                <Typography
                  style={{
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: 22
                  }}
                >
                  {t("Add New Client")}
                </Typography>
                <Add
                  style={{
                    marginTop: 5,
                    color:"#fff",
                    height: 24,
                    width: 24
                  }}
                />
              </Box>
              <Box display="flex" width="80%">
                <Typography
                  style={{
                    color: "#8E8E8E",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 14,
                    marginTop: 24,
                    marginBottom: 15
                  }}
                >
                  {t("Search for the profile of clients you've worked with and add them.")}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                flexWrap="wrap"
                width="80%"
              >
                {clients.map((client) => (
                  <Box
                    key={client.id}
                    display="flex"
                    style={{
                      height: "auto",
                      marginBottom: 7,
                      position: "relative"
                    }}
                  >
                    <Box
                      style={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        zIndex: 10
                      }}
                    >
                      <IconButton
                        style={{backgroundColor:'transparent'}}
                        onClick={(e) => this.handlePopoverOpen(e, client.id)}
                      >
                        <MoreHoriz style={{ width: 24, height: 24, color: "black" }} />
                      </IconButton>

                      <Popover
                        open={this.state.openedPopoverId === client.id}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left"
                        }}
                      >
                        <Box display="flex" flexDirection="column">
                          <Button
                            onClick={() => {
                              localStorage.setItem(
                                "clientDetails",
                                JSON.stringify({
                                  id: client.id,
                                  clientName: client.attributes.client_name,
                                  caseStudyName: JSON.stringify({
                                    id: client.attributes.case_study.id,
                                    attributes: {
                                      ...client.attributes.case_study
                                    }
                                  }),
                                  category: "",
                                  isCurrentlyWorking:
                                    client.attributes.i_currently_work_with,
                                  startMonth: JSON.stringify(this.Months[client.attributes
                                    .i_currently_work_with
                                    ? new Date()
                                        .getMonth()
                                    : new Date(client.attributes.start_date)
                                        .getMonth()]),
                                  startYear: new Date(
                                    client.attributes.start_date
                                  )
                                    .getFullYear()
                                    .toString(),
                                  endMonth: JSON.stringify(this.Months[client.attributes
                                    .i_currently_work_with
                                    ? new Date()
                                        .getMonth()
                                    : new Date(client.attributes.end_date)
                                        .getMonth()]),
                                  endYear: client.attributes
                                    .i_currently_work_in_this_company
                                    ? new Date().getFullYear().toString()
                                    : new Date(client.attributes.end_date)
                                        .getFullYear()
                                        .toString(),
                                  logo: "",
                                  logoUrl: client.attributes.client_photo ?? ""
                                })
                              );
                              this.props.history.push("/clients/create");
                            }}
                            variant="text"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 12,
                              textAlign: "left",
                              justifyContent: "flex-start"
                            }}
                          >
                            {t("Edit")}
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({
                                showDialog: true,
                                clientId: client.id
                              });
                            }}
                            variant="text"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 12,
                              textAlign: "left",
                              justifyContent: "flex-start"
                            }}
                          >
                            {t("Delete")}
                          </Button>
                        </Box>
                      </Popover>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: 105,
                        width: 34,
                        backgroundColor: "#EFF0F6",
                        borderRadius: "16px 0px 0px 16px"
                      }}
                    >
                      <img
                        src={verticalDots}
                        style={{ width: 24, height: 24 }}
                      />
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minHeight: 105,
                        width: "100%",
                        backgroundColor: "#F7F7FC",
                        borderRadius: "0px 16px 16px 0px",
                        padding: "22px 20px"
                      }}
                    >
                      <Avatar
                        src={client.attributes.client_photo}
                        style={{ width: 48, height: 48, marginRight: 35 }}
                      />
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            color: "#081F32",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: 18
                          }}
                        >
                          {client.attributes.case_study.title}
                        </Typography>
                        <Typography
                          style={{
                            color: "#6E798C",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: 10,
                            marginTop: 4,
                            marginBottom: 2
                          }}
                        >
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 10
                            }}
                            component="span"
                          >
                            {client.attributes.client_name}
                          </Typography>{" "}
                          {/* · Shanghai, China */}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid sm={12} md={4}>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        <Loader loading={this.state.loading} />
        <CatchInvalidToken
              onClose={() => this.setState({ error: null })}
              message={this.state.error || "N/A"}
            />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`${t("Are you sure you want to delete this client?")}`}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => this.setState({ showDialog: false, clientId: null })}
              variant="text"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => this.deleteClient(this.state.clientId)}
              variant="text"
              autoFocus
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  async componentDidMount() {
    this.getAllClients();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
// Customizable Area End

export default withTranslation()(withRouter(ClientsList as React.ComponentType<any>))
