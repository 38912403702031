//@ts-ignore
//@ts-nocheck
import { Link, Typography, Button, Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import ProfileHeader from '../../user-profile-basic/src/ProfileHeader';
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import { Formik, Form, Field } from "formik";
import SubSideMenu from "./SubSideMenu.web";
import SettingController from './SettingController.web';
import Select, { components } from 'react-select';
import { withTranslation } from 'react-i18next';
import Snackbar from "../../../components/src/Snackbar";
import ConfirmationModal from './ConfirmationModal.web';

class EditProfile extends SettingController {
  constructor(props: Props) {
    super(props);
  }

  toggleMainMenu = () => {
    this.setState({ toggleSideMenu: false })
  }

  componentDidMount() {
    this.getUserData()
    this.toggleMainMenu()
    const language = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(language);
    if(language){
      this.setState({language})
    }
  }


  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleModal = () => {
    this.setState({ isModal: !this.state.isModal})
  };

  render() {
    const { classes, t , i18n } = this.props
    const { userData, loading, isModal } = this.state

    const genders = [
      { value: "male", label: `${t("Male")}` },
      { value: "female", label: `${t("Female")}` },
      { value: "other", label: `${t("prefer not to say")}` },
    ]
    const lang = [
      { value: "en", label: "English" },
      { value: "br", label: "Portuguese" },
      { value: "kr", label: "Korean" },
      { value: "th", label: "Thai" },
      { value: "id", label: "Bahasa" },
    ]

    const handleLangChange = evt => {
      const lang = evt;
      this.setState({language:lang});
      localStorage.setItem("langauge", lang)
      i18n.changeLanguage(lang);
      this.setLanguage(lang)
    };

    let phoneNumber
    if (userData?.phone_number) { 
      phoneNumber = `+${userData?.country_code}` +" "+ `${userData?.phone_number}`
    } else if (userData?.full_phone_number) {
      phoneNumber = userData?.full_phone_number
    } 
    else phoneNumber = ""

    return (
      <>
        <ProfileHeader heading={t("Edit Profile")} subModule="3" />
        <Grid container style={{ paddingBottom: 0, height: "100%" }}>
          <Grid item sm={3} xs={12} style={{ position: "relative" }}>
            <SideMenu subModule="3" />
          </Grid>
          <Grid item xs={2} className={classes.settingIcon}>
            <SubSideMenu handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen}/>
          </Grid>
          <Grid item xs={12} sm={7} >
            <div className={classes.formContainer} >
            {loading ? 
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress style={{ color: "#F14E24" }} />
            </Box>
            :
              <Formik
                initialValues={{
                  userName: userData?.user_name || "",
                  name: userData?.first_name || "",
                  email: userData?.email || "",
                  phoneNumber: phoneNumber,
                  gender: userData?.gender || "",
                }}
                validationSchema={this.checkUserInfo(t)}
                validateOnMount={true}
                onSubmit={(values) => {
                  this.editProfile(values)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue
                }) => (
                  <Form
                    translate="yes"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography style={{ textAlign: "right", color: "rgb(123 118 118)", fontWeight: "700", fontSize: "16px", padding: "9px 0px",fontFamily:"Poppins" }}>{t("Username")}</Typography>
                      </Grid>
                      <Grid item xs={10} className={classes.fieldBlock}  >

                        <Field
                          name="userName"
                          placeholder={t("Username")}
                          className={classes.field}
                        />
                        {errors.userName && touched.userName ? (
                          <Typography className={classes.errorText}>{errors.userName}</Typography>
                        ) : null}
                        <Typography className={classes.subText}>
                          {t("In most cases, you'll be able to change your username back to %user% for another 14 days.")}
                        </Typography>
                        <Typography className={classes.professionalText}>
                          {t("Personal Information")}
                        </Typography>
                        <Typography className={classes.sub1Text}>
                          {t("Provide your personal information, even if the account is used for a business or something else. This won't be a part of your public profile.")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{padding: "10px 0px"}}>
                      <Grid item xs={2}>
                        <Typography style={{ textAlign: "right", color: "rgb(123 118 118)", fontWeight: "700", fontSize: "15px", padding: "9px 0px",fontFamily:"Poppins" }}>{t("Legal name")}</Typography>
                      </Grid>
                      <Grid item xs={10} className={classes.fieldBlock} >
                        <Field
                          name="name"
                          placeholder={t("Legal name")}
                          className={classes.field}
                        />
                        {errors.name && touched.name ? (
                          <Typography className={classes.errorText}>{errors.name}</Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{padding: "10px 0px"}}>
                      <Grid item xs={2}>

                        <Typography style={{ textAlign: "right", color: "rgb(123 118 118)", fontWeight: "700", fontSize: "16px", padding: "9px 0px",fontFamily:"Poppins" }}>{t("Email")}</Typography>
                      </Grid>
                      <Grid item xs={10} className={classes.fieldBlock} >
                        <Field
                          name="email"
                          placeholder={t("Email")}
                          className={classes.field}
                          disabled={userData?.email}
                        />
                        {errors.email && touched.email ? (
                          <Typography className={classes.errorText}>{errors.email}</Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{padding: "10px 0px"}}>
                      <Grid item xs={2}>

                        <Typography style={{ textAlign: "right", color: "rgb(123 118 118)", fontWeight: "700", fontSize: "16px", padding: "9px 0px",fontFamily:"Poppins" }}>{t("Phone")}</Typography>
                      </Grid>
                      <Grid item xs={10} className={classes.fieldBlock} >
                        <Field
                          name="phoneNumber"
                          placeholder={t("Phone number")}
                          className={classes.field}
                        />
                        {errors.phoneNumber && touched.phoneNumber ? (
                          <Typography className={classes.errorText}>{errors.phoneNumber}</Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{padding: "10px 0px"}}> 
                      <Grid item xs={2}>
                        <Typography style={{ textAlign: "right", color: "rgb(123 118 118)", fontWeight: "700", fontSize: "16px", padding: "9px 0px",fontFamily:"Poppins" }}>{t("Gender")}</Typography>
                      </Grid>
                      <Grid item xs={10} className={classes.fieldBlock} >
                          <Select
                          styles={selectStyles}
                          isSearchable={true}
                          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                          placeholder={t("Gender")}
                          defaultValue={genders.find(({ value }) => value == values.gender)}
                          name="gender"
                          onChange={
                            (option: any) => {setFieldValue('gender', option.value)
                            setFieldValue("team", option.label)
                          }
                          }
                          options={genders}
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "hsl(0, 0%, 80%)",
                            }
                          })}
                        />
                        {errors.gender && touched.gender ? (
                          <Typography className={classes.errorText}>{errors.gender}</Typography>
                        ) : null}
                        
                        
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{padding: "10px 0px"}}>
                      <Grid item xs={2}>
                        <Typography style={{ textAlign: "right", color: "rgb(123 118 118)", fontWeight: "700", fontSize: "16px", padding: "9px 0px",fontFamily:"Poppins" }}>{t("Languages")}</Typography>
                      </Grid>
                      <Grid item xs={10} className={classes.fieldBlock} >
                          <Select
                          styles={selectStyles}
                          isSearchable={true}
                          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                          placeholder={t("language")}
                          onChange={handleLangChange} 
                          defaultValue={lang.find(({ value }) => value == this.state.language)}
                          name="language"
                          onChange={
                            (option: any) => {handleLangChange(option.value)
                            setFieldValue("language", option.label)
                            }
                          }
                          
                          options={lang}  
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "hsl(0, 0%, 80%)",
                            }
                          })}
                        />   
                        <Grid container spacing={2} className={classes.buttonContainer}>
                          <Grid item xs={10} sm={3}>
                            <Button className={ classes.btn } type="submit">{t("Submit")}</Button>
                            <Typography className={classes.disableText} onClick={this.handleModal}>
                              {t("Temporarily disable my account")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              }
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        <ConfirmationModal isModal={isModal} handleModal={this.handleModal} deleteAccount={this.deleteAccount}/>
      </>
    )
  }
}

const EditProfileStyle = {
  field: {
    border: "2px solid #DBDBDB",
    height: 40,
    width: "80%",
    color: "#bbb6b6",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 16,
    paddingRight: 16,
    outline: "none",
    borderRadius: 30,
    '::-webkit-input-placeholder': {
      color: "#bbb6b6",
    },
    "@media (max-width: 768px)": {
      width: "100%",
    }
  },
  fieldBlock: {
    paddingLeft: 25,
    "@media (max-width: 768px)": {
      paddingLeft: "35px !important",
    }
  },
  settingIcon: {
    borderLeft: "1px solid rgba(128, 128, 128, 0.26)", 
    borderRight: "1px solid rgba(128, 128, 128, 0.26)",
    "@media (max-width: 768px)": {
      borderLeft: "none",
      borderRight: "none",
      position: "absolute",
      right: "40px",
      top: "16px",
    }
  },
  formContainer: {
    padding: "50px 50px 0 50px", 
    position: "relative",
    "@media (max-width: 768px)": {
      marginTop: 15,
      padding: "45px 17px 0",
    }
  },
  heading: {
    fontSize: 18,
    fontFamily: "Poppins",
    paddingTop: 5,
  },
  subText: {
    fontSize: 14,
    color: "#DBDBDB",
    fontFamily: "Poppins",
    paddingTop: 15,
    fontWeight: 500,
    width: "80%",
    "@media (max-width: 768px)": {
      width: "100%",
    }
  },
  professionalText: {
    fontSize: 17,
    color: "#aba6a6",
    fontFamily: "Poppins",
    paddingTop: 50,
    fontWeight: 600,
    width: "80%",
    "@media (max-width: 768px)": {
      width: "100%",
    }
  },
  sub1Text: {
    fontSize: 14,
    color: "#DBDBDB",
    fontFamily: "Poppins",
    paddingTop: 10,
    paddingBottom: 20,
    fontWeight: 500,
    width: "80%",
    "@media (max-width: 768px)": {
      width: "100%",
    }
  },
  btn: {
    marginTop: 25,
    fontSize: 14,
    color: "white",
    borderRadius: 30,
    padding: "5px 30px",
    background: "#F14E24",
    fontFamily:"Poppins",
    "&:hover": {
      backgroundColor: '#F14E24',
      color: "white",
    },
    "@media (max-width: 768px)": {
      marginBottom: 0
    }
  },
  errorText: {
    color: "#F14E24",
    fontFamily: "Poppins",
    fontWeight: 300,
    fontSize: 12,
    marginTop: 5,
    marginBottom: 15,
  },
  disableText: {
    width: "max-content",
    color: "#9c9897",
    fontSize: 14,
    fontWeight: 700,
    paddingTop: 25,
    fontFamily: "Poppins",
    cursor: "pointer",
    "@media (max-width: 768px)": {
      textAlign: "left",
    }
  },
  buttonContainer: {
    width: "100%",
    padding: "10px 0px",
    "@media (max-width: 768px)": {
      width: "100%",
      paddingLeft: "0",
    }
  },
}

const selectStyles = {
  control: (provided: any, state: any) => {
    return {
      ...provided,
      height: 40,
      position: "aboslute",
      borderRadius: 30,
      border: "2px solid #DBDBDB",
      width: "80%",
      "@media (max-width: 768px)": {
        width: "100%",
      },
      '&:hover': {
        border: "2px solid #DBDBDB",
      }
    }
  },
  indicatorSeparator: (provided: any, state: any) => {
    return {
      ...provided,
      display: "none",
    }
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      position: "unset",
      width: "80%",
      "@media (max-width: 768px)": {
        width: "100% !important",
      }
    }
  },
  menuList: (provided: any, state: any) => {
    return {
      ...provided,
      maxHeight: 200,
    }
  },
  placeholder: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: 17,
      fontFamily: "Poppins",
      color: "rgb(128 128 128 / 68%)",
    }
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      position: "absolute",
      marginTop: 9,
      fontSize: 22,
      color: "#bbb6b6",
    }
  },
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      paddingLeft: 16
    }
  },
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      color: "#bbb6b6",
    }
  },
}

export default withTranslation()(withStyles(EditProfileStyle)(EditProfile))