//@ts-nocheck
//@ts-ignore
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import {
  Box,
  Link,
  Typography,
  Button,
  Checkbox,
  IconButton
} from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { CheckBox, Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";
import { withTranslation } from "react-i18next";

import Snackbar from "../../../components/src/Snackbar";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";

import { logo, profileIcon, checkboxIcon, mailIcon, starImage } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import Copyright from "../../../components/src/Copyright";

class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="85%"
          pt="22px"
        >
          <img
            style={{
              width: isWidthUp("sm", this.props.width) ? 96 : 45,
              height: "auto"
            }}
            src={logo}
            onClick={() => {
              this.props.history.push("/");
            }}
          />
          <Box display="flex" alignItems="center">
            <Typography
              style={{
                fontSize: 16,
                color: "#4E4B66",
                fontFamily: "Poppins",
                fontWeight: 400
              }}
            >
              {t("Already have an account")}
            </Typography>
            <Link
              href="/login"
              underline="none"
              style={{
                fontSize: 14,
                color: "#F14E24",
                fontFamily: "Roboto",
                fontWeight: 500,
                marginLeft: 5,
                textTransform: "uppercase"
              }}
            >
              {t("Sign in")}
            </Link>
          </Box>
        </Box>

        <Box
          display="flex"
          mt="50px"
          mb="27px"
          width={isWidthUp("sm", this.props.width) ? 494 : 363}
          justifyContent="center"
        >
          <Typography
            style={{
              fontSize: 26,
              color: "#14142B",
              fontFamily: "Manrope",
              fontWeight: 800
            }}
          >
            {t("Create your account")}
          </Typography>
          <img
            src={starImage}
            style={{
              position: "absolute",
              height: 45,
              width: isWidthUp("sm", this.props.width) ? 494 : 363
            }}
          />
        </Box>

        <Formik
          initialValues={{
            first_name: "",
            email: "",
            password: "",
            isSubscribe: false,
            termsAndConditions: false,
            showPassword: false
          }}
          validationSchema={this.EmailSignupSchema(t)}
          validateOnMount={true}
          onSubmit={(values) => {
            if (values.termsAndConditions) {
              this.createAccount(values);
            } else {
              this.setState({ error: `${t("Please accept user agreement")}` });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleChange,
            setFieldValue
          }) => (
            <Form translate="yes" style={{ width: 363 }}>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  overflow="hidden"
                  alignItems="center"
                  height="56px"
                  border="1px solid #D1D1D1"
                  borderRadius="16px"
                  bgcolor="white"
                >
                  <Field
                    name="first_name"
                    placeholder={t("Name")}
                    style={{
                      border: "none",
                      height: "100%",
                      width: "80%",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 16,
                      marginRight: 10,
                      marginLeft: 21,
                      outline: "none"
                    }}
                  />
                  <img
                    src={profileIcon}
                    style={{ width: 24, height: 24, marginRight: 16 }}
                  />
                </Box>
                {errors.first_name && touched.first_name ? (
                  <Typography
                    style={{
                      color: "#F14E24",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: 14,
                      marginTop: 5,
                      marginLeft: 10
                    }}
                  >
                    {errors.first_name}
                  </Typography>
                ) : null}

                <Box
                  display="flex"
                  overflow="hidden"
                  alignItems="center"
                  height="56px"
                  mt="20px"
                  border="1px solid #D1D1D1"
                  borderRadius="16px"
                  bgcolor="white"
                >
                  <Field
                    name="email"
                    placeholder={t("Email")}
                    style={{
                      border: "none",
                      height: "100%",
                      width: "80%",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 16,
                      marginRight: 10,
                      marginLeft: 21,
                      outline: "none"
                    }}
                  />
                  <img
                    src={mailIcon}
                    style={{ width: 24, height: 24, marginRight: 16 }}
                  />
                </Box>
                {errors.email && touched.email ? (
                  <Typography
                    style={{
                      color: "#F14E24",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: 14,
                      marginTop: 5,
                      marginLeft: 10
                    }}
                  >
                    {errors.email}
                  </Typography>
                ) : null}
                <Box
                  display="flex"
                  overflow="hidden"
                  alignItems="center"
                  height="56px"
                  mt="20px"
                  border="1px solid #D1D1D1"
                  borderRadius="16px"
                  bgcolor="white"
                >
                  <Field
                    name="password"
                    placeholder={t("Password")}
                    type={values.showPassword ? "text" : "password"}
                    style={{
                      border: "none",
                      height: "100%",
                      width: "80%",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 16,
                      marginRight: 10,
                      marginLeft: 21,
                      outline: "none"
                    }}
                  />
                  {values.showPassword ? (
                    <IconButton
                      onClick={() => setFieldValue("showPassword", false)}
                      style={{ padding: 0, backgroundColor: "transparent" }}
                      disableRipple={true}
                    >
                      <Visibility
                        style={{
                          width: 24,
                          height: 24,
                          marginRight: 16,
                          color: "#000000",
                          opacity: 0.54
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => setFieldValue("showPassword", true)}
                      style={{ padding: 0, backgroundColor: "transparent" }}
                      disableRipple={true}
                    >
                      <VisibilityOff
                        style={{
                          width: 24,
                          height: 24,
                          marginRight: 16,
                          color: "#000000",
                          opacity: 0.54
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
                {errors.password && touched.password ? (
                  <Typography
                    style={{
                      color: "#F14E24",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      fontSize: 14,
                      marginTop: 5,
                      marginLeft: 10
                    }}
                  >
                    {errors.password}
                  </Typography>
                ) : null}

                <Box display="flex" mt="25px" alignItems="flex-start">
                  <Checkbox
                    checked={values.isSubscribe}
                    onChange={handleChange("isSubscribe")}
                    style={{ padding: 0, marginRight: 4, marginTop: 4 }}
                    checkedIcon={
                      <CheckBox
                        style={{
                          color: "#F14E24",
                          width: 16,
                          height: 16,
                          padding: 0,
                          margin: 0
                        }}
                      />
                    }
                    icon={
                      <img
                        src={checkboxIcon}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                  />
                  <Typography
                    style={{
                      color: "#A0A3BD",
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: 12
                    }}
                  >
                    {t(
                      "Yes! Send me genuinely useful email every now and then to help me get the most of yup."
                    )}
                  </Typography>
                </Box>

                <Box display="flex" mt="25px" alignItems="flex-start">
                  <Checkbox
                    checked={values.termsAndConditions}
                    onChange={handleChange("termsAndConditions")}
                    style={{ padding: 0, marginRight: 4, marginTop: 4 }}
                    checkedIcon={
                      <CheckBox
                        style={{
                          color: "#F14E24",
                          width: 16,
                          height: 16,
                          padding: 0,
                          margin: 0
                        }}
                      />
                    }
                    icon={
                      <img
                        src={checkboxIcon}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                  />
                  <Typography
                    style={{
                      color: "#A0A3BD",
                      fontFamily: "Poppins",
                      fontWeight: "normal",
                      fontSize: 12
                    }}
                  >
                    {t("Yes, I understand and agree to the")}{" "}
                    <Link
                      target="_blank"
                      href="/user-agreement"
                      underline="none"
                      style={{ color: "#F14E24" }}
                    >
                      {t("Terms of Service")} ,
                    </Link>{" "}
                    <Link
                      target="_blank"
                      href="/privacy-policy"
                      underline="none"
                      style={{ color: "#F14E24" }}
                    >
                      {t("Privacy Policy")}
                    </Link>
                    .
                  </Typography>
                </Box>

                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    backgroundColor: "#F14E24",
                    border: "1px solid #D9DBE9",
                    borderRadius: 16,
                    height: 34,
                    marginBottom: 14,
                    boxShadow: "none",
                    color: "#F7F7FC",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: 16,
                    marginTop: 30
                  }}
                >
                  {t("Continue")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>

        <Box mt="315px" mb="100px">
        <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />

        <CatchInvalidToken
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
        />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
    this.checkUsernameToken();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});

export default withTranslation()(
  withWidth()(withRouter(EmailAccountRegistration as React.ComponentType<any>))
);
