//@ts-ignore
//@ts-nocheck
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import { Box, Typography } from "@material-ui/core";

import Snackbar from "../../../components/src/Snackbar";
import { logo, starImage } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import Copyright from "../../../components/src/Copyright";

class PrivacyPolicy extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="85%"
          pt="22px"
        >
          <img
            style={{ width: 96, height: "auto" }}
            src={logo}
            onClick={() => {
              this.props.history.push("/");
            }}
          />
        </Box>

        <Box
          display="flex"
          mt="50px"
          mb="27px"
          width="494px"
          justifyContent="center"
        >
          <Typography
            style={{
              fontSize: 26,
              color: "#14142B",
              fontFamily: "Manrope",
              fontWeight: 800
            }}
          >
            Privacy Policy
          </Typography>
          <img
            src={starImage}
            style={{ position: "absolute", height: 45, width: 494 }}
          />
        </Box>
        <Box
          style={{
            paddingLeft: "2.5rem",
            paddingRight: "2.5rem",
            background: "#F2F2F2"
          }}
        >
          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Introduction
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
              This Privacy Policy (this “<strong>Policy</strong>”) describes how
              Yup Pte. Ltd., its respective subsidiaries, affiliates, associated
              companies and jointly controlled entities (collectively referred
              to as “<strong>Yup</strong>”, “<strong>we</strong>”, “
              <strong>us</strong>” or “<strong>our</strong>”) collect, use and
              disclose your Personal Data (as defined below), in connection with
              your use of yup.gg (the “<strong>Site</strong>”), any of our
              applications, services, products or tools, and any other service
              that states that they are offered under our User Agreement
              (collectively the “<strong>Services</strong>”), regardless of how
              you access or use these Services (including access via mobile
              devices and apps). Your use of the Site or any the Services shall
              constitute your acceptance of this Policy, to the extent permitted
              by law.
            </Typography>
          </Box>
          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Collection of Personal Data
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
              “<strong>Personal</strong> Data” is any information which can be used to 
              identify you or from which you are identifiable.<br/>
              We collect your Personal Data when you use our Services, provide us with information
              via a web form, add or update information in your Yup account, or otherwise interact 
              with us. We also collect Personal Data from other sources, such as the social 
              networks with whom you already have an account.<br/>The Personal Data that we
              collect includes but is not limited to the following:
            </Typography>

            <ul>
              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 12
                  }}
                >
                  1. Data you provide when using our Services
                </Typography>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                · Data that identifies you, such as your username, name, address(es), 
                  nationality, telephone number, personal interests, email address, 
                  your image, government-issued identification numbers, date of birth, 
                  employment information or financial information.
                  <br/>
                  · Data relating to transactions you may engage with through any Service that 
                  you may provide, including data regarding offers, bids or purchases.
                  <br/>
                  · Financial data (e.g. bank account or credit card details, transaction details, 
                  and form of payment).
                  <br/>
                  · Any information you provide to display on your profile, such as your work 
                  experience and skills. While having a detailed profile page will help you get 
                  more from our Services, including helping you secure more opportunities through 
                  Yup, the information you wish to display on your profile is ultimately your choice. 
                  Please do not include any data to your profile that you would not want to be publicly
                  available.
                  <br/>
                  · Any information you provide, post or upload to our Services, such as when you 
                  create listings, fill out a firm, respond to a survey, submit a resume or a 
                  application to be involved in any opportunity listed on the Site. You may also 
                  give other data to us, such as by syncing your address book.
                  <br/>
                  · Any information that you share with other users through our messaging tools.
                  <br/> 
                  · Any other information you may provide to us by way of your involvement in 
                  community discussions, feedback regarding our Services, dispute resolution, or
                  if you contact us for any other reason regarding our Services.
                  <br/>
                  · Any information that we may receive about you when you use the services of our
                  partners or customers.
                  <br/>
                  · Other data that we are required or entitled by applicable law to collect and 
                  process and that we need for your authentication or identification, or for the 
                  verification of the data we collect.
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 12
                  }}
                >
                  2. Data we automatically collect when you use our Services
                </Typography>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                · Data that is generated through any of your actions on, interactions with 
                or usage of our Services, such as when you follow other users, search for
                anything through our Services, browse pages within the Site, the links you 
                click, or when you communicate with us.
                <br/>
                · Data that is generated as part of or as a result of any transaction in which 
                you are involved or is linked to your Yup account, such as transaction amounts, 
                time and location of transactions and form of payment, as well as messages or requests 
                that you send, receive, or engage with in connection with our Services.
                <br/> 
                · Data that is generated through your other actions when you use our Services and 
                which is linked to your eBay account, e.g. when you place items in your shopping
                 cart, place items on the watch list, save sellers, searches or interests, 
                 or follow users.
                <br/> 
                · Computer and connection information, such as statistics regarding your use of 
                Services, information on data traffic to and from websites, referral URL, information 
                on advertisements, your IP address, your access times, your browser history data, your 
                language settings and your weblog information.
                <br/>
                · (For mobile devices) location data, including your general location data 
                (e.g., IP address) and the precise location data of your mobile device. Please note 
                that for most mobile devices, you can manage or disable the use of location services 
                for all applications in the settings menu of your mobile device.
                <br/>
                · Any of the data described above may be collected with the use of cookies,
                web beacons or similar technologies. For more information about the use of 
                these technologies and your choices, please see the section entitled “Cookies” below.
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 12
                  }}
                >
                  3. Data collected from other sources or means
                </Typography>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                 · When you access or use the Services through social media networks or when 
                 you connect the Services to social media networks or third-party services, you 
                 are authorising us to collect, store, and use such additional information and 
                 content in accordance with this Policy. 
                 <br/>· In the event that your organisation (e.g. your company or talent agency)
                  buys or subscribes for a Service for you to use, they give us data about you 
                  and your eligibility to use such Services in such a context (e.g. that 
                  they have purchased Services for use by their staff or talents).
                  <br/>
                  · Data from public sources, credit agencies or bureaus or other data providers 
                  (e.g. demographic data, identity confirmation checks, interest-based and other 
                  data that may be related to our provision of new or improved Services to you).
                  <br/>
                  · We may also receive access to data about you from our partners, 
                  subsidiaries, affiliates, associated companies or jointly controlled entities.
                </Typography>
              </li>
            </ul>

            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
            The Personal Data we collect from you may be combined, connected or associated 
            with data obtained from other sources, and to the extent that such data may 
            constitute Personal Data, it shall be treated in accordance with this Policy.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Use of Personal Data
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
             We use your Personal Data for various purposes, including but not limited to the 
             provision, maintenance, support, development and improvement of our existing and 
             new Services for you and other users, to fulfil contractual or legal obligations, 
             in the assertion or defence of Yup’s legitimate interests, and to detect, prevent, 
             mitigate and investigate fraudulent or illegal activity.
             <br/>
              Some ways in which we may use your Personal Data are set out below:
            </Typography>

            <ul>
              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: 12
                  }}
                />
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                  <strong>1. Our interaction with you:</strong>
                  We use your contact information to communicate with you, including to 
                  provide updates on existing, upcoming or new Services (including to 
                  investigate, respond to or resolve service issues), for customer service 
                  or research purposes, or to contact you in relation to your account, profile
                  or any action, interaction or transaction related to your account on any 
                  Service. We may also send you reminders, suggestions, and promotional 
                  messages from us or our partners. We may also use your Personal Data 
                  in the enforcement of this Policy, the User Agreement and any other policies,
                  agreements or rules which may apply to you through your use of any Service.
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                  <strong>2. Facilitate discovery and connections:</strong>
                  We use your Personal Data (such as your profile and profiles
                  you have viewed to help others find your profile, and to help
                  suggest other users that you should follow or connect with.
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                  <strong>3. Interaction with other users:</strong>
                  We enable interaction and communications between you and other users 
                  through our Services, such as the viewing of profiles and related information,
                   and the sending and receiving of requests and messages between users. 
                   Given the social nature of some of our Services, do note that other users
                  may have access to some of your Personal Data (including your activity as 
                  a user of any relevant Service) through their valid use of such Services, 
                  and they use or retain such Personal Data, or disclose such Personal Data 
                  to other third parties (e.g. if you engage in a business transaction with 
                  another user that is a company or a professional, they may need to (for their 
                  legal or professional compliance purposes) record or archive their communications 
                  with you). Please also be mindful of this when considering your activity on our 
                  Services. We may also review or check messages to protect our legitimate interests, 
                  such as to protect against activities that may be fraudulent, suspicious, or in 
                  violation of the User Agreement, any other policies, agreements or rules which may 
                  apply to you through your use of any Service, or any relevant law.
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                  <strong>4. Security and fraud prevention:</strong>
                  We may use your Personal Data for security purposes (e.g. to detect, prevent or 
                  investigate possible fraud or any other prohibited or illegal activity, anything 
                  that could harm you, us or any other person/entity), detect, prevent or investigate 
                  any violation or threatened violation of the User Agreement, any other policies, 
                  agreements or rules which may apply to you through your use of any Service, or any 
                  relevant law, or to assert or defend the legitimate interests of Yup or third parties 
                  (e.g. your employer or agency). We may also use or disclose your Personal Data with 
                  our legal advisors, law enforcement officials, government authorities or other third 
                  parties for the purposes of complying with any law or legal obligation to which we may 
                  be subject, including fulfilling any valid information requests from third parties 
                  based on any legal requirement (e.g. in the event of any potential intellectual 
                  property infringement by a user, or where it is necessary to respond to an emergency 
                  that threatens the life, health or safety of a person).
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                  <strong>5. Publicity and community:</strong>
                  As part of our community building and promotional efforts, we may publicise or promote 
                  certain relevant Personal Data, such as your listings or profile page, to other users 
                  and potential users of our Services.
                </Typography>
              </li>

              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                  <strong>
                    6. Disclosure to service providers and business partners:
                  </strong>
                  We may also use, process or disclose your Personal Data in pursuit of the legitimate 
                  interests of us or a third party, to the extent permitted by applicable laws, such as 
                  for the analysis, enhancement or development of existing and new Services, or the 
                  offering of shared content and services with our affiliates or partners.
                </Typography>
              </li>

              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                  <strong>7. International data transfer:</strong>
                  Your Personal Data may be stored and processed in your region or in any other 
                  country where Yup or its affiliates, subsidiaries, partners, or service providers 
                  are located or maintain facilities. If we provide any of your Personal Data about 
                  you to any such entities, we will take appropriate measures to ensure such entities 
                  protect your information adequately in accordance with this Policy and applicable law.
                </Typography>
              </li>

              <li>
                <Typography
                  style={{
                    color: "#626262",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: 12
                  }}
                >
                  <strong>8. Merger or sale</strong>
                  In the event that Yup, or some or all assets related to the Services are 
                  acquired by, merged with or otherwise transferred to a third party entity in 
                  connection with a contemplated change of ownership or other corporate transaction, 
                  we reserve the right, in any of these circumstances, to transfer or assign any 
                  Personal Data that we have collected from users as part of that merger, 
                  acquisition, sale, or other change of control event (including any action in preparation of such event).
                </Typography>
              </li>
            </ul>

{/* <Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
Your rights with respect to your Personal Data
 
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
In accordance with applicable laws and regulations, you may be entitled to various rights with respect to your Personal Data.
 
 To the extent where we have to rely on your consent to use any particular Personal Data, you have the right to decline such consent. If you had provided your consent but later choose to withdraw it, we will respect that choice in accordance with our legal rights and obligations.
  
 Please be reminded however that your choice to not grant us consent (or to withdraw your consent) regarding the use of any particular Personal Data may prevent us from the provision (or continued provision) of certain Services to you.
  
 <u>Residents of Brazil</u>
  
 Residents of Brazil can exercise certain data rights available to them under applicable local laws. While we will comply with requests to exercise any such rights in accordance with applicable law, please note that there may still be certain cases in which we may need to continue processing your information (e.g. where there are legitimate interests to uphold, or if we need to comply with other legal obligations). 
  
 If you wish to exercise any of your data rights under applicable local laws, please contact us to submit your request.
  
  
  </Typography>
</Box> */}
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
            Our use, processing or disclosure of any of your Personal Data shall be based 
            on such lawful bases that allow such use, processing or disclosure. These 
            lawful bases include but are not limited to your consent (where you have given 
            consent), contractual (e.g. the delivery of Services to you pursuant to the 
            User Agreement).
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Cookies
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
              Yup, as well as third party service providers or partners that we work with, 
              may use cookies or similar technologies  (“<strong>Cookies</strong>”) in connection 
              with your use of the Services. These Cookies serve various functions, 
              including enabling us to provide you with Services, to help us optimise our 
              Services or to improve your user experience. 
              <br/>
              Cookies may have unique identifiers, and reside, among other places, on your device, 
              in emails we send to you, and on our web pages. Cookies may transmit Personal Data 
              about you and your use of the Service, such as your browser type, search preferences, 
              IP address, data relating to advertisements that have been displayed to you or that 
              you have clicked on, and the date and time of your use. Cookies may be persistent or 
              stored only during an individual session.
              <br/> 
              In certain cases, third parties (e.g. social networks that you connect your 
              Yup account to) may use Cookies in our Services to collect Personal Data and 
              non-personally identifiable data, and they may be able to associate the Personal 
              Data they collect with other Personal Data they have about you from other sources. 
              In general, we do not have access to or control over the Cookies they use, or what 
              they do with the Personal Data.
              <br/>
              Depending on the device and operating software that you are using, you may be 
              granted the option to disable our use of certain Cookies.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Retention of your Personal Data
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
            Yup retains your Personal Data for as long as it is required to fulfill the 
            relevant purposes described in this Policy, for as long as may be required 
            (or permitted) by law such as for tax and accounting purposes, or as otherwise 
            communicated to you. Subsequently, we will delete your Personal Data or take 
            steps to properly render any or all of your Personal Data anonymous.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Protection of your Personal Data
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
              We will use a variety of reasonable legal, organisational, technical and 
              physical measures to protect the integrity and security of your Personal Data, 
              which will vary depending on the sensitivity of the respective Personal Data.
              <br/> 
              However, we seek your understanding that the transmission of any information over 
              the internet is not completely secure, and that there is no guarantee that data 
              may not be accessed, disclosed, altered, or destroyed by breach of any of measures. 
              Therefore, we cannot ensure or guarantee the security of any Personal Data 
              transmitted through any means, and any such transmission is at your own risk.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Use, retention and protection of your Personal Data by third parties
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
            This Policy only governs your Personal Data that is in our possession. Our 
            Services may link to third party websites or services, which may receive or 
            collect Personal Data from you, and the privacy practices of these third 
            parties in respect to your Personal Data in their possession are not governed 
            by this Policy. We encourage you to review the privacy policies or notices of 
            these third parties to understand their practices.
            <br/>
            For example, if you link your Yup account or otherwise connect to a third party 
            service (e.g. another social network), you are authorising and directing us to 
            share with or grant access to such third party service your Personal Data and 
            other relevant information that we have, on your behalf.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Your rights with respect to your Personal Data
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
              In accordance with applicable laws and regulations, you may be entitled to various 
              rights with respect to your Personal Data.
              <br/>
              To the extent where we have to rely on your consent to use any particular 
              Personal Data, you have the right to decline such consent. If you had provided 
              your consent but later choose to withdraw it, we will respect that choice in 
              accordance with our legal rights and obligations.
              <br/>
              Please be reminded however that your choice to not grant us consent (or to 
              withdraw your consent) regarding the use of any particular Personal Data 
              may prevent us from the provision (or continued provision) of certain Services to you.
              <br/>
              <underline>Residents of Brazil</underline>
              Residents of Brazil can exercise certain data rights available to them under 
              applicable local laws. While we will comply with requests to exercise any 
              such rights in accordance with applicable law, please note that there may 
              still be certain cases in which we may need to continue processing your 
              information (e.g. where there are legitimate interests to uphold, or if we 
              need to comply with other legal obligations). 
              <br/>
              If you wish to exercise any of your data rights under applicable local laws, 
              please contact us to submit your request.
              <underline>Facebook Users</underline>
              If you signup or use Facebook login to access yup.gg, a call-back link 
              is configured with Facebook. This means that if you delete your account with Facebook,
              your user account with yup.gg will also be deleted automatically, 
              once Facebook initiates that call-back link.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Your responsibilities with the Personal Data of others
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
              When you interact with another user through our Services, we will provide 
              you with the other user’s Personal Data to varying levels of detail, 
              depending on the type of interaction with such other user. For example, 
              if you enter into a transaction with another user, you may receive the 
              other user’s name, contact information and billing/payment information.
              <br/>
              When you receive another user’s Personal Data through our Services, you are 
              the controller of such data and therefore solely and independently responsible 
              for any subsequent use, processing or disclosure of such data that you may perform, 
              in accordance with not only this Policy, the User Agreement, as well as any relevant 
              law (e.g. local data protection law) that may apply to you. You are hereby reminded 
              that any Personal Data of another user that you may receive through any Service 
              shall only be used for the purposes of any interaction or transaction with such 
              other user through any of the Services, and for purposes expressly consented by 
              such other user. Using Personal Data of any other user for any other purpose shall 
              constitute a violation of our User Agreement.
              <br/> 
              Accordingly, whether you are using our Services for your business or purely in your 
              personal capacity, you should take such measures to protect or to prevent compromising 
              any Personal Data of other users that may be in your possession.
              <br/>
              If you are using our Services as part of your business (whether or not as an 
              individual or as an organisation) we recommend that you notify other users of 
              how you may use their Personal Data through your own privacy notice.
              <br/>
              If you provide us with Personal Data relating to another person (e.g. your 
              employee or talent under your management), you must obtain the consent of 
              such person, or you must ensure that the use and disclosure of such Personal 
              Data is otherwise legally permissible. It is your sole responsibility to 
              inform such other person of how we will use their Personal Data in accordance 
              with this Policy.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Minimum age of usage and consent
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
              <u>Usage</u>
              <br />
              <strong>
                The Services are not for use by anyone under the age of 16. If
                you are under 16 years of age, or you are not of the minimum age
                that is required in your country to register for or use our
                Services, please do not use or access our Services at any time
                or in any manner.
              </strong><br/>
              Under our User Agreement, our Services are not intended for the
              use by persons who are deemed by any applicable law to be not old
              enough to use our Services. 
              <br/>
              We do not knowingly collect Personal Data or any other information of persons 
              who are deemed by any applicable law to be not old enough to use our Services 
              on their own accord (as determined by applicable laws). If we learn that any 
              Personal Data or information (e.g. messages) has been collected on or through 
              our Services, we will take appropriate steps to delete such data.
              <br/>
              <strong>
                If you are the parent or legal guardian of a person who is
                deemed by any applicable law to be not old enough to use our
                Services but has become a user of any of our Services, please
                close such person’s account immediately and contact us to have
                their Personal Data deleted.
              </strong>
              <br />
              <u>Consent</u>
              <br />
              Even if you are of 16 years or above, if by any applicable law you
              are deemed to be not old enough to have authority to give consent
              or to agree to our Policy, User Agreement or any other policies,
              contracts, agreements or rules which may apply to you through your
              use of any Service, you shall only use the Services under the
              supervision of a parent or legal guardian who consents to be bound
              by the foregoing.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Amendments
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
            Yup reserves the right to modify, update, amend, or otherwise change the terms 
            in this Policy at any time. It is your responsibility to review this Policy 
            regularly. Your continued use of our Services following the posting of any 
            change to this Policy, whether or not you have reviewed such changes, shall 
            constitute your binding acceptance of such change.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              Translations
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
              The original of this Policy is written in the English language. In the 
              event of any conflict between the English and other language versions, the 
              English version shall prevail.
            </Typography>
          </Box>

          <Box style={{ marginTop: "1rem" }}>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 15
              }}
            >
              How to contact us
            </Typography>
            <Typography
              style={{
                color: "#626262",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: 12
              }}
            >
              Please contact our Data Protection Officer at privacy@yup.gg if
              you have any questions or issues regarding this Policy.
              <br/> 
              We will endeavour to respond to your inquiry as soon as practicable, 
              and we seek your understanding that our response time may be affected by the 
              nature of your inquiry.
              <br/>
              Depending on the nature of your inquiry, we may require you to provide us with 
              supporting information necessary to confirm your identity and/or verify your 
              authority before responding to your request.
            </Typography>
          </Box>
        </Box>

        <Box mt="10px" mb="100px" style={{ background: "F2F2F2" }}>
        <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});

export default withRouter(PrivacyPolicy as React.ComponentType<any>);
