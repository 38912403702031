//@ts-nocheck
//@ts-ignore
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import { Box, Link, Typography, Button, Checkbox } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";

import Snackbar from "../../../components/src/Snackbar";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import {
  logo,
  profileIcon,
  passwordIcon,
  checkboxIcon,
  callIcon,
  starImage
} from "./assets";
import { withTranslation } from 'react-i18next';

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import Copyright from "../../../components/src/Copyright";

class PhoneOtp extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { t , i18n } = this.props;
    let params = new URL(document.location as any).searchParams;
    let token = params.get("token");
    let otp = params.get("otp");

    if (!token) {
      this.props.history.replace("/signup/phone");
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="85%"
          pt="22px"
        >
          <img
            style={{ width: 96, height: "auto" }}
            src={logo}
            onClick={() => {
              this.props.history.push("/");
            }}
          />
          {/* <Box display="flex" alignItems="center">
            <Typography
              style={{
                fontSize: 16,
                color: "#4E4B66",
                fontFamily: "Poppins",
                fontWeight: 400
              }}
            >
              Already have an account?
            </Typography>
            <Link
              href="/login"
              underline="none"
              style={{
                fontSize: 14,
                color: "#F14E24",
                fontFamily: "Roboto",
                fontWeight: 500,
                marginLeft: 5,
                textTransform: "uppercase"
              }}
            >
              Sign in
            </Link>
          </Box> */}
        </Box>

        <Box
          display="flex"
          mt="50px"
          mb="27px"
          width="494px"
          justifyContent="center"
        >
          <Typography
            style={{
              fontSize: 26,
              color: "#14142B",
              fontFamily: "Manrope",
              fontWeight: 800
            }}
          >
            {t("Verify account")}
          </Typography>
          <img
            src={starImage}
            style={{ position: "absolute", height: 45, width: 494 }}
          />
        </Box>

        <Formik
          initialValues={{
            pin: otp || "",
            token: token || ""
          }}
          validationSchema={this.PhoneOtpSchema(t)}
          validateOnMount={true}
          onSubmit={(values) => {
            // same shape as initial values
            this.verifyAccount(values);
          }}
        >
          {({ values, errors, touched, isValid, handleChange }) => (
            <Form translate="yes" style={{ width: 363 }}>
              <Fragment>
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    overflow="hidden"
                    alignItems="center"
                    height="56px"
                    border="1px solid #D1D1D1"
                    borderRadius="16px"
                    bgcolor="white"
                  >
                    <Field
                      name="pin"
                      placeholder="OTP"
                      type="number"
                      style={{
                        border: "none",
                        height: "100%",
                        width: "80%",
                        color: "rgba(0, 0, 0, 0.6)",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 16,
                        marginRight: 10,
                        marginLeft: 21,
                        outline: "none"
                      }}
                    />
                    {/* <img
                      src={profileIcon}
                      style={{ width: 24, height: 24, marginRight: 16 }}
                    /> */}
                  </Box>
                  {errors.pin && touched.pin ? (
                    <Typography
                      style={{
                        color: "#F14E24",
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        fontSize: 14,
                        marginTop: 5,
                        marginLeft: 10
                      }}
                    >
                      {errors.pin}
                    </Typography>
                  ) : null}

                  <Box display="flex" width="100%" justifyContent="flex-end">
                    <Button
                      onClick={() => this.resendOtpHandler()}
                      variant="text"
                      type="button"
                      style={{
                        backgroundColor: "#F2F2F2",
                        color: "#F14E24",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: 14
                      }}
                    >
                      {t("Resend OTP")}
                    </Button>
                  </Box>

                  <Button
                    disabled={!isValid}
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "#F14E24",
                      border: "1px solid #D9DBE9",
                      borderRadius: 16,
                      height: 34,
                      marginBottom: 20,
                      boxShadow: "none",
                      color: "#F7F7FC",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: 16,
                      marginTop: 30,
                      opacity: !isValid ? 0.6 : 1
                    }}
                  >
                    {t("Continue")}
                  </Button>
                  <Box display="flex" width="100%" justifyContent="center">
                    <Typography
                      style={{
                        color: "#626262",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 12
                      }}
                    >
                      {t("Please check your email / SMS for OTP.")}
                    </Typography>
                  </Box>
                </Box>
              </Fragment>
            </Form>
          )}
        </Formik>

        <Box mt="315px" mb="100px">
        <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        
        <CatchInvalidToken
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
        />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
    this.checkUsernameToken();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});

export default withTranslation()(withRouter(PhoneOtp as React.ComponentType<any>))
