//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Button, Grid, MenuItem,
  Select, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import OpenPositionsController, { Props } from "./OpenPositionsController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class OpenPositionsCreate extends OpenPositionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation } = this.props;
    const { classes, t, i18n } = this.props;
    const formDetails = JSON.parse(localStorage.getItem("openPositionDetails"));
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    return (
      <>
        <ProfileHeader heading={t("Open Position")} subModule="3" showProfile />
        <Grid className={classes.mainWrapper}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid sm={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Formik
                  initialValues={
                    formDetails || {
                      jobTitle: "",
                      contract: "",
                      level: "",
                      country: "",
                      city: "",
                      roleDescription: ""
                    }
                  }
                  validationSchema={this.CreateOpenPositionSchema(t)}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    this.handleCreateUpdateOpenPosition(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue
                  }) => (
                    <Form
                      translate="yes"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Avatar
                          src={userDetails.profileUrl}
                          style={{ width: 38, height: 38, marginRight: 22 }}
                        />
                        <Typography
                          style={{
                            color: "#262626",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 19,
                            marginLeft: 8
                          }}
                        >
                          {`${userDetails.firstName ||
                            ""} ${userDetails.lastName || ""}`}
                        </Typography>
                      </Box>

                      <Grid container={true} mt="37px">
                        <Grid xs={12}>
                          <div className={classes.frmColumns}>
                            <Typography
                              style={{
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                // fontWeight: 700,
                                fontSize: 16,
                                marginBottom: 10
                              }}
                            >
                              {t("Job Title")}
                            </Typography>
                            <Field
                              maxLength={100}
                              name="jobTitle"
                              placeholder="i.e. yup.gg"
                              style={{
                                border: "1px solid #DBDBDB",
                                height: 45,
                                width: "100%",
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 14,
                                paddingLeft: 16,
                                paddingRight: 10,
                                outline: "none",
                                borderRadius: 16
                              }}
                            />
                            {errors.jobTitle && touched.jobTitle ? (
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontFamily: "Poppins",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5
                                }}
                              >
                                {errors.jobTitle}
                              </Typography>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container={true} mt="30px">
                        <Grid xs={12} sm={6}>
                          <div className={classes.frmColumns}>
                            <Typography
                              style={{
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                //fontWeight: 700,
                                fontSize: 16,
                                marginBottom: 10
                              }}
                            >
                              {t("Contract")}
                            </Typography>
                            <Field
                              maxLength={100}
                              name="contract"
                              placeholder={t("Full time")}
                              style={{
                                border: "1px solid #DBDBDB",
                                height: 45,
                                width: "100%",
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 14,
                                paddingLeft: 16,
                                paddingRight: 10,
                                outline: "none",
                                borderRadius: 16
                              }}
                            />
                            {errors.contract && touched.contract ? (
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontFamily: "Poppins",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5
                                }}
                              >
                                {errors.contract}
                              </Typography>
                            ) : null}
                          </div>
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <div className={classes.frmColumns}>
                            <Typography
                              style={{
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                //fontWeight: 700,
                                fontSize: 16,
                                marginBottom: 10
                              }}
                            >
                              {t("Level")}
                            </Typography>
                            <Field
                              maxLength={100}
                              name="level"
                              placeholder={t("Entry")}
                              style={{
                                border: "1px solid #DBDBDB",
                                height: 45,
                                width: "100%",
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 14,
                                paddingLeft: 16,
                                paddingRight: 10,
                                outline: "none",
                                borderRadius: 16
                              }}
                            />
                            {errors.level && touched.level ? (
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontFamily: "Poppins",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5
                                }}
                              >
                                {errors.level}
                              </Typography>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container={true} mt="30px">
                        <Grid xs={12} sm={6}>
                          <div className={classes.frmColumns}>
                            <Typography
                              style={{
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                //fontWeight: 700,
                                fontSize: 16,
                                marginBottom: 10
                              }}
                            >
                              {t("Country")}
                            </Typography>
                            <div className={classes.selectDropdown}>
                              <Select
                                disableUnderline={true}
                                classes={{
                                  select: this.props.classes.dropdown
                                }}
                                IconComponent={() => (
                                  <KeyboardArrowDown
                                    style={{
                                      position: "absolute",
                                      right: 8,
                                      top: 8,
                                      height: 30,
                                      width: 30,
                                      color: "#8E8E8E"
                                    }}
                                  />
                                )}

                                renderValue={(value) =>
                                  // value ? value : `${t(`<span>Select country<span>`)}`
                                  value ? value : <span>{t("Select country")}</span>
                                }
                                displayEmpty={true}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={
                                  values.country
                                    ? JSON.parse(values.country).label
                                    : ""
                                }
                                onChange={handleChange("country")}
                                onBlur={() => {
                                  setFieldValue("city", "");
                                }}
                              >
                                {this.getCountries().map((country, index) => (
                                  <MenuItem
                                    key={index}
                                    style={{
                                      color: "#8E8E8E",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: 14
                                    }}
                                    value={JSON.stringify(country)}
                                  >
                                    <span>{country.label}</span>
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                            {errors.country && touched.country ? (
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontFamily: "Poppins",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5
                                }}
                              >
                                {errors.country}
                              </Typography>
                            ) : null}
                          </div>
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <div className={classes.frmColumns}>
                            <Typography
                              style={{
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                // fontWeight: 700,
                                fontSize: 16,
                                marginBottom: 10
                              }}
                            >
                              {t("City")}
                            </Typography>

                            <Field
                              maxLength={100}
                              name="city"
                              placeholder={t("City name")}
                              style={{
                                border: "1px solid #DBDBDB",
                                height: 45,
                                width: "100%",
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 14,
                                paddingLeft: 16,
                                paddingRight: 10,
                                outline: "none",
                                borderRadius: 16
                              }}
                            />
                            {errors.city && touched.city ? (
                              <Typography
                                style={{
                                  color: "#F14E24",
                                  fontFamily: "Poppins",
                                  fontWeight: 300,
                                  fontSize: 14,
                                  marginTop: 5
                                }}
                              >
                                {errors.city}
                              </Typography>
                            ) : null}
                          </div>
                        </Grid>
                      </Grid>

                      <Box display="flex" flexDirection="column" mt="38px">
                        <Typography
                          style={{
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            //fontWeight: 700,
                            fontSize: 16,
                            marginBottom: 10
                          }}
                        >
                          {t("Role description")}
                        </Typography>

                        <textarea
                          maxLength={2000}
                          value={values.roleDescription}
                          name="roleDescription"
                          onChange={handleChange("roleDescription")}
                          placeholder={t(
                            "Tell a bit more about your experience"
                          )}
                          style={{
                            border: "1px solid #DBDBDB",
                            height: 119,
                            width: "100%",
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14,
                            paddingLeft: 16,
                            paddingRight: 10,
                            paddingTop: 18,
                            outline: "none",
                            borderRadius: 16,
                            resize: "none"
                          }}
                        />
                        {errors.roleDescription && touched.roleDescription ? (
                          <Typography
                            style={{
                              color: "#F14E24",
                              fontFamily: "Poppins",
                              fontWeight: 300,
                              fontSize: 14,
                              marginTop: 5
                            }}
                          >
                            {errors.roleDescription}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="column"
                        mt="16px"
                        mb="40px"
                      >
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            variant="contained"
                            type="submit"
                            style={{
                              backgroundColor: "#F14E24",
                              border: "1px solid #D9DBE9",
                              borderRadius: 16,
                              height: 34,
                              marginBottom: 14,
                              boxShadow: "none",
                              color: "#F7F7FC",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: 14,
                              marginTop: 30
                            }}
                          >
                            {t("Submit")}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
            <Snackbar
              open={Boolean(this.state.error)}
              onClose={() => this.setState({ error: null })}
              message={this.state.error || "N/A"}
              severity="error"
            />
            <Loader loading={this.state.loading} />

            <CatchInvalidToken
              onClose={() => this.setState({ error: null })}
              message={this.state.error || "N/A"}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  async componentDidMount() {
    // this.getValidations();
    // this.requestSessionData();
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
    this.getCountryList();
  }
}
// Customizable Area Start
export const OpenPositionsCreateStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    width: "100%",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    // paddingRight: 10,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
      // backgroundColor: "white",
      // zIndex: 2
    }
  },
  mainWrapper: {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    }
  },
  selectDropdown: {
    // backgroundColor: "red",
    "& .MuiInput-root":
    {
      width: "100%",
    }
  },
  mainBodyContent: {
    // height: "100vh",
    marginTop: 30,
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      padding: 10
    }
  },
  frmColumns: {
    margin: 5,
  },
};
// Customizable Area End

export default withTranslation()(
  withStyles(OpenPositionsCreateStyle)(
    withRouter(OpenPositionsCreate as React.ComponentType<any>)
  )
);
