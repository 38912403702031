//@ts-ignore
//@ts-nocheck
import { Avatar, Box, Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, Popover, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { MoreHoriz } from "@material-ui/icons";
import React from "react";
import { withTranslation } from 'react-i18next';
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import PortfolioController, { Props } from "./PortfolioController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";


class PortfolioList extends PortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation, t, i18n } = this.props;
    const { portfolios } = this.state;
    const { classes } = this.props;
    return (
      <>
        <ProfileHeader heading={t("Portfolio")} subModule={true} showProfile={true} />
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Grid
            sm={12}
            md={6}
            style={{
              border: "1px solid #DEDDDA",
              borderTop: "none",
              height: "100%"
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                onClick={() => {
                  localStorage.removeItem("portfolioDetails");
                  localStorage.removeItem("portfolioMedia");
                  this.props.history.push("/portfolio/create");
                }}
                style={{
                  padding: 10,
                  cursor: "pointer",
                  backgroundImage: "linear-gradient(to right, rgb(241, 78, 36), rgb(195, 0, 82))",
                  minHeight: 127,
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: 43,
                  backgroundColor: "#FAFAFA",
                  boxShadow:
                    "0px 1px 1px rgba(0, 0, 0, 0.141176), 0px 2px 1px -1px rgba(0, 0, 0, 0.121569), 0px 1px 3px rgba(0, 0, 0, 0.2)",
                  borderRadius: 16
                }}
              >
                <Typography
                  style={{
                    color: "#ffffff",
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: 22
                  }}
                >
                  {t("Create a new project")}
                </Typography>
                <Typography
                  style={{
                    color: "#ffffff",
                    fontFamily: "Poppins",
                    fontWeight: "normal",
                    fontSize: 12,
                    marginTop: 13
                  }}
                >
                  {t("Your Portoflio should show off your best work to attract more clients")}
                </Typography>
              </Box>
              <Box width='100%'
                style={{ width: "90%", margin: "0 auto", marginTop: "34px" }}>
                
                <Grid container>
                  {portfolios.map((portfolio) => (
                    <Grid item xs={12} sm={6}
                      key={portfolio.id}>
                      <Box style={{
                        margin: "10px",
                        backgroundColor: "#F7F7FC",
                        border: "1px dashed #EBEEF0",
                        borderRadius: 20,
                        position: 'relative'
                      }}>
                        <Box>
                          <IconButton
                            style={{
                              backgroundColor: 'transparent',
                              position: "absolute", top: 1, right: 1, zIndex: 10
                            }}
                            onClick={(e) =>
                              this.handlePopoverOpen(e, portfolio.id)
                            }
                          >
                            <MoreHoriz style={{ width: 24, height: 24, color: 'black' }} />
                          </IconButton>

                          <Popover
                            open={this.state.openedPopoverId === portfolio.id}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handlePopoverClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                            }}
                          >
                            <Box display='flex' flexDirection='column' >
                              <Button
                                onClick={() => {
                                  localStorage.setItem("portfolioDetails", JSON.stringify({
                                    id: portfolio.id,
                                    title: portfolio.attributes.title,
                                    description: portfolio.attributes.description,
                                    categories: portfolio.attributes.project_category,
                                    toolsUsed: portfolio.attributes.used_tools,
                                    banner: "",
                                    bannerUrl: portfolio.attributes.project_banner
                                  }));
                                  localStorage.setItem("portfolioMedia", JSON.stringify({ portfolioId: portfolio.id, deletedMedia: [], media: portfolio.attributes.project_files.map(media => ({ id: media.id, url: media.url, file: { name: media?.filename || 'N/A', type: media?.mimetype || '' } })) }));
                                  this.props.history.push("/portfolio/create");
                                }}
                                variant="text"
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: 12,
                                  textAlign: 'left',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                {t("Edit")}
                              </Button>
                              <Button
                                onClick={() => {
                                  this.setState({ showDialog: true, portfolioId: portfolio.id })
                                }}
                                variant="text"
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: 12,
                                  textAlign: 'left',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                {t("Delete")}
                              </Button>
                            </Box>

                          </Popover>
                        </Box>
                        <div className={classes.avtarLayer} >
                          <Avatar src={portfolio.attributes?.project_banner} style={{ width: '100%', height: 200, objectFit: 'cover', borderRadius: "20px 20px 0 0" }} />
                        </div>
                        <Typography
                          style={{
                            color: "#14142A",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 12,
                            marginTop: 20,
                            marginBottom: 30,
                            textAlign: "center",
                            wordBreak: "break-all"
                          }}
                        >
                          {portfolio.attributes.title}
                        </Typography>
                      </Box>
                    </Grid>
                    // </Box>
                  ))}
                </Grid>
                {/* </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        <Loader loading={this.state.loading} />

        <CatchInvalidToken
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
        />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this portfolio?"}</DialogTitle>
          <DialogActions>
            <Button onClick={() => this.setState({ showDialog: false, portfolioId: null })} variant='text'>
              {t("Cancel")}
            </Button>
            <Button onClick={() => this.deletePortfolio(this.state.portfolioId)} variant='text' autoFocus>
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </>


    );
  }

  async componentDidMount() {
    this.getAllPortfolios();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
export const portfolioStyles = {
  position: "relative",
  avtarLayer: {
    "&:after": {
      content: "''",
      position: "absolute",
      zIndex: "1",
      backgroundColor: "rgba(0,0,0, 0.5)",
      width: "100%",
      height: "200px",
      left: "0",
      top: "0",
      borderRadius: "20px 20px 0 0",
    },
  },
}
// Customizable Area End
export default withTranslation()(withStyles(portfolioStyles)(withRouter(PortfolioList)))
