//@ts-ignore
//@ts-nocheck
import PropTypes from "prop-types";
import React, { Component } from "react";
import TwitterLogin from "react-twitter-auth";
import { Box, Link, Typography, Button } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import { configJSON } from "./SocialMediaAccountWebController";
import { twitterIcon } from "../../email-account-registration/src/assets";

type Props = {
  loginUrl: string;
  requestTokenUrl: string;
  loginTwitterButtonText: string;
  onSuccess: () => void;
  onPress: () => void;
  onFailed: () => void;
  classes: object;
};

class CustomTwitterLogInButton extends Component<Props> {
  static propTypes = {
    loginUrl: PropTypes.string,
    requestTokenUrl: PropTypes.string,
    loginTwitterButtonText: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onFailed: PropTypes.func.isRequired,
    onPress: PropTypes.func.isRequired,
    classes: PropTypes.object,
  };

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <TwitterLogin
        loginUrl={this.props.loginUrl}
        onSuccess={this.props.onSuccess}
        onFailed={this.props.onFailed}
        // className={styles.facebookTextStyle}
        requestTokenUrl={this.props.requestTokenUrl}
        className={this.props.classes.twitterBtn}
        children={
          <Button variant='contained'
          style={{ 
            backgroundColor: '#1DA1F3',
            borderRadius: 16,
            width: 361,
            height: 80,
            marginBottom: 14,
            boxShadow: 'none',
            fontFamily: 'Poppins',
          }}
        onClick={() => {
              this.props.onPress();
            }}
        >
          <Box display='flex' justifyContent='space-between' width='80%' >
            <Box display='flex' width='25%'>
              <img src={twitterIcon } style={{ width: 26, height: 'auto' }} />
            </Box>
            <Box display='flex' width='75%' style={{ fontSize: 16, color: "white" }}>
              {this.props.loginTwitterButtonText}
            </Box>
          </Box>
        </Button>
        }
      />
    );
  }
}

const style = {
  twitterBtn: {
    padding: 0,
    background: "none",
    border: "none",
  }
}

export default withStyles(style)(CustomTwitterLogInButton)