//@ts-ignore
//@ts-nocheck
import { Link, Typography, Button, Checkbox, Box, CircularProgress } from "@material-ui/core";
import { View, Text } from 'react-native';
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';;
import { businessImg, creatorImg } from "./assets";
import ProfileHeader from '../../user-profile-basic/src/ProfileHeader';
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import { Formik, Form, Field } from "formik";
import SubSideMenu from "./SubSideMenu.web";
import Radio from '@material-ui/core/Radio';
import SettingController from './SettingController.web';
import Snackbar from "../../../components/src/Snackbar";
import Hidden from '@material-ui/core/Hidden';
import { withTranslation } from 'react-i18next';
import { containerCSS } from "react-select/src/components/containers";

class ProfessionalAccount extends SettingController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getUserData()
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  onSubmit = () => {
    const { account_type } = this.state
    if (account_type) {
      this.sendProfessionalAccount(account_type)
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes, t, i18n } = this.props
    const { account_type, loading } = this.state

    return (
      <>
        <ProfileHeader heading={t("Which Best Describes You?")} subModule="3" />
        <Grid container style={{ paddingBottom: 0, height: "100%" }}>
          <Grid item sm={3} xs={12} style={{ position: "relative" }}>
            <SideMenu subModule="3" />
          </Grid>
          <Grid item xs={2} className={classes.settingIcon}>
            <SubSideMenu handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <div style={{ padding: "28px 50px 0 50px", position: "relative" }}>
              {loading ?
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress style={{ color: "#F14E24" }} />
                </Box>
                :
                <>
                  <Grid contianer >
                    <div style={{ paddingBottom: 15, display: "flex" }}>
                      <Grid item xs={10} sm={6}>
                        <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                          <div className={classes.accountImg}>
                            <img src={creatorImg} />
                          </div>
                        </Box>
                        <Typography className={classes.heading}>{t("Creator")}</Typography>
                        <Typography className={classes.subText}>{t("Best for public figures, producers, artistis and influencers")}</Typography>
                      </Grid>
                      <Grid item sm={4} xs={2}>
                        <Box display="flex" width="100%" style={{ paddingTop: 14 }}>
                          <Radio
                            checked={account_type === "creator"}
                            value="creator"
                            name="account_type"
                            // inputProps={{ 'aria-label': 'anyone' }}
                            onChange={(event: any) => this.handleChange(event)}
                            classes={{ root: classes.radio, checked: classes.checked }}
                          />
                        </Box>
                      </Grid>
                    </div>
                  </Grid>


                  <Grid contianer>
                    <div style={{ paddingBottom: 15, display: "flex" }}>
                      <Grid item xs={10} sm={6}>
                        <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                          <div className={classes.accountImg}>
                            <img src={businessImg} />
                          </div>
                        </Box>
                        <Typography className={classes.heading}>{t("Business")}</Typography>
                        <Typography className={classes.subText}>{t("Best for retailers, local businesses, brands, organizations and service providers.")}</Typography>
                      </Grid>
                      <Grid item sm={4} xs={2}>
                        <Box display="flex" width="100%" style={{ paddingTop: 14 }}>
                          <Radio
                            checked={account_type === "business"}
                            value="business"
                            name="account_type"
                            // inputProps={{ 'aria-label': 'anyone' }}
                            onChange={(event: any) => this.handleChange(event)}
                            classes={{ root: classes.radio, checked: classes.checked }}
                          />
                        </Box>
                      </Grid>
                    </div>
                  </Grid>

                  <Button style={{ fontFamily: "Poppins" }} className={account_type ? classes.btn : classes.disablebtn} onClick={this.onSubmit}>
                    {t("Save")}
                  </Button>
                </>
              }
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
      </>
    )
  }
}

const ProfessionalAccountStyle = {
  radio: {
    fontSize: "x-large",
    '&$checked': {
      color: '#F14E24 !important'
    }
  },
  checked: {
    fontSize: "x-large",
    color: '#F14E24 !important',
  },
  accountImg: {
    width: 65,
    height: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(128, 128, 128, 0.26)",
    borderRadius: "50%",
    padding: 20,
    "& img":
    {
      width: 30,
      height: 30,
      objectFit: "contain",
    }
  },
  heading: {
    fontSize: 18,
    fontFamily: "Poppins",
    paddingTop: 5,
  },
  subText: {
    fontSize: 13,
    color: "rgb(128 128 128 / 54%)",
    fontFamily: "Poppins",
    paddingTop: 10,
    fontWeight: 500,
  },
  btn: {
    marginTop: 25,
    fontSize: 14,
    color: "white",
    background: "#F14E24",
    "&:hover": {
      backgroundColor: '#F14E24',
      color: "white",
    },
    marginBottom: "60%",
    "&:disabled": {
      backgroundColor: 'red',
      opacity: 0.6,
    }
  },
  disablebtn: {
    marginTop: 25,
    fontSize: 14,
    color: "white",
    cursor: "not-allowed",
    background: "#F14E24",
    "&:hover": {
      backgroundColor: '#F14E24',
      color: "white",
    },
    marginBottom: "60%",
    opacity: 0.7,
  },
  settingIcon: {
    borderLeft: "1px solid rgba(128, 128, 128, 0.26)",
    borderRight: "1px solid rgba(128, 128, 128, 0.26)",
    "@media (max-width: 768px)": {
      borderLeft: "none",
      borderRight: "none",
      position: "absolute",
      right: "40px",
      top: "16px",
    }
  },
}

const CssRadio = withStyles({
  colorSecondary: {
    color: '#FFFFFF',
    '&$checked': {
      color: 'hotpink',
    },
  },
  checked: {}
})(Radio)

export default withTranslation()(withStyles(ProfessionalAccountStyle)(ProfessionalAccount))