//@ts-ignore
//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  sendProfessionalAccountApiCallId: any
  getUserDataApiCallId: any
  editProfileApiCallId: any
  editSecurityApiCallId: any
  connectSocialAccountApiCallId: any
  getSocialAccountsApiCallId: any
  connectTwitchAccountApiCallId: any
  connectGoogleApiCallId: any;
  connectYoutubeApiCallId: any
  setLanguageApiCallId: any;
  deleteAccountApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
    ];

    this.state = {
      account_type: "",
      error: null,
      loading: false,
      userData: {},
      isSecurity: false,
      isSucsess: false,
      facebookUsername: "",
      googleUsername: "",
      twitterUsername: "",
      twitchUsername: "",
      toggleSideMenu: true,
      HamburgerMenuOpen: false,
      mobileOpen: false,
      googleToken: "",
      isModal: false,
      isFacebookConnect: true
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errors = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.sendProfessionalAccountApiCallId && responseJson !== undefined) {
        if (responseJson.data) {
          window.location = "/profile-dashboard" as any;
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }


      if (apiRequestCallId === this.getUserDataApiCallId && responseJson !== undefined) {
        if (responseJson.data) {
          this.setState({ account_type: responseJson?.data?.attributes?.professional_account_type, 
            loading: false, userData: responseJson?.data?.attributes, 
            isSecurity: responseJson?.data?.attributes?.two_factor_auth_enabled
          })
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }

      if (apiRequestCallId === this.getSocialAccountsApiCallId && responseJson !== undefined) {
        if (responseJson.data) {
          const facebookAccount = responseJson.data.find(item => (item.attributes.provider === "facebook"))
          const youtubeAccount = responseJson.data.find(item => (item.attributes.provider === "youtube"))
          const twitterAccount = responseJson.data.find(item => (item.attributes.provider === "twitter"))
          const twitchAccount = responseJson.data.find(item => (item.attributes.provider === "twitch"))

          this.setState({facebookUsername: facebookAccount?.attributes?.user_name,
            googleUsername: youtubeAccount?.attributes?.user_name,
            twitterUsername: twitterAccount?.attributes?.user_name,
            twitchUsername: twitchAccount?.attributes?.user_name,
            loading: false
          })
        } else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }


      if (apiRequestCallId === this.editProfileApiCallId && responseJson !== undefined) {
        if (responseJson.data) {
          window.location = "/profile-dashboard" as any;
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }


      if (apiRequestCallId === this.connectTwitchAccountApiCallId && responseJson !== undefined) {
        if (responseJson.data) {
          this.setState({ error: 'Successfully connect with twitch', isSucsess: true})
          window.location = "/settings/connect-account" as any;
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }


      if (apiRequestCallId === this.connectYoutubeApiCallId && responseJson !== undefined) {
        if (responseJson.data) {
          this.setState({ error: 'Successfully connect with Youtube', isSucsess: true,
          googleUsername: responseJson?.data?.attributes?.user_name
        })
          window.location = "/settings/connect-account" as any;
          localStorage.removeItem("googleToken");
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else if (responseJson.status === 500) {
          window.location = "/settings/connect-account" as any;
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
        localStorage.removeItem("isFacebookConnect")
        this.setState({isFacebookConnect: true})
      }

      if (apiRequestCallId === this.connectGoogleApiCallId && responseJson !== undefined) {
        if (responseJson?.uri) {
          window.location = responseJson?.uri as any;
          localStorage.setItem("googleToken", responseJson?.token)
          const isfacebook = localStorage.getItem("isFacebookConnect")
          this.setState({isFacebookConnect: isfacebook})
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }

      if (apiRequestCallId === this.connectSocialAccountApiCallId && responseJson !== undefined ) {
        if (responseJson.data) {
          if (responseJson.data.attributes.errors) {
            let error = responseJson?.data?.attributes.errors?.unique_auth_id[0]
          this.setState({ error: error || 'Already connect with this account', isSucsess: false });
          }
          if (responseJson.data.attributes.provider === "facebook") {
            this.setState({ error: 'Successfully connect with facebook',
            facebookUsername: responseJson?.data?.attributes?.user_name,
            isSucsess: true });
          }
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }

      if (apiRequestCallId === this.editSecurityApiCallId && responseJson !== undefined) {
        if (responseJson.data) {
          window.location = "/settings/privacy-security" as any;
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }

      if (apiRequestCallId === this.setLanguageApiCallId && responseJson !== undefined) {
        if (responseJson.data) {
          console.log("setLanguage")
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }

      if (apiRequestCallId === this.deleteAccountApiCallId && responseJson !== undefined) {
        if (responseJson.message) {
          this.setState({ error: 'Your account has been disabled successfully.', isSucsess: false });
          window.localStorage.clear();
          if ( localStorage.length == 0) { window.location = "/" as any;}
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }
    }
    else {
      this.parseApiErrorResponse(responseJson);
    }

  }

  responseFacebook = (response: any) => {
    const { isFacebookConnect } = this.state
    if(response.accessToken) { 
    this.connectSocialAccount(response)
    }
  }

  // Customizable Area Start

  handleTwitchSignup = async () => {
    try {
      if (window.location.hash) {
        if(window.location.hash.split("&")[1]?.split("=")[0] === 'id_token'){
          this.setState({loading: true})
          let idToken = window.location.hash.split("&")[1]?.split("=")[1];
          let decoded:any = jwt_decode(idToken);
          let response = await  axios.get('https://id.twitch.tv/oauth2/userinfo',{
            headers:{
              "Authorization":`Bearer ${window.location.hash.split("&")[0]?.split("=")[1]}`
            }
          }) 
          this.setState({ twitchUsername: response?.data?.preferred_username})
          this.connectTwitchAccount(response)
        } 
      }
    } catch (error) {
      console.log(error);
      this.setState({loading: false})
    }
   
  };

  googleResponse = async() => {
    if (window.location.search) {
      let code = window.location.search.split("&")[0]?.split("=")[1];
      this.connectYoutube(code)
    }
  }

  connectYoutube = async(code) => {
    try {
      const header = {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("userToken")
      };
      const baseUrl = `https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/youtube_connect?callback_url=${window.location.href}`
      const formData = new FormData();
      const googleToken = localStorage.getItem("googleToken")
      const isfacebook = localStorage.getItem("isFacebookConnect")
      this.setState({isFacebookConnect: isfacebook})
    if (code) {
      formData.append("code", code);
      formData.append("token", googleToken);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectYoutubeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodConnectYoutube
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  connectGoogle = async() => {
    try {
      const header = {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("userToken")
      };

      const baseUrl = `https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/authorize?callback_url=${window.location.href}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectGoogleApiCallId = requestMessage.messageId;
    localStorage.setItem("isFacebookConnect", false)
    this.setState({isFacebookConnect: false})

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodConnectGoogle
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }


  connectTwitchAccount = async(response) => {
    try {
      const header = {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("userToken")
      };
    const formData = new FormData();
    const baseUrl = `https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/twitch?callback_url=${window.location.href}`

    if (response.data) {
      formData.append("user_name", response?.data?.preferred_username);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectTwitchAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodConnectTwitchAccount
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }


  connectSocialAccount = async(response) => {
    try {
      const header = {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("userToken")
      };
    const formData = new FormData();

    if (response.graphDomain === "facebook") {
      formData.append("social_profile[provider]", "facebook");
      formData.append("social_profile[unique_auth_id]", response.id);
      formData.append("social_profile[user_name]", response.name);
      formData.append("facebook_token", response.accessToken);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectSocialAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointConnectSocialAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodConnectSocialAccount
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  getSocialAccounts = async() => {
    try {
      const header = {
        'Content-Type': 'application/json',
        token: localStorage.getItem("userToken")
      };

    const id = localStorage.getItem("userId")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSocialAccountsApiCallId = requestMessage.messageId;
    this.setState({ loading: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointGetSocialAccounts}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodGetSocialAccounts
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  sendProfessionalAccount = async(account_type) => {
    try {

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.sendProfessionalAccountApiCallId = requestMessage.messageId;

    const id = localStorage.getItem("userId")

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointsendProfessionalAccount+`${id}`
    );
    const header = {
      // 'Content-Type': 'multipart/form-data',
      token: localStorage.getItem("userToken")
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();
    formData.append("account[professional_account_type]", account_type);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodsendProfessionalAccount
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  editSecurity = async(checked) => {
    try {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editSecurityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointeditSecurity
    );
    const header = {
      // 'Content-Type': 'multipart/form-data',
      token: localStorage.getItem("userToken")
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();
    formData.append("two_factor_auth", checked);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodeditSecurity
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  editProfile = async(values) => {
    try {

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editProfileApiCallId = requestMessage.messageId;

    const id = localStorage.getItem("userId")

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointeditProfile+`${id}`
    );
    const header = {
      // 'Content-Type': 'multipart/form-data',
      token: localStorage.getItem("userToken")
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();
    formData.append("account[user_name]", values.userName);
    formData.append("account[first_name]", values.name);
    formData.append("account[email]", values.email);
    formData.append("account[full_phone_number]", values.phoneNumber);
    formData.append("account[gender]", values.gender);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodeditProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  getUserData = () => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

    const id = localStorage.getItem("userId")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDataApiCallId = requestMessage.messageId;
    this.setState({ loading: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointGetUserData}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodGetUserData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  deleteAccount = () => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

    const id = localStorage.getItem("userId")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccountApiCallId = requestMessage.messageId;
    this.setState({ loading: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointDeleteAccount}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodDeleteAccount
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }



  setLanguage = async (langauge) => {
    try {
      // this.setState({loading: true})
      
      const header = {
        "token": localStorage.getItem('userToken')
      };

      const formData = new FormData();
      if(langauge === "br") {
        formData.append('language', "pt")
      }else if (langauge === "kr"){
        formData.append('language', "ko")
      }else if (langauge === "id"){
        formData.append('language', "in")
      }else if (langauge === "th"){
        formData.append('language', "th")
      }else{
        formData.append('language', "en")
      }
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setLanguageApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApisetLanguage
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApisetLanguage
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }

  checkUserInfo(t) {
    const validations  = Yup.object().shape({
      email: Yup.string()
      .trim()
      .email(`${t("Invalid email address")}`)
      .required(`${t("This field is required.")}`),
      name: Yup.string().required(`${t("This field is required.")}`),
      userName: Yup.string().required(`${t("This field is required.")}`),
      phoneNumber: Yup.string().matches(
        // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        /^[0-9-+()\s]*$/,
        `${t('Phone number is not valid')}`)
        .max(16, `${t('Phone number is not valid')}`)
      .trim()
      .required(`${t("This field is required.")}`),
      gender: Yup.string().required(`${t("This field is required.")}`),
    })
    return validations
}
   // Customizable Area End
  
}
