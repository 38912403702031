export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const ProfileIcon = require("../assets/profile-icon.png");
export const PeopleIcon = require("../assets/people-icon.png");
export const UserImage = require("../assets/user-image.png");
export const FlagImage = require("../assets/flag-icon.png");

export const SportsPlayer = require("../assets/sports-player.png");
export const SportsTeam = require("../assets/sports-team.png");
export const GamePublisher = require("../assets/game-publisher.png");
export const Designer = require("../assets/designer.png");
export const Engineer = require("../assets/engineer.png");
export const Media = require("../assets/media.png");
export const VideoEditor = require("../assets/video-editor.png");
export const logo = require("../assets/logo.png");
export const ProgressImage = require("../assets/progress-image.png");

export const verify = require("../assets/verify.png");