// import RubikBold from '../assets/font/Rubik-VariableFont_wght.ttf';

// const Rubik_Bold = {
//   fontFamily: 'Rubik',
//   fontWeight: 'bold',
//   fontStretch: 'normal',
//   fontStyle: 'normal',
//   src: `
//       local('Rubik-Bold'),
//       url(${RubikBold}) format('ttf')
//     `
// };

import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    },
    secondary:{
      main:'rgba(0, 0, 0, 0.87)'
    }
  },
  // typography: {
  //   h1: {
  //     fontSize: 40,
  //     fontWeight: 700,
  //     color: '#FFFFFF',
  //     fontFamily: 'Montserrat-Bold'
  //   },
  //   h2: {
  //     fontSize: 32,
  //     fontWeight: 700,
  //     color: '#FFFFFF',
  //     fontFamily: 'Montserrat-Bold'
  //   },
  //   h3: {
  //     fontSize: 24,
  //     fontWeight: 700,
  //     fontFamily: 'Montserrat-Bold'
  //   },
  //   h4: {
  //     fontSize: 16,
  //     fontWeight: 700,
  //     fontFamily: 'Montserrat-SemiBold'
  //   },
  //   h5: {
  //     fontSize: 35,
  //     fontWeight: 500,
  //     color: '#FFFFFF',
  //     fontFamily: 'Montserrat-Bold'
  //   },
  //   subtitle1: {
  //     fontSize: 14,
  //     lineHeight: 1.325,
  //     fontWeight: 400,
  //     fontFamily: 'Montserrat-Regular'
  //   },
  //   caption: {
  //     fontSize: 13,
  //     fontWeight: 100,
  //     fontFamily: 'Montserrat-Regular'
  //   },
  //   body1: {
  //     fontSize: 10,
  //     fontWeight: 100,
  //     fontFamily: 'Montserrat-Regular'
  //   },
  //   allVariants: {
  //     // fontFamily: 'Montserrat Montserrat-Bold Montserrat-Regular',
  //   }
  // },
  overrides: {
    // MuiCssBaseline: {
    //   '@global': {
    //     '@font-face': [
    //       Montserrat_Bold,
    //       Montserrat_Regular,
    //       Montserrat_Light,
    //       Montserrat_SemiBold
    //     ]
    //   }
    // },
    // MuiInputBase: {
    //   root: {
    //     fontSize: '14px',
    //     fontWeight: 400,
    //     color: '#FFFFFF',
    //     fontFamily: 'Montserrat-Regular'
    //   },
    //   input:{
    //     color: '#FFFFFF',
    //     fontFamily: 'Montserrat-Regular'
    //   }
    // },
    MuiOutlinedInput: {
      root: {
        borderRadius: "10px",
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'rgb(0 0 0 / 8%)',
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgb(0 0 0 / 8%)"
        },
      },
      input: {
        padding: '20px',
        '&::placeholder': {
          color: 'rgb(0 0 0 / 8%)'
        },
      },
      notchedOutline: {
        borderColor: 'rgb(0 0 0 / 8%)',
      }
    },
    PrivateNotchedOutline: {
      root: {
        borderWidth: "3px",
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '15px',
        color: "rgb(0 0 0 / 22%)",
        fontFamily: "Manrope-bold",
      },
    },
    // MuiMenuItem: {
    //   root: {
    //     fontSize: '14px',
    //     fontWeight: 400,
    //     fontStretch: 'normal',
    //     fontStyle: 'normal',
    //     lineHeight: 'normal',
    //     letterSpacing: 'normal',
    //     color: '#E1E1E1',
    //     fontFamily: 'Montserrat-Regular'
    //   }
    // },
    // MuiFormHelperText: {
    //   root: {
    //     fontSize: '12px',
    //     fontWeight: 'normal',
    //     fontStretch: 'normal',
    //     fontStyle: 'normal',
    //     lineHeight: 'normal',
    //     letterSpacing: 'normal',
    //     color: '#F44336!important',
    //     fontFamily: 'Montserrat-Regular',
    //     textAlign: 'right'
    //   }
    // },
    // MuiChip: {
    //   root: {
    //     fontSize: '10px',
    //     fontWeight: 600,
    //     fontStretch: 'normal',
    //     fontStyle: 'normal',
    //     lineHeight: 'normal',
    //     letterSpacing: 'normal',
    //     color: '#000000',
    //     fontFamily: 'Montserrat-Regular'
    //   }
    // },
    MuiFormControl: {
      root: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#E1E1E1',
        width: '24%'
      }
    },
    // MuiFormControlLabel: {
    //   root: {
    //     fontSize: '14px',
    //     fontWeight: 400,
    //     color: '#E1E1E1',
    //     fontFamily: 'Montserrat-Regular!important'
    //   }
    // },
    MuiButton: {
      root: {
        textTransform: 'initial',
        borderRadius: "10px",
        fontFamily: 'Manrope-bold',
      },
      text: {
        // '&:hover': {
        //   backgroundImage: 'none',
        //   // backgroundColor:'white'
        // }
      }
    },
    // MuiInputAdornment: {
    //   positionStart: {
    //     marginRight: 0
    //   }
    // },
    MuiSvgIcon: {
      root: {
        fontSize: "x-large",
      },
    },


    MuiRadio: {
      root: {
        fontSize: "x-large",
      },
    },

    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor:'white'
        }
      },
      colorSecondary: {
        "&:hover": {
          backgroundColor:'white'
        }
      },
    },
  }
});

export default theme