//@ts-ignore
//@ts-nocheck
import {
  Box, Button, Grid, IconButton, MenuItem,
  Select, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  CameraAltOutlined, Clear, KeyboardArrowDown
} from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import { stepOne } from "./assets";
import CropImage from "./CropImage.web";
import ListingsController, { Props } from "./ListingsController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class ListingCreate extends ListingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation, t, i18n } = this.props;
    const { classes } = this.props;
    const formDetails = JSON.parse(localStorage.getItem("listingDetails"));

    const { categories } = this.state;

    return (
      <>
        <ProfileHeader
          heading={t("Listings")}
          subModule={true}
          showProfile={true}
          multiPage
          backLocation="/listings"
        />
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Formik
            initialValues={
              formDetails || {
                title: "",
                description: "",
                category: "social_media_campaign",
                banner: "",
                bannerUrl: ""
              }
            }
            validationSchema={this.CreateListingSchema(t)}
            validateOnMount={true}
            onSubmit={(values) => {
              localStorage.setItem("listingDetails", JSON.stringify(values));
              this.props.history.push("/listing/pricing");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldError
            }) => (
              <Grid
                sm={12}
                md={6}
                style={{
                  border: "1px solid #DEDDDA",
                  borderTop: "none",
                  height: "100%",
                  width: "100%"
                }}
              >
                <Box className={classes.progressBarRow}
                >
                  <Typography
                    style={{
                      color: "#808080",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: 13,
                      marginRight: 14
                    }}
                  >
                    {t("Step")} 1 of 3
                  </Typography>
                  <img src={stepOne} style={{ height: 12, width: 185 }} />
                </Box>
                <Form
                  translate="yes"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 52,
                    paddingRight: 41,
                    paddingTop: 40
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography
                      style={{
                        color: "#191919",
                        fontFamily: "Poppins",
                        // fontWeight: 700,
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Category")}
                    </Typography>

                    <Select
                      disableUnderline={true}
                      classes={{
                        select: this.props.classes.dropdown
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDown
                          style={{
                            position: "absolute",
                            left: 8,
                            top: 8,
                            height: 30,
                            width: 30,
                            color: "#8E8E8E"
                          }}
                        />
                      )}
                      placeholder={t("select category")}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.category}
                      onChange={handleChange("category")}
                    >
                      <MenuItem
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 14
                        }}
                        value="social_media_campaign"
                      >
                        {t("Social Media Campaign")}
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 14
                        }}
                        value="product_placement"
                      >
                        {t("Product Placement")}
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 14
                        }}
                        value="product_un_boxing"
                      >
                        {t("Product Un-Boxing")}
                      </MenuItem>
                    </Select>

                    {errors.category && touched.category ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.category}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box display="flex" flexDirection="column" mt="38px">
                    <Typography
                      style={{
                        color: "#191919",
                        fontFamily: "Poppins",
                        // fontWeight: 700,
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Listing title")}
                    </Typography>
                    <Field
                      maxLength={100}
                      name="title"
                      placeholder={t("Insert a name for your listing")}
                      style={{
                        border: "1px solid #DBDBDB",
                        height: 45,
                        width: "100%",
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 16,
                        paddingRight: 10,
                        outline: "none",
                        borderRadius: 16
                      }}
                    />
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 12,
                        marginTop: 5
                      }}
                    >
                      {t("Current category")}:{" "}
                      <Typography
                        component="span"
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          fontSize: 12,
                          display: "inline"
                        }}
                      >
                        {t(localStorage.getItem("userCategory"))}
                      </Typography>
                    </Typography>
                    {errors.title && touched.title ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.title}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box display="flex" flexDirection="column" mt="38px">
                    <Typography
                      style={{
                        color: "#191919",
                        fontFamily: "Poppins",
                        // fontWeight: 700,
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Add description")}
                    </Typography>

                    <textarea
                      maxLength={2000}
                      value={values.description}
                      name="description"
                      onChange={handleChange("description")}
                      placeholder={t("Add a quick description")}
                      style={{
                        border: "1px solid #DBDBDB",
                        height: 119,
                        width: "100%",
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 16,
                        paddingRight: 10,
                        paddingTop: 18,
                        outline: "none",
                        borderRadius: 16,
                        resize: "none"
                      }}
                    />
                    {errors.description && touched.description ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.description}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    mt="16px"
                    mb="40px"
                  >
                    <Typography
                      style={{
                        color: "#191919",
                        fontFamily: "Poppins",
                        // fontWeight: 700,
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Listing banner")}
                    </Typography>

                    <Box
                      style={{
                        border: "1px solid #DBDBDB",
                        height: 244,
                        width: "100%",
                        outline: "none",
                        borderRadius: 16,
                        resize: "none",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundImage: values.bannerUrl
                          ? `url(${values.bannerUrl})`
                          : ""
                      }}
                    >
                      <input
                        type="file"
                        onChange={(e: any) => {
                          this.handleSelectBanner(
                            e,
                            setFieldValue,
                            setFieldError
                          );
                        }}
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          zIndex: 2,
                          cursor: "pointer",
                          opacity: 0
                        }}
                        accept="image/png, image/jpeg, image/jpg"
                      />

                      {this.state.ImgDialogOpen && (
                        <CropImage
                          handleClose={() => {
                            setFieldValue("bannerUrl", null);
                            this.setState({ ImgDialogOpen: false });
                          }}
                          url={values.bannerUrl}
                          open={this.state.ImgDialogOpen}
                          getCropImageUrl={(url) => {
                            setFieldValue("bannerUrl", url);
                            this.setState({ ImgDialogOpen: false });
                          }}
                        />
                      )}

                      {values.bannerUrl && (
                        <Box style={{ zIndex: 10 }}>
                          <IconButton
                            style={{ marginRight: 30, position: "relative" }}
                          >
                            <input
                              type="file"
                              onChange={(e: any) => {
                                this.handleSelectBanner(
                                  e,
                                  setFieldValue,
                                  setFieldError
                                );
                              }}
                              style={{
                                position: "absolute",
                                zIndex: 2,
                                height: "100%",
                                width: "100%",
                                cursor: "pointer",
                                opacity: 0
                              }}
                              accept="image/png, image/jpeg, image/jpg"
                            />
                            <CameraAltOutlined
                              style={{
                                color: "white",
                                width: 24,
                                height: "auto"
                              }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={(e: any) =>
                              this.handleSelectBanner(
                                "",
                                setFieldValue,
                                setFieldError
                              )
                            }
                          >
                            <Clear
                              style={{
                                color: "white",
                                width: 26,
                                height: "auto"
                              }}
                            />
                          </IconButton>
                        </Box>
                      )}
                      {values.bannerUrl && (
                        <Box
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            zIndex: 5,
                            backgroundColor: "black",
                            opacity: 0.5
                          }}
                        />
                      )}

                      {!values.bannerUrl && (
                        <Typography
                          style={{
                            color: "rgba(0, 0, 0, 0.38)",
                            fontFamily: "Poppins",
                            // fontWeight: 700,
                            fontSize: 22
                          }}
                        >
                          {t("Choose banner")}
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex">
                      <Typography
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 12,
                          marginTop: "8px"
                        }}
                      >
                        {t("Ideal size")}:{" "}
                        <Typography
                          style={{
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            // fontWeight: 700,
                            fontSize: 12,
                            display: "inline"
                          }}
                        >
                          {t("1920x1080px")}
                        </Typography>
                      </Typography>
                    </Box>
                    {errors.bannerUrl && touched.bannerUrl ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.bannerUrl}
                      </Typography>
                    ) : null}

                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{
                          backgroundColor: "#F14E24",
                          border: "1px solid #D9DBE9",
                          borderRadius: 16,
                          height: 34,
                          marginBottom: 14,
                          boxShadow: "none",
                          color: "#F7F7FC",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14,
                          marginTop: 30
                        }}
                      >
                        {t("Next")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Grid>
            )}
          </Formik>
        </Grid>
      </>
    );
  }

  async componentDidMount() {
    // this.getValidations();
    // this.fetchCategories();
    // this.requestSessionData();
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
export const listingCreateStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 40,
    // paddingRight: 10,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
      // backgroundColor: "white",
      // zIndex: 2
    }
  },
  progressBarRow: {
    marginRight: "30px", 
    marginTop: "-39px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
   
    "@media (max-width: 768px)": {
      marginRight: "0", 
      marginTop: "-32px",
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      marginTop: "15px",
    },
   
  },
};
// Customizable Area End

export default withTranslation()(
  withStyles(listingCreateStyle)(
    withRouter(ListingCreate as React.ComponentType<any>)
  )
);
