//@ts-ignore
//@ts-nocheck
import React from "react";
import { Box, Link, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";

import { notificationImg } from "./assets";
//@ts-ignore
import { notificationStyle } from "../assets/notificationStyle";
import Snackbar from "../../../components/src/Snackbar";
import { withTranslation } from 'react-i18next';
import NotificationsController, { Props } from "./NotificationsController";
import Copyright from "../../../components/src/Copyright";

class PushNotification extends NotificationsController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  render() {
    const { classes, t, i18n } = this.props;

    return (
      <Box className={classes.body}>
        <Typography className={classes.heading}>
          {t("Allow Desktop Notifications")}
        </Typography>
        <Box display="flex" flexDirection="column" marginTop="85px">
          <Box textAlign="center">
            <img src={notificationImg} className={classes.img} />
          </Box>
          <Typography className={classes.subHeading}>
            {t("Turn On Notifications")}
          </Typography>
          <Typography className={classes.text}>
            {t("Get the most out of yup.gg by keeping yourself updated")}
          </Typography>
          <Button
            color="primary"
            className={classes.btnText}
            onClick={this.onAllowNotification}
          >
            {t("Allow Notifications")}
          </Button>
          <Typography style={{fontFamily: "Poppins", fontWeight: 400, fontSize: 14, height: 34}}
            className={classes.skipText}
            onClick={() => {
              window.location.replace("/profile-dashboard");
            }}
          >
            {t("Skip for Now")}
          </Typography>
          <Box mt="110px" marginBottom="19%" textAlign="center">
          <Copyright />
          </Box>
        </Box>
      
      </Box>
    );
  }
}

export default withTranslation()(withStyles(notificationStyle)(withRouter(PushNotification)))