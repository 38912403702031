//@ts-ignore
//@ts-nocheck
import {
  Box, Button, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from 'react-i18next';
import Loader from "../../../components/src/Loader.web";
import HighlightedContentController, { Props } from "./HighlightedContentController";
import ProfileHeader from './ProfileHeader';
import SideMenu from "./SideMenu.web";

class HighlightedContent extends HighlightedContentController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getHighlightedContent();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  render() {
    const { classes, t , i18n } = this.props;
    return (
      <>
        <ProfileHeader heading={t("Highlighted Content")} subModule={this.state.subModule} showProfile />
        <Grid className={classes.mainWrapper}>
          <Grid container>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Typography className={classes.videoDesc}>
                  {t("Please provide an URL for your best video content to be featured in your profile")}
                </Typography>
                <Formik
                  initialValues={{
                    vidoes: this.state?.getHighlightedContent || [],
                    mainVideo: this.state?.mainVideo || "",
                    mainVideoTitle:this.state.mainTitle || ''
                  }}
                  validateOnMount={true}
                  validationSchema={this.HighlightedContentSchema(t)}
                  onSubmit={(values) =>{
                    this.createHighlightedContent(values)
                  }
                  }
                  enableReinitialize
                  render={({ values, errors, touched }) => {
                    return (
                      <Form style={{
                        width: "100%"
                      }} noValidate>
                        <Box style={{ width: "100%", marginTop: "20px" }}>
                          <label className={classes.videoLabel}>
                            {t("Main Video")}
                          </label>
                          <Box>
                          <Field
                          name="mainVideoTitle"
                          type="text"
                          placeholder={t("Example- main video title")}
                            style={{
                              width: "100%",
                              padding: "10px 20px",
                              borderRadius: "15px",
                              border: "1px solid #ccc",
                              color: "#081F32",
                              outline: "none",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop:"10px"
                            }}
                          />
                           <ErrorMessage name={`mainVideoTitle`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />       
                           <Field
                            name="mainVideo"
                            type="url"
                            placeholder={t("Example -https://www.youtube.com/watch?v=0HhWIvPhbu0")}
                            style={{
                              width: "100%",
                              padding: "10px 20px",
                              borderRadius: "15px",
                              border: "1px solid #ccc",
                              color: "#081F32",
                              outline: "none",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              // marginLeft:"4px",
                              marginTop:"10px",
                            }}
                          />
                           <ErrorMessage name={`mainVideo`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                          </Box>
                        </Box>
  
                        <FieldArray
                          name="vidoes"
                          render={(arrayHelpers,remove, push) => (
                            <>
                              {values.vidoes && values.vidoes.length > 0
                                ? values.vidoes.map((video, index) => (
                                  <>
                                    <Box
                                      key={index}
                                      style={{ width: "100%", marginTop: "20px" }}
                                    ><div style={{display:"flex",justifyContent: "space-between"}}>
                                      <label
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: 13,
                                          display: "block",
                                          color: "#081F32",
                                          fontWeight: 400,
                                          marginTop: "10px",
                                        }}
                                      >
                                        {t("Video")} {index + 1}
                                      </label>
                                      <div style={{ marginTop: '8px', color: '#F14E24', marginLeft: '10px' }}><DeleteIcon onClick={() => {
                                         arrayHelpers.remove(index)
                                          this.deleteHighlightedContent(index)
                                        }} /></div>
                                        </div>
                                      <Box>
                                        <Field
                                        name={`vidoes.${index}.title`}
                                        type="text"
                                        placeholder={t("Example-title")}
                                          style={{
                                            width: "100%",
                                            padding: "10px 20px",
                                            borderRadius: "15px",
                                            border: "1px solid #ccc",
                                            color: "#081F32",
                                            outline: "none",
                                            fontFamily: "Poppins",
                                            fontSize: 13,
                                          }}
                                        />
                                         <ErrorMessage name={`vidoes.${index}.title`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                                         <Field
                                           name={`vidoes.${index}.video_url`}
                                           type="url"
                                           placeholder={t("Example -https://www.youtube.com/watch?v=0HhWIvPhbu0")}
                                          style={{
                                            width: "100%",
                                            padding: "10px 20px",
                                            borderRadius: "15px",
                                            border: "1px solid #ccc",
                                            color: "#081F32",
                                            outline: "none",
                                            fontFamily: "Poppins",
                                            fontSize: 13,
                                            // marginLeft:"4px",
                                            marginTop:"10px",
                                          }}
                                        />
                                        <ErrorMessage name={`vidoes.${index}.video_url`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                                      </Box>  
                                    </Box>
                                  </>
                                ))
                                : null}
                              <Box
                                style={{
                                  padding: 3,
                                  cursor: "pointer",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  marginTop: 25,
                                  borderRadius: 16,
                                  border: "2px dotted #DBDBDB",
                                }}
                                onClick={() => arrayHelpers.push({ video_url: "", title: "" })}
                              >
                                <Add
                                  style={{
                                    marginTop: 5,
                                    color: "rgba(0,0,0,0.38)",
                                    height: 24,
                                    width: 24,
                                  }}
                                />
                              </Box>
  
                              <Box width="100%">
                                <Button
                                  variant="contained"
                                  type="submit"
                                  style={{
                                    backgroundColor: "#F14E24",
                                    border: "1px solid #D9DBE9",
                                    borderRadius: 16,
                                    height: 35,
                                    marginBottom: 14,
                                    boxShadow: "none",
                                    color: "#F7F7FC",
                                    fontFamily: "Poppins",
                                    fontSize: 13,
                                    marginTop: 18,
                                    float: "right",
                                  }}
                                >
                                  {t("Save")}
                                </Button>
                              </Box>
                            </>
                          )}
                        />
                      </Form>
                    )
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
            </Grid>
          </Grid>
        </Grid>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}


const highlightedContentStyle = {
  twitterBtn: {
    padding: 0,
    background: "none",
    border: "none",
    width: "80%",
  },
  videoLabel: {
    fontFamily: "Poppins",
    fontSize: 13,
    display: "block",
    color: "#081F32",
    fontWeight: 400,
    marginBottom: "10px",
  },
  mainWrapper:
  {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    },
  },
  mainBodyContent:
  {
    height: "100%",
    border: "#80808042 1px solid",
    padding: 42,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10,
    },
  },
  videoDesc:
  {
    color: "#081F32",
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight:400,
    // wordSpacing:"2px",
  },
}


export default withTranslation()(withStyles(highlightedContentStyle)(HighlightedContent))
