//@ts-ignore
//@ts-nocheck
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation } from 'react-i18next';
import Snackbar from "../../../components/src/Snackbar";
import AboutController, { Props } from "./AboutController.web";
import ProfileHeader from './ProfileHeader';
import SideMenu from "./SideMenu";



class About extends AboutController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getUserData()
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  render() {
    const { classes, t , i18n } = this.props
    return (
      <>
        <ProfileHeader heading={t("About")} subModule="3" showProfile />
        <Grid className={classes.mainWrapper + " " + classes.main}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu subModule="3" showProfile />
            </Grid>
            <Grid item xs={12} md={6} className={classes.gamingBlk}>
              <div className={classes.mainBodyContent}>
                {this.state.loading ?
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress style={{ color: "#F14E24" }} />
                  </Box>
                  :
                  <Formik
                    initialValues={{
                      description: this.state.userData?.about || "",
                    }}
                    validationSchema={this.checkAbout(t)}
                    validateOnMount={true}
                    onSubmit={(values) => {
                      this.onSaveAbout(values)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                    }) => (
                      <Form
                        translate="yes"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography className={classes.heading}>
                          {t("yup.gg is your professional home, your profile should reflect how you want the gaming industry to see you")}.
                        </Typography>
                        <Box display="flex" flexDirection="column" mt="38px">
                          <textarea
                            value={values.description}
                            name="description"
                            onChange={handleChange("description")}
                            placeholder={t("Add a quick description")}
                            className={classes.field}
                          />
                          {errors.description && touched.description ? (
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5
                              }}
                            >
                              {errors.description}
                            </Typography>
                          ) : null}
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            variant="contained"
                            type="submit"
                            style={{
                              backgroundColor: "#F14E24",
                              border: "1px solid #D9DBE9",
                              borderRadius: 16,
                              height: 34,
                              marginBottom: 14,
                              boxShadow: "none",
                              color: "#F7F7FC",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: 15,
                              marginTop: "40%",
                              padding: "18px 30px",
                            }}
                          >
                            {t("Save")}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                }
              </div>
            </Grid>
          </Grid>
          <Snackbar
            open={Boolean(this.state.error)}
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
            severity="error"
          />
        </Grid>
      </>
    )
  }
}

const aboutStyle = {
  mainWrapper:
  {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
  },
  mainBodyContent:
  {
    height: "100vh",
    border: "#80808042 1px solid",
    padding: 45,
    marginTop: 50,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10,
      marginTop: 30,
    },
  },
  heading: {
    color: "#191919",
    fontFamily: "Poppins",
    fontSize: 17,
    marginBottom: 17,
    fontWeight: 500,
  },
  field: {
    border: "1px solid #DBDBDB",
    height: 200,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    paddingRight: 10,
    paddingTop: 18,
    outline: "none",
    borderRadius: 16,
    resize: "none"
  },
  main: {
    "@media (max-width: 768px)": {
      display: "block",
    }
  },
  gamingBlk: {
    "@media (max-width: 768px)": {
      maxWidth: "100% !important",
    }
  },
}

export default withTranslation()(withStyles(aboutStyle)(About))