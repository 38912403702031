//@ts-ignore
//@ts-nocheck
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";

import { Box, Link, Typography, Button, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";

import Snackbar from "../../../components/src/Snackbar";
import { logo, profileIcon } from "./assets";
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Copyright from "../../../components/src/Copyright";

class EmailAccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes, t, i18n } = this.props;
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
        height="inherit"
        style={{
          overflowX: "hidden"
        }}
      >
        <Box
          className={classes.LogoImg}
          display="flex"
          mt="160px"
          mb="28px"
          width="494px"
          justifyContent="center"
        >
          <img src={logo} style={{ height: 45 }} />
        </Box>

        <Formik
          initialValues={{
            email: "",
            password: "",
            showPassword: false
          }}
          validationSchema={this.LoginSchema(t)}
          validateOnMount={true}
          onSubmit={(values) => {
            // same shape as initial values
            this.doLogIn(values);
          }}
        >
          {({ values, touched, errors, isValid, setFieldValue }) => (
            <Form translate="yes" style={{}}>
              <Fragment>
                <Box display="flex" flexDirection="column">
                  <Box
                    className={classes.frmInput}
                    display="flex"
                    overflow="hidden"
                    alignItems="center"
                    height="56px"
                    border="1px solid #D1D1D1"
                    borderRadius="16px"
                    bgcolor="white"
                  >
                    <Field
                      name="email"
                      placeholder={t("Phone, Username or email")}
                      style={{
                        border: "none",
                        height: "100%",
                        width: "100%",
                        color: "rgba(0, 0, 0, 0.6)",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 16,
                        marginRight: 10,
                        marginLeft: 21,
                        outline: "none"
                      }}
                    />
                    <img
                      src={profileIcon}
                      style={{ width: 24, height: 24, marginRight: 16 }}
                    />
                  </Box>
                  {errors.email && touched.email ? (
                    <Typography
                      style={{
                        color: "#F14E24",
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        fontSize: 14,
                        marginTop: 5,
                        marginLeft: 10
                      }}
                    >
                      {errors.email}
                    </Typography>
                  ) : null}

                  <Box
                    className={classes.frmInput}
                    display="flex"
                    overflow="hidden"
                    alignItems="center"
                    height="56px"
                    mt="13px"
                    border="1px solid #D1D1D1"
                    borderRadius="16px"
                    bgcolor="white"
                  >
                    <Field
                      name="password"
                      placeholder={t("Password")}
                      type={values.showPassword ? "text" : "password"}
                      style={{
                        border: "none",
                        height: "100%",
                        width: "100%",
                        color: "rgba(0, 0, 0, 0.6)",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 16,
                        marginRight: 10,
                        marginLeft: 21,
                        outline: "none"
                      }}
                    />
                    {values.showPassword ? (
                      <IconButton
                        onClick={() => setFieldValue("showPassword", false)}
                        style={{ padding: 0, backgroundColor: "transparent" }}
                        disableRipple={true}
                      >
                        <Visibility
                          style={{
                            width: 24,
                            height: 24,
                            marginRight: 16,
                            color: "#000000",
                            opacity: 0.54
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => setFieldValue("showPassword", true)}
                        style={{ padding: 0, backgroundColor: "transparent" }}
                        disableRipple={true}
                      >
                        <VisibilityOff
                          style={{
                            width: 24,
                            height: 24,
                            marginRight: 16,
                            color: "#000000",
                            opacity: 0.54
                          }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  {errors.password && touched.password ? (
                    <Typography
                      style={{
                        color: "#F14E24",
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        fontSize: 14,
                        marginTop: 5,
                        marginLeft: 10
                      }}
                    >
                      {errors.password}
                    </Typography>
                  ) : null}

                  <Button
                    className={classes.login}
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "#F14E24",
                      border: "1px solid #D9DBE9",
                      borderRadius: 16,
                      height: 56,
                      marginBottom: 14,
                      boxShadow: "none",
                      color: "#F7F7FC",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: 16,
                      marginTop: 10
                    }}
                  >
                    {t("login")}
                  </Button>
                </Box>
              </Fragment>
            </Form>
          )}
        </Formik>

        <Box>
          <Typography
            style={{
              color: "#000000",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}
          >
            {t("Don’t have an account")}?{" "}
            <Link
              href="/claim-username"
              underline="none"
              style={{
                fontSize: 12,
                color: "#F14E24",
                fontFamily: "Roboto",
                fontWeight: 500,
                marginLeft: 5
              }}
            >
              {t("Sign up")}
            </Link>
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mt="23px">
          <Box width="159px" height="1px" bgcolor="rgba(0, 0, 0, 0.1)" />
          <Typography
            style={{
              color: "rgba(0, 0, 0, 0.25)",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 12,
              marginRight: 14,
              marginLeft: 14
            }}
          >
            {t("OR")}
          </Typography>
          <Box width="159px" height="1px" bgcolor="rgba(0, 0, 0, 0.1)" />
        </Box>

        <Link
          href="/social-login"
          underline="none"
          style={{
            fontSize: 14,
            color: "#F14E24",
            fontFamily: "Roboto",
            fontWeight: 500,
            marginTop: 23,
            marginBottom: 20
          }}
        >
          {t("Login with social media")}
        </Link>

        <Link
          href="/forgot-password"
          underline="none"
          style={{
            fontSize: 14,
            color: "#F14E24",
            fontFamily: "Roboto",
            fontWeight: 500
          }}
        >
          {t("Forgot your password")}
        </Link>

        <Box mt="100px" mb="100px" className={classes.copyright}>
          <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    const data = localStorage.getItem("_oldAdjustableElements");
    // window.localStorage.clear();
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userNameToken");
    localStorage.removeItem("adjustableElements");
    localStorage.removeItem("googleToken");

    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
    localStorage.setItem("_oldAdjustableElements", data);
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }

  // Customizable Area End
});
const EmailAccountLoginStyle = {
  LogoImg: {
    "@media(max-width: 767px)": {
      marginTop: "40px",
      width: "100px"
    }
  },
  copyright: {
    "@media(max-width: 767px)": {
      margin: "50px 0px !important"
    }
  },
  frmInput: {
    width: "340px",
    "@media(max-width: 767px)": {
      width: "100%"
    },
  },
  copyright: {
    "@media(width: 320px)": {
      margin: "40px 0px"
    }
  }
};
export default withTranslation()(
  withStyles(EmailAccountLoginStyle)(EmailAccountLogin)
);
