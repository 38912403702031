"use strict";

const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL;

