import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as yup from "yup";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  auth: string | null | undefined;
  loading: boolean;
  getUserData: any;
  error: any;
  oldpassworderror: any;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  auth: string | null | undefined = window.localStorage.getItem("userToken");
  getUserDataApiCallId: any;
  updatePasswordDataApiCallId: any;
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      // Customizable Area Start
      // Customizable Area End
      loading: false,
      auth: "",
      getUserData: {},
      error: "",
      oldpassworderror: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getUserData();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      //runEngine.debugLog("Message Recived", message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson.data) {
        if (apiRequestCallId === this.getUserDataApiCallId) {
          this.setState({ getUserData: responseJson.data, loading: false });
        } else if (apiRequestCallId === this.updatePasswordDataApiCallId) {
          this.setState({ loading: false });
          window.location = "/" as any;
          window.localStorage.clear();
        }
      } else if (
        apiRequestCallId === this.updatePasswordDataApiCallId &&
        responseJson.errors
      ) {
        this.setState({ error: responseJson.errors[0].profile });
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  getUserData = () => {
    try {
      const header = {
        token: this.auth,
      };

      const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getUserDataApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getUserDataAPIEndPoint}${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  changePassword = (values: any) => {
    try {
      const header = {
        token: this.auth,
        "Content-Type": configJSON.validationApiContentType,
      };
      const httpBody = {
        data: {
          current_password: values.oldPassword,
          new_password: values.confirmPassword,
        },
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePasswordDataApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updatePasswordAPIEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  changePasswordValidations(t) {  
    const validations = yup.object().shape({
      oldPassword: yup.string().required(`${t("Old Password is required.")}`),

      newPassword: yup
        .string()
        .min(8, `${t("Minimum Password length is 8.")}`)
        .required(`${t("New Password is required.")}`)
         .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
          `${t("Password must contain atleast a capital letter, a lowercase letter, a number and a special character.")}`
        ),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], `${t("Password must match")}` )
        .required(`${t("Confirm Password is required.")}` ),
    });

    return validations;
  }
}
