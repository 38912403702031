//@ts-ignore
//@ts-nocheck
import { Animation } from "@devexpress/dx-react-chart";
import {
  Chart, Legend, PieSeries
} from "@devexpress/dx-react-chart-material-ui";
import {
  Avatar, Box, Button,
  Chip, Grid, IconButton, Link, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import {
  backIcon, BellplusIcon, calendarIcon, CoverPhoto2, facebookImg,
  instagramImg, locationIcon, profileImg1, twitterImg, verify, youtubeIcon, youtubeImg
} from "./assets";
const data = [
  { region: "Asia", val: 4119626293 },
  { region: "Africa", val: 1012956064 },
  { region: "Northern America", val: 344124520 },
  { region: "Latin America and the Caribbean", val: 590946440 },
  { region: "Europe", val: 727082222 },
  { region: "Oceania", val: 35104756 },
];

class ListingDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  render() {
    const{t, i18n }=this.props;
    return (
      <>
        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid sm={12} md={3} style={{ height: "100%" }}>
            <Box
              style={{
                height: 64,
                borderBottom: "1px solid #DEDDDA",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SideMenu
                profileBoxStyle={{ bottom: 0 }}
                sideMenuContainerStyles={{ marginTop: 30 }}
              />
            </Box>
          </Grid>
          <Grid
            sm={12}
            md={5}
            style={{
              border: "1px solid #DEDDDA",
              borderTop: "none",
              height: "100%",
            }}
          >
            <Box
              style={{
                height: 64,
                borderBottom: "1px solid #DEDDDA",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{ marginLeft: 0 }}
                onClick={() => this.props.navigation.goBack()}
              />
              <Typography
                style={{
                  color: "#081F32",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: 18,
                }}
              >
                <img
                  src={backIcon}
                  style={{ height: "16px", marginRight: "10px" }}
                />
                {t("Nobru")}
              </Typography>
            </Box>

            <Box style={{ position: "relative" }}>
              <img src={CoverPhoto2} style={{ width: "100%" }} />
              <img
                src={profileImg1}
                style={{
                  width: "100px",
                  position: "absolute",
                  left: "17px",
                  bottom: "-45px",
                }}
              />
            </Box>

            <Box style={{ padding: "60px 20px 15px", position: "relative" }}>
              <Button
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #F14E24",
                  borderRadius: 16,
                  height: 35,
                  marginBottom: 14,
                  boxShadow: "none",
                  color: "#F14E24",
                  fontFamily: "Poppins",
                  fontSize: 13,
                  marginTop: 20,
                  position: "absolute",
                  top: "0px",
                  right: "10px",
                }}
              >
                {t("Make Offer")}
              </Button>
              <img
                src={BellplusIcon}
                style={{
                  position: "absolute",
                  height: "32px",
                  right: "132px",
                  top: "22px",
                  cursor: "pointer",
                }}
              />
              <Typography
                style={{
                  color: "#081F32",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                {t("Nobru")}{" "}
                <img src={verify} style={{ height: 13, marginLeft: "5px" }} />
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  style={{
                    color: "#969696",
                    fontSize: "1.2rem",
                    fontWeight: "400",
                    paddingRight: "10px",
                    borderRight: "1px solid #969696",
                  }}
                >
                  <img
                    src={locationIcon}
                    style={{ height: "18px", paddingRight: "5px" }}
                  />
                  {t("Brazil")}
                </Typography>
                <Typography
                  style={{
                    color: "#969696",
                    fontSize: "1.2rem",
                    fontWeight: "400",
                    paddingLeft: "10px",
                  }}
                >
                  <img
                    src={calendarIcon}
                    style={{ height: "18px", paddingRight: "5px" }}
                  />
                  {t("Deadline")} : January 21, 2020
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  style={{
                    color: "#2ED48A",
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    borderRight: "1px solid #969696",
                    paddingRight: "10px",
                  }}
                >
                  {t("Product Placement")}
                </Typography>
                <Typography
                  style={{
                    color: "#2ED48A",
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    paddingLeft: "10px",
                    borderRight: "1px solid #969696",
                    paddingRight: "10px",
                  }}
                >
                  S$ 100/Month
                </Typography>
                <Typography
                  style={{
                    color: "#969696",
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    paddingLeft: "10px",
                  }}
                >
                  {t("Platform")}:{" "}
                  <img
                    src={youtubeIcon}
                    style={{ height: "18px", paddingLeft: "5px" }}
                  />
                </Typography>
              </Box>
            </Box>

            <Box>
              <Box style={{ padding: "10px 20px 0px" }}>
                <Typography
                  style={{
                    color: "#353535",
                    fontFamily: "Poppins",
                    fontSize: 18,
                    marginTop: 5,
                    fontWeight: 600,
                    marginBottom: 7,
                  }}
                >
                  {t("Listing Title")}
                </Typography>

                <Typography
                  style={{
                    color: "#6b6b6b",
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  {t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...")}
                  <Link href="" style={{ color: "rgb(241, 78, 36)" }}>
                    {t("See More")}
                  </Link>
                </Typography>
              </Box>

              <Box style={{ padding: "10px 20px 0px" }}>
                <Typography
                  style={{
                    color: "#353535",
                    fontFamily: "Poppins",
                    fontSize: 18,
                    marginTop: 5,
                    fontWeight: 600,
                    marginBottom: 7,
                  }}
                >
                  {t("Duration")}
                </Typography>

                <Typography
                  style={{
                    color: "#6b6b6b",
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  6 {t("Months")}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid sm={12} md={4}>
            <Box
              style={{
                height: 64,
                borderBottom: "1px solid #DEDDDA",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 32,
                paddingRight: 40,
              }}
            >
              <Box display="flex">
                <Typography
                  style={{
                    color: "#0A0B0D",
                    fontFamily: "Manrope",
                    fontWeight: 200,
                    fontSize: 14,
                    marginRight: 6,
                  }}
                >
                  {t("Your profile")}:
                </Typography>
                <Typography
                  style={{
                    color: "#0A0B0D",
                    fontFamily: "Manrope",
                    fontWeight: 200,
                    fontSize: 14,
                  }}
                >
                  https://yup.gg/nobrutv
                </Typography>
              </Box>

              <Button
                variant="outlined"
                style={{
                  color: "#686D73",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: 13,
                }}
              >
                {t("Share")}
              </Button>
            </Box>

            <Box style={{ padding: "25px" }}>
              <Box
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "1.5rem",
                    padding: "12px",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  {t("Social Media")}
                </Typography>
                <Box
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Chip
                    avatar={<Avatar alt="Natacha" src={twitterImg} />}
                    label="1.7 milion"
                    style={{
                      backgroundColor: "#f3f3f3",
                      padding: "25px 10px",
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Chip
                    avatar={<Avatar alt="Natacha" src={youtubeImg} />}
                    label="4.9 milion"
                    style={{
                      backgroundColor: "#f3f3f3",
                      padding: "25px 10px",
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Chip
                    avatar={<Avatar alt="Natacha" src={facebookImg} />}
                    label="4.9 milion"
                    style={{
                      backgroundColor: "#f3f3f3",
                      padding: "25px 10px",
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Chip
                    avatar={<Avatar alt="Natacha" src={instagramImg} />}
                    label="4.9 milion"
                    style={{
                      backgroundColor: "#f3f3f3",
                      padding: "25px 10px",
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Chip
                    avatar={<Avatar alt="Natacha" src={youtubeImg} />}
                    label="4.9 milion"
                    style={{
                      backgroundColor: "#f3f3f3",
                      padding: "25px 10px",
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  />
                </Box>
              </Box>

              <Box
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "1.5rem",
                    padding: "12px",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  {t("Audience")}
                </Typography>
                <Box
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Chart */}
                  <Chart data={data} style={{ width: "100%", height: "275px" }}>
                    <PieSeries
                      valueField="val"
                      argumentField="region"
                      innerRadius={0.8}
                    />
                    {/* <Title text="The Population of Continents and Regions" /> */}
                    <Legend
                      rootComponent={LegendRoot}
                      labelComponent={LegendLabel}
                      position="bottom"
                    />
                    <Animation />
                  </Chart>
                </Box>
              </Box>

              <Box style={{ border: "1px solid #ccc", borderRadius: "20px" }}>
                <Typography
                  style={{
                    fontSize: "1.5rem",
                    padding: "12px",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  {t("Skills")}
                </Typography>
                <Box
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Chip
                    label="Brand Concepts"
                    style={{
                      backgroundColor: "#f3f3f3",
                      padding: "25px 10px",
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Chip
                    label="Brand Design"
                    style={{
                      backgroundColor: "#f3f3f3",
                      padding: "25px 10px",
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Chip
                    label="Creative Design"
                    style={{
                      backgroundColor: "#f3f3f3",
                      padding: "25px 10px",
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <Chip
                    label="Logo Design"
                    style={{
                      backgroundColor: "#f3f3f3",
                      padding: "25px 10px",
                      fontSize: "1.1rem",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}
const legendStyles = () => ({
  root: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
const legendLabelStyles = () => ({
  label: {
    whiteSpace: "wrap",
  },
});

const LegendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const LegendRoot = withStyles(legendStyles, { name: "LegendRoot" })(
  LegendRootBase
);

const LegendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const LegendLabel = withStyles(legendLabelStyles, { name: "LegendLabel" })(
  LegendLabelBase
);

export default withTranslation()(withRouter(ListingDetails));
