import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loading: boolean;
  error: string | null;
  popularProfiles: any[];
  categories: any[];
  featuredGamers: any[];
  contentCreators: any[];
  esportsPlayers: any[];
  totalTasks: number;
  completedTasks: number;
  showMorePopularProfiles: boolean;
  showMoreCategories: boolean;
  showMoreFeaturedGamers: boolean;
  showMoreContentCreators: boolean;
  showMoreEsportsPlayers: boolean;
  recentSearches: any[];
  searchKeyword: string;
  showSearchResults: boolean;
  searchResults: any[];
  value: number;
  categoryProfiles: any[]
  selectedCategory: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getDiscoveryApiCallId: string;
  getCategoryProfilesApiCallId: string;
  getCategoriesApiCallId: string;
  connectAccountApiCallId: string;
  disconnectAccountApiCallId: string;
  followAccountApiCallId: string;
  getProfileTasksApiCallId: string;
  getRecentSearchApiCallId: string;
  removeRecentSearchApiCallId: string;
  clearAllRecentSearchApiCallId: string;
  getSearchResultsApiCallId: string;
  createRecentSearchApiCallId: string;
  searchInputRef: any = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loading: false,
      error: null,
      popularProfiles: [],
      categories: [],
      featuredGamers: [],
      contentCreators: [],
      esportsPlayers: [],
      totalTasks: 0,
      completedTasks: 0,
      showMorePopularProfiles: false,
      showMoreCategories: false,
      showMoreFeaturedGamers: false,
      showMoreContentCreators: false,
      showMoreEsportsPlayers: false,
      recentSearches: [],
      searchKeyword: "",
      showSearchResults: false,
      searchResults: [],
      value: 0,
      categoryProfiles: [],
      selectedCategory: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (apiRequestCallId === this.getDiscoveryApiCallId) {
        if (responseJson.data) {
          
          const contentCreators = responseJson.data.find(item => item.name === 'Content Creator');
          const esportsPlayers = responseJson.data.find(item => item.name === 'Esports Pro Player');
          const gamers = responseJson.data.find(item => item.name === 'Gamer');
          
          this.setState({ 
            loading: false, 
            popularProfiles: responseJson.data[0].accounts,
            featuredGamers: gamers.accounts,
            contentCreators: contentCreators.accounts,
            esportsPlayers:  esportsPlayers.accounts
          });
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.getCategoriesApiCallId) {
        if (responseJson.data) {
          
          this.setState({ 
            loading: false, 
            categories: responseJson.data
          });
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.getRecentSearchApiCallId) {
        if (responseJson.data) {
          
          this.setState({ 
            loading: false, 
            recentSearches: responseJson.data
          });
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.getCategoryProfilesApiCallId) {
        if (responseJson.data) {
          
          this.setState({ 
            loading: false, 
            categoryProfiles: responseJson.data
          });
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error , loading: false,  });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false,  });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.removeRecentSearchApiCallId) {
        if (responseJson.data) {
          
          this.fetchRecentSearch();
          
          this.setState({ 
            loading: false
          });
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.clearAllRecentSearchApiCallId) {
        if (responseJson.data) {
          
          this.fetchRecentSearch();
          
          this.setState({ 
            loading: false
          });
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.getSearchResultsApiCallId) {
        if (responseJson.data) {
          
          this.setState({ 
            loading: false, 
            searchResults: responseJson.data
          });
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!' });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.connectAccountApiCallId) {
        if (responseJson.data) {
          if(this.state.selectedCategory){
            this.fetchCategoryProfiles(this.state.selectedCategory.id);
          }else{
            this.fetchDiscoveryData();
          }
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error , loading: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.disconnectAccountApiCallId) {
        if (responseJson.data) {
          if(this.state.selectedCategory){
            this.fetchCategoryProfiles(this.state.selectedCategory.id);
          }else{
            this.fetchDiscoveryData();
          }
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error , loading: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.createRecentSearchApiCallId) {
        if (responseJson.data) {
          window.location = '/user-profile/' + responseJson.data.attributes.search_account_id as any;
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error , loading: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.followAccountApiCallId) {
        if (responseJson.data) {
          if(this.state.selectedCategory){
            this.fetchCategoryProfiles(this.state.selectedCategory.id);
          }else{
            this.fetchDiscoveryData();
          }
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error , loading: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
      
      if (apiRequestCallId === this.getProfileTasksApiCallId) {
        if (responseJson.data) {
          console.log(responseJson);
          this.setState({
            loading: false, 
            totalTasks: responseJson.data.profile_status.total_tasks, 
            completedTasks: responseJson.data.profile_status.completed_tasks
          })
        } else if (responseJson.errors) {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error , loading: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', loading: false });
        }

        // this.parseApiCatchErrorResponse(responseJson);
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  
  
  fetchDiscoveryData = () => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getDiscoveryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiGetDiscovery
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetDiscovery
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }
  
  fetchCategories = () => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCategoriesApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiGetCategories
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetCategories
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }
  
  fetchCompletedProfileDetails = () => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getProfileTasksApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiProfileTasks
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiProfileTasks
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }
  
  fetchRecentSearch = () => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getRecentSearchApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiRecentSearch
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiRecentSearch
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  }
  
  fetchCategoryProfiles = (categoryId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        token: localStorage.getItem("userToken")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCategoryProfilesApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiCategoryProfiles + categoryId
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiCategoryProfiles
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  connectAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };
  
      const data = {
        connection_id: userId
      };
  
      const httpBody = { data };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.connectAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiConnectAccounts
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiConnectAccounts
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  disconnectAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.disconnectAccountApiCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiDisconnectAccounts}?person_id=${userId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiDisconnectAccounts
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  followAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };
  
      const data = {
        following_id: userId
      };
  
      const httpBody = { data };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.followAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiFollowAccounts
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiFollowAccounts
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  unfollowAccount = async (userId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.followAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiUnfollowAccounts}${userId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiUnfollowAccounts
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  removeRecentSearch = async (searchId) => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.removeRecentSearchApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.endPointApiRemoveRecentSearch}/${searchId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiRemoveRecentSearch
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  clearAllRecentSearch = async () => {
    try {
      this.setState({loading: true})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.clearAllRecentSearchApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiClearAllRecentSearch
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiClearAllRecentSearch
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  fetchSearchResults = async (searchKeyword) => {
    try {
      this.setState({loading: false})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };
  
      const httpBody = { search: searchKeyword };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getSearchResultsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiGetSearchResults
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetSearchResults
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  createRecentSearch = async (userId) => {
    try {
      this.setState({loading: false})
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": localStorage.getItem('userToken')
      };
  
      const httpBody = {  account_id: userId };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createRecentSearchApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiCreateRecentSearch
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiCreateRecentSearch
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      this.setState({loading: false})
      console.log(error);
    }
  }
  
  searchHandler = (searchKeyword) => {
    this.setState({searchKeyword})
    let timer;
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      const search = searchKeyword;
      if(search === this.searchInputRef.current.value){
        this.fetchSearchResults(this.searchInputRef.current.value.trim())
      }
    }, 1000);
  }
  
  toggleShowSearchResults = (showSearchResults) => {
    this.setState({showSearchResults})
  }
  
  handleCreateRecentSearch = (userId) => {
    this.createRecentSearch(userId);
  }
  
  handleSelectCategory = (category) => {
    this.fetchCategoryProfiles(category.id);
     this.setState({
       selectedCategory: category
     })
  }
    
  // Customizable Area End
  
}
