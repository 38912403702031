//@ts-nocheck
import {
  Avatar, Box, Button,
  Checkbox, Grid, MenuItem,
  Select, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CheckBox, KeyboardArrowDown } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import { checkboxIcon } from "./assets";
import ClientsController, { Props } from "./ClientsController";
import CropImage from "./CropImage.web";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";

class ClientsCreate extends ClientsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { navigation, t, i18n } = this.props;
    const { caseStudies, categories } = this.state;
    const Years = this.getYears();
    const formDetails = JSON.parse(localStorage.getItem("clientDetails"));
    return (
      <>
        <ProfileHeader
          heading={t("Clients")}
          subModule={true}
          showProfile={true}
        />

        <Grid sm={12} container={true} style={{ backgroundColor: "#FFFFFF" }}>
          <Grid item xs={12} md={2} style={{ position: "relative" }}>
            <SideMenu showProfile />
          </Grid>
          <Formik
            initialValues={
              formDetails || {
                clientName: "",
                caseStudyName: "",
                category: "",
                isCurrentlyWorking: false,
                startMonth: "",
                startYear: "",
                endMonth: JSON.stringify({
                  label: "Jan",
                  value: "01"
                }),
                endYear: new Date().getFullYear().toString(),
                logo: "",
                logoUrl: ""
              }
            }
            validationSchema={this.CreateClientSchema(t)}
            validateOnMount={true}
            onSubmit={(values) => {
              this.handleCreateUpdateClient(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              setFieldError,
              setFieldTouched
            }) => (
              <Grid
                sm={12}
                md={6}
                style={{
                  border: "1px solid #DEDDDA",
                  borderTop: "none",
                  height: "100%",
                  width: "100%"
                }}
              >
                <Form
                  translate="yes"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 40
                  }}
                >
                  {this.state.isLogoImage && (
                    <CropImage
                      handleClose={() => {
                        setFieldValue("logo", null);
                        setFieldValue("logoUrl", null);
                        this.setState({ isLogoImage: false });
                      }}
                      circle={true}
                      width={150}
                      height={150}
                      url={values.logoUrl}
                      open={this.state.isLogoImage}
                      getCropImageUrl={(url) => {
                        setFieldValue("logo", url);
                        setFieldValue("logoUrl", url);
                        this.setState({ isLogoImage: false });
                      }}
                    />
                  )}
                  <Box display="flex">
                    <Avatar
                      src={values.logoUrl || "/"}
                      style={{ width: 38, height: 38, marginRight: 22 }}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      justifyContent="flex-start"
                    >
                      <Typography
                        style={{
                          color: "#262626",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 19,
                          marginLeft: 8
                        }}
                      >
                        {values.clientName || `${t("Client name")}`}
                      </Typography>
                      <Box display="flex">
                        <Button
                          variant="text"
                          style={{
                            color: "#F14E24",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 14
                          }}
                        >
                          <input
                            type="file"
                            onChange={(e: any) => {
                              this.handleSelectImage(
                                e,
                                setFieldValue,
                                setFieldError
                              );
                            }}
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              zIndex: 100,
                              cursor: "pointer !important",
                              opacity: 0
                            }}
                            accept="image/png, image/jpeg, image/jpg"
                          />
                          {t("Upload photo")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="column" mt="37px">
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Client name")}
                    </Typography>
                    <Field
                      maxLength={100}
                      name="clientName"
                      placeholder="i.e. yup.gg"
                      style={{
                        border: "1px solid #DBDBDB",
                        height: 45,
                        width: "100%",
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 14,
                        paddingLeft: 16,
                        paddingRight: 10,
                        outline: "none",
                        borderRadius: 16
                      }}
                    />
                    {errors.clientName && touched.clientName ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.clientName}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box display="flex" flexDirection="column" mt="30px">
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Case Study name")}
                    </Typography>

                    <Select
                      disableUnderline={true}
                      classes={{
                        select: this.props.classes.categoryDropdown
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDown
                          style={{
                            position: "absolute",
                            left: 8,
                            top: 8,
                            height: 30,
                            width: 30,
                            color: "#8E8E8E"
                          }}
                        />
                      )}
                      renderValue={(value) =>
                        value ? value : `${t("select case study")}`
                      }
                      displayEmpty={true}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        values.caseStudyName
                          ? JSON.parse(values.caseStudyName).attributes.title
                          : ""
                      }
                      onChange={handleChange("caseStudyName")}
                    >
                      {caseStudies.map((caseStudy) => (
                        <MenuItem
                          key={caseStudy.id}
                          style={{
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14
                          }}
                          value={JSON.stringify(caseStudy)}
                        >
                          {caseStudy.attributes.title}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.caseStudyName && touched.caseStudyName ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.caseStudyName}
                      </Typography>
                    ) : null}
                  </Box>

                  <Box display="flex" flexDirection="column" mt="38px">
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        marginBottom: 17
                      }}
                    >
                      {t("Category")}
                    </Typography>

                    <Select
                      disableUnderline={true}
                      classes={{
                        select: this.props.classes.categoryDropdown
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDown
                          style={{
                            position: "absolute",
                            left: 8,
                            top: 8,
                            height: 30,
                            width: 30,
                            color: "#8E8E8E"
                          }}
                        />
                      )}
                      renderValue={(value) =>
                        value ? value : `${t("select category")}`
                      }
                      displayEmpty={true}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        values.category
                          ? JSON.parse(values.category).attributes.name
                          : ""
                      }
                      onChange={handleChange("category")}
                    >
                      {categories.map((category) => (
                        <MenuItem
                          key={category.id}
                          style={{
                            color: "#8E8E8E",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: 14
                          }}
                          value={JSON.stringify(category)}
                        >
                          {category.attributes.name}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.category && touched.category ? (
                      <Typography
                        style={{
                          color: "#F14E24",
                          fontFamily: "Poppins",
                          fontWeight: 300,
                          fontSize: 14,
                          marginTop: 5
                        }}
                      >
                        {errors.category}
                      </Typography>
                    ) : null}
                  </Box>

                  <Grid container={true} className={classes.dateRow}>
                    <Grid xs={12} sm={6}>
                      <Typography
                        style={{
                          color: "#8E8E8E",
                          fontFamily: "Poppins",
                          fontSize: 16,
                          marginBottom: 17
                        }}
                      >
                        {t("Start date")}
                      </Typography>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Select
                          style={{ width: "49%" }}
                          disableUnderline={true}
                          classes={{
                            select: this.props.classes.dropdown
                          }}
                          IconComponent={() => (
                            <KeyboardArrowDown
                              style={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                height: 30,
                                width: 30,
                                color: "#8E8E8E"
                              }}
                            />
                          )}
                          renderValue={(value) =>
                            value ? value : `${t("Month")}`
                          }
                          displayEmpty={true}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            values.startMonth
                              ? `${t(JSON.parse(values.startMonth).label)}`
                              : ""
                          }
                          onChange={handleChange("startMonth")}
                          onBlur={() =>
                            this.checkEndDate(
                              values,
                              setFieldError,
                              setFieldTouched
                            )
                          }
                        >
                          {this.Months.map((month, index) => (
                            <MenuItem
                              key={index}
                              style={{
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 14
                              }}
                              value={JSON.stringify(month)}
                            >
                              {t(month.label)}
                            </MenuItem>
                          ))}
                        </Select>
                        <Select
                          style={{ width: "49%" }}
                          disableUnderline={true}
                          classes={{
                            select: this.props.classes.dropdown
                          }}
                          IconComponent={() => (
                            <KeyboardArrowDown
                              style={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                height: 30,
                                width: 30,
                                color: "#8E8E8E"
                              }}
                            />
                          )}
                          renderValue={(value) =>
                            value ? value : `${t("Year")}`
                          }
                          displayEmpty={true}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.startYear}
                          onChange={handleChange("startYear")}
                          onBlur={() =>
                            this.checkEndDate(
                              values,
                              setFieldError,
                              setFieldTouched
                            )
                          }
                        >
                          {Years.map((year, index) => (
                            <MenuItem
                              key={index}
                              style={{
                                color: "#8E8E8E",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: 14
                              }}
                              value={year}
                            >
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>

                      {errors.startMonth && touched.startMonth ? (
                        <Typography
                          style={{
                            color: "#F14E24",
                            fontFamily: "Poppins",
                            fontWeight: 300,
                            fontSize: 14,
                            marginTop: 5
                          }}
                        >
                          {errors.startMonth}
                        </Typography>
                      ) : null}
                    </Grid>
                    {!values.isCurrentlyWorking && (
                      <Grid xs={12} sm={6}>
                        <div className={classes.frmColumns}>
                          <Typography
                            style={{
                              color: "#8E8E8E",
                              fontFamily: "Poppins",
                              fontSize: 16,
                              marginBottom: 17,

                            }}
                          >
                            {t("End date")}
                          </Typography>

                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Select
                              style={{ width: "49%" }}
                              disableUnderline={true}
                              classes={{
                                select: this.props.classes.dropdown
                              }}
                              IconComponent={() => (
                                <KeyboardArrowDown
                                  style={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    height: 30,
                                    width: 30,
                                    color: "#8E8E8E"
                                  }}
                                />
                              )}
                              renderValue={(value) =>
                                value ? value : `${t("Month")}`
                              }
                              displayEmpty={true}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={
                                values.endMonth
                                  ? `${t(JSON.parse(values.endMonth).label)}`
                                  : ""
                              }
                              onChange={handleChange("endMonth")}
                              onBlur={() =>
                                this.checkEndDate(
                                  values,
                                  setFieldError,
                                  setFieldTouched
                                )
                              }
                            >
                              {this.Months.map((month, index) => (
                                <MenuItem
                                  key={index}
                                  style={{
                                    color: "#8E8E8E",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: 14
                                  }}
                                  value={JSON.stringify(month)}
                                >
                                  {t(month.label)}
                                </MenuItem>
                              ))}
                            </Select>
                            <Select
                              style={{ width: "49%" }}
                              disableUnderline={true}
                              classes={{
                                select: this.props.classes.dropdown
                              }}
                              IconComponent={() => (
                                <KeyboardArrowDown
                                  style={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    height: 30,
                                    width: 30,
                                    color: "#8E8E8E"
                                  }}
                                />
                              )}
                              renderValue={(value) =>
                                value ? value : `${t("Year")}`
                              }
                              displayEmpty={true}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={values.endYear}
                              onChange={handleChange("endYear")}
                              onBlur={() =>
                                this.checkEndDate(
                                  values,
                                  setFieldError,
                                  setFieldTouched
                                )
                              }
                            >
                              {Years.map((year, index) => (
                                <MenuItem
                                  key={index}
                                  style={{
                                    color: "#8E8E8E",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: 14
                                  }}
                                  value={year}
                                >
                                  {year}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          {errors.endMonth && touched.endMonth ? (
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 300,
                                fontSize: 14,
                                marginTop: 5
                              }}
                            >
                              {errors.endMonth}
                            </Typography>
                          ) : null}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                  <Box display="flex" mt="30px" alignItems="center">
                    <Checkbox
                      checked={values.isCurrentlyWorking}
                      onChange={handleChange("isCurrentlyWorking")}
                      style={{ padding: 0, marginRight: 8, marginTop: 0 }}
                      checkedIcon={
                        <CheckBox
                          style={{
                            color: "#F14E24",
                            width: 16,
                            height: 16,
                            padding: 0,
                            margin: 0
                          }}
                        />
                      }
                      icon={
                        <img
                          src={checkboxIcon}
                          style={{ width: 16, height: 16 }}
                        />
                      }
                    />
                    <Typography
                      style={{
                        color: "#8E8E8E",
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: 14
                      }}
                    >
                      {t("I currently work in this company")}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    mt="16px"
                    mb="40px"
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{
                          backgroundColor: "#F14E24",
                          border: "1px solid #D9DBE9",
                          borderRadius: 16,
                          height: 34,
                          marginBottom: 14,
                          boxShadow: "none",
                          color: "#F7F7FC",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: 14,
                          marginTop: 30
                        }}
                      >
                        {t("Submit")}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Grid>
            )}
          </Formik>
          <Snackbar
            open={Boolean(this.state.error)}
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
            severity="error"
          />
          <Loader loading={this.state.loading} />
          <CatchInvalidToken
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
          />
        </Grid>
      </>
    );
  }

  async componentDidMount() {
    this.fetchCategories();
    this.fetchCaseStudies();
    const langauge = localStorage.getItem("langauge");
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
export const ClientsCreateStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
    }
  },
  categoryDropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 40,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
    }
  },
  dateRow: {
    marginTop: "30px",
  },
  frmColumns: {
    marginLeft: "8px",
    "@media (max-width: 767px)": {
      marginLeft: "0",
      marginTop: "20px",
    }
  }
};
// Customizable Area End

export default withTranslation()(
  withStyles(ClientsCreateStyle)(
    withRouter(ClientsCreate as React.ComponentType<any>)
  )
);
