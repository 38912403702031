import headerLinksStyle from './HeaderLinkStyle';
import {
  container,
  mrAuto,
  title,
  blackColor,
  whiteColor,
  hexToRgb
} from "./ComponentTheme";

export const TopNavBarStyle = theme => ({
  ...headerLinksStyle(theme),
  sectionBlank: {
    height: "70px",
    display: "block"
  },
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor
    }
  },
  conatinerHeader2: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor
    },
    paddingTop: "25vh"
  },
  title,
  pageHeader: {
    position: "relative",
    height: "100vh",
    maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  },
  mrAuto,
  textCenter: {
    textAlign: "center"
  },
  card: {
    marginTop: "60px"
  },
  formControl: {
    margin: "0",
    padding: "8px 0 0 0"
  },
  textRight: {
    textAlign: "right"
  },
  button: {
    margin: "0 !important",
    color: whiteColor,
    backgroundColor: blackColor,
    borderRadius: "300px",
    padding: "10px 15px",
    '&:hover': {
      //   backgroundImage: 'none',
      backgroundColor: blackColor,
      color: whiteColor,
    },
  },
  newButton:
  {
    backgroundColor: "#f81700",
    border: "1px solid transparent",
    borderRadius: "10px",
    padding: "10px 25px",
    width:"100%",

    '&:hover': {
      //   backgroundImage: 'none',
      backgroundColor: "transparent",
      color: "#f81700",
      border: "1px solid #fff",
    },
    "@media (max-width: 768px)": {
      backgroundColor: "transparent",
      border: "0",
      padding: "0 0.9375rem",
      '&:hover': {
        backgroundColor: "transparent",
        color: "#f81700",
        border: "0",
      },
    },
  },
  landingLogoImage:
  {
    width: "68px",
    height: "20px",
    objectFit: "contain",
  },
  landingNavLinkactive:
  {
    fontWeight: "100 !important",
    fontSize: "14px",
    margin: "15px",
    lineHeight: "20px",
    textDecoration: "none",
    backgroundColor: "transparent",
    color: "#f81700",
    padding: "10px 25px",
    border: "1px solid #fff",
    width: "max-content",
    '&:hover': {
      //   backgroundImage: 'none',
      backgroundColor: "#f81700",
      color: "#fff",
      border: "1px solid transparent",
    },
    
    "@media (max-width: 768px)": {
      border: "1px solid",
      marginBottom: 10,
      margin: 0,
      color: "#000",
      padding: "20px 0",
      border: "0",
    },
    // "@media (max-width: 830px)": {
    //   marginBottom: 10,
    //   margin: 0,
    // },
  },
dropdownBtn:{
  fontWeight: "100 !important",
  fontSize: "14px",
  margin: "15px",
  lineHeight: "20px",
  textDecoration: "none",
  backgroundColor: "transparent",
  color: "#f81700",
  padding: "10px 25px",
  border: "1px solid #fff",
  // width:"100px",
  '&:hover': {
    //   backgroundImage: 'none',
    backgroundColor: "#f81700",
    color: "#fff",
    border: "1px solid transparent",
    // width:"100%",
  },
  
  "@media (max-width: 768px)": {
    border: "1px solid",
    marginBottom: 10,
    margin: 0,
    color: "#000",
    padding: "20px 0",
    border: "0",
  },
  // "@media (max-width: 830px)": {
  //   marginBottom: 10,
  //   margin: 0,
  // },
},
selectBtn:{
  "@media (max-width: 768px)": {
    paddingLeft:"10px",
  }
}
  

})