//@ts-ignore
//@ts-nocheck
import React from "react";
import { StyleSheet, Platform } from "react-native";
import {
  Grid,
  Box,
  Divider,
  TextField,
  Fab,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
  Modal,
} from "@material-ui/core";
import { withRouter } from "react-router";
import SendIcon from "@material-ui/icons/Send";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";



import { backIcon, logo, newMessage, pdfIcon } from "./assets";
import SideMenu from "../../../blocks/user-profile-basic/src/SideMenu.web";
import InboxController, { Props } from "./InboxController";
import { Formik, Form, Field } from "formik";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { withTranslation } from 'react-i18next';

class ChatBox extends InboxController {
  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      selectedMedia: null,
      accept: false,
      file: null

    }

    // Customizable Area Start
    // Customizable Area End
  }

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  handleFile2(file) {
      this.setState({ selectedMedia: { url: URL.createObjectURL(file), mimetype: file.type }, accept: true, file: file }, )

  }

  // Customizable Area Start
  // Customizable Area End
  displaytime(time) {
    let date = new Date(time)

    let d = date.getHours();
    let m = date.getMinutes();
              
              return `${d}:${m<9 ? `0`+m : m}   (${moment(time).format("DD MMM YYYY")})`

  }
  _handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.props.createMessage()

    }
  }
  createSocket=()=>{
    let chatChannel=null;
    
    if(this.props.selectedRoomId){
   
    chatChannel = this.props.CableApp.cable.subscriptions.create({
        channel: 'ChatroomsChannel',
        chat_id: this.props.selectedRoomId
      },
      {
        connected: () => {
          console.log('--------- ChatChannel connected');
        },
        disconnect: () => {
          console.log('--------- ChatChannel disconnect');
        },
        received: (data) => {
          debugger;
          console.log('receive',data)
          // getResponseMessage(data);
        },
      }
      
      )


    }

    chatChannel.connected()
 

    


    }


  

  render() {
    const { navigation, onClose, handleClose2nd, messageList, selectUser,selectedRoomId, allAccount, createMessage, NewMessage, newValue, handleFile, open2nd, handleClickOpen, t, i18n } = this.props;
    const { users, open, singleChatRoom, } = this.state;
    const currentAccountId = localStorage.getItem('userId');

    debugger
    
    return (
      <div style={{ paddingRight: "0.3rem", backgroundColor: "#ffff" }}>
        <Grid container>
          <Grid xs={12} style={{ display: 'flex', alignItems: 'center', }}>
            <Avatar src={selectUser.attributes.profile_picture} style={{ borderRadius: 25, marginLeft: '1rem' }} />

            <Box
              style={{
                height: 64,

                display: "flex",
                alignItems: "start",
                justifyContent: 'center',
                flexDirection: "column"
              }}
            >

         
              <Typography
                style={{
                  color: "#081F32",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: 18,
                  marginLeft: 10
                }}
              >
                {selectUser.attributes.first_name}  {" "}
                {
                  allAccount.length > 1 ?
                    <span style={{ cursor: "pointer" }} onClick={handleClickOpen}>
                      + {allAccount.length}
                    </span> : null
                }
              </Typography>
              <Typography
                style={{
                  color: "rgb(134 140 144)",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: 10,
                  marginLeft: 10
                }}
              >

                @{selectUser.attributes.user_name}
              </Typography>
            </Box>

          </Grid>

          <Grid xs={12} style={{ borderTop: "1px solid #DEDDDA" }}>
            <List style={{ overflowY: "auto", height: "70vh" }}>
              {messageList &&  messageList.map((message, i) => (
                <>
                  <ListItem key={i}>
                    <Grid container>
                      <Grid item xs={12} style={message.attributes.account_id == currentAccountId ? { 'display': 'flex', 'justifyContent': 'end', alignItems: 'center' } : { 'display': 'flex', 'justifyContent': 'start', alignItems: 'center' }}>

                        <Avatar style={{ width: '20px', height: '20px' }} src={message.attributes.account.data.attributes.profile_picture} />

                        <Typography
                          style={{
                            color: "#081F32",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 14,
                            marginLeft: 5
                          }}
                          align={
                            message.attributes.account_id == currentAccountId
                              ? "right"
                              : "left"
                          }
                        >
                          {message.attributes.account.data.attributes.first_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                      {/* <Typography
                          style={{
                            color: "#081F32",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 14,
                            wordBreak:'break-all'
                          }}
                          align={
                            message.attributes.account_id == currentAccountId
                              ? "right"
                              : "left"
                          }
                        >
                          {message.attributes.body}
                        </Typography> */}

                        {
                          message.attributes.body.length>45 ? 
                          <>
                          <Typography
                          style={{
                            color: "#081F32",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 14,
                            wordBreak:'break-all'
                          }}
                          align='left'
                        >
                          {message.attributes.body}
                        </Typography>


                          </>

                          :

                          <>
                          <Typography
                          style={{
                            color: "#081F32",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: 14,
                            wordBreak:'break-all'
                          }}
                          align={
                            message.attributes.account_id == currentAccountId
                              ? "right"
                              : "left"
                          }
                        >
                          {message.attributes.body}
                        </Typography>
                          
                          </>
                        }

                        
                      </Grid>
                      {
                        message.attributes.attached_files ?
                          <Grid item xs={12} style={message.attributes.account_id == currentAccountId ? { 'display': 'flex', 'justifyContent': 'end', flexDirection: 'column', alignItems: 'end', marginTop: 5 } : { 'display': 'flex', 'justifyContent': 'start', flexDirection: 'column', alignItems: 'start', marginTop: 5 }}>
                           
                            {message.attributes.attached_files[0]?.mimetype !== "application/pdf" ? (
                              <img style={{ 'cursor': 'pointer' }} onClick={() => this.setState({ selectedMedia: message.attributes.attached_files[0] })} src={message.attributes.attached_files[0].url} width="75" height="75" />
                            ) : (
                              <>
                                <img style={{ 'cursor': 'pointer' }} onClick={() => this.setState({ selectedMedia: message.attributes.attached_files[0].filename })} src={pdfIcon} width="75" height="75" />
                                <p style={{ maxWidth: '10rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{message.attributes.attached_files[0].filename}</p>
                              </>

                            )}
                          </Grid>
                          :
                          null

                      }
                      <Grid item xs={12}>
                        <ListItemText

                          align={
                            message.attributes.account_id == currentAccountId
                              ? "right"
                              : "left"
                          }
                          secondary={this.displaytime(message.attributes.created_at)}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              ))}
            </List>

            <Divider />
            <Grid container style={{ padding: "20px", display:'flex',  alignItems: 'center', justifyContent:'space-between' }}>

              <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                
                <input value={newValue}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      createMessage()
                    }
                  }}

                  onChange={(e) => NewMessage(e)} type="" style={{ border: '1px solid #EDEDED', color: '#726363', borderRadius: 15, padding: 10, width: '100%' }} placeholder={t("Start a new message")} />
                <AttachFileIcon onClick={this.handleClick} for="BtnBrowseHidden" style={{ cursor: 'pointer' }} />
                <input

                  id="BtnBrowseHidden"
                  type="file"
                  onChange={(e: any) =>
                    this.handleFile2(
                      e.target.files[0]
                    )
                  }
                  style={{
                    position: "absolute",
                    height: "10px",
                    width: "10px",
                    zIndex: 2,
                    cursor: "pointer",
                    opacity: 0
                  }}
                  ref="fileUploader"
                  accept="image/png, image/jpeg, image/jpg,.pdf"
                />
              </Grid>
             
                <SendIcon style={{ cursor: 'pointer' }} onClick={createMessage} />
                
            </Grid>

          </Grid>
        </Grid>

        <Modal
          open={open2nd}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClose={handleClose2nd}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{
            width: 600,
            background: 'white',
            maxHeight: '50rem',
            borderRadius: 10
          }}>
            <List style={{ backgroundColor: '#ffff', height: '46rem', overflow: "auto" }} >

              {
                allAccount.map(user => (

                  <ListItem button style={{ borderBottom: "1px solid #DEDDDA" }}>
                    <ListItemAvatar>
                      {

                        user.attributes.profile_picture ? <img src={user.attributes.profile_picture} width="40" height="40" style={{ borderRadius: 10 }} />
                          :
                          <Avatar style={{ borderRadius: 10 }} />

                      }



                    </ListItemAvatar>
                    <ListItemText primary={user.attributes.first_name} secondary={'@' + user.attributes.user_name} />
            

                  </ListItem>


                ))
              }


            </List>
          </div>
        </Modal>
       
        <Modal
          open={this.state.selectedMedia}
          style={{ display: 'flex', alignItems: 'center', flexDirection: 'column-reverse', justifyContent: 'center' }}

          onClose={() => this.setState({ selectedMedia: null, accept: false })}
          aria-labelledby="alert-Modal-title"
          aria-describedby="alert-dialog-description"
        >
          <div>

            {this.state.selectedMedia?.mimetype !== "application/pdf" ? (
              <Avatar src={this.state.selectedMedia?.url} style={{ width: '600px', height: '56rem', borderRadius: 0 }} />
            ) : (
              <iframe src={this.state.selectedMedia?.url} style={{ width: '600px', height: '56rem' }} />
            )}


            {
              this.state.accept &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={() => this.setState({ selectedMedia: null, accept: false })} style={{
                  marginRight: '2rem', backgroundColor: 'rgb(241, 78, 36)',
                  border: '1px solid rgb(217, 219, 233)',
                  borderRadius: '16px',
                  height: 35,
                  boxShadow: 'none',
                  color: ' rgb(247, 247, 252)',
                  fontFamily: 'Poppins',
                  fontSize: 13,
                  marginTop: 10,
                  marginRight: 10,
                  width: 150
                }}>
                  {t("Cancel")}
                </button>
                <button onClick={() => this.setState({ selectedMedia: null, accept: false }, () => handleFile(this.state.file))} style={{
                  backgroundColor: '#ffff',
                  border: '1px solid red',
                  borderRadius: '16px',
                  height: 35,
                  boxShadow: 'none',
                  color: ' red',
                  fontFamily: 'Poppins',
                  fontSize: 13,
                  marginTop: 10,
                  marginRight: 10,
                  width: 150
                }}>
                  {t("Send")}
                </button>
              </div>
            }

          </div>

        </Modal>


      </div>
    );
  }

  async componentDidMount() {
    const langauge = localStorage.getItem("langauge")
   
    this.props.i18n.changeLanguage(langauge);
  }
   componentDidUpdate = (preProps,currentProps)=>{
   
     if(preProps.selectedChatRoomId == this.props.selectedChatRoomId)
     {
      
    // this.createSocket()

     }
   
    


    }
  
}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  text: {

    fontSize: '1.2rem'

  },
  titleWhySignUp: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  title: {
    marginBottom: 2,
    fontSize: 14,
    textAlign: "left",
    marginTop: 16
  },

  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgDummyPassword: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    opacity: 0.4,
    fontWeight: "bold",
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 0,
    padding: 12,
    marginTop: 0
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    marginTop: 0,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  helperText: { marginTop: 10 }
});
// Customizable Area End

export default withTranslation()(withRouter(ChatBox as React.ComponentType<any>))
