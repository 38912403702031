//@ts-ignore
//@ts-nocheck
import {
  Avatar, Box, Button, Dialog,
  DialogActions,
  DialogTitle, Grid, IconButton,
  Popover, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add, MoreHoriz } from "@material-ui/icons";
import React from "react";
import { withTranslation } from 'react-i18next';
import { Platform, StyleSheet } from "react-native";
import { withRouter } from "react-router";
import CatchInvalidToken from "../../../components/src/CatchInvalidToken.web";
import Loader from "../../../components/src/Loader.web";
import Snackbar from "../../../components/src/Snackbar";
import OpenPositionsController, { Props } from "./OpenPositionsController";
import ProfileHeader from "./ProfileHeader";
import SideMenu from "./SideMenu.web";


class OpenPositionsList extends OpenPositionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { navigation } = this.props;
    const { openPositions, userDetails } = this.state;
    const { classes, t, i18n } = this.props;
    return (
      <>
        <ProfileHeader
          heading={t("Open Position")}
          subModule={true}
          showProfile={true}
        />
        <Grid className={classes.mainWrapper}>
          <Grid container={true}>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid xs={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Box
                  onClick={() => {
                    localStorage.removeItem("openPositionDetails");
                    this.props.history.push("/open-positions/create");
                  }}
                  style={{
                    padding: 10,
                    cursor: "pointer",
                    minHeight: 127,
                    width: "92%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 43,
                    borderRadius: 16,
                    border: "1px solid #DBDBDB",
                    backgroundImage: "linear-gradient(to right, rgb(241, 78, 36), rgb(195, 0, 82))",
                  }}
                >
                  <Typography
                    style={{
                      color:"#fff",
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      fontSize: 22
                    }}
                  >
                    {t("Open New Position")}
                  </Typography>
                  <Add
                    style={{
                      marginTop: 5,
                      color:"#fff",
                      height: 24,
                      width: 24
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                  width="92%"
                  mt='24px'
                >
                  {openPositions.map((openPosition) => (
                    <Box
                      key={openPosition.id}
                      display="flex"
                      style={{
                        height: "auto",
                        marginBottom: 7,
                        position: "relative"
                      }}
                    >
                      <Box style={{ position: "absolute", top: 1, right: 1 }}>
                        <IconButton
                          style={{backgroundColor:'transparent'}}
                          onClick={(e) =>
                            this.handlePopoverOpen(e, openPosition.id)
                          }
                        >
                          <MoreHoriz style={{ width: 24, height: 24, color: "black" }} />
                        </IconButton>

                        <Popover
                          open={this.state.openedPopoverId === openPosition.id}
                          anchorEl={this.state.anchorEl}
                          onClose={this.handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                          }}
                        >
                          <Box display="flex" flexDirection="column">
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "openPositionDetails",
                                  JSON.stringify({
                                    id: openPosition.id,
                                    jobTitle: openPosition.attributes.job_title,
                                    contract: openPosition.attributes.contract,
                                    level: openPosition.attributes.level,
                                    country: JSON.stringify({
                                      label: openPosition.attributes.country
                                    }),
                                    city: openPosition.attributes.city,
                                    roleDescription:
                                      openPosition.attributes.discription
                                  })
                                );
                                this.props.history.push(
                                  "/open-positions/create"
                                );
                              }}
                              variant="text"
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 12,
                                textAlign: "left",
                                justifyContent: "flex-start"
                              }}
                            >
                              {t("Edit")}
                            </Button>
                            <Button
                              onClick={() =>
                                this.setState({
                                  showDialog: true,
                                  openPositionId: openPosition.id
                                })
                              }
                              variant="text"
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 12,
                                justifyContent: "flex-start"
                              }}
                            >
                              {t("Delete")}
                            </Button>
                          </Box>
                        </Popover>
                      </Box>

                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          minHeight: 105,
                          width: "100%",
                          backgroundColor: "#F7F7FC",
                          borderRadius: "16px 16px 16px 16px",
                          padding: "22px 20px"
                        }}
                      >
                        <Avatar
                          src={userDetails?.profile_picture}
                          style={{ width: 48, height: 48, marginRight: 35 }}
                        />
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            style={{
                              color: "#081F32",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 18
                            }}
                          >
                            {`${openPosition.attributes.job_title}, ${
                              openPosition.attributes.country
                            }`}
                          </Typography>
                          <Typography
                            style={{
                              color: "#6E798C",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: 10,
                              marginTop: 4,
                              marginBottom: 2
                            }}
                          >
                            <Typography
                              style={{
                                color: "#F14E24",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: 10
                              }}
                              component="span"
                            >
                              {userDetails?.first_name}
                            </Typography>{" "}
                            ·{" "}
                            {`${openPosition.attributes.city}, ${
                              openPosition.attributes.country
                            }`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
        <Loader loading={this.state.loading} />
        
        <CatchInvalidToken
            onClose={() => this.setState({ error: null })}
            message={this.state.error || "N/A"}
          />

        <Dialog
          open={this.state.showDialog}
          onClose={() => this.setState({ showDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`${t("Are you sure you want to delete this open position?")}`}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ showDialog: false, openPositionId: null })
              }
              variant="text"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => this.deleteOpenPosition(this.state.openPositionId)}
              variant="text"
              autoFocus
            >
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getAllOpenPositions();
    this.getUserInformation();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}
// Customizable Area Start
export const OpenPositionsListStyle = {
  dropdown: {
    padding: 0,
    border: "1px solid #DBDBDB",
    height: 45,
    width: "100%",
    color: "#8E8E8E",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 16,
    // paddingRight: 10,
    outline: "none",
    borderRadius: 16,
    zIndex: 12,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 16
      // backgroundColor: "white",
      // zIndex: 2
    }
  },
  mainWrapper: {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
      // height: "auto",
    }
  },
  mainBodyContent: {
    marginTop: 30,
    border: "#80808042 1px solid",
    padding: 25,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      padding: 10
    }
  }
};
// Customizable Area End

export default withTranslation()(withStyles(OpenPositionsListStyle)(
  withRouter(OpenPositionsList as React.ComponentType<any>)
))
