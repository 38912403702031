//@ts-ignore
//@ts-nocheck
import {
  Box, Button, Grid, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { withTranslation } from 'react-i18next';
import CompanyInformationController, { Props } from "./CompanyInformationController";
import ProfileHeader from './ProfileHeader';
import SideMenu from "./SideMenu.web";

class CompanyInformation extends CompanyInformationController {
  constructor(props:Props) {
    super(props);
  }
  componentDidMount() {
    this.getCompanyInformation();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  render() {
    const { classes, t, i18n } = this.props;
    return (
      <>
        <ProfileHeader heading={t("Company Information")} subModule={this.state?.subModule} showProfile />
        <Grid className={classes.mainWrapper}>
          <Grid container>
            <Grid item xs={12} md={2} style={{ position: "relative" }}>
              <SideMenu showProfile />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.mainBodyContent}>
                <Typography className={classes.videoDesc}>
                  {t("yup.gg is your professional home. your profile should reflect how you want the gaming industry to see you.")}
                </Typography>
               
                <Formik
                  initialValues={{
                    website :this.state.getDataCompanyInformation?.website ||"",
                    companySize:this.state.getDataCompanyInformation?.company_size ||"",
                    foundationDate:this.state.getDataCompanyInformation?.foundation_date ||"",
                    facebook:this.state.getDataCompanyInformation?.facebook_field || "",
                    instagram:this.state.getDataCompanyInformation?.instagram_field || "",
                    twitter:this.state.getDataCompanyInformation?.twitter_field || "",
                    linkedin:this.state.getDataCompanyInformation?.linkedin_field|| ""
                  }}
                  validateOnMount={true}
                  validationSchema={this.CompanyInformationSchema(t)}
                  onSubmit={(values) =>{
                    this.createCompanyInformation(values)
                  }
                  }
                  enableReinitialize
                  render={({ values, errors, touched }) => {
                    return (
                      <Form style={{
                        width: "100%"
                      }} noValidate>
                             <Box style={{ width: "100%", marginTop: "20px" }}>
                          <label className={classes.videoLabel}>
                            {t("Website")}
                          </label>
                          </Box>
                          <Field
                          name="website"
                          type="url"
                          placeholder={t("Insert your company's URL")}
                            style={{
                              width: "100%",
                              padding: "10px 20px",
                              borderRadius: "15px",
                              border: "1px solid #ccc",
                              color: "#081F32",
                              outline: "none",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop:"10px"
                            }}
                          />
                            <ErrorMessage name={`website`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                          <div style={{display:"flex",justifyContent:"space-between",gap:"10px"}} className={classes.company}>
                         <div style={{marginTop:"20px", width: "100%"}}>
                         <label className={classes.videoLabel}>
                            {t("Company size")}
                          </label>
                          <Field
                          name="companySize"
                          type="text"
                          placeholder="i.e. 500+"
                            style={{
                              width: "100%",
                              padding: "10px 80px 10px 10px",
                              borderRadius: "15px",
                              border: "1px solid #ccc",
                              color: "#081F32",
                              outline: "none",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop:"10px"
                            }}
                          />
                            <ErrorMessage name={`companySize`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                         </div>  
                         <div style={{marginTop:"20px", width: "100%"}}>
                         <label className={classes.videoLabel}>
                            {t("Foundation year")}
                          </label>
                          <Field
                          name="foundationDate"
                          type="text"
                          placeholder="i.e. 1900"
                            style={{
                              width: "100%",
                              padding: "10px 80px 10px 10px",
                              borderRadius: "15px",
                              border: "1px solid #ccc",
                              color: "#081F32",
                              outline: "none",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop:"10px"
                            }}
                          />
                            <ErrorMessage name={`foundationDate`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                         </div>
                          </div>
                          <div style={{display:"flex",justifyContent:"space-between",gap:"10px"}} className={classes.company}>
                         <div style={{marginTop:"20px", width: "100%"}}>
                         <label className={classes.videoLabel}>
                            {t("Facebook Link")}
                          </label>
                          <Field
                          name="facebook"
                          type="text"
                          placeholder={t("Insert your facebook URL")}
                            style={{
                              width: "100%",
                              padding: "10px 80px 10px 10px",
                              borderRadius: "15px",
                              border: "1px solid #ccc",
                              color: "#081F32",
                              outline: "none",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop:"10px"
                            }}
                          />
                           <ErrorMessage name={`facebook`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                         </div>  
                         <div style={{marginTop:"20px", width: "100%"}}>
                         <label className={classes.videoLabel}>
                         {t("Instagram Link")}
                          </label>
                          <Field
                          name="instagram"
                          type="text"
                          placeholder={t("Insert your instagram URL")}
                            style={{
                              width: "100%",
                              padding: "10px 80px 10px 10px",
                              borderRadius: "15px",
                              border: "1px solid #ccc",
                              color: "#081F32",
                              outline: "none",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop:"10px"
                            }}
                          />
                           <ErrorMessage name={`instagram`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                         </div>
                          </div>
                          <div style={{display:"flex",justifyContent:"space-between",gap:"10px"}} className={classes.company}>
                         <div style={{marginTop:"20px", width: "100%"}}>
                         <label className={classes.videoLabel}>
                         {t("Twitter Link")}
                          </label>
                          <Field
                          name="twitter"
                          type="text"
                          placeholder={t("Insert your twitter URL")}
                            style={{
                              width: "100%",
                              padding: "10px 80px 10px 10px",
                              borderRadius: "15px",
                              border: "1px solid #ccc",
                              color: "#081F32",
                              outline: "none",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop:"10px"
                            }}
                          />
                           <ErrorMessage name={`twitter`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                         </div>  
                         <div style={{marginTop:"20px", width: "100%"}}>
                         <label className={classes.videoLabel}>
                         {t("Linkedin Link")}
                          </label>
                          <Field
                          name="linkedin"
                          type="text"
                          placeholder={t("Insert your linkedin URL")}
                            style={{
                              width: "100%",
                              padding: "10px 80px 10px 10px",
                              borderRadius: "15px",
                              border: "1px solid #ccc",
                              color: "#081F32",
                              outline: "none",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop:"10px"
                            }}
                          />
                           <ErrorMessage name={`linkedin`} component="Typography" style={{color:'red',marginTop:5,fontFamily: "Poppins", fontSize: 14,lineHeight:"30px"}} />
                         </div>
                          </div>
                          <Box width="100%">
                          <Button
                            variant="contained"
                            type="submit"
                            style={{
                              backgroundColor: "#F14E24",
                              border: "1px solid #D9DBE9",
                              borderRadius: 16,
                              height: 35,
                              marginBottom: 14,
                              boxShadow: "none",
                              color: "#F7F7FC",
                              fontFamily: "Poppins",
                              fontSize: 13,
                              marginTop: 18,
                              float: "right",
                            }}
                          >
                            {t("Save")}
                          </Button>
                          </Box>  
                      </Form>
                    )
                  }}
                />
                      </Box>
                  </Grid>
            <Grid item xs={12}  md={4}>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
const CompanyInformationStyle = {
  twitterBtn: {
    padding: 0,
    background: "none",
    border: "none",
    width: "80%",
  },
  videoLabel: {
    fontFamily: "Poppins",
    fontSize: 13,
    display: "block",
    color: "grey",
    fontWeight: 400,
    marginBottom: "0px",
  },
  mainWrapper:
  {
    height: "100%",
    paddingBottom: 50,
    backgroundColor: "#FFFFFF",
    "@media (max-width: 768px)": {
    },
  },
  mainBodyContent:
  {
    height: "100%",
    border: "#80808042 1px solid",
    padding: 42,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      padding: 10,
    },
  },
  videoDesc:
  {
    color: "#081F32",
    fontFamily: "Poppins",
    fontSize: 16,
    fontWeight:400,
  },
  company:{
    "@media (max-width: 768px)": {
     flexDirection:"column",
    }
  }
}


export default withTranslation()(withStyles(CompanyInformationStyle)(CompanyInformation))
