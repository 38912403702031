//@ts-nocheck
import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import { Box, Typography, Button, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik, Form, Field } from "formik";

import Snackbar from "../../../components/src/Snackbar";
import { backArrow, logo } from "./assets";
import { withTranslation } from 'react-i18next';

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";
import Copyright from "../../../components/src/Copyright";

class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { t , i18n } = this.props;
    let params = new URL(document.location as any).searchParams;
    let token = params.get("token");

    if (!token) {
      this.props.history.replace("/forgot-password/select-type");
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
        height="100vh"
      >
        <IconButton
          onClick={() => {
            this.props.history.goBack();
          }}
          style={{ position: "absolute", top: 62, left: 69 }}
        >
          <img src={backArrow} style={{ width: 17, height: 14 }} />
        </IconButton>

        <Box
          display="flex"
          mt="160px"
          mb="42px"
          width="350px"
          justifyContent="center"
        >
          <img src={logo} style={{ height: 45, width: 350 }} />
        </Box>

        <Formik
          initialValues={{
            new_password: "",
            confirm_password: "",
            showNewPassword: false,
            showConfirmPassword: false
          }}
          validationSchema={this.NewPasswordSchema(t)}
          validateOnMount={true}
          onSubmit={(values) => {
            // same shape as initial values
            this.changePassword(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleChange,
            setFieldValue
          }) => (
            <Form translate="yes" style={{ width: 363 }}>
              <Fragment>
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    overflow="hidden"
                    alignItems="center"
                    height="56px"
                    border="1px solid #D1D1D1"
                    borderRadius="16px"
                    bgcolor="white"
                  >
                    <Field
                      name="new_password"
                      placeholder={t("New Password")}
                      type={values.showNewPassword ? "text" : "password"}
                      style={{
                        border: "none",
                        height: "100%",
                        width: "80%",
                        color: "rgba(0, 0, 0, 0.6)",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 16,
                        marginRight: 10,
                        marginLeft: 21,
                        outline: "none"
                      }}
                    />
                    {values.showNewPassword ? (
                      <IconButton
                        onClick={() => setFieldValue("showNewPassword", false)}
                        style={{ padding: 0, backgroundColor: "transparent" }}
                        disableRipple={true}
                      >
                        <Visibility
                          style={{
                            width: 24,
                            height: 24,
                            marginRight: 16,
                            color: "#000000",
                            opacity: 0.54
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => setFieldValue("showNewPassword", true)}
                        style={{ padding: 0, backgroundColor: "transparent" }}
                        disableRipple={true}
                      >
                        <VisibilityOff
                          style={{
                            width: 24,
                            height: 24,
                            marginRight: 16,
                            color: "#000000",
                            opacity: 0.54
                          }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  {errors.new_password && touched.new_password ? (
                    <Typography
                      style={{
                        color: "#F14E24",
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        fontSize: 14,
                        marginTop: 5,
                        marginLeft: 10
                      }}
                    >
                      {errors.new_password}
                    </Typography>
                  ) : null}

                  <Box
                    display="flex"
                    overflow="hidden"
                    alignItems="center"
                    height="56px"
                    border="1px solid #D1D1D1"
                    borderRadius="16px"
                    bgcolor="white"
                    mt="10px"
                  >
                    <Field
                      name="confirm_password"
                      placeholder={t("Confirm Password")}
                      type={values.showConfirmPassword ? "text" : "password"}
                      style={{
                        border: "none",
                        height: "100%",
                        width: "80%",
                        color: "rgba(0, 0, 0, 0.6)",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: 16,
                        marginRight: 10,
                        marginLeft: 21,
                        outline: "none"
                      }}
                    />
                    {values.showConfirmPassword ? (
                      <IconButton
                        onClick={() =>
                          setFieldValue("showConfirmPassword", false)
                        }
                        style={{ padding: 0, backgroundColor: "transparent" }}
                        disableRipple={true}
                      >
                        <Visibility
                          style={{
                            width: 24,
                            height: 24,
                            marginRight: 16,
                            color: "#000000",
                            opacity: 0.54
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() =>
                          setFieldValue("showConfirmPassword", true)
                        }
                        style={{ padding: 0, backgroundColor: "transparent" }}
                        disableRipple={true}
                      >
                        <VisibilityOff
                          style={{
                            width: 24,
                            height: 24,
                            marginRight: 16,
                            color: "#000000",
                            opacity: 0.54
                          }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  {errors.confirm_password && touched.confirm_password ? (
                    <Typography
                      style={{
                        color: "#F14E24",
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        fontSize: 14,
                        marginTop: 5,
                        marginLeft: 10
                      }}
                    >
                      {errors.confirm_password}
                    </Typography>
                  ) : null}

                  <Button
                    disabled={!isValid}
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "#F14E24",
                      border: "1px solid #D9DBE9",
                      borderRadius: 16,
                      height: 34,
                      marginBottom: 14,
                      boxShadow: "none",
                      color: "#F7F7FC",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: 16,
                      marginTop: 30,
                      opacity: !isValid ? 0.6 : 1
                    }}
                  >
                    {t("Continue")}
                  </Button>
                </Box>
              </Fragment>
            </Form>
          )}
        </Formik>

        <Box mt="315px" mb="100px">
        <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});

export default withTranslation()(withRouter(NewPassword as React.ComponentType<any>))
