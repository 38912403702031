export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/ic_back.png");
export const logo = require("../assets/logo.png");
export const avatar = require("../assets/avatar.png");
export const moreIcon = require("../assets/ic_more.png");
export const mobilePreview = require("../assets/mobile_preview.png");
export const checkboxIcon = require("../assets/checkbox.png");
export const stepOne = require("../assets/step_1.png");
export const steptwo = require("../assets/step_2.png");
export const stepthree = require("../assets/step_3.png");
export const jpgIcon = require("../assets/ic_jpg.png");
export const pngIcon = require("../assets/ic_png.png");
export const pdfIcon = require("../assets/ic_pdf.png");
export const productPlacement = require("../assets/product_placement.png");
export const socialMediaCampaign = require("../assets/social_campaing.png");
export const productUnboxing = require("../assets/product_unboxing.png");
export const profilePicture = require("../assets/profile_picture.png");
export const locationIcon = require("../assets/ic_location.png");
export const calendarIcon = require("../assets/ic_calendar.png");
export const verify = require("../assets/verify.png");
export const twitchIcon = require("../assets/twitch.png");
export const twitterIcon = require("../assets/twitter.png");
export const instagramIcon = require("../assets/instagram.png");
export const instaIconImg = require("../assets/insta.png");
export const youtubeIcon = require("../assets/youtube.png");
export const backImage = require("../assets/375931745289896e5820791721433ffc907dbe78.png");
export const backCoverImage = require("../assets/back-cover-image.png");
export const facebookIcon = require("../assets/facebook.png");
export const facebookImg = require("../assets/facebook.png");
export const twitchImg = require("../assets/twitch.png");
export const twitterImg = require("../assets/twitter.png");
export const youtubeImg = require("../assets/youtube.png");
export const linkedinImg = require("../assets/linkedin.png");
export const tiktokImg = require("../assets/tiktok.png");
export const instagramImg = require("../assets/instagram.png");
export const mobileImg = require("../assets/mobile-view.png");
export const profileImg1 = require("../assets/4484c349dc5fb3bc19e7c16bb099d75726eba4a5.png");
export const defaultImg = require("../assets/avatar.png");
export const verticalDots = require("../assets/ic_vertical_dots.png");
export const MobileBackIcon = require("../assets/mobile-back-icon.png");
export const MobileShareIcon = require("../assets/mobile-share-icon.png");
export const BrazilFlagIcon = require("../assets/Brazil-flag.png");
export const portfolio1Img = require("../assets/portfolio1.png");
export const portfolio2Img = require("../assets/portfolio2.png");
export const caseStudyImg = require("../assets/case-study.png");
export const googleImg = require("../assets/google.png");
export const client1Img = require("../assets/client1.png");
export const client2Img = require("../assets/client2.png");
export const client3Img = require("../assets/client3.png");
export const client4Img = require("../assets/client4.png");
export const brand1Img = require("../assets/brand1.png");
export const mobileBgUrl = require("../assets/mobile_bg.png");
export const mobileBgBottom = require("../assets/bottom_part.png");
export const mailIcon = require("../../email-account-registration/assets/mail.png");
export const phoneIcon = require("../../email-account-registration/assets/phone.png");

export const CoverPhoto2 = require("../assets/coverphpto2.png");
export const BannerImage2 = require("../assets/bannerimage2.png");
export const BellplusIcon = require("../assets/bell_plus_icon.png");
export const ShareIcon = require("../assets/icons8-share.svg");

export const listingProductPlacement = require("../assets/listing_product_placement.png");
export const listingSocialCampaign = require("../assets/listing_social_campaign.png");

export const searchIcon = require("../assets/search_icon.png");
export const closeIcon = require("../assets/close_icon.png");
export const illustration = require("../assets/talent_illustration.png");
