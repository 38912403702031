//@ts-ignore
//@ts-nocheck
import PropTypes from "prop-types";
import React, { Component } from "react";
import { StyleSheet } from "react-native";
import GoogleLogin from 'react-google-login';
import { Box, Link, Typography, Button } from '@material-ui/core';

import { googleImage } from "./assets";
import { googleIcon } from "../../email-account-registration/src/assets"

type Props = {
  clientId: string;
  loginGoogleButtonText: string;
  // style: any;
  // googleButtonImageStyle: any;
  // googleButtonTextStyle: any;
  onPress: () => void;
  onSuccess: (response: any) => void;
  onFailure: (response: any) => void; 
  // Customizable Area Start
  // Customizable Area End
};

export default class CustomGoogleLogInButton extends Component<Props> {
  static propTypes = {
    clientId: PropTypes.string,
    // style: PropTypes.any,
    loginGoogleButtonText: PropTypes.string,
    onPress: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    // testID: PropTypes.string,
    // style: PropTypes.any,
    // googleButtonImageStyle: PropTypes.any,
    // googleButtonTextStyle: PropTypes.any,
  };

  constructor(props: any) {
    super(props);
  }

  render() {

    const { clientId, onSuccess, onFailure, onPress, loginGoogleButtonText } = this.props
    return (
      <GoogleLogin
        clientId={clientId}
        render={renderProps => (
          <Button variant='contained'
            style={{ 
              backgroundColor: '#FFFFFF',
              border: '1px solid #D9DBE9',
              borderRadius: 16,
              width: 361,
              height: 80,
              marginBottom: 14,
              boxShadow: 'none',
              fontFamily: 'Poppins',
            }}
          onClick={() => {
                renderProps.onClick()
                onPress();
              }}
          >
            <Box display='flex' justifyContent='space-between' width='80%' >
              <Box display='flex' width='25%'>
                <img src={googleIcon} style={{ width: 26, height: 'auto' }} />
              </Box>
              <Box display='flex' width='75%' style={{ fontSize: 16 }}>
                {loginGoogleButtonText}
              </Box>
            </Box>
          </Button>
        )}
        // buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        autoLoad={false}
        prompt="consent"
        accessType="offline"
        isSignedIn={false}
      />
    );
  }
}

const styles = StyleSheet.create({
  googleStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    elevation: 6,
    shadowRadius: 8,
    borderWidth: 0,
    borderRadius: 0,
    paddingTop: "4px",
    boxShadow: 'none',
  },
  facebookImageStyle: {
    marginRight: 10,
    width: 20,
    height: 20
  },
  googleButtonTextStyle: {
    color: '#00000',
    fontFamily: 'Poppins',
    fontWeight: "500",
    fontSize: 16,
    boxShadow: 'none',
    paddingLeft: 7
  },
});
