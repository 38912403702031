Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Search";
exports.labelBodyText = "Search Body";

exports.btnExampleTitle = "CLICK ME";

exports.endPointApiGetDiscovery = "discovery";
exports.methodTypeApiGetDiscovery = "GET";

exports.endPointApiGetCategories = "profile_categories";
exports.methodTypeApiGetCategories = "GET";

exports.endPointApiConnectAccounts = "bx_block_friendlist/connections";
exports.methodTypeApiConnectAccounts = "POST";

exports.endPointApiDisconnectAccounts = "bx_block_friendlist/disconnect";
exports.methodTypeApiDisconnectAccounts = "DELETE";

exports.endPointApiFollowAccounts = "bx_block_follower/followers";
exports.methodTypeApiFollowAccounts = "POST";

exports.endPointApiUnfollowAccounts = "bx_block_follower/followers/";
exports.methodTypeApiUnfollowAccounts = "DELETE";

exports.endPointApiProfileTasks = "profile_tasks";
exports.methodTypeApiProfileTasks = "GET";

exports.endPointApiRecentSearch = "recent_search";
exports.methodTypeApiRecentSearch = "GET";

exports.endPointApiRemoveRecentSearch = "recent_search";
exports.methodTypeApiRemoveRecentSearch = "DELETE";

exports.endPointApiClearAllRecentSearch = "recent_search/clear_all";
exports.methodTypeApiClearAllRecentSearch = "GET";

exports.endPointApiGetSearchResults = "recommendation_engine";
exports.methodTypeApiGetSearchResults = "POST";

exports.endPointApiCreateRecentSearch = "recent_search";
exports.methodTypeApiCreateRecentSearch = "POST";

exports.endPointApiCategoryProfiles = "profile_select_category/";
exports.methodTypeApiCategoryProfiles = "GET";
// Customizable Area End
