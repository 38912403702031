//@ts-ignore
//@ts-nocheck
import React from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import {
    backIcon,
    logo,
  } from "./assets";

 class CeoLetter extends React.Component {
 

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
    <>
      
         <Grid className={classes.mainWrapper}>
          <Grid container>
            <Grid item xs={12} md={3} style={{ position: "relative" }}>
            <Box className={classes.LogoImg}
              style={{
                height: 64,
                borderBottom: "1px solid #DEDDDA",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
               <IconButton
                      onClick={() => this.props.history.push("/aboutus")}
                      style={{ marginLeft: 10 }}
                    >
                      <img src={backIcon} style={{ height: 14, width: 17 }} />
                    </IconButton>
              <img src={logo} style={{ height: 20, width: 68.25 }} />
            </Box>
       
            </Grid>
            <Grid item xs={12} md={7}>
            <Box
                  style={{
                    height: 64,
                    borderBottom: "1px solid #DEDDDA",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: 47
                  }}
                >
                  <Box display="flex" alignItems="center">
                    
                    <Typography className={classes.Founder}
                      style={{
                        color: "#081F32",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: 18,
                        paddingLeft:"40px",
                        borderLeft: "#80808042 1px solid"
                      }}
                    >
                      Founders Letter
                    </Typography>
                  </Box>
                
                </Box>
              <Box className={classes.mainBodyContent}>
                <Typography className={classes.videoDesc}>
                  {/* <br /> */}
                 <Typography variant="h3" gutterBottom className={classes.Intro}>Introduction</Typography>
                 <br></br>
                 <hr />
                 {/* <br></br> */}
                 <div className={classes.IntroBlk}>
                 <p>Since for many of you this will be the first time you’ve come across yup.gg, perhaps it’s appropriate to
                  start at the beginning. What does yup.gg’s mission mean and why do we have the audacity to think that
                  this small group of passionate gamers and gaming professionals can pull off such a grand mission?</p>
                  <br></br>
                  {/* <br></br> */}
                  <p style={{color:"#f14e24",fontWeight:"500"}}>’yup.gg’s mission is to aggregate and organise all the opportunities in the gaming & esports industry in
                  one place, making information universally accessible and business easily actionable.’ </p>
                  <br></br>
                  <p>We’ve experienced the pain of trying to build a career in gaming and esports. Our Founding Team is
                  made up of gamers and gaming professionals. When Nicholas Khoo and I established yup.gg, we
                  wanted to help the gaming industry by creating the first business-focused community for gamers.
                  Instead of providing a single service for a specific area of gaming, we set out to bring together and
                  serve the gaming community as a whole</p>
                  <br></br>
                  <p style={{color:"#f14e24",fontWeight:"500"}}>The gaming industry is difficult to navigate.</p>
                  <br></br>
                  <p>Currently, all of the different players in (and wanting to enter) the industry have issues finding people,
                  information and the help they need to build their careers and/or launch effective partnerships and
                  campaigns. Yup.gg sets out to change all of that by making the discovery of people, projects and the
                  stuff you need as easy as possible. </p>
                  <br></br>
                  <p>Yup.gg seeks to build the largest community of gamers and gamer-related services on one platform.
                  We’ve got some great ideas as to how to do this, but we’ll need the buy-in and help from gaming
                  communities in every country and at every level of gaming. Please hold us to our goals, provide us your
                  feedback and we’ll do our best to make growth and monetisation as approachable and fun as possible. </p>
                  </div>
                  <br></br>
                  <br></br>
                  <Typography variant="h3" gutterBottom className={classes.Goals}>Goals for 2021 and 2022</Typography>
                  <br></br>
                  <hr></hr>
                  <div className={classes.GoalsListBlk}>
                  <ul className={classes.GoalsList}>
                    <li>Build the Network</li>
                    <li>Launch yupcup (Our Community esports tournament)</li>
                    <li>Launch Good Game Asia (the biggest reality show in gaming)</li>
                    <li>Launch services (free and subscription-based)</li>
                  </ul>
                  <br></br>
                  <p>We’ll keep our goals simple for this and next year, but we’ll have some cool announcements coming in
                  the final quarter of 2021. Every activation by yup.gg will be aimed at providing opportunity for the
                  gaming economy.</p>
                  <br></br>
                  <p>Whether it’s sponsoring an esports event/team, holding a community tournament that everyone can use
                  to grow their own channel/business, or taking our shot at creating the most widely distributed gaming
                  reality show in history, everything we do will be aimed at growing and pushing the business of gaming
                  forward. That means money in gamer’s pockets, ROI for brands on the campaigns they engage in and
                  opportunity for grassroots gamers to showcase their talent (and be rewarded for it).</p>
                  <br></br>
                  <p>So sign up, stay tuned and we’ll keep our users posted on who we’ve brought on the platform to help
                  you grow as well as what we’re doing to come good on our commitment to growing the gaming
                  economy through our own activations and marketing efforts.</p>
                  </div>
                  <br></br>
                  <br></br>
                  <Typography variant="h3" gutterBottom className={classes.Teams}>Team</Typography>
                  <br></br>
                  <hr></hr>
                  <div className={classes.TeamBlk}>
                  <p>Yup.gg’s culture is built on a foundation of mutual respect for each other and our differences. We have
                  built and will continue to build on a foundation of diverse people, experiences and backgrounds so we
                  can build the most complete and informative platform for gamers, worldwide.</p>
                  <br></br>
                  <p>Everyone in our team has had a voice in contributing to how the new yup.gg will be built and we can’t
                  wait to expand the current team to have more support from our HQ in Singapore, our satellite offices
                  opening in the next few years and all the ambassadors, contractors and contributors that will add to
                  yup.gg in 2021 and beyond. I’d like to personally thank them for their hard work in putting together the
                  platform during an incredibly difficult time</p>
                  </div>
                  <br></br>
                  <br></br>
                  <Typography variant="h3" gutterBottom className={classes.OurCommit}>Our Commitment to our Users</Typography>
                  <br />
                  <hr />
                  <div className={classes.OurCommitBlk}>
                  <p>While yup.gg is a business, we also want to be a significant contributor in building careers in gaming,
                  supporting entrepreneurs and helping bring more joy to the general gaming population. Yup.gg will
                  make choices where (and to what extent) we do and do not generate revenue.</p>
                  <br />
                  <p style={{color:"#f14e24",fontWeight:"500"}}>We want more entrepreneurs (contractors, content creators, artists and managers) to keep the money
                  they generate, while getting the tools they need to grow their business.</p>
                  <br />
                  <p>So we will drive a large part of our revenue through subscriptions, allowing us to build better
                  operational tools for organizations and individuals looking to do more on yup.gg. We will also take a
                  small platform fee (5% to make sure the cost of completing transactions is covered on our side
                  (payments, invoicing, fees, etc.). Beyond that, we will primarily drive our business and revenue through
                  our marketplace and yupPoints. </p>
                  <br />
                  <p>We’ll introduce both of these in Q1 of 2022 and we’re looking forward to seeing how the community
                  uses both to help increase their gaming joy. </p>
                  </div>
                  <br />
                  <br />
                  <Typography variant="h3" gutterBottom className={classes.OurInvestors}>Our Investors</Typography>
                  <br />
                  <hr />
                  <div className={classes.OurInvestorsBlk}>
                  <p>We’re honored and humbled by some of the minds that have helped us get here and continue to help us
                  build out the best economy for gamers. Our investors include gaming veterans like Kevin Lin and
                  Jonathan Shipman (Twitch Founding Team), Roland Ong (Founder of IAH Games, one of the first
                  distributors of AAA games to China), Warren Colbert (Gaming product veteran of Twitter, Activision/
                  Blizzard and Twitch), Ray Alimurung (CEO, Lazada Philippines), Kickstart Ventures (PH and Boxcar
                  Partners (USA. 
                  </p>
                  <br />
                  <p>We’ve carried the diversity that we expect to continue in our team to our investors and advisors, making
                  sure that we are able to learn and take advice from some of the best minds in gaming, globally. </p>
                  </div>
                  <br />
                  <br />
                  <Typography variant="h3" gutterBottom className={classes.Summary}>Summary & Conclusion</Typography>
                  <br />
                  <hr />
                  <div className={classes.SummaryBlk}>
                  <p>My personal goal is to revisit this document every year and feel like we’ve stayed true to our values,
                  focused on our goals and provided a better life for as many gamers as possible. If we’ve achieved this
                  simple (to say, but complex to do) goal, then I believe that yup.gg will be the home for many gamers to
                  better enjoy their games, communities, guilds, events and tournaments, all while building successful
                  and interesting lives and careers around their passion for games. </p>
                  <br />
                  <p>I thank all of the early users and initial business partners that experienced the growing pains of the
                  early platform and look forward to receiving feedback from new users that will come onto yup.gg in the
                  coming years. 
                  </p>
                  </div>
                  <br />
                  <p style={{color:"grey"}}>Sincerely,</p>
                  <br />
                  <p style={{color:"grey"}}>Raiford C. Cockfield III</p>
                </Typography>
                
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
          
            </Grid>
          </Grid>
        </Grid> 
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CeoLetterStyle = {
    twitterBtn: {
      padding: 0,
      background: "none",
      border: "none",
      width: "80%",
    },
    videoLabel: {
      fontFamily: "Poppins",
      fontSize: 13,
      display: "block",
      color: "#081F32",
      fontWeight: 400,
      marginBottom: "10px",
    },
    mainWrapper:
    {
      height: "100%",
      paddingBottom: 50,
      backgroundColor: "#FFFFFF",
      "@media (max-width: 768px)": {
        // height: "auto",
      },
    },
    mainBodyContent:
    {
      // height: "100vh",
      borderLeft: "#80808042 1px solid",
      padding: 40,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 768px)": {
        padding: 10,
      },
    },
    videoDesc:
    {
      color: "#081F32",
      fontFamily: "Poppins",
      fontSize: 13,
    },
    Intro:{
      fontWeight:"bold",
    },
    Goals:{
      fontWeight:"bold",
    },
    Teams:{
      fontWeight:"bold",
    },
    OurCommit:{
      fontWeight:"bold",
    },
    OurInvestors:{
      fontWeight:"bold",
    },
    Summary:{
      fontWeight:"bold",
    },
    GoalsList:{
      color:"#f14e24",
      paddingLeft:"15px",
      fontWeight:"500"
    },
    IntroBlk:{
      wordSpacing:"1px",
      color:"grey",
      lineHeight:"1.7",
    },
    GoalsListBlk:{
      wordSpacing:"1px",
      color:"grey",
      lineHeight:"1.7",
    },
    TeamBlk:{
      wordSpacing:"1px",
      color:"grey",
      lineHeight:"1.7",
    },
    OurCommitBlk:{
      wordSpacing:"1px",
      color:"grey",
      lineHeight:"1.7",
    },
    OurInvestorsBlk:{
      wordSpacing:"1px",
      color:"grey",
      lineHeight:"1.7",
    },
    SummaryBlk:{
      wordSpacing:"1px",
      color:"grey",
      lineHeight:"1.7",
    },
    Founder:{
      "@media (max-width: 768px)": {
        paddingLeft:"10px !important",
      }
    },
    LogoImg:{
      "@media (max-width: 768px)": {
        justifyContent:"flex-start !important",
      }
    }
  }
// Customizable Area End
export default withStyles(CeoLetterStyle)(withRouter(CeoLetter))
