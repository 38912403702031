//@ts-ignore
//@ts-nocheck
import React from "react";
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import { SiFacebook, SiTiktok } from 'react-icons/si';
import { RiTwitchFill } from "react-icons/ri";
import { Box, Link, Typography, Button, Checkbox } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Snackbar from "../../../components/src/Snackbar";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import TwitterLogin from "react-twitter-auth";
import GoogleLogin from 'react-google-login';
import { withTranslation } from 'react-i18next';

import { setupProfileStyle } from "../assets/setupProfileStyle"
import SetupProfileController, { Props } from './SetupProfileController.web';
import { starImage } from './assets';
import Copyright from "../../../components/src/Copyright";

class SetupProfile extends SetupProfileController {

  componentDidMount() {
    this.getSocialAccounts()
    this.handleTwitchSignup()
    this.googleResponse()
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  checkSocialAccount = () => {
    this.getSocialAccounts()
  }

  onFailed = () => {
    console.log("Failed")
  }
  onSuccess = (resp) => {
    resp.json().then(user => {
      if (user) {
        this.setState({ twitterUsername: user.data?.attributes?.user_name })
      }
    })
  }

  render() {
    const { classes, t , i18n } = this.props;
    const { isSocialAccount, facebookUsername, googleUsername, twitterUsername, twitchUsername } = this.state;

    const devUrl = 'https://yuppgg-68443-react-native.b68443.dev.us-east-1.aws.svc.builder.cafe';
    const stageUrl = 'https://yuppgg-68443-ruby.b68443.stage.us-east-1.aws.svc.builder.ai';
    const prodUrl = 'https://www.yup.gg';
    
    const backendDevUrl = 'https://yuppgg-68443-ruby.b68443.dev.us-east-1.aws.svc.builder.cafe';
    const backendStageUrl = 'https://yuppgg-68443-ruby.b68443.stage.us-east-1.aws.svc.builder.ai'
    const backendProdUrl = 'https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai'
      
      const twitchUrl =
        `https://id.twitch.tv/oauth2/authorize?client_id=ob87fgrb8uq1cc3pah4zr2wcgzlhn5&redirect_uri=${prodUrl}/setup-profile&response_type=token+id_token&scope=user:read:email+openid&claims={%22id_token%22:{%22email%22:null,%22email_verified%22:null},%22userinfo%22:{%22picture%22:null,%22preferred_username%22:null}}`;
        const token = localStorage.getItem("userToken")
        const twitterUrl = `${backendProdUrl}/get_twitter_token?token=${token}&callback_url=${window.location.href}`
        const twitterLoginUrl = `${backendProdUrl}/twitter/callback`

    return (
      <Box className={classes.body}>
       

        <Box className={classes.headingContent}>
          <Typography className={classes.heading} style={{fontFamily:"Poppins"}}>
            {t("Setup your profile")}
          </Typography>
          <img
            src={starImage}
            style={{ position: "absolute", height: 45, width: isWidthUp("sm", this.props.width) ? 494 : 363 }}
          />
        </Box>
        <Box
          display="flex"
          mt="28px"
          mb="48px"
          width="350px"
          justifyContent="center"
        >
          <Typography className={classes.subHeading}>
            {t("Use you existing accounts to instantly customsize your page")}
          </Typography>
        </Box>
        <Box className={classes.content} style={{width: isWidthUp("sm", this.props.width) ? 437 : 350}}>
          {/* <div style={{ display: "flex", width: "100%" }}>
            <Box display="flex" style={{ padding: "0px 10px 10px 0" }}>
              <SiTiktok className={classes.logo} />
            </Box>
            <Box display="flex" width="100%">
              <Button
                // onClick={(e) => this.props.history.push("/signup/email")}
                variant="contained" className={classes.btn} style={{ background: "none" }} >
                <Typography style={{ fontFamily: "Poppins", fontWeight: 500, color: "gb(78, 75, 102)", fontSize: 14 }}> @ Your Tiktok username</Typography>
              </Button>
            </Box>
          </div> */}


          {/* <div style={{ display: "flex", width: "100%" }}>
            <Box display="flex" style={{ padding: "0px 10px 10px 0" }}>
              <InstagramIcon className={classes.logo} />
            </Box>
            <Box display="flex" width="100%">
              <Button
                // onClick={(e) => this.props.history.push("/signup/email")}
                variant="contained" className={classes.btn} style={{ background: "none" }} >
                <Typography style={{ fontFamily: "Poppins", fontWeight: 500, color: "gb(78, 75, 102)", fontSize: 14 }}> @ Your Instagram username</Typography>
              </Button>
            </Box>
          </div> */}

          <div style={{ display: "flex", width: "100%" }}>
            <Box display="flex" style={{ padding: "0px 10px 10px 0" }}>
              <TwitterIcon className={classes.logo} style={{ color: twitterUsername ? "#1DA1F3" : "none" }} />
            </Box>
            <Box display="flex" width="100%" height="50px">

              <TwitterLogin
                loginUrl={twitterUsername ? "" : twitterLoginUrl}
                onSuccess={this.onSuccess}
                onFailed={this.onFailed}
                requestTokenUrl={twitterUsername ? "" : twitterUrl}
                className={classes.twitterBtn}
                children={
                  <Button variant='contained' className={classes.btn}
                    style={{
                      background: twitterUsername ? "#1DA1F3" : "none",
                      color: twitterUsername ? "white" : "#00000087",
                      border: twitterUsername ? "2px solid #1DA1F3" : "2px solid #D9DBE9",
                      width: "100%"
                    }}
                    onClick={() => { }}
                  >
                    <Typography style={{ fontFamily: "Poppins", fontWeight: 500, color: "gb(78, 75, 102)", fontSize: 14 }}>{twitterUsername ? twitterUsername : `${t("@ Your Twitter username")}`}</Typography>
                  </Button>
                }
              />
            </Box>
          </div>

          <div style={{ display: "flex", width: "100%" }}>
            <Box display="flex" style={{ padding: "0px 10px 10px 0" }}>
              <SiFacebook className={classes.logo} style={{ color: facebookUsername ? "#1DA1F3" : "none" }} />
            </Box>
            <Box display="flex" width="100%">
              <FacebookLogin
                fields="name,email,picture"
                scope="email,public_profile"
                appId="1800966006635263"
                returnScopes={true}
                callback={this.responseFacebook}
                render={renderProps => (
                  <Button variant='contained'
                    className={classes.btn}
                    style={{
                      background: facebookUsername ? "#1DA1F3" : "none",
                      color: facebookUsername ? "white" : "#00000087",
                      border: facebookUsername ? "2px solid #1DA1F3" : "2px solid #D9DBE9",
                    }}
                    onClick={
                      facebookUsername ? () => { } : () => { renderProps.onClick() }}
                  >
                    <Typography style={{ fontFamily: "Poppins", fontWeight: 500, color: "gb(78, 75, 102)", fontSize: 14 }}>{facebookUsername ? facebookUsername : `${t("@ Your Facebook username")}`}</Typography>
                  </Button>
                )}
              />
            </Box>
          </div>

          <div style={{ display: "flex", width: "100%" }}>
            <Box display="flex" style={{ padding: "0px 10px 10px 0" }}>
              <YouTubeIcon className={classes.logo} style={{ color: googleUsername ? "#1DA1F3" : "none" }} />
            </Box>
            <Box display="flex" width="100%">
              {/* <GoogleLogin
                clientId="1073041573631-biii08o6l6i670umi6smi9snn13e9vli.apps.googleusercontent.com"
                render={renderProps => ( */}
                  <Button variant='contained'
                    className={classes.btn}
                    style={{
                      background: googleUsername ? "#1DA1F3" : "none",
                      color: googleUsername ? "white" : "#00000087",
                      border: googleUsername ? "2px solid #1DA1F3" : "2px solid #D9DBE9",
                    }}
                    onClick={
                      googleUsername ? () => { } : () => {this.connectGoogle()}}
                  >
                    <Typography style={{ fontFamily: "Poppins", fontWeight: 500, color: "gb(78, 75, 102)", fontSize: 14 }}>{googleUsername ? googleUsername : `${t("@ Your Youtube username")}`}</Typography>
                  </Button>
                {/* )}
                // buttonText="Login"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
                autoLoad={false}
                prompt="consent"
                accessType="offline"
                isSignedIn={false}
              /> */}
            </Box>
          </div>

          <div style={{ display: "flex", width: "100%" }}>
            <Box display="flex" style={{ padding: "0px 10px 10px 0" }}>
              <RiTwitchFill className={classes.logo} style={{ color: twitchUsername ? "#1DA1F3" : "none" }} />
            </Box>
            <Box display="flex" width="100%">
              <Button
                onClick={twitchUsername ? () => { } : (e) => (window.location = twitchUrl)}
                variant="contained" className={classes.btn}
                style={{
                  background: twitchUsername ? "#1DA1F3" : "none",
                  color: twitchUsername ? "white" : "#00000087",
                  border: twitchUsername ? "2px solid #1DA1F3" : "2px solid #D9DBE9",
                }}
              >
                <Typography style={{ fontFamily: "Poppins", fontWeight: 500, color: "gb(78, 75, 102)", fontSize: 14 }}>{twitchUsername ? twitchUsername : `${t("@ Your Twitch username")}`}</Typography>
              </Button>
            </Box>
          </div>

        </Box>

        <Button
          onClick={
            (facebookUsername || googleUsername || twitterUsername || twitchUsername)
              // ? () => this.props.history.push('select-helps') :
              ? () => {this.updateProfile()} : 
              () => { }
          }
          // onClick={() => this.props.history.push('select-helps')}
          // disabled={Object.values(selectedHelps).length === 0}
          variant="contained"
          style={{
            backgroundColor: (facebookUsername || googleUsername || twitterUsername || twitchUsername)
              ? "#F14E24" : "#a6a6abeb",
            border: "1px solid #D9DBE9",
            borderRadius: 16,
            height: 34,
            width:  isWidthUp("sm", this.props.width) ? 437 : 350,
            marginBottom: 10,
            boxShadow: "none",
            color: (facebookUsername || googleUsername || twitterUsername || twitchUsername)
              ? "white" : "#F7F7FC",
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 16,
            marginTop: 20,
            // opacity: Object.values(selectedHelps).length > 0 ? 1 : 0.6
          }}
        >
          {t("Continue")}
        </Button>
        <Link
          href="/select-helps"
          underline="none"
          style={{
            
              // backgroundColor:"#F14E24" ,
              // border: "1px solid #D9DBE9",
              // borderRadius: 16,
              height: 34,
              // width:  isWidthUp("sm", this.props.width) ? 437 : 350,
              marginBottom: 14,
              // boxShadow: "none",
              color:"#055AFF" ,
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 14,
              marginTop: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
              // opacity: Object.values(selectedHelps).length > 0 ? 1 : 0.6
            
          }}
        >
          {t("Skip for Now")}
        </Link>
        


        <Box mt="220px" mb="100px">
        <Copyright />
        </Box>


        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity={this.state.isSucsess ? "success" : "error"}
        />
      </Box>
    );
  }
}

export default withTranslation()(withWidth()(withStyles(setupProfileStyle)(withRouter(SetupProfile))))