export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const notificationImg = require("../assets/notification.png");
export const backIcon = require("../assets/ic_back.png");
export const logo = require("../assets/logo.png");
export const newMessage = require("../assets/NewMessage.png");
export const searchIcon = require("../assets/searchIcon.png");
export const noNotification = require("../assets/noNotifcation.png");
export const avatar = require("../assets/avatar.png");
export const closeIcon = require("../assets/close_icon.png");
export const pdfIcon = require("../assets/ic_pdf.png");
export const noMessage = require("../assets/noMessage.png");



