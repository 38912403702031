import React, { Fragment } from "react";

// Customizable Area Start
import { StyleSheet, Platform } from "react-native";
import { withRouter } from "react-router";
import { Box, Typography } from "@material-ui/core";

import Snackbar from "../../../components/src/Snackbar";
import { logo, starImage } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import Copyright from "../../../components/src/Copyright";

class UserAgreement extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#F2F2F2"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="85%"
          pt="22px"
        >
          <img
            style={{ width: 96, height: "auto" }}
            src={logo}
            onClick={() => {
              this.props.history.push("/");
            }}
          />
        </Box>

        <Box
          display="flex"
          mt="50px"
          mb="27px"
          width="494px"
          justifyContent="center"
        >
          <Typography
            style={{
              fontSize: 26,
              color: "#14142B",
              fontFamily: "Manrope",
              fontWeight: 800
            }}
          >
            User agreement
          </Typography>
          <img
            src={starImage}
            style={{ position: "absolute", height: 45, width: 494 }}
          />
        </Box>

        <Box style={{paddingLeft:'2.5rem',paddingRight:'2.5rem',background:'#F2F2F2'}}>
        <Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
1. Introdunction
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
You agree that by clicking “Join Now”, “Join Yup”, “Sign Up” or similar links, registering, accessing or using our Services (described below), you are agreeing to enter into a legally binding contract with Yup (even if you are using our Services on behalf of a company). If you do not agree to this contract (“<strong>User Agreement</strong>”), do not access or otherwise use any of our Services. If you wish to terminate this User Agreement, you can do so at any time by closing your account and ceasing your access or use of our Services.
 
 This User Agreement, the Privacy Policy, and all policies and additional terms posted on or in yup.gg (the “<strong>Site</strong>”), any of our applications, services, products or tools, or any other service that states that they are offered under this User Agreement (collectively the “Services”) set out the terms on which Yup Pte. Ltd. ( “<strong>Yup</strong>”, “<strong>we</strong>”, “<strong>us</strong>” or “<strong>our</strong>”) offer you access to and use of our Services. The Privacy Policy, and all policies and additional terms posted on or in any of our Services are incorporated into this User Agreement, and you agree to comply with all terms of this User Agreement when accessing or using our Services.
  
 Through the Services, Yup enables its users to discover the various people, information and opportunities available within the gaming economy. Save where expressly provided otherwise, any interaction and transaction that occurs through the Services takes place directly between users, and Yup is not a party to any such interaction or transaction.
  
 Yup has no control over and does not guarantee the existence, quality, safety or legality of any content, listing or information transmitted through our Services (including any user’s content or listings), the ability of any user to provide or to pay for any product or service, or that any user will actually complete any transaction or that any user will use all information acquired through the Services in accordance with the terms of the User Agreement. Any data, information or guidance that is accessible through our Services is solely informational, and you decide to rely on any such information at your own risk.
  
 Yup reserves all available rights and remedies, including but not limited to taking legal or technical measures, to prevent or address any use of the Services in violation of this User Agreement, or the violation of any law. For instance, in the event of a serious violation of this User Agreement, we may revoke your account access and permanently suspend you from using our Services.
  
  </Typography>
</Box>

<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
2. Your eligibility to create a Yup account and use our Services
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
The Services are not for use by anyone under the age of 16. If you are under 16 years of age, or you are not of the minimum age that is required in your country to register for or use our Services, please do not use or access our Services at any time or in any manner.
 
 Even if you are of 16 years or above, if by any applicable law you are deemed to be not of sufficient age to have authority to give consent or to agree to this User Agreement on your own, you shall only use the Services under the supervision of a parent or legal guardian who consents to be bound by this User Agreement.
  
 Usage of the Services is not available to any person who is restricted or barred by Yup or any applicable authority or law in any jurisdiction from using or receiving the Services.
  
 Please note that creating an account with false information, including creating an account on behalf of others or persons under the age of 16, or providing a false representation of parental consent, or creating an account whilst restricted or barred from using or receiving the Services, constitutes a violation of this User Agreement.
  
  </Typography>
</Box>

<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
3. Your Yup account
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
You agree that:
  </Typography>

<ul style={{listStyle:"none"}}>
  <li>
    <Typography  style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 12
            }}>
    
    </Typography>
    <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
 
 1.            you will only have one Yup account, which must be associated with your real name and identity at minimum. You are to make sure that the information you provide to Yup upon the registration of your account and at all other times is (to the best of your knowledge) true, accurate, complete and up-to-date, and you are not to transfer or share any part of your account in any way with anybody else without Yup’s express written consent;
    </Typography>
  </li>
  <li>
    <Typography  style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 12
            }}>
   {/* 2.  	Data we automatically collect when you use our Services */}
    </Typography>
    <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
    2.            your account username shall not include any unauthorised, confusing or misleading use of the intellectual property right of another person, brand or organisation;  </Typography>
  </li>
  <li>
    <Typography  style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 12
            }}>
  {/* 3.  	Data collected from other sources or means */}
    </Typography>
    <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
3.            you will prevent the unauthorised use of your account by maintaining the confidentiality of your account and restricting access to your account (e.g. by using a strong password for your account and keep the password confidential); and

    </Typography>
  </li>
    <li>
    <Typography  style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 12
            }}>
  {/* 3.  	Data collected from other sources or means */}
    </Typography>
    <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
4.            you are ultimately solely responsible for any and all acts or activities that occur under or through your account, as well as any data, information or content that you submit, post or display on our Services.
    </Typography>
  </li>
</ul>



</Box>


<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
4. Licence to access and use the Services
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
By agreeing to this User Agreement, Yup grants you a personal, limited, non-sublicensable licence (the “Licence”) to access and use the Services for your personal use or internal business use only. You are solely responsible for your conduct or behaviour while on the Services.
 
 The Licence is subject to the provisions of this User Agreement. Any use of the Services in contravention of this User Agreement is strictly prohibited, and may also be in violation of intellectual property, proprietary or other legal rights or laws.
  
 Yup reserves all rights not expressly granted in this User Agreement, and nothing in this User Agreement shall be interpreted as conferring you any licence to intellectual property rights by any legal principle or theory whatsoever.
  
 Unless otherwise indicated, all content, information, and other materials on the Services, save for User Content (as defined in Clause 6 below), including without limitation, Yup’s trademarks and logos, graphics, design, information, software, computer code (including source code or object code), text, information, data and all other files, and the compilation, selection and arrangement thereof (collectively, the “Materials”) are the property of Yup, its subsidiaries, affiliates, partners and/or licensors, and are subject to the protection of various relevant local and international laws.
  
 
  </Typography>




</Box>



<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
5. Your usage of our Services
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
This clause sets out how you should and should not use our Services.
 
  </Typography>

<ul style={{listStyle:"none"}} >
  <li>
    <Typography  style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 12
            }}>
    
    </Typography>
    <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
 A.           You agree that you will:

 </Typography>
 <ul>
 <li>

<Typography style={{
           color: "#626262",
           fontFamily: "Poppins",
           fontWeight: 400,
           fontSize: 12
         }}>
1.            at all times comply with the terms of this User Agreement (including all policies and additional terms posted on or in any of our Services), as well as comply with any law, legal order or any determination or decision by Yup that applies to your use of your account (including all local laws regarding online conduct and acceptable content); and

</Typography>

</li>
<li>

<Typography style={{
           color: "#626262",
           fontFamily: "Poppins",
           fontWeight: 400,
           fontSize: 12
         }}>
provide true, accurate and complete information to us and that you will keep it updated.
</Typography>

</li>
 </ul>
  </li>
  <li>
 
    <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
You agree that you will not (and not attempt to):
</Typography>

<ul style={{listStyle:"none"}}>
  <li>

  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
1.            create a false identity on Yup, impersonate, pose or otherwise misrepresent your identity in a confusing, misleading or deceptive manner as (or to be falsely associated/affiliated with) another person, brand, or entity;
</Typography>


<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
2.            submit, upload or otherwise transmit through the Services any data, content or other information that does not belong to you and which you do not have the consent or authority to transmit, or misrepresent or falsify the source, content of, or your authority to transmit any information that is transmitted through the Services;
</Typography>



<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
3.            use another user’s account, or to forge another person’s signature;
</Typography>




<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
4.            engage in username squatting, including any attempt to sell, buy, or solicit/provide other forms of payment in exchange for transferring usernames;
</Typography>




<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
5.            post, list or upload content or items in inappropriate categories or areas on our Services;
</Typography>
<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
6.            violate or circumvent the intellectual property rights (including without limitation copyrights, trademarks, trade secrets) with respect to the Materials, or any other intellectual property, proprietary or other legal right of Yup or any other individual or organisation (whether or not they are also a user of the Services);
</Typography>
<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
7.            upload, distribute or otherwise transmit on or through our Services any information or content that is inaccurate, fraudulent, defamatory, libelous, harassing, threatening, abusive, inflammatory, obscene, or is otherwise objectionable, unlawful or infringing Yup’s or any third party’s rights in any way;
</Typography>
<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
 
 8.            interfere with any other user’s listings, profile information, account access or usage of any Service;
</Typography>
<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
9.            take any action that may undermine the quality or integrity of our Services;
</Typography>
<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
 10.   deep-link to our services for any purpose other than to promote your Yup profile or listings on our Services, without Yup’s express written consent;
</Typography>
<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
11.         collect personal information about other users of the Services without their consent;
</Typography>
<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
12.         attempt to indicate in any manner, without Yup’s express written consent, that you have a relationship with us or that we have endorsed you or your products or services;
</Typography>
<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
13.         send junk or spam messages to other users through the Services or via email or other means, including without limitation sending any form of unsolicited or bulk advertising or promotional materials or communication, or requests to participate in any charity activity, petition, contest or lucky draw or raffle, multi-level marketing / pyramid scheme, or other similar activities;
</Typography>
<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
14.         develop, support or use bots or any other form of automation tool, means or process to access or use the Services or any server, software application, or other computer resource owned, used and/or licensed by Yup;
</Typography>

<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
15.         copying, using, disclosing or distributing any information obtained from the Services whether directly or through third parties (such as search engines), without the consent of the relevant owner of such information;
</Typography>


<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
16.         develop, support or use any means, tools or processes (e.g. scraper or data mining tool) to copy, collect, compile or harvest profile information or any other data from the Services;</Typography>

<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
17.         delete, circumvent, disable, damage, or otherwise interfere with (a) security-related features of the Services or any content or information on the Services; (b) features that prevent or restrict the access, use or copying of any content accessible through the Services; (c) features that prevent or restrict access to or use of the Services or any server, software application, or other computer resource owned, used, and/or licensed by Yup; (d) features that enforce limitations on the use of the Services; or (e) the copyright or other proprietary rights notices on the Services;
</Typography>


<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
18.         interfere with, disrupt, negatively affect or impair the operation of the Services or servers or networks connected to the Services, or disobey any requirements, procedures, policies, or regulations of such networks connected to the Services, or interfere with, disrupt, negatively affect, or impair other users from their full use of the Services, or do anything that could adversely affect the functioning of the Services in any manner;
</Typography>



<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
19.         upload or otherwise transmit anything that contains software viruses, malware, or any harmful code through the Services or to any other user of the Services;
</Typography>



<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
20.         reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;
</Typography>

<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
21.         copy, disclose, distribute or use any information obtained from the Services, whether such information is obtained directly or through third parties, without Yup’s express written consent;
</Typography>

<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
22.         sell, commercialise or otherwise monetise the Services or related data or access to the Services without Yup’s express written consent;
</Typography>

<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
23.         monitor the availability, performance, or functionality of the Services by any means, tools or process, without Yup’s express written consent
</Typography>

<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
24.         modify, adapt or create derivative works based upon any part of the Services;
</Typography>

<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
             25.         simulate the appearance or function of the Services (e.g. by framing or mirroring the Services), or modifying the appearance or function of the services (e.g. by inserting overlays or elements to add, block or otherwise modify the appearance of the Services);
</Typography>

<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
26.         circumvent any measures or processes employed in the provision of our Services;
</Typography>

<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
27.         use the Services in any way other than for their intended purposes; or
</Typography>


<Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
28.         use the Services for any illegal purpose; in violation of any law, contract, intellectual property, proprietary, or other legal right; or commit a tort.
 
</Typography>




  </li>
</ul>

  </li>
 
   
</ul>



</Box>




<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
6. User Content
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
For the purposes of this User Agreement, “<strong>User Content</strong>” is defined as any content, messages, text, sound, images, applications, code, or other data or materials that a user creates, posts, uploads or otherwise transmits on, to or through the Services.
 
 In creating, posting, uploading or otherwise transmitting any User Content on, to or through the Services, you are granting Yup and our affiliates an irrevocable, royalty-free, non-exclusive, worldwide, transferable and sublicensable right to access, use, copy, translate, modify, process, create derivative content from, publish, distribute and store such User Content, without any further consent, notice, credit and/or compensation to you or others. You are solely responsible for your User Content and the consequences of posting or publishing it.
  
 Further to Clause 5 above, you represent and warrant that you are (a) the creator of the User Content or otherwise have sufficient the rights and authority to grant Yup and our affiliates the aforementioned rights and to otherwise meet your obligations under this User Agreement; (b) your User Content does not and will not: (i) infringe, violate, or misappropriate any third-party right, including any intellectual property or proprietary right, or (ii) defame or constitute a malicious falsehood against any other person; (c) the content of the User Content is true and accurate, and (d) your creating, posting, uploading or otherwise transmitting of any User Content on, to, or through the Services shall not constitute any violation of this User Agreement or any law. To the fullest extent permitted under law, you waive your right to enforce your Intellectual Property Rights in that content against Yup, our affiliates and our assignees in the use of your User Content in connection with the provision, improvement, expansion and promotion of our Services.
  
 For the avoidance of doubt, “<strong>User Content</strong>” also includes any <strong>feedback</strong>, ideas, suggestions (“Feedback”) that you may provide to Yup or its officers, directors, employees or agents from time to time. You acknowledge and agree that Yup shall be entitled to use or disclose such Feedback in whole or in part, for any purpose in any way or form, without providing any credit or compensation to you.
  
 To the fullest extent permitted by law, Yup reserves the right to screen, edit or remove any User Content created, posted, uploaded or otherwise transmitted on, to or through our Services at any time and without notice, including where such User Content may be in potential or actual violation of this User Agreement or any law (whether or not Yup is direct to do so by any competent authority), as determined by Yup in its sole discretion. You are solely responsible for creating backup copies of any User Content at your sole cost and expense.
  
 
  </Typography>




</Box>

<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
9. Liability
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
Yup does not guarantee the continuous operation of or access to our Services. The Services are provided to you on an “as is” and an “as available” basis.
 
 Under no circumstances shall Yup (including our affiliates and subsidiaries, as well as our and their respective officers, directors, employees, agents) be responsible or liable to you or any third party for any claims, costs, proceedings, demands, losses, damages, expenses or liabilities whatsoever in connection with your use of any of our Services, including any loss or damage resulting from any User Content of other users that you may encounter when using the Services, your use of or your inability to use our Services, glitches, bugs, errors, or inaccuracies of any kind in our Services, delays or disruptions in any of our Services, or any action that we may take in connection with your Yup account with respect to any potential or actual violation of this User Agreement or any law.
  
 We cannot prevent every misuse of our Services, and your use of the Services is at your own risk.
  
  
 
  </Typography>




</Box>



<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
8. Rights granted to third parties
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
Without limiting any other rights granted to third parties that are set out elsewhere in this User Agreement, the following sets out additional rights that may be granted to third parties that apply in certain scenarios.
 
 If you use a third-party service (e.g. a social media service) to sign up for and log in to the Services, both Yup and such third party shall be permitted to collect, use and disclose your information in accordance with the Privacy Policy and the third party’s privacy policy (or equivalent) respectively.  
  
 If any Service that you are using has been purchased by another party (e.g. your employer or agency) for you to use, such party who purchased such Service may have the right to control your access to and get reports on your use of such purchased Service.
  
 
  </Typography>




</Box>


<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
10. Indemnity
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
You will indemnify and hold us (including our affiliates and subsidiaries, as well as our and their respective officers, directors, employees, agents) harmless from any claims, costs, proceedings, demands, losses, damages, expenses or liabilities from any third party due to or arising out of your violation of this User Agreement or any law.
 
 
  </Typography>




</Box>



<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
11. Governing Law and Dispute Resolution
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
You agree that the governing law of this User Agreement shall be the laws of the Republic of Singapore, without regard to principles of conflict of laws.
 
 You and Yup agree that any and all disputes or difference between you and Yup arising out of or relating to this User Agreement shall be resolved exclusively referred to and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of the Singapore International Arbitration Centre for the time being in force, which rules are deemed to be incorporated by reference in this clause. The arbitration tribunal shall consist of one arbitrator, and the language of the arbitration shall be English.
  
  </Typography>




</Box>



<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
12. Enforceability
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
If any provision of this Agreement (or part of a provision) is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force, and if any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention of such provision.
 
 
  </Typography>




</Box>



<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
13. No waiver
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
Any failure or delay by Yup in enforcing any right or remedy in this User Agreement that we are entitled to shall not constitute a waiver of such right or remedy.
 
 
  </Typography>




</Box>



<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
14. No third party rights
 
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
A person who is not party to this Agreement has no rights under the Contracts (Rights of Third Parties) Act (Chapter 53B of Singapore) to enforce any term of this Agreement, but this does not affect any right or remedy of a third party which exists or is available apart from the said Act.
 
 
  </Typography>




</Box>




<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
15. Amendment of this User Agreement
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
Yup reserves the right to modify, update, amend, or otherwise change the terms of this User Agreement at any time. It is your responsibility to review this User Agreement regularly. Your continued use of our Services following the posting of any change to this User Agreement, whether or not you have reviewed such changes, shall constitute your binding acceptance of such change. If you object to any amendment, you should not continue to access or otherwise use any of our Services.
 
 
  </Typography>




</Box>




<Box style={{marginTop:'1rem'}}>
  <Typography   style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: 15
            }}>
16. Translations
 
  </Typography>
  <Typography style={{
              color: "#626262",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: 12
            }}>
The original of this User Agreement is written in the English language. In the event of any conflict between the English and other language versions, the English version shall prevail.
 
 
  </Typography>




</Box>




</Box>

        <Box mt="10px" mb="100px" style={{background:'F2F2F2'}}>
        <Copyright />
        </Box>

        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  }
  // Customizable Area End
});

export default withRouter(UserAgreement as React.ComponentType<any>);
