//@ts-ignore
//@ts-nocheck
import { Link, Typography, Button, IconButton } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import ProfileHeader from "../../user-profile-basic/src/ProfileHeader";
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SubSideMenu from "./SubSideMenu.web";
import ChangePasswordController from "./ChangePasswordController";
import { defaultImg } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Snackbar from "../../../components/src/Snackbar";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

class ChangePassword extends ChangePasswordController {
  constructor(props) {
    super(props);
  
  }

  componentDidMount() {
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  onForgotPassword = () => {
    window.localStorage.clear();
    this.props.history.push("/forgot-password")
  }

  render() {
    const { classes, t , i18n } = this.props;
    let userName = localStorage.getItem("userName")

    return (
      <>
        <ProfileHeader heading={t("Change Password")} subModule="3" />
        <Grid container className={classes.mainContainer}>
          <Grid item sm={3} xs={12} style={{ position: "relative" }}>
            <SideMenu subModule="3" />
          </Grid>
          <Grid item xs={2} className={classes.settingIcon}>
            <SubSideMenu handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen}/>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className={classes.privacyBlock}>
              <Formik
                initialValues={
                  {
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                  showPassword:false,
                  showConfirmPassword: false,
                  showOldPassword: false,
                  oldpassworderror:this.state.oldpassworderror
                }}
                validationSchema={this.changePasswordValidations(t)}
                // validateOnMount={true}
                onSubmit={(values) => {
                  this.changePassword(values);
                }}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  isValid
                }) => {
                  return (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={2}>
                          <div>
                            <img
                              src={this.state.getUserData.attributes?.profile_picture||defaultImg}
                              style={{
                                height: "30px",
                                width: "30px",
                                float: "right",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                          <Typography className={classes.userName} style={{fontFamily:"Poppins"}}>
                            {/* {this.state.getUserData.attributes?.first_name} */}
                            {userName}
                          </Typography>
                        </Grid>
                      </Grid>
                     
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={2}>
                          <Typography
                            style={{
                              textAlign: "right",
                              color: "rgb(78 75 75)",
                              fontWeight: "700",
                              fontSize: "1.2rem",
                              padding: "15px 0px",
                              fontFamily: "Poppins",
                            }}
                          >
                            {t("Old Password")}
                          </Typography>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                          <Field
                            type={values.showOldPassword ? "text" : "password"}
                            name="oldPassword"
                            className={classes.field}
                            onChange={(e)=>{
                              setFieldValue("oldPassword" , e.target.value)
                              setFieldValue("oldpassworderror" , '')
                            }}
                          />
                         {values.showOldPassword ? (
                              <IconButton
                                onClick={() => setFieldValue("showOldPassword", false)}
                                style={{ padding: 0, backgroundColor: "transparent", justifyContent: "flex-end" }}
                                disableRipple={true}
                              >
                                <Visibility
                                  style={{
                                    width: 24,
                                    height: 24,
                                    marginRight: 16,
                                    color: "#000000",
                                    opacity: 0.54,
                                    position: "absolute"
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => setFieldValue("showOldPassword", true)}
                                style={{ padding: 0, backgroundColor: "transparent", justifyContent: "flex-end" }}
                                disableRipple={true}
                              >
                                <VisibilityOff
                                  style={{
                                    width: 24,
                                    height: 24,
                                    marginRight: 16,
                                    color: "#000000",
                                    opacity: 0.54,
                                    position: "absolute"
                                  }}
                                />
                              </IconButton>
                            )}
                       {values.oldpassworderror ? <Typography style={{color: "rgb(241, 78, 36)",
                            fontSize: "1.1rem",
                            display: "block",
                         paddingLeft: "10px", fontFamily: "Poppins",}}>{values.oldpassworderror}</Typography>
                         :
                         <ErrorMessage component="Typography" name="oldPassword" style={{color: "rgb(241, 78, 36)", fontFamily: "Poppins",
                         fontSize: "1.1rem",
                          display: "block",
                         paddingLeft: "10px",}}/>
                         }
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={2}>
                          <Typography
                            style={{
                              textAlign: "right",
                              color: "rgb(78 75 75)",
                              fontWeight: "700",
                              fontSize: "1.2rem",
                              padding: "15px 0px",
                              fontFamily: "Poppins",
                            }}
                          >
                            {t("New Password")}
                          </Typography>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                          <Field type={values.showPassword ? "text" : "password"} 
                            name="newPassword" className={classes.field} />
                            {values.showPassword ? (
                              <IconButton
                                onClick={() => setFieldValue("showPassword", false)}
                                style={{ padding: 0, backgroundColor: "transparent", justifyContent: "flex-end" }}
                                disableRipple={true}
                              >
                                <Visibility
                                  style={{
                                    width: 24,
                                    height: 24,
                                    marginRight: 16,
                                    color: "#000000",
                                    opacity: 0.54,
                                    position: "absolute"
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => setFieldValue("showPassword", true)}
                                style={{ padding: 0, backgroundColor: "transparent", justifyContent: "flex-end" }}
                                disableRipple={true}
                              >
                                <VisibilityOff
                                  style={{
                                    width: 24,
                                    height: 24,
                                    marginRight: 16,
                                    color: "#000000",
                                    opacity: 0.54,
                                    position: "absolute"
                                  }}
                                />
                              </IconButton>
                            )}
                            <ErrorMessage component="Typography" name="newPassword" style={{color: "rgb(241, 78, 36)", fontFamily: "Poppins",
                              fontSize: "1.1rem",
                              display: "block",
                              paddingLeft: "10px",}}/>
                          
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={2}>
                          <Typography
                            style={{
                              textAlign: "right",
                              color: "rgb(78 75 75)",
                              fontWeight: "700",
                              fontSize: "1.2rem",
                              padding: "8px 0px",
                              fontFamily: "Poppins",
                            }}
                          >
                            {t("Confirm New Password")}
                          </Typography>
                        </Grid>
                        <Grid item xs={9} sm={10} className={classes.passwordField}>
                          <Field type={values.showConfirmPassword ? "text" : "password"}
                            name="confirmPassword" className={classes.field} />
                            {values.showConfirmPassword ? (
                              <IconButton
                                onClick={() => setFieldValue("showConfirmPassword", false)}
                                style={{ padding: 0, backgroundColor: "transparent", justifyContent: "flex-end" }}
                                disableRipple={true}
                              >
                                <Visibility
                                  style={{
                                    width: 24,
                                    height: 24,
                                    marginRight: 16,
                                    color: "#000000",
                                    opacity: 0.54,
                                    position: "absolute"
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => setFieldValue("showConfirmPassword", true)}
                                style={{ padding: 0, backgroundColor: "transparent", justifyContent: "flex-end" }}
                                disableRipple={true}
                              >
                                <VisibilityOff
                                  style={{
                                    width: 24,
                                    height: 24,
                                    marginRight: 16,
                                    color: "#000000",
                                    opacity: 0.54,
                                    position: "absolute"
                                  }}
                                />
                              </IconButton>
                            )}
                            <ErrorMessage component="Typography" name="confirmPassword" style={{color: "rgb(241, 78, 36)", fontFamily: "Poppins",
                            fontSize: "1.1rem",
                            display: "block",
                            paddingLeft: "10px",}}/>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={2}/>
                        <Grid item xs={9} sm={10}>
                          <div className={classes.buttonContainer} >
                            <Button
                              disabled={!isValid}
                              variant="contained"
                              type="submit"
                              style={{
                                backgroundColor: "#F14E24",
                                border: "1px solid #D9DBE9",
                                borderRadius: 16,
                                height: 35,
                                marginBottom: 14,
                                boxShadow: "none",
                                color: "#F7F7FC",
                                fontFamily: "Poppins",
                                fontSize: 13,
                                marginTop: 18,
                                opacity: !isValid ? 0.6 : 1
                              }}
                            >
                              {t("Change Password")}
                            </Button>
                            <Link className={classes.forgotPassword} onClick={this.onForgotPassword} underline="none" >
                            {t("Forgot password?")}
                          </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
      </>
    );
  }
}

const style = {
  privacyBlock: {
    // marginTop: 45,
    padding: "50px 50px 0px 50px",
    position: "relative",
    "@media (max-width: 768px)": {
      marginTop: 0,
    }
  },
  mainContainer: {
    paddingBottom: 0,
    height: "100%",
    "@media (max-width: 768px)": {
      height: 0
    }
  },
  field: {
    margin: "7px 0px",
    height: "40px",
    width: "70%",
    borderRadius: "25px",
    border: "2px solid #ccc",
    backgroundColor: "FAFAFA",
    outline:"none",
    padding:"7px 15px",
    fontSize:"1.3rem",
    color: "#646464",
    "@media (max-width: 768px)": {
      margin: "11px 0px",
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "70%",
    "@media (max-width: 768px)": {
      display: "block",
      width: "100%",
      marginLeft: 20,
    },
  },
  userName: {
    color: "rgb(84 82 82 / 85%)",
    fontWeight: "500",
    fontSize: "15px",
    padding: "7px 0px",
    "@media (max-width: 768px)": {
      padding: "7px 20px",
    },
  },
  settingIcon: {
    borderLeft: "1px solid rgba(128, 128, 128, 0.26)", 
    borderRight: "1px solid rgba(128, 128, 128, 0.26)",
    "@media (max-width: 768px)": {
      borderLeft: "none",
      borderRight: "none",
      position: "absolute",
      right: "40px",
      top: "16px",
    },
  },
  forgotPassword: {
    fontSize: 14,
    color: "#F14E24",
    fontFamily: "Poppins",
    fontWeight: 500,
    cursor: "pointer",
  "@media (max-width: 768px)": {
    display: "block"
  }
  },
}

export default withTranslation()(withStyles(style)(withRouter(ChangePassword)))
