//@ts-ignore
//@ts-nocheck
import { Link, Typography, Button, Checkbox, Box } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';;
import { facebookImg, twitchImg, twitterImg, youtubeImg, tiktokImg, instagramImg } from "./assets";
import ProfileHeader from '../../user-profile-basic/src/ProfileHeader';
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import SubSideMenu from "./SubSideMenu.web";
import TwitterLogin from "react-twitter-auth";
import SettingController from './SettingController.web';
import Snackbar from "../../../components/src/Snackbar";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { withTranslation } from 'react-i18next';

class ConnectSocialAccount extends SettingController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getSocialAccounts()
    this.handleTwitchSignup()
    this.googleResponse()
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  onFailed = () => {
    console.log("Failed")
  }
  onSuccess = (resp) => {
    resp.json().then(user => {
      if (user) {
        this.setState({ twitterUsername: user.data?.attributes?.user_name })
      }
    })
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes, t , i18n } = this.props
    const { facebookUsername, googleUsername, twitterUsername, twitchUsername, isFacebookConnect } = this.state

    const twitchStageUrl =
      "https://id.twitch.tv/oauth2/authorize?client_id=ob87fgrb8uq1cc3pah4zr2wcgzlhn5&redirect_uri=https://www.yup.gg/settings/connect-account&response_type=token+id_token&scope=user:read:email+openid&claims={%22id_token%22:{%22email%22:null,%22email_verified%22:null},%22userinfo%22:{%22picture%22:null,%22preferred_username%22:null}}";

    const token = localStorage.getItem("userToken")
    const twitterUrl = `https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/get_twitter_token?token=${token}&callback_url=${window.location.href}`  

    return (
      <>
        <ProfileHeader heading={t("Select a network to connect a profile")} subModule="3" connectBlock/>
        <Grid container style={{ paddingBottom: 0, height: "100%" }}>
          <Grid item sm={3} xs={12} style={{ position: "relative" }}>
            <SideMenu subModule="3" />
          </Grid>
          <Grid item xs={2} className={classes.settingIcon}>
            <SubSideMenu handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen}/>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className={classes.privacyBlock}>
              <Typography className={classes.heading}>
                {t("If you have several accounts to connect, don't worry--we'll bring you back to this screen after each profile is set up")}.
              </Typography>
              <div style={{ paddingBottom: 15, display: "flex" }}>
                <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                  <img src={twitterImg} style={{ width: 26, height: "auto" }} />
                </Box>
                <Box display="flex" width="100%">
                  <TwitterLogin
                    loginUrl={twitterUsername ? "" : "https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/twitter/callback"}
                    onSuccess={this.onSuccess}
                    onFailed={this.onFailed}
                    requestTokenUrl={twitterUsername ? "" : twitterUrl}
                    className={classes.twitterBtn}
                    children={
                      <Button variant='contained' className={classes.btn}
                        style={{
                          backgroundColor: twitterUsername ? "black" : "#FFFFFF",
                          color: twitterUsername ? "white" : "rgb(184 184 189)",
                          width: "100%"
                        }}
                        onClick={() => { }}
                      >
                        <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: 14 }}>{twitterUsername ? twitterUsername : `${t("Connect Twitter")}`}</Typography>
                      </Button>
                    }
                  />
                </Box>
              </div>
              <div style={{ paddingBottom: 15, display: "flex" }}>
                <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                  <img src={facebookImg} style={{ width: 26, height: "auto" }} />
                </Box>
                <Box display="flex" width="100%">
                  <FacebookLogin
                    fields="name,email,picture"
                    scope="email,public_profile"
                    appId="1800966006635263"
                    returnScopes={true}
                    callback={isFacebookConnect ? this.responseFacebook : () => {}}
                    render={renderProps => (
                      <Button variant='contained'
                        className={classes.btn}
                        style={{
                          backgroundColor: facebookUsername ? "black" : "#FFFFFF",
                          color: facebookUsername ? "white" : "rgb(184 184 189)",
                        }}
                        onClick={facebookUsername ? () => { } : () => { renderProps.onClick() }}
                      >
                        <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: 14 }}>{facebookUsername ? facebookUsername : `${t("Connect Facebook")}`}</Typography>
                      </Button>
                    )}
                  />
                </Box>
              </div>
              <div style={{ paddingBottom: 15, display: "flex" }}>
                <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                  <img src={youtubeImg} style={{ width: 26, height: "auto" }} />
                </Box>


                <Box display="flex" width="100%">
                      <Button variant='contained'
                        className={classes.btn}
                        style={{
                          backgroundColor: googleUsername ? "black" : "#FFFFFF",
                          color: googleUsername ? "white" : "rgb(184 184 189)",
                        }}
                        onClick={
                          googleUsername ? () => { } : () => {this.connectGoogle()}}
                      >
                        <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: 14 }}> {googleUsername ? googleUsername : `${t("Connect Youtube")}`}</Typography>
                      </Button>
                </Box>
              </div>
              <div style={{ paddingBottom: 15, display: "flex", marginBottom: "50%" }}>
                <Box display="flex" style={{ padding: "10px 30px 10px 0" }}>
                  <img src={twitchImg} style={{ width: 26, height: "auto" }} />
                </Box>
                <Box display="flex" width="100%">
                  <Button
                    onClick={twitchUsername ? () => { } : (e) => (window.location = twitchStageUrl)}
                    variant="contained" className={classes.btn}
                    style={{
                      backgroundColor: twitchUsername ? "black" : "#FFFFFF",
                      color: twitchUsername ? "white" : "rgb(184 184 189)",
                    }}
                  >
                    <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: 14 }}> {twitchUsername ? twitchUsername : `${t("Connect Twitch")}`}</Typography>
                  </Button>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity={this.state.isSucsess ? "success" : "error"}
        />
      </>
    )
  }
}

const ConnectSocialAccountStyle = {
  heading: {
    color: "#191919",
    fontFamily: "Poppins",
    fontSize: 17,
    marginBottom: 17,
    fontWeight: 500,
  },
  btn: {
    border: "1px solid #D9DBE9",
    borderRadius: 16,
    width: "80%",
    mixWidth: "auto",
    height: 50,
    boxShadow: "none",
    justifyContent: "left",
    color: "rgb(184 184 189)",
    "&:hover": {
      backgroundColor: 'black !important',
      color: "white !important",
      boxShadow: "none",
    },
    "@media (max-width: 768px)": {
      width: "90%",
      "&:hover": {
        backgroundColor: 'black !important',
        color: "white !important",
        boxShadow: "none",
      },
    },
  },
  twitterBtn: {
    padding: 0,
    background: "none",
    border: "none",
    width: "80%",
    "@media (max-width: 768px)": {
      width: "90%",
    }
  },
  privacyBlock: {
    // marginTop: 45,
    padding: "50px 100px 0 50px",
    position: "relative",
    "@media (max-width: 768px)": {
      marginTop: 0,
      padding: "50px 0px 0 50px",
    }
  },
  settingIcon: {
    borderLeft: "1px solid rgba(128, 128, 128, 0.26)", 
    borderRight: "1px solid rgba(128, 128, 128, 0.26)",
    "@media (max-width: 768px)": {
      borderLeft: "none",
      borderRight: "none",
      position: "absolute",
      right: "40px",
      top: "16px",
    }
  },
}

export default withTranslation()(withStyles(ConnectSocialAccountStyle)(ConnectSocialAccount))