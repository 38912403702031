import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  auth: string | null | undefined;
  loading: boolean;
  getSubscribeValue: any;
  error: any;
  FeedbackEmailsCheckedOrNot: boolean;
  ReminderEmailsCheckedOrNot: boolean;
  ProductEmailsCheckedOrNot: boolean;
  NewsEmailsCheckedOrNot: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAndSmsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  auth: string | null | undefined = window.localStorage.getItem("userToken");
  getSubscribeApiCallId: any;
  SubscribeDataApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      auth: "",
      getSubscribeValue: {},
      error: "",
      FeedbackEmailsCheckedOrNot: false,
      ReminderEmailsCheckedOrNot: false,
      ProductEmailsCheckedOrNot: false,
      NewsEmailsCheckedOrNot: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getSubscribe();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      //runEngine.debugLog("Message Recived", message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson.data) {
        if (apiRequestCallId === this.getSubscribeApiCallId) {
          this.setState({
            FeedbackEmailsCheckedOrNot: responseJson.data.attributes.feedback_emails,
            ReminderEmailsCheckedOrNot: responseJson.data.attributes.reminder_emails,
            ProductEmailsCheckedOrNot: responseJson.data.attributes.product_emails,
            NewsEmailsCheckedOrNot: responseJson.data.attributes.news_emails,
            loading: false,
          });
        } else if (apiRequestCallId === this.SubscribeDataApiCallId) {
        }
      } else if (
        apiRequestCallId === this.SubscribeDataApiCallId &&
        responseJson.errors
      ) {
        //this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  handleChange = (evt) => {
    const formData = new FormData();
    let check: any = false;
    let targetName: any = evt.target.name
    let targetValue: any = evt.target.value
    if (evt.target?.checked) {
      check = true
    }

    if (targetName === 'FeedbackEmails') {
      this.setState({
        FeedbackEmailsCheckedOrNot: check
      });
      formData.append('subscribe_notification[feedback_emails]', check);
      this.Subscribed(formData)
    }
    else if (targetName === 'ProductEmails') {
      this.setState({
        ProductEmailsCheckedOrNot: check
      });
      formData.append('subscribe_notification[product_emails]', check);
      this.Subscribed(formData)
    }
    else if (targetName === 'ReminderEmails') {
      this.setState({
        ReminderEmailsCheckedOrNot: check
      });
      formData.append('subscribe_notification[reminder_emails]', check);
      this.Subscribed(formData)
    }
    else if (targetName === 'NewsEmails') {
      this.setState({
        NewsEmailsCheckedOrNot: check
      });
      formData.append('subscribe_notification[news_emails]', check);
      this.Subscribed(formData)
    }
  };

  // Customizable Area Start
  getSubscribe = () => {
    try {
      const header = {
        token: this.auth,
      };

      const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getSubscribeApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSubscribeAPIEndPoint}${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  Subscribed = (value: any) => {
    try {
      const header = {
        token: this.auth
      };

      const httpBody = value;
      // const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.SubscribeDataApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.subscribeAPIEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };
  // Customizable Area End


}
