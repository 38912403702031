//@ts-ignore
//@ts-nocheck
import React from 'react';
import { withRouter } from 'react-router';
import { Grid, item, Box, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";


// import i18n from 'i18next';

import NewHeader from "./NewHeader";
// import Footer from "../../../components/src/Footer.web";
import { NewLandingPageStyle } from "../assets/LandingPage";
import leftArrowImage from '../assets/left_arrow.png';
import phoneImg1 from '../assets/phone_img1.png';
import phoneImg2 from '../assets/phone_img2.png';
import phoneImg3 from '../assets/phone_img3.png';
import phoneImg4 from '../assets/phone_img4.png';
import landingDiamondImg from '../assets/diamond.png';
import landingShapedimaImg from '../assets/shapedima.png';
import followBgImg from '../assets/follow_bg.png';
import backgroundImage from '../assets/backgroundLanding.png';
import followBgCircleImg from '../assets/follow_bg_circle.png';
import landingPublishersImg from '../assets/publishers.png';
import LandingPage2Controller, { Props } from './LandingPage2Controller';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { HiArrowNarrowRight } from "react-icons/hi";
import Footer from "../../../components/src/Footer.web";


class NewLandingPage extends LandingPage2Controller {
  render() {
    const { classes, t, i18n } = this.props
    return (
      <Box class={classes.landingMainWrapper}>

        {/* top content */}
        <div className={classes.mainContanier}>
          <NewHeader />
          <Box className={classes.contentP + " " + classes.contentWrapper + " " + classes.headerCotenblock}>
            {/* <img src={backgroundImage} className={classes.backgroundImage} alt="" /> */}
            {/* contentHeader */}
            <Box className={classes.contentHeader}>
              <h1 className={classes.title + " " + classes.contentHeaderP}>{t("Unifying the")}
                <br></br>
                {t("Gaming economy")}</h1>
              <p className={classes.lead}>
               {t("yup.gg helps you navigate the gaming universe")} <br></br>
               {t("Find the people, information and opportunities you need to succeed")} 
              </p>
              <Button className={classes.landingJoinBtn} onClick={() => { this.props.history.push('/claim-username') }}>
                {t("Sign up for our beta")}
                {/* <img src={leftArrowImage} alt="landingJoinBtn" style={{ color: "white" }}/>  */}
                <HiArrowNarrowRight style={{ color: "white", fontSize: "x-large", marginLeft: 18 }}/>
              </Button>
            </Box>
            {/* ends: contentHeader */}

            {/* social section */}
            <Box className={classes.socialSection + " " + classes.socialmain}>
              <Grid container className={classes.socialRow}>
                <Grid xs={12} sm={6}>
                  <span className={classes.socialSectiontitle}>{t("YOUR PROFESSIONAL HOME")}</span>
                  <h3 className={classes.title + " " + classes.titleH3}
                    style=
                    {{
                      margin: "20px 0",
                      whitspace: "nopwrap",
                    }}>{t("Build your profile")}</h3>
                  <p className={classes.lead}>
                   {t("One home to showcase your personality, skills and achievements in gaming and gaming-related work")}
                  </p>
                  <p className={classes.lead}>
                    {t("Looking for work or sponsorship?Create listings to show other users how you can work with others")}
                  </p>
                </Grid>
                <Grid xs={12} sm={6} className={classes.socialRightBlock}>
                  <div className={classes.phoneImgrow}>
                  <div className={classes.phoneImgSpacing  + " " + classes.phoneImgDiv}>
                      <img src={phoneImg1} className={classes.phoneImg} alt="" />
                    </div>
                    <div className={classes.phoneImgDiv}>
                      <img src={phoneImg2} className={classes.phoneImg} alt="" />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
            {/* ends: social section */}
          </Box>
        </div>
        {/* ends: top content */}

        {/* follow section */}
        <Box className={classes.followBgImage}>
          <Box className={classes.contentP + " " + classes.subContentWrapper}>
            <Box className={classes.socialSection}>
              <Grid container>
                <Grid xs={12} sm={6} className={classes.socialCol + " " + classes.followLeftSection}>
                  {/* <img src={followBgCircleImg} className={classes.followBgImg} alt="" /> */}
                  <div className={classes.phoneImgrow}>
                  <div className={classes.phoneImgSpacing  + " " + classes.phoneImgDiv}>
                      <img src={phoneImg3} className={classes.phoneImg} alt="" />
                    </div>
                    <div className={classes.phoneImgDiv}>
                      <img src={phoneImg4} className={classes.phoneImg} alt="" />
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} sm={6} className={classes.followRightSection + " " + classes.socialCol}>
                  {/* <div className={classes.followBgImg} > */}
                  <span className={classes.socialSectiontitle}>{t("FIND PEOPLE TO WORK WITH")}</span>
                  <h3 className={classes.title + " " + classes.titleH3}
                    style=
                    {{
                      margin: "20px 0",
                    }}>{t("Follow connect")}<br></br>
                    {t("and collaborate")}</h3>
                  <p className={classes.lead}>
                   {t("Discover the right gamers, creators, designers and brands to collaborate with and build out your professional gaming network")} 
                  </p>
                  <p className={classes.lead}>
                    {t("Find and support your friends and colleagues, as well as your favorite creators and esports organizations, to show your allegiances and get more from your fandom")}
                  </p>
                  {/* </div> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        {/* ends: follow section */}

        {/* feature section */}
        <Box className={classes.contentP + " " + classes.mainWrapper}>
          <Box className={classes.upcomingWrapper}>
            <Box className={classes.socialSection}>
              <h2 className={classes.title + " " + classes.titleH3 + " " + classes.featureTitle}>{t("Upcoming features")}</h2>
              <Grid container spacing={2} className={classes.featureRow}>
                <Grid item xs={12} md={4} className={classes.colPr}>
                  <div className={classes.featureCard + " " + classes.featureLeftCard}>
                    <span className={classes.socialSectiontitle}>{t("INSIGHTS")}</span>
                    <div className={classes.topBlock}>
                      <img src={landingDiamondImg} className={classes.landingDiamondImg} alt="Diamond Img" />
                      <img src={landingShapedimaImg} className={classes.landingShapedimaImg} alt="Shapedima Img" />
                    </div>
                    <h5 className={classes.landingFeatureh5}>
                      {/* {t("Insider access to")}<br></br>{t("the gaming")}<br></br>{t("industry")} */}
                      {t("Insider access to the gaming industry")}

                    </h5>
                    <p className={classes.featureLead}>
                      {t("Learn more about the industry, how it operates and")}<br></br>{t("how to better achieve your objectives")}
                    </p>
                    <span className={classes.socialSectiontitle}>{t("Coming soon")}</span>
                  </div>
                </Grid>
                <Grid item xs={12} md={8} className={classes.featureRightBlock}>
                  <div className={classes.featureCard}>
                    <span className={classes.socialSectiontitle}>{t("PUBLISHERS")}</span>
                    <p className={classes.featureLead + " " + classes.featureP}>
                      {t("Enjoy special access to games and updates from")} <br></br>{t("your favorite developers and publishers")}
                    </p>
                    <span className={classes.socialSectiontitle}>{t("Coming soon")}</span>
                    <img src={landingPublishersImg} className={classes.landingPublishersImg} alt="Diamond Img" />
                  </div>
                  <Grid container spacing={2} className={classes.colPt}>
                    <Grid item xs={12} md={6} className={classes.colPr}>
                      <div className={classes.featureCard + " " + classes.publisherCard}>
                        <span className={classes.socialSectiontitle}>{t("OPPORTUNITIES")}</span>
                        <h5 className={classes.landingFeatureh5 + " " + classes.landingPublisherh5}>
                          {t("Join")}<br></br>
                          {t("campaigns")}<br></br>
                          {t("Earn rewards")}
                        </h5>
                        <span className={classes.socialSectiontitle + " " + classes.featureSectiontitle}>{t("Coming soon")}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.colPr}>
                      <div className={classes.featureCard + " " + classes.publisherCard}>
                        <span className={classes.socialSectiontitle}>{t("FINANCE")}</span>
                        <h5 className={classes.landingFeatureh5 + " " + classes.landingPublisherh5}>
                         + {t("Marketplace")}<br></br>
                         + {t("Wallet")}<br></br>
                        </h5>
                        <span className={classes.socialSectiontitle + " " + classes.featureSectiontitle}>{t("Coming soon")}</span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        {/* ends: feature section */}

        <Footer />
      </Box >
    )
  }
}

export default withTranslation()(withStyles(NewLandingPageStyle)(withRouter(NewLandingPage)))

