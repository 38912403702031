//@ts-ignore
//@ts-nocheck
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as Yup from "yup";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { defaultImg } from "./assets";

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onClick?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  error: string | null;
  listSkills: any[];
  selectedSkills: any[];
  userData: any;
  loading: boolean;
  socialAccounts: any[];
  brands: any[];
  
  HamburgerMenuOpen : boolean
  showShareMenu: boolean
  ImgDialogOpen: boolean
  isProfileImage: boolean
  notificationCount:any
  countryNames: any[]
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AboutController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  notificationCountGetApiCallId: any;

  userAttr: any;
  aboutApiCallId: any;
  getSkillsApiCallId: any;
  updateSkillsApiCallId: any;
  updateHeaderApiCallId: any;
  getUserDataApiCallId: any;
  socialAccountsApiCallId: any;
  SelectedSkillsApiCallId: any;
  getSocialAccountsApiCallId: any;
  getUserInformationApiCallId: any;
  getBrandsApiCallId: any;
  connectSocialAccountApiCallId: any;
  connectTwitchAccountApiCallId: any;
  connectGoogleApiCallId: any;
  connectYoutubeApiCallId: any;
  getCountrylistGetApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      profileUrl: defaultImg,
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",
      notificationCount:0,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      error: null,
      listSkills: [],
      selectedSkills: [],
      userData: {},
      loading: false,
      socialAccounts: [],
      brands: [],
      isSucsess: false,
      facebookUsername: "",
      googleUsername: "",
      twitterUsername: "",
      twitchUsername: "",
      HamburgerMenuOpen:false,
      showShareMenu: false,
      ImgDialogOpen: false,
      isProfileImage: false,
      countryNames: []
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId && responseJson) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData) {
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }

              if (regexData.email_validation_regexp) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules
                });
              }
            }
          }
        } else if (apiRequestCallId === this.userProfileGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data.attributes;

            if (this.userAttr !== null && this.userAttr !== undefined) {
              let email = this.userAttr.email;
              let firstName = this.userAttr.first_name;
              let lastName = this.userAttr.last_name;
              let currentCountryCode = this.userAttr.country_code;
              let phoneNumber = this.userAttr.phone_number
                ? this.userAttr.phone_number
                : "";

              this.setState({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
              });

              //@ts-ignore
              this.txtInputFirstNameProps.value = firstName;

              //@ts-ignore
              this.txtInputLastNameProps.value = lastName;

              //@ts-ignore
              this.txtInputEmailProps.value = email;

              //@ts-ignore
              this.txtInputPhoneNumberProps.value = phoneNumber;

              this.registrationAndLoginType = this.userAttr.type;

              if (this.userAttr.country_code) {
                this.setState({ currentCountryCode: currentCountryCode });
              }

              if (
                configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: true
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: false,
                  llDoChangePwdContainerVisible: false
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
              ) {
                this.setState({
                  llChangePwdDummyShowContainerVisible: true,
                  edtMobileNoEnabled: false,
                  countryCodeEnabled: false
                });
                this.txtInputPhoneNumberProps.editable = false;
              }
            }
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired", "Please Log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiChangePhoneValidation) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success
            this.validateAndUpdateProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }else if (apiRequestCallId === this.notificationCountGetApiCallId) {
          if (responseJson != null && responseJson.errors === undefined) {
           
            this.setState({notificationCount:responseJson.unread_notification_count})
          } else {
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }  
        else if (
          apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
        ) {
          if (responseJson != null && responseJson.errors === undefined) {
            this.showAlert("Success", "Profile updated successfully.");
            this.enableDisableEditPassword(false);
            this.getUserProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
        else if (apiRequestCallId === this.aboutApiCallId) {
          if (responseJson.data) {
            window.location = "/profile-dashboard" as any;
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }
        }
        else if (apiRequestCallId === this.getCountrylistGetApiCallId) {
          if (responseJson?.countries) {
            this.setState({ countryNames: responseJson?.countries})
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }
        }
        else if (apiRequestCallId === this.getSkillsApiCallId) {
          if (responseJson.data) {
            this.setState({ listSkills: responseJson?.data })
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }
        }

        else if (apiRequestCallId === this.getBrandsApiCallId) {
          if (responseJson.data) {
            this.setState({ brands: responseJson?.data })
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }
        }


        else if (apiRequestCallId === this.connectYoutubeApiCallId) {
        if (responseJson.data) {
          this.setState({ error: 'Successfully connect with Youtube', isSucsess: true,
          googleUsername: responseJson?.data?.attributes?.user_name
        })
          window.location = "/social-media" as any;
          localStorage.removeItem("googleToken");
        }else if (responseJson.errors) {
          let error = Object.values(responseJson.errors[0])[0] as string;
          this.setState({ error, isSucsess: false });
        } else {
          this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
        }
      }

        else if (apiRequestCallId === this.connectGoogleApiCallId) {
          if (responseJson?.uri) {
            window.location = responseJson?.uri as any;
          localStorage.setItem("googleToken", responseJson?.token)
          }else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error, isSucsess: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
          }
        }

        else if (apiRequestCallId === this.connectTwitchAccountApiCallId) {
          if (responseJson.data) {
            this.setState({ error: 'Successfully connect with twitch', isSucsess: true})
          window.location = "/social-media" as any;
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }
        }

        else if (apiRequestCallId === this.updateSkillsApiCallId) {
          if (responseJson.message) {
            window.location = "/profile-dashboard" as any;
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!' });
          }
        }

        else if (apiRequestCallId === this.updateHeaderApiCallId) {
          if (responseJson.data) {
            window.location = "/profile-dashboard" as any;
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error, isSucsess: false, loading: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false, loading: false });
          }
        } 
        
        else if (apiRequestCallId === this.connectSocialAccountApiCallId) {
          if (responseJson.data) {
            if (responseJson.data.attributes.errors) {
              let error = responseJson?.data?.attributes.errors?.unique_auth_id[0]
            this.setState({ error: error || 'Already connect with this account', isSucsess: false });
            }
            if (responseJson.data.attributes.provider === "facebook") {
              this.setState({ error: 'Successfully connect with facebook',
              facebookUsername: responseJson?.data?.attributes?.user_name,
              isSucsess: true });
            }
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error, isSucsess: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
          }
        } 

        else if (apiRequestCallId === this.getUserDataApiCallId) {
          if (responseJson.data) {
            this.setState({ userData: responseJson?.data?.attributes, loading: false})
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error, isSucsess: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
          }
        }


        else if (apiRequestCallId === this.socialAccountsApiCallId) {
          if (responseJson.data) {
            window.location = "/profile-dashboard" as any;
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error, isSucsess: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false});
          }
          this.setState({ loading: false })
        }  

        else if (apiRequestCallId === this.SelectedSkillsApiCallId) {
          if (responseJson.data) {
            const skillIds = responseJson?.data.map(({ id }) => id)
            this.setState({ selectedSkills: skillIds , loading: false })
          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error, isSucsess: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
          }
          this.setState({ loading: false })
        }  

        else if (apiRequestCallId === this.getSocialAccountsApiCallId) {
          if (responseJson.data) {
            const facebookAccount = responseJson.data.find(item => (item.attributes.provider === "facebook"))
            const youtubeAccount = responseJson.data.find(item => (item.attributes.provider === "youtube"))
            const twitterAccount = responseJson.data.find(item => (item.attributes.provider === "twitter"))
            const twitchAccount = responseJson.data.find(item => (item.attributes.provider === "twitch"))

            this.setState({facebookUsername: facebookAccount?.attributes?.user_name,
              googleUsername: youtubeAccount?.attributes?.user_name,
              twitterUsername: twitterAccount?.attributes?.user_name,
              twitchUsername: twitchAccount?.attributes?.user_name,
              loading: false
            })

          } else if (responseJson.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error, isSucsess: false });
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
          }
          // this.setState({ loading: false })
        }

        else if (apiRequestCallId === this.getUserInformationApiCallId) {
          if (responseJson.data) {
            const userDetails = {
              email: responseJson?.data?.attributes?.email, 
              firstName: responseJson?.data?.attributes?.first_name,
              lastName: responseJson?.data?.attributes?.last_name,
              profileUrl: responseJson?.data?.attributes?.profile_picture,
              userName: responseJson?.data?.attributes?.user_name || ''
            }
            this.setState(userDetails)
            localStorage.setItem('userCategory',responseJson?.data?.attributes?.brand?.data?.attributes?.name || '')
            localStorage.setItem('userDetails',JSON.stringify(userDetails))
          } else if (responseJson.errors) {
            let error = responseJson.errors[0] as string;
            this.setState({ error, isSucsess: false });
            if (error.toLowerCase().includes('not found')){
              window.location.replace('/login')
            }
          } else {
            this.setState({ error: responseJson?.error || 'Something went wrong!', isSucsess: false });
          }
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }
    else this.setState({ loading: false })

    // Customizable Area End
  }

  responseFacebook = (response: any) => {
      if (response.accessToken) {
        this.connectSocialAccount(response)
      }
  }


  handleTwitchSignup = async () => {
    try {
      if (window.location.hash) {
        if(window.location.hash.split("&")[1]?.split("=")[0] === 'id_token'){
          this.setState({loading: true})
          let idToken = window.location.hash.split("&")[1]?.split("=")[1];
          let decoded:any = jwt_decode(idToken);
          let response = await  axios.get('https://id.twitch.tv/oauth2/userinfo',{
            headers:{
              "Authorization":`Bearer ${window.location.hash.split("&")[0]?.split("=")[1]}`
            }
          }) 
          this.setState({ twitchUsername: response?.data?.preferred_username})
          this.connectTwitchAccount(response)
        } 
      }
    } catch (error) {
      console.log(error);
      this.setState({loading: false})
    }
   
  };

  googleResponse = async() => {
    if (window.location.search) {
      let code = window.location.search.split("&")[0]?.split("=")[1];
      this.connectYoutube(code)
    }
  }

  getCountries = () => {
    const countries = this.state.countryNames
    // const allCountryList = Country.getAllCountries();
    const countryList = countries.map((item) => ({
      value: item.code,
      label: item.country
    }));
    return countryList;
  }

  connectYoutube = async(code) => {
    try {
      const header = {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("userToken")
      };
      const baseUrl = `https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/youtube_connect?callback_url=${window.location.href}`

      // const baseUrl = `https://yuppgg-68443-ruby.b68443.dev.us-east-1.aws.svc.builder.cafe/youtube_connect?callback_url=${window.location.href}`

    const formData = new FormData();
    const googleToken = localStorage.getItem("googleToken")
    if (code) {
      formData.append("code", code);
      formData.append("token", googleToken);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectYoutubeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodConnectYoutube
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  connectGoogle = async() => {
    try {
      const header = {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("userToken")
      };

      const baseUrl = `https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/authorize?callback_url=${window.location.href}`
      // const baseUrl = `https://yuppgg-68443-ruby.b68443.dev.us-east-1.aws.svc.builder.cafe/authorize?callback_url=${window.location.href}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectGoogleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodConnectGoogle
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }


  connectTwitchAccount = async(response) => {
    try {
      const header = {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("userToken")
      };
    const formData = new FormData();
    const baseUrl = `https://yuppgg-68443-ruby.b68443.prod.southeastasia.az.svc.builder.ai/twitch?callback_url=${window.location.href}`

    if (response.data) {
      formData.append("user_name", response?.data?.preferred_username);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectTwitchAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      baseUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodConnectTwitchAccount
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNotificationCount= async()=> {
  

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationCountGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiNotificationCount
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: localStorage.getItem("userToken"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryList() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountrylistGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApigetCountryList
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: localStorage.getItem("userToken")
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApigetCountryList
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  onSaveAbout = async (values) => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };
    const formData = new FormData();

    formData.append("about", values.description);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.aboutApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointAboutApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodAboutAPi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  getSkills = async() => {

    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSkillsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointGetSkills
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodGetSkills
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  getBrands = async() => {

    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBrandsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointGetBrands
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodGetBrands
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  getUserInformation = async() => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

    const id = localStorage.getItem("userId")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserInformationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointGetUserData}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodGetUserData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  updateSkills = async(skill_ids) => {
    try {
      const header = {
        'Content-Type': 'application/json',
        token: localStorage.getItem("userToken")
      };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateSkillsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointUpdateSkills
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({skill_ids})
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodUpdateSkills
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log('api catch block------', error);
  }
  }

  updateHeader = async(values) => {
    try {
      
      this.setState({loading: true})
      
      const header = {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("userToken")
      };
    const formData = new FormData();
    const id = localStorage.getItem("userId")

    formData.append("account[brand_id]", values.accountType);
    formData.append("account[profile_country_code]", values.country);
    if (values.profileUrl && values.profileUrl.includes('blob:')) {
      let blob = await fetch(values.profileUrl).then(r => r.blob());
      formData.append("account[profile_picture]", blob);
    }
    if(values.cover_Url || values.cover_Url.includes('blob:')) {
        let blob = await fetch(values.cover_Url).then(r => r.blob());
        formData.append("account[profile_cover]", blob);
    }else {formData.append("account[profile_cover]", "");}

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateHeaderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointUpdateHeader}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodUpdateHeader
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    this.setState({loading: false})
    console.log(error);
  }
  }

  connectSocialAccount = async(response) => {
    try {
      const header = {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("userToken")
      };
    const formData = new FormData();

    if (response.graphDomain === "facebook") {
      formData.append("social_profile[provider]", "facebook");
      formData.append("social_profile[unique_auth_id]", response.id);
      formData.append("social_profile[user_name]", response.name);
      formData.append("facebook_token", response.accessToken);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.connectSocialAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointConnectSocialAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodConnectSocialAccount
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  getUserData = async() => {
    try {
      const header = {
        token: localStorage.getItem("userToken")
      };

    const id = localStorage.getItem("userId")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDataApiCallId = requestMessage.messageId;
    this.setState({ loading: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointGetUserData}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodGetUserData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }

  addSocialAccounts = async(data: any) => {
    try {
      const header = {
        'Content-Type': 'application/json',
        token: localStorage.getItem("userToken")
      };
      const httpBody = {
        data: data
      };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.socialAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointSocialAccounts}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodSocialAccounts
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
    this.setState({ loading: false })
  }
  }

  getSelectedSkills = async() => {
    try {
      const header = {
        'Content-Type': 'application/json',
        token: localStorage.getItem("userToken")
      };

    // const id = localStorage.getItem("userId")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SelectedSkillsApiCallId = requestMessage.messageId;
    this.setState({ loading: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointSelectedSkills}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodSelectedSkills
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }


  getSocialAccounts = async() => {
    try {
      const header = {
        'Content-Type': 'application/json',
        token: localStorage.getItem("userToken")
      };

    const id = localStorage.getItem("userId")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSocialAccountsApiCallId = requestMessage.messageId;
    this.setState({ loading: true })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointGetSocialAccounts}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodGetSocialAccounts
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  } catch (error) {
    console.log(error);
  }
  }


  // Customizable Area Start

  checkAbout(t) {
    const validations = Yup.object().shape({
      description: Yup.string().required(`${t("This field is required.")}`)
    })
    return validations
  }

  validationHeader(t) {
    const validations  = Yup.object().shape({
      accountType: Yup.string().required(`${t("This field is required.")}`),
      country: Yup.string().required(`${t("This field is required.")}`)
    })
    return validations
  }
  // Customizable Area End

}
