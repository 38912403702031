//@ts-ignore
//@ts-nocheck
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from '@material-ui/icons/Instagram';
import IconButton from "@material-ui/core/IconButton";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { withTranslation } from 'react-i18next';
import styles from "./FooterStyles";
// import yupLogo from "../assets/yup-logo-1.png"
import landingLogoImage from '../assets/logo 96x96.svg';
const useStyles = makeStyles(styles);

const Footer = ({t, i18n}) => {
  const classes = useStyles();
  // const { t, i18n } = props
  return (
    // <Footer
    //   theme="white"
    // >
    <div className={classes.section}>
      <footer className="page-footer center-on-small-only stylish-color-dark">
        <div className={classes.footer}>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} className={classes.footerContanier} >
              <img className={classes.img} src={landingLogoImage} />
              <div
                className={classNames(classes.pullCenter, classes.copyRight)}
              >
                {/* Copyright &copy; {1900 + new Date().getYear()}{" "} */}
                {t("Copyright ©")} {1900 + new Date().getYear()}{" "}  {t("WASD Pte. Ltd.")}<br /> {t("All rights reserved")}
              </div>
              <div>
                <ul className={classes.socialButtons}>
                  <li>
                    <IconButton className={classes.socialIcon}>
                      <InstagramIcon />
                    </IconButton>
                  </li>
                  <li>
                    <IconButton className={classes.socialIcon}>
                      <LinkedInIcon />
                    </IconButton>
                  </li>
                  <li>
                    <IconButton className={classes.socialIcon}>
                      <TwitterIcon />
                    </IconButton>
                  </li>
                  <li>
                    <IconButton className={classes.socialIcon}>
                      <YouTubeIcon />
                    </IconButton>
                  </li>
                </ul>
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={2} md={2}>
              <h5>Product</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <a href="#pablo">About us</a>
                </li>
                <li>
                  <a href="#pablo">Blog</a>
                </li>
                <li>
                  <a href="#pablo">Contact us</a>
                </li>
                <li>
                  <a href="#pablo">Pricing</a>
                </li>
                <li>
                  <a href="#pablo">Testimonials</a>
                </li>
              </ul>
            </Grid> */}
            {/* <Grid item xs={12} sm={2} md={2}>
              <h5>Resources</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <a href="#pablo">Help center</a>
                </li>
                <li>
                  <a href="#pablo">Terms of service</a>
                </li>
                <li>
                  <a href="#pablo">Legal</a>
                </li>
                <li>
                  <a href="#pablo">Privacy policy</a>
                </li>
                <li>
                  <a href="#pablo">Status</a>
                </li>
              </ul>
            </Grid> */}
            {/* <Grid item xs={12} sm={2} md={2}>
              <h5>Company</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <a href="#pablo">Help center</a>
                </li>
                <li>
                  <a href="#pablo">Terms of service</a>
                </li>
                <li>
                  <a href="#pablo">Legal</a>
                </li>
                <li>
                  <a href="#pablo">Privacy policy</a>
                </li>
                <li>
                  <a href="#pablo">Status</a>
                </li>
              </ul>
            </Grid> */}
          </Grid>
        </div>
      </footer>
    </div>
  )
}

export default withTranslation()(Footer)
