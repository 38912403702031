//@ts-ignore
//@ts-nocheck
import React,{ useState } from "react";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { withRouter } from "react-router";
import classNames from "classnames";

// import landingLogImage from '../assets/yup-logo.png';
import landingLogoImage  from '../assets/favicon-76x76.png';
import { TopNavBarStyle } from '../../../components/src/TopNavBarStyle';
import Header from "../../../components/src/Header";
import { withTranslation } from 'react-i18next';
class NewHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    language :"en",
    };
  }

  async componentDidMount() {
    const language = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(language);
    if(language) {
      this.setState({language})
    }else {
    localStorage.setItem("langauge", this.state.language)
    } 
    // Customizable Area End
  }
  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevState !== this.state.language){
  
  //   }
  // }

  render() {
    const { classes, t, i18n } = this.props

    const handleLangChange = evt => {
      const lang = evt.target.value;
      this.setState({language:lang});
      localStorage.setItem("langauge", lang)
      i18n.changeLanguage(lang);
    };
    const languageOption = [
      {value: "en", label: "English"},
      {value: "br", label: "Portuguese"},
      {value: "th", label: "Thai"},
      {value: "id", label: "Bahasa"},
      {value: "kr", label: "Korean"}
    ]

    return (
      <Header
        className={classes.landingHeader}
        // absolute
        color="transparent"
        image={landingLogoImage}
        links={
          <div className={classes.collapse}>
            <List className={classes.landing_list + " " + classes.list + " " + classes.mlAuto}>
              <ListItem className={classes.listItem}>
                <Button
                  color="white"
                  backgroundColor="black"
                  target="_blank"
                  className={classes.landingNavLink}
                  onClick={() => {
                    this.props.history.push('/');
                  }}
                  style={{ width: "max-content" }}
                >
                 {t("Home")}
                </Button>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Button
                  color="white"
                  backgroundColor="black"
                  target="_blank"
                  className={classes.landingNavLink}
                  onClick={() => {
                    this.props.history.push('/aboutus');
                  }}
                  style={{ width: "max-content" }}
                >
                  {t("About")}
                </Button>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Button
                  color="white"
                  backgroundColor="black"
                  target="_blank"
                  className={classes.landingNavLinkactive}
                  onClick={() => {
                    this.props.history.push('/login');
                  }}
                >
                  {t("Login")}
                </Button>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Button
                  color="white"
                  backgroundColor="black"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  target="_blank"
                  className={classes.landingNavLink + " " + classes.newButton}
                  onClick={() => {
                    this.props.history.push('/claim-username');
                  }}
                >
                  {t("Signup")}
                </Button>
              </ListItem>
              <ListItem className={classes.selectBtn}>
                <select color="white"
                    backgroundColor="black" style={{borderRadius:"10px"}}className={classes.dropdownBtn} onChange={handleLangChange} 
                    value={this.state.language}
                    // options={languageOption}
                    // defaultValue={languageOption.find(obj => obj.value === this.state.language)}
                >
                <option value="en">English</option>
                <option value="br">Portuguese</option>
                <option value="kr">Korean</option>
                <option value="th">Thai</option>
                <option value="id">Bahasa</option>
               
              </select>
              </ListItem>
            </List>
          </div>
        }
      />
    );
  }
};

export default withTranslation()(withStyles(TopNavBarStyle)(withRouter(NewHeader)))
