//@ts-ignore
//@ts-nocheck
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";

class SetProfile extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.updateAdjustableElements();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  render() {
    const { classes, history, t , i18n } = this.props;
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      this.setState({ value: newValue });
    };

    return (
      <>
        <div style={{ marginTop: 30 }}>
          <Tabs value={this.state.value} onChange={handleChange} centered>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <Tab
                label={t("Profile")}
                className={classes.tab1}
                onClick={() => this.props.history.push("/profile-dashboard")}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <Tab
                label={t("People")}
                className={classes.tabSecond}
                onClick={() => this.props.history.push("/discovery")}
              />
            </Grid>
          </Tabs>
        </div>

        <div
          style={{
            border: "#80808042 1px solid",
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15
          }}
        >
          <Grid container className={classes.secondContanier}>
            <div className={classes.buttonContainer}>
              <Box className={classes.cardBlock}>
                <Typography className={classes.cardTitle}>
                  <span className={classes.cardTitleBorder} />
                  {t("Header")}
                </Typography>
                <Box className={classes.profileRow}>
                  <Typography className={classes.subText}>{t("profile pic, name, location")}</Typography>
                  <Button className={classes.buttonText} onClick={() => this.props.history.push("/edit-header")}>
                    {t("Edit")}
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>

          <Grid container className={classes.secondContanier}>
            <div className={classes.buttonContainer}>
              <Box className={classes.cardBlock}>
                <Typography className={classes.cardTitle}>
                  <span className={classes.cardTitleBorder} />
                  {t("Social Media")}
                </Typography>
                <Box className={classes.profileRow}>
                  <Typography className={classes.subText}>
                    {t("facebook, twitter, youtube, twitch...")}
                  </Typography>
                  <Button className={classes.buttonText} onClick={() => this.props.history.push("/social-media")}>
                    {t("Edit")}
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>

          <Grid container className={classes.secondContanier}>
            <div className={classes.buttonContainer}>
              <Box className={classes.cardBlock}>
                <Typography className={classes.cardTitle}>
                  <span className={classes.cardTitleBorder} />
                  {t("About")}
                </Typography>
                <Box className={classes.profileRow}>
                  <Typography className={classes.subText}>{t("Your story")}</Typography>
                  <Button className={classes.buttonText} onClick={() => this.props.history.push("/about")}>
                    {t("Edit")}
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>
          <Typography className={classes.adjustText}>{t("Adjustable elements")}</Typography>
          <Typography className={classes.dargText}>
            {t("We've activated the elements that should apply to your account")}:
          </Typography>

          {this.state.adjustableElements &&
            this.state.adjustableElements.map((element, index) => {
              return (
                <Grid
                  container
                  key={element.id}
                  className={classes.secondContanier}
                >
                  <div className={classes.buttonContainer}>
                    <Box className={classes.cardBlock}>
                      <Typography
                        className={classes.cardTitle}
                        style={element.name === "Audience" ? { opacity: 0.4 } : {}}
                      >
                        <span className={classes.cardTitleBorder} />
                        {t(element.name)}
                      </Typography>
                      <Box className={classes.profileRow}>
                        <Typography className={classes.subText}>{t(element.sub_title)}</Typography>
                        <Box display="flex">
                          <IconButton
                            disabled={!Boolean(element.redirect_to)}
                            onClick={() => this.updatePreview(index)}
                            disableRipple={true}
                            style={{
                              padding: 0,
                              marginRight: 10,
                              backgroundColor: "transparent"
                            }}
                          >
                            <VisibilityIcon
                              style={{
                                width: 24,
                                height: 24,
                                color: element.visible ? "red" : "#000000",
                                opacity: 0.54
                              }}
                            />
                          </IconButton>
                          <Button
                            disabled={!Boolean(element.redirect_to)}
                            className={classes.buttonText}
                            onClick={() => this.props.history.push(element.redirect_to)}
                          >
                            {t("Edit")}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Grid>
              );
            })}
          <Box className={classes.btnAdd} />
        </div>
      </>
    );
  }
}

const setProfileStyle = {
  buttonContainer: {
    width: "inherit",
    background: "rgb(247, 247, 252)",
    borderRadius: 24,
    marginTop: 15,
    marginRight: 10,
    marginLeft: 10,
    padding: "15px 30px",
    display: "flex",
    alignItems: "center",

    "@media (max-width: 768px)": {
      padding: "5px 10px",
      textAlign: "right",
      borderRadius: 10,
      border: "1px solid #ccc"
    }
  },
  profileRow: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    "@media (max-width: 768px)": {
      justifyContent: "space-between"
    }
  },
  cardBlock: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 768px)": {
      flexWrap: "wrap"
    }
  },
  cardTitle: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    whiteSpace: "nowrap",
    color: "rgba(0, 0, 0, 0.87)",
    letterSpacing: 1,
    position: "relative",
    paddingLeft: "10px",
    // paddingBottom: "10px"
  },
  cardTitleBorder: {
    "@media (max-width: 768px)": {
      content: " ",
      backgroundColor: "#000",
      position: "absolute",
      left: "0",
      top: "3px",
      height: "16px",
      width: "3px",
      borderRadius: "38%"
    }
  },
  tabSecond: {
    fontSize: 17,
    fontFamily: "Poppins",
    padding: 15,
    minWidth: "100%",
    height: 100,
    textTransform: "none",
    background: "rgb(247, 247, 252)"
  },
  secondContanier: {
    display: "flex",
    width: "100%",
    flexWrap: "inherit"
  },
  subText: {
    letterSpacing: 1,
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "rgb(142, 142, 142)",
    fontSize: 12,
    marginTop: 5,
    marginRight: "5px",
    "@media (max-width: 768px)": {
      paddingLeft: "10px",
      textAlign: "left"
    }
  },
  tab1: {
    fontSize: 17,
    fontFamily: "Poppins",
    padding: 15,
    minWidth: "100%",
    height: 100,
    background: "black",
    color: "white",
    textTransform: "none",
    letterSpacing: 1,
    opacity: 1,
    "&:hover": {
      background: "black",
      color: "white"
    }
  },
  adjustText: {
    marginTop: 50,
    fontWeight: 600,
    color: "rgba(20, 20, 43, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins",
    fontSize: 18,
    paddingLeft: 10,
    letterSpacing: 1
  },
  dargText: {
    marginTop: 5,
    color: "rgba(142, 142, 142, 1)",
    textAlign: "left",
    textAlignVertical: "top",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 10
  },
  textAdd: {
    marginTop: 10,
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 0,
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins",
    fontSize: 14,
    paddingBottom: 30
  },
  btnAdd: {
    marginTop: "20%",
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 0,
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Poppins",
    fontSize: 14
  },
  buttonText: {
    fontSize: 14,
    background: "none",
    padding: 0,
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#000000",
    textTransform: "uppercase"
  }
};

export default withTranslation()(withStyles(setProfileStyle)(
  withRouter(SetProfile as React.ComponentType<any>)
))
