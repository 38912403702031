//@ts-ignore
//@ts-nocheck
import {
  Link,
  Typography,
  Button,
  Checkbox,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import { View, Text } from "react-native";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ProfileHeader from "../../user-profile-basic/src/ProfileHeader";
import SideMenu from "../../user-profile-basic/src/SideMenu.web";
import SubSideMenu from "./SubSideMenu.web";
import SettingController from "./SettingController.web";
import Snackbar from "../../../components/src/Snackbar";
import { withTranslation } from 'react-i18next';

class PrivacySecurity extends SettingController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.getUserData();
    const langauge = localStorage.getItem("langauge")
    this.props.i18n.changeLanguage(langauge);
  }

  handleChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
    this.editSecurity(checked);
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  
  render() {
    const { classes, t , i18n } = this.props;
    const { isSecurity, loading, userData } = this.state;

    const lastFiveDigit = userData?.full_phone_number? userData?.full_phone_number%100000 : "***"

    return (
      <>
        <ProfileHeader heading={t("Privacy Security")} subModule="3" />
        <Grid container className={classes.mainContainer}>
          <Grid item sm={3} xs={12} style={{ position: "relative" }}>
            <SideMenu subModule="3" />
          </Grid>
          <Grid item xs={2} className={classes.settingIcon}>
            <SubSideMenu handleDrawerToggle={this.handleDrawerToggle} mobileOpen={this.state.mobileOpen}/>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className={classes.privacyBlock}>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress style={{ color: "#F14E24" }} />
                </Box>
              ) : (
                <>
                  <Typography className={classes.heading}>
                    {t("Two-Factor Authentication")}
                  </Typography>
                  <Typography
                    className={classes.content}
                    style={{ fontSize: 13 }}
                  >
                    {t("We'll ask for a security code when we need to confirm that it's you logging in.")}
                  </Typography>
                  <Typography
                    className={classes.content}
                    style={{ fontSize: 14, paddingTop: 25, paddingBottom: 5 }}
                  >
                    {t("Text Message")}
                  </Typography>
                  <Box display="flex" mt="10px" alignItems="flex-start">
                    <Checkbox
                      checked={isSecurity}
                      onChange={(e) => this.handleChange(e)}
                      style={{ padding: 0, marginRight: 4, marginTop: 4 }}
                      name="isSecurity"
                      checkedIcon={
                        <CheckBox
                          style={{
                            color: "#F14E24",
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      }
                    />
                    <Typography
                      style={{
                        // color: "#A0A3BD",
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: 12,
                        paddingTop: 6,
                      }}
                    >
                      {t("Enable Text Message")}
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      color: "#A0A3BD",
                      paddingTop: 3,
                      fontFamily: "Poppins",
                    }}
                  >
                    {t("We'll send a code to")} ***{lastFiveDigit}
                  </Typography>
                </>
              )}
            </div>
          </Grid>
        </Grid>
        <Snackbar
          open={Boolean(this.state.error)}
          onClose={() => this.setState({ error: null })}
          message={this.state.error || "N/A"}
          severity="error"
        />
      </>
    );
  }
}

const styles = {
  heading: {
    fontSize: 19,
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  content: {
    paddingTop: 10,
    fontFamily: "Poppins",
    color: "darkslategray",
    fontWeight: 400,
  },
  mainContainer: {
    paddingBottom: 0,
    height: "100%",
    "@media (max-width: 768px)": {
      height: 0
    }
  },
  privacyBlock: {
    // marginTop: 45,
    padding: "35px 50px 0px 50px",
    position: "relative",
    "@media (max-width: 768px)": {
      marginTop: 0,
    }
  },
  settingIcon: {
    borderLeft: "1px solid rgba(128, 128, 128, 0.26)", 
    borderRight: "1px solid rgba(128, 128, 128, 0.26)",
    "@media (max-width: 768px)": {
      borderLeft: "none",
      borderRight: "none",
      position: "absolute",
      right: "40px",
      top: "16px",
    }
  },
};

export default withTranslation()(withStyles(styles)(PrivacySecurity))
